/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOExteriorColorGroup } from './SelectOptionDTOExteriorColorGroup';
import {
    SelectOptionDTOExteriorColorGroupFromJSON,
    SelectOptionDTOExteriorColorGroupFromJSONTyped,
    SelectOptionDTOExteriorColorGroupToJSON,
    SelectOptionDTOExteriorColorGroupToJSONTyped,
} from './SelectOptionDTOExteriorColorGroup';

/**
 * 
 * @export
 * @interface OptionalSelectDTOExteriorColorGroup
 */
export interface OptionalSelectDTOExteriorColorGroup {
    /**
     * 
     * @type {SelectOptionDTOExteriorColorGroup}
     * @memberof OptionalSelectDTOExteriorColorGroup
     */
    value?: SelectOptionDTOExteriorColorGroup;
    /**
     * 
     * @type {Array<SelectOptionDTOExteriorColorGroup>}
     * @memberof OptionalSelectDTOExteriorColorGroup
     */
    options: Array<SelectOptionDTOExteriorColorGroup>;
}

/**
 * Check if a given object implements the OptionalSelectDTOExteriorColorGroup interface.
 */
export function instanceOfOptionalSelectDTOExteriorColorGroup(value: object): value is OptionalSelectDTOExteriorColorGroup {
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function OptionalSelectDTOExteriorColorGroupFromJSON(json: any): OptionalSelectDTOExteriorColorGroup {
    return OptionalSelectDTOExteriorColorGroupFromJSONTyped(json, false);
}

export function OptionalSelectDTOExteriorColorGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalSelectDTOExteriorColorGroup {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : SelectOptionDTOExteriorColorGroupFromJSON(json['value']),
        'options': ((json['options'] as Array<any>).map(SelectOptionDTOExteriorColorGroupFromJSON)),
    };
}

export function OptionalSelectDTOExteriorColorGroupToJSON(json: any): OptionalSelectDTOExteriorColorGroup {
    return OptionalSelectDTOExteriorColorGroupToJSONTyped(json, false);
}

export function OptionalSelectDTOExteriorColorGroupToJSONTyped(value?: OptionalSelectDTOExteriorColorGroup | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': SelectOptionDTOExteriorColorGroupToJSON(value['value']),
        'options': ((value['options'] as Array<any>).map(SelectOptionDTOExteriorColorGroupToJSON)),
    };
}


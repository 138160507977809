/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseInformationDTO,
  ColorAttributesDTO,
  ColorAttributesUpdateDTO,
  LocalizationsItemDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    ColorAttributesDTOFromJSON,
    ColorAttributesDTOToJSON,
    ColorAttributesUpdateDTOFromJSON,
    ColorAttributesUpdateDTOToJSON,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface DeleteColorRequest {
    colorCode: string;
}

export interface GetAttributesOfColorRequest {
    colorCode: string;
}

export interface GetBaseInformationOfColorRequest {
    colorCode: string;
    languageTag: string;
}

export interface GetLocalizationsOfDescriptionOfColorRequest {
    colorCode: string;
}

export interface GetLocalizationsOfNameOfColorRequest {
    colorCode: string;
}

export interface UpdateAttributesOfColorRequest {
    colorCode: string;
    colorAttributesUpdateDTO: ColorAttributesUpdateDTO;
}

export interface UpdateDescriptionOfColorRequest {
    colorCode: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfColorRequest {
    colorCode: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class ColorDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a Color by a given key
     */
    async deleteColorRaw(requestParameters: DeleteColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['colorCode'] == null) {
            throw new runtime.RequiredError(
                'colorCode',
                'Required parameter "colorCode" was null or undefined when calling deleteColor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/colors/details/{colorCode}`.replace(`{${"colorCode"}}`, encodeURIComponent(String(requestParameters['colorCode']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Color by a given key
     */
    async deleteColor(requestParameters: DeleteColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteColorRaw(requestParameters, initOverrides);
    }

    /**
     * Get attributes of Color with given key
     */
    async getAttributesOfColorRaw(requestParameters: GetAttributesOfColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ColorAttributesDTO>> {
        if (requestParameters['colorCode'] == null) {
            throw new runtime.RequiredError(
                'colorCode',
                'Required parameter "colorCode" was null or undefined when calling getAttributesOfColor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/colors/details/{colorCode}/attributes`.replace(`{${"colorCode"}}`, encodeURIComponent(String(requestParameters['colorCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ColorAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of Color with given key
     */
    async getAttributesOfColor(requestParameters: GetAttributesOfColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ColorAttributesDTO> {
        const response = await this.getAttributesOfColorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get baseInformation of Color with given key
     */
    async getBaseInformationOfColorRaw(requestParameters: GetBaseInformationOfColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters['colorCode'] == null) {
            throw new runtime.RequiredError(
                'colorCode',
                'Required parameter "colorCode" was null or undefined when calling getBaseInformationOfColor().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getBaseInformationOfColor().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/colors/details/{colorCode}/base-information`.replace(`{${"colorCode"}}`, encodeURIComponent(String(requestParameters['colorCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of Color with given key
     */
    async getBaseInformationOfColor(requestParameters: GetBaseInformationOfColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfColorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get localizationsOfDescription of Color with given key
     */
    async getLocalizationsOfDescriptionOfColorRaw(requestParameters: GetLocalizationsOfDescriptionOfColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters['colorCode'] == null) {
            throw new runtime.RequiredError(
                'colorCode',
                'Required parameter "colorCode" was null or undefined when calling getLocalizationsOfDescriptionOfColor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/colors/details/{colorCode}/description/localizations`.replace(`{${"colorCode"}}`, encodeURIComponent(String(requestParameters['colorCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfDescription of Color with given key
     */
    async getLocalizationsOfDescriptionOfColor(requestParameters: GetLocalizationsOfDescriptionOfColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfDescriptionOfColorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get localizationsOfName of Color with given key
     */
    async getLocalizationsOfNameOfColorRaw(requestParameters: GetLocalizationsOfNameOfColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters['colorCode'] == null) {
            throw new runtime.RequiredError(
                'colorCode',
                'Required parameter "colorCode" was null or undefined when calling getLocalizationsOfNameOfColor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/colors/details/{colorCode}/name/localizations`.replace(`{${"colorCode"}}`, encodeURIComponent(String(requestParameters['colorCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of Color with given key
     */
    async getLocalizationsOfNameOfColor(requestParameters: GetLocalizationsOfNameOfColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfColorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates attributes of an existing Color
     */
    async updateAttributesOfColorRaw(requestParameters: UpdateAttributesOfColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['colorCode'] == null) {
            throw new runtime.RequiredError(
                'colorCode',
                'Required parameter "colorCode" was null or undefined when calling updateAttributesOfColor().'
            );
        }

        if (requestParameters['colorAttributesUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'colorAttributesUpdateDTO',
                'Required parameter "colorAttributesUpdateDTO" was null or undefined when calling updateAttributesOfColor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/colors/details/{colorCode}/attributes`.replace(`{${"colorCode"}}`, encodeURIComponent(String(requestParameters['colorCode']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ColorAttributesUpdateDTOToJSON(requestParameters['colorAttributesUpdateDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing Color
     */
    async updateAttributesOfColor(requestParameters: UpdateAttributesOfColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAttributesOfColorRaw(requestParameters, initOverrides);
    }

    /**
     * Updates description of an existing Color
     */
    async updateDescriptionOfColorRaw(requestParameters: UpdateDescriptionOfColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['colorCode'] == null) {
            throw new runtime.RequiredError(
                'colorCode',
                'Required parameter "colorCode" was null or undefined when calling updateDescriptionOfColor().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateDescriptionOfColor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/colors/details/{colorCode}/description`.replace(`{${"colorCode"}}`, encodeURIComponent(String(requestParameters['colorCode']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates description of an existing Color
     */
    async updateDescriptionOfColor(requestParameters: UpdateDescriptionOfColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDescriptionOfColorRaw(requestParameters, initOverrides);
    }

    /**
     * Updates name of an existing Color
     */
    async updateNameOfColorRaw(requestParameters: UpdateNameOfColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['colorCode'] == null) {
            throw new runtime.RequiredError(
                'colorCode',
                'Required parameter "colorCode" was null or undefined when calling updateNameOfColor().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateNameOfColor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/colors/details/{colorCode}/name`.replace(`{${"colorCode"}}`, encodeURIComponent(String(requestParameters['colorCode']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing Color
     */
    async updateNameOfColor(requestParameters: UpdateNameOfColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNameOfColorRaw(requestParameters, initOverrides);
    }

}

import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { OptionCustomizationFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { OptionCustomizationFilter } from "../../../../../pdsapi"
import { FunctionComponent } from "react"
import { MultiSelectFilter } from "../../../../../components/filter/MultiSelectFilter"
import { GroupedMultiSelectFilter } from "../../../../../components/filter/GroupedMultiSelectFilter"

export const OptionCustomizationListFilter: FunctionComponent<TableFilterProps<OptionCustomizationFilter, OptionCustomizationFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const typeFilter = (
        <MultiSelectFilter
            label={"Types"}
            options={filterOptions?.types}
            value={filter.types}
            onChange={(types) =>
                setFilter((filter) => ({
                    ...filter,
                    types,
                }))
            }
        />
    )
    const modelGenerationFilter = (
        <MultiSelectFilter
            label={"Model Generations"}
            options={filterOptions?.modelGenerations}
            value={filter.modelGenerationKeys}
            onChange={(modelGenerationKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelGenerationKeys,
                }))
            }
        />
    )
    const equipmentTagFilter = (
        <GroupedMultiSelectFilter
            label={"Equipment Tags"}
            options={filterOptions?.categorizedEquipmentTags}
            value={filter.equipmentTagKeys}
            onChange={(equipmentTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    equipmentTagKeys,
                }))
            }
        />
    )
    const interiorColorTagFilter = (
        <MultiSelectFilter
            label={"Interior Color Tags"}
            options={filterOptions?.interiorColorTags}
            value={filter.interiorColorTagKeys}
            onChange={(interiorColorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    interiorColorTagKeys,
                }))
            }
        />
    )
    const exteriorColorTagFilter = (
        <MultiSelectFilter
            label={"Exterior Color Tags"}
            options={filterOptions?.exteriorColorTags}
            value={filter.exteriorColorTagKeys}
            onChange={(exteriorColorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    exteriorColorTagKeys,
                }))
            }
        />
    )
    const roofColorTagFilter = (
        <MultiSelectFilter
            label={"Roof Color Tags"}
            options={filterOptions?.roofColorTags}
            value={filter.roofColorTagKeys}
            onChange={(roofColorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    roofColorTagKeys,
                }))
            }
        />
    )
    const interiorMaterialFilter = (
        <MultiSelectFilter
            label={"Interior Materials"}
            options={filterOptions?.interiorMaterials}
            value={filter.interiorMaterials}
            onChange={(interiorMaterials) =>
                setFilter((filter) => ({
                    ...filter,
                    interiorMaterials,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Configuration"}>
                {typeFilter}
                {modelGenerationFilter}
            </ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Tag"}>
                {equipmentTagFilter}
                {interiorColorTagFilter}
                {exteriorColorTagFilter}
                {roofColorTagFilter}
            </ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Other"}>{interiorMaterialFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}

export interface TagOption {
    value: string
    label: string
    route: string
}

export const toTagOption = <KEY,>(data: { key: KEY; label: string }, toRoute: (key: KEY) => string) => ({
    value: data.key,
    label: data.label,
    route: toRoute(data.key),
})

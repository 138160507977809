/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationAttributesUpdateDTOEquipmentValue } from './OptionCustomizationAttributesUpdateDTOEquipmentValue';
import {
    OptionCustomizationAttributesUpdateDTOEquipmentValueFromJSON,
    OptionCustomizationAttributesUpdateDTOEquipmentValueFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOEquipmentValueToJSON,
    OptionCustomizationAttributesUpdateDTOEquipmentValueToJSONTyped,
} from './OptionCustomizationAttributesUpdateDTOEquipmentValue';
import type { OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue } from './OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue';
import {
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueFromJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueFromJSONTyped,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueToJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueToJSONTyped,
} from './OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesUpdateDTOEquipment
 */
export interface OptionCustomizationAttributesUpdateDTOEquipment {
    /**
     * 
     * @type {OptionCustomizationAttributesUpdateDTOEquipmentValue}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipment
     */
    defaultValue: OptionCustomizationAttributesUpdateDTOEquipmentValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipment
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipment
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue>}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipment
     */
    conditionalValues: Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesUpdateDTOEquipment
     */
    isDefaultValueActive: boolean;
}

/**
 * Check if a given object implements the OptionCustomizationAttributesUpdateDTOEquipment interface.
 */
export function instanceOfOptionCustomizationAttributesUpdateDTOEquipment(value: object): value is OptionCustomizationAttributesUpdateDTOEquipment {
    if (!('defaultValue' in value) || value['defaultValue'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelGenerationKeys' in value) || value['modelGenerationKeys'] === undefined) return false;
    if (!('conditionalValues' in value) || value['conditionalValues'] === undefined) return false;
    if (!('isDefaultValueActive' in value) || value['isDefaultValueActive'] === undefined) return false;
    return true;
}

export function OptionCustomizationAttributesUpdateDTOEquipmentFromJSON(json: any): OptionCustomizationAttributesUpdateDTOEquipment {
    return OptionCustomizationAttributesUpdateDTOEquipmentFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTOEquipment {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTOEquipmentValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
    };
}

export function OptionCustomizationAttributesUpdateDTOEquipmentToJSON(json: any): OptionCustomizationAttributesUpdateDTOEquipment {
    return OptionCustomizationAttributesUpdateDTOEquipmentToJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOEquipmentToJSONTyped(value?: OptionCustomizationAttributesUpdateDTOEquipment | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTOEquipmentValueToJSON(value['defaultValue']),
        'optionCode': value['optionCode'],
        'modelGenerationKeys': value['modelGenerationKeys'],
        'conditionalValues': ((value['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueToJSON)),
        'isDefaultValueActive': value['isDefaultValueActive'],
    };
}


import { TableFilterTagsConfig } from "../../../../components/filteredpagedtable/FilteredPagedTable"
import { TechnicalDataFilterOptionsDTO } from "../../../../generated/pdsapi"
import { TechnicalDataFilter } from "../../../../pdsapi"
import { findGroupedOption, findOption } from "../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { filterTagsFor } from "../../../../components/filteredpagedtable/filtertags/filterTagsFor"

export const TechnicalDataListFilterTagsConfig: TableFilterTagsConfig<TechnicalDataFilter, TechnicalDataFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => [
    ...filterTagsFor(
        filter,
        setFilter,
        "orderTypeKeys",
        (orderTypeKey) => findGroupedOption(filterOptions?.modelYearAndGenerationRelatedOrderTypes, orderTypeKey)?.label ?? orderTypeKey
    ),
    ...filterTagsFor(filter, setFilter, "modelYears", (modelYear) => findOption(filterOptions?.modelYears, modelYear)?.label ?? String(modelYear)),
    ...filterTagsFor(
        filter,
        setFilter,
        "modelGenerationKeys",
        (modelGenerationKey) => findGroupedOption(filterOptions?.modelSeriesRelatedModelGenerations, modelGenerationKey)?.label ?? modelGenerationKey
    ),
    ...filterTagsFor(filter, setFilter, "modelSeriesKeys", (modelSeriesKey) => findOption(filterOptions?.modelSeries, modelSeriesKey)?.label ?? modelSeriesKey),
]

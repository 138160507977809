/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderTypeStandardEquipmentDTOOptionsCategoryOption } from './OrderTypeStandardEquipmentDTOOptionsCategoryOption';
import {
    OrderTypeStandardEquipmentDTOOptionsCategoryOptionFromJSON,
    OrderTypeStandardEquipmentDTOOptionsCategoryOptionFromJSONTyped,
    OrderTypeStandardEquipmentDTOOptionsCategoryOptionToJSON,
    OrderTypeStandardEquipmentDTOOptionsCategoryOptionToJSONTyped,
} from './OrderTypeStandardEquipmentDTOOptionsCategoryOption';

/**
 * 
 * @export
 * @interface OrderTypeStandardEquipmentDTOOptionsCategory
 */
export interface OrderTypeStandardEquipmentDTOOptionsCategory {
    /**
     * 
     * @type {string}
     * @memberof OrderTypeStandardEquipmentDTOOptionsCategory
     */
    label: string;
    /**
     * 
     * @type {Array<OrderTypeStandardEquipmentDTOOptionsCategoryOption>}
     * @memberof OrderTypeStandardEquipmentDTOOptionsCategory
     */
    content: Array<OrderTypeStandardEquipmentDTOOptionsCategoryOption>;
}

/**
 * Check if a given object implements the OrderTypeStandardEquipmentDTOOptionsCategory interface.
 */
export function instanceOfOrderTypeStandardEquipmentDTOOptionsCategory(value: object): value is OrderTypeStandardEquipmentDTOOptionsCategory {
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function OrderTypeStandardEquipmentDTOOptionsCategoryFromJSON(json: any): OrderTypeStandardEquipmentDTOOptionsCategory {
    return OrderTypeStandardEquipmentDTOOptionsCategoryFromJSONTyped(json, false);
}

export function OrderTypeStandardEquipmentDTOOptionsCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeStandardEquipmentDTOOptionsCategory {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'],
        'content': ((json['content'] as Array<any>).map(OrderTypeStandardEquipmentDTOOptionsCategoryOptionFromJSON)),
    };
}

export function OrderTypeStandardEquipmentDTOOptionsCategoryToJSON(json: any): OrderTypeStandardEquipmentDTOOptionsCategory {
    return OrderTypeStandardEquipmentDTOOptionsCategoryToJSONTyped(json, false);
}

export function OrderTypeStandardEquipmentDTOOptionsCategoryToJSONTyped(value?: OrderTypeStandardEquipmentDTOOptionsCategory | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'label': value['label'],
        'content': ((value['content'] as Array<any>).map(OrderTypeStandardEquipmentDTOOptionsCategoryOptionToJSON)),
    };
}


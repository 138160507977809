/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface OptionalSelectDTOString
 */
export interface OptionalSelectDTOString {
    /**
     * 
     * @type {SelectOptionDTOString}
     * @memberof OptionalSelectDTOString
     */
    value?: SelectOptionDTOString;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionalSelectDTOString
     */
    options: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the OptionalSelectDTOString interface.
 */
export function instanceOfOptionalSelectDTOString(value: object): value is OptionalSelectDTOString {
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function OptionalSelectDTOStringFromJSON(json: any): OptionalSelectDTOString {
    return OptionalSelectDTOStringFromJSONTyped(json, false);
}

export function OptionalSelectDTOStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalSelectDTOString {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : SelectOptionDTOStringFromJSON(json['value']),
        'options': ((json['options'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function OptionalSelectDTOStringToJSON(json: any): OptionalSelectDTOString {
    return OptionalSelectDTOStringToJSONTyped(json, false);
}

export function OptionalSelectDTOStringToJSONTyped(value?: OptionalSelectDTOString | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': SelectOptionDTOStringToJSON(value['value']),
        'options': ((value['options'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


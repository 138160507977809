import { technicalDataMenuItemGroups } from "../components/technicaldatamenu/technicalDataMenuItemGroups"
import { OptionCustomizationTypeDTO, OptionTagTypeDTO, OptionTypeDTO, PdsMarketplace } from "../generated/pdsapi"

const buildProductRoutes = <KEY>(root: string, convertKeyToPath: (key: KEY) => string) => ({
    list: () => root,
    create: (params?: URLSearchParams) => (params ? `${root}/create?${params.toString()}` : `${root}/create`),
    details: (key: KEY, params?: URLSearchParams) => (params ? `${root}/${convertKeyToPath(key)}?${params.toString()}` : `${root}/${convertKeyToPath(key)}`),
})

const buildProductRoutesWithSort = <KEY>(root: string, convertKeyToPath: (key: KEY) => string) => ({
    ...buildProductRoutes(root, convertKeyToPath),
    sort: () => `${root}/sort`,
})

const defaultKeyToPath = (key: string) => key

export const routes = {
    models: {
        modelSeries: buildProductRoutesWithSort("/models/model-series", defaultKeyToPath),
        modelGenerations: buildProductRoutesWithSort("/models/model-generations", defaultKeyToPath),
        derivatives: buildProductRoutesWithSort("/models/derivatives", defaultKeyToPath),
        derivativeCategories: buildProductRoutesWithSort("/models/derivative-categories", defaultKeyToPath),
        orderTypes: {
            ...buildProductRoutes("/models/order-types", defaultKeyToPath),
            subpages: (orderTypeKey: string) => {
                const root = buildProductRoutes("/models/order-types", defaultKeyToPath).details(orderTypeKey)
                return {
                    options: {
                        details: (optionCode: string, type: OptionTypeDTO) =>
                            `${root}/options/${type}/${optionCode.startsWith(":") ? optionCode : encodeURIComponent(optionCode)}`,
                        list: () => `${root}?selectedTab=2&tab2.options.isExpanded=true`,
                        create: (params?: URLSearchParams) => (params ? `${root}/options/create?${params.toString()}` : `${root}/${"options"}/create`),
                    },
                    technicalData: (groupIndex?: number, itemIndex?: number, marketplace?: PdsMarketplace) => {
                        const marketplaceParam = marketplace ? `&tab5.selectedMarketplace=${marketplace}` : ""
                        const groupIndexParam = groupIndex ? `&tab5.selectedTechnicalDataMenuItem.groupIndex=${groupIndex}` : ""
                        const itemIndexParam = itemIndex ? `&tab5.selectedTechnicalDataMenuItem.itemIndex=${itemIndex}` : ""
                        let route = `${root}?selectedTab=5${groupIndexParam}${itemIndexParam}${marketplaceParam}`
                        technicalDataMenuItemGroups.forEach((_, index) => (route += `&tab5.menu.accordion.${index}.isExpanded=${index === groupIndex}`))
                        return route
                    },
                }
            },
        },
    },
    options: {
        catalogue: () => "/options/catalogue",
        customizations: buildProductRoutes("/options/customizations", (key: { type: OptionCustomizationTypeDTO; key: string }) => `${key.type}/${key.key}`),
        tags: buildProductRoutesWithSort("/options/tags", (key: { type: OptionTagTypeDTO; key: string }) => `${key.type}/${key.key}`),
        colors: buildProductRoutes("/options/colors", defaultKeyToPath),
    },
    customColors: {
        exterior: buildProductRoutes("/custom-colors/exterior", defaultKeyToPath),
        interior: buildProductRoutes("/custom-colors/interior", defaultKeyToPath),
    },
    technicalData: {
        list: () => "/technical-data",
    },
}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectDTOBodyType } from './SelectDTOBodyType';
import {
    SelectDTOBodyTypeFromJSON,
    SelectDTOBodyTypeFromJSONTyped,
    SelectDTOBodyTypeToJSON,
    SelectDTOBodyTypeToJSONTyped,
} from './SelectDTOBodyType';
import type { GroupedSelectDTOString } from './GroupedSelectDTOString';
import {
    GroupedSelectDTOStringFromJSON,
    GroupedSelectDTOStringFromJSONTyped,
    GroupedSelectDTOStringToJSON,
    GroupedSelectDTOStringToJSONTyped,
} from './GroupedSelectDTOString';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';

/**
 * 
 * @export
 * @interface DerivativeAttributesDTO
 */
export interface DerivativeAttributesDTO {
    /**
     * 
     * @type {string}
     * @memberof DerivativeAttributesDTO
     */
    key: string;
    /**
     * 
     * @type {GroupedSelectDTOString}
     * @memberof DerivativeAttributesDTO
     */
    derivativeCategory: GroupedSelectDTOString;
    /**
     * 
     * @type {SelectDTOBodyType}
     * @memberof DerivativeAttributesDTO
     */
    bodyType: SelectDTOBodyType;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof DerivativeAttributesDTO
     */
    name: BaseNameDTO;
}

/**
 * Check if a given object implements the DerivativeAttributesDTO interface.
 */
export function instanceOfDerivativeAttributesDTO(value: object): value is DerivativeAttributesDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('derivativeCategory' in value) || value['derivativeCategory'] === undefined) return false;
    if (!('bodyType' in value) || value['bodyType'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function DerivativeAttributesDTOFromJSON(json: any): DerivativeAttributesDTO {
    return DerivativeAttributesDTOFromJSONTyped(json, false);
}

export function DerivativeAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DerivativeAttributesDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'derivativeCategory': GroupedSelectDTOStringFromJSON(json['derivativeCategory']),
        'bodyType': SelectDTOBodyTypeFromJSON(json['bodyType']),
        'name': BaseNameDTOFromJSON(json['name']),
    };
}

export function DerivativeAttributesDTOToJSON(json: any): DerivativeAttributesDTO {
    return DerivativeAttributesDTOToJSONTyped(json, false);
}

export function DerivativeAttributesDTOToJSONTyped(value?: DerivativeAttributesDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'derivativeCategory': GroupedSelectDTOStringToJSON(value['derivativeCategory']),
        'bodyType': SelectDTOBodyTypeToJSON(value['bodyType']),
        'name': BaseNameDTOToJSON(value['name']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { OptionTagCreateRequestDTOEquipment } from './OptionTagCreateRequestDTOEquipment';
import {
    instanceOfOptionTagCreateRequestDTOEquipment,
    OptionTagCreateRequestDTOEquipmentFromJSON,
    OptionTagCreateRequestDTOEquipmentFromJSONTyped,
    OptionTagCreateRequestDTOEquipmentToJSON,
} from './OptionTagCreateRequestDTOEquipment';
import type { OptionTagCreateRequestDTOExteriorColor } from './OptionTagCreateRequestDTOExteriorColor';
import {
    instanceOfOptionTagCreateRequestDTOExteriorColor,
    OptionTagCreateRequestDTOExteriorColorFromJSON,
    OptionTagCreateRequestDTOExteriorColorFromJSONTyped,
    OptionTagCreateRequestDTOExteriorColorToJSON,
} from './OptionTagCreateRequestDTOExteriorColor';
import type { OptionTagCreateRequestDTOInteriorColor } from './OptionTagCreateRequestDTOInteriorColor';
import {
    instanceOfOptionTagCreateRequestDTOInteriorColor,
    OptionTagCreateRequestDTOInteriorColorFromJSON,
    OptionTagCreateRequestDTOInteriorColorFromJSONTyped,
    OptionTagCreateRequestDTOInteriorColorToJSON,
} from './OptionTagCreateRequestDTOInteriorColor';
import type { OptionTagCreateRequestDTORoofColor } from './OptionTagCreateRequestDTORoofColor';
import {
    instanceOfOptionTagCreateRequestDTORoofColor,
    OptionTagCreateRequestDTORoofColorFromJSON,
    OptionTagCreateRequestDTORoofColorFromJSONTyped,
    OptionTagCreateRequestDTORoofColorToJSON,
} from './OptionTagCreateRequestDTORoofColor';

/**
 * @type OptionTagCreateRequestDTO
 * 
 * @export
 */
export type OptionTagCreateRequestDTO = { type: 'Equipment' } & OptionTagCreateRequestDTOEquipment | { type: 'ExteriorColor' } & OptionTagCreateRequestDTOExteriorColor | { type: 'InteriorColor' } & OptionTagCreateRequestDTOInteriorColor | { type: 'RoofColor' } & OptionTagCreateRequestDTORoofColor;

export function OptionTagCreateRequestDTOFromJSON(json: any): OptionTagCreateRequestDTO {
    return OptionTagCreateRequestDTOFromJSONTyped(json, false);
}

export function OptionTagCreateRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagCreateRequestDTO {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return Object.assign({}, OptionTagCreateRequestDTOEquipmentFromJSONTyped(json, true), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionTagCreateRequestDTOExteriorColorFromJSONTyped(json, true), { type: 'ExteriorColor' } as const);
        case 'InteriorColor':
            return Object.assign({}, OptionTagCreateRequestDTOInteriorColorFromJSONTyped(json, true), { type: 'InteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionTagCreateRequestDTORoofColorFromJSONTyped(json, true), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionTagCreateRequestDTO exists with 'type=${json['type']}'`);
    }
}

export function OptionTagCreateRequestDTOToJSON(json: any): any {
    return OptionTagCreateRequestDTOToJSONTyped(json, false);
}

export function OptionTagCreateRequestDTOToJSONTyped(value?: OptionTagCreateRequestDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'Equipment':
            return Object.assign({}, OptionTagCreateRequestDTOEquipmentToJSON(value), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionTagCreateRequestDTOExteriorColorToJSON(value), { type: 'ExteriorColor' } as const);
        case 'InteriorColor':
            return Object.assign({}, OptionTagCreateRequestDTOInteriorColorToJSON(value), { type: 'InteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionTagCreateRequestDTORoofColorToJSON(value), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionTagCreateRequestDTO exists with 'type=${value['type']}'`);
    }

}


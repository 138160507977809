import { propertiesOf } from "../../../../../../common/property"
import { routes } from "../../../../../../common/routes"
import { getSimpleFormWatch } from "../../../../../../components/formelements/FormDisplayData"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { getBoolFormElement } from "../../../../../../components/formelements/boolformelement/BoolFormElement"
import { getDateFormElement } from "../../../../../../components/formelements/dateformelement/DateFormElement"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { getTagFormElement } from "../../../../../../components/formelements/tagformelement/TagFormElement"
import { toTagOption } from "../../../../../../components/formelements/tagformelement/TagOption"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { ModelGenerationAttributesDTO, ModelGenerationAttributesUpdateDTO } from "../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { FC } from "react"

export const ModelGenerationDetailsAttributesCardContent: FC<
    ProductDetailsCardContentProps<ModelGenerationAttributesDTO, ModelGenerationAttributesUpdateDTO>
> = ({ formDisplayData }) => {
    const properties = propertiesOf<ModelGenerationAttributesUpdateDTO>()
    const watch = getSimpleFormWatch(formDisplayData)
    const modelSeriesKey = watch("modelSeriesKey")

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <FormElementWrapper
                    label={"Code"}
                    formDisplayData={formDisplayData}
                    field={properties.key}
                    FormElement={getTextFormElement<ModelGenerationAttributesDTO, ModelGenerationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.key,
                        showAsHint: false,
                    })}
                    getEditProps={(data) => ({
                        value: data.key,
                        readOnly: true,
                    })}
                />

                <FormElementWrapper
                    label={"Model Series"}
                    formDisplayData={formDisplayData}
                    field={properties.modelSeriesKey}
                    FormElement={getTagFormElement<ModelGenerationAttributesDTO, ModelGenerationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        tag: {
                            label: data.modelSeries.value.label,
                            route: routes.models.modelSeries.details(data.modelSeries.value.key),
                        },
                    })}
                    getEditProps={(data) => ({
                        value: data.modelSeries.value.key,
                        options: data.modelSeries.options.map(toOption),
                    })}
                />

                <FormElementWrapper
                    label={"Facelift Of"}
                    formDisplayData={formDisplayData}
                    field={properties.faceliftOf}
                    FormElement={getTagFormElement<ModelGenerationAttributesDTO, ModelGenerationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        tag: data.faceliftOf.value && toTagOption(data.faceliftOf.value, routes.models.modelGenerations.details),
                    })}
                    getEditProps={(data) => ({
                        value: data.faceliftOf.value?.key ?? "",
                        options: [
                            {
                                value: "",
                                label: "-",
                            },
                            ...data.faceliftOf.relatedOptions.filter((modelGeneration) => modelGeneration.relatedKey === modelSeriesKey).map(toOption),
                        ],
                        optional: true,
                    })}
                />
            </RowGroupWrapper>

            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getViewProps={(data) => ({
                    value: data.name,
                })}
                getEditProps={(data) => ({
                    value: data.name,
                })}
            />

            <RowGroupWrapper label={"Status"}>
                <FormElementWrapper
                    label={"Classic"}
                    formDisplayData={formDisplayData}
                    field={properties.classic}
                    FormElement={getBoolFormElement<ModelGenerationAttributesDTO, ModelGenerationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        falseMessage: "No",
                        trueMessage: "Yes",
                        value: data.classic,
                    })}
                    getEditProps={(data) => ({
                        falseMessage: "No",
                        trueMessage: "Yes",
                        value: data.classic,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper label={"Production Period"}>
                <FormElementWrapper
                    label={"Point of Sales Date"}
                    formDisplayData={formDisplayData}
                    field={properties.pointOfSalesDate}
                    FormElement={getDateFormElement<ModelGenerationAttributesDTO, ModelGenerationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.pointOfSalesDate,
                    })}
                    getEditProps={(data) => ({
                        value: data.pointOfSalesDate,
                        optional: true,
                    })}
                />
                <FormElementWrapper
                    label={"End of Production Date"}
                    formDisplayData={formDisplayData}
                    field={properties.endOfProductionDate}
                    FormElement={getDateFormElement<ModelGenerationAttributesDTO, ModelGenerationAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.endOfProductionDate,
                    })}
                    getEditProps={(data) => ({
                        value: data.endOfProductionDate,
                        optional: true,
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}

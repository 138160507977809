/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseInformationDTO,
  DerivativeAttributesDTO,
  DerivativeAttributesUpdateDTO,
  DerivativeLinksDTO,
  LocalizationsItemDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    DerivativeAttributesDTOFromJSON,
    DerivativeAttributesDTOToJSON,
    DerivativeAttributesUpdateDTOFromJSON,
    DerivativeAttributesUpdateDTOToJSON,
    DerivativeLinksDTOFromJSON,
    DerivativeLinksDTOToJSON,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface DeleteDerivativeRequest {
    key: string;
}

export interface GetAttributesOfDerivativeRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfDerivativeRequest {
    key: string;
    languageTag: string;
}

export interface GetLinksOfDerivativeRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfNameOfDerivativeRequest {
    key: string;
}

export interface UpdateAttributesOfDerivativeRequest {
    key: string;
    derivativeAttributesUpdateDTO: DerivativeAttributesUpdateDTO;
}

export interface UpdateNameOfDerivativeRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class DerivativeDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a Derivative by a given key
     */
    async deleteDerivativeRaw(requestParameters: DeleteDerivativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling deleteDerivative().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Derivative by a given key
     */
    async deleteDerivative(requestParameters: DeleteDerivativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDerivativeRaw(requestParameters, initOverrides);
    }

    /**
     * Get attributes of Derivative with given key
     */
    async getAttributesOfDerivativeRaw(requestParameters: GetAttributesOfDerivativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DerivativeAttributesDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getAttributesOfDerivative().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getAttributesOfDerivative().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DerivativeAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of Derivative with given key
     */
    async getAttributesOfDerivative(requestParameters: GetAttributesOfDerivativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DerivativeAttributesDTO> {
        const response = await this.getAttributesOfDerivativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get baseInformation of Derivative with given key
     */
    async getBaseInformationOfDerivativeRaw(requestParameters: GetBaseInformationOfDerivativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getBaseInformationOfDerivative().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getBaseInformationOfDerivative().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of Derivative with given key
     */
    async getBaseInformationOfDerivative(requestParameters: GetBaseInformationOfDerivativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfDerivativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get links of Derivative with given key
     */
    async getLinksOfDerivativeRaw(requestParameters: GetLinksOfDerivativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DerivativeLinksDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getLinksOfDerivative().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getLinksOfDerivative().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/details/{key}/links`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DerivativeLinksDTOFromJSON(jsonValue));
    }

    /**
     * Get links of Derivative with given key
     */
    async getLinksOfDerivative(requestParameters: GetLinksOfDerivativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DerivativeLinksDTO> {
        const response = await this.getLinksOfDerivativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get localizationsOfName of Derivative with given key
     */
    async getLocalizationsOfNameOfDerivativeRaw(requestParameters: GetLocalizationsOfNameOfDerivativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getLocalizationsOfNameOfDerivative().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of Derivative with given key
     */
    async getLocalizationsOfNameOfDerivative(requestParameters: GetLocalizationsOfNameOfDerivativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfDerivativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates attributes of an existing Derivative
     */
    async updateAttributesOfDerivativeRaw(requestParameters: UpdateAttributesOfDerivativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateAttributesOfDerivative().'
            );
        }

        if (requestParameters['derivativeAttributesUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'derivativeAttributesUpdateDTO',
                'Required parameter "derivativeAttributesUpdateDTO" was null or undefined when calling updateAttributesOfDerivative().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/derivatives/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DerivativeAttributesUpdateDTOToJSON(requestParameters['derivativeAttributesUpdateDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing Derivative
     */
    async updateAttributesOfDerivative(requestParameters: UpdateAttributesOfDerivativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAttributesOfDerivativeRaw(requestParameters, initOverrides);
    }

    /**
     * Updates name of an existing Derivative
     */
    async updateNameOfDerivativeRaw(requestParameters: UpdateNameOfDerivativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateNameOfDerivative().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateNameOfDerivative().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/derivatives/details/{key}/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing Derivative
     */
    async updateNameOfDerivative(requestParameters: UpdateNameOfDerivativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNameOfDerivativeRaw(requestParameters, initOverrides);
    }

}

import { FunctionComponent } from "react"
import { TableFilterProps } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { OrderTypeOptionFilterOptionsDTO } from "../../../../../../../generated/pdsapi"
import { OrderTypeOptionFilter } from "../../../../../../../pdsapi"
import { ColumnGroupWrapper } from "../../../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { ColumnGroupsWrapper } from "../../../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { MultiSelectFilter } from "../../../../../../../components/filter/MultiSelectFilter"
import { GroupedMultiSelectFilter } from "../../../../../../../components/filter/GroupedMultiSelectFilter"

export const OrderTypeOptionItemsCardFilter: FunctionComponent<TableFilterProps<OrderTypeOptionFilter, OrderTypeOptionFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const equipmentTagFilter = (
        <GroupedMultiSelectFilter
            label={"Equipment Tags"}
            options={filterOptions?.categorizedEquipmentTags}
            value={filter.equipmentTagKeys}
            onChange={(equipmentTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    equipmentTagKeys,
                }))
            }
        />
    )
    const interiorColorTagFilter = (
        <MultiSelectFilter
            label={"Interior Color Tags"}
            options={filterOptions?.interiorColorTags}
            value={filter.interiorColorTagKeys}
            onChange={(interiorColorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    interiorColorTagKeys,
                }))
            }
        />
    )
    const exteriorColorTagFilter = (
        <MultiSelectFilter
            label={"Exterior Color Tags"}
            options={filterOptions?.exteriorColorTags}
            value={filter.exteriorColorTagKeys}
            onChange={(exteriorColorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    exteriorColorTagKeys,
                }))
            }
        />
    )
    const roofColorTagFilter = (
        <MultiSelectFilter
            label={"Roof Color Tags"}
            options={filterOptions?.roofColorTags}
            value={filter.roofColorTagKeys}
            onChange={(roofColorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    roofColorTagKeys,
                }))
            }
        />
    )
    const vehicleEquipmentCategoryFilter = (
        <MultiSelectFilter
            label={"Vehicle Equipment Category"}
            options={filterOptions?.vehicleEquipmentCategories}
            value={filter.vehicleEquipmentCategories}
            onChange={(vehicleEquipmentCategories) =>
                setFilter((filter) => ({
                    ...filter,
                    vehicleEquipmentCategories,
                }))
            }
        />
    )
    const equipmentFamilyFilter = (
        <MultiSelectFilter
            label={"Equipment Families"}
            options={filterOptions?.equipmentFamilies?.map((family) => ({ key: family, label: family }))}
            value={filter.equipmentFamilies}
            onChange={(equipmentFamilies) =>
                setFilter((filter) => ({
                    ...filter,
                    equipmentFamilies,
                }))
            }
        />
    )
    const interiorMaterialFilter = (
        <MultiSelectFilter
            label={"Interior Materials"}
            options={filterOptions?.interiorMaterials}
            value={filter.interiorMaterials}
            onChange={(interiorMaterials) =>
                setFilter((filter) => ({
                    ...filter,
                    interiorMaterials,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Tag"}>
                {equipmentTagFilter}
                {interiorColorTagFilter}
                {exteriorColorTagFilter}
                {roofColorTagFilter}
            </ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Other"}>
                {vehicleEquipmentCategoryFilter}
                {equipmentFamilyFilter}
                {interiorMaterialFilter}
            </ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}

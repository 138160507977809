/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PageDTOTechnicalDataItemDTO,
  PdsMarketplace,
  SortDirection,
  TechnicalDataAttribute,
  TechnicalDataAttributeDataDTO,
  TechnicalDataFilterOptionsDTO,
  TechnicalDataSortField,
} from '../models/index';
import {
    PageDTOTechnicalDataItemDTOFromJSON,
    PageDTOTechnicalDataItemDTOToJSON,
    PdsMarketplaceFromJSON,
    PdsMarketplaceToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    TechnicalDataAttributeFromJSON,
    TechnicalDataAttributeToJSON,
    TechnicalDataAttributeDataDTOFromJSON,
    TechnicalDataAttributeDataDTOToJSON,
    TechnicalDataFilterOptionsDTOFromJSON,
    TechnicalDataFilterOptionsDTOToJSON,
    TechnicalDataSortFieldFromJSON,
    TechnicalDataSortFieldToJSON,
} from '../models/index';

export interface GetTechnicalDataFilterOptionsRequest {
    languageTag: string;
}

export interface GetTechnicalDataPageRequest {
    technicalDataAttribute: TechnicalDataAttribute;
    languageTag: string;
    offset: number;
    size: number;
    sortField: TechnicalDataSortField;
    sortDirection: SortDirection;
    marketplace?: PdsMarketplace;
    search?: string;
    modelSeriesKeys?: Set<string>;
    modelGenerationKeys?: Set<string>;
    modelYears?: Set<number>;
    orderTypeKeys?: Set<string>;
}

/**
 * 
 */
export class TechnicalDataListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get column definitions for given technical data attribute
     */
    async getTechnicalDataAttributeOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: TechnicalDataAttributeDataDTO; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/technical-data/list/technical-data-attribute-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, TechnicalDataAttributeDataDTOFromJSON));
    }

    /**
     * Get column definitions for given technical data attribute
     */
    async getTechnicalDataAttributeOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: TechnicalDataAttributeDataDTO; }> {
        const response = await this.getTechnicalDataAttributeOptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get options to filter TechnicalData
     */
    async getTechnicalDataFilterOptionsRaw(requestParameters: GetTechnicalDataFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TechnicalDataFilterOptionsDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getTechnicalDataFilterOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/technical-data/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicalDataFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter TechnicalData
     */
    async getTechnicalDataFilterOptions(requestParameters: GetTechnicalDataFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TechnicalDataFilterOptionsDTO> {
        const response = await this.getTechnicalDataFilterOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get page of TechnicalData
     */
    async getTechnicalDataPageRaw(requestParameters: GetTechnicalDataPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOTechnicalDataItemDTO>> {
        if (requestParameters['technicalDataAttribute'] == null) {
            throw new runtime.RequiredError(
                'technicalDataAttribute',
                'Required parameter "technicalDataAttribute" was null or undefined when calling getTechnicalDataPage().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getTechnicalDataPage().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getTechnicalDataPage().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getTechnicalDataPage().'
            );
        }

        if (requestParameters['sortField'] == null) {
            throw new runtime.RequiredError(
                'sortField',
                'Required parameter "sortField" was null or undefined when calling getTechnicalDataPage().'
            );
        }

        if (requestParameters['sortDirection'] == null) {
            throw new runtime.RequiredError(
                'sortDirection',
                'Required parameter "sortDirection" was null or undefined when calling getTechnicalDataPage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['technicalDataAttribute'] != null) {
            queryParameters['technicalDataAttribute'] = requestParameters['technicalDataAttribute'];
        }

        if (requestParameters['marketplace'] != null) {
            queryParameters['marketplace'] = requestParameters['marketplace'];
        }

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sortField'] != null) {
            queryParameters['sortField'] = requestParameters['sortField'];
        }

        if (requestParameters['sortDirection'] != null) {
            queryParameters['sortDirection'] = requestParameters['sortDirection'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['modelSeriesKeys'] != null) {
            queryParameters['modelSeriesKeys'] = requestParameters['modelSeriesKeys'];
        }

        if (requestParameters['modelGenerationKeys'] != null) {
            queryParameters['modelGenerationKeys'] = requestParameters['modelGenerationKeys'];
        }

        if (requestParameters['modelYears'] != null) {
            queryParameters['modelYears'] = requestParameters['modelYears'];
        }

        if (requestParameters['orderTypeKeys'] != null) {
            queryParameters['orderTypeKeys'] = requestParameters['orderTypeKeys'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/technical-data/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOTechnicalDataItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of TechnicalData
     */
    async getTechnicalDataPage(requestParameters: GetTechnicalDataPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOTechnicalDataItemDTO> {
        const response = await this.getTechnicalDataPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

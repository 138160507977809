/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOExteriorColorValue } from './OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOExteriorColorValue';
import {
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOExteriorColorValueFromJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOExteriorColorValueFromJSONTyped,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOExteriorColorValueToJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOExteriorColorValueToJSONTyped,
} from './OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOExteriorColorValue';
import type { OptionCustomizationAttributesUpdateDTOExteriorColorValue } from './OptionCustomizationAttributesUpdateDTOExteriorColorValue';
import {
    OptionCustomizationAttributesUpdateDTOExteriorColorValueFromJSON,
    OptionCustomizationAttributesUpdateDTOExteriorColorValueFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOExteriorColorValueToJSON,
    OptionCustomizationAttributesUpdateDTOExteriorColorValueToJSONTyped,
} from './OptionCustomizationAttributesUpdateDTOExteriorColorValue';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesUpdateDTOExteriorColor
 */
export interface OptionCustomizationAttributesUpdateDTOExteriorColor {
    /**
     * 
     * @type {OptionCustomizationAttributesUpdateDTOExteriorColorValue}
     * @memberof OptionCustomizationAttributesUpdateDTOExteriorColor
     */
    defaultValue: OptionCustomizationAttributesUpdateDTOExteriorColorValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesUpdateDTOExteriorColor
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationAttributesUpdateDTOExteriorColor
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOExteriorColorValue>}
     * @memberof OptionCustomizationAttributesUpdateDTOExteriorColor
     */
    conditionalValues: Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOExteriorColorValue>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesUpdateDTOExteriorColor
     */
    isDefaultValueActive: boolean;
}

/**
 * Check if a given object implements the OptionCustomizationAttributesUpdateDTOExteriorColor interface.
 */
export function instanceOfOptionCustomizationAttributesUpdateDTOExteriorColor(value: object): value is OptionCustomizationAttributesUpdateDTOExteriorColor {
    if (!('defaultValue' in value) || value['defaultValue'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelGenerationKeys' in value) || value['modelGenerationKeys'] === undefined) return false;
    if (!('conditionalValues' in value) || value['conditionalValues'] === undefined) return false;
    if (!('isDefaultValueActive' in value) || value['isDefaultValueActive'] === undefined) return false;
    return true;
}

export function OptionCustomizationAttributesUpdateDTOExteriorColorFromJSON(json: any): OptionCustomizationAttributesUpdateDTOExteriorColor {
    return OptionCustomizationAttributesUpdateDTOExteriorColorFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTOExteriorColor {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTOExteriorColorValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOExteriorColorValueFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
    };
}

export function OptionCustomizationAttributesUpdateDTOExteriorColorToJSON(json: any): OptionCustomizationAttributesUpdateDTOExteriorColor {
    return OptionCustomizationAttributesUpdateDTOExteriorColorToJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOExteriorColorToJSONTyped(value?: OptionCustomizationAttributesUpdateDTOExteriorColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTOExteriorColorValueToJSON(value['defaultValue']),
        'optionCode': value['optionCode'],
        'modelGenerationKeys': value['modelGenerationKeys'],
        'conditionalValues': ((value['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOExteriorColorValueToJSON)),
        'isDefaultValueActive': value['isDefaultValueActive'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MissingOptionCustomizationDTOExteriorColorCustomization
 */
export interface MissingOptionCustomizationDTOExteriorColorCustomization {
    /**
     * 
     * @type {string}
     * @memberof MissingOptionCustomizationDTOExteriorColorCustomization
     */
    key: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MissingOptionCustomizationDTOExteriorColorCustomization
     */
    modelGenerationNames: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MissingOptionCustomizationDTOExteriorColorCustomization
     */
    hexCode: string;
    /**
     * 
     * @type {string}
     * @memberof MissingOptionCustomizationDTOExteriorColorCustomization
     */
    colorTypeName?: string;
}

/**
 * Check if a given object implements the MissingOptionCustomizationDTOExteriorColorCustomization interface.
 */
export function instanceOfMissingOptionCustomizationDTOExteriorColorCustomization(value: object): value is MissingOptionCustomizationDTOExteriorColorCustomization {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('modelGenerationNames' in value) || value['modelGenerationNames'] === undefined) return false;
    if (!('hexCode' in value) || value['hexCode'] === undefined) return false;
    return true;
}

export function MissingOptionCustomizationDTOExteriorColorCustomizationFromJSON(json: any): MissingOptionCustomizationDTOExteriorColorCustomization {
    return MissingOptionCustomizationDTOExteriorColorCustomizationFromJSONTyped(json, false);
}

export function MissingOptionCustomizationDTOExteriorColorCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissingOptionCustomizationDTOExteriorColorCustomization {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelGenerationNames': json['modelGenerationNames'],
        'hexCode': json['hexCode'],
        'colorTypeName': json['colorTypeName'] == null ? undefined : json['colorTypeName'],
    };
}

export function MissingOptionCustomizationDTOExteriorColorCustomizationToJSON(json: any): MissingOptionCustomizationDTOExteriorColorCustomization {
    return MissingOptionCustomizationDTOExteriorColorCustomizationToJSONTyped(json, false);
}

export function MissingOptionCustomizationDTOExteriorColorCustomizationToJSONTyped(value?: MissingOptionCustomizationDTOExteriorColorCustomization | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'modelGenerationNames': value['modelGenerationNames'],
        'hexCode': value['hexCode'],
        'colorTypeName': value['colorTypeName'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOInteriorMaterial } from './SelectOptionDTOInteriorMaterial';
import {
    SelectOptionDTOInteriorMaterialFromJSON,
    SelectOptionDTOInteriorMaterialFromJSONTyped,
    SelectOptionDTOInteriorMaterialToJSON,
    SelectOptionDTOInteriorMaterialToJSONTyped,
} from './SelectOptionDTOInteriorMaterial';

/**
 * 
 * @export
 * @interface SelectDTOInteriorMaterial
 */
export interface SelectDTOInteriorMaterial {
    /**
     * 
     * @type {SelectOptionDTOInteriorMaterial}
     * @memberof SelectDTOInteriorMaterial
     */
    value: SelectOptionDTOInteriorMaterial;
    /**
     * 
     * @type {Array<SelectOptionDTOInteriorMaterial>}
     * @memberof SelectDTOInteriorMaterial
     */
    options: Array<SelectOptionDTOInteriorMaterial>;
}

/**
 * Check if a given object implements the SelectDTOInteriorMaterial interface.
 */
export function instanceOfSelectDTOInteriorMaterial(value: object): value is SelectDTOInteriorMaterial {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function SelectDTOInteriorMaterialFromJSON(json: any): SelectDTOInteriorMaterial {
    return SelectDTOInteriorMaterialFromJSONTyped(json, false);
}

export function SelectDTOInteriorMaterialFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectDTOInteriorMaterial {
    if (json == null) {
        return json;
    }
    return {
        
        'value': SelectOptionDTOInteriorMaterialFromJSON(json['value']),
        'options': ((json['options'] as Array<any>).map(SelectOptionDTOInteriorMaterialFromJSON)),
    };
}

export function SelectDTOInteriorMaterialToJSON(json: any): SelectDTOInteriorMaterial {
    return SelectDTOInteriorMaterialToJSONTyped(json, false);
}

export function SelectDTOInteriorMaterialToJSONTyped(value?: SelectDTOInteriorMaterial | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': SelectOptionDTOInteriorMaterialToJSON(value['value']),
        'options': ((value['options'] as Array<any>).map(SelectOptionDTOInteriorMaterialToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SelectOptionDTOInteger
 */
export interface SelectOptionDTOInteger {
    /**
     * 
     * @type {number}
     * @memberof SelectOptionDTOInteger
     */
    key: number;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDTOInteger
     */
    label: string;
}

/**
 * Check if a given object implements the SelectOptionDTOInteger interface.
 */
export function instanceOfSelectOptionDTOInteger(value: object): value is SelectOptionDTOInteger {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function SelectOptionDTOIntegerFromJSON(json: any): SelectOptionDTOInteger {
    return SelectOptionDTOIntegerFromJSONTyped(json, false);
}

export function SelectOptionDTOIntegerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectOptionDTOInteger {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'label': json['label'],
    };
}

export function SelectOptionDTOIntegerToJSON(json: any): SelectOptionDTOInteger {
    return SelectOptionDTOIntegerToJSONTyped(json, false);
}

export function SelectOptionDTOIntegerToJSONTyped(value?: SelectOptionDTOInteger | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'label': value['label'],
    };
}


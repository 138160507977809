/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MasterDataSource = {
    PCCD: 'PCCD',
    IPLONE: 'IPLONE',
    VIS: 'VIS',
    MANUAL: 'MANUAL'
} as const;
export type MasterDataSource = typeof MasterDataSource[keyof typeof MasterDataSource];


export function instanceOfMasterDataSource(value: any): boolean {
    for (const key in MasterDataSource) {
        if (Object.prototype.hasOwnProperty.call(MasterDataSource, key)) {
            if (MasterDataSource[key as keyof typeof MasterDataSource] === value) {
                return true;
            }
        }
    }
    return false;
}

export function MasterDataSourceFromJSON(json: any): MasterDataSource {
    return MasterDataSourceFromJSONTyped(json, false);
}

export function MasterDataSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): MasterDataSource {
    return json as MasterDataSource;
}

export function MasterDataSourceToJSON(value?: MasterDataSource | null): any {
    return value as any;
}

export function MasterDataSourceToJSONTyped(value: any, ignoreDiscriminator: boolean): MasterDataSource {
    return value as MasterDataSource;
}


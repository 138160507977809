/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationAttributesDTOEquipmentValue } from './OptionCustomizationAttributesDTOEquipmentValue';
import {
    OptionCustomizationAttributesDTOEquipmentValueFromJSON,
    OptionCustomizationAttributesDTOEquipmentValueFromJSONTyped,
    OptionCustomizationAttributesDTOEquipmentValueToJSON,
    OptionCustomizationAttributesDTOEquipmentValueToJSONTyped,
} from './OptionCustomizationAttributesDTOEquipmentValue';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';
import type { OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValue } from './OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValue';
import {
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValueFromJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValueFromJSONTyped,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValueToJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValueToJSONTyped,
} from './OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValue';
import type { MultiSelectDTOString } from './MultiSelectDTOString';
import {
    MultiSelectDTOStringFromJSON,
    MultiSelectDTOStringFromJSONTyped,
    MultiSelectDTOStringToJSON,
    MultiSelectDTOStringToJSONTyped,
} from './MultiSelectDTOString';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesDTOEquipment
 */
export interface OptionCustomizationAttributesDTOEquipment {
    /**
     * 
     * @type {OptionCustomizationAttributesDTOEquipmentValue}
     * @memberof OptionCustomizationAttributesDTOEquipment
     */
    defaultValue: OptionCustomizationAttributesDTOEquipmentValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOEquipment
     */
    optionCode: string;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValue>}
     * @memberof OptionCustomizationAttributesDTOEquipment
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValue>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesDTOEquipment
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {MultiSelectDTOString}
     * @memberof OptionCustomizationAttributesDTOEquipment
     */
    modelGenerations: MultiSelectDTOString;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationAttributesDTOEquipment
     */
    derivatives: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the OptionCustomizationAttributesDTOEquipment interface.
 */
export function instanceOfOptionCustomizationAttributesDTOEquipment(value: object): value is OptionCustomizationAttributesDTOEquipment {
    if (!('defaultValue' in value) || value['defaultValue'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('conditionalValues' in value) || value['conditionalValues'] === undefined) return false;
    if (!('isDefaultValueActive' in value) || value['isDefaultValueActive'] === undefined) return false;
    if (!('modelGenerations' in value) || value['modelGenerations'] === undefined) return false;
    if (!('derivatives' in value) || value['derivatives'] === undefined) return false;
    return true;
}

export function OptionCustomizationAttributesDTOEquipmentFromJSON(json: any): OptionCustomizationAttributesDTOEquipment {
    return OptionCustomizationAttributesDTOEquipmentFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOEquipment {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesDTOEquipmentValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValueFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'modelGenerations': MultiSelectDTOStringFromJSON(json['modelGenerations']),
        'derivatives': ((json['derivatives'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function OptionCustomizationAttributesDTOEquipmentToJSON(json: any): OptionCustomizationAttributesDTOEquipment {
    return OptionCustomizationAttributesDTOEquipmentToJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOEquipmentToJSONTyped(value?: OptionCustomizationAttributesDTOEquipment | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultValue': OptionCustomizationAttributesDTOEquipmentValueToJSON(value['defaultValue']),
        'optionCode': value['optionCode'],
        'conditionalValues': ((value['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOEquipmentValueToJSON)),
        'isDefaultValueActive': value['isDefaultValueActive'],
        'modelGenerations': MultiSelectDTOStringToJSON(value['modelGenerations']),
        'derivatives': ((value['derivatives'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { OptionTagItemDTOEquipment } from './OptionTagItemDTOEquipment';
import {
    instanceOfOptionTagItemDTOEquipment,
    OptionTagItemDTOEquipmentFromJSON,
    OptionTagItemDTOEquipmentFromJSONTyped,
    OptionTagItemDTOEquipmentToJSON,
} from './OptionTagItemDTOEquipment';
import type { OptionTagItemDTOExteriorColor } from './OptionTagItemDTOExteriorColor';
import {
    instanceOfOptionTagItemDTOExteriorColor,
    OptionTagItemDTOExteriorColorFromJSON,
    OptionTagItemDTOExteriorColorFromJSONTyped,
    OptionTagItemDTOExteriorColorToJSON,
} from './OptionTagItemDTOExteriorColor';
import type { OptionTagItemDTOInteriorColor } from './OptionTagItemDTOInteriorColor';
import {
    instanceOfOptionTagItemDTOInteriorColor,
    OptionTagItemDTOInteriorColorFromJSON,
    OptionTagItemDTOInteriorColorFromJSONTyped,
    OptionTagItemDTOInteriorColorToJSON,
} from './OptionTagItemDTOInteriorColor';
import type { OptionTagItemDTORoofColor } from './OptionTagItemDTORoofColor';
import {
    instanceOfOptionTagItemDTORoofColor,
    OptionTagItemDTORoofColorFromJSON,
    OptionTagItemDTORoofColorFromJSONTyped,
    OptionTagItemDTORoofColorToJSON,
} from './OptionTagItemDTORoofColor';

/**
 * @type OptionTagItemDTO
 * 
 * @export
 */
export type OptionTagItemDTO = { type: 'Equipment' } & OptionTagItemDTOEquipment | { type: 'ExteriorColor' } & OptionTagItemDTOExteriorColor | { type: 'InteriorColor' } & OptionTagItemDTOInteriorColor | { type: 'RoofColor' } & OptionTagItemDTORoofColor;

export function OptionTagItemDTOFromJSON(json: any): OptionTagItemDTO {
    return OptionTagItemDTOFromJSONTyped(json, false);
}

export function OptionTagItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagItemDTO {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return Object.assign({}, OptionTagItemDTOEquipmentFromJSONTyped(json, true), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionTagItemDTOExteriorColorFromJSONTyped(json, true), { type: 'ExteriorColor' } as const);
        case 'InteriorColor':
            return Object.assign({}, OptionTagItemDTOInteriorColorFromJSONTyped(json, true), { type: 'InteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionTagItemDTORoofColorFromJSONTyped(json, true), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionTagItemDTO exists with 'type=${json['type']}'`);
    }
}

export function OptionTagItemDTOToJSON(json: any): any {
    return OptionTagItemDTOToJSONTyped(json, false);
}

export function OptionTagItemDTOToJSONTyped(value?: OptionTagItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'Equipment':
            return Object.assign({}, OptionTagItemDTOEquipmentToJSON(value), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionTagItemDTOExteriorColorToJSON(value), { type: 'ExteriorColor' } as const);
        case 'InteriorColor':
            return Object.assign({}, OptionTagItemDTOInteriorColorToJSON(value), { type: 'InteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionTagItemDTORoofColorToJSON(value), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionTagItemDTO exists with 'type=${value['type']}'`);
    }

}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  KeyDTO,
  ModelGenerationCreateOptionsDTO,
  ModelGenerationCreateRequestDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    KeyDTOFromJSON,
    KeyDTOToJSON,
    ModelGenerationCreateOptionsDTOFromJSON,
    ModelGenerationCreateOptionsDTOToJSON,
    ModelGenerationCreateRequestDTOFromJSON,
    ModelGenerationCreateRequestDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface CreateModelGenerationRequest {
    modelGenerationCreateRequestDTO: ModelGenerationCreateRequestDTO;
}

export interface GetModelGenerationCreateOptionsRequest {
    languageTag: string;
}

/**
 * 
 */
export class ModelGenerationCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new ModelGeneration
     */
    async createModelGenerationRaw(requestParameters: CreateModelGenerationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KeyDTO>> {
        if (requestParameters['modelGenerationCreateRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'modelGenerationCreateRequestDTO',
                'Required parameter "modelGenerationCreateRequestDTO" was null or undefined when calling createModelGeneration().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/model-generations/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModelGenerationCreateRequestDTOToJSON(requestParameters['modelGenerationCreateRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KeyDTOFromJSON(jsonValue));
    }

    /**
     * Create a new ModelGeneration
     */
    async createModelGeneration(requestParameters: CreateModelGenerationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KeyDTO> {
        const response = await this.createModelGenerationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get select options to create ModelGenerations
     */
    async getModelGenerationCreateOptionsRaw(requestParameters: GetModelGenerationCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelGenerationCreateOptionsDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getModelGenerationCreateOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-generations/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelGenerationCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create ModelGenerations
     */
    async getModelGenerationCreateOptions(requestParameters: GetModelGenerationCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelGenerationCreateOptionsDTO> {
        const response = await this.getModelGenerationCreateOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

import { OptionKeyDTOOptionTypeDTO, OptionsInFamilyDTO } from "../../../../../../../../generated/pdsapi"
import { ProductDetailsTabConfigContentProps } from "../../../../../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard, ProductDetailsCardContentProps } from "../../../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { OptionsInFamilyCardContent } from "./content/OptionsInFamilyCardContent"
import { FC, useCallback } from "react"

interface OptionsInFamilyCardProps {
    getData: (props: OptionKeyDTOOptionTypeDTO & { languageTag: string }) => Promise<OptionsInFamilyDTO>
    getRoute: (key: OptionKeyDTOOptionTypeDTO) => string
}

export const OptionsInFamilyCard: FC<ProductDetailsTabConfigContentProps<OptionKeyDTOOptionTypeDTO> & OptionsInFamilyCardProps> = (props) => {
    const Content: FC<ProductDetailsCardContentProps<OptionsInFamilyDTO, object, OptionKeyDTOOptionTypeDTO>> = useCallback(
        (contentProps) => <OptionsInFamilyCardContent {...contentProps} getRoute={props.getRoute} />,
        [props.getRoute]
    )

    return <ProductDetailsCard<OptionsInFamilyDTO, object, OptionKeyDTOOptionTypeDTO> {...props} label={"Options in Family"} Content={Content} />
}

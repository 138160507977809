/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOBodyType } from './SelectOptionDTOBodyType';
import {
    SelectOptionDTOBodyTypeFromJSON,
    SelectOptionDTOBodyTypeFromJSONTyped,
    SelectOptionDTOBodyTypeToJSON,
    SelectOptionDTOBodyTypeToJSONTyped,
} from './SelectOptionDTOBodyType';

/**
 * 
 * @export
 * @interface SelectDTOBodyType
 */
export interface SelectDTOBodyType {
    /**
     * 
     * @type {SelectOptionDTOBodyType}
     * @memberof SelectDTOBodyType
     */
    value: SelectOptionDTOBodyType;
    /**
     * 
     * @type {Array<SelectOptionDTOBodyType>}
     * @memberof SelectDTOBodyType
     */
    options: Array<SelectOptionDTOBodyType>;
}

/**
 * Check if a given object implements the SelectDTOBodyType interface.
 */
export function instanceOfSelectDTOBodyType(value: object): value is SelectDTOBodyType {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function SelectDTOBodyTypeFromJSON(json: any): SelectDTOBodyType {
    return SelectDTOBodyTypeFromJSONTyped(json, false);
}

export function SelectDTOBodyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectDTOBodyType {
    if (json == null) {
        return json;
    }
    return {
        
        'value': SelectOptionDTOBodyTypeFromJSON(json['value']),
        'options': ((json['options'] as Array<any>).map(SelectOptionDTOBodyTypeFromJSON)),
    };
}

export function SelectDTOBodyTypeToJSON(json: any): SelectDTOBodyType {
    return SelectDTOBodyTypeToJSONTyped(json, false);
}

export function SelectDTOBodyTypeToJSONTyped(value?: SelectDTOBodyType | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': SelectOptionDTOBodyTypeToJSON(value['value']),
        'options': ((value['options'] as Array<any>).map(SelectOptionDTOBodyTypeToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObject } from './TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObject';
import {
    TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObjectFromJSON,
    TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObjectFromJSONTyped,
    TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObjectToJSON,
    TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObjectToJSONTyped,
} from './TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObject';
import type { TechnicalDataAttributeDataColumnDefinition } from './TechnicalDataAttributeDataColumnDefinition';
import {
    TechnicalDataAttributeDataColumnDefinitionFromJSON,
    TechnicalDataAttributeDataColumnDefinitionFromJSONTyped,
    TechnicalDataAttributeDataColumnDefinitionToJSON,
    TechnicalDataAttributeDataColumnDefinitionToJSONTyped,
} from './TechnicalDataAttributeDataColumnDefinition';

/**
 * 
 * @export
 * @interface GlobalOrderTypeTechnicalDataAttributeDTO
 */
export interface GlobalOrderTypeTechnicalDataAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof GlobalOrderTypeTechnicalDataAttributeDTO
     */
    attributeName?: string;
    /**
     * 
     * @type {Array<TechnicalDataAttributeDataColumnDefinition>}
     * @memberof GlobalOrderTypeTechnicalDataAttributeDTO
     */
    columnDefinitions: Array<TechnicalDataAttributeDataColumnDefinition>;
    /**
     * 
     * @type {Array<TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObject>}
     * @memberof GlobalOrderTypeTechnicalDataAttributeDTO
     */
    equipmentRelations: Array<TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObject>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GlobalOrderTypeTechnicalDataAttributeDTO
     */
    disclaimer: { [key: string]: string; };
}

/**
 * Check if a given object implements the GlobalOrderTypeTechnicalDataAttributeDTO interface.
 */
export function instanceOfGlobalOrderTypeTechnicalDataAttributeDTO(value: object): value is GlobalOrderTypeTechnicalDataAttributeDTO {
    if (!('columnDefinitions' in value) || value['columnDefinitions'] === undefined) return false;
    if (!('equipmentRelations' in value) || value['equipmentRelations'] === undefined) return false;
    if (!('disclaimer' in value) || value['disclaimer'] === undefined) return false;
    return true;
}

export function GlobalOrderTypeTechnicalDataAttributeDTOFromJSON(json: any): GlobalOrderTypeTechnicalDataAttributeDTO {
    return GlobalOrderTypeTechnicalDataAttributeDTOFromJSONTyped(json, false);
}

export function GlobalOrderTypeTechnicalDataAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GlobalOrderTypeTechnicalDataAttributeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'] == null ? undefined : json['attributeName'],
        'columnDefinitions': ((json['columnDefinitions'] as Array<any>).map(TechnicalDataAttributeDataColumnDefinitionFromJSON)),
        'equipmentRelations': ((json['equipmentRelations'] as Array<any>).map(TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObjectFromJSON)),
        'disclaimer': json['disclaimer'],
    };
}

export function GlobalOrderTypeTechnicalDataAttributeDTOToJSON(json: any): GlobalOrderTypeTechnicalDataAttributeDTO {
    return GlobalOrderTypeTechnicalDataAttributeDTOToJSONTyped(json, false);
}

export function GlobalOrderTypeTechnicalDataAttributeDTOToJSONTyped(value?: GlobalOrderTypeTechnicalDataAttributeDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'attributeName': value['attributeName'],
        'columnDefinitions': ((value['columnDefinitions'] as Array<any>).map(TechnicalDataAttributeDataColumnDefinitionToJSON)),
        'equipmentRelations': ((value['equipmentRelations'] as Array<any>).map(TechnicalDataAttributeEquipmentRelationTechnicalDataAttributeDataValueObjectToJSON)),
        'disclaimer': value['disclaimer'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseInformationDTO,
  CustomInteriorColorAttributesDTO,
  CustomInteriorColorAttributesUpdateDTO,
  LocalizationsItemDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
  VehicleStateSearchResult,
} from '../models/index';
import {
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    CustomInteriorColorAttributesDTOFromJSON,
    CustomInteriorColorAttributesDTOToJSON,
    CustomInteriorColorAttributesUpdateDTOFromJSON,
    CustomInteriorColorAttributesUpdateDTOToJSON,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    VehicleStateSearchResultFromJSON,
    VehicleStateSearchResultToJSON,
} from '../models/index';

export interface DeleteCustomInteriorColorRequest {
    key: string;
}

export interface FindCustomInteriorColorVehicleImagesRequest {
    key: string;
    limit?: number;
}

export interface GetAttributesOfCustomInteriorColorRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfCustomInteriorColorRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfNameOfCustomInteriorColorRequest {
    key: string;
}

export interface UpdateAttributesOfCustomInteriorColorRequest {
    key: string;
    customInteriorColorAttributesUpdateDTO: CustomInteriorColorAttributesUpdateDTO;
}

export interface UpdateNameOfCustomInteriorColorRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class CustomInteriorColorDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a CustomInteriorColor by a given key
     */
    async deleteCustomInteriorColorRaw(requestParameters: DeleteCustomInteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling deleteCustomInteriorColor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-interior-colors/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a CustomInteriorColor by a given key
     */
    async deleteCustomInteriorColor(requestParameters: DeleteCustomInteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCustomInteriorColorRaw(requestParameters, initOverrides);
    }

    /**
     * Find vehicle images that have this CustomInteriorColor
     */
    async findCustomInteriorColorVehicleImagesRaw(requestParameters: FindCustomInteriorColorVehicleImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleStateSearchResult>>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling findCustomInteriorColorVehicleImages().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-interior-colors/details/{key}/vehicle-images`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleStateSearchResultFromJSON));
    }

    /**
     * Find vehicle images that have this CustomInteriorColor
     */
    async findCustomInteriorColorVehicleImages(requestParameters: FindCustomInteriorColorVehicleImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleStateSearchResult>> {
        const response = await this.findCustomInteriorColorVehicleImagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get attributes of CustomInteriorColor with given key
     */
    async getAttributesOfCustomInteriorColorRaw(requestParameters: GetAttributesOfCustomInteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomInteriorColorAttributesDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getAttributesOfCustomInteriorColor().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getAttributesOfCustomInteriorColor().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-interior-colors/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomInteriorColorAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of CustomInteriorColor with given key
     */
    async getAttributesOfCustomInteriorColor(requestParameters: GetAttributesOfCustomInteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomInteriorColorAttributesDTO> {
        const response = await this.getAttributesOfCustomInteriorColorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get baseInformation of CustomInteriorColor with given key
     */
    async getBaseInformationOfCustomInteriorColorRaw(requestParameters: GetBaseInformationOfCustomInteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getBaseInformationOfCustomInteriorColor().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getBaseInformationOfCustomInteriorColor().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-interior-colors/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of CustomInteriorColor with given key
     */
    async getBaseInformationOfCustomInteriorColor(requestParameters: GetBaseInformationOfCustomInteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfCustomInteriorColorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get localizationsOfName of CustomInteriorColor with given key
     */
    async getLocalizationsOfNameOfCustomInteriorColorRaw(requestParameters: GetLocalizationsOfNameOfCustomInteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getLocalizationsOfNameOfCustomInteriorColor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-interior-colors/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of CustomInteriorColor with given key
     */
    async getLocalizationsOfNameOfCustomInteriorColor(requestParameters: GetLocalizationsOfNameOfCustomInteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfCustomInteriorColorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates attributes of an existing CustomInteriorColor
     */
    async updateAttributesOfCustomInteriorColorRaw(requestParameters: UpdateAttributesOfCustomInteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateAttributesOfCustomInteriorColor().'
            );
        }

        if (requestParameters['customInteriorColorAttributesUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'customInteriorColorAttributesUpdateDTO',
                'Required parameter "customInteriorColorAttributesUpdateDTO" was null or undefined when calling updateAttributesOfCustomInteriorColor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/custom-interior-colors/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomInteriorColorAttributesUpdateDTOToJSON(requestParameters['customInteriorColorAttributesUpdateDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing CustomInteriorColor
     */
    async updateAttributesOfCustomInteriorColor(requestParameters: UpdateAttributesOfCustomInteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAttributesOfCustomInteriorColorRaw(requestParameters, initOverrides);
    }

    /**
     * Updates name of an existing CustomInteriorColor
     */
    async updateNameOfCustomInteriorColorRaw(requestParameters: UpdateNameOfCustomInteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateNameOfCustomInteriorColor().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateNameOfCustomInteriorColor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/custom-interior-colors/details/{key}/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing CustomInteriorColor
     */
    async updateNameOfCustomInteriorColor(requestParameters: UpdateNameOfCustomInteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNameOfCustomInteriorColorRaw(requestParameters, initOverrides);
    }

}

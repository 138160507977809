/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Transmission = {
    MANUAL: 'MANUAL',
    TIPTRONIC: 'TIPTRONIC',
    PDK: 'PDK'
} as const;
export type Transmission = typeof Transmission[keyof typeof Transmission];


export function instanceOfTransmission(value: any): boolean {
    for (const key in Transmission) {
        if (Object.prototype.hasOwnProperty.call(Transmission, key)) {
            if (Transmission[key as keyof typeof Transmission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TransmissionFromJSON(json: any): Transmission {
    return TransmissionFromJSONTyped(json, false);
}

export function TransmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transmission {
    return json as Transmission;
}

export function TransmissionToJSON(value?: Transmission | null): any {
    return value as any;
}

export function TransmissionToJSONTyped(value: any, ignoreDiscriminator: boolean): Transmission {
    return value as Transmission;
}


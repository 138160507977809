import { getEnumConverterGroup, getObjectURLParamConverter, numberConverterGroup, stringConverterGroup } from "../../../common/useURLSearchParamState"
import {
    InteriorMaterial,
    OptionClassDTO,
    OptionFilterOptionsDTO,
    OptionItemDTO,
    OptionSortField,
    PageDTOOptionItemDTO,
    SortDirection,
    VehicleEquipmentCategory,
} from "../../../generated/pdsapi"
import { optionApi, OptionFilter } from "../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../viewtemplates/list/ProductListContainer"
import { OptionCatalogueColumnsConfig } from "./columns/OptionCatalogueColumns"
import { OptionCatalogueFilter } from "./filter/OptionCatalogueFilter"
import { OptionCatalogueFilterTagsConfig } from "./filter/OptionCatalogueFilterTagsConfig"
import { routes } from "../../../common/routes"
import { Route } from "react-router-dom"
import { optionClassToOptionTypeEnum } from "../../models/ordertypes/details/options/optionTypeMappings"

export const OptionCatalogueContainerConfig: ProductListContainerConfig<OptionItemDTO, OptionFilter, OptionFilterOptionsDTO, OptionSortField> = {
    listName: "Option Catalogue",
    itemsName: "Options",

    buildDetailsPath: (item) => routes.models.orderTypes.subpages(item.orderTypeKey).options.details(item.optionCode, optionClassToOptionTypeEnum(item.type)),
    buildCreatePath: undefined,
    buildSortPath: undefined,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<OptionFilter>({
        modelGenerationKeys: stringConverterGroup.optionalSet,
        modelYears: numberConverterGroup.optionalSet,
        orderTypeKeys: stringConverterGroup.optionalSet,
        classes: getEnumConverterGroup(OptionClassDTO).optionalSet,
        equipmentTagKeys: stringConverterGroup.optionalSet,
        interiorColorTagKeys: stringConverterGroup.optionalSet,
        exteriorColorTagKeys: stringConverterGroup.optionalSet,
        roofColorTagKeys: stringConverterGroup.optionalSet,
        vehicleEquipmentCategories: getEnumConverterGroup(VehicleEquipmentCategory).optionalSet,
        equipmentFamilies: stringConverterGroup.optionalSet,
        interiorMaterials: getEnumConverterGroup(InteriorMaterial).optionalSet,
    }),
    defaultSorting: {
        sortField: OptionSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(OptionSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: async (queryOptions) => {
        if (Object.keys(queryOptions).length === 5) {
            // we do not load without filter set:
            // fields that do not filter: languageTag, offset, size, sortField, sortDirection
            return {
                totalItemCount: 0,
                content: [],
            } satisfies PageDTOOptionItemDTO
        }

        return optionApi.catalogue.getPage(queryOptions)
    },
    fetchFilterOptions: optionApi.catalogue.getFilterOptions,

    defaultShowsFilter: true,
    Filter: OptionCatalogueFilter,
    filterTagsConfig: OptionCatalogueFilterTagsConfig,

    columnsConfig: OptionCatalogueColumnsConfig,
}

const OptionCatalogueContainer = getProductListContainer(OptionCatalogueContainerConfig)

export const OptionCatalogueRoute = <Route path={routes.options.catalogue()} Component={OptionCatalogueContainer} />

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseInformationDTO,
  OptionTypeDTO,
  SimpleApiError,
} from '../models/index';
import {
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    OptionTypeDTOFromJSON,
    OptionTypeDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
} from '../models/index';

export interface DeleteOptionRequest {
    orderTypeKey: string;
    optionCode: string;
    type: OptionTypeDTO;
}

export interface GetBaseInformationOfOptionRequest {
    orderTypeKey: string;
    optionCode: string;
    type: OptionTypeDTO;
    languageTag: string;
}

/**
 * 
 */
export class OrderTypeOptionDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a Option by a given key
     */
    async deleteOptionRaw(requestParameters: DeleteOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['orderTypeKey'] == null) {
            throw new runtime.RequiredError(
                'orderTypeKey',
                'Required parameter "orderTypeKey" was null or undefined when calling deleteOption().'
            );
        }

        if (requestParameters['optionCode'] == null) {
            throw new runtime.RequiredError(
                'optionCode',
                'Required parameter "optionCode" was null or undefined when calling deleteOption().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling deleteOption().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['optionCode'] != null) {
            queryParameters['optionCode'] = requestParameters['optionCode'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/option`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters['orderTypeKey']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Option by a given key
     */
    async deleteOption(requestParameters: DeleteOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOptionRaw(requestParameters, initOverrides);
    }

    /**
     * Get baseInformation of Option with given key
     */
    async getBaseInformationOfOptionRaw(requestParameters: GetBaseInformationOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters['orderTypeKey'] == null) {
            throw new runtime.RequiredError(
                'orderTypeKey',
                'Required parameter "orderTypeKey" was null or undefined when calling getBaseInformationOfOption().'
            );
        }

        if (requestParameters['optionCode'] == null) {
            throw new runtime.RequiredError(
                'optionCode',
                'Required parameter "optionCode" was null or undefined when calling getBaseInformationOfOption().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getBaseInformationOfOption().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getBaseInformationOfOption().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['optionCode'] != null) {
            queryParameters['optionCode'] = requestParameters['optionCode'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/option/base-information`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters['orderTypeKey']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of Option with given key
     */
    async getBaseInformationOfOption(requestParameters: GetBaseInformationOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfOptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

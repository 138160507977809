import { colorApi } from "../../../../pdsapi"
import { getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLocalizationCard } from "../../../../viewtemplates/details/cards/localizationcard/DetailsLocalizationCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { ColorDetailsAttributesCardContent } from "./data/attributes/ColorDetailsAttributesCardContent"
import { Route, useParams } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { ColorKeyDTO } from "../../../../generated/pdsapi"
import { ColorListContainerConfig } from "../list/ColorListRoute"

export const ColorDetailsContainerConfig: ProductDetailsContainerConfig<ColorKeyDTO> = {
    getBaseInformation: colorApi.details.getBaseInformation,
    deleteByKey: colorApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={colorApi.details.getAttributes}
                        updateData={({ colorCode, update }) =>
                            colorApi.details.updateAttributes({
                                colorCode,
                                colorAttributesUpdateDTO: update,
                            })
                        }
                        Content={ColorDetailsAttributesCardContent}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={colorApi.details.getNameLocalizations}
                        updateData={(update) =>
                            colorApi.details.updateName({
                                colorCode: update.colorCode,
                                requestBody: update.update,
                            })
                        }
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Description",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={colorApi.details.getDescriptionLocalizations}
                        updateData={(update) =>
                            colorApi.details.updateDescription({
                                colorCode: update.colorCode,
                                requestBody: update.update,
                            })
                        }
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

const ColorDetailsContainer = getProductDetailsContainer(ColorDetailsContainerConfig)

export const ColorDetailsRoute = (
    <Route
        path={routes.options.colors.details(":colorCode")}
        Component={() => {
            const params = useParams<{ colorCode: string }>()

            return (
                <ColorDetailsContainer
                    itemKey={params}
                    parentCrumbs={[
                        {
                            name: ColorListContainerConfig.listName,
                            path: routes.options.colors.list(),
                        },
                    ]}
                />
            )
        }}
    />
)

import { usePdsBootContext } from "../../../../../common/PdsBootContext"
import { Sorting } from "../../../../../common/Sorting"
import {
    getEnumConverterGroup,
    getObjectURLParamConverter,
    numberConverterGroup,
    URLParamConverter,
    useURLSearchParamState,
} from "../../../../../common/useURLSearchParamState"
import {
    FilteredPagedTable,
    FilteredPagedTableFindParams,
    TableColumnsConfig,
    TableFilterProps,
    TableFilterTagsConfig,
} from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { KeyDTO, OptionSortField, OrderTypeOptionFilterOptionsDTO, OrderTypeOptionItemDTO, SortDirection, UserAuthority } from "../../../../../generated/pdsapi"
import { useOrderTypeDetailsContext } from "../OrderTypeDetailsContext"
import { PButton, PTabsBar, TabsBarUpdateEventDetail } from "@porsche-design-system/components-react"
import { spacing } from "@porsche-design-system/utilities"
import { FC, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { optionClassOptions, optionClassToEnum } from "./optionTypeMappings"
import { OrderTypeOptionFilter } from "../../../../../pdsapi"

export interface OrderTypeOptionsCardProps {
    getCreateRoute: (orderTypeKey: string) => string
    getDetailsRoute: (orderTypeKey: string, option: OrderTypeOptionItemDTO) => string
    getOptions: (findParams: FilteredPagedTableFindParams<OrderTypeOptionFilter, OptionSortField> & KeyDTO) => Promise<{
        totalItemCount: number
        content: OrderTypeOptionItemDTO[]
    }>
    getFilterOptions: (params: { key: string; languageTag: string }) => Promise<OrderTypeOptionFilterOptionsDTO>

    defaultSortField: OptionSortField
    sortFieldEnum: { [index: string]: OptionSortField | undefined }

    defaultFilter: OrderTypeOptionFilter
    filterUrlParamsConverter: URLParamConverter<OrderTypeOptionFilter>

    columnsConfig: TableColumnsConfig<OrderTypeOptionItemDTO, OptionSortField>
    FilterComponent: FC<TableFilterProps<OrderTypeOptionFilter, OrderTypeOptionFilterOptionsDTO>>
    filterTagsConfig: TableFilterTagsConfig<OrderTypeOptionFilter, OrderTypeOptionFilterOptionsDTO>
}

export const OrderTypeOptionsCard = ({
    urlPathParamsPrefix,
    orderTypeKey,

    getCreateRoute,
    getDetailsRoute,
    getOptions,
    getFilterOptions,

    defaultSortField,
    sortFieldEnum,

    defaultFilter,
    filterUrlParamsConverter,

    columnsConfig,
    FilterComponent,
    filterTagsConfig,
}: {
    urlPathParamsPrefix: string
    orderTypeKey: string
} & OrderTypeOptionsCardProps) => {
    const navigate = useNavigate()
    const { hasAuthority } = usePdsBootContext()
    const { isEditable } = useOrderTypeDetailsContext()
    const [tabIndex, setTabIndex] = useURLSearchParamState(`${urlPathParamsPrefix}.selectedClassIndex`, 0, numberConverterGroup.required)
    const selectedOptionClass = optionClassOptions[tabIndex]
    const onTabUpdate = useCallback((e: CustomEvent<TabsBarUpdateEventDetail>) => {
        setTabIndex(e.detail.activeTabIndex)
    }, [])

    const createButton =
        isEditable && hasAuthority(UserAuthority.MAINTAIN_DATA) ? (
            <PButton key={"createButton"} icon={"plus"} onClick={() => navigate(getCreateRoute(orderTypeKey))}>
                Create
            </PButton>
        ) : undefined

    const fetchPage = useCallback(
        (findParams: FilteredPagedTableFindParams<OrderTypeOptionFilter, any>) =>
            getOptions({
                key: orderTypeKey,
                ...findParams,
            }),
        [getOptions, orderTypeKey]
    )

    const fetchFilterOptions = useCallback(
        (params: { languageTag: string }) =>
            getFilterOptions({
                key: orderTypeKey,
                ...params,
            }),
        [getFilterOptions, orderTypeKey]
    )
    const buildDetailsPath = useCallback((option: OrderTypeOptionItemDTO) => getDetailsRoute(orderTypeKey, option), [orderTypeKey, getDetailsRoute])

    return (
        <SpacedItems direction={"column"} lineSpacing={spacing[32]}>
            <SpacedItems direction={"row"} justifyContent={"space-between"}>
                <PTabsBar activeTabIndex={tabIndex} onUpdate={onTabUpdate}>
                    {optionClassOptions.map((option) => (
                        <button type={"button"}>{option.label}</button>
                    ))}
                </PTabsBar>
            </SpacedItems>
            <FilteredPagedTable
                itemsName={`${selectedOptionClass.label}s`}
                urlParamsPrefix={`${urlPathParamsPrefix}.list`}
                actionButtons={createButton}
                columnsConfig={columnsConfig}
                buildDetailsPath={buildDetailsPath}
                fetchPage={fetchPage}
                fetchFilterOptions={fetchFilterOptions}
                defaultFilter={{
                    ...defaultFilter,
                    classes: new Set([optionClassToEnum(selectedOptionClass.value)]),
                }}
                filterUrlParamsConverter={filterUrlParamsConverter}
                defaultSorting={{
                    sortField: defaultSortField,
                    sortDirection: SortDirection.ASC,
                }}
                sortingUrlParamsConverter={getObjectURLParamConverter<Sorting<OptionSortField>>({
                    sortField: getEnumConverterGroup(sortFieldEnum).required,
                    sortDirection: getEnumConverterGroup(SortDirection).required,
                })}
                defaultShowsFilter={false}
                FilterComponent={FilterComponent}
                filterTagsConfig={filterTagsConfig}
            />
        </SpacedItems>
    )
}

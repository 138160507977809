/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOEquipmentTagCategory } from './SelectOptionDTOEquipmentTagCategory';
import {
    SelectOptionDTOEquipmentTagCategoryFromJSON,
    SelectOptionDTOEquipmentTagCategoryFromJSONTyped,
    SelectOptionDTOEquipmentTagCategoryToJSON,
    SelectOptionDTOEquipmentTagCategoryToJSONTyped,
} from './SelectOptionDTOEquipmentTagCategory';

/**
 * 
 * @export
 * @interface SelectDTOEquipmentTagCategory
 */
export interface SelectDTOEquipmentTagCategory {
    /**
     * 
     * @type {SelectOptionDTOEquipmentTagCategory}
     * @memberof SelectDTOEquipmentTagCategory
     */
    value: SelectOptionDTOEquipmentTagCategory;
    /**
     * 
     * @type {Array<SelectOptionDTOEquipmentTagCategory>}
     * @memberof SelectDTOEquipmentTagCategory
     */
    options: Array<SelectOptionDTOEquipmentTagCategory>;
}

/**
 * Check if a given object implements the SelectDTOEquipmentTagCategory interface.
 */
export function instanceOfSelectDTOEquipmentTagCategory(value: object): value is SelectDTOEquipmentTagCategory {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function SelectDTOEquipmentTagCategoryFromJSON(json: any): SelectDTOEquipmentTagCategory {
    return SelectDTOEquipmentTagCategoryFromJSONTyped(json, false);
}

export function SelectDTOEquipmentTagCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectDTOEquipmentTagCategory {
    if (json == null) {
        return json;
    }
    return {
        
        'value': SelectOptionDTOEquipmentTagCategoryFromJSON(json['value']),
        'options': ((json['options'] as Array<any>).map(SelectOptionDTOEquipmentTagCategoryFromJSON)),
    };
}

export function SelectDTOEquipmentTagCategoryToJSON(json: any): SelectDTOEquipmentTagCategory {
    return SelectDTOEquipmentTagCategoryToJSONTyped(json, false);
}

export function SelectDTOEquipmentTagCategoryToJSONTyped(value?: SelectDTOEquipmentTagCategory | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': SelectOptionDTOEquipmentTagCategoryToJSON(value['value']),
        'options': ((value['options'] as Array<any>).map(SelectOptionDTOEquipmentTagCategoryToJSON)),
    };
}


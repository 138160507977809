/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KeyDTO
 */
export interface KeyDTO {
    /**
     * 
     * @type {string}
     * @memberof KeyDTO
     */
    key: string;
}

/**
 * Check if a given object implements the KeyDTO interface.
 */
export function instanceOfKeyDTO(value: object): value is KeyDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    return true;
}

export function KeyDTOFromJSON(json: any): KeyDTO {
    return KeyDTOFromJSONTyped(json, false);
}

export function KeyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
    };
}

export function KeyDTOToJSON(json: any): KeyDTO {
    return KeyDTOToJSONTyped(json, false);
}

export function KeyDTOToJSONTyped(value?: KeyDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ColorTileDTO } from './ColorTileDTO';
import {
    ColorTileDTOFromJSON,
    ColorTileDTOFromJSONTyped,
    ColorTileDTOToJSON,
    ColorTileDTOToJSONTyped,
} from './ColorTileDTO';

/**
 * 
 * @export
 * @interface OrderTypeOptionItemDTORoofColor
 */
export interface OrderTypeOptionItemDTORoofColor {
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTORoofColor
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTORoofColor
     */
    optionCode: string;
    /**
     * 
     * @type {number}
     * @memberof OrderTypeOptionItemDTORoofColor
     */
    sortIndex: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTORoofColor
     */
    imageKey?: string;
    /**
     * 
     * @type {ColorTileDTO}
     * @memberof OrderTypeOptionItemDTORoofColor
     */
    color?: ColorTileDTO;
    /**
     * 
     * @type {ColorTileDTO}
     * @memberof OrderTypeOptionItemDTORoofColor
     */
    tag?: ColorTileDTO;
}

/**
 * Check if a given object implements the OrderTypeOptionItemDTORoofColor interface.
 */
export function instanceOfOrderTypeOptionItemDTORoofColor(value: object): value is OrderTypeOptionItemDTORoofColor {
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('sortIndex' in value) || value['sortIndex'] === undefined) return false;
    return true;
}

export function OrderTypeOptionItemDTORoofColorFromJSON(json: any): OrderTypeOptionItemDTORoofColor {
    return OrderTypeOptionItemDTORoofColorFromJSONTyped(json, false);
}

export function OrderTypeOptionItemDTORoofColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeOptionItemDTORoofColor {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'optionCode': json['optionCode'],
        'sortIndex': json['sortIndex'],
        'imageKey': json['imageKey'] == null ? undefined : json['imageKey'],
        'color': json['color'] == null ? undefined : ColorTileDTOFromJSON(json['color']),
        'tag': json['tag'] == null ? undefined : ColorTileDTOFromJSON(json['tag']),
    };
}

export function OrderTypeOptionItemDTORoofColorToJSON(json: any): OrderTypeOptionItemDTORoofColor {
    return OrderTypeOptionItemDTORoofColorToJSONTyped(json, false);
}

export function OrderTypeOptionItemDTORoofColorToJSONTyped(value?: OrderTypeOptionItemDTORoofColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'optionCode': value['optionCode'],
        'sortIndex': value['sortIndex'],
        'imageKey': value['imageKey'],
        'color': ColorTileDTOToJSON(value['color']),
        'tag': ColorTileDTOToJSON(value['tag']),
    };
}


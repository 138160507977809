/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RelatedOptionGroupDTOStringString } from './RelatedOptionGroupDTOStringString';
import {
    RelatedOptionGroupDTOStringStringFromJSON,
    RelatedOptionGroupDTOStringStringFromJSONTyped,
    RelatedOptionGroupDTOStringStringToJSON,
    RelatedOptionGroupDTOStringStringToJSONTyped,
} from './RelatedOptionGroupDTOStringString';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface RelatedGroupedOptionalSelectDTOStringString
 */
export interface RelatedGroupedOptionalSelectDTOStringString {
    /**
     * 
     * @type {SelectOptionDTOString}
     * @memberof RelatedGroupedOptionalSelectDTOStringString
     */
    value?: SelectOptionDTOString;
    /**
     * 
     * @type {Array<RelatedOptionGroupDTOStringString>}
     * @memberof RelatedGroupedOptionalSelectDTOStringString
     */
    relatedCategorizedOptions: Array<RelatedOptionGroupDTOStringString>;
}

/**
 * Check if a given object implements the RelatedGroupedOptionalSelectDTOStringString interface.
 */
export function instanceOfRelatedGroupedOptionalSelectDTOStringString(value: object): value is RelatedGroupedOptionalSelectDTOStringString {
    if (!('relatedCategorizedOptions' in value) || value['relatedCategorizedOptions'] === undefined) return false;
    return true;
}

export function RelatedGroupedOptionalSelectDTOStringStringFromJSON(json: any): RelatedGroupedOptionalSelectDTOStringString {
    return RelatedGroupedOptionalSelectDTOStringStringFromJSONTyped(json, false);
}

export function RelatedGroupedOptionalSelectDTOStringStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedGroupedOptionalSelectDTOStringString {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : SelectOptionDTOStringFromJSON(json['value']),
        'relatedCategorizedOptions': ((json['relatedCategorizedOptions'] as Array<any>).map(RelatedOptionGroupDTOStringStringFromJSON)),
    };
}

export function RelatedGroupedOptionalSelectDTOStringStringToJSON(json: any): RelatedGroupedOptionalSelectDTOStringString {
    return RelatedGroupedOptionalSelectDTOStringStringToJSONTyped(json, false);
}

export function RelatedGroupedOptionalSelectDTOStringStringToJSONTyped(value?: RelatedGroupedOptionalSelectDTOStringString | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': SelectOptionDTOStringToJSON(value['value']),
        'relatedCategorizedOptions': ((value['relatedCategorizedOptions'] as Array<any>).map(RelatedOptionGroupDTOStringStringToJSON)),
    };
}


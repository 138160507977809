import { propertiesOf, Property } from "../../../../../../../../common/property"
import { FormElementWrapper } from "../../../../../../../../components/formelements/FormElementWrapper"
import { ReadonlyFormElementWrapper } from "../../../../../../../../components/formelements/ReadonlyFormElementWrapper"
import { getDerivedNameFormElement } from "../../../../../../../../components/formelements/derivednameformelement/DerivedNameFormElement"
import { getNumberFormElement } from "../../../../../../../../components/formelements/numberformelement/NumberFormElement"
import { getTextFormElement } from "../../../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../../components/layouts/spaceditems/SpacedItems"
import {
    OptionDTO,
    OptionDTOCustomTailoring,
    OptionDTOExteriorColor,
    OptionDTOIndividualEquipment,
    OptionDTOPaintToSampleExteriorColor,
    OptionDTOSalesPackage,
    OptionDTOTechnicalPackage,
    OptionDTOUnclassifiedEquipment,
    OptionDTOZOrder,
    OptionKeyDTOOptionTypeDTO,
    OptionTypeDTO,
    OptionUpdateDTOCustomTailoring,
    OptionUpdateDTOExteriorColor,
    OptionUpdateDTOIndividualEquipment,
    OptionUpdateDTOOption,
    OptionUpdateDTOPaintToSampleExteriorColor,
    OptionUpdateDTOSalesPackage,
    OptionUpdateDTOTechnicalPackage,
    OptionUpdateDTOUnclassifiedEquipment,
    OptionUpdateDTOZOrder,
    SelectOptionDTOString,
} from "../../../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import React, { FC, useEffect, useRef, useState } from "react"
import { OptionImage } from "../../../../../../../../components/optionimage/OptionImage"
import { getSelectTextFormElement } from "../../../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { toOption } from "../../../../../../../../components/formelements/selecttextformelement/Option"
import { FormDisplayData, getSimpleFormWatch } from "../../../../../../../../components/formelements/FormDisplayData"
import { getTagFormTextElement } from "../../../../../../../../components/formelements/tagformtextelement/TagFormTextElement"
import { routes } from "../../../../../../../../common/routes"
import { getTagFormMultiTextElement } from "../../../../../../../../components/formelements/tagformmultitextelement/TagFormMultiTextElement"
import { equipmentClassOptions, optionClasses, optionClassOptions } from "../../../optionTypeMappings"
import { getTagsFormElement } from "../../../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { showErrorToast } from "../../../../../../../../common/errorToastHelper"
import { optionApi } from "../../../../../../../../pdsapi"
import { useToast } from "@finder/ui-kit"
import { browserLanguage } from "../../../../../../../../common/global"
import { getBoolFormElement } from "../../../../../../../../components/formelements/boolformelement/BoolFormElement"

export const OptionDetailsCardContent: FC<ProductDetailsCardContentProps<OptionDTO, OptionUpdateDTOOption, OptionKeyDTOOptionTypeDTO>> = ({
    formDisplayData,
    itemKey,
}) => {
    const properties = propertiesOf<OptionUpdateDTOOption>()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"} childrenSize={6}>
                <FormElementWrapper
                    label={"Class"}
                    formDisplayData={formDisplayData}
                    field={properties.type}
                    FormElement={getSelectTextFormElement<OptionDTO, OptionUpdateDTOOption>()}
                    getViewProps={(data) => ({
                        text: optionClasses[data.type] ?? data.type,
                    })}
                    getEditProps={(data) => ({
                        value: data.type,
                        options: data.type === "UnclassifiedEquipment" ? equipmentClassOptions : optionClassOptions,
                        readOnly: data.type !== "UnclassifiedEquipment",
                    })}
                />
                <ReadonlyFormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    FormElement={getTextFormElement()}
                    getViewProps={(data) => ({
                        value: data.optionCode,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper childrenSize={6}>
                <FormElementWrapper
                    label={"Sort Index"}
                    formDisplayData={formDisplayData}
                    field={properties.sortIndex}
                    FormElement={getNumberFormElement<OptionDTO, OptionUpdateDTOOption>()}
                    getViewProps={(data) => ({
                        value: data.sortIndex,
                    })}
                    getEditProps={(data) => ({
                        value: data.sortIndex,
                        step: 1,
                        min: 0,
                    })}
                />
            </RowGroupWrapper>

            <RowGroupWrapper label={"Name"} childrenSize={6}>
                {(["de", "en"] as const).map((languageTag) => (
                    <FormElementWrapper
                        key={languageTag}
                        label={`Name - ${languageTag}`}
                        formDisplayData={formDisplayData}
                        field={properties.name[languageTag]}
                        FormElement={getDerivedNameFormElement<OptionDTO, OptionUpdateDTOOption>()}
                        getViewProps={(data) => {
                            const name = data.name[languageTag]
                            return {
                                primaryName: {
                                    value: name.merged.withFallback,
                                    isDerived: !name.merged.raw,
                                },
                                secondaryNames: [
                                    {
                                        label: "Option",
                                        value: name.option.withFallback,
                                        isDerived: !name.option.raw,
                                    },
                                    {
                                        label: "Customization",
                                        value: name.customization?.withFallback,
                                        isDerived: !name.customization?.raw,
                                    },
                                ],
                            }
                        }}
                        getEditProps={(data) => ({
                            rawName: data.name[languageTag].option.raw,
                        })}
                    />
                ))}
            </RowGroupWrapper>

            <RowGroupWrapper label={"Description"} childrenSize={6}>
                {(["de", "en"] as const).map((languageTag) => (
                    <FormElementWrapper
                        key={languageTag}
                        label={`Description - ${languageTag}`}
                        formDisplayData={formDisplayData}
                        field={properties.description[languageTag]}
                        FormElement={getDerivedNameFormElement<OptionDTO, OptionUpdateDTOOption>()}
                        getViewProps={(data) => {
                            const description = data.description[languageTag]
                            return {
                                primaryName: {
                                    value: description.merged.withFallback,
                                    isDerived: !description.merged.raw,
                                },
                                secondaryNames: [
                                    {
                                        label: "Option",
                                        value: description.option.withFallback,
                                        isDerived: !description.option.raw,
                                    },
                                    {
                                        label: "Customization",
                                        value: description.customization?.withFallback,
                                        isDerived: !description.customization?.raw,
                                    },
                                ],
                            }
                        }}
                        getEditProps={(data) => ({
                            rawName: data.description[languageTag].option.raw,
                        })}
                    />
                ))}
            </RowGroupWrapper>
            <SpecificOptionItemContent formDisplayData={formDisplayData} property={properties} itemKey={itemKey} />
            <RowGroupWrapper label={"Images"} childrenSize={5}>
                {formDisplayData.data?.imageKeys.map((imageKey) => <OptionImage key={imageKey} imageKey={imageKey} sizes={"400px"} />)}
            </RowGroupWrapper>
        </SpacedItems>
    )
}

interface SpecificOptionItemContentProps<DATA extends OptionDTO = OptionDTO, UPDATE extends OptionUpdateDTOOption = OptionUpdateDTOOption> {
    itemKey: OptionKeyDTOOptionTypeDTO
    property: Property<UPDATE, UPDATE>
    formDisplayData: FormDisplayData<DATA, UPDATE>
}

const SpecificOptionItemContent = (props: SpecificOptionItemContentProps) => {
    const optionClass = getSimpleFormWatch(props.formDisplayData)("type")
    switch (optionClass) {
        case "IndividualEquipment": {
            return (
                <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
                    <CommonEquipmentFields {...props}></CommonEquipmentFields>
                    <RowGroupWrapper childrenSize={6}>
                        <TwikitFlagField {...props}></TwikitFlagField>
                    </RowGroupWrapper>
                </SpacedItems>
            )
        }

        case "CustomTailoring": {
            return (
                <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
                    <CommonEquipmentFields {...props}></CommonEquipmentFields>
                    <RowGroupWrapper childrenSize={6}>
                        <TwikitFlagField {...props}></TwikitFlagField>
                        <LenaFlagField {...props}></LenaFlagField>
                    </RowGroupWrapper>
                    <AvailableColorsField {...props}></AvailableColorsField>
                </SpacedItems>
            )
        }

        case "ZOrder": {
            return (
                <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
                    <CommonEquipmentFields {...props}></CommonEquipmentFields>
                    <RowGroupWrapper childrenSize={6}>
                        <LenaFlagField {...props}></LenaFlagField>
                    </RowGroupWrapper>
                    <AvailableColorsField {...props}></AvailableColorsField>
                </SpacedItems>
            )
        }

        case "SalesPackage": {
            return <CommonEquipmentFields {...props}></CommonEquipmentFields>
        }

        case "TechnicalPackage": {
            return <CommonEquipmentFields {...props}></CommonEquipmentFields>
        }

        case "UnclassifiedEquipment": {
            return <CommonEquipmentFields {...props}></CommonEquipmentFields>
        }

        case "ExteriorColor": {
            type ExteriorColorDTO = OptionDTOExteriorColor & { type: "ExteriorColor" }
            type ExteriorColorUpdateDTO = OptionUpdateDTOExteriorColor & { type: "ExteriorColor" }
            const { property, formDisplayData } = props as SpecificOptionItemContentProps<ExteriorColorDTO, ExteriorColorUpdateDTO>
            return (
                <RowGroupWrapper label={"Additional Information"} childrenSize={6}>
                    <FormElementWrapper
                        label={"Exterior Color Type"}
                        formDisplayData={formDisplayData}
                        field={property.exteriorColorType}
                        FormElement={getSelectTextFormElement<ExteriorColorDTO, ExteriorColorUpdateDTO>()}
                        getViewProps={(data) => ({
                            text: data.exteriorColorType.value?.label ?? "-",
                        })}
                        getEditProps={(options) => ({
                            options: [{ value: "", label: "-" }, ...options.exteriorColorType.options.map(toOption)],
                            value: options.exteriorColorType.value?.key ?? "",
                            optional: true,
                        })}
                    />
                    <FormElementWrapper
                        label={"Exterior Color Group"}
                        formDisplayData={formDisplayData}
                        field={property.exteriorColorGroup}
                        FormElement={getSelectTextFormElement<ExteriorColorDTO, ExteriorColorUpdateDTO>()}
                        getViewProps={(data) => ({
                            text: data.exteriorColorGroup.value?.label ?? "-",
                        })}
                        getEditProps={(options) => ({
                            options: [{ value: "", label: "-" }, ...options.exteriorColorGroup.options.map(toOption)],
                            value: options.exteriorColorGroup.value?.key ?? "",
                            optional: true,
                        })}
                    />
                </RowGroupWrapper>
            )
        }

        case "PaintToSampleExteriorColor": {
            type PaintToSampleExteriorColorDTO = OptionDTOPaintToSampleExteriorColor & { type: "PaintToSampleExteriorColor" }
            type PaintToSampleExteriorColorUpdateDTO = OptionUpdateDTOPaintToSampleExteriorColor & { type: "PaintToSampleExteriorColor" }
            const { property, formDisplayData } = props as SpecificOptionItemContentProps<PaintToSampleExteriorColorDTO, PaintToSampleExteriorColorUpdateDTO>

            return (
                <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
                    <RowGroupWrapper label={"References"} childrenSize={6}>
                        <FormElementWrapper
                            label={"Paint to Sample Equipment Option Code"}
                            formDisplayData={formDisplayData}
                            field={property.paintToSampleEquipmentOptionCode}
                            FormElement={getTagFormTextElement<PaintToSampleExteriorColorDTO, PaintToSampleExteriorColorUpdateDTO>()}
                            getViewProps={({ paintToSampleEquipmentOptionCode }) => ({
                                tag: paintToSampleEquipmentOptionCode
                                    ? {
                                          label: paintToSampleEquipmentOptionCode,
                                          route: routes.models.orderTypes
                                              .subpages(props.itemKey.orderTypeKey)
                                              .options.details(paintToSampleEquipmentOptionCode, OptionTypeDTO.EQUIPMENT),
                                      }
                                    : undefined,
                            })}
                            getEditProps={(data) => ({
                                value: data.paintToSampleEquipmentOptionCode ?? "",
                                readOnly: false,
                                optional: true,
                            })}
                        />
                        <FormElementWrapper
                            label={"Paint to Sample Plus Equipment Option Code"}
                            formDisplayData={formDisplayData}
                            field={property.paintToSamplePlusEquipmentOptionCode}
                            FormElement={getTagFormTextElement<PaintToSampleExteriorColorDTO, PaintToSampleExteriorColorUpdateDTO>()}
                            getViewProps={({ paintToSamplePlusEquipmentOptionCode }) => ({
                                tag: paintToSamplePlusEquipmentOptionCode
                                    ? {
                                          label: paintToSamplePlusEquipmentOptionCode,
                                          route: routes.models.orderTypes
                                              .subpages(props.itemKey.orderTypeKey)
                                              .options.details(paintToSamplePlusEquipmentOptionCode, OptionTypeDTO.EQUIPMENT),
                                      }
                                    : undefined,
                            })}
                            getEditProps={(data) => ({
                                value: data.paintToSamplePlusEquipmentOptionCode ?? "",
                                readOnly: false,
                                optional: true,
                            })}
                        />
                        <FormElementWrapper
                            label={"Z-Order Option Code(s)"}
                            formDisplayData={formDisplayData}
                            field={property.zOrderOptionCodes}
                            FormElement={getTagFormMultiTextElement<PaintToSampleExteriorColorDTO, PaintToSampleExteriorColorUpdateDTO>()}
                            getViewProps={(data) => ({
                                tags: (data.zOrderOptionCodes ?? []).map((value) => ({
                                    label: value,
                                    route: routes.models.orderTypes.subpages(props.itemKey.orderTypeKey).options.details(value, OptionTypeDTO.EQUIPMENT),
                                })),
                                emptyLabel: "-",
                            })}
                            getEditProps={(data) => ({
                                value: data.zOrderOptionCodes ?? [],
                                readOnly: false,
                                optional: true,
                            })}
                        />
                    </RowGroupWrapper>
                </SpacedItems>
            )
        }
    }
}

const CommonEquipmentFields = (props: SpecificOptionItemContentProps) => {
    type EquipmentDTO =
        | (OptionDTOIndividualEquipment & { type: "IndividualEquipment" })
        | (OptionDTOCustomTailoring & { type: "CustomTailoring" })
        | (OptionDTOZOrder & { type: "ZOrder" })
        | (OptionDTOSalesPackage & { type: "SalesPackage" })
        | (OptionDTOTechnicalPackage & { type: "TechnicalPackage" })
        | (OptionDTOUnclassifiedEquipment & { type: "UnclassifiedEquipment" })
    type EquipmentUpdateDTO =
        | (OptionUpdateDTOIndividualEquipment & { type: "IndividualEquipment" })
        | (OptionUpdateDTOCustomTailoring & { type: "CustomTailoring" })
        | (OptionUpdateDTOZOrder & { type: "ZOrder" })
        | (OptionUpdateDTOSalesPackage & { type: "SalesPackage" })
        | (OptionUpdateDTOTechnicalPackage & { type: "TechnicalPackage" })
        | (OptionUpdateDTOUnclassifiedEquipment & { type: "UnclassifiedEquipment" })

    const { property, formDisplayData } = props as SpecificOptionItemContentProps<EquipmentDTO, EquipmentUpdateDTO>
    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Equipment Information"} childrenSize={6}>
                <FormElementWrapper
                    label={"Category"}
                    formDisplayData={formDisplayData}
                    field={property.category}
                    FormElement={getSelectTextFormElement<EquipmentDTO, EquipmentUpdateDTO>()}
                    getViewProps={(data) => ({
                        text: data.category.value?.label ?? "-",
                    })}
                    getEditProps={(data) => ({
                        value: data.category.value?.key ?? "",
                        options: [{ label: "-", value: "" }, ...data.category.options.map(toOption)],
                        optional: true,
                    })}
                />
                <ReadonlyFormElementWrapper
                    label={"Family"}
                    formDisplayData={formDisplayData}
                    FormElement={getTextFormElement()}
                    getViewProps={(data) => ({
                        value: data.family,
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper childrenSize={6}>
                <ReadonlyFormElementWrapper
                    label={"Serial Option Type"}
                    formDisplayData={formDisplayData}
                    FormElement={getTextFormElement()}
                    getViewProps={(data) => ({
                        value: data.serialOptionType,
                    })}
                />
                <ReadonlyFormElementWrapper
                    label={"Group Id List"}
                    formDisplayData={formDisplayData}
                    FormElement={getTextFormElement()}
                    getViewProps={(data) => ({
                        value: data.groupIdList.join(", "),
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}

const TwikitFlagField = (props: SpecificOptionItemContentProps) => {
    type EquipmentWithTwikitDTO = (OptionDTOIndividualEquipment & { type: "IndividualEquipment" }) | (OptionDTOCustomTailoring & { type: "CustomTailoring" })
    type EquipmentWithTwikitUpdateDTO =
        | (OptionUpdateDTOIndividualEquipment & { type: "IndividualEquipment" })
        | (OptionUpdateDTOCustomTailoring & { type: "CustomTailoring" })
    const { property, formDisplayData } = props as SpecificOptionItemContentProps<EquipmentWithTwikitDTO, EquipmentWithTwikitUpdateDTO>

    return (
        <FormElementWrapper
            label={"Is Twikit Option?"}
            hint={`Twikit marks an option that supports special configuration via the third-party Twikit configurator. 
            These options will be displayed differently in the car configurator.`}
            formDisplayData={formDisplayData}
            field={property.twikit}
            FormElement={getBoolFormElement<EquipmentWithTwikitDTO, EquipmentWithTwikitUpdateDTO>()}
            getViewProps={(data) => ({
                value: data.twikit,
                trueMessage: "Yes",
                falseMessage: "No",
            })}
            getEditProps={(data) => ({
                value: data.twikit,
                trueMessage: "Yes",
                falseMessage: "No",
            })}
        />
    )
}

const LenaFlagField = (props: SpecificOptionItemContentProps) => {
    type EquipmentWithLenaDTO = (OptionDTOCustomTailoring & { type: "CustomTailoring" }) | (OptionDTOZOrder & { type: "ZOrder" })
    type EquipmentWithLenaUpdateDTO = (OptionUpdateDTOCustomTailoring & { type: "CustomTailoring" }) | (OptionUpdateDTOZOrder & { type: "ZOrder" })
    const { property, formDisplayData } = props as SpecificOptionItemContentProps<EquipmentWithLenaDTO, EquipmentWithLenaUpdateDTO>

    return (
        <FormElementWrapper
            label={"Is LENA Option?"}
            hint={`LENA (Limited Edition Number Assignment) marks an option that is added to a limited edition Porsche 
            so it can be recognized as such. This option will receive a specific number value when added to a vehicle.`}
            formDisplayData={formDisplayData}
            field={property.lena}
            FormElement={getBoolFormElement<EquipmentWithLenaDTO, EquipmentWithLenaUpdateDTO>()}
            getViewProps={(data) => ({
                value: data.lena,
                trueMessage: "Yes",
                falseMessage: "No",
            })}
            getEditProps={(data) => ({
                value: data.lena,
                trueMessage: "Yes",
                falseMessage: "No",
            })}
        />
    )
}

const AvailableColorsField = (props: SpecificOptionItemContentProps) => {
    type EquipmentWithColorsDTO = (OptionDTOCustomTailoring & { type: "CustomTailoring" }) | (OptionDTOZOrder & { type: "ZOrder" })
    type EquipmentWithColorsUpdateDTO = (OptionUpdateDTOCustomTailoring & { type: "CustomTailoring" }) | (OptionUpdateDTOZOrder & { type: "ZOrder" })
    const { property, formDisplayData } = props as SpecificOptionItemContentProps<EquipmentWithColorsDTO, EquipmentWithColorsUpdateDTO>

    const [availableColors, setAvailableColors] = useState<SelectOptionDTOString[]>([])
    const toastRef = useRef(useToast())
    useEffect(() => {
        if (formDisplayData.data?.availableColorCodes) {
            setAvailableColors(formDisplayData.data.availableColorCodes.options)
        } else {
            optionApi.create
                .getCreateOptions({ languageTag: browserLanguage })
                .then((createOptions) => setAvailableColors(createOptions.availableColors))
                .catch((e) => showErrorToast(toastRef.current, e))
        }
    }, [])

    return (
        <RowGroupWrapper childrenSize={12}>
            <FormElementWrapper
                label={"Available Colors"}
                formDisplayData={formDisplayData}
                field={property.availableColorCodes}
                FormElement={getTagsFormElement<EquipmentWithColorsDTO, EquipmentWithColorsUpdateDTO>()}
                getViewProps={(data) => ({
                    emptyLabel: "-",
                    tags: data.availableColorCodes.value.map((color) => ({
                        value: color.key,
                        label: color.label,
                        route: routes.options.colors.details(color.key),
                    })),
                })}
                getEditProps={(data) => ({
                    emptyLabel: "-",
                    value: data.availableColorCodes?.value?.map((color) => color.key) ?? [],
                    options: availableColors.map(toOption),
                    clearable: true,
                    optional: true,
                })}
            />
        </RowGroupWrapper>
    )
}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BaseInformationDTO
 */
export interface BaseInformationDTO {
    /**
     * 
     * @type {string}
     * @memberof BaseInformationDTO
     */
    headline: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseInformationDTO
     */
    isEditable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaseInformationDTO
     */
    isDeletable: boolean;
}

/**
 * Check if a given object implements the BaseInformationDTO interface.
 */
export function instanceOfBaseInformationDTO(value: object): value is BaseInformationDTO {
    if (!('headline' in value) || value['headline'] === undefined) return false;
    if (!('isEditable' in value) || value['isEditable'] === undefined) return false;
    if (!('isDeletable' in value) || value['isDeletable'] === undefined) return false;
    return true;
}

export function BaseInformationDTOFromJSON(json: any): BaseInformationDTO {
    return BaseInformationDTOFromJSONTyped(json, false);
}

export function BaseInformationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseInformationDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'headline': json['headline'],
        'isEditable': json['isEditable'],
        'isDeletable': json['isDeletable'],
    };
}

export function BaseInformationDTOToJSON(json: any): BaseInformationDTO {
    return BaseInformationDTOToJSONTyped(json, false);
}

export function BaseInformationDTOToJSONTyped(value?: BaseInformationDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'headline': value['headline'],
        'isEditable': value['isEditable'],
        'isDeletable': value['isDeletable'],
    };
}


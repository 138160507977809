import { TableColumnsConfig } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../../../components/layouts/table/Table"
import { getSortIndexColumnBuilder } from "../../../../../../../components/pagedtable/columns/columnBuilder"
import { OptionImage } from "../../../../../../../components/optionimage/OptionImage"
import { OptionSortField, OrderTypeOptionItemDTO } from "../../../../../../../generated/pdsapi"
import { Text } from "@finder/ui-kit"
import { NamedColorTile } from "../../../../../../../components/tiles/namedcolortile/NamedColorTile"
import { spacing } from "@porsche-design-system/utilities"
import React from "react"
import { NamedInteriorColorTile } from "../../../../../../../components/tiles/namedinteriorcolortile/NamedInteriorColorTile"

type OptionItemCardColumnBuilder = ColumnBuilder<OrderTypeOptionItemDTO, OptionSortField>

export const OrderTypeOptionItemsCardColumnsConfig: TableColumnsConfig<OrderTypeOptionItemDTO, OptionSortField> = ({ sorting, setSorting }) => {
    const previewColumn: OptionItemCardColumnBuilder = {
        width: 80,
        headerCellProps: {
            content: "Preview",
        },
        buildCellContent: (option) => <OptionImage imageKey={option.imageKey} sizes={"100px"} />,
    }

    const sortIndexColumn: OptionItemCardColumnBuilder = getSortIndexColumnBuilder((option) => option.sortIndex, {
        field: OptionSortField.SORT_INDEX,
        value: sorting,
        setValue: setSorting,
    })

    const optionCodeColumn: OptionItemCardColumnBuilder = {
        width: 100,
        headerCellProps: {
            content: "Option Code",
        },
        buildCellContent: (option) => option.optionCode,
    }

    const nameColumn: OptionItemCardColumnBuilder = {
        grow: 3,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (option) => {
            switch (option.type) {
                case "Interior": {
                    return option.color ? (
                        <NamedInteriorColorTile
                            label={option.name ?? "-"}
                            seatColor={option.color.seatColorHexCode}
                            seatStitchingColor={option.color.seatStitchingColorHexCode}
                            cockpitColor={option.color.cockpitColorHexCode}
                            cockpitStitchingColor={option.color.cockpitStitchingColorHexCode}
                        />
                    ) : (
                        (option.name ?? "-")
                    )
                }
                case "RoofColor":
                case "ExteriorColor":
                    return (option.color && option.name && <NamedColorTile label={option.name} hexCode={option.color.hexCode} />) ?? option.name ?? "-"
                default:
                    return option.name ?? "-"
            }
        },
    }

    const additionalFieldsColumn: OptionItemCardColumnBuilder = {
        grow: 2,
        headerCellProps: {
            content: "Additional Properties",
        },
        buildCellContent: (option) => {
            switch (option.type) {
                case "IndividualEquipment":
                case "CustomTailoring":
                case "ZOrder":
                case "SalesPackage":
                case "TechnicalPackage":
                case "UnclassifiedEquipment":
                    return (
                        <SeparatedValues>
                            {option.family && <Text>{option.family}</Text>}
                            {option.categoryName && <Text>{option.categoryName}</Text>}
                        </SeparatedValues>
                    )
                case "ExteriorColor":
                    return (
                        <SeparatedValues>
                            {option.tag && <NamedColorTile label={option.tag.label} hexCode={option.tag.hexCode} />}
                            {option.colorTypeName && <Text>{option.colorTypeName}</Text>}
                        </SeparatedValues>
                    )
                case "PaintToSampleExteriorColor":
                    return <></>
                case "Interior":
                    return (
                        <SeparatedValues>
                            {option.colorTag && <NamedColorTile label={option.colorTag.label} hexCode={option.colorTag.hexCode} />}
                            {option.materialName && <Text>{option.materialName}</Text>}
                        </SeparatedValues>
                    )
                case "RoofColor":
                    return <SeparatedValues>{option.tag && <NamedColorTile label={option.tag.label} hexCode={option.tag.hexCode} />}</SeparatedValues>
            }
        },
    }

    return [previewColumn, sortIndexColumn, optionCodeColumn, nameColumn, additionalFieldsColumn]
}

const SeparatedValues = ({ children }: { children: React.ReactNode }) => {
    return (
        <div style={{ display: "flex", gap: spacing[8] }}>
            {React.Children.toArray(children)
                .filter((child) => child != null)
                .map((child, index) => (
                    <>
                        {index > 0 && <Text color={"neutral-contrast-low"}>|</Text>}
                        {child}
                    </>
                ))}
        </div>
    )
}

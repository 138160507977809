/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptionCustomizationItemDTOPaintToSampleExteriorColor
 */
export interface OptionCustomizationItemDTOPaintToSampleExteriorColor {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTOPaintToSampleExteriorColor
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTOPaintToSampleExteriorColor
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationItemDTOPaintToSampleExteriorColor
     */
    modelGenerationNames: Array<string>;
}

/**
 * Check if a given object implements the OptionCustomizationItemDTOPaintToSampleExteriorColor interface.
 */
export function instanceOfOptionCustomizationItemDTOPaintToSampleExteriorColor(value: object): value is OptionCustomizationItemDTOPaintToSampleExteriorColor {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelGenerationNames' in value) || value['modelGenerationNames'] === undefined) return false;
    return true;
}

export function OptionCustomizationItemDTOPaintToSampleExteriorColorFromJSON(json: any): OptionCustomizationItemDTOPaintToSampleExteriorColor {
    return OptionCustomizationItemDTOPaintToSampleExteriorColorFromJSONTyped(json, false);
}

export function OptionCustomizationItemDTOPaintToSampleExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationItemDTOPaintToSampleExteriorColor {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'optionCode': json['optionCode'],
        'modelGenerationNames': json['modelGenerationNames'],
    };
}

export function OptionCustomizationItemDTOPaintToSampleExteriorColorToJSON(json: any): OptionCustomizationItemDTOPaintToSampleExteriorColor {
    return OptionCustomizationItemDTOPaintToSampleExteriorColorToJSONTyped(json, false);
}

export function OptionCustomizationItemDTOPaintToSampleExteriorColorToJSONTyped(value?: OptionCustomizationItemDTOPaintToSampleExteriorColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'optionCode': value['optionCode'],
        'modelGenerationNames': value['modelGenerationNames'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptionTagItemDTOEquipment
 */
export interface OptionTagItemDTOEquipment {
    /**
     * 
     * @type {string}
     * @memberof OptionTagItemDTOEquipment
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OptionTagItemDTOEquipment
     */
    key: string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionTagItemDTOEquipment
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof OptionTagItemDTOEquipment
     */
    sortIndex: number;
    /**
     * 
     * @type {string}
     * @memberof OptionTagItemDTOEquipment
     */
    categoryName: string;
}

/**
 * Check if a given object implements the OptionTagItemDTOEquipment interface.
 */
export function instanceOfOptionTagItemDTOEquipment(value: object): value is OptionTagItemDTOEquipment {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('sortIndex' in value) || value['sortIndex'] === undefined) return false;
    if (!('categoryName' in value) || value['categoryName'] === undefined) return false;
    return true;
}

export function OptionTagItemDTOEquipmentFromJSON(json: any): OptionTagItemDTOEquipment {
    return OptionTagItemDTOEquipmentFromJSONTyped(json, false);
}

export function OptionTagItemDTOEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagItemDTOEquipment {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'key': json['key'],
        'active': json['active'],
        'sortIndex': json['sortIndex'],
        'categoryName': json['categoryName'],
    };
}

export function OptionTagItemDTOEquipmentToJSON(json: any): OptionTagItemDTOEquipment {
    return OptionTagItemDTOEquipmentToJSONTyped(json, false);
}

export function OptionTagItemDTOEquipmentToJSONTyped(value?: OptionTagItemDTOEquipment | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'key': value['key'],
        'active': value['active'],
        'sortIndex': value['sortIndex'],
        'categoryName': value['categoryName'],
    };
}


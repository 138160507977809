/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { OptionCustomizationAttributesDTOEquipment } from './OptionCustomizationAttributesDTOEquipment';
import {
    instanceOfOptionCustomizationAttributesDTOEquipment,
    OptionCustomizationAttributesDTOEquipmentFromJSON,
    OptionCustomizationAttributesDTOEquipmentFromJSONTyped,
    OptionCustomizationAttributesDTOEquipmentToJSON,
} from './OptionCustomizationAttributesDTOEquipment';
import type { OptionCustomizationAttributesDTOExteriorColor } from './OptionCustomizationAttributesDTOExteriorColor';
import {
    instanceOfOptionCustomizationAttributesDTOExteriorColor,
    OptionCustomizationAttributesDTOExteriorColorFromJSON,
    OptionCustomizationAttributesDTOExteriorColorFromJSONTyped,
    OptionCustomizationAttributesDTOExteriorColorToJSON,
} from './OptionCustomizationAttributesDTOExteriorColor';
import type { OptionCustomizationAttributesDTOInterior } from './OptionCustomizationAttributesDTOInterior';
import {
    instanceOfOptionCustomizationAttributesDTOInterior,
    OptionCustomizationAttributesDTOInteriorFromJSON,
    OptionCustomizationAttributesDTOInteriorFromJSONTyped,
    OptionCustomizationAttributesDTOInteriorToJSON,
} from './OptionCustomizationAttributesDTOInterior';
import type { OptionCustomizationAttributesDTOPaintToSampleExteriorColor } from './OptionCustomizationAttributesDTOPaintToSampleExteriorColor';
import {
    instanceOfOptionCustomizationAttributesDTOPaintToSampleExteriorColor,
    OptionCustomizationAttributesDTOPaintToSampleExteriorColorFromJSON,
    OptionCustomizationAttributesDTOPaintToSampleExteriorColorFromJSONTyped,
    OptionCustomizationAttributesDTOPaintToSampleExteriorColorToJSON,
} from './OptionCustomizationAttributesDTOPaintToSampleExteriorColor';
import type { OptionCustomizationAttributesDTORoofColor } from './OptionCustomizationAttributesDTORoofColor';
import {
    instanceOfOptionCustomizationAttributesDTORoofColor,
    OptionCustomizationAttributesDTORoofColorFromJSON,
    OptionCustomizationAttributesDTORoofColorFromJSONTyped,
    OptionCustomizationAttributesDTORoofColorToJSON,
} from './OptionCustomizationAttributesDTORoofColor';

/**
 * @type OptionCustomizationAttributesDTOObject
 * 
 * @export
 */
export type OptionCustomizationAttributesDTOObject = { type: 'Equipment' } & OptionCustomizationAttributesDTOEquipment | { type: 'ExteriorColor' } & OptionCustomizationAttributesDTOExteriorColor | { type: 'Interior' } & OptionCustomizationAttributesDTOInterior | { type: 'PaintToSampleExteriorColor' } & OptionCustomizationAttributesDTOPaintToSampleExteriorColor | { type: 'RoofColor' } & OptionCustomizationAttributesDTORoofColor;

export function OptionCustomizationAttributesDTOObjectFromJSON(json: any): OptionCustomizationAttributesDTOObject {
    return OptionCustomizationAttributesDTOObjectFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOObject {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return Object.assign({}, OptionCustomizationAttributesDTOEquipmentFromJSONTyped(json, true), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionCustomizationAttributesDTOExteriorColorFromJSONTyped(json, true), { type: 'ExteriorColor' } as const);
        case 'Interior':
            return Object.assign({}, OptionCustomizationAttributesDTOInteriorFromJSONTyped(json, true), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionCustomizationAttributesDTOPaintToSampleExteriorColorFromJSONTyped(json, true), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionCustomizationAttributesDTORoofColorFromJSONTyped(json, true), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionCustomizationAttributesDTOObject exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationAttributesDTOObjectToJSON(json: any): any {
    return OptionCustomizationAttributesDTOObjectToJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOObjectToJSONTyped(value?: OptionCustomizationAttributesDTOObject | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'Equipment':
            return Object.assign({}, OptionCustomizationAttributesDTOEquipmentToJSON(value), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionCustomizationAttributesDTOExteriorColorToJSON(value), { type: 'ExteriorColor' } as const);
        case 'Interior':
            return Object.assign({}, OptionCustomizationAttributesDTOInteriorToJSON(value), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionCustomizationAttributesDTOPaintToSampleExteriorColorToJSON(value), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionCustomizationAttributesDTORoofColorToJSON(value), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionCustomizationAttributesDTOObject exists with 'type=${value['type']}'`);
    }

}


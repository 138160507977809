import { Control, FieldValues, Path, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { FieldPath, FieldPathValue } from "react-hook-form/dist/types/path"

export interface ViewFormDisplayData<DATA> {
    kind: "VIEW"
    data: DATA
}

export interface EditFormDisplayData<DATA, UPDATE extends FieldValues> {
    kind: "EDIT"
    register: UseFormRegister<UPDATE>
    setValue: UseFormSetValue<UPDATE>
    watch: UseFormWatch<UPDATE>
    control: Control<UPDATE>
    validationErrors: Map<Path<UPDATE>, string>
    data: DATA
}

export interface LoadingFormDisplayData {
    kind: "LOADING"
    data?: undefined
}

export type FormDisplayMode = FormDisplayData<any, any>["kind"]

export type FormDisplayData<DATA, UPDATE extends FieldValues> = LoadingFormDisplayData | ViewFormDisplayData<DATA> | EditFormDisplayData<DATA, UPDATE>

export type NotLoadingFormDisplayData<DATA, UPDATE extends FieldValues> = ViewFormDisplayData<DATA> | EditFormDisplayData<DATA, UPDATE>

export const getEditData = <DATA, UPDATE extends FieldValues>(formDisplayData: FormDisplayData<DATA, UPDATE>): EditFormDisplayData<DATA, UPDATE> | undefined =>
    formDisplayData.kind === "EDIT" ? formDisplayData : undefined

export type SimpleFormWatch<UPDATE extends FieldValues> = <TFieldName extends FieldPath<UPDATE>>(
    name: TFieldName
) => FieldPathValue<UPDATE, TFieldName> | undefined

export const getSimpleFormWatch = <UPDATE extends FieldValues>(formDisplayData: FormDisplayData<any, UPDATE>): SimpleFormWatch<UPDATE> =>
    getEditData(formDisplayData)?.watch ?? ((property) => formDisplayData.data?.[property])

export const mapNotLoadingFormDisplayData = <DATA_T, DATA_S, UPDATE extends FieldValues>(
    formDisplayData: NotLoadingFormDisplayData<DATA_T, UPDATE>,
    mapper: (data: DATA_T) => DATA_S
): NotLoadingFormDisplayData<DATA_S, UPDATE> => ({
    ...formDisplayData,
    data: mapper(formDisplayData.data),
})

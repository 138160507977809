/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LocalizationsItemDTO } from './LocalizationsItemDTO';
import {
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOFromJSONTyped,
    LocalizationsItemDTOToJSON,
    LocalizationsItemDTOToJSONTyped,
} from './LocalizationsItemDTO';

/**
 * 
 * @export
 * @interface OrderTypeNameItemDTO
 */
export interface OrderTypeNameItemDTO {
    /**
     * 
     * @type {LocalizationsItemDTO}
     * @memberof OrderTypeNameItemDTO
     */
    merged: LocalizationsItemDTO;
    /**
     * 
     * @type {LocalizationsItemDTO}
     * @memberof OrderTypeNameItemDTO
     */
    manual: LocalizationsItemDTO;
    /**
     * 
     * @type {LocalizationsItemDTO}
     * @memberof OrderTypeNameItemDTO
     */
    synced: LocalizationsItemDTO;
}

/**
 * Check if a given object implements the OrderTypeNameItemDTO interface.
 */
export function instanceOfOrderTypeNameItemDTO(value: object): value is OrderTypeNameItemDTO {
    if (!('merged' in value) || value['merged'] === undefined) return false;
    if (!('manual' in value) || value['manual'] === undefined) return false;
    if (!('synced' in value) || value['synced'] === undefined) return false;
    return true;
}

export function OrderTypeNameItemDTOFromJSON(json: any): OrderTypeNameItemDTO {
    return OrderTypeNameItemDTOFromJSONTyped(json, false);
}

export function OrderTypeNameItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeNameItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'merged': LocalizationsItemDTOFromJSON(json['merged']),
        'manual': LocalizationsItemDTOFromJSON(json['manual']),
        'synced': LocalizationsItemDTOFromJSON(json['synced']),
    };
}

export function OrderTypeNameItemDTOToJSON(json: any): OrderTypeNameItemDTO {
    return OrderTypeNameItemDTOToJSONTyped(json, false);
}

export function OrderTypeNameItemDTOToJSONTyped(value?: OrderTypeNameItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'merged': LocalizationsItemDTOToJSON(value['merged']),
        'manual': LocalizationsItemDTOToJSON(value['manual']),
        'synced': LocalizationsItemDTOToJSON(value['synced']),
    };
}


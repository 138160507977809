/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOExteriorColorType } from './SelectOptionDTOExteriorColorType';
import {
    SelectOptionDTOExteriorColorTypeFromJSON,
    SelectOptionDTOExteriorColorTypeFromJSONTyped,
    SelectOptionDTOExteriorColorTypeToJSON,
    SelectOptionDTOExteriorColorTypeToJSONTyped,
} from './SelectOptionDTOExteriorColorType';

/**
 * 
 * @export
 * @interface OptionalSelectDTOExteriorColorType
 */
export interface OptionalSelectDTOExteriorColorType {
    /**
     * 
     * @type {SelectOptionDTOExteriorColorType}
     * @memberof OptionalSelectDTOExteriorColorType
     */
    value?: SelectOptionDTOExteriorColorType;
    /**
     * 
     * @type {Array<SelectOptionDTOExteriorColorType>}
     * @memberof OptionalSelectDTOExteriorColorType
     */
    options: Array<SelectOptionDTOExteriorColorType>;
}

/**
 * Check if a given object implements the OptionalSelectDTOExteriorColorType interface.
 */
export function instanceOfOptionalSelectDTOExteriorColorType(value: object): value is OptionalSelectDTOExteriorColorType {
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function OptionalSelectDTOExteriorColorTypeFromJSON(json: any): OptionalSelectDTOExteriorColorType {
    return OptionalSelectDTOExteriorColorTypeFromJSONTyped(json, false);
}

export function OptionalSelectDTOExteriorColorTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalSelectDTOExteriorColorType {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : SelectOptionDTOExteriorColorTypeFromJSON(json['value']),
        'options': ((json['options'] as Array<any>).map(SelectOptionDTOExteriorColorTypeFromJSON)),
    };
}

export function OptionalSelectDTOExteriorColorTypeToJSON(json: any): OptionalSelectDTOExteriorColorType {
    return OptionalSelectDTOExteriorColorTypeToJSONTyped(json, false);
}

export function OptionalSelectDTOExteriorColorTypeToJSONTyped(value?: OptionalSelectDTOExteriorColorType | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': SelectOptionDTOExteriorColorTypeToJSON(value['value']),
        'options': ((value['options'] as Array<any>).map(SelectOptionDTOExteriorColorTypeToJSON)),
    };
}


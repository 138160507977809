/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationAttributesDTOExteriorColorValue } from './OptionCustomizationAttributesDTOExteriorColorValue';
import {
    OptionCustomizationAttributesDTOExteriorColorValueFromJSON,
    OptionCustomizationAttributesDTOExteriorColorValueFromJSONTyped,
    OptionCustomizationAttributesDTOExteriorColorValueToJSON,
    OptionCustomizationAttributesDTOExteriorColorValueToJSONTyped,
} from './OptionCustomizationAttributesDTOExteriorColorValue';
import type { IntRange } from './IntRange';
import {
    IntRangeFromJSON,
    IntRangeFromJSONTyped,
    IntRangeToJSON,
    IntRangeToJSONTyped,
} from './IntRange';

/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue
 */
export interface OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue
     */
    key: string;
    /**
     * 
     * @type {IntRange}
     * @memberof OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue
     */
    modelYearRange?: IntRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue
     */
    derivativeKeys?: Array<string>;
    /**
     * 
     * @type {OptionCustomizationAttributesDTOExteriorColorValue}
     * @memberof OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue
     */
    value: OptionCustomizationAttributesDTOExteriorColorValue;
}

/**
 * Check if a given object implements the OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue interface.
 */
export function instanceOfOptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue(value: object): value is OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueFromJSON(json: any): OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue {
    return OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelYearRange': json['modelYearRange'] == null ? undefined : IntRangeFromJSON(json['modelYearRange']),
        'derivativeKeys': json['derivativeKeys'] == null ? undefined : json['derivativeKeys'],
        'value': OptionCustomizationAttributesDTOExteriorColorValueFromJSON(json['value']),
    };
}

export function OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueToJSON(json: any): OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue {
    return OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueToJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueToJSONTyped(value?: OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'modelYearRange': IntRangeToJSON(value['modelYearRange']),
        'derivativeKeys': value['derivativeKeys'],
        'value': OptionCustomizationAttributesDTOExteriorColorValueToJSON(value['value']),
    };
}


import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { DerivativeFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { DerivativeFilter } from "../../../../../pdsapi"
import { FunctionComponent } from "react"
import { MultiSelectFilter } from "../../../../../components/filter/MultiSelectFilter"
import { GroupedMultiSelectFilter } from "../../../../../components/filter/GroupedMultiSelectFilter"

export const DerivativeListFilter: FunctionComponent<TableFilterProps<DerivativeFilter, DerivativeFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const modelSeriesFilter = (
        <MultiSelectFilter
            label={"Model Series"}
            options={filterOptions?.modelSeries}
            value={filter.modelSeriesKeys}
            onChange={(modelSeriesKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelSeriesKeys,
                }))
            }
        />
    )

    const relevantDerivativeCategories = filter.modelSeriesKeys
        ? filterOptions?.modelSeriesRelatedDerivativeCategories.filter((modelSeriesGroup) => filter.modelSeriesKeys!.has(modelSeriesGroup.relatedKey))
        : filterOptions?.modelSeriesRelatedDerivativeCategories
    const derivativeCategoryFilter = (
        <GroupedMultiSelectFilter
            label={"Derivative Category"}
            options={relevantDerivativeCategories}
            value={filter.derivativeCategoryKeys}
            onChange={(derivativeCategoryKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    derivativeCategoryKeys,
                }))
            }
        />
    )

    const bodyTypeFilter = (
        <MultiSelectFilter
            label={"Body Types"}
            options={filterOptions?.bodyTypes}
            value={filter.bodyTypes}
            onChange={(bodyTypes) =>
                setFilter((filter) => ({
                    ...filter,
                    bodyTypes,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Model"}>
                {modelSeriesFilter}
                {derivativeCategoryFilter}
                {bodyTypeFilter}
            </ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}

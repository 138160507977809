/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RelatedOptionDTOStringString } from './RelatedOptionDTOStringString';
import {
    RelatedOptionDTOStringStringFromJSON,
    RelatedOptionDTOStringStringFromJSONTyped,
    RelatedOptionDTOStringStringToJSON,
    RelatedOptionDTOStringStringToJSONTyped,
} from './RelatedOptionDTOStringString';

/**
 * 
 * @export
 * @interface RelatedGroupedRelatedOptionDTOIntegerStringString
 */
export interface RelatedGroupedRelatedOptionDTOIntegerStringString {
    /**
     * 
     * @type {string}
     * @memberof RelatedGroupedRelatedOptionDTOIntegerStringString
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof RelatedGroupedRelatedOptionDTOIntegerStringString
     */
    relatedKey: number;
    /**
     * 
     * @type {Array<RelatedOptionDTOStringString>}
     * @memberof RelatedGroupedRelatedOptionDTOIntegerStringString
     */
    options: Array<RelatedOptionDTOStringString>;
}

/**
 * Check if a given object implements the RelatedGroupedRelatedOptionDTOIntegerStringString interface.
 */
export function instanceOfRelatedGroupedRelatedOptionDTOIntegerStringString(value: object): value is RelatedGroupedRelatedOptionDTOIntegerStringString {
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('relatedKey' in value) || value['relatedKey'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSON(json: any): RelatedGroupedRelatedOptionDTOIntegerStringString {
    return RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSONTyped(json, false);
}

export function RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedGroupedRelatedOptionDTOIntegerStringString {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'],
        'relatedKey': json['relatedKey'],
        'options': ((json['options'] as Array<any>).map(RelatedOptionDTOStringStringFromJSON)),
    };
}

export function RelatedGroupedRelatedOptionDTOIntegerStringStringToJSON(json: any): RelatedGroupedRelatedOptionDTOIntegerStringString {
    return RelatedGroupedRelatedOptionDTOIntegerStringStringToJSONTyped(json, false);
}

export function RelatedGroupedRelatedOptionDTOIntegerStringStringToJSONTyped(value?: RelatedGroupedRelatedOptionDTOIntegerStringString | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'label': value['label'],
        'relatedKey': value['relatedKey'],
        'options': ((value['options'] as Array<any>).map(RelatedOptionDTOStringStringToJSON)),
    };
}


import { MultiSelect } from "@finder/ui-kit"
import { toGroupedOption } from "../formelements/groupedselecttextformelement/GroupedSelectOption"

interface GroupedMultiSelectFilterProps<T> {
    label: string
    options: { label: string; options: { key: T; label: string }[] }[] | undefined
    value: Set<T> | undefined
    onChange: (value: Set<T> | undefined) => void
}

export const GroupedMultiSelectFilter = <T extends string>({ label, options, value, onChange }: GroupedMultiSelectFilterProps<T>) => (
    <MultiSelect
        label={label}
        placeholder={"All"}
        clearable={true}
        searchable={true}
        options={options?.map(toGroupedOption)}
        value={value ? Array.from(value) : []}
        onChange={(keys) => onChange(keys.length === 0 ? undefined : new Set(keys as T[]))}
    />
)

import { DefaultCardViewContentWrapper } from "../../../../components/layouts/defaultcardviewcontentwrapper/DefaultCardViewContentWrapper"
import { ColorCreateRequestDTO, ColorKeyDTO } from "../../../../generated/pdsapi"
import { colorApi } from "../../../../pdsapi"
import { getProductCreateContainer, ProductCreateContainerConfig } from "../../../../viewtemplates/create/ProductCreateContainer"
import { ColorCreateAttributesCard } from "./attributes/ColorCreateAttributesCard"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"
import { ColorListContainerConfig } from "../list/ColorListRoute"

export const ColorCreateContainerConfig: ProductCreateContainerConfig<ColorCreateRequestDTO, unknown, ColorKeyDTO> = {
    headline: "Create new Color",
    buildDetailsPath: (key) => routes.options.colors.details(key.colorCode),

    create: (item) => colorApi.create.create({ colorCreateRequestDTO: item }),
    getCreateOptions: async () => ({}),

    Content: (props) => {
        return (
            <DefaultCardViewContentWrapper>
                <ColorCreateAttributesCard {...props} />
            </DefaultCardViewContentWrapper>
        )
    },
}

const ColorCreateContainer = getProductCreateContainer(ColorCreateContainerConfig)

export const ColorCreateRoute = (
    <Route
        path={routes.options.colors.create()}
        element={
            <ColorCreateContainer
                parentCrumbs={[
                    {
                        name: ColorListContainerConfig.listName,
                        path: routes.options.colors.list(),
                    },
                ]}
            />
        }
    />
)

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionalSelectDTOExteriorColorType } from './OptionalSelectDTOExteriorColorType';
import {
    OptionalSelectDTOExteriorColorTypeFromJSON,
    OptionalSelectDTOExteriorColorTypeFromJSONTyped,
    OptionalSelectDTOExteriorColorTypeToJSON,
    OptionalSelectDTOExteriorColorTypeToJSONTyped,
} from './OptionalSelectDTOExteriorColorType';
import type { OptionalSelectDTOString } from './OptionalSelectDTOString';
import {
    OptionalSelectDTOStringFromJSON,
    OptionalSelectDTOStringFromJSONTyped,
    OptionalSelectDTOStringToJSON,
    OptionalSelectDTOStringToJSONTyped,
} from './OptionalSelectDTOString';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface CustomExteriorColorAttributesDTO
 */
export interface CustomExteriorColorAttributesDTO {
    /**
     * 
     * @type {string}
     * @memberof CustomExteriorColorAttributesDTO
     */
    key: string;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof CustomExteriorColorAttributesDTO
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof CustomExteriorColorAttributesDTO
     */
    hexCode?: string;
    /**
     * 
     * @type {OptionalSelectDTOString}
     * @memberof CustomExteriorColorAttributesDTO
     */
    exteriorColorTag: OptionalSelectDTOString;
    /**
     * 
     * @type {OptionalSelectDTOExteriorColorType}
     * @memberof CustomExteriorColorAttributesDTO
     */
    exteriorColorType: OptionalSelectDTOExteriorColorType;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof CustomExteriorColorAttributesDTO
     */
    linkedColors: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the CustomExteriorColorAttributesDTO interface.
 */
export function instanceOfCustomExteriorColorAttributesDTO(value: object): value is CustomExteriorColorAttributesDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('exteriorColorTag' in value) || value['exteriorColorTag'] === undefined) return false;
    if (!('exteriorColorType' in value) || value['exteriorColorType'] === undefined) return false;
    if (!('linkedColors' in value) || value['linkedColors'] === undefined) return false;
    return true;
}

export function CustomExteriorColorAttributesDTOFromJSON(json: any): CustomExteriorColorAttributesDTO {
    return CustomExteriorColorAttributesDTOFromJSONTyped(json, false);
}

export function CustomExteriorColorAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomExteriorColorAttributesDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': BaseNameDTOFromJSON(json['name']),
        'hexCode': json['hexCode'] == null ? undefined : json['hexCode'],
        'exteriorColorTag': OptionalSelectDTOStringFromJSON(json['exteriorColorTag']),
        'exteriorColorType': OptionalSelectDTOExteriorColorTypeFromJSON(json['exteriorColorType']),
        'linkedColors': ((json['linkedColors'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function CustomExteriorColorAttributesDTOToJSON(json: any): CustomExteriorColorAttributesDTO {
    return CustomExteriorColorAttributesDTOToJSONTyped(json, false);
}

export function CustomExteriorColorAttributesDTOToJSONTyped(value?: CustomExteriorColorAttributesDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'name': BaseNameDTOToJSON(value['name']),
        'hexCode': value['hexCode'],
        'exteriorColorTag': OptionalSelectDTOStringToJSON(value['exteriorColorTag']),
        'exteriorColorType': OptionalSelectDTOExteriorColorTypeToJSON(value['exteriorColorType']),
        'linkedColors': ((value['linkedColors'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


import { useJsonMemorizedValue } from "../hooks/useJsonMemorizedValue"
import { useCallback, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"

type StateHook<T> = readonly [T, (value: ((prevState: T) => T) | T, action?: "push" | "replace") => void]

export type URLParamConverter<T> = {
    read: (name: string, searchParams: URLSearchParams, initialState: T) => T
    update: (name: string, value: T, searchParams: URLSearchParams, initialState: T) => void
}
export const useURLSearchParamState = <T>(name: string, initialState: T, converter: URLParamConverter<T>): StateHook<T> => {
    const navigate = useNavigate()
    const location = useLocation()

    const urlValue = useJsonMemorizedValue(converter.read(name, new URLSearchParams(location.search), initialState))

    const setURLSearchParamsRef = useRef<(valueOrFn: ((previousValue: T) => T) | T, action?: "push" | "replace") => void>(() => undefined)
    setURLSearchParamsRef.current = (valueOrFn: ((previousValue: T) => T) | T, action?: "push" | "replace") => {
        const searchParams = new URLSearchParams(location.search)

        const value = typeof valueOrFn !== "function" ? (valueOrFn as T) : (valueOrFn as (previousValue: T) => T)(urlValue)
        converter.update(name, value, searchParams, initialState)

        searchParams.sort()
        const searchParamsString = searchParams.toString()
        const path = `${location.pathname}${searchParamsString.length > 0 ? `?${searchParamsString}` : ""}`
        if (action === "push") {
            navigate(path)
        } else {
            navigate(path, { replace: true })
        }
    }

    const setURLSearchParams = useCallback(
        (valueOrFn: ((previousValue: T) => T) | T, action?: "push" | "replace") => setURLSearchParamsRef.current(valueOrFn, action),
        []
    )

    return [urlValue, setURLSearchParams] as const
}

export type URLParamConverterGroup<T extends NonNullable<unknown>> = {
    required: URLParamConverter<T>
    optional: URLParamConverter<T | undefined>
    array: URLParamConverter<T[]>
    optionalArray: URLParamConverter<T[] | undefined>
    set: URLParamConverter<Set<T>>
    optionalSet: URLParamConverter<Set<T> | undefined>
}
const getConverterGroup = <T extends NonNullable<unknown>>(props: {
    serialize: (value: T) => string
    deserialize: (string: string) => T | undefined
}): URLParamConverterGroup<T> => {
    const arrayEquals = (a: T[], b?: T[]) => a.length === b?.length && a.every((val, index) => val === b[index])

    const arrayConverter: URLParamConverter<T[]> = {
        read: (name, searchParams, initialState) => {
            const values = searchParams
                .getAll(name)
                .map(props.deserialize)
                .filter((value) => value !== undefined) as T[]
            return values.length > 0 ? values : initialState
        },
        update: (name, values, searchParams, initialState) => {
            searchParams.delete(name)

            if (!arrayEquals(values, initialState)) {
                values.forEach((value) => searchParams.append(name, props.serialize(value)))
            }
        },
    }

    const optionalArrayConverter: URLParamConverter<T[] | undefined> = {
        read: (name, searchParams, initialState) => {
            const values = searchParams
                .getAll(name)
                .map(props.deserialize)
                .filter((value) => value !== undefined) as T[]
            return values.length > 0 ? values : initialState
        },
        update: (name, values, searchParams, initialState) => {
            searchParams.delete(name)

            if (values !== undefined && !arrayEquals(values, initialState)) {
                values.forEach((value) => searchParams.append(name, props.serialize(value)))
            }
        },
    }

    return {
        required: {
            read: (name, searchParams, initialState) => {
                const values = searchParams.getAll(name)
                const value = values.length === 1 ? props.deserialize(values[0]) : undefined
                return value ?? initialState
            },
            update: (name, value, searchParams, initialState: T) => {
                searchParams.delete(name)
                if (value !== initialState) {
                    searchParams.set(name, props.serialize(value))
                }
            },
        },
        optional: {
            read: (name, searchParams, initialState) => {
                const values = searchParams.getAll(name)
                const value = values.length === 1 ? props.deserialize(values[0]) : undefined
                return value ?? initialState
            },
            update: (name, value, searchParams, initialState) => {
                searchParams.delete(name)
                if (value !== undefined && value !== initialState) {
                    searchParams.set(name, props.serialize(value))
                }
            },
        },
        array: arrayConverter,
        optionalArray: optionalArrayConverter,
        set: {
            read: (name, searchParams, initialState) => new Set(arrayConverter.read(name, searchParams, Array.from(initialState))),
            update: (name, value, searchParams, initialState) => arrayConverter.update(name, Array.from(value), searchParams, Array.from(initialState)),
        },
        optionalSet: {
            read: (name, searchParams, initialState) => {
                const array = optionalArrayConverter.read(name, searchParams, initialState && Array.from(initialState))
                return array && new Set(array)
            },
            update: (name, value, searchParams, initialState) =>
                optionalArrayConverter.update(name, value && Array.from(value), searchParams, initialState && Array.from(initialState)),
        },
    }
}

export const stringConverterGroup: URLParamConverterGroup<string> = getConverterGroup({
    serialize: (string) => string,
    deserialize: (string) => (string !== "" ? string : undefined),
})

export const numberConverterGroup: URLParamConverterGroup<number> = getConverterGroup({
    serialize: (number) => number.toString(),
    deserialize: (string: string) => {
        const number = +string
        return !isNaN(number) ? number : undefined
    },
})

export const booleanConverterGroup: URLParamConverterGroup<boolean> = getConverterGroup({
    serialize: (bool) => (bool ? "true" : "false"),
    deserialize: (string: string) => {
        switch (string) {
            case "true":
                return true
            case "false":
                return false
            default:
                return undefined
        }
    },
})

export const getEnumConverterGroup = <T extends string>(enumDefinition: { [index: string]: T | undefined }): URLParamConverterGroup<T> =>
    getConverterGroup({
        serialize: (enumValue) => enumValue,
        deserialize: (string) => enumDefinition[string],
    })

export type ChildrenURLParamsConverter<T extends NonNullable<unknown>> = {
    readonly [P in keyof T & string]: URLParamConverter<T[P]>
}
export const getObjectURLParamConverter = <T extends Record<string, any>>(childrenConverter: ChildrenURLParamsConverter<T>): URLParamConverter<T> => {
    const childNames = Object.keys(childrenConverter)

    return {
        read: (name, searchParams, initialValue) => {
            const value = Object.fromEntries(
                childNames
                    .map((childName) => {
                        const childConverter: URLParamConverter<any> = childrenConverter[childName]
                        const initialChildValue: any = initialValue[childName]

                        return [childName, childConverter.read(`${name}.${childName}`, searchParams, initialChildValue)]
                    })
                    .filter(([, childValue]) => childValue !== undefined)
            ) as T

            return {
                ...initialValue,
                ...value,
            }
        },
        update: (name, value, searchParams, initialValue) => {
            childNames.forEach((childName) => {
                const childValue: any = value[childName]
                const childConverter: URLParamConverter<any> = childrenConverter[childName]
                const initialChildValue: any = initialValue[childName]

                childConverter.update(`${name}.${childName}`, childValue, searchParams, initialChildValue)
            })
        },
    }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';

/**
 * 
 * @export
 * @interface OptionCustomizationCreateRequestDTORoofColor
 */
export interface OptionCustomizationCreateRequestDTORoofColor {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationCreateRequestDTORoofColor
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationCreateRequestDTORoofColor
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationCreateRequestDTORoofColor
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationCreateRequestDTORoofColor
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationCreateRequestDTORoofColor
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationCreateRequestDTORoofColor
     */
    hexCode: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationCreateRequestDTORoofColor
     */
    roofColorTagKey: string;
}

/**
 * Check if a given object implements the OptionCustomizationCreateRequestDTORoofColor interface.
 */
export function instanceOfOptionCustomizationCreateRequestDTORoofColor(value: object): value is OptionCustomizationCreateRequestDTORoofColor {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelGenerationKeys' in value) || value['modelGenerationKeys'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('isDefaultValueActive' in value) || value['isDefaultValueActive'] === undefined) return false;
    if (!('hexCode' in value) || value['hexCode'] === undefined) return false;
    if (!('roofColorTagKey' in value) || value['roofColorTagKey'] === undefined) return false;
    return true;
}

export function OptionCustomizationCreateRequestDTORoofColorFromJSON(json: any): OptionCustomizationCreateRequestDTORoofColor {
    return OptionCustomizationCreateRequestDTORoofColorFromJSONTyped(json, false);
}

export function OptionCustomizationCreateRequestDTORoofColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationCreateRequestDTORoofColor {
    if (json == null) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'description': BaseNameDTOFromJSON(json['description']),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'hexCode': json['hexCode'],
        'roofColorTagKey': json['roofColorTagKey'],
    };
}

export function OptionCustomizationCreateRequestDTORoofColorToJSON(json: any): OptionCustomizationCreateRequestDTORoofColor {
    return OptionCustomizationCreateRequestDTORoofColorToJSONTyped(json, false);
}

export function OptionCustomizationCreateRequestDTORoofColorToJSONTyped(value?: OptionCustomizationCreateRequestDTORoofColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': BaseNameDTOToJSON(value['name']),
        'optionCode': value['optionCode'],
        'modelGenerationKeys': value['modelGenerationKeys'],
        'description': BaseNameDTOToJSON(value['description']),
        'isDefaultValueActive': value['isDefaultValueActive'],
        'hexCode': value['hexCode'],
        'roofColorTagKey': value['roofColorTagKey'],
    };
}


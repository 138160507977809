/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EquipmentTagCategory,
  OptionTagFilterOptionsDTO,
  OptionTagSortField,
  OptionTagTypeDTO,
  PageDTOOptionTagItemDTO,
  SortDirection,
} from '../models/index';
import {
    EquipmentTagCategoryFromJSON,
    EquipmentTagCategoryToJSON,
    OptionTagFilterOptionsDTOFromJSON,
    OptionTagFilterOptionsDTOToJSON,
    OptionTagSortFieldFromJSON,
    OptionTagSortFieldToJSON,
    OptionTagTypeDTOFromJSON,
    OptionTagTypeDTOToJSON,
    PageDTOOptionTagItemDTOFromJSON,
    PageDTOOptionTagItemDTOToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models/index';

export interface GetOptionTagFilterOptionsRequest {
    languageTag: string;
}

export interface GetOptionTagsPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: OptionTagSortField;
    sortDirection: SortDirection;
    types?: Set<OptionTagTypeDTO>;
    equipmentTagCategories?: Set<EquipmentTagCategory>;
    search?: string;
    active?: boolean;
}

/**
 * 
 */
export class OptionTagListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter OptionTags
     */
    async getOptionTagFilterOptionsRaw(requestParameters: GetOptionTagFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionTagFilterOptionsDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getOptionTagFilterOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-tags/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionTagFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter OptionTags
     */
    async getOptionTagFilterOptions(requestParameters: GetOptionTagFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionTagFilterOptionsDTO> {
        const response = await this.getOptionTagFilterOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get page of OptionTags
     */
    async getOptionTagsPageRaw(requestParameters: GetOptionTagsPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOOptionTagItemDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getOptionTagsPage().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getOptionTagsPage().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getOptionTagsPage().'
            );
        }

        if (requestParameters['sortField'] == null) {
            throw new runtime.RequiredError(
                'sortField',
                'Required parameter "sortField" was null or undefined when calling getOptionTagsPage().'
            );
        }

        if (requestParameters['sortDirection'] == null) {
            throw new runtime.RequiredError(
                'sortDirection',
                'Required parameter "sortDirection" was null or undefined when calling getOptionTagsPage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sortField'] != null) {
            queryParameters['sortField'] = requestParameters['sortField'];
        }

        if (requestParameters['sortDirection'] != null) {
            queryParameters['sortDirection'] = requestParameters['sortDirection'];
        }

        if (requestParameters['types'] != null) {
            queryParameters['types'] = requestParameters['types'];
        }

        if (requestParameters['equipmentTagCategories'] != null) {
            queryParameters['equipmentTagCategories'] = requestParameters['equipmentTagCategories'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['active'] != null) {
            queryParameters['active'] = requestParameters['active'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-tags/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOOptionTagItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of OptionTags
     */
    async getOptionTagsPage(requestParameters: GetOptionTagsPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOOptionTagItemDTO> {
        const response = await this.getOptionTagsPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InteriorColorTileDTO } from './InteriorColorTileDTO';
import {
    InteriorColorTileDTOFromJSON,
    InteriorColorTileDTOFromJSONTyped,
    InteriorColorTileDTOToJSON,
    InteriorColorTileDTOToJSONTyped,
} from './InteriorColorTileDTO';

/**
 * 
 * @export
 * @interface MissingOptionCustomizationDTOInteriorCustomization
 */
export interface MissingOptionCustomizationDTOInteriorCustomization {
    /**
     * 
     * @type {string}
     * @memberof MissingOptionCustomizationDTOInteriorCustomization
     */
    key: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MissingOptionCustomizationDTOInteriorCustomization
     */
    modelGenerationNames: Array<string>;
    /**
     * 
     * @type {InteriorColorTileDTO}
     * @memberof MissingOptionCustomizationDTOInteriorCustomization
     */
    color: InteriorColorTileDTO;
    /**
     * 
     * @type {string}
     * @memberof MissingOptionCustomizationDTOInteriorCustomization
     */
    materialName: string;
}

/**
 * Check if a given object implements the MissingOptionCustomizationDTOInteriorCustomization interface.
 */
export function instanceOfMissingOptionCustomizationDTOInteriorCustomization(value: object): value is MissingOptionCustomizationDTOInteriorCustomization {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('modelGenerationNames' in value) || value['modelGenerationNames'] === undefined) return false;
    if (!('color' in value) || value['color'] === undefined) return false;
    if (!('materialName' in value) || value['materialName'] === undefined) return false;
    return true;
}

export function MissingOptionCustomizationDTOInteriorCustomizationFromJSON(json: any): MissingOptionCustomizationDTOInteriorCustomization {
    return MissingOptionCustomizationDTOInteriorCustomizationFromJSONTyped(json, false);
}

export function MissingOptionCustomizationDTOInteriorCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissingOptionCustomizationDTOInteriorCustomization {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelGenerationNames': json['modelGenerationNames'],
        'color': InteriorColorTileDTOFromJSON(json['color']),
        'materialName': json['materialName'],
    };
}

export function MissingOptionCustomizationDTOInteriorCustomizationToJSON(json: any): MissingOptionCustomizationDTOInteriorCustomization {
    return MissingOptionCustomizationDTOInteriorCustomizationToJSONTyped(json, false);
}

export function MissingOptionCustomizationDTOInteriorCustomizationToJSONTyped(value?: MissingOptionCustomizationDTOInteriorCustomization | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'modelGenerationNames': value['modelGenerationNames'],
        'color': InteriorColorTileDTOToJSON(value['color']),
        'materialName': value['materialName'],
    };
}


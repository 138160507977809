/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MarketplaceDTOLanguage } from './MarketplaceDTOLanguage';
import {
    MarketplaceDTOLanguageFromJSON,
    MarketplaceDTOLanguageFromJSONTyped,
    MarketplaceDTOLanguageToJSON,
    MarketplaceDTOLanguageToJSONTyped,
} from './MarketplaceDTOLanguage';
import type { PdsMarketplace } from './PdsMarketplace';
import {
    PdsMarketplaceFromJSON,
    PdsMarketplaceFromJSONTyped,
    PdsMarketplaceToJSON,
    PdsMarketplaceToJSONTyped,
} from './PdsMarketplace';

/**
 * 
 * @export
 * @interface MarketplaceDTO
 */
export interface MarketplaceDTO {
    /**
     * 
     * @type {PdsMarketplace}
     * @memberof MarketplaceDTO
     */
    marketplace: PdsMarketplace;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceDTO
     */
    label: string;
    /**
     * 
     * @type {Array<MarketplaceDTOLanguage>}
     * @memberof MarketplaceDTO
     */
    languages: Array<MarketplaceDTOLanguage>;
}



/**
 * Check if a given object implements the MarketplaceDTO interface.
 */
export function instanceOfMarketplaceDTO(value: object): value is MarketplaceDTO {
    if (!('marketplace' in value) || value['marketplace'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('languages' in value) || value['languages'] === undefined) return false;
    return true;
}

export function MarketplaceDTOFromJSON(json: any): MarketplaceDTO {
    return MarketplaceDTOFromJSONTyped(json, false);
}

export function MarketplaceDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketplaceDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'marketplace': PdsMarketplaceFromJSON(json['marketplace']),
        'label': json['label'],
        'languages': ((json['languages'] as Array<any>).map(MarketplaceDTOLanguageFromJSON)),
    };
}

export function MarketplaceDTOToJSON(json: any): MarketplaceDTO {
    return MarketplaceDTOToJSONTyped(json, false);
}

export function MarketplaceDTOToJSONTyped(value?: MarketplaceDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'marketplace': PdsMarketplaceToJSON(value['marketplace']),
        'label': value['label'],
        'languages': ((value['languages'] as Array<any>).map(MarketplaceDTOLanguageToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationAttributesDTORoofColorValue } from './OptionCustomizationAttributesDTORoofColorValue';
import {
    OptionCustomizationAttributesDTORoofColorValueFromJSON,
    OptionCustomizationAttributesDTORoofColorValueFromJSONTyped,
    OptionCustomizationAttributesDTORoofColorValueToJSON,
    OptionCustomizationAttributesDTORoofColorValueToJSONTyped,
} from './OptionCustomizationAttributesDTORoofColorValue';
import type { OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue } from './OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue';
import {
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValueFromJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValueFromJSONTyped,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValueToJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValueToJSONTyped,
} from './OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';
import type { MultiSelectDTOString } from './MultiSelectDTOString';
import {
    MultiSelectDTOStringFromJSON,
    MultiSelectDTOStringFromJSONTyped,
    MultiSelectDTOStringToJSON,
    MultiSelectDTOStringToJSONTyped,
} from './MultiSelectDTOString';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesDTORoofColor
 */
export interface OptionCustomizationAttributesDTORoofColor {
    /**
     * 
     * @type {OptionCustomizationAttributesDTORoofColorValue}
     * @memberof OptionCustomizationAttributesDTORoofColor
     */
    defaultValue: OptionCustomizationAttributesDTORoofColorValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTORoofColor
     */
    optionCode: string;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue>}
     * @memberof OptionCustomizationAttributesDTORoofColor
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesDTORoofColor
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {MultiSelectDTOString}
     * @memberof OptionCustomizationAttributesDTORoofColor
     */
    modelGenerations: MultiSelectDTOString;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationAttributesDTORoofColor
     */
    derivatives: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the OptionCustomizationAttributesDTORoofColor interface.
 */
export function instanceOfOptionCustomizationAttributesDTORoofColor(value: object): value is OptionCustomizationAttributesDTORoofColor {
    if (!('defaultValue' in value) || value['defaultValue'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('conditionalValues' in value) || value['conditionalValues'] === undefined) return false;
    if (!('isDefaultValueActive' in value) || value['isDefaultValueActive'] === undefined) return false;
    if (!('modelGenerations' in value) || value['modelGenerations'] === undefined) return false;
    if (!('derivatives' in value) || value['derivatives'] === undefined) return false;
    return true;
}

export function OptionCustomizationAttributesDTORoofColorFromJSON(json: any): OptionCustomizationAttributesDTORoofColor {
    return OptionCustomizationAttributesDTORoofColorFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTORoofColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTORoofColor {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesDTORoofColorValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValueFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'modelGenerations': MultiSelectDTOStringFromJSON(json['modelGenerations']),
        'derivatives': ((json['derivatives'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function OptionCustomizationAttributesDTORoofColorToJSON(json: any): OptionCustomizationAttributesDTORoofColor {
    return OptionCustomizationAttributesDTORoofColorToJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTORoofColorToJSONTyped(value?: OptionCustomizationAttributesDTORoofColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultValue': OptionCustomizationAttributesDTORoofColorValueToJSON(value['defaultValue']),
        'optionCode': value['optionCode'],
        'conditionalValues': ((value['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValueToJSON)),
        'isDefaultValueActive': value['isDefaultValueActive'],
        'modelGenerations': MultiSelectDTOStringToJSON(value['modelGenerations']),
        'derivatives': ((value['derivatives'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


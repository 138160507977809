/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RelatedOptionDTOStringString } from './RelatedOptionDTOStringString';
import {
    RelatedOptionDTOStringStringFromJSON,
    RelatedOptionDTOStringStringFromJSONTyped,
    RelatedOptionDTOStringStringToJSON,
    RelatedOptionDTOStringStringToJSONTyped,
} from './RelatedOptionDTOStringString';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface ModelGenerationCreateOptionsDTO
 */
export interface ModelGenerationCreateOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof ModelGenerationCreateOptionsDTO
     */
    modelSeries: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<RelatedOptionDTOStringString>}
     * @memberof ModelGenerationCreateOptionsDTO
     */
    modelSeriesRelatedModelGenerations: Array<RelatedOptionDTOStringString>;
}

/**
 * Check if a given object implements the ModelGenerationCreateOptionsDTO interface.
 */
export function instanceOfModelGenerationCreateOptionsDTO(value: object): value is ModelGenerationCreateOptionsDTO {
    if (!('modelSeries' in value) || value['modelSeries'] === undefined) return false;
    if (!('modelSeriesRelatedModelGenerations' in value) || value['modelSeriesRelatedModelGenerations'] === undefined) return false;
    return true;
}

export function ModelGenerationCreateOptionsDTOFromJSON(json: any): ModelGenerationCreateOptionsDTO {
    return ModelGenerationCreateOptionsDTOFromJSONTyped(json, false);
}

export function ModelGenerationCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelGenerationCreateOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'modelSeries': ((json['modelSeries'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'modelSeriesRelatedModelGenerations': ((json['modelSeriesRelatedModelGenerations'] as Array<any>).map(RelatedOptionDTOStringStringFromJSON)),
    };
}

export function ModelGenerationCreateOptionsDTOToJSON(json: any): ModelGenerationCreateOptionsDTO {
    return ModelGenerationCreateOptionsDTOToJSONTyped(json, false);
}

export function ModelGenerationCreateOptionsDTOToJSONTyped(value?: ModelGenerationCreateOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'modelSeries': ((value['modelSeries'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'modelSeriesRelatedModelGenerations': ((value['modelSeriesRelatedModelGenerations'] as Array<any>).map(RelatedOptionDTOStringStringToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimilarCustomizationsDTORoofColor
 */
export interface SimilarCustomizationsDTORoofColor {
    /**
     * 
     * @type {string}
     * @memberof SimilarCustomizationsDTORoofColor
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof SimilarCustomizationsDTORoofColor
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof SimilarCustomizationsDTORoofColor
     */
    hexCode: string;
}

/**
 * Check if a given object implements the SimilarCustomizationsDTORoofColor interface.
 */
export function instanceOfSimilarCustomizationsDTORoofColor(value: object): value is SimilarCustomizationsDTORoofColor {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('hexCode' in value) || value['hexCode'] === undefined) return false;
    return true;
}

export function SimilarCustomizationsDTORoofColorFromJSON(json: any): SimilarCustomizationsDTORoofColor {
    return SimilarCustomizationsDTORoofColorFromJSONTyped(json, false);
}

export function SimilarCustomizationsDTORoofColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilarCustomizationsDTORoofColor {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'label': json['label'],
        'hexCode': json['hexCode'],
    };
}

export function SimilarCustomizationsDTORoofColorToJSON(json: any): SimilarCustomizationsDTORoofColor {
    return SimilarCustomizationsDTORoofColorToJSONTyped(json, false);
}

export function SimilarCustomizationsDTORoofColorToJSONTyped(value?: SimilarCustomizationsDTORoofColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'label': value['label'],
        'hexCode': value['hexCode'],
    };
}


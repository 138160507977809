/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { LinkedCustomizationDTOCustomizationValueEquipment } from './LinkedCustomizationDTOCustomizationValueEquipment';
import {
    instanceOfLinkedCustomizationDTOCustomizationValueEquipment,
    LinkedCustomizationDTOCustomizationValueEquipmentFromJSON,
    LinkedCustomizationDTOCustomizationValueEquipmentFromJSONTyped,
    LinkedCustomizationDTOCustomizationValueEquipmentToJSON,
} from './LinkedCustomizationDTOCustomizationValueEquipment';
import type { LinkedCustomizationDTOCustomizationValueExteriorColor } from './LinkedCustomizationDTOCustomizationValueExteriorColor';
import {
    instanceOfLinkedCustomizationDTOCustomizationValueExteriorColor,
    LinkedCustomizationDTOCustomizationValueExteriorColorFromJSON,
    LinkedCustomizationDTOCustomizationValueExteriorColorFromJSONTyped,
    LinkedCustomizationDTOCustomizationValueExteriorColorToJSON,
} from './LinkedCustomizationDTOCustomizationValueExteriorColor';
import type { LinkedCustomizationDTOCustomizationValueInterior } from './LinkedCustomizationDTOCustomizationValueInterior';
import {
    instanceOfLinkedCustomizationDTOCustomizationValueInterior,
    LinkedCustomizationDTOCustomizationValueInteriorFromJSON,
    LinkedCustomizationDTOCustomizationValueInteriorFromJSONTyped,
    LinkedCustomizationDTOCustomizationValueInteriorToJSON,
} from './LinkedCustomizationDTOCustomizationValueInterior';
import type { LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColor } from './LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColor';
import {
    instanceOfLinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColor,
    LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorFromJSON,
    LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorFromJSONTyped,
    LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorToJSON,
} from './LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColor';
import type { LinkedCustomizationDTOCustomizationValueRoofColor } from './LinkedCustomizationDTOCustomizationValueRoofColor';
import {
    instanceOfLinkedCustomizationDTOCustomizationValueRoofColor,
    LinkedCustomizationDTOCustomizationValueRoofColorFromJSON,
    LinkedCustomizationDTOCustomizationValueRoofColorFromJSONTyped,
    LinkedCustomizationDTOCustomizationValueRoofColorToJSON,
} from './LinkedCustomizationDTOCustomizationValueRoofColor';

/**
 * @type LinkedCustomizationDTOCustomizationValue
 * 
 * @export
 */
export type LinkedCustomizationDTOCustomizationValue = { type: 'Equipment' } & LinkedCustomizationDTOCustomizationValueEquipment | { type: 'ExteriorColor' } & LinkedCustomizationDTOCustomizationValueExteriorColor | { type: 'Interior' } & LinkedCustomizationDTOCustomizationValueInterior | { type: 'PaintToSampleExteriorColor' } & LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColor | { type: 'RoofColor' } & LinkedCustomizationDTOCustomizationValueRoofColor;

export function LinkedCustomizationDTOCustomizationValueFromJSON(json: any): LinkedCustomizationDTOCustomizationValue {
    return LinkedCustomizationDTOCustomizationValueFromJSONTyped(json, false);
}

export function LinkedCustomizationDTOCustomizationValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedCustomizationDTOCustomizationValue {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return Object.assign({}, LinkedCustomizationDTOCustomizationValueEquipmentFromJSONTyped(json, true), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, LinkedCustomizationDTOCustomizationValueExteriorColorFromJSONTyped(json, true), { type: 'ExteriorColor' } as const);
        case 'Interior':
            return Object.assign({}, LinkedCustomizationDTOCustomizationValueInteriorFromJSONTyped(json, true), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorFromJSONTyped(json, true), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, LinkedCustomizationDTOCustomizationValueRoofColorFromJSONTyped(json, true), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of LinkedCustomizationDTOCustomizationValue exists with 'type=${json['type']}'`);
    }
}

export function LinkedCustomizationDTOCustomizationValueToJSON(json: any): any {
    return LinkedCustomizationDTOCustomizationValueToJSONTyped(json, false);
}

export function LinkedCustomizationDTOCustomizationValueToJSONTyped(value?: LinkedCustomizationDTOCustomizationValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'Equipment':
            return Object.assign({}, LinkedCustomizationDTOCustomizationValueEquipmentToJSON(value), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, LinkedCustomizationDTOCustomizationValueExteriorColorToJSON(value), { type: 'ExteriorColor' } as const);
        case 'Interior':
            return Object.assign({}, LinkedCustomizationDTOCustomizationValueInteriorToJSON(value), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, LinkedCustomizationDTOCustomizationValuePaintToSampleExteriorColorToJSON(value), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, LinkedCustomizationDTOCustomizationValueRoofColorToJSON(value), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of LinkedCustomizationDTOCustomizationValue exists with 'type=${value['type']}'`);
    }

}


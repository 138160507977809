/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GlobalOrderTypeTechnicalDataAttributeDTO,
  MarketplaceSpecificOrderTypeTechnicalDataAttributeDTO,
  OrderTypeTechnicalDataAttributeUpdateDTO,
  OrderTypeTechnicalDataRelatedDataDTO,
  PdsMarketplace,
  SimpleApiError,
  SimpleOrValidationApiError,
  TechnicalDataAttribute,
} from '../models/index';
import {
    GlobalOrderTypeTechnicalDataAttributeDTOFromJSON,
    GlobalOrderTypeTechnicalDataAttributeDTOToJSON,
    MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOFromJSON,
    MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOToJSON,
    OrderTypeTechnicalDataAttributeUpdateDTOFromJSON,
    OrderTypeTechnicalDataAttributeUpdateDTOToJSON,
    OrderTypeTechnicalDataRelatedDataDTOFromJSON,
    OrderTypeTechnicalDataRelatedDataDTOToJSON,
    PdsMarketplaceFromJSON,
    PdsMarketplaceToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    TechnicalDataAttributeFromJSON,
    TechnicalDataAttributeToJSON,
} from '../models/index';

export interface GetGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest {
    key: string;
    technicalDataAttribute: TechnicalDataAttribute;
}

export interface GetRelatedDataOfOrderTypeRequest {
    key: string;
    languageTag: string;
}

export interface GetmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest {
    key: string;
    marketplace: PdsMarketplace;
    technicalDataAttribute: TechnicalDataAttribute;
}

export interface UpdateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest {
    key: string;
    technicalDataAttribute: TechnicalDataAttribute;
    orderTypeTechnicalDataAttributeUpdateDTO: OrderTypeTechnicalDataAttributeUpdateDTO;
}

export interface UpdatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest {
    key: string;
    marketplace: PdsMarketplace;
    technicalDataAttribute: TechnicalDataAttribute;
    orderTypeTechnicalDataAttributeUpdateDTO: OrderTypeTechnicalDataAttributeUpdateDTO;
}

/**
 * 
 */
export class OrderTypeTechnicalDataDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get globalOfTechnicalDataAttributesByTechnicalDataAttribute of OrderType with given key
     */
    async getGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters: GetGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GlobalOrderTypeTechnicalDataAttributeDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType().'
            );
        }

        if (requestParameters['technicalDataAttribute'] == null) {
            throw new runtime.RequiredError(
                'technicalDataAttribute',
                'Required parameter "technicalDataAttribute" was null or undefined when calling getGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/technical-data/technical-data-attributes/{technicalDataAttribute}/global`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))).replace(`{${"technicalDataAttribute"}}`, encodeURIComponent(String(requestParameters['technicalDataAttribute']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GlobalOrderTypeTechnicalDataAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get globalOfTechnicalDataAttributesByTechnicalDataAttribute of OrderType with given key
     */
    async getGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType(requestParameters: GetGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GlobalOrderTypeTechnicalDataAttributeDTO> {
        const response = await this.getGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get relatedData of OrderType with given key
     */
    async getRelatedDataOfOrderTypeRaw(requestParameters: GetRelatedDataOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderTypeTechnicalDataRelatedDataDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getRelatedDataOfOrderType().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getRelatedDataOfOrderType().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/technical-data/related-data`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeTechnicalDataRelatedDataDTOFromJSON(jsonValue));
    }

    /**
     * Get relatedData of OrderType with given key
     */
    async getRelatedDataOfOrderType(requestParameters: GetRelatedDataOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderTypeTechnicalDataRelatedDataDTO> {
        const response = await this.getRelatedDataOfOrderTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get {marketplace}OfSpecificOfTechnicalDataAttributesByTechnicalDataAttribute of OrderType with given key
     */
    async getmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters: GetmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MarketplaceSpecificOrderTypeTechnicalDataAttributeDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType().'
            );
        }

        if (requestParameters['marketplace'] == null) {
            throw new runtime.RequiredError(
                'marketplace',
                'Required parameter "marketplace" was null or undefined when calling getmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType().'
            );
        }

        if (requestParameters['technicalDataAttribute'] == null) {
            throw new runtime.RequiredError(
                'technicalDataAttribute',
                'Required parameter "technicalDataAttribute" was null or undefined when calling getmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/technical-data/technical-data-attributes/{technicalDataAttribute}/specific/{marketplace}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))).replace(`{${"marketplace"}}`, encodeURIComponent(String(requestParameters['marketplace']))).replace(`{${"technicalDataAttribute"}}`, encodeURIComponent(String(requestParameters['technicalDataAttribute']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get {marketplace}OfSpecificOfTechnicalDataAttributesByTechnicalDataAttribute of OrderType with given key
     */
    async getmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType(requestParameters: GetmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MarketplaceSpecificOrderTypeTechnicalDataAttributeDTO> {
        const response = await this.getmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates globalOfTechnicalDataAttributesByTechnicalDataAttribute of an existing OrderType
     */
    async updateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters: UpdateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType().'
            );
        }

        if (requestParameters['technicalDataAttribute'] == null) {
            throw new runtime.RequiredError(
                'technicalDataAttribute',
                'Required parameter "technicalDataAttribute" was null or undefined when calling updateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType().'
            );
        }

        if (requestParameters['orderTypeTechnicalDataAttributeUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'orderTypeTechnicalDataAttributeUpdateDTO',
                'Required parameter "orderTypeTechnicalDataAttributeUpdateDTO" was null or undefined when calling updateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/details/{key}/technical-data/technical-data-attributes/{technicalDataAttribute}/global`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))).replace(`{${"technicalDataAttribute"}}`, encodeURIComponent(String(requestParameters['technicalDataAttribute']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderTypeTechnicalDataAttributeUpdateDTOToJSON(requestParameters['orderTypeTechnicalDataAttributeUpdateDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates globalOfTechnicalDataAttributesByTechnicalDataAttribute of an existing OrderType
     */
    async updateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType(requestParameters: UpdateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters, initOverrides);
    }

    /**
     * Updates {marketplace}OfSpecificOfTechnicalDataAttributesByTechnicalDataAttribute of an existing OrderType
     */
    async updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters: UpdatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType().'
            );
        }

        if (requestParameters['marketplace'] == null) {
            throw new runtime.RequiredError(
                'marketplace',
                'Required parameter "marketplace" was null or undefined when calling updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType().'
            );
        }

        if (requestParameters['technicalDataAttribute'] == null) {
            throw new runtime.RequiredError(
                'technicalDataAttribute',
                'Required parameter "technicalDataAttribute" was null or undefined when calling updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType().'
            );
        }

        if (requestParameters['orderTypeTechnicalDataAttributeUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'orderTypeTechnicalDataAttributeUpdateDTO',
                'Required parameter "orderTypeTechnicalDataAttributeUpdateDTO" was null or undefined when calling updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/details/{key}/technical-data/technical-data-attributes/{technicalDataAttribute}/specific/{marketplace}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))).replace(`{${"marketplace"}}`, encodeURIComponent(String(requestParameters['marketplace']))).replace(`{${"technicalDataAttribute"}}`, encodeURIComponent(String(requestParameters['technicalDataAttribute']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderTypeTechnicalDataAttributeUpdateDTOToJSON(requestParameters['orderTypeTechnicalDataAttributeUpdateDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates {marketplace}OfSpecificOfTechnicalDataAttributesByTechnicalDataAttribute of an existing OrderType
     */
    async updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType(requestParameters: UpdatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderTypeRaw(requestParameters, initOverrides);
    }

}

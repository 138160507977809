/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderTypeNameItemDTO } from './OrderTypeNameItemDTO';
import {
    OrderTypeNameItemDTOFromJSON,
    OrderTypeNameItemDTOFromJSONTyped,
    OrderTypeNameItemDTOToJSON,
    OrderTypeNameItemDTOToJSONTyped,
} from './OrderTypeNameItemDTO';

/**
 * 
 * @export
 * @interface OrderTypeAttributesDTOBaseName
 */
export interface OrderTypeAttributesDTOBaseName {
    /**
     * 
     * @type {OrderTypeNameItemDTO}
     * @memberof OrderTypeAttributesDTOBaseName
     */
    en: OrderTypeNameItemDTO;
    /**
     * 
     * @type {OrderTypeNameItemDTO}
     * @memberof OrderTypeAttributesDTOBaseName
     */
    de: OrderTypeNameItemDTO;
}

/**
 * Check if a given object implements the OrderTypeAttributesDTOBaseName interface.
 */
export function instanceOfOrderTypeAttributesDTOBaseName(value: object): value is OrderTypeAttributesDTOBaseName {
    if (!('en' in value) || value['en'] === undefined) return false;
    if (!('de' in value) || value['de'] === undefined) return false;
    return true;
}

export function OrderTypeAttributesDTOBaseNameFromJSON(json: any): OrderTypeAttributesDTOBaseName {
    return OrderTypeAttributesDTOBaseNameFromJSONTyped(json, false);
}

export function OrderTypeAttributesDTOBaseNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeAttributesDTOBaseName {
    if (json == null) {
        return json;
    }
    return {
        
        'en': OrderTypeNameItemDTOFromJSON(json['en']),
        'de': OrderTypeNameItemDTOFromJSON(json['de']),
    };
}

export function OrderTypeAttributesDTOBaseNameToJSON(json: any): OrderTypeAttributesDTOBaseName {
    return OrderTypeAttributesDTOBaseNameToJSONTyped(json, false);
}

export function OrderTypeAttributesDTOBaseNameToJSONTyped(value?: OrderTypeAttributesDTOBaseName | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'en': OrderTypeNameItemDTOToJSON(value['en']),
        'de': OrderTypeNameItemDTOToJSON(value['de']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation } from './OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation';
import {
    OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelationFromJSON,
    OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelationFromJSONTyped,
    OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelationToJSON,
    OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelationToJSONTyped,
} from './OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation';

/**
 * 
 * @export
 * @interface OrderTypeTechnicalDataAttributeUpdateDTO
 */
export interface OrderTypeTechnicalDataAttributeUpdateDTO {
    /**
     * 
     * @type {Array<OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation>}
     * @memberof OrderTypeTechnicalDataAttributeUpdateDTO
     */
    equipmentRelations: Array<OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelation>;
}

/**
 * Check if a given object implements the OrderTypeTechnicalDataAttributeUpdateDTO interface.
 */
export function instanceOfOrderTypeTechnicalDataAttributeUpdateDTO(value: object): value is OrderTypeTechnicalDataAttributeUpdateDTO {
    if (!('equipmentRelations' in value) || value['equipmentRelations'] === undefined) return false;
    return true;
}

export function OrderTypeTechnicalDataAttributeUpdateDTOFromJSON(json: any): OrderTypeTechnicalDataAttributeUpdateDTO {
    return OrderTypeTechnicalDataAttributeUpdateDTOFromJSONTyped(json, false);
}

export function OrderTypeTechnicalDataAttributeUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeTechnicalDataAttributeUpdateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'equipmentRelations': ((json['equipmentRelations'] as Array<any>).map(OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelationFromJSON)),
    };
}

export function OrderTypeTechnicalDataAttributeUpdateDTOToJSON(json: any): OrderTypeTechnicalDataAttributeUpdateDTO {
    return OrderTypeTechnicalDataAttributeUpdateDTOToJSONTyped(json, false);
}

export function OrderTypeTechnicalDataAttributeUpdateDTOToJSONTyped(value?: OrderTypeTechnicalDataAttributeUpdateDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'equipmentRelations': ((value['equipmentRelations'] as Array<any>).map(OrderTypeTechnicalDataAttributeUpdateDTOEquipmentRelationToJSON)),
    };
}


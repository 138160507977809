/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UnmaintainedCustomColorDTO } from './UnmaintainedCustomColorDTO';
import {
    UnmaintainedCustomColorDTOFromJSON,
    UnmaintainedCustomColorDTOFromJSONTyped,
    UnmaintainedCustomColorDTOToJSON,
    UnmaintainedCustomColorDTOToJSONTyped,
} from './UnmaintainedCustomColorDTO';

/**
 * 
 * @export
 * @interface PageDTOUnmaintainedCustomColorDTO
 */
export interface PageDTOUnmaintainedCustomColorDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTOUnmaintainedCustomColorDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<UnmaintainedCustomColorDTO>}
     * @memberof PageDTOUnmaintainedCustomColorDTO
     */
    content: Array<UnmaintainedCustomColorDTO>;
}

/**
 * Check if a given object implements the PageDTOUnmaintainedCustomColorDTO interface.
 */
export function instanceOfPageDTOUnmaintainedCustomColorDTO(value: object): value is PageDTOUnmaintainedCustomColorDTO {
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function PageDTOUnmaintainedCustomColorDTOFromJSON(json: any): PageDTOUnmaintainedCustomColorDTO {
    return PageDTOUnmaintainedCustomColorDTOFromJSONTyped(json, false);
}

export function PageDTOUnmaintainedCustomColorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOUnmaintainedCustomColorDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(UnmaintainedCustomColorDTOFromJSON)),
    };
}

export function PageDTOUnmaintainedCustomColorDTOToJSON(json: any): PageDTOUnmaintainedCustomColorDTO {
    return PageDTOUnmaintainedCustomColorDTOToJSONTyped(json, false);
}

export function PageDTOUnmaintainedCustomColorDTOToJSONTyped(value?: PageDTOUnmaintainedCustomColorDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'totalItemCount': value['totalItemCount'],
        'content': ((value['content'] as Array<any>).map(UnmaintainedCustomColorDTOToJSON)),
    };
}


import { IncompleteCustomizationDTO, OptionCustomizationTypeDTO } from "../../../generated/pdsapi"
import { dashboardApi } from "../../../pdsapi"
import { DashboardPagedTableProps } from "../dashboardpagedtable/DashboardPagedTable"
import { incompleteCustomizationTableColumns } from "./parts/IncompleteCustomizationTableColumns"
import { routes } from "../../../common/routes"

export const IncompleteExteriorColorCustomizationsTableConfig: DashboardPagedTableProps<IncompleteCustomizationDTO> = {
    titlePrefix: "Incomplete",
    itemsName: "Exterior Color Customizations",
    getRoute: (incompleteCustomization) =>
        routes.options.customizations.details({
            type: OptionCustomizationTypeDTO.EXTERIOR_COLOR,
            key: incompleteCustomization.key,
        }),
    urlParamsPrefix: "exterior-color-customizations.incomplete",
    columns: incompleteCustomizationTableColumns,
    fetchPage: dashboardApi.getIncompleteExteriorCustomizations,
}

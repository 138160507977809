/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const HttpStatus = {
    CONTINUE: 'CONTINUE',
    SWITCHING_PROTOCOLS: 'SWITCHING_PROTOCOLS',
    PROCESSING: 'PROCESSING',
    EARLY_HINTS: 'EARLY_HINTS',
    CHECKPOINT: 'CHECKPOINT',
    OK: 'OK',
    CREATED: 'CREATED',
    ACCEPTED: 'ACCEPTED',
    NON_AUTHORITATIVE_INFORMATION: 'NON_AUTHORITATIVE_INFORMATION',
    NO_CONTENT: 'NO_CONTENT',
    RESET_CONTENT: 'RESET_CONTENT',
    PARTIAL_CONTENT: 'PARTIAL_CONTENT',
    MULTI_STATUS: 'MULTI_STATUS',
    ALREADY_REPORTED: 'ALREADY_REPORTED',
    IM_USED: 'IM_USED',
    MULTIPLE_CHOICES: 'MULTIPLE_CHOICES',
    MOVED_PERMANENTLY: 'MOVED_PERMANENTLY',
    FOUND: 'FOUND',
    MOVED_TEMPORARILY: 'MOVED_TEMPORARILY',
    SEE_OTHER: 'SEE_OTHER',
    NOT_MODIFIED: 'NOT_MODIFIED',
    USE_PROXY: 'USE_PROXY',
    TEMPORARY_REDIRECT: 'TEMPORARY_REDIRECT',
    PERMANENT_REDIRECT: 'PERMANENT_REDIRECT',
    BAD_REQUEST: 'BAD_REQUEST',
    UNAUTHORIZED: 'UNAUTHORIZED',
    PAYMENT_REQUIRED: 'PAYMENT_REQUIRED',
    FORBIDDEN: 'FORBIDDEN',
    NOT_FOUND: 'NOT_FOUND',
    METHOD_NOT_ALLOWED: 'METHOD_NOT_ALLOWED',
    NOT_ACCEPTABLE: 'NOT_ACCEPTABLE',
    PROXY_AUTHENTICATION_REQUIRED: 'PROXY_AUTHENTICATION_REQUIRED',
    REQUEST_TIMEOUT: 'REQUEST_TIMEOUT',
    CONFLICT: 'CONFLICT',
    GONE: 'GONE',
    LENGTH_REQUIRED: 'LENGTH_REQUIRED',
    PRECONDITION_FAILED: 'PRECONDITION_FAILED',
    PAYLOAD_TOO_LARGE: 'PAYLOAD_TOO_LARGE',
    REQUEST_ENTITY_TOO_LARGE: 'REQUEST_ENTITY_TOO_LARGE',
    URI_TOO_LONG: 'URI_TOO_LONG',
    REQUEST_URI_TOO_LONG: 'REQUEST_URI_TOO_LONG',
    UNSUPPORTED_MEDIA_TYPE: 'UNSUPPORTED_MEDIA_TYPE',
    REQUESTED_RANGE_NOT_SATISFIABLE: 'REQUESTED_RANGE_NOT_SATISFIABLE',
    EXPECTATION_FAILED: 'EXPECTATION_FAILED',
    I_AM_A_TEAPOT: 'I_AM_A_TEAPOT',
    INSUFFICIENT_SPACE_ON_RESOURCE: 'INSUFFICIENT_SPACE_ON_RESOURCE',
    METHOD_FAILURE: 'METHOD_FAILURE',
    DESTINATION_LOCKED: 'DESTINATION_LOCKED',
    UNPROCESSABLE_ENTITY: 'UNPROCESSABLE_ENTITY',
    LOCKED: 'LOCKED',
    FAILED_DEPENDENCY: 'FAILED_DEPENDENCY',
    TOO_EARLY: 'TOO_EARLY',
    UPGRADE_REQUIRED: 'UPGRADE_REQUIRED',
    PRECONDITION_REQUIRED: 'PRECONDITION_REQUIRED',
    TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS',
    REQUEST_HEADER_FIELDS_TOO_LARGE: 'REQUEST_HEADER_FIELDS_TOO_LARGE',
    UNAVAILABLE_FOR_LEGAL_REASONS: 'UNAVAILABLE_FOR_LEGAL_REASONS',
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    NOT_IMPLEMENTED: 'NOT_IMPLEMENTED',
    BAD_GATEWAY: 'BAD_GATEWAY',
    SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE',
    GATEWAY_TIMEOUT: 'GATEWAY_TIMEOUT',
    HTTP_VERSION_NOT_SUPPORTED: 'HTTP_VERSION_NOT_SUPPORTED',
    VARIANT_ALSO_NEGOTIATES: 'VARIANT_ALSO_NEGOTIATES',
    INSUFFICIENT_STORAGE: 'INSUFFICIENT_STORAGE',
    LOOP_DETECTED: 'LOOP_DETECTED',
    BANDWIDTH_LIMIT_EXCEEDED: 'BANDWIDTH_LIMIT_EXCEEDED',
    NOT_EXTENDED: 'NOT_EXTENDED',
    NETWORK_AUTHENTICATION_REQUIRED: 'NETWORK_AUTHENTICATION_REQUIRED'
} as const;
export type HttpStatus = typeof HttpStatus[keyof typeof HttpStatus];


export function instanceOfHttpStatus(value: any): boolean {
    for (const key in HttpStatus) {
        if (Object.prototype.hasOwnProperty.call(HttpStatus, key)) {
            if (HttpStatus[key as keyof typeof HttpStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function HttpStatusFromJSON(json: any): HttpStatus {
    return HttpStatusFromJSONTyped(json, false);
}

export function HttpStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): HttpStatus {
    return json as HttpStatus;
}

export function HttpStatusToJSON(value?: HttpStatus | null): any {
    return value as any;
}

export function HttpStatusToJSONTyped(value: any, ignoreDiscriminator: boolean): HttpStatus {
    return value as HttpStatus;
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LinkedCustomizationDTO,
  OptionDTO,
  OptionTypeDTO,
  OptionUpdateDTOOption,
  OptionsInFamilyDTO,
  SimilarCustomizationsDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    LinkedCustomizationDTOFromJSON,
    LinkedCustomizationDTOToJSON,
    OptionDTOFromJSON,
    OptionDTOToJSON,
    OptionTypeDTOFromJSON,
    OptionTypeDTOToJSON,
    OptionUpdateDTOOptionFromJSON,
    OptionUpdateDTOOptionToJSON,
    OptionsInFamilyDTOFromJSON,
    OptionsInFamilyDTOToJSON,
    SimilarCustomizationsDTOFromJSON,
    SimilarCustomizationsDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface GetLinkedCustomizationOfOptionRequest {
    orderTypeKey: string;
    optionCode: string;
    type: OptionTypeDTO;
    languageTag: string;
}

export interface GetOptionOfOptionRequest {
    orderTypeKey: string;
    optionCode: string;
    type: OptionTypeDTO;
    languageTag: string;
}

export interface GetOptionsInFamilyOfOptionRequest {
    orderTypeKey: string;
    optionCode: string;
    type: OptionTypeDTO;
    languageTag: string;
}

export interface GetSimilarCustomizationsOfOptionRequest {
    orderTypeKey: string;
    optionCode: string;
    type: OptionTypeDTO;
    languageTag: string;
}

export interface UpdateOptionOfOptionRequest {
    orderTypeKey: string;
    optionCode: string;
    optionUpdateDTOOption: OptionUpdateDTOOption;
}

/**
 * 
 */
export class OrderTypeOptionDataDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get linkedCustomization of Option with given key
     */
    async getLinkedCustomizationOfOptionRaw(requestParameters: GetLinkedCustomizationOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LinkedCustomizationDTO>> {
        if (requestParameters['orderTypeKey'] == null) {
            throw new runtime.RequiredError(
                'orderTypeKey',
                'Required parameter "orderTypeKey" was null or undefined when calling getLinkedCustomizationOfOption().'
            );
        }

        if (requestParameters['optionCode'] == null) {
            throw new runtime.RequiredError(
                'optionCode',
                'Required parameter "optionCode" was null or undefined when calling getLinkedCustomizationOfOption().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getLinkedCustomizationOfOption().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getLinkedCustomizationOfOption().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['optionCode'] != null) {
            queryParameters['optionCode'] = requestParameters['optionCode'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/option/data/linked-customization`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters['orderTypeKey']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LinkedCustomizationDTOFromJSON(jsonValue));
    }

    /**
     * Get linkedCustomization of Option with given key
     */
    async getLinkedCustomizationOfOption(requestParameters: GetLinkedCustomizationOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LinkedCustomizationDTO> {
        const response = await this.getLinkedCustomizationOfOptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get option of Option with given key
     */
    async getOptionOfOptionRaw(requestParameters: GetOptionOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionDTO>> {
        if (requestParameters['orderTypeKey'] == null) {
            throw new runtime.RequiredError(
                'orderTypeKey',
                'Required parameter "orderTypeKey" was null or undefined when calling getOptionOfOption().'
            );
        }

        if (requestParameters['optionCode'] == null) {
            throw new runtime.RequiredError(
                'optionCode',
                'Required parameter "optionCode" was null or undefined when calling getOptionOfOption().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getOptionOfOption().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getOptionOfOption().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['optionCode'] != null) {
            queryParameters['optionCode'] = requestParameters['optionCode'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/option/data/option`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters['orderTypeKey']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionDTOFromJSON(jsonValue));
    }

    /**
     * Get option of Option with given key
     */
    async getOptionOfOption(requestParameters: GetOptionOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionDTO> {
        const response = await this.getOptionOfOptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get optionsInFamily of Option with given key
     */
    async getOptionsInFamilyOfOptionRaw(requestParameters: GetOptionsInFamilyOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionsInFamilyDTO>> {
        if (requestParameters['orderTypeKey'] == null) {
            throw new runtime.RequiredError(
                'orderTypeKey',
                'Required parameter "orderTypeKey" was null or undefined when calling getOptionsInFamilyOfOption().'
            );
        }

        if (requestParameters['optionCode'] == null) {
            throw new runtime.RequiredError(
                'optionCode',
                'Required parameter "optionCode" was null or undefined when calling getOptionsInFamilyOfOption().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getOptionsInFamilyOfOption().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getOptionsInFamilyOfOption().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['optionCode'] != null) {
            queryParameters['optionCode'] = requestParameters['optionCode'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/option/data/options-in-family`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters['orderTypeKey']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionsInFamilyDTOFromJSON(jsonValue));
    }

    /**
     * Get optionsInFamily of Option with given key
     */
    async getOptionsInFamilyOfOption(requestParameters: GetOptionsInFamilyOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionsInFamilyDTO> {
        const response = await this.getOptionsInFamilyOfOptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get similarCustomizations of Option with given key
     */
    async getSimilarCustomizationsOfOptionRaw(requestParameters: GetSimilarCustomizationsOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimilarCustomizationsDTO>> {
        if (requestParameters['orderTypeKey'] == null) {
            throw new runtime.RequiredError(
                'orderTypeKey',
                'Required parameter "orderTypeKey" was null or undefined when calling getSimilarCustomizationsOfOption().'
            );
        }

        if (requestParameters['optionCode'] == null) {
            throw new runtime.RequiredError(
                'optionCode',
                'Required parameter "optionCode" was null or undefined when calling getSimilarCustomizationsOfOption().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getSimilarCustomizationsOfOption().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getSimilarCustomizationsOfOption().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['optionCode'] != null) {
            queryParameters['optionCode'] = requestParameters['optionCode'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/option/data/similar-customizations`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters['orderTypeKey']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimilarCustomizationsDTOFromJSON(jsonValue));
    }

    /**
     * Get similarCustomizations of Option with given key
     */
    async getSimilarCustomizationsOfOption(requestParameters: GetSimilarCustomizationsOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimilarCustomizationsDTO> {
        const response = await this.getSimilarCustomizationsOfOptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates option of an existing Option
     */
    async updateOptionOfOptionRaw(requestParameters: UpdateOptionOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['orderTypeKey'] == null) {
            throw new runtime.RequiredError(
                'orderTypeKey',
                'Required parameter "orderTypeKey" was null or undefined when calling updateOptionOfOption().'
            );
        }

        if (requestParameters['optionCode'] == null) {
            throw new runtime.RequiredError(
                'optionCode',
                'Required parameter "optionCode" was null or undefined when calling updateOptionOfOption().'
            );
        }

        if (requestParameters['optionUpdateDTOOption'] == null) {
            throw new runtime.RequiredError(
                'optionUpdateDTOOption',
                'Required parameter "optionUpdateDTOOption" was null or undefined when calling updateOptionOfOption().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['optionCode'] != null) {
            queryParameters['optionCode'] = requestParameters['optionCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/option/data/option`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters['orderTypeKey']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OptionUpdateDTOOptionToJSON(requestParameters['optionUpdateDTOOption']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates option of an existing Option
     */
    async updateOptionOfOption(requestParameters: UpdateOptionOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOptionOfOptionRaw(requestParameters, initOverrides);
    }

}

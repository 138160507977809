/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTONumberOfSeats } from './SelectOptionDTONumberOfSeats';
import {
    SelectOptionDTONumberOfSeatsFromJSON,
    SelectOptionDTONumberOfSeatsFromJSONTyped,
    SelectOptionDTONumberOfSeatsToJSON,
    SelectOptionDTONumberOfSeatsToJSONTyped,
} from './SelectOptionDTONumberOfSeats';
import type { RelatedOptionDTOStringString } from './RelatedOptionDTOStringString';
import {
    RelatedOptionDTOStringStringFromJSON,
    RelatedOptionDTOStringStringFromJSONTyped,
    RelatedOptionDTOStringStringToJSON,
    RelatedOptionDTOStringStringToJSONTyped,
} from './RelatedOptionDTOStringString';
import type { SelectOptionDTODriveTrain } from './SelectOptionDTODriveTrain';
import {
    SelectOptionDTODriveTrainFromJSON,
    SelectOptionDTODriveTrainFromJSONTyped,
    SelectOptionDTODriveTrainToJSON,
    SelectOptionDTODriveTrainToJSONTyped,
} from './SelectOptionDTODriveTrain';
import type { SelectOptionDTOEngineType } from './SelectOptionDTOEngineType';
import {
    SelectOptionDTOEngineTypeFromJSON,
    SelectOptionDTOEngineTypeFromJSONTyped,
    SelectOptionDTOEngineTypeToJSON,
    SelectOptionDTOEngineTypeToJSONTyped,
} from './SelectOptionDTOEngineType';

/**
 * 
 * @export
 * @interface OrderTypeCreateOptionsDTO
 */
export interface OrderTypeCreateOptionsDTO {
    /**
     * 
     * @type {Array<RelatedOptionDTOStringString>}
     * @memberof OrderTypeCreateOptionsDTO
     */
    modelSeriesRelatedModelGenerations: Array<RelatedOptionDTOStringString>;
    /**
     * 
     * @type {Array<RelatedOptionDTOStringString>}
     * @memberof OrderTypeCreateOptionsDTO
     */
    modelSeriesRelatedDerivatives: Array<RelatedOptionDTOStringString>;
    /**
     * 
     * @type {Array<SelectOptionDTODriveTrain>}
     * @memberof OrderTypeCreateOptionsDTO
     */
    driveTrains: Array<SelectOptionDTODriveTrain>;
    /**
     * 
     * @type {Array<SelectOptionDTOEngineType>}
     * @memberof OrderTypeCreateOptionsDTO
     */
    engineTypes: Array<SelectOptionDTOEngineType>;
    /**
     * 
     * @type {Array<SelectOptionDTONumberOfSeats>}
     * @memberof OrderTypeCreateOptionsDTO
     */
    numberOfSeats: Array<SelectOptionDTONumberOfSeats>;
}

/**
 * Check if a given object implements the OrderTypeCreateOptionsDTO interface.
 */
export function instanceOfOrderTypeCreateOptionsDTO(value: object): value is OrderTypeCreateOptionsDTO {
    if (!('modelSeriesRelatedModelGenerations' in value) || value['modelSeriesRelatedModelGenerations'] === undefined) return false;
    if (!('modelSeriesRelatedDerivatives' in value) || value['modelSeriesRelatedDerivatives'] === undefined) return false;
    if (!('driveTrains' in value) || value['driveTrains'] === undefined) return false;
    if (!('engineTypes' in value) || value['engineTypes'] === undefined) return false;
    if (!('numberOfSeats' in value) || value['numberOfSeats'] === undefined) return false;
    return true;
}

export function OrderTypeCreateOptionsDTOFromJSON(json: any): OrderTypeCreateOptionsDTO {
    return OrderTypeCreateOptionsDTOFromJSONTyped(json, false);
}

export function OrderTypeCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeCreateOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'modelSeriesRelatedModelGenerations': ((json['modelSeriesRelatedModelGenerations'] as Array<any>).map(RelatedOptionDTOStringStringFromJSON)),
        'modelSeriesRelatedDerivatives': ((json['modelSeriesRelatedDerivatives'] as Array<any>).map(RelatedOptionDTOStringStringFromJSON)),
        'driveTrains': ((json['driveTrains'] as Array<any>).map(SelectOptionDTODriveTrainFromJSON)),
        'engineTypes': ((json['engineTypes'] as Array<any>).map(SelectOptionDTOEngineTypeFromJSON)),
        'numberOfSeats': ((json['numberOfSeats'] as Array<any>).map(SelectOptionDTONumberOfSeatsFromJSON)),
    };
}

export function OrderTypeCreateOptionsDTOToJSON(json: any): OrderTypeCreateOptionsDTO {
    return OrderTypeCreateOptionsDTOToJSONTyped(json, false);
}

export function OrderTypeCreateOptionsDTOToJSONTyped(value?: OrderTypeCreateOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'modelSeriesRelatedModelGenerations': ((value['modelSeriesRelatedModelGenerations'] as Array<any>).map(RelatedOptionDTOStringStringToJSON)),
        'modelSeriesRelatedDerivatives': ((value['modelSeriesRelatedDerivatives'] as Array<any>).map(RelatedOptionDTOStringStringToJSON)),
        'driveTrains': ((value['driveTrains'] as Array<any>).map(SelectOptionDTODriveTrainToJSON)),
        'engineTypes': ((value['engineTypes'] as Array<any>).map(SelectOptionDTOEngineTypeToJSON)),
        'numberOfSeats': ((value['numberOfSeats'] as Array<any>).map(SelectOptionDTONumberOfSeatsToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueUpdateDTOModelYearRange
 */
export interface OptionCustomizationConditionalValueUpdateDTOModelYearRange {
    /**
     * 
     * @type {number}
     * @memberof OptionCustomizationConditionalValueUpdateDTOModelYearRange
     */
    start?: number;
    /**
     * 
     * @type {number}
     * @memberof OptionCustomizationConditionalValueUpdateDTOModelYearRange
     */
    end?: number;
}

/**
 * Check if a given object implements the OptionCustomizationConditionalValueUpdateDTOModelYearRange interface.
 */
export function instanceOfOptionCustomizationConditionalValueUpdateDTOModelYearRange(value: object): value is OptionCustomizationConditionalValueUpdateDTOModelYearRange {
    return true;
}

export function OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSON(json: any): OptionCustomizationConditionalValueUpdateDTOModelYearRange {
    return OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueUpdateDTOModelYearRange {
    if (json == null) {
        return json;
    }
    return {
        
        'start': json['start'] == null ? undefined : json['start'],
        'end': json['end'] == null ? undefined : json['end'],
    };
}

export function OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSON(json: any): OptionCustomizationConditionalValueUpdateDTOModelYearRange {
    return OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSONTyped(value?: OptionCustomizationConditionalValueUpdateDTOModelYearRange | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'start': value['start'],
        'end': value['end'],
    };
}


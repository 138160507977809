/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IncompleteCustomizationDTO } from './IncompleteCustomizationDTO';
import {
    IncompleteCustomizationDTOFromJSON,
    IncompleteCustomizationDTOFromJSONTyped,
    IncompleteCustomizationDTOToJSON,
    IncompleteCustomizationDTOToJSONTyped,
} from './IncompleteCustomizationDTO';

/**
 * 
 * @export
 * @interface PageDTOIncompleteCustomizationDTO
 */
export interface PageDTOIncompleteCustomizationDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTOIncompleteCustomizationDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<IncompleteCustomizationDTO>}
     * @memberof PageDTOIncompleteCustomizationDTO
     */
    content: Array<IncompleteCustomizationDTO>;
}

/**
 * Check if a given object implements the PageDTOIncompleteCustomizationDTO interface.
 */
export function instanceOfPageDTOIncompleteCustomizationDTO(value: object): value is PageDTOIncompleteCustomizationDTO {
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function PageDTOIncompleteCustomizationDTOFromJSON(json: any): PageDTOIncompleteCustomizationDTO {
    return PageDTOIncompleteCustomizationDTOFromJSONTyped(json, false);
}

export function PageDTOIncompleteCustomizationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOIncompleteCustomizationDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(IncompleteCustomizationDTOFromJSON)),
    };
}

export function PageDTOIncompleteCustomizationDTOToJSON(json: any): PageDTOIncompleteCustomizationDTO {
    return PageDTOIncompleteCustomizationDTOToJSONTyped(json, false);
}

export function PageDTOIncompleteCustomizationDTOToJSONTyped(value?: PageDTOIncompleteCustomizationDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'totalItemCount': value['totalItemCount'],
        'content': ((value['content'] as Array<any>).map(IncompleteCustomizationDTOToJSON)),
    };
}


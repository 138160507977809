import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { OrderTypeFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { OrderTypeFilter } from "../../../../../pdsapi"
import { findGroupedOption, findOption } from "../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { filterTagsFor } from "../../../../../components/filteredpagedtable/filtertags/filterTagsFor"

export const OrderTypeListFilterTagsConfig: TableFilterTagsConfig<OrderTypeFilter, OrderTypeFilterOptionsDTO> = ({ filter, setFilter, filterOptions }) => [
    ...filterTagsFor(
        filter,
        setFilter,
        "derivativeKeys",
        (derivativeKey) => findGroupedOption(filterOptions?.modelSeriesRelatedDerivatives, derivativeKey)?.label ?? derivativeKey
    ),
    ...filterTagsFor(
        filter,
        setFilter,
        "modelGenerationKeys",
        (modelGenerationKey) => findGroupedOption(filterOptions?.modelSeriesRelatedModelGenerations, modelGenerationKey)?.label ?? modelGenerationKey
    ),
    ...filterTagsFor(filter, setFilter, "modelSeriesKeys", (modelSeriesKey) => findOption(filterOptions?.modelSeries, modelSeriesKey)?.label ?? modelSeriesKey),
]

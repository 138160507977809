/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderTypeMediaDTOEngineSoundsDTO } from './OrderTypeMediaDTOEngineSoundsDTO';
import {
    OrderTypeMediaDTOEngineSoundsDTOFromJSON,
    OrderTypeMediaDTOEngineSoundsDTOFromJSONTyped,
    OrderTypeMediaDTOEngineSoundsDTOToJSON,
    OrderTypeMediaDTOEngineSoundsDTOToJSONTyped,
} from './OrderTypeMediaDTOEngineSoundsDTO';

/**
 * 
 * @export
 * @interface OrderTypeMediaDTOEquipmentRelationDTO
 */
export interface OrderTypeMediaDTOEquipmentRelationDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeMediaDTOEquipmentRelationDTO
     */
    optionCodes: Array<string>;
    /**
     * 
     * @type {OrderTypeMediaDTOEngineSoundsDTO}
     * @memberof OrderTypeMediaDTOEquipmentRelationDTO
     */
    values: OrderTypeMediaDTOEngineSoundsDTO;
}

/**
 * Check if a given object implements the OrderTypeMediaDTOEquipmentRelationDTO interface.
 */
export function instanceOfOrderTypeMediaDTOEquipmentRelationDTO(value: object): value is OrderTypeMediaDTOEquipmentRelationDTO {
    if (!('optionCodes' in value) || value['optionCodes'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function OrderTypeMediaDTOEquipmentRelationDTOFromJSON(json: any): OrderTypeMediaDTOEquipmentRelationDTO {
    return OrderTypeMediaDTOEquipmentRelationDTOFromJSONTyped(json, false);
}

export function OrderTypeMediaDTOEquipmentRelationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeMediaDTOEquipmentRelationDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'optionCodes': json['optionCodes'],
        'values': OrderTypeMediaDTOEngineSoundsDTOFromJSON(json['values']),
    };
}

export function OrderTypeMediaDTOEquipmentRelationDTOToJSON(json: any): OrderTypeMediaDTOEquipmentRelationDTO {
    return OrderTypeMediaDTOEquipmentRelationDTOToJSONTyped(json, false);
}

export function OrderTypeMediaDTOEquipmentRelationDTOToJSONTyped(value?: OrderTypeMediaDTOEquipmentRelationDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'optionCodes': value['optionCodes'],
        'values': OrderTypeMediaDTOEngineSoundsDTOToJSON(value['values']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ColorTileDTO } from './ColorTileDTO';
import {
    ColorTileDTOFromJSON,
    ColorTileDTOFromJSONTyped,
    ColorTileDTOToJSON,
    ColorTileDTOToJSONTyped,
} from './ColorTileDTO';

/**
 * 
 * @export
 * @interface CustomExteriorColorItemDTO
 */
export interface CustomExteriorColorItemDTO {
    /**
     * 
     * @type {string}
     * @memberof CustomExteriorColorItemDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof CustomExteriorColorItemDTO
     */
    hexCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomExteriorColorItemDTO
     */
    name: string;
    /**
     * 
     * @type {ColorTileDTO}
     * @memberof CustomExteriorColorItemDTO
     */
    exteriorColorTag?: ColorTileDTO;
    /**
     * 
     * @type {string}
     * @memberof CustomExteriorColorItemDTO
     */
    exteriorColorTypeName?: string;
}

/**
 * Check if a given object implements the CustomExteriorColorItemDTO interface.
 */
export function instanceOfCustomExteriorColorItemDTO(value: object): value is CustomExteriorColorItemDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function CustomExteriorColorItemDTOFromJSON(json: any): CustomExteriorColorItemDTO {
    return CustomExteriorColorItemDTOFromJSONTyped(json, false);
}

export function CustomExteriorColorItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomExteriorColorItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'hexCode': json['hexCode'] == null ? undefined : json['hexCode'],
        'name': json['name'],
        'exteriorColorTag': json['exteriorColorTag'] == null ? undefined : ColorTileDTOFromJSON(json['exteriorColorTag']),
        'exteriorColorTypeName': json['exteriorColorTypeName'] == null ? undefined : json['exteriorColorTypeName'],
    };
}

export function CustomExteriorColorItemDTOToJSON(json: any): CustomExteriorColorItemDTO {
    return CustomExteriorColorItemDTOToJSONTyped(json, false);
}

export function CustomExteriorColorItemDTOToJSONTyped(value?: CustomExteriorColorItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'hexCode': value['hexCode'],
        'name': value['name'],
        'exteriorColorTag': ColorTileDTOToJSON(value['exteriorColorTag']),
        'exteriorColorTypeName': value['exteriorColorTypeName'],
    };
}


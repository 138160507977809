/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface ModelSeriesLinksDTO
 */
export interface ModelSeriesLinksDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof ModelSeriesLinksDTO
     */
    modelGenerations: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof ModelSeriesLinksDTO
     */
    derivativeCategories: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the ModelSeriesLinksDTO interface.
 */
export function instanceOfModelSeriesLinksDTO(value: object): value is ModelSeriesLinksDTO {
    if (!('modelGenerations' in value) || value['modelGenerations'] === undefined) return false;
    if (!('derivativeCategories' in value) || value['derivativeCategories'] === undefined) return false;
    return true;
}

export function ModelSeriesLinksDTOFromJSON(json: any): ModelSeriesLinksDTO {
    return ModelSeriesLinksDTOFromJSONTyped(json, false);
}

export function ModelSeriesLinksDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelSeriesLinksDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'modelGenerations': ((json['modelGenerations'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'derivativeCategories': ((json['derivativeCategories'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function ModelSeriesLinksDTOToJSON(json: any): ModelSeriesLinksDTO {
    return ModelSeriesLinksDTOToJSONTyped(json, false);
}

export function ModelSeriesLinksDTOToJSONTyped(value?: ModelSeriesLinksDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'modelGenerations': ((value['modelGenerations'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'derivativeCategories': ((value['derivativeCategories'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


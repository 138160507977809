import { propertiesOf } from "../../../../../common/property"
import { EditFormElementWrapper } from "../../../../../components/formelements/EditFormElementWrapper"
import { getBoolFormElement } from "../../../../../components/formelements/boolformelement/BoolFormElement"
import { getColorPickerFormElement } from "../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { toOption } from "../../../../../components/formelements/selecttextformelement/Option"
import { getTagFormElement } from "../../../../../components/formelements/tagformelement/TagFormElement"
import { getTagsFormElement } from "../../../../../components/formelements/tagsformelement/TagsFormElement"
import { getTextFormElement } from "../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import {
    OptionCustomizationCreateOptionsDTO,
    OptionCustomizationCreateRequestDTO,
    OptionCustomizationCreateRequestDTOEquipment,
    OptionCustomizationCreateRequestDTOExteriorColor,
    OptionCustomizationCreateRequestDTOInterior,
    OptionCustomizationCreateRequestDTORoofColor,
    OptionCustomizationTypeDTO,
} from "../../../../../generated/pdsapi"
import { ProductCreateContentProps } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { CreateDefaultLocalizationsRow } from "../../../../../viewtemplates/create/components/createdefaultlocalizationsrow/CreateDefaultLocalizationsRow"
import { FC, JSX } from "react"
import { useLocation } from "react-router-dom"
import { EditFormDisplayData, getEditData, LoadingFormDisplayData } from "../../../../../components/formelements/FormDisplayData"
import { getSelectTextFormElement } from "../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { optionCustomizationTypeFromEnum, optionCustomizationTypeOptions } from "../../optionCustomizationTypes"
import { getGroupedTagsFormElement } from "../../../../../components/formelements/groupedtagsformelement/GroupedTagsFormElement"
import { toGroupedOption } from "../../../../../components/formelements/groupedselecttextformelement/GroupedSelectOption"
import { InteriorColorTile } from "@finder/ui-kit"

export const OptionCustomizationCreateAttributesCard: FC<
    ProductCreateContentProps<OptionCustomizationCreateRequestDTO, OptionCustomizationCreateOptionsDTO>
> = ({ formDisplayData }) => {
    const properties = propertiesOf<OptionCustomizationCreateRequestDTO>()
    const searchParams = new URLSearchParams(useLocation().search)
    const customizationType = (searchParams.get("type") ?? OptionCustomizationTypeDTO.EQUIPMENT) as OptionCustomizationTypeDTO

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <EditFormElementWrapper
                    label={"Type"}
                    formDisplayData={formDisplayData}
                    field={properties.type}
                    FormElement={getSelectTextFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                    getEditProps={() => ({
                        options: optionCustomizationTypeOptions,
                        value: optionCustomizationTypeFromEnum(customizationType),
                    })}
                />
                <EditFormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    field={properties.optionCode}
                    FormElement={getTextFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: searchParams.get("optionCode") ?? "",
                    })}
                />
                <EditFormElementWrapper
                    label={"Model Generations"}
                    formDisplayData={formDisplayData}
                    field={properties.modelGenerationKeys}
                    FormElement={getTagsFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                    getEditProps={(createOptions) => ({
                        options: createOptions.modelGenerations.map(toOption),
                        value: searchParams.getAll("modelGenerationKeys"),
                        emptyLabel: "-",
                        clearable: true,
                    })}
                />
                <EditFormElementWrapper
                    label={"Default Value Status"}
                    formDisplayData={formDisplayData}
                    field={properties.isDefaultValueActive}
                    FormElement={getBoolFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                    getEditProps={() => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: true,
                    })}
                />
            </RowGroupWrapper>

            <CreateDefaultLocalizationsRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getEditProps={() => ({
                    value: { de: "", en: "" },
                    optional: true,
                })}
            />

            <CreateDefaultLocalizationsRow
                label={"Description"}
                formDisplayData={formDisplayData}
                field={properties.description}
                getEditProps={() => ({
                    value: { de: "", en: "" },
                    optional: true,
                })}
            />

            <TypeSpecificRowGroup formDisplayData={formDisplayData} />
        </SpacedItems>
    )
}

const TypeSpecificRowGroup = ({
    formDisplayData,
}: {
    formDisplayData: LoadingFormDisplayData | EditFormDisplayData<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>
}): JSX.Element | null => {
    switch (getEditData(formDisplayData)?.watch("type")) {
        case "Equipment": {
            const properties = propertiesOf<OptionCustomizationCreateRequestDTOEquipment>()

            return (
                <RowGroupWrapper label={"Tags"}>
                    <EditFormElementWrapper
                        label={"Equipment Tags"}
                        formDisplayData={formDisplayData}
                        field={properties.equipmentTagKeys}
                        FormElement={getGroupedTagsFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                        getEditProps={(createOptions) => ({
                            options: createOptions.categorizedEquipmentTags.map(toGroupedOption),
                            value: [],
                            emptyLabel: "-",
                            clearable: true,
                        })}
                    />
                </RowGroupWrapper>
            )
        }

        case "Interior": {
            const properties = propertiesOf<OptionCustomizationCreateRequestDTOInterior>()
            const editData = getEditData(formDisplayData)
            const seatColorHexCode = editData?.watch("seatColorHexCode")
            const seatStitchingColorHexCode = editData?.watch("seatStitchingColorHexCode")
            const cockpitColorHexCode = editData?.watch("cockpitColorHexCode")
            const cockpitStitchingColorHexCode = editData?.watch("cockpitStitchingColorHexCode")

            const colorLabel = (
                <>
                    Color
                    <InteriorColorTile
                        seatColor={seatColorHexCode ?? "#000000"}
                        seatStitchingColor={seatStitchingColorHexCode}
                        cockpitColor={cockpitColorHexCode}
                        cockpitStitchingColor={cockpitStitchingColorHexCode}
                    />
                </>
            )
            return (
                <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
                    <RowGroupWrapper label={colorLabel}>
                        <EditFormElementWrapper
                            label={"Seat Color Hex Code"}
                            formDisplayData={formDisplayData}
                            field={properties.seatColorHexCode}
                            FormElement={getColorPickerFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                            getEditProps={() => ({
                                hexValue: "#000000",
                                disableGlossy: true,
                            })}
                        />
                        <EditFormElementWrapper
                            label={"Seat Stitching Color Hex Code"}
                            formDisplayData={formDisplayData}
                            field={properties.seatStitchingColorHexCode}
                            FormElement={getColorPickerFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                            getEditProps={() => ({
                                hexValue: "#000000",
                                disableGlossy: true,
                            })}
                        />
                    </RowGroupWrapper>
                    <RowGroupWrapper>
                        <EditFormElementWrapper
                            label={"Cockpit Color Hex Code"}
                            formDisplayData={formDisplayData}
                            field={properties.cockpitColorHexCode}
                            FormElement={getColorPickerFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                            getEditProps={() => ({
                                hexValue: "#000000",
                                disableGlossy: true,
                            })}
                        />
                        <EditFormElementWrapper
                            label={"Cockpit Stitching Color Hex Code"}
                            formDisplayData={formDisplayData}
                            field={properties.cockpitStitchingColorHexCode}
                            FormElement={getColorPickerFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                            getEditProps={() => ({
                                hexValue: "#000000",
                                disableGlossy: true,
                            })}
                        />
                    </RowGroupWrapper>
                    <RowGroupWrapper>
                        <EditFormElementWrapper
                            label={"Interior Color Tag"}
                            formDisplayData={formDisplayData}
                            field={properties.interiorColorTagKey}
                            FormElement={getTagFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                            getEditProps={(createOptions) => ({
                                options: createOptions.interiorColorTags.map(toOption),
                                value: createOptions.interiorColorTags[0].key,
                            })}
                        />
                        <EditFormElementWrapper
                            label={"Custom Color"}
                            formDisplayData={formDisplayData}
                            field={properties.isCustomColor}
                            FormElement={getBoolFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                            getEditProps={() => ({
                                trueMessage: "Yes",
                                falseMessage: "No",
                                value: false,
                            })}
                        />
                    </RowGroupWrapper>

                    <RowGroupWrapper label={"Material"}>
                        <EditFormElementWrapper
                            label={"Interior Material"}
                            formDisplayData={formDisplayData}
                            field={properties.material}
                            FormElement={getTagFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                            getEditProps={(createOptions) => ({
                                options: createOptions.interiorMaterials.map(toOption),
                                value: createOptions.interiorMaterials[0].key,
                            })}
                        />
                    </RowGroupWrapper>
                </SpacedItems>
            )
        }

        case "ExteriorColor": {
            const properties = propertiesOf<OptionCustomizationCreateRequestDTOExteriorColor>()

            return (
                <RowGroupWrapper label={"Color"}>
                    <EditFormElementWrapper
                        label={"Hex Code"}
                        formDisplayData={formDisplayData}
                        field={properties.hexCode}
                        FormElement={getColorPickerFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                        getEditProps={() => ({
                            hexValue: "#000000",
                            disableGlossy: true,
                        })}
                    />
                    <EditFormElementWrapper
                        label={"Exterior Color Tag"}
                        formDisplayData={formDisplayData}
                        field={properties.exteriorColorTagKey}
                        FormElement={getTagFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                        getEditProps={(createOptions) => ({
                            options: createOptions.exteriorColorTags.map(toOption),
                            value: createOptions.exteriorColorTags[0].key,
                        })}
                    />
                    <EditFormElementWrapper
                        label={"Exterior Color Type"}
                        formDisplayData={formDisplayData}
                        field={properties.exteriorColorType}
                        FormElement={getTagFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                        getEditProps={(createOptions) => ({
                            options: createOptions.exteriorColorTypes.map(toOption),
                            value: createOptions.exteriorColorTypes[0].key,
                        })}
                    />
                </RowGroupWrapper>
            )
        }

        case "PaintToSampleExteriorColor":
            return null

        case "RoofColor": {
            const properties = propertiesOf<OptionCustomizationCreateRequestDTORoofColor>()

            return (
                <RowGroupWrapper label={"Color"}>
                    <EditFormElementWrapper
                        label={"Hex Code"}
                        formDisplayData={formDisplayData}
                        field={properties.hexCode}
                        FormElement={getColorPickerFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                        getEditProps={() => ({
                            hexValue: "#000000",
                            disableGlossy: true,
                        })}
                    />
                    <EditFormElementWrapper
                        label={"Roof Color Tag"}
                        formDisplayData={formDisplayData}
                        field={properties.roofColorTagKey}
                        FormElement={getTagFormElement<OptionCustomizationCreateOptionsDTO, OptionCustomizationCreateRequestDTO>()}
                        getEditProps={(createOptions) => ({
                            options: createOptions.roofColorTags.map(toOption),
                            value: createOptions.roofColorTags[0].key,
                        })}
                    />
                </RowGroupWrapper>
            )
        }

        case undefined:
            return null
    }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntRange
 */
export interface IntRange {
    /**
     * 
     * @type {number}
     * @memberof IntRange
     */
    start: number;
    /**
     * 
     * @type {number}
     * @memberof IntRange
     */
    end: number;
}

/**
 * Check if a given object implements the IntRange interface.
 */
export function instanceOfIntRange(value: object): value is IntRange {
    if (!('start' in value) || value['start'] === undefined) return false;
    if (!('end' in value) || value['end'] === undefined) return false;
    return true;
}

export function IntRangeFromJSON(json: any): IntRange {
    return IntRangeFromJSONTyped(json, false);
}

export function IntRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntRange {
    if (json == null) {
        return json;
    }
    return {
        
        'start': json['start'],
        'end': json['end'],
    };
}

export function IntRangeToJSON(json: any): IntRange {
    return IntRangeToJSONTyped(json, false);
}

export function IntRangeToJSONTyped(value?: IntRange | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'start': value['start'],
        'end': value['end'],
    };
}


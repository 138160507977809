/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { OptionCustomizationAttributesUpdateDTOEquipment } from './OptionCustomizationAttributesUpdateDTOEquipment';
import {
    instanceOfOptionCustomizationAttributesUpdateDTOEquipment,
    OptionCustomizationAttributesUpdateDTOEquipmentFromJSON,
    OptionCustomizationAttributesUpdateDTOEquipmentFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOEquipmentToJSON,
} from './OptionCustomizationAttributesUpdateDTOEquipment';
import type { OptionCustomizationAttributesUpdateDTOExteriorColor } from './OptionCustomizationAttributesUpdateDTOExteriorColor';
import {
    instanceOfOptionCustomizationAttributesUpdateDTOExteriorColor,
    OptionCustomizationAttributesUpdateDTOExteriorColorFromJSON,
    OptionCustomizationAttributesUpdateDTOExteriorColorFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOExteriorColorToJSON,
} from './OptionCustomizationAttributesUpdateDTOExteriorColor';
import type { OptionCustomizationAttributesUpdateDTOInterior } from './OptionCustomizationAttributesUpdateDTOInterior';
import {
    instanceOfOptionCustomizationAttributesUpdateDTOInterior,
    OptionCustomizationAttributesUpdateDTOInteriorFromJSON,
    OptionCustomizationAttributesUpdateDTOInteriorFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOInteriorToJSON,
} from './OptionCustomizationAttributesUpdateDTOInterior';
import type { OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor } from './OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor';
import {
    instanceOfOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorFromJSON,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorToJSON,
} from './OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor';
import type { OptionCustomizationAttributesUpdateDTORoofColor } from './OptionCustomizationAttributesUpdateDTORoofColor';
import {
    instanceOfOptionCustomizationAttributesUpdateDTORoofColor,
    OptionCustomizationAttributesUpdateDTORoofColorFromJSON,
    OptionCustomizationAttributesUpdateDTORoofColorFromJSONTyped,
    OptionCustomizationAttributesUpdateDTORoofColorToJSON,
} from './OptionCustomizationAttributesUpdateDTORoofColor';

/**
 * @type OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject
 * 
 * @export
 */
export type OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject = { type: 'Equipment' } & OptionCustomizationAttributesUpdateDTOEquipment | { type: 'ExteriorColor' } & OptionCustomizationAttributesUpdateDTOExteriorColor | { type: 'Interior' } & OptionCustomizationAttributesUpdateDTOInterior | { type: 'PaintToSampleExteriorColor' } & OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor | { type: 'RoofColor' } & OptionCustomizationAttributesUpdateDTORoofColor;

export function OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObjectFromJSON(json: any): OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject {
    return OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObjectFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return Object.assign({}, OptionCustomizationAttributesUpdateDTOEquipmentFromJSONTyped(json, true), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionCustomizationAttributesUpdateDTOExteriorColorFromJSONTyped(json, true), { type: 'ExteriorColor' } as const);
        case 'Interior':
            return Object.assign({}, OptionCustomizationAttributesUpdateDTOInteriorFromJSONTyped(json, true), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorFromJSONTyped(json, true), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionCustomizationAttributesUpdateDTORoofColorFromJSONTyped(json, true), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObjectToJSON(json: any): any {
    return OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObjectToJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObjectToJSONTyped(value?: OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'Equipment':
            return Object.assign({}, OptionCustomizationAttributesUpdateDTOEquipmentToJSON(value), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionCustomizationAttributesUpdateDTOExteriorColorToJSON(value), { type: 'ExteriorColor' } as const);
        case 'Interior':
            return Object.assign({}, OptionCustomizationAttributesUpdateDTOInteriorToJSON(value), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorToJSON(value), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionCustomizationAttributesUpdateDTORoofColorToJSON(value), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject exists with 'type=${value['type']}'`);
    }

}


import { modelGenerationApi } from "../../../../pdsapi"
import { getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLocalizationCard } from "../../../../viewtemplates/details/cards/localizationcard/DetailsLocalizationCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { ModelGenerationDetailsAttributesCardContent } from "./data/attributes/ModelGenerationDetailsAttributesCardContent"
import { Route, useParams } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { ModelGenerationProductListContainerConfig } from "../list/ModelGenerationListRoute"

export const ModelGenerationProductDetailsContainerConfig: ProductDetailsContainerConfig = {
    getBaseInformation: modelGenerationApi.details.getBaseInformation,
    deleteByKey: modelGenerationApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={modelGenerationApi.details.getAttributes}
                        updateData={({ key, update }) =>
                            modelGenerationApi.details.updateAttributes({
                                key,
                                modelGenerationAttributesUpdateDTO: update,
                            })
                        }
                        Content={ModelGenerationDetailsAttributesCardContent}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={modelGenerationApi.details.getName}
                        updateData={(update) =>
                            modelGenerationApi.details.updateName({
                                key: update.key,
                                requestBody: update.update,
                            })
                        }
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

const ModelGenerationDetailsContainer = getProductDetailsContainer(ModelGenerationProductDetailsContainerConfig)

export const ModelGenerationDetailsRoute = (
    <Route
        path={routes.models.modelGenerations.details(":key")}
        Component={() => {
            const params = useParams<{ key: string }>()

            return (
                <ModelGenerationDetailsContainer
                    itemKey={params}
                    parentCrumbs={[
                        {
                            name: ModelGenerationProductListContainerConfig.listName,
                            path: routes.models.modelGenerations.list(),
                        },
                    ]}
                />
            )
        }}
    />
)

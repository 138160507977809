import { routes } from "../../../common/routes"
import { UnmaintainedCustomColorDTO } from "../../../generated/pdsapi"
import { dashboardApi } from "../../../pdsapi"
import { DashboardPagedTableProps } from "../dashboardpagedtable/DashboardPagedTable"
import { unmaintainedCustomColorColumns } from "./parts/UnmaintainedCustomColorColumns"

export const UnmaintainedCustomExteriorColorsTableConfig: DashboardPagedTableProps<UnmaintainedCustomColorDTO> = {
    titlePrefix: "Unmaintained",
    itemsName: "Custom Exterior Colors",

    getRoute: (unmaintainedCustomColor) => routes.customColors.exterior.details(unmaintainedCustomColor.key),

    urlParamsPrefix: "custom-exterior-colors.unmaintained",
    columns: unmaintainedCustomColorColumns,
    fetchPage: dashboardApi.getUnmaintainedCustomExteriorColors,
}

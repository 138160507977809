/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { OptionUpdateDTOCustomTailoring } from './OptionUpdateDTOCustomTailoring';
import {
    instanceOfOptionUpdateDTOCustomTailoring,
    OptionUpdateDTOCustomTailoringFromJSON,
    OptionUpdateDTOCustomTailoringFromJSONTyped,
    OptionUpdateDTOCustomTailoringToJSON,
} from './OptionUpdateDTOCustomTailoring';
import type { OptionUpdateDTOExteriorColor } from './OptionUpdateDTOExteriorColor';
import {
    instanceOfOptionUpdateDTOExteriorColor,
    OptionUpdateDTOExteriorColorFromJSON,
    OptionUpdateDTOExteriorColorFromJSONTyped,
    OptionUpdateDTOExteriorColorToJSON,
} from './OptionUpdateDTOExteriorColor';
import type { OptionUpdateDTOIndividualEquipment } from './OptionUpdateDTOIndividualEquipment';
import {
    instanceOfOptionUpdateDTOIndividualEquipment,
    OptionUpdateDTOIndividualEquipmentFromJSON,
    OptionUpdateDTOIndividualEquipmentFromJSONTyped,
    OptionUpdateDTOIndividualEquipmentToJSON,
} from './OptionUpdateDTOIndividualEquipment';
import type { OptionUpdateDTOInterior } from './OptionUpdateDTOInterior';
import {
    instanceOfOptionUpdateDTOInterior,
    OptionUpdateDTOInteriorFromJSON,
    OptionUpdateDTOInteriorFromJSONTyped,
    OptionUpdateDTOInteriorToJSON,
} from './OptionUpdateDTOInterior';
import type { OptionUpdateDTOPaintToSampleExteriorColor } from './OptionUpdateDTOPaintToSampleExteriorColor';
import {
    instanceOfOptionUpdateDTOPaintToSampleExteriorColor,
    OptionUpdateDTOPaintToSampleExteriorColorFromJSON,
    OptionUpdateDTOPaintToSampleExteriorColorFromJSONTyped,
    OptionUpdateDTOPaintToSampleExteriorColorToJSON,
} from './OptionUpdateDTOPaintToSampleExteriorColor';
import type { OptionUpdateDTORoofColor } from './OptionUpdateDTORoofColor';
import {
    instanceOfOptionUpdateDTORoofColor,
    OptionUpdateDTORoofColorFromJSON,
    OptionUpdateDTORoofColorFromJSONTyped,
    OptionUpdateDTORoofColorToJSON,
} from './OptionUpdateDTORoofColor';
import type { OptionUpdateDTOSalesPackage } from './OptionUpdateDTOSalesPackage';
import {
    instanceOfOptionUpdateDTOSalesPackage,
    OptionUpdateDTOSalesPackageFromJSON,
    OptionUpdateDTOSalesPackageFromJSONTyped,
    OptionUpdateDTOSalesPackageToJSON,
} from './OptionUpdateDTOSalesPackage';
import type { OptionUpdateDTOTechnicalPackage } from './OptionUpdateDTOTechnicalPackage';
import {
    instanceOfOptionUpdateDTOTechnicalPackage,
    OptionUpdateDTOTechnicalPackageFromJSON,
    OptionUpdateDTOTechnicalPackageFromJSONTyped,
    OptionUpdateDTOTechnicalPackageToJSON,
} from './OptionUpdateDTOTechnicalPackage';
import type { OptionUpdateDTOUnclassifiedEquipment } from './OptionUpdateDTOUnclassifiedEquipment';
import {
    instanceOfOptionUpdateDTOUnclassifiedEquipment,
    OptionUpdateDTOUnclassifiedEquipmentFromJSON,
    OptionUpdateDTOUnclassifiedEquipmentFromJSONTyped,
    OptionUpdateDTOUnclassifiedEquipmentToJSON,
} from './OptionUpdateDTOUnclassifiedEquipment';
import type { OptionUpdateDTOZOrder } from './OptionUpdateDTOZOrder';
import {
    instanceOfOptionUpdateDTOZOrder,
    OptionUpdateDTOZOrderFromJSON,
    OptionUpdateDTOZOrderFromJSONTyped,
    OptionUpdateDTOZOrderToJSON,
} from './OptionUpdateDTOZOrder';

/**
 * @type OptionUpdateDTOOption
 * 
 * @export
 */
export type OptionUpdateDTOOption = { type: 'CustomTailoring' } & OptionUpdateDTOCustomTailoring | { type: 'ExteriorColor' } & OptionUpdateDTOExteriorColor | { type: 'IndividualEquipment' } & OptionUpdateDTOIndividualEquipment | { type: 'Interior' } & OptionUpdateDTOInterior | { type: 'PaintToSampleExteriorColor' } & OptionUpdateDTOPaintToSampleExteriorColor | { type: 'RoofColor' } & OptionUpdateDTORoofColor | { type: 'SalesPackage' } & OptionUpdateDTOSalesPackage | { type: 'TechnicalPackage' } & OptionUpdateDTOTechnicalPackage | { type: 'UnclassifiedEquipment' } & OptionUpdateDTOUnclassifiedEquipment | { type: 'ZOrder' } & OptionUpdateDTOZOrder;

export function OptionUpdateDTOOptionFromJSON(json: any): OptionUpdateDTOOption {
    return OptionUpdateDTOOptionFromJSONTyped(json, false);
}

export function OptionUpdateDTOOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionUpdateDTOOption {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'CustomTailoring':
            return Object.assign({}, OptionUpdateDTOCustomTailoringFromJSONTyped(json, true), { type: 'CustomTailoring' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionUpdateDTOExteriorColorFromJSONTyped(json, true), { type: 'ExteriorColor' } as const);
        case 'IndividualEquipment':
            return Object.assign({}, OptionUpdateDTOIndividualEquipmentFromJSONTyped(json, true), { type: 'IndividualEquipment' } as const);
        case 'Interior':
            return Object.assign({}, OptionUpdateDTOInteriorFromJSONTyped(json, true), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionUpdateDTOPaintToSampleExteriorColorFromJSONTyped(json, true), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionUpdateDTORoofColorFromJSONTyped(json, true), { type: 'RoofColor' } as const);
        case 'SalesPackage':
            return Object.assign({}, OptionUpdateDTOSalesPackageFromJSONTyped(json, true), { type: 'SalesPackage' } as const);
        case 'TechnicalPackage':
            return Object.assign({}, OptionUpdateDTOTechnicalPackageFromJSONTyped(json, true), { type: 'TechnicalPackage' } as const);
        case 'UnclassifiedEquipment':
            return Object.assign({}, OptionUpdateDTOUnclassifiedEquipmentFromJSONTyped(json, true), { type: 'UnclassifiedEquipment' } as const);
        case 'ZOrder':
            return Object.assign({}, OptionUpdateDTOZOrderFromJSONTyped(json, true), { type: 'ZOrder' } as const);
        default:
            throw new Error(`No variant of OptionUpdateDTOOption exists with 'type=${json['type']}'`);
    }
}

export function OptionUpdateDTOOptionToJSON(json: any): any {
    return OptionUpdateDTOOptionToJSONTyped(json, false);
}

export function OptionUpdateDTOOptionToJSONTyped(value?: OptionUpdateDTOOption | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'CustomTailoring':
            return Object.assign({}, OptionUpdateDTOCustomTailoringToJSON(value), { type: 'CustomTailoring' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionUpdateDTOExteriorColorToJSON(value), { type: 'ExteriorColor' } as const);
        case 'IndividualEquipment':
            return Object.assign({}, OptionUpdateDTOIndividualEquipmentToJSON(value), { type: 'IndividualEquipment' } as const);
        case 'Interior':
            return Object.assign({}, OptionUpdateDTOInteriorToJSON(value), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionUpdateDTOPaintToSampleExteriorColorToJSON(value), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionUpdateDTORoofColorToJSON(value), { type: 'RoofColor' } as const);
        case 'SalesPackage':
            return Object.assign({}, OptionUpdateDTOSalesPackageToJSON(value), { type: 'SalesPackage' } as const);
        case 'TechnicalPackage':
            return Object.assign({}, OptionUpdateDTOTechnicalPackageToJSON(value), { type: 'TechnicalPackage' } as const);
        case 'UnclassifiedEquipment':
            return Object.assign({}, OptionUpdateDTOUnclassifiedEquipmentToJSON(value), { type: 'UnclassifiedEquipment' } as const);
        case 'ZOrder':
            return Object.assign({}, OptionUpdateDTOZOrderToJSON(value), { type: 'ZOrder' } as const);
        default:
            throw new Error(`No variant of OptionUpdateDTOOption exists with 'type=${value['type']}'`);
    }

}


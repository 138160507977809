/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleStateSearchResult
 */
export interface VehicleStateSearchResult {
    /**
     * 
     * @type {string}
     * @memberof VehicleStateSearchResult
     */
    inventoryId: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleStateSearchResult
     */
    vehicleIdentifier: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleStateSearchResult
     */
    vehicleImageIds: Array<string>;
}

/**
 * Check if a given object implements the VehicleStateSearchResult interface.
 */
export function instanceOfVehicleStateSearchResult(value: object): value is VehicleStateSearchResult {
    if (!('inventoryId' in value) || value['inventoryId'] === undefined) return false;
    if (!('vehicleIdentifier' in value) || value['vehicleIdentifier'] === undefined) return false;
    if (!('vehicleImageIds' in value) || value['vehicleImageIds'] === undefined) return false;
    return true;
}

export function VehicleStateSearchResultFromJSON(json: any): VehicleStateSearchResult {
    return VehicleStateSearchResultFromJSONTyped(json, false);
}

export function VehicleStateSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleStateSearchResult {
    if (json == null) {
        return json;
    }
    return {
        
        'inventoryId': json['inventoryId'],
        'vehicleIdentifier': json['vehicleIdentifier'],
        'vehicleImageIds': json['vehicleImageIds'],
    };
}

export function VehicleStateSearchResultToJSON(json: any): VehicleStateSearchResult {
    return VehicleStateSearchResultToJSONTyped(json, false);
}

export function VehicleStateSearchResultToJSONTyped(value?: VehicleStateSearchResult | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'inventoryId': value['inventoryId'],
        'vehicleIdentifier': value['vehicleIdentifier'],
        'vehicleImageIds': value['vehicleImageIds'],
    };
}


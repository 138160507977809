/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOExteriorColorType } from './SelectOptionDTOExteriorColorType';
import {
    SelectOptionDTOExteriorColorTypeFromJSON,
    SelectOptionDTOExteriorColorTypeFromJSONTyped,
    SelectOptionDTOExteriorColorTypeToJSON,
    SelectOptionDTOExteriorColorTypeToJSONTyped,
} from './SelectOptionDTOExteriorColorType';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface CustomExteriorColorCreateOptionsDTO
 */
export interface CustomExteriorColorCreateOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof CustomExteriorColorCreateOptionsDTO
     */
    exteriorColorTags: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOExteriorColorType>}
     * @memberof CustomExteriorColorCreateOptionsDTO
     */
    exteriorColorTypes: Array<SelectOptionDTOExteriorColorType>;
}

/**
 * Check if a given object implements the CustomExteriorColorCreateOptionsDTO interface.
 */
export function instanceOfCustomExteriorColorCreateOptionsDTO(value: object): value is CustomExteriorColorCreateOptionsDTO {
    if (!('exteriorColorTags' in value) || value['exteriorColorTags'] === undefined) return false;
    if (!('exteriorColorTypes' in value) || value['exteriorColorTypes'] === undefined) return false;
    return true;
}

export function CustomExteriorColorCreateOptionsDTOFromJSON(json: any): CustomExteriorColorCreateOptionsDTO {
    return CustomExteriorColorCreateOptionsDTOFromJSONTyped(json, false);
}

export function CustomExteriorColorCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomExteriorColorCreateOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'exteriorColorTags': ((json['exteriorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'exteriorColorTypes': ((json['exteriorColorTypes'] as Array<any>).map(SelectOptionDTOExteriorColorTypeFromJSON)),
    };
}

export function CustomExteriorColorCreateOptionsDTOToJSON(json: any): CustomExteriorColorCreateOptionsDTO {
    return CustomExteriorColorCreateOptionsDTOToJSONTyped(json, false);
}

export function CustomExteriorColorCreateOptionsDTOToJSONTyped(value?: CustomExteriorColorCreateOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'exteriorColorTags': ((value['exteriorColorTags'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'exteriorColorTypes': ((value['exteriorColorTypes'] as Array<any>).map(SelectOptionDTOExteriorColorTypeToJSON)),
    };
}


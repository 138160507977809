/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue } from './OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue';
import {
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSONTyped,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSONTyped,
} from './OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue';
import type { OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue } from './OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue';
import {
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSON,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSON,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSONTyped,
} from './OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor
 */
export interface OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor {
    /**
     * 
     * @type {OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue}
     * @memberof OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor
     */
    defaultValue: OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue>}
     * @memberof OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor
     */
    conditionalValues: Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor
     */
    isDefaultValueActive: boolean;
}

/**
 * Check if a given object implements the OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor interface.
 */
export function instanceOfOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor(value: object): value is OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor {
    if (!('defaultValue' in value) || value['defaultValue'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelGenerationKeys' in value) || value['modelGenerationKeys'] === undefined) return false;
    if (!('conditionalValues' in value) || value['conditionalValues'] === undefined) return false;
    if (!('isDefaultValueActive' in value) || value['isDefaultValueActive'] === undefined) return false;
    return true;
}

export function OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorFromJSON(json: any): OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor {
    return OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
    };
}

export function OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorToJSON(json: any): OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor {
    return OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorToJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorToJSONTyped(value?: OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSON(value['defaultValue']),
        'optionCode': value['optionCode'],
        'modelGenerationKeys': value['modelGenerationKeys'],
        'conditionalValues': ((value['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSON)),
        'isDefaultValueActive': value['isDefaultValueActive'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LocalizationsItemDTO } from './LocalizationsItemDTO';
import {
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOFromJSONTyped,
    LocalizationsItemDTOToJSON,
    LocalizationsItemDTOToJSONTyped,
} from './LocalizationsItemDTO';
import type { IntRange } from './IntRange';
import {
    IntRangeFromJSON,
    IntRangeFromJSONTyped,
    IntRangeToJSON,
    IntRangeToJSONTyped,
} from './IntRange';

/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO
 */
export interface OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO
     */
    key: string;
    /**
     * 
     * @type {IntRange}
     * @memberof OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO
     */
    modelYearRange?: IntRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO
     */
    derivativeKeys?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: LocalizationsItemDTO; }}
     * @memberof OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO
     */
    value: { [key: string]: LocalizationsItemDTO; };
}

/**
 * Check if a given object implements the OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO interface.
 */
export function instanceOfOptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO(value: object): value is OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTOFromJSON(json: any): OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO {
    return OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTOFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelYearRange': json['modelYearRange'] == null ? undefined : IntRangeFromJSON(json['modelYearRange']),
        'derivativeKeys': json['derivativeKeys'] == null ? undefined : json['derivativeKeys'],
        'value': (mapValues(json['value'], LocalizationsItemDTOFromJSON)),
    };
}

export function OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTOToJSON(json: any): OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO {
    return OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTOToJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTOToJSONTyped(value?: OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'modelYearRange': IntRangeToJSON(value['modelYearRange']),
        'derivativeKeys': value['derivativeKeys'],
        'value': (mapValues(value['value'], LocalizationsItemDTOToJSON)),
    };
}


import "./App.css"
import { CognitoRedirect } from "./common/CognitoRedirect"
import { PdsBootContextProvider } from "./common/PdsBootContextProvider"
import { PdsHeader } from "./components/pdsheader/PdsHeader"
import { bootApi } from "./pdsapi"
import { DashboardRoute } from "./views/dashboard/DashboardRoute"
import { ToastManager } from "@finder/ui-kit"
import { PorscheDesignSystemProvider } from "@porsche-design-system/components-react"
import { FC, ReactNode } from "react"
import { IntlProvider } from "react-intl"
import { BrowserRouter, Routes } from "react-router-dom"
import { BootInfoDTO } from "./generated/pdsapi"
import { modelRoutes } from "./views/models/modelRoutes"
import { customColorRoutes } from "./views/customcolors/customColorRoutes"
import { optionRoutes } from "./views/options/optionRoutes"
import { technicalDataRoutes } from "./views/technicaldata/technicalDataRoutes"

interface AppProps {
    children: ReactNode
    getBootInfo: () => Promise<BootInfoDTO>
}

export const AppWrapper: FC<AppProps> = ({ children, getBootInfo }) => (
    <IntlProvider locale={"en"}>
        <CognitoRedirect>
            <PdsBootContextProvider getBootInfo={getBootInfo}>
                <BrowserRouter>
                    <PorscheDesignSystemProvider>
                        <ToastManager>{children}</ToastManager>
                    </PorscheDesignSystemProvider>
                </BrowserRouter>
            </PdsBootContextProvider>
        </CognitoRedirect>
    </IntlProvider>
)

const App = () => (
    <AppWrapper getBootInfo={bootApi.boot}>
        <header>
            <PdsHeader />
        </header>
        <main>
            <Routes children={[DashboardRoute, ...modelRoutes, ...optionRoutes, ...customColorRoutes, ...technicalDataRoutes]} />
        </main>
    </AppWrapper>
)

export default App

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseInformationDTO,
  DerivativeCategoryAttributesDTO,
  DerivativeCategoryAttributesUpdateDTO,
  DerivativeCategoryLinksDTO,
  LocalizationsItemDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    DerivativeCategoryAttributesDTOFromJSON,
    DerivativeCategoryAttributesDTOToJSON,
    DerivativeCategoryAttributesUpdateDTOFromJSON,
    DerivativeCategoryAttributesUpdateDTOToJSON,
    DerivativeCategoryLinksDTOFromJSON,
    DerivativeCategoryLinksDTOToJSON,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface DeleteDerivativeCategoryRequest {
    key: string;
}

export interface GetAttributesOfDerivativeCategoryRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfDerivativeCategoryRequest {
    key: string;
    languageTag: string;
}

export interface GetLinksOfDerivativeCategoryRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfDescriptionOfDerivativeCategoryRequest {
    key: string;
}

export interface GetLocalizationsOfNameOfDerivativeCategoryRequest {
    key: string;
}

export interface UpdateAttributesOfDerivativeCategoryRequest {
    key: string;
    derivativeCategoryAttributesUpdateDTO: DerivativeCategoryAttributesUpdateDTO;
}

export interface UpdateDescriptionOfDerivativeCategoryRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfDerivativeCategoryRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class DerivativeCategoryDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a DerivativeCategory by a given key
     */
    async deleteDerivativeCategoryRaw(requestParameters: DeleteDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling deleteDerivativeCategory().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a DerivativeCategory by a given key
     */
    async deleteDerivativeCategory(requestParameters: DeleteDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDerivativeCategoryRaw(requestParameters, initOverrides);
    }

    /**
     * Get attributes of DerivativeCategory with given key
     */
    async getAttributesOfDerivativeCategoryRaw(requestParameters: GetAttributesOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DerivativeCategoryAttributesDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getAttributesOfDerivativeCategory().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getAttributesOfDerivativeCategory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DerivativeCategoryAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of DerivativeCategory with given key
     */
    async getAttributesOfDerivativeCategory(requestParameters: GetAttributesOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DerivativeCategoryAttributesDTO> {
        const response = await this.getAttributesOfDerivativeCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get baseInformation of DerivativeCategory with given key
     */
    async getBaseInformationOfDerivativeCategoryRaw(requestParameters: GetBaseInformationOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getBaseInformationOfDerivativeCategory().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getBaseInformationOfDerivativeCategory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of DerivativeCategory with given key
     */
    async getBaseInformationOfDerivativeCategory(requestParameters: GetBaseInformationOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfDerivativeCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get links of DerivativeCategory with given key
     */
    async getLinksOfDerivativeCategoryRaw(requestParameters: GetLinksOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DerivativeCategoryLinksDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getLinksOfDerivativeCategory().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getLinksOfDerivativeCategory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/links`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DerivativeCategoryLinksDTOFromJSON(jsonValue));
    }

    /**
     * Get links of DerivativeCategory with given key
     */
    async getLinksOfDerivativeCategory(requestParameters: GetLinksOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DerivativeCategoryLinksDTO> {
        const response = await this.getLinksOfDerivativeCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get localizationsOfDescription of DerivativeCategory with given key
     */
    async getLocalizationsOfDescriptionOfDerivativeCategoryRaw(requestParameters: GetLocalizationsOfDescriptionOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getLocalizationsOfDescriptionOfDerivativeCategory().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/description/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfDescription of DerivativeCategory with given key
     */
    async getLocalizationsOfDescriptionOfDerivativeCategory(requestParameters: GetLocalizationsOfDescriptionOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfDescriptionOfDerivativeCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get localizationsOfName of DerivativeCategory with given key
     */
    async getLocalizationsOfNameOfDerivativeCategoryRaw(requestParameters: GetLocalizationsOfNameOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getLocalizationsOfNameOfDerivativeCategory().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of DerivativeCategory with given key
     */
    async getLocalizationsOfNameOfDerivativeCategory(requestParameters: GetLocalizationsOfNameOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfDerivativeCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates attributes of an existing DerivativeCategory
     */
    async updateAttributesOfDerivativeCategoryRaw(requestParameters: UpdateAttributesOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateAttributesOfDerivativeCategory().'
            );
        }

        if (requestParameters['derivativeCategoryAttributesUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'derivativeCategoryAttributesUpdateDTO',
                'Required parameter "derivativeCategoryAttributesUpdateDTO" was null or undefined when calling updateAttributesOfDerivativeCategory().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DerivativeCategoryAttributesUpdateDTOToJSON(requestParameters['derivativeCategoryAttributesUpdateDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing DerivativeCategory
     */
    async updateAttributesOfDerivativeCategory(requestParameters: UpdateAttributesOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAttributesOfDerivativeCategoryRaw(requestParameters, initOverrides);
    }

    /**
     * Updates description of an existing DerivativeCategory
     */
    async updateDescriptionOfDerivativeCategoryRaw(requestParameters: UpdateDescriptionOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateDescriptionOfDerivativeCategory().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateDescriptionOfDerivativeCategory().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/description`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates description of an existing DerivativeCategory
     */
    async updateDescriptionOfDerivativeCategory(requestParameters: UpdateDescriptionOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDescriptionOfDerivativeCategoryRaw(requestParameters, initOverrides);
    }

    /**
     * Updates name of an existing DerivativeCategory
     */
    async updateNameOfDerivativeCategoryRaw(requestParameters: UpdateNameOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateNameOfDerivativeCategory().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateNameOfDerivativeCategory().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/derivative-categories/details/{key}/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing DerivativeCategory
     */
    async updateNameOfDerivativeCategory(requestParameters: UpdateNameOfDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNameOfDerivativeCategoryRaw(requestParameters, initOverrides);
    }

}

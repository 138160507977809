/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VehicleEquipmentCategory } from './VehicleEquipmentCategory';
import {
    VehicleEquipmentCategoryFromJSON,
    VehicleEquipmentCategoryFromJSONTyped,
    VehicleEquipmentCategoryToJSON,
    VehicleEquipmentCategoryToJSONTyped,
} from './VehicleEquipmentCategory';

/**
 * 
 * @export
 * @interface SelectOptionDTOVehicleEquipmentCategory
 */
export interface SelectOptionDTOVehicleEquipmentCategory {
    /**
     * 
     * @type {VehicleEquipmentCategory}
     * @memberof SelectOptionDTOVehicleEquipmentCategory
     */
    key: VehicleEquipmentCategory;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDTOVehicleEquipmentCategory
     */
    label: string;
}



/**
 * Check if a given object implements the SelectOptionDTOVehicleEquipmentCategory interface.
 */
export function instanceOfSelectOptionDTOVehicleEquipmentCategory(value: object): value is SelectOptionDTOVehicleEquipmentCategory {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function SelectOptionDTOVehicleEquipmentCategoryFromJSON(json: any): SelectOptionDTOVehicleEquipmentCategory {
    return SelectOptionDTOVehicleEquipmentCategoryFromJSONTyped(json, false);
}

export function SelectOptionDTOVehicleEquipmentCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectOptionDTOVehicleEquipmentCategory {
    if (json == null) {
        return json;
    }
    return {
        
        'key': VehicleEquipmentCategoryFromJSON(json['key']),
        'label': json['label'],
    };
}

export function SelectOptionDTOVehicleEquipmentCategoryToJSON(json: any): SelectOptionDTOVehicleEquipmentCategory {
    return SelectOptionDTOVehicleEquipmentCategoryToJSONTyped(json, false);
}

export function SelectOptionDTOVehicleEquipmentCategoryToJSONTyped(value?: SelectOptionDTOVehicleEquipmentCategory | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': VehicleEquipmentCategoryToJSON(value['key']),
        'label': value['label'],
    };
}


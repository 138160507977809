/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue } from './OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue';
import {
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValueFromJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValueFromJSONTyped,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValueToJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValueToJSONTyped,
} from './OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue';
import type { OptionCustomizationAttributesUpdateDTOInteriorValue } from './OptionCustomizationAttributesUpdateDTOInteriorValue';
import {
    OptionCustomizationAttributesUpdateDTOInteriorValueFromJSON,
    OptionCustomizationAttributesUpdateDTOInteriorValueFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOInteriorValueToJSON,
    OptionCustomizationAttributesUpdateDTOInteriorValueToJSONTyped,
} from './OptionCustomizationAttributesUpdateDTOInteriorValue';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesUpdateDTOInterior
 */
export interface OptionCustomizationAttributesUpdateDTOInterior {
    /**
     * 
     * @type {OptionCustomizationAttributesUpdateDTOInteriorValue}
     * @memberof OptionCustomizationAttributesUpdateDTOInterior
     */
    defaultValue: OptionCustomizationAttributesUpdateDTOInteriorValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesUpdateDTOInterior
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationAttributesUpdateDTOInterior
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue>}
     * @memberof OptionCustomizationAttributesUpdateDTOInterior
     */
    conditionalValues: Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesUpdateDTOInterior
     */
    isDefaultValueActive: boolean;
}

/**
 * Check if a given object implements the OptionCustomizationAttributesUpdateDTOInterior interface.
 */
export function instanceOfOptionCustomizationAttributesUpdateDTOInterior(value: object): value is OptionCustomizationAttributesUpdateDTOInterior {
    if (!('defaultValue' in value) || value['defaultValue'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelGenerationKeys' in value) || value['modelGenerationKeys'] === undefined) return false;
    if (!('conditionalValues' in value) || value['conditionalValues'] === undefined) return false;
    if (!('isDefaultValueActive' in value) || value['isDefaultValueActive'] === undefined) return false;
    return true;
}

export function OptionCustomizationAttributesUpdateDTOInteriorFromJSON(json: any): OptionCustomizationAttributesUpdateDTOInterior {
    return OptionCustomizationAttributesUpdateDTOInteriorFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOInteriorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTOInterior {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTOInteriorValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValueFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
    };
}

export function OptionCustomizationAttributesUpdateDTOInteriorToJSON(json: any): OptionCustomizationAttributesUpdateDTOInterior {
    return OptionCustomizationAttributesUpdateDTOInteriorToJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOInteriorToJSONTyped(value?: OptionCustomizationAttributesUpdateDTOInterior | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTOInteriorValueToJSON(value['defaultValue']),
        'optionCode': value['optionCode'],
        'modelGenerationKeys': value['modelGenerationKeys'],
        'conditionalValues': ((value['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValueToJSON)),
        'isDefaultValueActive': value['isDefaultValueActive'],
    };
}


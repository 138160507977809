/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OrderTypeMediaDTO,
  OrderTypeMediaRelatedDataDTO,
  OrderTypeMediaUpdateDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    OrderTypeMediaDTOFromJSON,
    OrderTypeMediaDTOToJSON,
    OrderTypeMediaRelatedDataDTOFromJSON,
    OrderTypeMediaRelatedDataDTOToJSON,
    OrderTypeMediaUpdateDTOFromJSON,
    OrderTypeMediaUpdateDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface GetEngineSoundsOfSoundOfMediaRequest {
    key: string;
}

export interface GetRelatedDataOfMediaRequest {
    key: string;
}

export interface UpdateOfEngineSoundsOfSoundOfMediaRequest {
    key: string;
    orderTypeMediaUpdateDTO: OrderTypeMediaUpdateDTO;
}

/**
 * 
 */
export class OrderTypeMediaDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get engineSoundsOfSound of Media with given key
     */
    async getEngineSoundsOfSoundOfMediaRaw(requestParameters: GetEngineSoundsOfSoundOfMediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderTypeMediaDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getEngineSoundsOfSoundOfMedia().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/media/details/{key}/media/sound/engine-sounds`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeMediaDTOFromJSON(jsonValue));
    }

    /**
     * Get engineSoundsOfSound of Media with given key
     */
    async getEngineSoundsOfSoundOfMedia(requestParameters: GetEngineSoundsOfSoundOfMediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderTypeMediaDTO> {
        const response = await this.getEngineSoundsOfSoundOfMediaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get relatedData of Media with given key
     */
    async getRelatedDataOfMediaRaw(requestParameters: GetRelatedDataOfMediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderTypeMediaRelatedDataDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getRelatedDataOfMedia().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/media/details/{key}/media/related-data`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeMediaRelatedDataDTOFromJSON(jsonValue));
    }

    /**
     * Get relatedData of Media with given key
     */
    async getRelatedDataOfMedia(requestParameters: GetRelatedDataOfMediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderTypeMediaRelatedDataDTO> {
        const response = await this.getRelatedDataOfMediaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates OfEngineSoundsOfSound of an existing Media
     */
    async updateOfEngineSoundsOfSoundOfMediaRaw(requestParameters: UpdateOfEngineSoundsOfSoundOfMediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateOfEngineSoundsOfSoundOfMedia().'
            );
        }

        if (requestParameters['orderTypeMediaUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'orderTypeMediaUpdateDTO',
                'Required parameter "orderTypeMediaUpdateDTO" was null or undefined when calling updateOfEngineSoundsOfSoundOfMedia().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/media/details/{key}/media/sound/engine-sounds/`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderTypeMediaUpdateDTOToJSON(requestParameters['orderTypeMediaUpdateDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates OfEngineSoundsOfSound of an existing Media
     */
    async updateOfEngineSoundsOfSoundOfMedia(requestParameters: UpdateOfEngineSoundsOfSoundOfMediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateOfEngineSoundsOfSoundOfMediaRaw(requestParameters, initOverrides);
    }

}

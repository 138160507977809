/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MarketplaceDTO } from './MarketplaceDTO';
import {
    MarketplaceDTOFromJSON,
    MarketplaceDTOFromJSONTyped,
    MarketplaceDTOToJSON,
    MarketplaceDTOToJSONTyped,
} from './MarketplaceDTO';
import type { UserAuthority } from './UserAuthority';
import {
    UserAuthorityFromJSON,
    UserAuthorityFromJSONTyped,
    UserAuthorityToJSON,
    UserAuthorityToJSONTyped,
} from './UserAuthority';

/**
 * 
 * @export
 * @interface BootInfoDTO
 */
export interface BootInfoDTO {
    /**
     * 
     * @type {Array<UserAuthority>}
     * @memberof BootInfoDTO
     */
    userAuthorities: Array<UserAuthority>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BootInfoDTO
     */
    supportedLanguageTags: Array<string>;
    /**
     * 
     * @type {Array<MarketplaceDTO>}
     * @memberof BootInfoDTO
     */
    supportedMarketplaces: Array<MarketplaceDTO>;
}

/**
 * Check if a given object implements the BootInfoDTO interface.
 */
export function instanceOfBootInfoDTO(value: object): value is BootInfoDTO {
    if (!('userAuthorities' in value) || value['userAuthorities'] === undefined) return false;
    if (!('supportedLanguageTags' in value) || value['supportedLanguageTags'] === undefined) return false;
    if (!('supportedMarketplaces' in value) || value['supportedMarketplaces'] === undefined) return false;
    return true;
}

export function BootInfoDTOFromJSON(json: any): BootInfoDTO {
    return BootInfoDTOFromJSONTyped(json, false);
}

export function BootInfoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BootInfoDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'userAuthorities': ((json['userAuthorities'] as Array<any>).map(UserAuthorityFromJSON)),
        'supportedLanguageTags': json['supportedLanguageTags'],
        'supportedMarketplaces': ((json['supportedMarketplaces'] as Array<any>).map(MarketplaceDTOFromJSON)),
    };
}

export function BootInfoDTOToJSON(json: any): BootInfoDTO {
    return BootInfoDTOToJSONTyped(json, false);
}

export function BootInfoDTOToJSONTyped(value?: BootInfoDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'userAuthorities': ((value['userAuthorities'] as Array<any>).map(UserAuthorityToJSON)),
        'supportedLanguageTags': value['supportedLanguageTags'],
        'supportedMarketplaces': ((value['supportedMarketplaces'] as Array<any>).map(MarketplaceDTOToJSON)),
    };
}


import { OrderTypeOptionsCardProps } from "../OrderTypeOptionsCard"
import { InteriorMaterial, OptionClassDTO, OptionSortField, VehicleEquipmentCategory } from "../../../../../../generated/pdsapi"
import { routes } from "../../../../../../common/routes"
import { orderTypeApi, OrderTypeOptionFilter } from "../../../../../../pdsapi"
import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../../../common/useURLSearchParamState"
import { OrderTypeOptionItemsCardColumnsConfig } from "./columns/OrderTypeOptionItemsCardColumns"
import { OrderTypeOptionItemsCardFilter } from "./filter/OrderTypeOptionItemsCardFilter"
import { OrderTypeOptionItemsCardFilterTagsConfig } from "./filter/OrderTypeOptionItemsCardFilterTagsConfig"
import { optionClassToOptionTypeEnum } from "../optionTypeMappings"

export const orderTypeOptionItemsCardConfig: OrderTypeOptionsCardProps = {
    getCreateRoute: (orderTypeKey) => routes.models.orderTypes.subpages(orderTypeKey).options.create(),
    getDetailsRoute: (orderTypeKey, option) =>
        routes.models.orderTypes.subpages(orderTypeKey).options.details(option.optionCode, optionClassToOptionTypeEnum(option.type)),
    getOptions: orderTypeApi.details.options.getAll,
    getFilterOptions: orderTypeApi.details.options.getAllFilterOptions,

    defaultSortField: OptionSortField.SORT_INDEX,
    sortFieldEnum: OptionSortField,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<OrderTypeOptionFilter>({
        classes: getEnumConverterGroup(OptionClassDTO).optionalSet,
        equipmentTagKeys: stringConverterGroup.optionalSet,
        interiorColorTagKeys: stringConverterGroup.optionalSet,
        exteriorColorTagKeys: stringConverterGroup.optionalSet,
        roofColorTagKeys: stringConverterGroup.optionalSet,
        vehicleEquipmentCategories: getEnumConverterGroup(VehicleEquipmentCategory).optionalSet,
        equipmentFamilies: stringConverterGroup.optionalSet,
        interiorMaterials: getEnumConverterGroup(InteriorMaterial).optionalSet,
    }),

    columnsConfig: OrderTypeOptionItemsCardColumnsConfig,
    FilterComponent: OrderTypeOptionItemsCardFilter,
    filterTagsConfig: OrderTypeOptionItemsCardFilterTagsConfig,
}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InteriorColorTileDTO } from './InteriorColorTileDTO';
import {
    InteriorColorTileDTOFromJSON,
    InteriorColorTileDTOFromJSONTyped,
    InteriorColorTileDTOToJSON,
    InteriorColorTileDTOToJSONTyped,
} from './InteriorColorTileDTO';

/**
 * 
 * @export
 * @interface SimilarCustomizationsDTOSimilarCustomizationInterior
 */
export interface SimilarCustomizationsDTOSimilarCustomizationInterior {
    /**
     * 
     * @type {string}
     * @memberof SimilarCustomizationsDTOSimilarCustomizationInterior
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof SimilarCustomizationsDTOSimilarCustomizationInterior
     */
    label: string;
    /**
     * 
     * @type {InteriorColorTileDTO}
     * @memberof SimilarCustomizationsDTOSimilarCustomizationInterior
     */
    color: InteriorColorTileDTO;
}

/**
 * Check if a given object implements the SimilarCustomizationsDTOSimilarCustomizationInterior interface.
 */
export function instanceOfSimilarCustomizationsDTOSimilarCustomizationInterior(value: object): value is SimilarCustomizationsDTOSimilarCustomizationInterior {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('color' in value) || value['color'] === undefined) return false;
    return true;
}

export function SimilarCustomizationsDTOSimilarCustomizationInteriorFromJSON(json: any): SimilarCustomizationsDTOSimilarCustomizationInterior {
    return SimilarCustomizationsDTOSimilarCustomizationInteriorFromJSONTyped(json, false);
}

export function SimilarCustomizationsDTOSimilarCustomizationInteriorFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilarCustomizationsDTOSimilarCustomizationInterior {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'label': json['label'],
        'color': InteriorColorTileDTOFromJSON(json['color']),
    };
}

export function SimilarCustomizationsDTOSimilarCustomizationInteriorToJSON(json: any): SimilarCustomizationsDTOSimilarCustomizationInterior {
    return SimilarCustomizationsDTOSimilarCustomizationInteriorToJSONTyped(json, false);
}

export function SimilarCustomizationsDTOSimilarCustomizationInteriorToJSONTyped(value?: SimilarCustomizationsDTOSimilarCustomizationInterior | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'label': value['label'],
        'color': InteriorColorTileDTOToJSON(value['color']),
    };
}


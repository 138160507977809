/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TechnicalDataItemDTO } from './TechnicalDataItemDTO';
import {
    TechnicalDataItemDTOFromJSON,
    TechnicalDataItemDTOFromJSONTyped,
    TechnicalDataItemDTOToJSON,
    TechnicalDataItemDTOToJSONTyped,
} from './TechnicalDataItemDTO';

/**
 * 
 * @export
 * @interface PageDTOTechnicalDataItemDTO
 */
export interface PageDTOTechnicalDataItemDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTOTechnicalDataItemDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<TechnicalDataItemDTO>}
     * @memberof PageDTOTechnicalDataItemDTO
     */
    content: Array<TechnicalDataItemDTO>;
}

/**
 * Check if a given object implements the PageDTOTechnicalDataItemDTO interface.
 */
export function instanceOfPageDTOTechnicalDataItemDTO(value: object): value is PageDTOTechnicalDataItemDTO {
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function PageDTOTechnicalDataItemDTOFromJSON(json: any): PageDTOTechnicalDataItemDTO {
    return PageDTOTechnicalDataItemDTOFromJSONTyped(json, false);
}

export function PageDTOTechnicalDataItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOTechnicalDataItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(TechnicalDataItemDTOFromJSON)),
    };
}

export function PageDTOTechnicalDataItemDTOToJSON(json: any): PageDTOTechnicalDataItemDTO {
    return PageDTOTechnicalDataItemDTOToJSONTyped(json, false);
}

export function PageDTOTechnicalDataItemDTOToJSONTyped(value?: PageDTOTechnicalDataItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'totalItemCount': value['totalItemCount'],
        'content': ((value['content'] as Array<any>).map(TechnicalDataItemDTOToJSON)),
    };
}


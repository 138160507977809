/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';

/**
 * 
 * @export
 * @interface OptionCreateRequestDTOInterior
 */
export interface OptionCreateRequestDTOInterior {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCreateRequestDTOInterior
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof OptionCreateRequestDTOInterior
     */
    orderTypeKey: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCreateRequestDTOInterior
     */
    optionCode: string;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCreateRequestDTOInterior
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {number}
     * @memberof OptionCreateRequestDTOInterior
     */
    sortIndex?: number;
}

/**
 * Check if a given object implements the OptionCreateRequestDTOInterior interface.
 */
export function instanceOfOptionCreateRequestDTOInterior(value: object): value is OptionCreateRequestDTOInterior {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('orderTypeKey' in value) || value['orderTypeKey'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    return true;
}

export function OptionCreateRequestDTOInteriorFromJSON(json: any): OptionCreateRequestDTOInterior {
    return OptionCreateRequestDTOInteriorFromJSONTyped(json, false);
}

export function OptionCreateRequestDTOInteriorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCreateRequestDTOInterior {
    if (json == null) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'orderTypeKey': json['orderTypeKey'],
        'optionCode': json['optionCode'],
        'description': BaseNameDTOFromJSON(json['description']),
        'sortIndex': json['sortIndex'] == null ? undefined : json['sortIndex'],
    };
}

export function OptionCreateRequestDTOInteriorToJSON(json: any): OptionCreateRequestDTOInterior {
    return OptionCreateRequestDTOInteriorToJSONTyped(json, false);
}

export function OptionCreateRequestDTOInteriorToJSONTyped(value?: OptionCreateRequestDTOInterior | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': BaseNameDTOToJSON(value['name']),
        'orderTypeKey': value['orderTypeKey'],
        'optionCode': value['optionCode'],
        'description': BaseNameDTOToJSON(value['description']),
        'sortIndex': value['sortIndex'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization } from './MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization';
import {
    MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSON,
    MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSONTyped,
    MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationToJSON,
    MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationToJSONTyped,
} from './MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization';

/**
 * 
 * @export
 * @interface PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
 */
export interface PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    /**
     * 
     * @type {number}
     * @memberof PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization>}
     * @memberof PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
     */
    content: Array<MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization>;
}

/**
 * Check if a given object implements the PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization interface.
 */
export function instanceOfPageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization(value: object): value is PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSON(json: any): PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    return PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSONTyped(json, false);
}

export function PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    if (json == null) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSON)),
    };
}

export function PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationToJSON(json: any): PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    return PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationToJSONTyped(json, false);
}

export function PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationToJSONTyped(value?: PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'totalItemCount': value['totalItemCount'],
        'content': ((value['content'] as Array<any>).map(MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ColorTileDTO } from './ColorTileDTO';
import {
    ColorTileDTOFromJSON,
    ColorTileDTOFromJSONTyped,
    ColorTileDTOToJSON,
    ColorTileDTOToJSONTyped,
} from './ColorTileDTO';

/**
 * 
 * @export
 * @interface OptionItemDTORoofColor
 */
export interface OptionItemDTORoofColor {
    /**
     * 
     * @type {string}
     * @memberof OptionItemDTORoofColor
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionItemDTORoofColor
     */
    orderTypeKey: string;
    /**
     * 
     * @type {string}
     * @memberof OptionItemDTORoofColor
     */
    optionCode: string;
    /**
     * 
     * @type {number}
     * @memberof OptionItemDTORoofColor
     */
    modelYear: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionItemDTORoofColor
     */
    orderTypeCodes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OptionItemDTORoofColor
     */
    imageKey?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionItemDTORoofColor
     */
    modelGenerationName?: string;
    /**
     * 
     * @type {ColorTileDTO}
     * @memberof OptionItemDTORoofColor
     */
    color?: ColorTileDTO;
    /**
     * 
     * @type {ColorTileDTO}
     * @memberof OptionItemDTORoofColor
     */
    tag?: ColorTileDTO;
}

/**
 * Check if a given object implements the OptionItemDTORoofColor interface.
 */
export function instanceOfOptionItemDTORoofColor(value: object): value is OptionItemDTORoofColor {
    if (!('orderTypeKey' in value) || value['orderTypeKey'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelYear' in value) || value['modelYear'] === undefined) return false;
    if (!('orderTypeCodes' in value) || value['orderTypeCodes'] === undefined) return false;
    return true;
}

export function OptionItemDTORoofColorFromJSON(json: any): OptionItemDTORoofColor {
    return OptionItemDTORoofColorFromJSONTyped(json, false);
}

export function OptionItemDTORoofColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionItemDTORoofColor {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'orderTypeKey': json['orderTypeKey'],
        'optionCode': json['optionCode'],
        'modelYear': json['modelYear'],
        'orderTypeCodes': json['orderTypeCodes'],
        'imageKey': json['imageKey'] == null ? undefined : json['imageKey'],
        'modelGenerationName': json['modelGenerationName'] == null ? undefined : json['modelGenerationName'],
        'color': json['color'] == null ? undefined : ColorTileDTOFromJSON(json['color']),
        'tag': json['tag'] == null ? undefined : ColorTileDTOFromJSON(json['tag']),
    };
}

export function OptionItemDTORoofColorToJSON(json: any): OptionItemDTORoofColor {
    return OptionItemDTORoofColorToJSONTyped(json, false);
}

export function OptionItemDTORoofColorToJSONTyped(value?: OptionItemDTORoofColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'orderTypeKey': value['orderTypeKey'],
        'optionCode': value['optionCode'],
        'modelYear': value['modelYear'],
        'orderTypeCodes': value['orderTypeCodes'],
        'imageKey': value['imageKey'],
        'modelGenerationName': value['modelGenerationName'],
        'color': ColorTileDTOToJSON(value['color']),
        'tag': ColorTileDTOToJSON(value['tag']),
    };
}


import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { DerivativeFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { DerivativeFilter } from "../../../../../pdsapi"
import { findGroupedOption, findOption } from "../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { filterTagsFor } from "../../../../../components/filteredpagedtable/filtertags/filterTagsFor"

export const DerivativeListFilterTagsConfig: TableFilterTagsConfig<DerivativeFilter, DerivativeFilterOptionsDTO> = ({ filter, setFilter, filterOptions }) => [
    ...filterTagsFor(filter, setFilter, "modelSeriesKeys", (modelSeriesKey) => findOption(filterOptions?.modelSeries, modelSeriesKey)?.label ?? modelSeriesKey),
    ...filterTagsFor(
        filter,
        setFilter,
        "derivativeCategoryKeys",
        (derivativeCategoryKey) =>
            findGroupedOption(filterOptions?.modelSeriesRelatedDerivativeCategories, derivativeCategoryKey)?.label ?? derivativeCategoryKey
    ),
    ...filterTagsFor(filter, setFilter, "bodyTypes", (bodyType) => findOption(filterOptions?.bodyTypes, bodyType)?.label ?? bodyType),
]

import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { OptionCustomizationFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { OptionCustomizationFilter } from "../../../../../pdsapi"
import { findGroupedOption, findOption } from "../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { filterTagsFor } from "../../../../../components/filteredpagedtable/filtertags/filterTagsFor"

export const OptionCustomizationListFilterTagsConfig: TableFilterTagsConfig<OptionCustomizationFilter, OptionCustomizationFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => [
    ...filterTagsFor(filter, setFilter, "types", (type) => findOption(filterOptions?.types, type)?.label ?? type),
    ...filterTagsFor(
        filter,
        setFilter,
        "modelGenerationKeys",
        (modelGenerationKey) => findOption(filterOptions?.modelGenerations, modelGenerationKey)?.label ?? modelGenerationKey
    ),
    ...filterTagsFor(
        filter,
        setFilter,
        "equipmentTagKeys",
        (equipmentTagKey) => findGroupedOption(filterOptions?.categorizedEquipmentTags, equipmentTagKey)?.label ?? equipmentTagKey
    ),
    ...filterTagsFor(
        filter,
        setFilter,
        "interiorColorTagKeys",
        (interiorColorTagKey) => findOption(filterOptions?.interiorColorTags, interiorColorTagKey)?.label ?? interiorColorTagKey
    ),
    ...filterTagsFor(
        filter,
        setFilter,
        "exteriorColorTagKeys",
        (exteriorColorTagKey) => findOption(filterOptions?.exteriorColorTags, exteriorColorTagKey)?.label ?? exteriorColorTagKey
    ),
    ...filterTagsFor(
        filter,
        setFilter,
        "roofColorTagKeys",
        (roofColorTagKey) => findOption(filterOptions?.roofColorTags, roofColorTagKey)?.label ?? roofColorTagKey
    ),
    ...filterTagsFor(
        filter,
        setFilter,
        "interiorMaterials",
        (interiorMaterial) => findOption(filterOptions?.interiorMaterials, interiorMaterial)?.label ?? interiorMaterial
    ),
]

import { MultiSelect } from "@finder/ui-kit"
import { toOption } from "../formelements/selecttextformelement/Option"

interface MultiSelectFilterProps<T> {
    label: string
    options: { key: T; label: string }[] | undefined
    value: Set<T> | undefined
    onChange: (value: Set<T> | undefined) => void
}

export const MultiSelectFilter = <T extends string>({ label, options, value, onChange }: MultiSelectFilterProps<T>) => (
    <MultiSelect
        label={label}
        placeholder={"All"}
        clearable={true}
        searchable={true}
        options={options?.map(toOption)}
        value={value ? Array.from(value) : []}
        onChange={(keys) => onChange(keys.length === 0 ? undefined : new Set(keys as T[]))}
    />
)
export const NumberMultiSelectFilter = ({ label, options, value, onChange }: MultiSelectFilterProps<number>) => (
    <MultiSelect
        label={label}
        placeholder={"All"}
        clearable={true}
        searchable={true}
        options={options?.map((option) => ({
            value: String(option.key),
            label: option.label,
        }))}
        value={value ? Array.from(value).map((num) => String(num)) : []}
        onChange={(nums) => onChange(nums.length === 0 ? undefined : new Set(nums.map((num) => +num)))}
    />
)

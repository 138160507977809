/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TechnicalDataAttributeDataValueObject
 */
export interface TechnicalDataAttributeDataValueObject {
    /**
     * 
     * @type {any}
     * @memberof TechnicalDataAttributeDataValueObject
     */
    value?: any;
    /**
     * 
     * @type {any}
     * @memberof TechnicalDataAttributeDataValueObject
     */
    rawValue?: any;
}

/**
 * Check if a given object implements the TechnicalDataAttributeDataValueObject interface.
 */
export function instanceOfTechnicalDataAttributeDataValueObject(value: object): value is TechnicalDataAttributeDataValueObject {
    return true;
}

export function TechnicalDataAttributeDataValueObjectFromJSON(json: any): TechnicalDataAttributeDataValueObject {
    return TechnicalDataAttributeDataValueObjectFromJSONTyped(json, false);
}

export function TechnicalDataAttributeDataValueObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalDataAttributeDataValueObject {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
        'rawValue': json['rawValue'] == null ? undefined : json['rawValue'],
    };
}

export function TechnicalDataAttributeDataValueObjectToJSON(json: any): TechnicalDataAttributeDataValueObject {
    return TechnicalDataAttributeDataValueObjectToJSONTyped(json, false);
}

export function TechnicalDataAttributeDataValueObjectToJSONTyped(value?: TechnicalDataAttributeDataValueObject | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': value['value'],
        'rawValue': value['rawValue'],
    };
}


import { TableFilterTagsConfig } from "../../../../../../../components/filteredpagedtable/FilteredPagedTable"
import { OrderTypeOptionFilter } from "../../../../../../../pdsapi"
import { OrderTypeOptionFilterOptionsDTO } from "../../../../../../../generated/pdsapi"
import { findGroupedOption, findOption } from "../../../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { filterTagsFor } from "../../../../../../../components/filteredpagedtable/filtertags/filterTagsFor"

export const OrderTypeOptionItemsCardFilterTagsConfig: TableFilterTagsConfig<OrderTypeOptionFilter, OrderTypeOptionFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => [
    ...filterTagsFor(
        filter,
        setFilter,
        "equipmentTagKeys",
        (equipmentTagKey) => findGroupedOption(filterOptions?.categorizedEquipmentTags, equipmentTagKey)?.label ?? equipmentTagKey
    ),
    ...filterTagsFor(
        filter,
        setFilter,
        "interiorColorTagKeys",
        (interiorColorTagKey) => findOption(filterOptions?.interiorColorTags, interiorColorTagKey)?.label ?? interiorColorTagKey
    ),
    ...filterTagsFor(
        filter,
        setFilter,
        "exteriorColorTagKeys",
        (exteriorColorTagKey) => findOption(filterOptions?.exteriorColorTags, exteriorColorTagKey)?.label ?? exteriorColorTagKey
    ),
    ...filterTagsFor(
        filter,
        setFilter,
        "roofColorTagKeys",
        (roofColorTagKey) => findOption(filterOptions?.roofColorTags, roofColorTagKey)?.label ?? roofColorTagKey
    ),
    ...filterTagsFor(
        filter,
        setFilter,
        "vehicleEquipmentCategories",
        (category) => findOption(filterOptions?.vehicleEquipmentCategories, category)?.label ?? category
    ),
    ...filterTagsFor(filter, setFilter, "equipmentFamilies", (family) => family),
    ...filterTagsFor(filter, setFilter, "interiorMaterials", (material) => findOption(filterOptions?.interiorMaterials, material)?.label ?? material),
]

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { OptionCreateRequestDTOCustomTailoring } from './OptionCreateRequestDTOCustomTailoring';
import {
    instanceOfOptionCreateRequestDTOCustomTailoring,
    OptionCreateRequestDTOCustomTailoringFromJSON,
    OptionCreateRequestDTOCustomTailoringFromJSONTyped,
    OptionCreateRequestDTOCustomTailoringToJSON,
} from './OptionCreateRequestDTOCustomTailoring';
import type { OptionCreateRequestDTOExteriorColor } from './OptionCreateRequestDTOExteriorColor';
import {
    instanceOfOptionCreateRequestDTOExteriorColor,
    OptionCreateRequestDTOExteriorColorFromJSON,
    OptionCreateRequestDTOExteriorColorFromJSONTyped,
    OptionCreateRequestDTOExteriorColorToJSON,
} from './OptionCreateRequestDTOExteriorColor';
import type { OptionCreateRequestDTOIndividualEquipment } from './OptionCreateRequestDTOIndividualEquipment';
import {
    instanceOfOptionCreateRequestDTOIndividualEquipment,
    OptionCreateRequestDTOIndividualEquipmentFromJSON,
    OptionCreateRequestDTOIndividualEquipmentFromJSONTyped,
    OptionCreateRequestDTOIndividualEquipmentToJSON,
} from './OptionCreateRequestDTOIndividualEquipment';
import type { OptionCreateRequestDTOInterior } from './OptionCreateRequestDTOInterior';
import {
    instanceOfOptionCreateRequestDTOInterior,
    OptionCreateRequestDTOInteriorFromJSON,
    OptionCreateRequestDTOInteriorFromJSONTyped,
    OptionCreateRequestDTOInteriorToJSON,
} from './OptionCreateRequestDTOInterior';
import type { OptionCreateRequestDTOPaintToSampleExteriorColor } from './OptionCreateRequestDTOPaintToSampleExteriorColor';
import {
    instanceOfOptionCreateRequestDTOPaintToSampleExteriorColor,
    OptionCreateRequestDTOPaintToSampleExteriorColorFromJSON,
    OptionCreateRequestDTOPaintToSampleExteriorColorFromJSONTyped,
    OptionCreateRequestDTOPaintToSampleExteriorColorToJSON,
} from './OptionCreateRequestDTOPaintToSampleExteriorColor';
import type { OptionCreateRequestDTORoofColor } from './OptionCreateRequestDTORoofColor';
import {
    instanceOfOptionCreateRequestDTORoofColor,
    OptionCreateRequestDTORoofColorFromJSON,
    OptionCreateRequestDTORoofColorFromJSONTyped,
    OptionCreateRequestDTORoofColorToJSON,
} from './OptionCreateRequestDTORoofColor';
import type { OptionCreateRequestDTOSalesPackage } from './OptionCreateRequestDTOSalesPackage';
import {
    instanceOfOptionCreateRequestDTOSalesPackage,
    OptionCreateRequestDTOSalesPackageFromJSON,
    OptionCreateRequestDTOSalesPackageFromJSONTyped,
    OptionCreateRequestDTOSalesPackageToJSON,
} from './OptionCreateRequestDTOSalesPackage';
import type { OptionCreateRequestDTOTechnicalPackage } from './OptionCreateRequestDTOTechnicalPackage';
import {
    instanceOfOptionCreateRequestDTOTechnicalPackage,
    OptionCreateRequestDTOTechnicalPackageFromJSON,
    OptionCreateRequestDTOTechnicalPackageFromJSONTyped,
    OptionCreateRequestDTOTechnicalPackageToJSON,
} from './OptionCreateRequestDTOTechnicalPackage';
import type { OptionCreateRequestDTOZOrder } from './OptionCreateRequestDTOZOrder';
import {
    instanceOfOptionCreateRequestDTOZOrder,
    OptionCreateRequestDTOZOrderFromJSON,
    OptionCreateRequestDTOZOrderFromJSONTyped,
    OptionCreateRequestDTOZOrderToJSON,
} from './OptionCreateRequestDTOZOrder';

/**
 * @type OptionCreateRequestDTO
 * 
 * @export
 */
export type OptionCreateRequestDTO = { type: 'CustomTailoring' } & OptionCreateRequestDTOCustomTailoring | { type: 'ExteriorColor' } & OptionCreateRequestDTOExteriorColor | { type: 'IndividualEquipment' } & OptionCreateRequestDTOIndividualEquipment | { type: 'Interior' } & OptionCreateRequestDTOInterior | { type: 'PaintToSampleExteriorColor' } & OptionCreateRequestDTOPaintToSampleExteriorColor | { type: 'RoofColor' } & OptionCreateRequestDTORoofColor | { type: 'SalesPackage' } & OptionCreateRequestDTOSalesPackage | { type: 'TechnicalPackage' } & OptionCreateRequestDTOTechnicalPackage | { type: 'ZOrder' } & OptionCreateRequestDTOZOrder;

export function OptionCreateRequestDTOFromJSON(json: any): OptionCreateRequestDTO {
    return OptionCreateRequestDTOFromJSONTyped(json, false);
}

export function OptionCreateRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCreateRequestDTO {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'CustomTailoring':
            return Object.assign({}, OptionCreateRequestDTOCustomTailoringFromJSONTyped(json, true), { type: 'CustomTailoring' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionCreateRequestDTOExteriorColorFromJSONTyped(json, true), { type: 'ExteriorColor' } as const);
        case 'IndividualEquipment':
            return Object.assign({}, OptionCreateRequestDTOIndividualEquipmentFromJSONTyped(json, true), { type: 'IndividualEquipment' } as const);
        case 'Interior':
            return Object.assign({}, OptionCreateRequestDTOInteriorFromJSONTyped(json, true), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionCreateRequestDTOPaintToSampleExteriorColorFromJSONTyped(json, true), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionCreateRequestDTORoofColorFromJSONTyped(json, true), { type: 'RoofColor' } as const);
        case 'SalesPackage':
            return Object.assign({}, OptionCreateRequestDTOSalesPackageFromJSONTyped(json, true), { type: 'SalesPackage' } as const);
        case 'TechnicalPackage':
            return Object.assign({}, OptionCreateRequestDTOTechnicalPackageFromJSONTyped(json, true), { type: 'TechnicalPackage' } as const);
        case 'ZOrder':
            return Object.assign({}, OptionCreateRequestDTOZOrderFromJSONTyped(json, true), { type: 'ZOrder' } as const);
        default:
            throw new Error(`No variant of OptionCreateRequestDTO exists with 'type=${json['type']}'`);
    }
}

export function OptionCreateRequestDTOToJSON(json: any): any {
    return OptionCreateRequestDTOToJSONTyped(json, false);
}

export function OptionCreateRequestDTOToJSONTyped(value?: OptionCreateRequestDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'CustomTailoring':
            return Object.assign({}, OptionCreateRequestDTOCustomTailoringToJSON(value), { type: 'CustomTailoring' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionCreateRequestDTOExteriorColorToJSON(value), { type: 'ExteriorColor' } as const);
        case 'IndividualEquipment':
            return Object.assign({}, OptionCreateRequestDTOIndividualEquipmentToJSON(value), { type: 'IndividualEquipment' } as const);
        case 'Interior':
            return Object.assign({}, OptionCreateRequestDTOInteriorToJSON(value), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionCreateRequestDTOPaintToSampleExteriorColorToJSON(value), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionCreateRequestDTORoofColorToJSON(value), { type: 'RoofColor' } as const);
        case 'SalesPackage':
            return Object.assign({}, OptionCreateRequestDTOSalesPackageToJSON(value), { type: 'SalesPackage' } as const);
        case 'TechnicalPackage':
            return Object.assign({}, OptionCreateRequestDTOTechnicalPackageToJSON(value), { type: 'TechnicalPackage' } as const);
        case 'ZOrder':
            return Object.assign({}, OptionCreateRequestDTOZOrderToJSON(value), { type: 'ZOrder' } as const);
        default:
            throw new Error(`No variant of OptionCreateRequestDTO exists with 'type=${value['type']}'`);
    }

}


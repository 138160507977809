/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';

/**
 * 
 * @export
 * @interface OptionTagAttributesDTOInteriorColor
 */
export interface OptionTagAttributesDTOInteriorColor {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionTagAttributesDTOInteriorColor
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof OptionTagAttributesDTOInteriorColor
     */
    key: string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionTagAttributesDTOInteriorColor
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof OptionTagAttributesDTOInteriorColor
     */
    hexCode: string;
}

/**
 * Check if a given object implements the OptionTagAttributesDTOInteriorColor interface.
 */
export function instanceOfOptionTagAttributesDTOInteriorColor(value: object): value is OptionTagAttributesDTOInteriorColor {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('hexCode' in value) || value['hexCode'] === undefined) return false;
    return true;
}

export function OptionTagAttributesDTOInteriorColorFromJSON(json: any): OptionTagAttributesDTOInteriorColor {
    return OptionTagAttributesDTOInteriorColorFromJSONTyped(json, false);
}

export function OptionTagAttributesDTOInteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagAttributesDTOInteriorColor {
    if (json == null) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'key': json['key'],
        'active': json['active'],
        'hexCode': json['hexCode'],
    };
}

export function OptionTagAttributesDTOInteriorColorToJSON(json: any): OptionTagAttributesDTOInteriorColor {
    return OptionTagAttributesDTOInteriorColorToJSONTyped(json, false);
}

export function OptionTagAttributesDTOInteriorColorToJSONTyped(value?: OptionTagAttributesDTOInteriorColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': BaseNameDTOToJSON(value['name']),
        'key': value['key'],
        'active': value['active'],
        'hexCode': value['hexCode'],
    };
}


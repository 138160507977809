/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DerivativeCategoryCreateOptionsDTO,
  DerivativeCategoryCreateRequestDTO,
  KeyDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    DerivativeCategoryCreateOptionsDTOFromJSON,
    DerivativeCategoryCreateOptionsDTOToJSON,
    DerivativeCategoryCreateRequestDTOFromJSON,
    DerivativeCategoryCreateRequestDTOToJSON,
    KeyDTOFromJSON,
    KeyDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface CreateDerivativeCategoryRequest {
    derivativeCategoryCreateRequestDTO: DerivativeCategoryCreateRequestDTO;
}

export interface GetDerivativeCategoryCreateOptionsRequest {
    languageTag: string;
}

/**
 * 
 */
export class DerivativeCategoryCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new DerivativeCategory
     */
    async createDerivativeCategoryRaw(requestParameters: CreateDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KeyDTO>> {
        if (requestParameters['derivativeCategoryCreateRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'derivativeCategoryCreateRequestDTO',
                'Required parameter "derivativeCategoryCreateRequestDTO" was null or undefined when calling createDerivativeCategory().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/derivative-categories/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DerivativeCategoryCreateRequestDTOToJSON(requestParameters['derivativeCategoryCreateRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KeyDTOFromJSON(jsonValue));
    }

    /**
     * Create a new DerivativeCategory
     */
    async createDerivativeCategory(requestParameters: CreateDerivativeCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KeyDTO> {
        const response = await this.createDerivativeCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get select options to create DerivativeCategories
     */
    async getDerivativeCategoryCreateOptionsRaw(requestParameters: GetDerivativeCategoryCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DerivativeCategoryCreateOptionsDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getDerivativeCategoryCreateOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivative-categories/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DerivativeCategoryCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create DerivativeCategories
     */
    async getDerivativeCategoryCreateOptions(requestParameters: GetDerivativeCategoryCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DerivativeCategoryCreateOptionsDTO> {
        const response = await this.getDerivativeCategoryCreateOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RelatedOptionDTOStringString
 */
export interface RelatedOptionDTOStringString {
    /**
     * 
     * @type {string}
     * @memberof RelatedOptionDTOStringString
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedOptionDTOStringString
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedOptionDTOStringString
     */
    relatedKey: string;
}

/**
 * Check if a given object implements the RelatedOptionDTOStringString interface.
 */
export function instanceOfRelatedOptionDTOStringString(value: object): value is RelatedOptionDTOStringString {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('relatedKey' in value) || value['relatedKey'] === undefined) return false;
    return true;
}

export function RelatedOptionDTOStringStringFromJSON(json: any): RelatedOptionDTOStringString {
    return RelatedOptionDTOStringStringFromJSONTyped(json, false);
}

export function RelatedOptionDTOStringStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedOptionDTOStringString {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'label': json['label'],
        'relatedKey': json['relatedKey'],
    };
}

export function RelatedOptionDTOStringStringToJSON(json: any): RelatedOptionDTOStringString {
    return RelatedOptionDTOStringStringToJSONTyped(json, false);
}

export function RelatedOptionDTOStringStringToJSONTyped(value?: RelatedOptionDTOStringString | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'label': value['label'],
        'relatedKey': value['relatedKey'],
    };
}


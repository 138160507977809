/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment
 */
export interface OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment {
    /**
     * 
     * @type {Date}
     * @memberof OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment
     */
    from?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment
     */
    to?: Date;
}

/**
 * Check if a given object implements the OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment interface.
 */
export function instanceOfOptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment(value: object): value is OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment {
    return true;
}

export function OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentFromJSON(json: any): OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment {
    return OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentFromJSONTyped(json, false);
}

export function OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment {
    if (json == null) {
        return json;
    }
    return {
        
        'from': json['from'] == null ? undefined : (new Date(json['from'])),
        'to': json['to'] == null ? undefined : (new Date(json['to'])),
    };
}

export function OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentToJSON(json: any): OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment {
    return OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentToJSONTyped(json, false);
}

export function OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentToJSONTyped(value?: OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'from': value['from'] == null ? undefined : ((value['from']).toISOString().substring(0,10)),
        'to': value['to'] == null ? undefined : ((value['to']).toISOString().substring(0,10)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ColorItemDTO
 */
export interface ColorItemDTO {
    /**
     * 
     * @type {string}
     * @memberof ColorItemDTO
     */
    colorCode: string;
    /**
     * 
     * @type {string}
     * @memberof ColorItemDTO
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ColorItemDTO
     */
    hexCodes: Array<string>;
}

/**
 * Check if a given object implements the ColorItemDTO interface.
 */
export function instanceOfColorItemDTO(value: object): value is ColorItemDTO {
    if (!('colorCode' in value) || value['colorCode'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('hexCodes' in value) || value['hexCodes'] === undefined) return false;
    return true;
}

export function ColorItemDTOFromJSON(json: any): ColorItemDTO {
    return ColorItemDTOFromJSONTyped(json, false);
}

export function ColorItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColorItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'colorCode': json['colorCode'],
        'name': json['name'],
        'hexCodes': json['hexCodes'],
    };
}

export function ColorItemDTOToJSON(json: any): ColorItemDTO {
    return ColorItemDTOToJSONTyped(json, false);
}

export function ColorItemDTOToJSONTyped(value?: ColorItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'colorCode': value['colorCode'],
        'name': value['name'],
        'hexCodes': value['hexCodes'],
    };
}


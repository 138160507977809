/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FuelType = {
    DIESEL: 'DIESEL',
    REGULAR_PETROL: 'REGULAR_PETROL',
    PREMIUM_PETROL: 'PREMIUM_PETROL'
} as const;
export type FuelType = typeof FuelType[keyof typeof FuelType];


export function instanceOfFuelType(value: any): boolean {
    for (const key in FuelType) {
        if (Object.prototype.hasOwnProperty.call(FuelType, key)) {
            if (FuelType[key as keyof typeof FuelType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FuelTypeFromJSON(json: any): FuelType {
    return FuelTypeFromJSONTyped(json, false);
}

export function FuelTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FuelType {
    return json as FuelType;
}

export function FuelTypeToJSON(value?: FuelType | null): any {
    return value as any;
}

export function FuelTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): FuelType {
    return value as FuelType;
}


import { propertiesOf } from "../../../../../../common/property"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { ColorAttributesDTO, ColorAttributesUpdateDTO, ColorKeyDTO } from "../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { FC } from "react"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { getMultiTextFormElement } from "../../../../../../components/formelements/multitextformelement/MultiTextFormElement"
import { ReadonlyFormElementWrapper } from "../../../../../../components/formelements/ReadonlyFormElementWrapper"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { getTagFormTextElement } from "../../../../../../components/formelements/tagformtextelement/TagFormTextElement"
import { routes } from "../../../../../../common/routes"

export const ColorDetailsAttributesCardContent: FC<ProductDetailsCardContentProps<ColorAttributesDTO, ColorAttributesUpdateDTO, ColorKeyDTO>> = ({
    formDisplayData,
}) => {
    const properties = propertiesOf<ColorAttributesUpdateDTO>()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <ReadonlyFormElementWrapper
                    label={"Color Code"}
                    formDisplayData={formDisplayData}
                    FormElement={getTextFormElement<ColorAttributesDTO, ColorAttributesUpdateDTO>()}
                    getViewProps={(color) => ({
                        value: color.colorCode,
                    })}
                />
                <FormElementWrapper
                    label={"Brush Code"}
                    formDisplayData={formDisplayData}
                    field={properties.brushCode}
                    FormElement={getTagFormTextElement<ColorAttributesDTO, ColorAttributesUpdateDTO>()}
                    getViewProps={(color) => ({
                        tag: color.brushCode
                            ? {
                                  label: color.brushCode,
                                  route: routes.customColors.exterior.details(color.brushCode),
                              }
                            : undefined,
                    })}
                    getEditProps={(color) => ({
                        value: color.brushCode ?? "",
                        optional: true,
                    })}
                />
                <FormElementWrapper
                    label={"Order Text"}
                    formDisplayData={formDisplayData}
                    field={properties.orderText}
                    FormElement={getTextFormElement<ColorAttributesDTO, ColorAttributesUpdateDTO>()}
                    getViewProps={(color) => ({
                        value: color.orderText ?? "",
                    })}
                    getEditProps={(color) => ({
                        value: color.orderText ?? "",
                        optional: true,
                    })}
                />
                <FormElementWrapper
                    label={"Hex Codes"}
                    formDisplayData={formDisplayData}
                    field={properties.hexCodes}
                    FormElement={getMultiTextFormElement<ColorAttributesDTO, ColorAttributesUpdateDTO>()}
                    getViewProps={(color) => ({
                        value: color.hexCodes,
                    })}
                    getEditProps={(color) => ({
                        value: color.hexCodes,
                    })}
                />
            </RowGroupWrapper>
            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getViewProps={(color) => ({
                    value: color.name,
                })}
                getEditProps={(color) => ({
                    value: color.name,
                })}
            />
            <DetailsDefaultLocalizationsFormRow
                label={"Description"}
                formDisplayData={formDisplayData}
                field={properties.description}
                getViewProps={(color) => ({
                    value: color.description,
                })}
                getEditProps={(color) => ({
                    value: color.description,
                    optional: true,
                })}
            />
        </SpacedItems>
    )
}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface SelectDTOString
 */
export interface SelectDTOString {
    /**
     * 
     * @type {SelectOptionDTOString}
     * @memberof SelectDTOString
     */
    value: SelectOptionDTOString;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof SelectDTOString
     */
    options: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the SelectDTOString interface.
 */
export function instanceOfSelectDTOString(value: object): value is SelectDTOString {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function SelectDTOStringFromJSON(json: any): SelectDTOString {
    return SelectDTOStringFromJSONTyped(json, false);
}

export function SelectDTOStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectDTOString {
    if (json == null) {
        return json;
    }
    return {
        
        'value': SelectOptionDTOStringFromJSON(json['value']),
        'options': ((json['options'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function SelectDTOStringToJSON(json: any): SelectDTOString {
    return SelectDTOStringToJSONTyped(json, false);
}

export function SelectDTOStringToJSONTyped(value?: SelectDTOString | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': SelectOptionDTOStringToJSON(value['value']),
        'options': ((value['options'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


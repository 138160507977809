/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UserAuthority = {
    VIEW_DATA: 'VIEW_DATA',
    VIEW_UNPUBLISHED_DATA: 'VIEW_UNPUBLISHED_DATA',
    MAINTAIN_DATA: 'MAINTAIN_DATA',
    ADMINISTRATE_SERVICE: 'ADMINISTRATE_SERVICE'
} as const;
export type UserAuthority = typeof UserAuthority[keyof typeof UserAuthority];


export function instanceOfUserAuthority(value: any): boolean {
    for (const key in UserAuthority) {
        if (Object.prototype.hasOwnProperty.call(UserAuthority, key)) {
            if (UserAuthority[key as keyof typeof UserAuthority] === value) {
                return true;
            }
        }
    }
    return false;
}

export function UserAuthorityFromJSON(json: any): UserAuthority {
    return UserAuthorityFromJSONTyped(json, false);
}

export function UserAuthorityFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAuthority {
    return json as UserAuthority;
}

export function UserAuthorityToJSON(value?: UserAuthority | null): any {
    return value as any;
}

export function UserAuthorityToJSONTyped(value: any, ignoreDiscriminator: boolean): UserAuthority {
    return value as UserAuthority;
}


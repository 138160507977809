/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InteriorColorTileDTO
 */
export interface InteriorColorTileDTO {
    /**
     * 
     * @type {string}
     * @memberof InteriorColorTileDTO
     */
    seatColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorColorTileDTO
     */
    seatStitchingColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorColorTileDTO
     */
    cockpitColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorColorTileDTO
     */
    cockpitStitchingColorHexCode: string;
}

/**
 * Check if a given object implements the InteriorColorTileDTO interface.
 */
export function instanceOfInteriorColorTileDTO(value: object): value is InteriorColorTileDTO {
    if (!('seatColorHexCode' in value) || value['seatColorHexCode'] === undefined) return false;
    if (!('seatStitchingColorHexCode' in value) || value['seatStitchingColorHexCode'] === undefined) return false;
    if (!('cockpitColorHexCode' in value) || value['cockpitColorHexCode'] === undefined) return false;
    if (!('cockpitStitchingColorHexCode' in value) || value['cockpitStitchingColorHexCode'] === undefined) return false;
    return true;
}

export function InteriorColorTileDTOFromJSON(json: any): InteriorColorTileDTO {
    return InteriorColorTileDTOFromJSONTyped(json, false);
}

export function InteriorColorTileDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorColorTileDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'seatColorHexCode': json['seatColorHexCode'],
        'seatStitchingColorHexCode': json['seatStitchingColorHexCode'],
        'cockpitColorHexCode': json['cockpitColorHexCode'],
        'cockpitStitchingColorHexCode': json['cockpitStitchingColorHexCode'],
    };
}

export function InteriorColorTileDTOToJSON(json: any): InteriorColorTileDTO {
    return InteriorColorTileDTOToJSONTyped(json, false);
}

export function InteriorColorTileDTOToJSONTyped(value?: InteriorColorTileDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'seatColorHexCode': value['seatColorHexCode'],
        'seatStitchingColorHexCode': value['seatStitchingColorHexCode'],
        'cockpitColorHexCode': value['cockpitColorHexCode'],
        'cockpitStitchingColorHexCode': value['cockpitStitchingColorHexCode'],
    };
}


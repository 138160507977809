/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ModelGenerationItemDTO } from './ModelGenerationItemDTO';
import {
    ModelGenerationItemDTOFromJSON,
    ModelGenerationItemDTOFromJSONTyped,
    ModelGenerationItemDTOToJSON,
    ModelGenerationItemDTOToJSONTyped,
} from './ModelGenerationItemDTO';

/**
 * 
 * @export
 * @interface PageDTOModelGenerationItemDTO
 */
export interface PageDTOModelGenerationItemDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTOModelGenerationItemDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<ModelGenerationItemDTO>}
     * @memberof PageDTOModelGenerationItemDTO
     */
    content: Array<ModelGenerationItemDTO>;
}

/**
 * Check if a given object implements the PageDTOModelGenerationItemDTO interface.
 */
export function instanceOfPageDTOModelGenerationItemDTO(value: object): value is PageDTOModelGenerationItemDTO {
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function PageDTOModelGenerationItemDTOFromJSON(json: any): PageDTOModelGenerationItemDTO {
    return PageDTOModelGenerationItemDTOFromJSONTyped(json, false);
}

export function PageDTOModelGenerationItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOModelGenerationItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(ModelGenerationItemDTOFromJSON)),
    };
}

export function PageDTOModelGenerationItemDTOToJSON(json: any): PageDTOModelGenerationItemDTO {
    return PageDTOModelGenerationItemDTOToJSONTyped(json, false);
}

export function PageDTOModelGenerationItemDTOToJSONTyped(value?: PageDTOModelGenerationItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'totalItemCount': value['totalItemCount'],
        'content': ((value['content'] as Array<any>).map(ModelGenerationItemDTOToJSON)),
    };
}


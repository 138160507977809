/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LinkedCustomizationDTOCustomization } from './LinkedCustomizationDTOCustomization';
import {
    LinkedCustomizationDTOCustomizationFromJSON,
    LinkedCustomizationDTOCustomizationFromJSONTyped,
    LinkedCustomizationDTOCustomizationToJSON,
    LinkedCustomizationDTOCustomizationToJSONTyped,
} from './LinkedCustomizationDTOCustomization';

/**
 * 
 * @export
 * @interface LinkedCustomizationDTO
 */
export interface LinkedCustomizationDTO {
    /**
     * 
     * @type {string}
     * @memberof LinkedCustomizationDTO
     */
    modelGenerationKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LinkedCustomizationDTO
     */
    hasSimilarCustomizations: boolean;
    /**
     * 
     * @type {LinkedCustomizationDTOCustomization}
     * @memberof LinkedCustomizationDTO
     */
    customization?: LinkedCustomizationDTOCustomization;
}

/**
 * Check if a given object implements the LinkedCustomizationDTO interface.
 */
export function instanceOfLinkedCustomizationDTO(value: object): value is LinkedCustomizationDTO {
    if (!('hasSimilarCustomizations' in value) || value['hasSimilarCustomizations'] === undefined) return false;
    return true;
}

export function LinkedCustomizationDTOFromJSON(json: any): LinkedCustomizationDTO {
    return LinkedCustomizationDTOFromJSONTyped(json, false);
}

export function LinkedCustomizationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedCustomizationDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'modelGenerationKey': json['modelGenerationKey'] == null ? undefined : json['modelGenerationKey'],
        'hasSimilarCustomizations': json['hasSimilarCustomizations'],
        'customization': json['customization'] == null ? undefined : LinkedCustomizationDTOCustomizationFromJSON(json['customization']),
    };
}

export function LinkedCustomizationDTOToJSON(json: any): LinkedCustomizationDTO {
    return LinkedCustomizationDTOToJSONTyped(json, false);
}

export function LinkedCustomizationDTOToJSONTyped(value?: LinkedCustomizationDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'modelGenerationKey': value['modelGenerationKey'],
        'hasSimilarCustomizations': value['hasSimilarCustomizations'],
        'customization': LinkedCustomizationDTOCustomizationToJSON(value['customization']),
    };
}


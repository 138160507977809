import { TableFilterTagsConfig } from "../../../../components/filteredpagedtable/FilteredPagedTable"
import { OptionFilterOptionsDTO } from "../../../../generated/pdsapi"
import { OptionFilter } from "../../../../pdsapi"
import { findGroupedOption, findOption } from "../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { filterTagsFor } from "../../../../components/filteredpagedtable/filtertags/filterTagsFor"

export const OptionCatalogueFilterTagsConfig: TableFilterTagsConfig<OptionFilter, OptionFilterOptionsDTO> = ({ filterOptions, filter, setFilter }) => [
    ...filterTagsFor(
        filter,
        setFilter,
        "modelGenerationKeys",
        (modelGenerationKey) => findOption(filterOptions?.modelGenerations, modelGenerationKey)?.label ?? modelGenerationKey
    ),
    ...filterTagsFor(filter, setFilter, "modelYears", (modelYear) => modelYear.toString()),
    ...filterTagsFor(
        filter,
        setFilter,
        "orderTypeKeys",
        (orderTypeKey) => findGroupedOption(filterOptions?.modelYearAndGenerationRelatedOrderTypes, orderTypeKey)?.label ?? orderTypeKey
    ),
    ...filterTagsFor(filter, setFilter, "classes", (clazz) => findOption(filterOptions?.classes, clazz)?.label ?? clazz),
    ...filterTagsFor(
        filter,
        setFilter,
        "equipmentTagKeys",
        (equipmentTagKey) => findGroupedOption(filterOptions?.categorizedEquipmentTags, equipmentTagKey)?.label ?? equipmentTagKey
    ),
    ...filterTagsFor(
        filter,
        setFilter,
        "interiorColorTagKeys",
        (interiorColorTagKey) => findOption(filterOptions?.interiorColorTags, interiorColorTagKey)?.label ?? interiorColorTagKey
    ),
    ...filterTagsFor(
        filter,
        setFilter,
        "exteriorColorTagKeys",
        (exteriorColorTagKey) => findOption(filterOptions?.exteriorColorTags, exteriorColorTagKey)?.label ?? exteriorColorTagKey
    ),
    ...filterTagsFor(
        filter,
        setFilter,
        "roofColorTagKeys",
        (roofColorTagKey) => findOption(filterOptions?.roofColorTags, roofColorTagKey)?.label ?? roofColorTagKey
    ),
    ...filterTagsFor(
        filter,
        setFilter,
        "vehicleEquipmentCategories",
        (vehicleEquipmentCategory) => findOption(filterOptions?.vehicleEquipmentCategories, vehicleEquipmentCategory)?.label ?? vehicleEquipmentCategory
    ),
    ...filterTagsFor(filter, setFilter, "equipmentFamilies", (equipmentFamily) => equipmentFamily),
    ...filterTagsFor(
        filter,
        setFilter,
        "interiorMaterials",
        (interiorMaterial) => findOption(filterOptions?.interiorMaterials, interiorMaterial)?.label ?? interiorMaterial
    ),
]

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocalizationsItemDTO
 */
export interface LocalizationsItemDTO {
    /**
     * 
     * @type {string}
     * @memberof LocalizationsItemDTO
     */
    raw?: string;
    /**
     * 
     * @type {string}
     * @memberof LocalizationsItemDTO
     */
    withFallback?: string;
}

/**
 * Check if a given object implements the LocalizationsItemDTO interface.
 */
export function instanceOfLocalizationsItemDTO(value: object): value is LocalizationsItemDTO {
    return true;
}

export function LocalizationsItemDTOFromJSON(json: any): LocalizationsItemDTO {
    return LocalizationsItemDTOFromJSONTyped(json, false);
}

export function LocalizationsItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalizationsItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'raw': json['raw'] == null ? undefined : json['raw'],
        'withFallback': json['withFallback'] == null ? undefined : json['withFallback'],
    };
}

export function LocalizationsItemDTOToJSON(json: any): LocalizationsItemDTO {
    return LocalizationsItemDTOToJSONTyped(json, false);
}

export function LocalizationsItemDTOToJSONTyped(value?: LocalizationsItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'raw': value['raw'],
        'withFallback': value['withFallback'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationAttributesDTOExteriorColorValue } from './OptionCustomizationAttributesDTOExteriorColorValue';
import {
    OptionCustomizationAttributesDTOExteriorColorValueFromJSON,
    OptionCustomizationAttributesDTOExteriorColorValueFromJSONTyped,
    OptionCustomizationAttributesDTOExteriorColorValueToJSON,
    OptionCustomizationAttributesDTOExteriorColorValueToJSONTyped,
} from './OptionCustomizationAttributesDTOExteriorColorValue';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';
import type { MultiSelectDTOString } from './MultiSelectDTOString';
import {
    MultiSelectDTOStringFromJSON,
    MultiSelectDTOStringFromJSONTyped,
    MultiSelectDTOStringToJSON,
    MultiSelectDTOStringToJSONTyped,
} from './MultiSelectDTOString';
import type { OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue } from './OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue';
import {
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueFromJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueFromJSONTyped,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueToJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueToJSONTyped,
} from './OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesDTOExteriorColor
 */
export interface OptionCustomizationAttributesDTOExteriorColor {
    /**
     * 
     * @type {OptionCustomizationAttributesDTOExteriorColorValue}
     * @memberof OptionCustomizationAttributesDTOExteriorColor
     */
    defaultValue: OptionCustomizationAttributesDTOExteriorColorValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOExteriorColor
     */
    optionCode: string;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue>}
     * @memberof OptionCustomizationAttributesDTOExteriorColor
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValue>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesDTOExteriorColor
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {MultiSelectDTOString}
     * @memberof OptionCustomizationAttributesDTOExteriorColor
     */
    modelGenerations: MultiSelectDTOString;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationAttributesDTOExteriorColor
     */
    derivatives: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the OptionCustomizationAttributesDTOExteriorColor interface.
 */
export function instanceOfOptionCustomizationAttributesDTOExteriorColor(value: object): value is OptionCustomizationAttributesDTOExteriorColor {
    if (!('defaultValue' in value) || value['defaultValue'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('conditionalValues' in value) || value['conditionalValues'] === undefined) return false;
    if (!('isDefaultValueActive' in value) || value['isDefaultValueActive'] === undefined) return false;
    if (!('modelGenerations' in value) || value['modelGenerations'] === undefined) return false;
    if (!('derivatives' in value) || value['derivatives'] === undefined) return false;
    return true;
}

export function OptionCustomizationAttributesDTOExteriorColorFromJSON(json: any): OptionCustomizationAttributesDTOExteriorColor {
    return OptionCustomizationAttributesDTOExteriorColorFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOExteriorColor {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesDTOExteriorColorValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'modelGenerations': MultiSelectDTOStringFromJSON(json['modelGenerations']),
        'derivatives': ((json['derivatives'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function OptionCustomizationAttributesDTOExteriorColorToJSON(json: any): OptionCustomizationAttributesDTOExteriorColor {
    return OptionCustomizationAttributesDTOExteriorColorToJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOExteriorColorToJSONTyped(value?: OptionCustomizationAttributesDTOExteriorColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultValue': OptionCustomizationAttributesDTOExteriorColorValueToJSON(value['defaultValue']),
        'optionCode': value['optionCode'],
        'conditionalValues': ((value['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOExteriorColorValueToJSON)),
        'isDefaultValueActive': value['isDefaultValueActive'],
        'modelGenerations': MultiSelectDTOStringToJSON(value['modelGenerations']),
        'derivatives': ((value['derivatives'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VideoDTO } from './VideoDTO';
import {
    VideoDTOFromJSON,
    VideoDTOFromJSONTyped,
    VideoDTOToJSON,
    VideoDTOToJSONTyped,
} from './VideoDTO';

/**
 * 
 * @export
 * @interface VideoLocalizationsItemDTO
 */
export interface VideoLocalizationsItemDTO {
    /**
     * 
     * @type {VideoDTO}
     * @memberof VideoLocalizationsItemDTO
     */
    raw?: VideoDTO;
    /**
     * 
     * @type {VideoDTO}
     * @memberof VideoLocalizationsItemDTO
     */
    withFallback?: VideoDTO;
}

/**
 * Check if a given object implements the VideoLocalizationsItemDTO interface.
 */
export function instanceOfVideoLocalizationsItemDTO(value: object): value is VideoLocalizationsItemDTO {
    return true;
}

export function VideoLocalizationsItemDTOFromJSON(json: any): VideoLocalizationsItemDTO {
    return VideoLocalizationsItemDTOFromJSONTyped(json, false);
}

export function VideoLocalizationsItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VideoLocalizationsItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'raw': json['raw'] == null ? undefined : VideoDTOFromJSON(json['raw']),
        'withFallback': json['withFallback'] == null ? undefined : VideoDTOFromJSON(json['withFallback']),
    };
}

export function VideoLocalizationsItemDTOToJSON(json: any): VideoLocalizationsItemDTO {
    return VideoLocalizationsItemDTOToJSONTyped(json, false);
}

export function VideoLocalizationsItemDTOToJSONTyped(value?: VideoLocalizationsItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'raw': VideoDTOToJSON(value['raw']),
        'withFallback': VideoDTOToJSON(value['withFallback']),
    };
}


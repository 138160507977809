/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ColorTileDTO } from './ColorTileDTO';
import {
    ColorTileDTOFromJSON,
    ColorTileDTOFromJSONTyped,
    ColorTileDTOToJSON,
    ColorTileDTOToJSONTyped,
} from './ColorTileDTO';

/**
 * 
 * @export
 * @interface OrderTypeOptionItemDTOExteriorColor
 */
export interface OrderTypeOptionItemDTOExteriorColor {
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTOExteriorColor
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTOExteriorColor
     */
    optionCode: string;
    /**
     * 
     * @type {number}
     * @memberof OrderTypeOptionItemDTOExteriorColor
     */
    sortIndex: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTOExteriorColor
     */
    imageKey?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTOExteriorColor
     */
    colorTypeName?: string;
    /**
     * 
     * @type {ColorTileDTO}
     * @memberof OrderTypeOptionItemDTOExteriorColor
     */
    color?: ColorTileDTO;
    /**
     * 
     * @type {ColorTileDTO}
     * @memberof OrderTypeOptionItemDTOExteriorColor
     */
    tag?: ColorTileDTO;
}

/**
 * Check if a given object implements the OrderTypeOptionItemDTOExteriorColor interface.
 */
export function instanceOfOrderTypeOptionItemDTOExteriorColor(value: object): value is OrderTypeOptionItemDTOExteriorColor {
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('sortIndex' in value) || value['sortIndex'] === undefined) return false;
    return true;
}

export function OrderTypeOptionItemDTOExteriorColorFromJSON(json: any): OrderTypeOptionItemDTOExteriorColor {
    return OrderTypeOptionItemDTOExteriorColorFromJSONTyped(json, false);
}

export function OrderTypeOptionItemDTOExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeOptionItemDTOExteriorColor {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'optionCode': json['optionCode'],
        'sortIndex': json['sortIndex'],
        'imageKey': json['imageKey'] == null ? undefined : json['imageKey'],
        'colorTypeName': json['colorTypeName'] == null ? undefined : json['colorTypeName'],
        'color': json['color'] == null ? undefined : ColorTileDTOFromJSON(json['color']),
        'tag': json['tag'] == null ? undefined : ColorTileDTOFromJSON(json['tag']),
    };
}

export function OrderTypeOptionItemDTOExteriorColorToJSON(json: any): OrderTypeOptionItemDTOExteriorColor {
    return OrderTypeOptionItemDTOExteriorColorToJSONTyped(json, false);
}

export function OrderTypeOptionItemDTOExteriorColorToJSONTyped(value?: OrderTypeOptionItemDTOExteriorColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'optionCode': value['optionCode'],
        'sortIndex': value['sortIndex'],
        'imageKey': value['imageKey'],
        'colorTypeName': value['colorTypeName'],
        'color': ColorTileDTOToJSON(value['color']),
        'tag': ColorTileDTOToJSON(value['tag']),
    };
}


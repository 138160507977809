/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { OptionCustomizationCreateRequestDTOEquipment } from './OptionCustomizationCreateRequestDTOEquipment';
import {
    instanceOfOptionCustomizationCreateRequestDTOEquipment,
    OptionCustomizationCreateRequestDTOEquipmentFromJSON,
    OptionCustomizationCreateRequestDTOEquipmentFromJSONTyped,
    OptionCustomizationCreateRequestDTOEquipmentToJSON,
} from './OptionCustomizationCreateRequestDTOEquipment';
import type { OptionCustomizationCreateRequestDTOExteriorColor } from './OptionCustomizationCreateRequestDTOExteriorColor';
import {
    instanceOfOptionCustomizationCreateRequestDTOExteriorColor,
    OptionCustomizationCreateRequestDTOExteriorColorFromJSON,
    OptionCustomizationCreateRequestDTOExteriorColorFromJSONTyped,
    OptionCustomizationCreateRequestDTOExteriorColorToJSON,
} from './OptionCustomizationCreateRequestDTOExteriorColor';
import type { OptionCustomizationCreateRequestDTOInterior } from './OptionCustomizationCreateRequestDTOInterior';
import {
    instanceOfOptionCustomizationCreateRequestDTOInterior,
    OptionCustomizationCreateRequestDTOInteriorFromJSON,
    OptionCustomizationCreateRequestDTOInteriorFromJSONTyped,
    OptionCustomizationCreateRequestDTOInteriorToJSON,
} from './OptionCustomizationCreateRequestDTOInterior';
import type { OptionCustomizationCreateRequestDTOPaintToSampleExteriorColor } from './OptionCustomizationCreateRequestDTOPaintToSampleExteriorColor';
import {
    instanceOfOptionCustomizationCreateRequestDTOPaintToSampleExteriorColor,
    OptionCustomizationCreateRequestDTOPaintToSampleExteriorColorFromJSON,
    OptionCustomizationCreateRequestDTOPaintToSampleExteriorColorFromJSONTyped,
    OptionCustomizationCreateRequestDTOPaintToSampleExteriorColorToJSON,
} from './OptionCustomizationCreateRequestDTOPaintToSampleExteriorColor';
import type { OptionCustomizationCreateRequestDTORoofColor } from './OptionCustomizationCreateRequestDTORoofColor';
import {
    instanceOfOptionCustomizationCreateRequestDTORoofColor,
    OptionCustomizationCreateRequestDTORoofColorFromJSON,
    OptionCustomizationCreateRequestDTORoofColorFromJSONTyped,
    OptionCustomizationCreateRequestDTORoofColorToJSON,
} from './OptionCustomizationCreateRequestDTORoofColor';

/**
 * @type OptionCustomizationCreateRequestDTO
 * 
 * @export
 */
export type OptionCustomizationCreateRequestDTO = { type: 'Equipment' } & OptionCustomizationCreateRequestDTOEquipment | { type: 'ExteriorColor' } & OptionCustomizationCreateRequestDTOExteriorColor | { type: 'Interior' } & OptionCustomizationCreateRequestDTOInterior | { type: 'PaintToSampleExteriorColor' } & OptionCustomizationCreateRequestDTOPaintToSampleExteriorColor | { type: 'RoofColor' } & OptionCustomizationCreateRequestDTORoofColor;

export function OptionCustomizationCreateRequestDTOFromJSON(json: any): OptionCustomizationCreateRequestDTO {
    return OptionCustomizationCreateRequestDTOFromJSONTyped(json, false);
}

export function OptionCustomizationCreateRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationCreateRequestDTO {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return Object.assign({}, OptionCustomizationCreateRequestDTOEquipmentFromJSONTyped(json, true), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionCustomizationCreateRequestDTOExteriorColorFromJSONTyped(json, true), { type: 'ExteriorColor' } as const);
        case 'Interior':
            return Object.assign({}, OptionCustomizationCreateRequestDTOInteriorFromJSONTyped(json, true), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionCustomizationCreateRequestDTOPaintToSampleExteriorColorFromJSONTyped(json, true), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionCustomizationCreateRequestDTORoofColorFromJSONTyped(json, true), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionCustomizationCreateRequestDTO exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationCreateRequestDTOToJSON(json: any): any {
    return OptionCustomizationCreateRequestDTOToJSONTyped(json, false);
}

export function OptionCustomizationCreateRequestDTOToJSONTyped(value?: OptionCustomizationCreateRequestDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'Equipment':
            return Object.assign({}, OptionCustomizationCreateRequestDTOEquipmentToJSON(value), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionCustomizationCreateRequestDTOExteriorColorToJSON(value), { type: 'ExteriorColor' } as const);
        case 'Interior':
            return Object.assign({}, OptionCustomizationCreateRequestDTOInteriorToJSON(value), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionCustomizationCreateRequestDTOPaintToSampleExteriorColorToJSON(value), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionCustomizationCreateRequestDTORoofColorToJSON(value), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionCustomizationCreateRequestDTO exists with 'type=${value['type']}'`);
    }

}


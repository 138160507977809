/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VehicleEquipmentCategory } from './VehicleEquipmentCategory';
import {
    VehicleEquipmentCategoryFromJSON,
    VehicleEquipmentCategoryFromJSONTyped,
    VehicleEquipmentCategoryToJSON,
    VehicleEquipmentCategoryToJSONTyped,
} from './VehicleEquipmentCategory';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';

/**
 * 
 * @export
 * @interface OptionCreateRequestDTOCustomTailoring
 */
export interface OptionCreateRequestDTOCustomTailoring {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCreateRequestDTOCustomTailoring
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof OptionCreateRequestDTOCustomTailoring
     */
    orderTypeKey: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCreateRequestDTOCustomTailoring
     */
    optionCode: string;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCreateRequestDTOCustomTailoring
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {number}
     * @memberof OptionCreateRequestDTOCustomTailoring
     */
    sortIndex?: number;
    /**
     * 
     * @type {VehicleEquipmentCategory}
     * @memberof OptionCreateRequestDTOCustomTailoring
     */
    category?: VehicleEquipmentCategory;
    /**
     * 
     * @type {string}
     * @memberof OptionCreateRequestDTOCustomTailoring
     */
    family?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCreateRequestDTOCustomTailoring
     */
    availableColorCodes: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCreateRequestDTOCustomTailoring
     */
    twikit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCreateRequestDTOCustomTailoring
     */
    lena: boolean;
}



/**
 * Check if a given object implements the OptionCreateRequestDTOCustomTailoring interface.
 */
export function instanceOfOptionCreateRequestDTOCustomTailoring(value: object): value is OptionCreateRequestDTOCustomTailoring {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('orderTypeKey' in value) || value['orderTypeKey'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('availableColorCodes' in value) || value['availableColorCodes'] === undefined) return false;
    if (!('twikit' in value) || value['twikit'] === undefined) return false;
    if (!('lena' in value) || value['lena'] === undefined) return false;
    return true;
}

export function OptionCreateRequestDTOCustomTailoringFromJSON(json: any): OptionCreateRequestDTOCustomTailoring {
    return OptionCreateRequestDTOCustomTailoringFromJSONTyped(json, false);
}

export function OptionCreateRequestDTOCustomTailoringFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCreateRequestDTOCustomTailoring {
    if (json == null) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'orderTypeKey': json['orderTypeKey'],
        'optionCode': json['optionCode'],
        'description': BaseNameDTOFromJSON(json['description']),
        'sortIndex': json['sortIndex'] == null ? undefined : json['sortIndex'],
        'category': json['category'] == null ? undefined : VehicleEquipmentCategoryFromJSON(json['category']),
        'family': json['family'] == null ? undefined : json['family'],
        'availableColorCodes': json['availableColorCodes'],
        'twikit': json['twikit'],
        'lena': json['lena'],
    };
}

export function OptionCreateRequestDTOCustomTailoringToJSON(json: any): OptionCreateRequestDTOCustomTailoring {
    return OptionCreateRequestDTOCustomTailoringToJSONTyped(json, false);
}

export function OptionCreateRequestDTOCustomTailoringToJSONTyped(value?: OptionCreateRequestDTOCustomTailoring | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': BaseNameDTOToJSON(value['name']),
        'orderTypeKey': value['orderTypeKey'],
        'optionCode': value['optionCode'],
        'description': BaseNameDTOToJSON(value['description']),
        'sortIndex': value['sortIndex'],
        'category': VehicleEquipmentCategoryToJSON(value['category']),
        'family': value['family'],
        'availableColorCodes': value['availableColorCodes'],
        'twikit': value['twikit'],
        'lena': value['lena'],
    };
}


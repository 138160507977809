/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionalSelectDTOString } from './OptionalSelectDTOString';
import {
    OptionalSelectDTOStringFromJSON,
    OptionalSelectDTOStringFromJSONTyped,
    OptionalSelectDTOStringToJSON,
    OptionalSelectDTOStringToJSONTyped,
} from './OptionalSelectDTOString';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesDTOInteriorValueColor
 */
export interface OptionCustomizationAttributesDTOInteriorValueColor {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOInteriorValueColor
     */
    seatColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOInteriorValueColor
     */
    seatStitchingColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOInteriorValueColor
     */
    cockpitColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOInteriorValueColor
     */
    cockpitStitchingColorHexCode: string;
    /**
     * 
     * @type {OptionalSelectDTOString}
     * @memberof OptionCustomizationAttributesDTOInteriorValueColor
     */
    tag: OptionalSelectDTOString;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesDTOInteriorValueColor
     */
    isCustomColor: boolean;
}

/**
 * Check if a given object implements the OptionCustomizationAttributesDTOInteriorValueColor interface.
 */
export function instanceOfOptionCustomizationAttributesDTOInteriorValueColor(value: object): value is OptionCustomizationAttributesDTOInteriorValueColor {
    if (!('seatColorHexCode' in value) || value['seatColorHexCode'] === undefined) return false;
    if (!('seatStitchingColorHexCode' in value) || value['seatStitchingColorHexCode'] === undefined) return false;
    if (!('cockpitColorHexCode' in value) || value['cockpitColorHexCode'] === undefined) return false;
    if (!('cockpitStitchingColorHexCode' in value) || value['cockpitStitchingColorHexCode'] === undefined) return false;
    if (!('tag' in value) || value['tag'] === undefined) return false;
    if (!('isCustomColor' in value) || value['isCustomColor'] === undefined) return false;
    return true;
}

export function OptionCustomizationAttributesDTOInteriorValueColorFromJSON(json: any): OptionCustomizationAttributesDTOInteriorValueColor {
    return OptionCustomizationAttributesDTOInteriorValueColorFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOInteriorValueColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOInteriorValueColor {
    if (json == null) {
        return json;
    }
    return {
        
        'seatColorHexCode': json['seatColorHexCode'],
        'seatStitchingColorHexCode': json['seatStitchingColorHexCode'],
        'cockpitColorHexCode': json['cockpitColorHexCode'],
        'cockpitStitchingColorHexCode': json['cockpitStitchingColorHexCode'],
        'tag': OptionalSelectDTOStringFromJSON(json['tag']),
        'isCustomColor': json['isCustomColor'],
    };
}

export function OptionCustomizationAttributesDTOInteriorValueColorToJSON(json: any): OptionCustomizationAttributesDTOInteriorValueColor {
    return OptionCustomizationAttributesDTOInteriorValueColorToJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOInteriorValueColorToJSONTyped(value?: OptionCustomizationAttributesDTOInteriorValueColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'seatColorHexCode': value['seatColorHexCode'],
        'seatStitchingColorHexCode': value['seatStitchingColorHexCode'],
        'cockpitColorHexCode': value['cockpitColorHexCode'],
        'cockpitStitchingColorHexCode': value['cockpitStitchingColorHexCode'],
        'tag': OptionalSelectDTOStringToJSON(value['tag']),
        'isCustomColor': value['isCustomColor'],
    };
}


import {
    getProductDetailsContainer,
    ProductDetailsContainerConfig,
    ProductDetailsTabConfigContentProps,
} from "../../../../../../viewtemplates/details/ProductDetailsContainer"
import { optionApi } from "../../../../../../pdsapi"
import { ProductDetailsTab } from "../../../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { ProductDetailsCard } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { OptionDetailsCardContent } from "./data/content/OptionDetailsCardContent"
import { Route, useParams } from "react-router-dom"
import { routes } from "../../../../../../common/routes"
import { LocalizationsItemDTO, OptionKeyDTOOptionTypeDTO, OptionLocalizationItemDTO, OptionTypeDTO } from "../../../../../../generated/pdsapi"
import { useOrderTypeSubpageCrumbs } from "../../../useOrderTypeSubpageCrumbs"
import { OptionDetailsCardNotification } from "./data/content/OptionDetailsCardNotification"
import { LinkedCustomizationCard } from "./data/linkedcustomization/LinkedCustomizationCard"
import { LinkedOptionCustomizationCardContent } from "./data/linkedcustomization/LinkedOptionCustomizationCardContent"
import { OptionSimilarCustomizationsCard } from "./data/similarcustomizations/OptionSimilarCustomizationsCard"
import { DetailsDerivedLocalizationCard } from "../../../../../../viewtemplates/details/cards/derivedlocalizationcard/DetailsDerivedLocalizationCard"
import { MarketplaceSpecificsCardContent } from "./data/marketplacespecificscard/MarketplaceSpecificsCardContent"
import { NameItem } from "../../../../../../components/formelements/derivednameformelement/DerivedNameFormElement"
import { OptionsInFamilyCard } from "./data/optionsinfamilycard/OptionsInFamilyCard"
import { JSX } from "react"

const nameItem = (label: string | undefined, item: LocalizationsItemDTO): NameItem => ({
    label,
    isDerived: item.raw === undefined,
    value: item.withFallback,
})

export const OptionProductDetailsContainerConfig: ProductDetailsContainerConfig<OptionKeyDTOOptionTypeDTO> = {
    getBaseInformation: optionApi.details.getBaseInformation,

    deleteByKey: optionApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Option"}
                        getData={optionApi.details.getOption}
                        updateData={(data) =>
                            optionApi.details.updateOption({
                                orderTypeKey: data.orderTypeKey,
                                optionCode: data.optionCode,
                                optionUpdateDTOOption: data.update,
                            })
                        }
                        Content={OptionDetailsCardContent}
                        Notification={OptionDetailsCardNotification}
                    />
                    <LinkedCustomizationCard
                        {...props}
                        getLinkedCustomization={optionApi.details.getLinkedCustomization}
                        CustomizationContent={LinkedOptionCustomizationCardContent}
                        getCreateCustomizationRoute={routes.options.customizations.create}
                    />
                    <OptionSimilarCustomizationsCard {...props} />
                    {getTypeSpecificOptionDataTabCards(props)}
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsDerivedLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={optionApi.details.getName}
                        updateData={({ orderTypeKey, optionCode, type, update }) =>
                            optionApi.details.updateName({
                                orderTypeKey,
                                optionCode,
                                type,
                                requestBody: update,
                            })
                        }
                        getViewProps={(data, languageTag) => {
                            const name: OptionLocalizationItemDTO = data[languageTag]
                            return {
                                primaryName: nameItem(undefined, name.merged),
                                secondaryNames: [nameItem("Option", name.option), nameItem("Customization", name.customization ?? {})],
                            }
                        }}
                        getEditProps={(data, languageTag) => ({
                            rawName: data[languageTag].option.raw,
                        })}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Description",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsDerivedLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={optionApi.details.getDescription}
                        updateData={({ orderTypeKey, optionCode, type, update }) =>
                            optionApi.details.updateDescription({
                                orderTypeKey,
                                optionCode,
                                type,
                                requestBody: update,
                            })
                        }
                        getViewProps={(data, languageTag) => {
                            const name: OptionLocalizationItemDTO = data[languageTag]
                            return {
                                primaryName: nameItem(undefined, name.merged),
                                secondaryNames: [nameItem("Option", name.option), nameItem("Customization", name.customization ?? {})],
                            }
                        }}
                        getEditProps={(data, languageTag) => ({
                            rawName: data[languageTag].option.raw,
                        })}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Market Specifics",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        isDataEditable={false}
                        urlPathParamsPrefix={props.urlPathParamsPrefix}
                        itemKey={props.itemKey}
                        reloadDataIndicator={props.reloadDataIndicator}
                        triggerReload={props.triggerReload}
                        label={"Market Specifics"}
                        getData={optionApi.details.getMarketplaceSpecifics}
                        Content={MarketplaceSpecificsCardContent}
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

const getTypeSpecificOptionDataTabCards = (props: ProductDetailsTabConfigContentProps<OptionKeyDTOOptionTypeDTO>): JSX.Element | null => {
    switch (props.itemKey.type) {
        case OptionTypeDTO.EQUIPMENT:
            return (
                <OptionsInFamilyCard
                    {...props}
                    getData={optionApi.details.getOptionsInFamily}
                    getRoute={({ orderTypeKey, optionCode, type }) => routes.models.orderTypes.subpages(orderTypeKey).options.details(optionCode, type)}
                />
            )
        case OptionTypeDTO.EXTERIOR_COLOR:
        case OptionTypeDTO.PAINT_TO_SAMPLE_EXTERIOR_COLOR:
        case OptionTypeDTO.INTERIOR:
        case OptionTypeDTO.ROOF_COLOR:
            return null
    }
}

const OptionDetailsContainer = getProductDetailsContainer(OptionProductDetailsContainerConfig)

export const OrderTypeOptionDetailsRoute = (
    <Route
        path={routes.models.orderTypes.subpages(":orderTypeKey").options.details(":optionCode", ":type" as OptionTypeDTO)}
        Component={() => {
            const params = useParams<{ orderTypeKey: string; optionCode: string; type: OptionTypeDTO }>()
            const parentCrumbs = useOrderTypeSubpageCrumbs(params.orderTypeKey)
            return (
                <OptionDetailsContainer
                    itemKey={params}
                    parentCrumbs={[
                        ...parentCrumbs,
                        {
                            name: "Options",
                            path: routes.models.orderTypes.subpages(params.orderTypeKey).options.list(),
                        },
                    ]}
                />
            )
        }}
    />
)

import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { CustomExteriorColorFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { CustomExteriorColorFilter } from "../../../../../pdsapi"
import { FunctionComponent } from "react"
import { MultiSelectFilter } from "../../../../../components/filter/MultiSelectFilter"

export const CustomExteriorColorListFilter: FunctionComponent<TableFilterProps<CustomExteriorColorFilter, CustomExteriorColorFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const exteriorColorTypeFilter = (
        <MultiSelectFilter
            label={"Exterior color type"}
            options={filterOptions?.exteriorColorTypes}
            value={filter.exteriorColorTypes}
            onChange={(exteriorColorTypes) =>
                setFilter({
                    ...filter,
                    exteriorColorTypes,
                })
            }
        />
    )
    const exteriorColorTagKeysFilter = (
        <MultiSelectFilter
            label={"Exterior color tag"}
            options={filterOptions?.exteriorColorTags}
            value={filter.exteriorColorTagKeys}
            onChange={(exteriorColorTagKeys) =>
                setFilter({
                    ...filter,
                    exteriorColorTagKeys,
                })
            }
        />
    )
    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Exterior color tag"}>{exteriorColorTagKeysFilter}</ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Exterior color type"}>{exteriorColorTypeFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationAttributesDTOInteriorValueColor } from './OptionCustomizationAttributesDTOInteriorValueColor';
import {
    OptionCustomizationAttributesDTOInteriorValueColorFromJSON,
    OptionCustomizationAttributesDTOInteriorValueColorFromJSONTyped,
    OptionCustomizationAttributesDTOInteriorValueColorToJSON,
    OptionCustomizationAttributesDTOInteriorValueColorToJSONTyped,
} from './OptionCustomizationAttributesDTOInteriorValueColor';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';
import type { SelectDTOInteriorMaterial } from './SelectDTOInteriorMaterial';
import {
    SelectDTOInteriorMaterialFromJSON,
    SelectDTOInteriorMaterialFromJSONTyped,
    SelectDTOInteriorMaterialToJSON,
    SelectDTOInteriorMaterialToJSONTyped,
} from './SelectDTOInteriorMaterial';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesDTOInteriorValue
 */
export interface OptionCustomizationAttributesDTOInteriorValue {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationAttributesDTOInteriorValue
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationAttributesDTOInteriorValue
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {OptionCustomizationAttributesDTOInteriorValueColor}
     * @memberof OptionCustomizationAttributesDTOInteriorValue
     */
    color: OptionCustomizationAttributesDTOInteriorValueColor;
    /**
     * 
     * @type {SelectDTOInteriorMaterial}
     * @memberof OptionCustomizationAttributesDTOInteriorValue
     */
    material: SelectDTOInteriorMaterial;
}

/**
 * Check if a given object implements the OptionCustomizationAttributesDTOInteriorValue interface.
 */
export function instanceOfOptionCustomizationAttributesDTOInteriorValue(value: object): value is OptionCustomizationAttributesDTOInteriorValue {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('color' in value) || value['color'] === undefined) return false;
    if (!('material' in value) || value['material'] === undefined) return false;
    return true;
}

export function OptionCustomizationAttributesDTOInteriorValueFromJSON(json: any): OptionCustomizationAttributesDTOInteriorValue {
    return OptionCustomizationAttributesDTOInteriorValueFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOInteriorValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOInteriorValue {
    if (json == null) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
        'color': OptionCustomizationAttributesDTOInteriorValueColorFromJSON(json['color']),
        'material': SelectDTOInteriorMaterialFromJSON(json['material']),
    };
}

export function OptionCustomizationAttributesDTOInteriorValueToJSON(json: any): OptionCustomizationAttributesDTOInteriorValue {
    return OptionCustomizationAttributesDTOInteriorValueToJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOInteriorValueToJSONTyped(value?: OptionCustomizationAttributesDTOInteriorValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': BaseNameDTOToJSON(value['name']),
        'description': BaseNameDTOToJSON(value['description']),
        'color': OptionCustomizationAttributesDTOInteriorValueColorToJSON(value['color']),
        'material': SelectDTOInteriorMaterialToJSON(value['material']),
    };
}


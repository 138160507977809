/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ColorKeyDTO
 */
export interface ColorKeyDTO {
    /**
     * 
     * @type {string}
     * @memberof ColorKeyDTO
     */
    colorCode: string;
}

/**
 * Check if a given object implements the ColorKeyDTO interface.
 */
export function instanceOfColorKeyDTO(value: object): value is ColorKeyDTO {
    if (!('colorCode' in value) || value['colorCode'] === undefined) return false;
    return true;
}

export function ColorKeyDTOFromJSON(json: any): ColorKeyDTO {
    return ColorKeyDTOFromJSONTyped(json, false);
}

export function ColorKeyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColorKeyDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'colorCode': json['colorCode'],
    };
}

export function ColorKeyDTOToJSON(json: any): ColorKeyDTO {
    return ColorKeyDTOToJSONTyped(json, false);
}

export function ColorKeyDTOToJSONTyped(value?: ColorKeyDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'colorCode': value['colorCode'],
    };
}


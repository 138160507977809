/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTO } from './OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTO';
import {
    OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTOFromJSON,
    OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTOFromJSONTyped,
    OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTOToJSON,
    OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTOToJSONTyped,
} from './OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTO';
import type { VideoLocalizationsItemDTO } from './VideoLocalizationsItemDTO';
import {
    VideoLocalizationsItemDTOFromJSON,
    VideoLocalizationsItemDTOFromJSONTyped,
    VideoLocalizationsItemDTOToJSON,
    VideoLocalizationsItemDTOToJSONTyped,
} from './VideoLocalizationsItemDTO';

/**
 * 
 * @export
 * @interface OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO
 */
export interface OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO {
    /**
     * 
     * @type {{ [key: string]: VideoLocalizationsItemDTO; }}
     * @memberof OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO
     */
    defaultValue: { [key: string]: VideoLocalizationsItemDTO; };
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTO>}
     * @memberof OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTO>;
}

/**
 * Check if a given object implements the OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO interface.
 */
export function instanceOfOptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO(value: object): value is OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO {
    if (!('defaultValue' in value) || value['defaultValue'] === undefined) return false;
    if (!('conditionalValues' in value) || value['conditionalValues'] === undefined) return false;
    return true;
}

export function OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTOFromJSON(json: any): OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO {
    return OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTOFromJSONTyped(json, false);
}

export function OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultValue': (mapValues(json['defaultValue'], VideoLocalizationsItemDTOFromJSON)),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTOFromJSON)),
    };
}

export function OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTOToJSON(json: any): OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO {
    return OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTOToJSONTyped(json, false);
}

export function OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTOToJSONTyped(value?: OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultValue': (mapValues(value['defaultValue'], VideoLocalizationsItemDTOToJSON)),
        'conditionalValues': ((value['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOMapStringVideoLocalizationsItemDTOToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface ModelGenerationFilterOptionsDTO
 */
export interface ModelGenerationFilterOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof ModelGenerationFilterOptionsDTO
     */
    modelSeries: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the ModelGenerationFilterOptionsDTO interface.
 */
export function instanceOfModelGenerationFilterOptionsDTO(value: object): value is ModelGenerationFilterOptionsDTO {
    if (!('modelSeries' in value) || value['modelSeries'] === undefined) return false;
    return true;
}

export function ModelGenerationFilterOptionsDTOFromJSON(json: any): ModelGenerationFilterOptionsDTO {
    return ModelGenerationFilterOptionsDTOFromJSONTyped(json, false);
}

export function ModelGenerationFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelGenerationFilterOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'modelSeries': ((json['modelSeries'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function ModelGenerationFilterOptionsDTOToJSON(json: any): ModelGenerationFilterOptionsDTO {
    return ModelGenerationFilterOptionsDTOToJSONTyped(json, false);
}

export function ModelGenerationFilterOptionsDTOToJSONTyped(value?: ModelGenerationFilterOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'modelSeries': ((value['modelSeries'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


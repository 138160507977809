/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ColorSortField = {
    COLOR_CODE: 'COLOR_CODE'
} as const;
export type ColorSortField = typeof ColorSortField[keyof typeof ColorSortField];


export function instanceOfColorSortField(value: any): boolean {
    for (const key in ColorSortField) {
        if (Object.prototype.hasOwnProperty.call(ColorSortField, key)) {
            if (ColorSortField[key as keyof typeof ColorSortField] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ColorSortFieldFromJSON(json: any): ColorSortField {
    return ColorSortFieldFromJSONTyped(json, false);
}

export function ColorSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColorSortField {
    return json as ColorSortField;
}

export function ColorSortFieldToJSON(value?: ColorSortField | null): any {
    return value as any;
}

export function ColorSortFieldToJSONTyped(value: any, ignoreDiscriminator: boolean): ColorSortField {
    return value as ColorSortField;
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InteriorMaterial,
  OptionClassDTO,
  OptionFilterOptionsDTO,
  OptionSortField,
  PageDTOOptionItemDTO,
  SortDirection,
  VehicleEquipmentCategory,
} from '../models/index';
import {
    InteriorMaterialFromJSON,
    InteriorMaterialToJSON,
    OptionClassDTOFromJSON,
    OptionClassDTOToJSON,
    OptionFilterOptionsDTOFromJSON,
    OptionFilterOptionsDTOToJSON,
    OptionSortFieldFromJSON,
    OptionSortFieldToJSON,
    PageDTOOptionItemDTOFromJSON,
    PageDTOOptionItemDTOToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    VehicleEquipmentCategoryFromJSON,
    VehicleEquipmentCategoryToJSON,
} from '../models/index';

export interface GetOptionFilterOptionsRequest {
    languageTag: string;
}

export interface GetOptionsPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: OptionSortField;
    sortDirection: SortDirection;
    search?: string;
    modelGenerationKeys?: Set<string>;
    modelYears?: Set<number>;
    orderTypeKeys?: Set<string>;
    classes?: Set<OptionClassDTO>;
    equipmentTagKeys?: Set<string>;
    interiorColorTagKeys?: Set<string>;
    exteriorColorTagKeys?: Set<string>;
    roofColorTagKeys?: Set<string>;
    vehicleEquipmentCategories?: Set<VehicleEquipmentCategory>;
    equipmentFamilies?: Set<string>;
    interiorMaterials?: Set<InteriorMaterial>;
}

/**
 * 
 */
export class OptionCatalogueWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter Options
     */
    async getOptionFilterOptionsRaw(requestParameters: GetOptionFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionFilterOptionsDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getOptionFilterOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter Options
     */
    async getOptionFilterOptions(requestParameters: GetOptionFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionFilterOptionsDTO> {
        const response = await this.getOptionFilterOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get page of Options
     */
    async getOptionsPageRaw(requestParameters: GetOptionsPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOOptionItemDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getOptionsPage().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getOptionsPage().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getOptionsPage().'
            );
        }

        if (requestParameters['sortField'] == null) {
            throw new runtime.RequiredError(
                'sortField',
                'Required parameter "sortField" was null or undefined when calling getOptionsPage().'
            );
        }

        if (requestParameters['sortDirection'] == null) {
            throw new runtime.RequiredError(
                'sortDirection',
                'Required parameter "sortDirection" was null or undefined when calling getOptionsPage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sortField'] != null) {
            queryParameters['sortField'] = requestParameters['sortField'];
        }

        if (requestParameters['sortDirection'] != null) {
            queryParameters['sortDirection'] = requestParameters['sortDirection'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['modelGenerationKeys'] != null) {
            queryParameters['modelGenerationKeys'] = requestParameters['modelGenerationKeys'];
        }

        if (requestParameters['modelYears'] != null) {
            queryParameters['modelYears'] = requestParameters['modelYears'];
        }

        if (requestParameters['orderTypeKeys'] != null) {
            queryParameters['orderTypeKeys'] = requestParameters['orderTypeKeys'];
        }

        if (requestParameters['classes'] != null) {
            queryParameters['classes'] = requestParameters['classes'];
        }

        if (requestParameters['equipmentTagKeys'] != null) {
            queryParameters['equipmentTagKeys'] = requestParameters['equipmentTagKeys'];
        }

        if (requestParameters['interiorColorTagKeys'] != null) {
            queryParameters['interiorColorTagKeys'] = requestParameters['interiorColorTagKeys'];
        }

        if (requestParameters['exteriorColorTagKeys'] != null) {
            queryParameters['exteriorColorTagKeys'] = requestParameters['exteriorColorTagKeys'];
        }

        if (requestParameters['roofColorTagKeys'] != null) {
            queryParameters['roofColorTagKeys'] = requestParameters['roofColorTagKeys'];
        }

        if (requestParameters['vehicleEquipmentCategories'] != null) {
            queryParameters['vehicleEquipmentCategories'] = requestParameters['vehicleEquipmentCategories'];
        }

        if (requestParameters['equipmentFamilies'] != null) {
            queryParameters['equipmentFamilies'] = requestParameters['equipmentFamilies'];
        }

        if (requestParameters['interiorMaterials'] != null) {
            queryParameters['interiorMaterials'] = requestParameters['interiorMaterials'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOOptionItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of Options
     */
    async getOptionsPage(requestParameters: GetOptionsPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOOptionItemDTO> {
        const response = await this.getOptionsPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CustomExteriorColorItemDTO } from './CustomExteriorColorItemDTO';
import {
    CustomExteriorColorItemDTOFromJSON,
    CustomExteriorColorItemDTOFromJSONTyped,
    CustomExteriorColorItemDTOToJSON,
    CustomExteriorColorItemDTOToJSONTyped,
} from './CustomExteriorColorItemDTO';

/**
 * 
 * @export
 * @interface PageDTOCustomExteriorColorItemDTO
 */
export interface PageDTOCustomExteriorColorItemDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTOCustomExteriorColorItemDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<CustomExteriorColorItemDTO>}
     * @memberof PageDTOCustomExteriorColorItemDTO
     */
    content: Array<CustomExteriorColorItemDTO>;
}

/**
 * Check if a given object implements the PageDTOCustomExteriorColorItemDTO interface.
 */
export function instanceOfPageDTOCustomExteriorColorItemDTO(value: object): value is PageDTOCustomExteriorColorItemDTO {
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function PageDTOCustomExteriorColorItemDTOFromJSON(json: any): PageDTOCustomExteriorColorItemDTO {
    return PageDTOCustomExteriorColorItemDTOFromJSONTyped(json, false);
}

export function PageDTOCustomExteriorColorItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOCustomExteriorColorItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(CustomExteriorColorItemDTOFromJSON)),
    };
}

export function PageDTOCustomExteriorColorItemDTOToJSON(json: any): PageDTOCustomExteriorColorItemDTO {
    return PageDTOCustomExteriorColorItemDTOToJSONTyped(json, false);
}

export function PageDTOCustomExteriorColorItemDTOToJSONTyped(value?: PageDTOCustomExteriorColorItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'totalItemCount': value['totalItemCount'],
        'content': ((value['content'] as Array<any>).map(CustomExteriorColorItemDTOToJSON)),
    };
}


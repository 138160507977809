/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ColorTileDTO } from './ColorTileDTO';
import {
    ColorTileDTOFromJSON,
    ColorTileDTOFromJSONTyped,
    ColorTileDTOToJSON,
    ColorTileDTOToJSONTyped,
} from './ColorTileDTO';
import type { InteriorColorTileWithLabelDTO } from './InteriorColorTileWithLabelDTO';
import {
    InteriorColorTileWithLabelDTOFromJSON,
    InteriorColorTileWithLabelDTOFromJSONTyped,
    InteriorColorTileWithLabelDTOToJSON,
    InteriorColorTileWithLabelDTOToJSONTyped,
} from './InteriorColorTileWithLabelDTO';

/**
 * 
 * @export
 * @interface OrderTypeOptionItemDTOInterior
 */
export interface OrderTypeOptionItemDTOInterior {
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTOInterior
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTOInterior
     */
    optionCode: string;
    /**
     * 
     * @type {number}
     * @memberof OrderTypeOptionItemDTOInterior
     */
    sortIndex: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTOInterior
     */
    imageKey?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTOInterior
     */
    materialName?: string;
    /**
     * 
     * @type {InteriorColorTileWithLabelDTO}
     * @memberof OrderTypeOptionItemDTOInterior
     */
    color?: InteriorColorTileWithLabelDTO;
    /**
     * 
     * @type {ColorTileDTO}
     * @memberof OrderTypeOptionItemDTOInterior
     */
    colorTag?: ColorTileDTO;
}

/**
 * Check if a given object implements the OrderTypeOptionItemDTOInterior interface.
 */
export function instanceOfOrderTypeOptionItemDTOInterior(value: object): value is OrderTypeOptionItemDTOInterior {
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('sortIndex' in value) || value['sortIndex'] === undefined) return false;
    return true;
}

export function OrderTypeOptionItemDTOInteriorFromJSON(json: any): OrderTypeOptionItemDTOInterior {
    return OrderTypeOptionItemDTOInteriorFromJSONTyped(json, false);
}

export function OrderTypeOptionItemDTOInteriorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeOptionItemDTOInterior {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'optionCode': json['optionCode'],
        'sortIndex': json['sortIndex'],
        'imageKey': json['imageKey'] == null ? undefined : json['imageKey'],
        'materialName': json['materialName'] == null ? undefined : json['materialName'],
        'color': json['color'] == null ? undefined : InteriorColorTileWithLabelDTOFromJSON(json['color']),
        'colorTag': json['colorTag'] == null ? undefined : ColorTileDTOFromJSON(json['colorTag']),
    };
}

export function OrderTypeOptionItemDTOInteriorToJSON(json: any): OrderTypeOptionItemDTOInterior {
    return OrderTypeOptionItemDTOInteriorToJSONTyped(json, false);
}

export function OrderTypeOptionItemDTOInteriorToJSONTyped(value?: OrderTypeOptionItemDTOInterior | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'optionCode': value['optionCode'],
        'sortIndex': value['sortIndex'],
        'imageKey': value['imageKey'],
        'materialName': value['materialName'],
        'color': InteriorColorTileWithLabelDTOToJSON(value['color']),
        'colorTag': ColorTileDTOToJSON(value['colorTag']),
    };
}


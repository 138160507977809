/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OptionCustomizationCreateOptionsDTO,
  OptionCustomizationCreateRequestDTO,
  OptionCustomizationTypeDTO,
  SimpleOrValidationApiError,
  TypedKeyDTOOptionCustomizationTypeDTO,
  VehicleStateSearchResult,
} from '../models/index';
import {
    OptionCustomizationCreateOptionsDTOFromJSON,
    OptionCustomizationCreateOptionsDTOToJSON,
    OptionCustomizationCreateRequestDTOFromJSON,
    OptionCustomizationCreateRequestDTOToJSON,
    OptionCustomizationTypeDTOFromJSON,
    OptionCustomizationTypeDTOToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    TypedKeyDTOOptionCustomizationTypeDTOFromJSON,
    TypedKeyDTOOptionCustomizationTypeDTOToJSON,
    VehicleStateSearchResultFromJSON,
    VehicleStateSearchResultToJSON,
} from '../models/index';

export interface CreateOptionCustomizationRequest {
    optionCustomizationCreateRequestDTO: OptionCustomizationCreateRequestDTO;
}

export interface GetOptionCustomizationCreateOptionsRequest {
    languageTag: string;
}

export interface GetVehicleImagesForOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    optionCode: string;
    modelGenerationKeys: Set<string>;
    limit?: number;
}

/**
 * 
 */
export class OptionCustomizationCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new OptionCustomization
     */
    async createOptionCustomizationRaw(requestParameters: CreateOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TypedKeyDTOOptionCustomizationTypeDTO>> {
        if (requestParameters['optionCustomizationCreateRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'optionCustomizationCreateRequestDTO',
                'Required parameter "optionCustomizationCreateRequestDTO" was null or undefined when calling createOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OptionCustomizationCreateRequestDTOToJSON(requestParameters['optionCustomizationCreateRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypedKeyDTOOptionCustomizationTypeDTOFromJSON(jsonValue));
    }

    /**
     * Create a new OptionCustomization
     */
    async createOptionCustomization(requestParameters: CreateOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TypedKeyDTOOptionCustomizationTypeDTO> {
        const response = await this.createOptionCustomizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get select options to create OptionCustomizations
     */
    async getOptionCustomizationCreateOptionsRaw(requestParameters: GetOptionCustomizationCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionCustomizationCreateOptionsDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getOptionCustomizationCreateOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create OptionCustomizations
     */
    async getOptionCustomizationCreateOptions(requestParameters: GetOptionCustomizationCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionCustomizationCreateOptionsDTO> {
        const response = await this.getOptionCustomizationCreateOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getVehicleImagesForOptionCustomizationRaw(requestParameters: GetVehicleImagesForOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleStateSearchResult>>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getVehicleImagesForOptionCustomization().'
            );
        }

        if (requestParameters['optionCode'] == null) {
            throw new runtime.RequiredError(
                'optionCode',
                'Required parameter "optionCode" was null or undefined when calling getVehicleImagesForOptionCustomization().'
            );
        }

        if (requestParameters['modelGenerationKeys'] == null) {
            throw new runtime.RequiredError(
                'modelGenerationKeys',
                'Required parameter "modelGenerationKeys" was null or undefined when calling getVehicleImagesForOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['optionCode'] != null) {
            queryParameters['optionCode'] = requestParameters['optionCode'];
        }

        if (requestParameters['modelGenerationKeys'] != null) {
            queryParameters['modelGenerationKeys'] = requestParameters['modelGenerationKeys'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/create/vehicle-images`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleStateSearchResultFromJSON));
    }

    /**
     */
    async getVehicleImagesForOptionCustomization(requestParameters: GetVehicleImagesForOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleStateSearchResult>> {
        const response = await this.getVehicleImagesForOptionCustomizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

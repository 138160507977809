/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderTypeStandardEquipmentDTOOptionsCategoryOption
 */
export interface OrderTypeStandardEquipmentDTOOptionsCategoryOption {
    /**
     * 
     * @type {string}
     * @memberof OrderTypeStandardEquipmentDTOOptionsCategoryOption
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeStandardEquipmentDTOOptionsCategoryOption
     */
    optionCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderTypeStandardEquipmentDTOOptionsCategoryOption
     */
    isVisible: boolean;
    /**
     * 
     * @type {Date}
     * @memberof OrderTypeStandardEquipmentDTOOptionsCategoryOption
     */
    isStandardFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderTypeStandardEquipmentDTOOptionsCategoryOption
     */
    isStandardTo?: Date;
}

/**
 * Check if a given object implements the OrderTypeStandardEquipmentDTOOptionsCategoryOption interface.
 */
export function instanceOfOrderTypeStandardEquipmentDTOOptionsCategoryOption(value: object): value is OrderTypeStandardEquipmentDTOOptionsCategoryOption {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('isVisible' in value) || value['isVisible'] === undefined) return false;
    return true;
}

export function OrderTypeStandardEquipmentDTOOptionsCategoryOptionFromJSON(json: any): OrderTypeStandardEquipmentDTOOptionsCategoryOption {
    return OrderTypeStandardEquipmentDTOOptionsCategoryOptionFromJSONTyped(json, false);
}

export function OrderTypeStandardEquipmentDTOOptionsCategoryOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeStandardEquipmentDTOOptionsCategoryOption {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'optionCode': json['optionCode'],
        'isVisible': json['isVisible'],
        'isStandardFrom': json['isStandardFrom'] == null ? undefined : (new Date(json['isStandardFrom'])),
        'isStandardTo': json['isStandardTo'] == null ? undefined : (new Date(json['isStandardTo'])),
    };
}

export function OrderTypeStandardEquipmentDTOOptionsCategoryOptionToJSON(json: any): OrderTypeStandardEquipmentDTOOptionsCategoryOption {
    return OrderTypeStandardEquipmentDTOOptionsCategoryOptionToJSONTyped(json, false);
}

export function OrderTypeStandardEquipmentDTOOptionsCategoryOptionToJSONTyped(value?: OrderTypeStandardEquipmentDTOOptionsCategoryOption | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'optionCode': value['optionCode'],
        'isVisible': value['isVisible'],
        'isStandardFrom': value['isStandardFrom'] == null ? undefined : ((value['isStandardFrom']).toISOString().substring(0,10)),
        'isStandardTo': value['isStandardTo'] == null ? undefined : ((value['isStandardTo']).toISOString().substring(0,10)),
    };
}


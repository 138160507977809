/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EngineLayout = {
    FRONT: 'FRONT',
    MID: 'MID',
    REAR: 'REAR'
} as const;
export type EngineLayout = typeof EngineLayout[keyof typeof EngineLayout];


export function instanceOfEngineLayout(value: any): boolean {
    for (const key in EngineLayout) {
        if (Object.prototype.hasOwnProperty.call(EngineLayout, key)) {
            if (EngineLayout[key as keyof typeof EngineLayout] === value) {
                return true;
            }
        }
    }
    return false;
}

export function EngineLayoutFromJSON(json: any): EngineLayout {
    return EngineLayoutFromJSONTyped(json, false);
}

export function EngineLayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineLayout {
    return json as EngineLayout;
}

export function EngineLayoutToJSON(value?: EngineLayout | null): any {
    return value as any;
}

export function EngineLayoutToJSONTyped(value: any, ignoreDiscriminator: boolean): EngineLayout {
    return value as EngineLayout;
}


import {
    BootControllerApi,
    ColorCreateWebControllerApi,
    ColorDetailsWebControllerApi,
    ColorListWebControllerApi,
    ColorSortField,
    CustomExteriorColorCreateWebControllerApi,
    CustomExteriorColorDetailsWebControllerApi,
    CustomExteriorColorListWebControllerApi,
    CustomInteriorColorCreateWebControllerApi,
    CustomInteriorColorDetailsWebControllerApi,
    CustomInteriorColorListWebControllerApi,
    DashboardWebControllerApi,
    DerivativeCategoryCreateWebControllerApi,
    DerivativeCategoryDetailsWebControllerApi,
    DerivativeCategoryListWebControllerApi,
    DerivativeCategorySortField,
    DerivativeCategorySortWebControllerApi,
    DerivativeCreateWebControllerApi,
    DerivativeDetailsWebControllerApi,
    DerivativeListWebControllerApi,
    DerivativeSortField,
    DerivativeSortWebControllerApi,
    GetAllOfOrderTypeRequest,
    GetColorsPageRequest,
    GetCustomExteriorColorsPageRequest,
    GetCustomInteriorColorsPageRequest,
    GetDerivativeCategoriesPageRequest,
    GetDerivativesPageRequest,
    GetModelGenerationsPageRequest,
    GetModelSeriesPageRequest,
    GetOptionCustomizationsPageRequest,
    GetOptionsPageRequest,
    GetOptionTagsPageRequest,
    GetOrderTypesPageRequest,
    GetTechnicalDataPageRequest,
    LocalizationsItemDTO,
    ModelGenerationCreateWebControllerApi,
    ModelGenerationDetailsWebControllerApi,
    ModelGenerationListWebControllerApi,
    ModelGenerationSortField,
    ModelGenerationSortWebControllerApi,
    ModelSeriesCreateWebControllerApi,
    ModelSeriesDetailsWebControllerApi,
    ModelSeriesListWebControllerApi,
    ModelSeriesSortField,
    ModelSeriesSortWebControllerApi,
    OptionCatalogueWebControllerApi,
    OptionCreateWebControllerApi,
    OptionCustomizationCreateWebControllerApi,
    OptionCustomizationDetailsWebControllerApi,
    OptionCustomizationListWebControllerApi,
    OptionCustomizationSortField,
    OptionSortField,
    OptionTagCreateWebControllerApi,
    OptionTagDetailsWebControllerApi,
    OptionTagListWebControllerApi,
    OptionTagSortField,
    OptionTagSortWebControllerApi,
    OrderTypeCreateWebControllerApi,
    OrderTypeDataDetailsWebControllerApi,
    OrderTypeDetailsWebControllerApi,
    OrderTypeListWebControllerApi,
    OrderTypeLocalizationDetailsWebControllerApi,
    OrderTypeMediaDetailsWebControllerApi,
    OrderTypeOptionDataDetailsWebControllerApi,
    OrderTypeOptionDescriptionDetailsWebControllerApi,
    OrderTypeOptionDetailsWebControllerApi,
    OrderTypeOptionMarketplaceSpecificsDetailsWebControllerApi,
    OrderTypeOptionNameDetailsWebControllerApi,
    OrderTypeOptionsWebControllerApi,
    OrderTypePressEmbargoDetailsWebControllerApi,
    OrderTypeSortField,
    OrderTypeStandardEquipmentDetailsWebControllerApi,
    OrderTypeTechnicalDataDetailsWebControllerApi,
    TechnicalDataListWebControllerApi,
    TechnicalDataSortField,
    VideoLocalizationsItemDTO,
} from "../generated/pdsapi"
import { ProductListFindParamsWithoutFilter } from "../viewtemplates/list/ProductListContainer"
import { boundApi } from "./boundApi"

export type LocalizationsDTO = Record<string, LocalizationsItemDTO>
export type LocalizationsUpdateDTO = { [key: string]: string }
export type VideoLocalizationsDTO = Record<string, VideoLocalizationsItemDTO>

const dashboardControllerApi = boundApi(DashboardWebControllerApi)
export const dashboardApi = {
    getIncompleteExteriorCustomizations: dashboardControllerApi.getIncompleteExteriorColorCustomizations,
    getMissingExteriorColorCustomizations: dashboardControllerApi.getMissingExteriorColorCustomizations,
    getMissingRoofColorCustomizations: dashboardControllerApi.getMissingRoofColorCustomizations,
    getIncompleteInteriorCustomizations: dashboardControllerApi.getIncompleteInteriorCustomizations,
    getMissingInteriorCustomizations: dashboardControllerApi.getMissingInteriorCustomizations,
    getUnmaintainedCustomExteriorColors: dashboardControllerApi.getUnmaintainedCustomExteriorColors,
    getUnmaintainedCustomInteriorColors: dashboardControllerApi.getUnmaintainedCustomInteriorColors,
    getIncompleteOrderTypes: dashboardControllerApi.getIncompleteOrderTypes,
}

const colorCreateApi = boundApi(ColorCreateWebControllerApi)
const colorDetailsApi = boundApi(ColorDetailsWebControllerApi)
const colorListApi = boundApi(ColorListWebControllerApi)
export type ColorFilter = Omit<GetColorsPageRequest, keyof ProductListFindParamsWithoutFilter<ColorSortField>>
export const colorApi = {
    create: {
        create: colorCreateApi.createColor,
    },
    details: {
        getBaseInformation: colorDetailsApi.getBaseInformationOfColor,
        deleteByKey: colorDetailsApi.deleteColor,
        getAttributes: colorDetailsApi.getAttributesOfColor,
        updateAttributes: colorDetailsApi.updateAttributesOfColor,
        getNameLocalizations: colorDetailsApi.getLocalizationsOfNameOfColor,
        updateName: colorDetailsApi.updateNameOfColor,
        getDescriptionLocalizations: colorDetailsApi.getLocalizationsOfDescriptionOfColor,
        updateDescription: colorDetailsApi.updateDescriptionOfColor,
    },
    list: {
        getPage: colorListApi.getColorsPage,
    },
}

const customExteriorColorCreateApi = boundApi(CustomExteriorColorCreateWebControllerApi)
const customExteriorColorDetailsApi = boundApi(CustomExteriorColorDetailsWebControllerApi)
const customExteriorColorListApi = boundApi(CustomExteriorColorListWebControllerApi)
export type CustomExteriorColorFilter = Omit<GetCustomExteriorColorsPageRequest, keyof ProductListFindParamsWithoutFilter<unknown>>
export const customExteriorColorApi = {
    create: {
        create: customExteriorColorCreateApi.createCustomExteriorColor,
        getOptions: customExteriorColorCreateApi.getCustomExteriorColorCreateOptions,
    },
    details: {
        loadVehicleImages: customExteriorColorDetailsApi.findCustomExteriorColorVehicleImages,
        getBaseInformation: customExteriorColorDetailsApi.getBaseInformationOfCustomExteriorColor,
        deleteByKey: customExteriorColorDetailsApi.deleteCustomExteriorColor,
        getAttributes: customExteriorColorDetailsApi.getAttributesOfCustomExteriorColor,
        updateAttributes: customExteriorColorDetailsApi.updateAttributesOfCustomExteriorColor,
        getNameLocalizations: customExteriorColorDetailsApi.getLocalizationsOfNameOfCustomExteriorColor,
        updateName: customExteriorColorDetailsApi.updateNameOfCustomExteriorColor,
    },
    list: {
        getPage: customExteriorColorListApi.getCustomExteriorColorsPage,
        getFilterOptions: customExteriorColorListApi.getCustomExteriorColorFilterOptions,
    },
}

const customInteriorColorCreateApi = boundApi(CustomInteriorColorCreateWebControllerApi)
const customInteriorColorDetailsApi = boundApi(CustomInteriorColorDetailsWebControllerApi)
const customInteriorColorListApi = boundApi(CustomInteriorColorListWebControllerApi)
export type CustomInteriorColorFilter = Omit<GetCustomInteriorColorsPageRequest, keyof ProductListFindParamsWithoutFilter<unknown>>
export const customInteriorColorApi = {
    create: {
        create: customInteriorColorCreateApi.createCustomInteriorColor,
        getOptions: customInteriorColorCreateApi.getCustomInteriorColorCreateOptions,
    },
    details: {
        loadVehicleImages: customInteriorColorDetailsApi.findCustomInteriorColorVehicleImages,
        getBaseInformation: customInteriorColorDetailsApi.getBaseInformationOfCustomInteriorColor,
        deleteByKey: customInteriorColorDetailsApi.deleteCustomInteriorColor,
        getAttributes: customInteriorColorDetailsApi.getAttributesOfCustomInteriorColor,
        updateAttributes: customInteriorColorDetailsApi.updateAttributesOfCustomInteriorColor,
        getNameLocalizations: customInteriorColorDetailsApi.getLocalizationsOfNameOfCustomInteriorColor,
        updateName: customInteriorColorDetailsApi.updateNameOfCustomInteriorColor,
    },
    list: {
        getPage: customInteriorColorListApi.getCustomInteriorColorsPage,
        getFilterOptions: customInteriorColorListApi.getCustomInteriorColorFilterOptions,
    },
}

const derivativeCategoryCreateControllerApi = boundApi(DerivativeCategoryCreateWebControllerApi)
const derivativeCategoryListControllerApi = boundApi(DerivativeCategoryListWebControllerApi)
const derivativeCategoryDetailsControllerApi = boundApi(DerivativeCategoryDetailsWebControllerApi)
const derivativeCategorySortControllerApi = boundApi(DerivativeCategorySortWebControllerApi)
export type DerivativeCategoryFilter = Omit<GetDerivativeCategoriesPageRequest, keyof ProductListFindParamsWithoutFilter<DerivativeCategorySortField>>

export const derivativeCategoryApi = {
    create: {
        create: derivativeCategoryCreateControllerApi.createDerivativeCategory,
        getCreateOptions: derivativeCategoryCreateControllerApi.getDerivativeCategoryCreateOptions,
    },
    list: {
        getPage: derivativeCategoryListControllerApi.getDerivativeCategoriesPage,
        getFilterOptions: derivativeCategoryListControllerApi.getDerivativeCategoryFilterOptions,
    },
    details: {
        deleteByKey: derivativeCategoryDetailsControllerApi.deleteDerivativeCategory,
        getBaseInformation: derivativeCategoryDetailsControllerApi.getBaseInformationOfDerivativeCategory,
        getAttributes: derivativeCategoryDetailsControllerApi.getAttributesOfDerivativeCategory,
        updateAttributes: derivativeCategoryDetailsControllerApi.updateAttributesOfDerivativeCategory,
        getLinks: derivativeCategoryDetailsControllerApi.getLinksOfDerivativeCategory,
        getNameLocalizations: derivativeCategoryDetailsControllerApi.getLocalizationsOfNameOfDerivativeCategory,
        updateNameLocalizations: derivativeCategoryDetailsControllerApi.updateNameOfDerivativeCategory,
        getDescriptionLocalizations: derivativeCategoryDetailsControllerApi.getLocalizationsOfDescriptionOfDerivativeCategory,
        updateDescriptionLocalizations: derivativeCategoryDetailsControllerApi.updateDescriptionOfDerivativeCategory,
    },
    sort: {
        getSortableItems: derivativeCategorySortControllerApi.getDerivativeCategorySortableItems,
        updateSortIndices: derivativeCategorySortControllerApi.updateDerivativeCategorySortIndex,
    },
}

const derivativeCreateControllerApi = boundApi(DerivativeCreateWebControllerApi)
const derivativeListControllerApi = boundApi(DerivativeListWebControllerApi)
const derivativeDetailsControllerApi = boundApi(DerivativeDetailsWebControllerApi)
const derivativeSortControllerApi = boundApi(DerivativeSortWebControllerApi)
export type DerivativeFilter = Omit<GetDerivativesPageRequest, keyof ProductListFindParamsWithoutFilter<DerivativeSortField>>

export const derivativeApi = {
    create: {
        create: derivativeCreateControllerApi.createDerivative,
        getCreateOptions: derivativeCreateControllerApi.getDerivativeCreateOptions,
    },
    list: {
        getPage: derivativeListControllerApi.getDerivativesPage,
        getFilterOptions: derivativeListControllerApi.getDerivativeFilterOptions,
    },
    details: {
        deleteByKey: derivativeDetailsControllerApi.deleteDerivative,
        getBaseInformation: derivativeDetailsControllerApi.getBaseInformationOfDerivative,
        getAttributes: derivativeDetailsControllerApi.getAttributesOfDerivative,
        updateAttributes: derivativeDetailsControllerApi.updateAttributesOfDerivative,
        getLinks: derivativeDetailsControllerApi.getLinksOfDerivative,
        getLocalizations: derivativeDetailsControllerApi.getLocalizationsOfNameOfDerivative,
        updateLocalizations: derivativeDetailsControllerApi.updateNameOfDerivative,
    },
    sort: {
        getSortableItems: derivativeSortControllerApi.getDerivativeSortableItems,
        updateSortIndices: derivativeSortControllerApi.updateDerivativeSortIndex,
    },
}

const optionCustomizationCreateControllerApi = boundApi(OptionCustomizationCreateWebControllerApi)
const optionCustomizationListControllerApi = boundApi(OptionCustomizationListWebControllerApi)
const optionCustomizationDetailsControllerApi = boundApi(OptionCustomizationDetailsWebControllerApi)
export type OptionCustomizationFilter = Omit<GetOptionCustomizationsPageRequest, keyof ProductListFindParamsWithoutFilter<OptionCustomizationSortField>>

export const optionCustomizationApi = {
    create: {
        create: optionCustomizationCreateControllerApi.createOptionCustomization,
        getCreateOptions: optionCustomizationCreateControllerApi.getOptionCustomizationCreateOptions,
        getVehicleImages: optionCustomizationCreateControllerApi.getVehicleImagesForOptionCustomization,
    },
    list: {
        getPage: optionCustomizationListControllerApi.getOptionCustomizationsPage,
        getFilterOptions: optionCustomizationListControllerApi.getOptionCustomizationFilterOptions,
    },
    details: {
        deleteByKey: optionCustomizationDetailsControllerApi.deleteOptionCustomization,
        getBaseInformation: optionCustomizationDetailsControllerApi.getBaseInformationOfOptionCustomization,
        getAttributes: optionCustomizationDetailsControllerApi.getAttributesOfOptionCustomization,
        updateAttributes: optionCustomizationDetailsControllerApi.updateAttributesOfOptionCustomization,
        getLinks: optionCustomizationDetailsControllerApi.getLinksOfOptionCustomization,
        getVehicleImages: optionCustomizationDetailsControllerApi.getVehicleImagesOfOptionCustomization,
        getName: optionCustomizationDetailsControllerApi.getLocalizationsOfNameOfOptionCustomization,
        updateDefaultName: optionCustomizationDetailsControllerApi.updateNameOfDefaultValueOfOptionCustomization,
        updateConditionalValueName: optionCustomizationDetailsControllerApi.updateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization,
        getDescription: optionCustomizationDetailsControllerApi.getLocalizationsOfDescriptionOfOptionCustomization,
        updateDefaultDescription: optionCustomizationDetailsControllerApi.updateDescriptionOfDefaultValueOfOptionCustomization,
        updateConditionalValueDescription:
            optionCustomizationDetailsControllerApi.updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization,
        getVideos: optionCustomizationDetailsControllerApi.getLocalizationsOfVideoOfOptionCustomization,
        updateDefaultVideo: optionCustomizationDetailsControllerApi.updateVideoOfDefaultValueOfOptionCustomization,
        updateConditionalValueVideo: optionCustomizationDetailsControllerApi.updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization,
    },
}

const optionTagCreateControllerApi = boundApi(OptionTagCreateWebControllerApi)
const optionTagListControllerApi = boundApi(OptionTagListWebControllerApi)
const optionTagDetailsControllerApi = boundApi(OptionTagDetailsWebControllerApi)
const optionTagSortControllerApi = boundApi(OptionTagSortWebControllerApi)
export type OptionTagFilter = Omit<GetOptionTagsPageRequest, keyof ProductListFindParamsWithoutFilter<OptionTagSortField>>

export const optionTagApi = {
    create: {
        create: optionTagCreateControllerApi.createOptionTag,
        getCreateOptions: optionTagCreateControllerApi.getOptionTagCreateOptions,
    },
    list: {
        getPage: optionTagListControllerApi.getOptionTagsPage,
        getFilterOptions: optionTagListControllerApi.getOptionTagFilterOptions,
    },
    details: {
        deleteByKey: optionTagDetailsControllerApi.deleteOptionTag,
        getBaseInformation: optionTagDetailsControllerApi.getBaseInformationOfOptionTag,
        getAttributes: optionTagDetailsControllerApi.getAttributesOfOptionTag,
        updateAttributes: optionTagDetailsControllerApi.updateAttributesOfOptionTag,
        getNameLocalizations: optionTagDetailsControllerApi.getLocalizationsOfNameOfOptionTag,
        updateName: optionTagDetailsControllerApi.updateNameOfOptionTag,
    },
    sort: {
        getSortableItems: optionTagSortControllerApi.getOptionTagSortableItems,
        updateSortIndices: optionTagSortControllerApi.updateOptionTagSortIndex,
    },
}

const optionCatalogueControllerApi = boundApi(OptionCatalogueWebControllerApi)
const optionCreateControllerApi = boundApi(OptionCreateWebControllerApi)
const orderTypeOptionDetailsControllerApi = boundApi(OrderTypeOptionDetailsWebControllerApi)
const orderTypeOptionDataDetailsControllerApi = boundApi(OrderTypeOptionDataDetailsWebControllerApi)
const orderTypeOptionNameDetailsControllerApi = boundApi(OrderTypeOptionNameDetailsWebControllerApi)
const orderTypeOptionDescriptionDetailsControllerApi = boundApi(OrderTypeOptionDescriptionDetailsWebControllerApi)
const orderTypeOptionMarketplaceSpecificsDetailsWebControllerApi = boundApi(OrderTypeOptionMarketplaceSpecificsDetailsWebControllerApi)
export type OptionFilter = Omit<GetOptionsPageRequest, keyof ProductListFindParamsWithoutFilter<OptionSortField>>
export const optionApi = {
    catalogue: {
        getPage: optionCatalogueControllerApi.getOptionsPage,
        getFilterOptions: optionCatalogueControllerApi.getOptionFilterOptions,
    },
    create: {
        create: optionCreateControllerApi.createOption,
        getCreateOptions: optionCreateControllerApi.getOptionCreateOptions,
    },
    details: {
        deleteByKey: orderTypeOptionDetailsControllerApi.deleteOption,
        getBaseInformation: orderTypeOptionDetailsControllerApi.getBaseInformationOfOption,
        getOption: orderTypeOptionDataDetailsControllerApi.getOptionOfOption,
        updateOption: orderTypeOptionDataDetailsControllerApi.updateOptionOfOption,
        getLinkedCustomization: orderTypeOptionDataDetailsControllerApi.getLinkedCustomizationOfOption,
        getSimilarCustomizations: orderTypeOptionDataDetailsControllerApi.getSimilarCustomizationsOfOption,
        getOptionsInFamily: orderTypeOptionDataDetailsControllerApi.getOptionsInFamilyOfOption,
        getName: orderTypeOptionNameDetailsControllerApi.getLocalizationsOfNameOfOption,
        updateName: orderTypeOptionNameDetailsControllerApi.updateNameOfOption,
        getDescription: orderTypeOptionDescriptionDetailsControllerApi.getLocalizationsOfDescriptionOfOption,
        updateDescription: orderTypeOptionDescriptionDetailsControllerApi.updateDescriptionOfOption,
        getMarketplaceSpecifics: orderTypeOptionMarketplaceSpecificsDetailsWebControllerApi.getMarketplaceSpecificsOfOption,
    },
}

const modelGenerationCreateControllerApi = boundApi(ModelGenerationCreateWebControllerApi)
const modelGenerationDetailsControllerApi = boundApi(ModelGenerationDetailsWebControllerApi)
const modelGenerationListControllerApi = boundApi(ModelGenerationListWebControllerApi)
const modelGenerationSortControllerApi = boundApi(ModelGenerationSortWebControllerApi)
export type ModelGenerationFilter = Omit<GetModelGenerationsPageRequest, keyof ProductListFindParamsWithoutFilter<ModelGenerationSortField>>

export const modelGenerationApi = {
    create: {
        create: modelGenerationCreateControllerApi.createModelGeneration,
        getCreateOptions: modelGenerationCreateControllerApi.getModelGenerationCreateOptions,
    },
    details: {
        getBaseInformation: modelGenerationDetailsControllerApi.getBaseInformationOfModelGeneration,
        deleteByKey: modelGenerationDetailsControllerApi.deleteModelGeneration,
        getAttributes: modelGenerationDetailsControllerApi.getAttributesOfModelGeneration,
        updateAttributes: modelGenerationDetailsControllerApi.updateAttributesOfModelGeneration,
        getName: modelGenerationDetailsControllerApi.getLocalizationsOfNameOfModelGeneration,
        updateName: modelGenerationDetailsControllerApi.updateNameOfModelGeneration,
    },
    list: {
        getPage: modelGenerationListControllerApi.getModelGenerationsPage,
        getFilterOptions: modelGenerationListControllerApi.getModelGenerationFilterOptions,
    },
    sort: {
        getSortableItems: modelGenerationSortControllerApi.getModelGenerationSortableItems,
        updateSortIndices: modelGenerationSortControllerApi.updateModelGenerationSortIndex,
    },
}

const modelSeriesCreateControllerApi = boundApi(ModelSeriesCreateWebControllerApi)
const modelSeriesDetailsControllerApi = boundApi(ModelSeriesDetailsWebControllerApi)
const modelSeriesListControllerApi = boundApi(ModelSeriesListWebControllerApi)
const modelSeriesSortControllerApi = boundApi(ModelSeriesSortWebControllerApi)
export type ModelSeriesFilter = Omit<GetModelSeriesPageRequest, keyof ProductListFindParamsWithoutFilter<ModelSeriesSortField>>

export const modelSeriesApi = {
    create: {
        create: modelSeriesCreateControllerApi.createModelSeries,
    },
    details: {
        getBaseInformation: modelSeriesDetailsControllerApi.getBaseInformationOfModelSeries,
        deleteByKey: modelSeriesDetailsControllerApi.deleteModelSeries,
        getAttributes: modelSeriesDetailsControllerApi.getAttributesOfModelSeries,
        updateAttributes: modelSeriesDetailsControllerApi.updateAttributesOfModelSeries,
        getLinks: modelSeriesDetailsControllerApi.getLinksOfModelSeries,
        getName: modelSeriesDetailsControllerApi.getLocalizationsOfNameOfModelSeries,
        updateName: modelSeriesDetailsControllerApi.updateNameOfModelSeries,
    },
    list: {
        getPage: modelSeriesListControllerApi.getModelSeriesPage,
    },
    sort: {
        getSortableItems: modelSeriesSortControllerApi.getModelSeriesSortableItems,
        updateSortIndices: modelSeriesSortControllerApi.updateModelSeriesSortIndex,
    },
}

const orderTypeCreateControllerApi = boundApi(OrderTypeCreateWebControllerApi)
const orderTypeListControllerApi = boundApi(OrderTypeListWebControllerApi)
const orderTypeDetailsControllerApi = boundApi(OrderTypeDetailsWebControllerApi)
const orderTypeDataDetailsControllerApi = boundApi(OrderTypeDataDetailsWebControllerApi)
const orderTypeLocalizationDetailsControllerApi = boundApi(OrderTypeLocalizationDetailsWebControllerApi)
const orderTypeOptionsControllerApi = boundApi(OrderTypeOptionsWebControllerApi)
const orderTypeStandardEquipmentDetailsControllerApi = boundApi(OrderTypeStandardEquipmentDetailsWebControllerApi)
const orderTypeTechnicalDataDetailsControllerApi = boundApi(OrderTypeTechnicalDataDetailsWebControllerApi)
const orderTypeMediaControllerApi = boundApi(OrderTypeMediaDetailsWebControllerApi)
const orderTypePressEmbargoDetailsControllerApi = boundApi(OrderTypePressEmbargoDetailsWebControllerApi)
export type OrderTypeFilter = Omit<GetOrderTypesPageRequest, keyof ProductListFindParamsWithoutFilter<OrderTypeSortField>>
export type OrderTypeOptionFilter = Omit<GetAllOfOrderTypeRequest, keyof ProductListFindParamsWithoutFilter<OptionSortField> | "key">
export const orderTypeApi = {
    create: {
        create: orderTypeCreateControllerApi.createOrderType,
        getCreateOptions: orderTypeCreateControllerApi.getOrderTypeCreateOptions,
    },
    list: {
        getPage: orderTypeListControllerApi.getOrderTypesPage,
        getFilterOptions: orderTypeListControllerApi.getOrderTypeFilterOptions,
    },
    details: {
        deleteByKey: orderTypeDetailsControllerApi.deleteOrderType,
        getBaseInformation: orderTypeDetailsControllerApi.getBaseInformationOfOrderType,
        getContext: orderTypeDetailsControllerApi.getContextOfOrderType,
        data: {
            getAttributes: orderTypeDataDetailsControllerApi.getAttributesOfOrderType,
            updateAttributes: orderTypeDataDetailsControllerApi.updateAttributesOfOrderType,
        },
        localization: {
            getName: orderTypeLocalizationDetailsControllerApi.getNameOfOrderType,
            updateManualName: orderTypeLocalizationDetailsControllerApi.updateManualOfNameOfOrderType,
        },
        options: {
            getAll: orderTypeOptionsControllerApi.getAllOfOrderType,
            getAllFilterOptions: orderTypeOptionsControllerApi.getFilterOptionsOfAllOfOrderType,
        },
        standardEquipments: {
            getStandardEquipment: orderTypeStandardEquipmentDetailsControllerApi.getlanguageTagOfStandardEquipmentByMarketplaceOfOrderType,
        },
        technicalData: {
            getRelatedData: orderTypeTechnicalDataDetailsControllerApi.getRelatedDataOfOrderType,
            getGlobalAttribute: orderTypeTechnicalDataDetailsControllerApi.getGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType,
            getMarketplaceSpecificAttribute:
                orderTypeTechnicalDataDetailsControllerApi.getmarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType,
            updateGlobalAttribute: orderTypeTechnicalDataDetailsControllerApi.updateGlobalOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType,
            updateMarketplaceSpecificAttribute:
                orderTypeTechnicalDataDetailsControllerApi.updatemarketplaceOfSpecificOfTechnicalDataAttributesByTechnicalDataAttributeOfOrderType,
        },
        pressEmbargoes: {
            getPressEmbargoes: orderTypePressEmbargoDetailsControllerApi.getOfOrderType,
            updatePressEmbargoes: orderTypePressEmbargoDetailsControllerApi.updateManualOfOrderType,
        },
        media: {
            getEngineSounds: orderTypeMediaControllerApi.getEngineSoundsOfSoundOfMedia,
            updateEngineSounds: orderTypeMediaControllerApi.updateOfEngineSoundsOfSoundOfMedia,
            getRelatedData: orderTypeMediaControllerApi.getRelatedDataOfMedia,
        },
    },
}

const technicalDataListControllerApi = boundApi(TechnicalDataListWebControllerApi)
export type TechnicalDataFilter = Omit<
    GetTechnicalDataPageRequest,
    keyof ProductListFindParamsWithoutFilter<TechnicalDataSortField> | "technicalDataAttribute" | "marketplace"
>

export const technicalDataApi = {
    list: {
        getPage: technicalDataListControllerApi.getTechnicalDataPage,
        getFilterOptions: technicalDataListControllerApi.getTechnicalDataFilterOptions,
        getAttributeOptions: technicalDataListControllerApi.getTechnicalDataAttributeOptions,
    },
}

const bootControllerApi = boundApi(BootControllerApi)
export const bootApi = {
    boot: bootControllerApi.boot,
}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DerivativeCategorySortField = {
    SORT_INDEX: 'SORT_INDEX'
} as const;
export type DerivativeCategorySortField = typeof DerivativeCategorySortField[keyof typeof DerivativeCategorySortField];


export function instanceOfDerivativeCategorySortField(value: any): boolean {
    for (const key in DerivativeCategorySortField) {
        if (Object.prototype.hasOwnProperty.call(DerivativeCategorySortField, key)) {
            if (DerivativeCategorySortField[key as keyof typeof DerivativeCategorySortField] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DerivativeCategorySortFieldFromJSON(json: any): DerivativeCategorySortField {
    return DerivativeCategorySortFieldFromJSONTyped(json, false);
}

export function DerivativeCategorySortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): DerivativeCategorySortField {
    return json as DerivativeCategorySortField;
}

export function DerivativeCategorySortFieldToJSON(value?: DerivativeCategorySortField | null): any {
    return value as any;
}

export function DerivativeCategorySortFieldToJSONTyped(value: any, ignoreDiscriminator: boolean): DerivativeCategorySortField {
    return value as DerivativeCategorySortField;
}


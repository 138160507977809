/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OrderTypeStandardEquipmentDTO,
  PdsMarketplace,
  SimpleApiError,
} from '../models/index';
import {
    OrderTypeStandardEquipmentDTOFromJSON,
    OrderTypeStandardEquipmentDTOToJSON,
    PdsMarketplaceFromJSON,
    PdsMarketplaceToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
} from '../models/index';

export interface GetlanguageTagOfStandardEquipmentByMarketplaceOfOrderTypeRequest {
    key: string;
    marketplace: PdsMarketplace;
    languageTag: string;
}

/**
 * 
 */
export class OrderTypeStandardEquipmentDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get {languageTag}OfStandardEquipmentByMarketplace of OrderType with given key
     */
    async getlanguageTagOfStandardEquipmentByMarketplaceOfOrderTypeRaw(requestParameters: GetlanguageTagOfStandardEquipmentByMarketplaceOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderTypeStandardEquipmentDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getlanguageTagOfStandardEquipmentByMarketplaceOfOrderType().'
            );
        }

        if (requestParameters['marketplace'] == null) {
            throw new runtime.RequiredError(
                'marketplace',
                'Required parameter "marketplace" was null or undefined when calling getlanguageTagOfStandardEquipmentByMarketplaceOfOrderType().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getlanguageTagOfStandardEquipmentByMarketplaceOfOrderType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/standard-equipments/standard-equipment/{marketplace}/{languageTag}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))).replace(`{${"marketplace"}}`, encodeURIComponent(String(requestParameters['marketplace']))).replace(`{${"languageTag"}}`, encodeURIComponent(String(requestParameters['languageTag']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeStandardEquipmentDTOFromJSON(jsonValue));
    }

    /**
     * Get {languageTag}OfStandardEquipmentByMarketplace of OrderType with given key
     */
    async getlanguageTagOfStandardEquipmentByMarketplaceOfOrderType(requestParameters: GetlanguageTagOfStandardEquipmentByMarketplaceOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderTypeStandardEquipmentDTO> {
        const response = await this.getlanguageTagOfStandardEquipmentByMarketplaceOfOrderTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ColorSortField,
  PageDTOColorItemDTO,
  SortDirection,
} from '../models/index';
import {
    ColorSortFieldFromJSON,
    ColorSortFieldToJSON,
    PageDTOColorItemDTOFromJSON,
    PageDTOColorItemDTOToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models/index';

export interface GetColorsPageRequest {
    languageTag: string;
    sortField: ColorSortField;
    sortDirection: SortDirection;
    offset: number;
    size: number;
    search?: string;
}

/**
 * 
 */
export class ColorListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get page of Colors
     */
    async getColorsPageRaw(requestParameters: GetColorsPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOColorItemDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getColorsPage().'
            );
        }

        if (requestParameters['sortField'] == null) {
            throw new runtime.RequiredError(
                'sortField',
                'Required parameter "sortField" was null or undefined when calling getColorsPage().'
            );
        }

        if (requestParameters['sortDirection'] == null) {
            throw new runtime.RequiredError(
                'sortDirection',
                'Required parameter "sortDirection" was null or undefined when calling getColorsPage().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getColorsPage().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getColorsPage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['sortField'] != null) {
            queryParameters['sortField'] = requestParameters['sortField'];
        }

        if (requestParameters['sortDirection'] != null) {
            queryParameters['sortDirection'] = requestParameters['sortDirection'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/colors/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOColorItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of Colors
     */
    async getColorsPage(requestParameters: GetColorsPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOColorItemDTO> {
        const response = await this.getColorsPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

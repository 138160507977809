import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import {
    InteriorMaterial,
    OptionCustomizationFilterOptionsDTO,
    OptionCustomizationItemDTO,
    OptionCustomizationSortField,
    OptionCustomizationTypeDTO,
    SortDirection,
} from "../../../../generated/pdsapi"
import { optionCustomizationApi, OptionCustomizationFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { OptionCustomizationListColumnsConfig } from "./columns/OptionCustomizationListColumns"
import { OptionCustomizationListFilter } from "./filter/OptionCustomizationListFilter"
import { OptionCustomizationListFilterTagsConfig } from "./filter/OptionCustomizationListFilterTagsConfig"
import { optionCustomizationTypeToEnum } from "../optionCustomizationTypes"
import { routes } from "../../../../common/routes"
import { Route } from "react-router-dom"

export const OptionCustomizationListContainerConfig: ProductListContainerConfig<
    OptionCustomizationItemDTO,
    OptionCustomizationFilter,
    OptionCustomizationFilterOptionsDTO,
    OptionCustomizationSortField
> = {
    listName: "Option Customizations",
    createItemLabel: "Add new Option Customization",

    buildDetailsPath: (optionCustomization) =>
        routes.options.customizations.details({
            type: optionCustomizationTypeToEnum(optionCustomization.type),
            key: optionCustomization.key,
        }),
    buildCreatePath: routes.options.customizations.create,
    buildSortPath: undefined,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<OptionCustomizationFilter>({
        types: getEnumConverterGroup(OptionCustomizationTypeDTO).optionalSet,
        modelGenerationKeys: stringConverterGroup.optionalSet,
        equipmentTagKeys: stringConverterGroup.optionalSet,
        interiorColorTagKeys: stringConverterGroup.optionalSet,
        exteriorColorTagKeys: stringConverterGroup.optionalSet,
        roofColorTagKeys: stringConverterGroup.optionalSet,
        interiorMaterials: getEnumConverterGroup(InteriorMaterial).optionalSet,
    }),
    defaultSorting: {
        sortField: OptionCustomizationSortField.OPTION_CODE,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(OptionCustomizationSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: optionCustomizationApi.list.getPage,
    fetchFilterOptions: optionCustomizationApi.list.getFilterOptions,

    defaultShowsFilter: false,
    Filter: OptionCustomizationListFilter,
    filterTagsConfig: OptionCustomizationListFilterTagsConfig,

    columnsConfig: OptionCustomizationListColumnsConfig,
}

const OptionCustomizationListContainer = getProductListContainer(OptionCustomizationListContainerConfig)

export const OptionCustomizationListRoute = <Route path={routes.options.customizations.list()} Component={OptionCustomizationListContainer} />

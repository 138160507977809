/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VideoDTO
 */
export interface VideoDTO {
    /**
     * 
     * @type {string}
     * @memberof VideoDTO
     */
    vimeoId: string;
    /**
     * 
     * @type {string}
     * @memberof VideoDTO
     */
    hash: string;
}

/**
 * Check if a given object implements the VideoDTO interface.
 */
export function instanceOfVideoDTO(value: object): value is VideoDTO {
    if (!('vimeoId' in value) || value['vimeoId'] === undefined) return false;
    if (!('hash' in value) || value['hash'] === undefined) return false;
    return true;
}

export function VideoDTOFromJSON(json: any): VideoDTO {
    return VideoDTOFromJSONTyped(json, false);
}

export function VideoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VideoDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'vimeoId': json['vimeoId'],
        'hash': json['hash'],
    };
}

export function VideoDTOToJSON(json: any): VideoDTO {
    return VideoDTOToJSONTyped(json, false);
}

export function VideoDTOToJSONTyped(value?: VideoDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'vimeoId': value['vimeoId'],
        'hash': value['hash'],
    };
}


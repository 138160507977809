/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface DerivativeCategoryCreateOptionsDTO
 */
export interface DerivativeCategoryCreateOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof DerivativeCategoryCreateOptionsDTO
     */
    modelSeries: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the DerivativeCategoryCreateOptionsDTO interface.
 */
export function instanceOfDerivativeCategoryCreateOptionsDTO(value: object): value is DerivativeCategoryCreateOptionsDTO {
    if (!('modelSeries' in value) || value['modelSeries'] === undefined) return false;
    return true;
}

export function DerivativeCategoryCreateOptionsDTOFromJSON(json: any): DerivativeCategoryCreateOptionsDTO {
    return DerivativeCategoryCreateOptionsDTOFromJSONTyped(json, false);
}

export function DerivativeCategoryCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DerivativeCategoryCreateOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'modelSeries': ((json['modelSeries'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function DerivativeCategoryCreateOptionsDTOToJSON(json: any): DerivativeCategoryCreateOptionsDTO {
    return DerivativeCategoryCreateOptionsDTOToJSONTyped(json, false);
}

export function DerivativeCategoryCreateOptionsDTOToJSONTyped(value?: DerivativeCategoryCreateOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'modelSeries': ((value['modelSeries'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


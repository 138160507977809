/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TechnicalDataAttributeEquipmentRelationObject
 */
export interface TechnicalDataAttributeEquipmentRelationObject {
    /**
     * 
     * @type {Array<string>}
     * @memberof TechnicalDataAttributeEquipmentRelationObject
     */
    equipmentCodes: Array<string>;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof TechnicalDataAttributeEquipmentRelationObject
     */
    values: { [key: string]: any; };
}

/**
 * Check if a given object implements the TechnicalDataAttributeEquipmentRelationObject interface.
 */
export function instanceOfTechnicalDataAttributeEquipmentRelationObject(value: object): value is TechnicalDataAttributeEquipmentRelationObject {
    if (!('equipmentCodes' in value) || value['equipmentCodes'] === undefined) return false;
    if (!('values' in value) || value['values'] === undefined) return false;
    return true;
}

export function TechnicalDataAttributeEquipmentRelationObjectFromJSON(json: any): TechnicalDataAttributeEquipmentRelationObject {
    return TechnicalDataAttributeEquipmentRelationObjectFromJSONTyped(json, false);
}

export function TechnicalDataAttributeEquipmentRelationObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalDataAttributeEquipmentRelationObject {
    if (json == null) {
        return json;
    }
    return {
        
        'equipmentCodes': json['equipmentCodes'],
        'values': json['values'],
    };
}

export function TechnicalDataAttributeEquipmentRelationObjectToJSON(json: any): TechnicalDataAttributeEquipmentRelationObject {
    return TechnicalDataAttributeEquipmentRelationObjectToJSONTyped(json, false);
}

export function TechnicalDataAttributeEquipmentRelationObjectToJSONTyped(value?: TechnicalDataAttributeEquipmentRelationObject | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'equipmentCodes': value['equipmentCodes'],
        'values': value['values'],
    };
}


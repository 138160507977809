/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LinkedCustomizationDTOCustomizationValueEquipmentTag } from './LinkedCustomizationDTOCustomizationValueEquipmentTag';
import {
    LinkedCustomizationDTOCustomizationValueEquipmentTagFromJSON,
    LinkedCustomizationDTOCustomizationValueEquipmentTagFromJSONTyped,
    LinkedCustomizationDTOCustomizationValueEquipmentTagToJSON,
    LinkedCustomizationDTOCustomizationValueEquipmentTagToJSONTyped,
} from './LinkedCustomizationDTOCustomizationValueEquipmentTag';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';

/**
 * 
 * @export
 * @interface LinkedCustomizationDTOCustomizationValueEquipment
 */
export interface LinkedCustomizationDTOCustomizationValueEquipment {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof LinkedCustomizationDTOCustomizationValueEquipment
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {Array<LinkedCustomizationDTOCustomizationValueEquipmentTag>}
     * @memberof LinkedCustomizationDTOCustomizationValueEquipment
     */
    tags: Array<LinkedCustomizationDTOCustomizationValueEquipmentTag>;
}

/**
 * Check if a given object implements the LinkedCustomizationDTOCustomizationValueEquipment interface.
 */
export function instanceOfLinkedCustomizationDTOCustomizationValueEquipment(value: object): value is LinkedCustomizationDTOCustomizationValueEquipment {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    return true;
}

export function LinkedCustomizationDTOCustomizationValueEquipmentFromJSON(json: any): LinkedCustomizationDTOCustomizationValueEquipment {
    return LinkedCustomizationDTOCustomizationValueEquipmentFromJSONTyped(json, false);
}

export function LinkedCustomizationDTOCustomizationValueEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedCustomizationDTOCustomizationValueEquipment {
    if (json == null) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'tags': ((json['tags'] as Array<any>).map(LinkedCustomizationDTOCustomizationValueEquipmentTagFromJSON)),
    };
}

export function LinkedCustomizationDTOCustomizationValueEquipmentToJSON(json: any): LinkedCustomizationDTOCustomizationValueEquipment {
    return LinkedCustomizationDTOCustomizationValueEquipmentToJSONTyped(json, false);
}

export function LinkedCustomizationDTOCustomizationValueEquipmentToJSONTyped(value?: LinkedCustomizationDTOCustomizationValueEquipment | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': BaseNameDTOToJSON(value['name']),
        'tags': ((value['tags'] as Array<any>).map(LinkedCustomizationDTOCustomizationValueEquipmentTagToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RelatedOptionGroupDTOStringString } from './RelatedOptionGroupDTOStringString';
import {
    RelatedOptionGroupDTOStringStringFromJSON,
    RelatedOptionGroupDTOStringStringFromJSONTyped,
    RelatedOptionGroupDTOStringStringToJSON,
    RelatedOptionGroupDTOStringStringToJSONTyped,
} from './RelatedOptionGroupDTOStringString';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface OrderTypeFilterOptionsDTO
 */
export interface OrderTypeFilterOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OrderTypeFilterOptionsDTO
     */
    modelSeries: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<RelatedOptionGroupDTOStringString>}
     * @memberof OrderTypeFilterOptionsDTO
     */
    modelSeriesRelatedModelGenerations: Array<RelatedOptionGroupDTOStringString>;
    /**
     * 
     * @type {Array<RelatedOptionGroupDTOStringString>}
     * @memberof OrderTypeFilterOptionsDTO
     */
    modelSeriesRelatedDerivatives: Array<RelatedOptionGroupDTOStringString>;
}

/**
 * Check if a given object implements the OrderTypeFilterOptionsDTO interface.
 */
export function instanceOfOrderTypeFilterOptionsDTO(value: object): value is OrderTypeFilterOptionsDTO {
    if (!('modelSeries' in value) || value['modelSeries'] === undefined) return false;
    if (!('modelSeriesRelatedModelGenerations' in value) || value['modelSeriesRelatedModelGenerations'] === undefined) return false;
    if (!('modelSeriesRelatedDerivatives' in value) || value['modelSeriesRelatedDerivatives'] === undefined) return false;
    return true;
}

export function OrderTypeFilterOptionsDTOFromJSON(json: any): OrderTypeFilterOptionsDTO {
    return OrderTypeFilterOptionsDTOFromJSONTyped(json, false);
}

export function OrderTypeFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeFilterOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'modelSeries': ((json['modelSeries'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'modelSeriesRelatedModelGenerations': ((json['modelSeriesRelatedModelGenerations'] as Array<any>).map(RelatedOptionGroupDTOStringStringFromJSON)),
        'modelSeriesRelatedDerivatives': ((json['modelSeriesRelatedDerivatives'] as Array<any>).map(RelatedOptionGroupDTOStringStringFromJSON)),
    };
}

export function OrderTypeFilterOptionsDTOToJSON(json: any): OrderTypeFilterOptionsDTO {
    return OrderTypeFilterOptionsDTOToJSONTyped(json, false);
}

export function OrderTypeFilterOptionsDTOToJSONTyped(value?: OrderTypeFilterOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'modelSeries': ((value['modelSeries'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'modelSeriesRelatedModelGenerations': ((value['modelSeriesRelatedModelGenerations'] as Array<any>).map(RelatedOptionGroupDTOStringStringToJSON)),
        'modelSeriesRelatedDerivatives': ((value['modelSeriesRelatedDerivatives'] as Array<any>).map(RelatedOptionGroupDTOStringStringToJSON)),
    };
}


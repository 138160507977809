/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VehicleEquipmentCategory } from './VehicleEquipmentCategory';
import {
    VehicleEquipmentCategoryFromJSON,
    VehicleEquipmentCategoryFromJSONTyped,
    VehicleEquipmentCategoryToJSON,
    VehicleEquipmentCategoryToJSONTyped,
} from './VehicleEquipmentCategory';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';

/**
 * 
 * @export
 * @interface OptionUpdateDTOSalesPackage
 */
export interface OptionUpdateDTOSalesPackage {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionUpdateDTOSalesPackage
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionUpdateDTOSalesPackage
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {number}
     * @memberof OptionUpdateDTOSalesPackage
     */
    sortIndex: number;
    /**
     * 
     * @type {VehicleEquipmentCategory}
     * @memberof OptionUpdateDTOSalesPackage
     */
    category?: VehicleEquipmentCategory;
}



/**
 * Check if a given object implements the OptionUpdateDTOSalesPackage interface.
 */
export function instanceOfOptionUpdateDTOSalesPackage(value: object): value is OptionUpdateDTOSalesPackage {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('sortIndex' in value) || value['sortIndex'] === undefined) return false;
    return true;
}

export function OptionUpdateDTOSalesPackageFromJSON(json: any): OptionUpdateDTOSalesPackage {
    return OptionUpdateDTOSalesPackageFromJSONTyped(json, false);
}

export function OptionUpdateDTOSalesPackageFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionUpdateDTOSalesPackage {
    if (json == null) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
        'sortIndex': json['sortIndex'],
        'category': json['category'] == null ? undefined : VehicleEquipmentCategoryFromJSON(json['category']),
    };
}

export function OptionUpdateDTOSalesPackageToJSON(json: any): OptionUpdateDTOSalesPackage {
    return OptionUpdateDTOSalesPackageToJSONTyped(json, false);
}

export function OptionUpdateDTOSalesPackageToJSONTyped(value?: OptionUpdateDTOSalesPackage | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': BaseNameDTOToJSON(value['name']),
        'description': BaseNameDTOToJSON(value['description']),
        'sortIndex': value['sortIndex'],
        'category': VehicleEquipmentCategoryToJSON(value['category']),
    };
}


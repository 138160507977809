/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OrderTypeFilterOptionsDTO,
  OrderTypeSortField,
  PageDTOOrderTypeItemDTO,
  SortDirection,
} from '../models/index';
import {
    OrderTypeFilterOptionsDTOFromJSON,
    OrderTypeFilterOptionsDTOToJSON,
    OrderTypeSortFieldFromJSON,
    OrderTypeSortFieldToJSON,
    PageDTOOrderTypeItemDTOFromJSON,
    PageDTOOrderTypeItemDTOToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models/index';

export interface GetOrderTypeFilterOptionsRequest {
    languageTag: string;
}

export interface GetOrderTypesPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: OrderTypeSortField;
    sortDirection: SortDirection;
    search?: string;
    modelSeriesKeys?: Set<string>;
    modelGenerationKeys?: Set<string>;
    derivativeKeys?: Set<string>;
}

/**
 * 
 */
export class OrderTypeListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter OrderTypes
     */
    async getOrderTypeFilterOptionsRaw(requestParameters: GetOrderTypeFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderTypeFilterOptionsDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getOrderTypeFilterOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter OrderTypes
     */
    async getOrderTypeFilterOptions(requestParameters: GetOrderTypeFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderTypeFilterOptionsDTO> {
        const response = await this.getOrderTypeFilterOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get page of OrderTypes
     */
    async getOrderTypesPageRaw(requestParameters: GetOrderTypesPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOOrderTypeItemDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getOrderTypesPage().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getOrderTypesPage().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getOrderTypesPage().'
            );
        }

        if (requestParameters['sortField'] == null) {
            throw new runtime.RequiredError(
                'sortField',
                'Required parameter "sortField" was null or undefined when calling getOrderTypesPage().'
            );
        }

        if (requestParameters['sortDirection'] == null) {
            throw new runtime.RequiredError(
                'sortDirection',
                'Required parameter "sortDirection" was null or undefined when calling getOrderTypesPage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sortField'] != null) {
            queryParameters['sortField'] = requestParameters['sortField'];
        }

        if (requestParameters['sortDirection'] != null) {
            queryParameters['sortDirection'] = requestParameters['sortDirection'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['modelSeriesKeys'] != null) {
            queryParameters['modelSeriesKeys'] = requestParameters['modelSeriesKeys'];
        }

        if (requestParameters['modelGenerationKeys'] != null) {
            queryParameters['modelGenerationKeys'] = requestParameters['modelGenerationKeys'];
        }

        if (requestParameters['derivativeKeys'] != null) {
            queryParameters['derivativeKeys'] = requestParameters['derivativeKeys'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOOrderTypeItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of OrderTypes
     */
    async getOrderTypesPage(requestParameters: GetOrderTypesPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOOrderTypeItemDTO> {
        const response = await this.getOrderTypesPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

import { getEnumConverterGroup, getObjectURLParamConverter, stringConverterGroup } from "../../../../common/useURLSearchParamState"
import { BodyType, DerivativeFilterOptionsDTO, DerivativeItemDTO, DerivativeSortField, SortDirection } from "../../../../generated/pdsapi"
import { derivativeApi, DerivativeFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { DerivativeListColumnsConfig } from "./columns/DerivativeListColumns"
import { DerivativeListFilter } from "./filter/DerivativeListFilter"
import { DerivativeListFilterTagsConfig } from "./filter/DerivativeListFilterTagsConfig"
import { Route } from "react-router-dom"
import { routes } from "../../../../common/routes"

export const DerivativeProductListContainerConfig: ProductListContainerConfig<
    DerivativeItemDTO,
    DerivativeFilter,
    DerivativeFilterOptionsDTO,
    DerivativeSortField
> = {
    listName: "Derivatives",
    createItemLabel: "Add new Derivative",

    buildDetailsPath: (derivative) => routes.models.derivatives.details(derivative.key),
    buildCreatePath: routes.models.derivatives.create,
    buildSortPath: routes.models.derivatives.sort,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter<DerivativeFilter>({
        bodyTypes: getEnumConverterGroup(BodyType).optionalSet,
        derivativeCategoryKeys: stringConverterGroup.optionalSet,
        modelSeriesKeys: stringConverterGroup.optionalSet,
    }),
    defaultSorting: {
        sortField: DerivativeSortField.SORT_INDEX,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(DerivativeSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: derivativeApi.list.getPage,
    fetchFilterOptions: derivativeApi.list.getFilterOptions,

    defaultShowsFilter: false,
    Filter: DerivativeListFilter,
    filterTagsConfig: DerivativeListFilterTagsConfig,

    columnsConfig: DerivativeListColumnsConfig,
}

const DerivativeListContainer = getProductListContainer(DerivativeProductListContainerConfig)

export const DerivativeListRoute = <Route path={routes.models.derivatives.list()} Component={DerivativeListContainer} />

import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { DerivativeCategoryFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { DerivativeCategoryFilter } from "../../../../../pdsapi"
import { findOption } from "../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { filterTagsFor } from "../../../../../components/filteredpagedtable/filtertags/filterTagsFor"

export const DerivativeCategoryListFilterTagsConfig: TableFilterTagsConfig<DerivativeCategoryFilter, DerivativeCategoryFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => [
    ...filterTagsFor(filter, setFilter, "modelSeriesKeys", (modelSeriesKey) => findOption(filterOptions?.modelSeries, modelSeriesKey)?.label ?? modelSeriesKey),
]

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderTypeItemDTO
 */
export interface OrderTypeItemDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderTypeItemDTO
     */
    key: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeItemDTO
     */
    codes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrderTypeItemDTO
     */
    modelYear: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeItemDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeItemDTO
     */
    modelGenerationName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeItemDTO
     */
    derivativeName?: string;
}

/**
 * Check if a given object implements the OrderTypeItemDTO interface.
 */
export function instanceOfOrderTypeItemDTO(value: object): value is OrderTypeItemDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('codes' in value) || value['codes'] === undefined) return false;
    if (!('modelYear' in value) || value['modelYear'] === undefined) return false;
    return true;
}

export function OrderTypeItemDTOFromJSON(json: any): OrderTypeItemDTO {
    return OrderTypeItemDTOFromJSONTyped(json, false);
}

export function OrderTypeItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'codes': json['codes'],
        'modelYear': json['modelYear'],
        'name': json['name'] == null ? undefined : json['name'],
        'modelGenerationName': json['modelGenerationName'] == null ? undefined : json['modelGenerationName'],
        'derivativeName': json['derivativeName'] == null ? undefined : json['derivativeName'],
    };
}

export function OrderTypeItemDTOToJSON(json: any): OrderTypeItemDTO {
    return OrderTypeItemDTOToJSONTyped(json, false);
}

export function OrderTypeItemDTOToJSONTyped(value?: OrderTypeItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'codes': value['codes'],
        'modelYear': value['modelYear'],
        'name': value['name'],
        'modelGenerationName': value['modelGenerationName'],
        'derivativeName': value['derivativeName'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface CustomInteriorColorFilterOptionsDTO
 */
export interface CustomInteriorColorFilterOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof CustomInteriorColorFilterOptionsDTO
     */
    interiorColorTags: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the CustomInteriorColorFilterOptionsDTO interface.
 */
export function instanceOfCustomInteriorColorFilterOptionsDTO(value: object): value is CustomInteriorColorFilterOptionsDTO {
    if (!('interiorColorTags' in value) || value['interiorColorTags'] === undefined) return false;
    return true;
}

export function CustomInteriorColorFilterOptionsDTOFromJSON(json: any): CustomInteriorColorFilterOptionsDTO {
    return CustomInteriorColorFilterOptionsDTOFromJSONTyped(json, false);
}

export function CustomInteriorColorFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomInteriorColorFilterOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'interiorColorTags': ((json['interiorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function CustomInteriorColorFilterOptionsDTOToJSON(json: any): CustomInteriorColorFilterOptionsDTO {
    return CustomInteriorColorFilterOptionsDTOToJSONTyped(json, false);
}

export function CustomInteriorColorFilterOptionsDTOToJSONTyped(value?: CustomInteriorColorFilterOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'interiorColorTags': ((value['interiorColorTags'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


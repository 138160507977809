/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionTagTypeDTO } from './OptionTagTypeDTO';
import {
    OptionTagTypeDTOFromJSON,
    OptionTagTypeDTOFromJSONTyped,
    OptionTagTypeDTOToJSON,
    OptionTagTypeDTOToJSONTyped,
} from './OptionTagTypeDTO';

/**
 * 
 * @export
 * @interface SelectOptionDTOOptionTagTypeDTO
 */
export interface SelectOptionDTOOptionTagTypeDTO {
    /**
     * 
     * @type {OptionTagTypeDTO}
     * @memberof SelectOptionDTOOptionTagTypeDTO
     */
    key: OptionTagTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDTOOptionTagTypeDTO
     */
    label: string;
}



/**
 * Check if a given object implements the SelectOptionDTOOptionTagTypeDTO interface.
 */
export function instanceOfSelectOptionDTOOptionTagTypeDTO(value: object): value is SelectOptionDTOOptionTagTypeDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function SelectOptionDTOOptionTagTypeDTOFromJSON(json: any): SelectOptionDTOOptionTagTypeDTO {
    return SelectOptionDTOOptionTagTypeDTOFromJSONTyped(json, false);
}

export function SelectOptionDTOOptionTagTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectOptionDTOOptionTagTypeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': OptionTagTypeDTOFromJSON(json['key']),
        'label': json['label'],
    };
}

export function SelectOptionDTOOptionTagTypeDTOToJSON(json: any): SelectOptionDTOOptionTagTypeDTO {
    return SelectOptionDTOOptionTagTypeDTOToJSONTyped(json, false);
}

export function SelectOptionDTOOptionTagTypeDTOToJSONTyped(value?: SelectOptionDTOOptionTagTypeDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': OptionTagTypeDTOToJSON(value['key']),
        'label': value['label'],
    };
}


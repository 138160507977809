/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseInformationDTO,
  LocalizationsItemDTO,
  ModelSeriesAttributesDTO,
  ModelSeriesAttributesUpdateDTO,
  ModelSeriesLinksDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    ModelSeriesAttributesDTOFromJSON,
    ModelSeriesAttributesDTOToJSON,
    ModelSeriesAttributesUpdateDTOFromJSON,
    ModelSeriesAttributesUpdateDTOToJSON,
    ModelSeriesLinksDTOFromJSON,
    ModelSeriesLinksDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface DeleteModelSeriesRequest {
    key: string;
}

export interface GetAttributesOfModelSeriesRequest {
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfModelSeriesRequest {
    key: string;
    languageTag: string;
}

export interface GetLinksOfModelSeriesRequest {
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfNameOfModelSeriesRequest {
    key: string;
}

export interface UpdateAttributesOfModelSeriesRequest {
    key: string;
    modelSeriesAttributesUpdateDTO: ModelSeriesAttributesUpdateDTO;
}

export interface UpdateNameOfModelSeriesRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class ModelSeriesDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a ModelSeries by a given key
     */
    async deleteModelSeriesRaw(requestParameters: DeleteModelSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling deleteModelSeries().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-series/details/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a ModelSeries by a given key
     */
    async deleteModelSeries(requestParameters: DeleteModelSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteModelSeriesRaw(requestParameters, initOverrides);
    }

    /**
     * Get attributes of ModelSeries with given key
     */
    async getAttributesOfModelSeriesRaw(requestParameters: GetAttributesOfModelSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelSeriesAttributesDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getAttributesOfModelSeries().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getAttributesOfModelSeries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-series/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelSeriesAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of ModelSeries with given key
     */
    async getAttributesOfModelSeries(requestParameters: GetAttributesOfModelSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelSeriesAttributesDTO> {
        const response = await this.getAttributesOfModelSeriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get baseInformation of ModelSeries with given key
     */
    async getBaseInformationOfModelSeriesRaw(requestParameters: GetBaseInformationOfModelSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getBaseInformationOfModelSeries().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getBaseInformationOfModelSeries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-series/details/{key}/base-information`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of ModelSeries with given key
     */
    async getBaseInformationOfModelSeries(requestParameters: GetBaseInformationOfModelSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfModelSeriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get links of ModelSeries with given key
     */
    async getLinksOfModelSeriesRaw(requestParameters: GetLinksOfModelSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelSeriesLinksDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getLinksOfModelSeries().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getLinksOfModelSeries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-series/details/{key}/links`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelSeriesLinksDTOFromJSON(jsonValue));
    }

    /**
     * Get links of ModelSeries with given key
     */
    async getLinksOfModelSeries(requestParameters: GetLinksOfModelSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelSeriesLinksDTO> {
        const response = await this.getLinksOfModelSeriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get localizationsOfName of ModelSeries with given key
     */
    async getLocalizationsOfNameOfModelSeriesRaw(requestParameters: GetLocalizationsOfNameOfModelSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getLocalizationsOfNameOfModelSeries().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-series/details/{key}/name/localizations`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of ModelSeries with given key
     */
    async getLocalizationsOfNameOfModelSeries(requestParameters: GetLocalizationsOfNameOfModelSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfModelSeriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates attributes of an existing ModelSeries
     */
    async updateAttributesOfModelSeriesRaw(requestParameters: UpdateAttributesOfModelSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateAttributesOfModelSeries().'
            );
        }

        if (requestParameters['modelSeriesAttributesUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'modelSeriesAttributesUpdateDTO',
                'Required parameter "modelSeriesAttributesUpdateDTO" was null or undefined when calling updateAttributesOfModelSeries().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/model-series/details/{key}/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ModelSeriesAttributesUpdateDTOToJSON(requestParameters['modelSeriesAttributesUpdateDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing ModelSeries
     */
    async updateAttributesOfModelSeries(requestParameters: UpdateAttributesOfModelSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAttributesOfModelSeriesRaw(requestParameters, initOverrides);
    }

    /**
     * Updates name of an existing ModelSeries
     */
    async updateNameOfModelSeriesRaw(requestParameters: UpdateNameOfModelSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateNameOfModelSeries().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateNameOfModelSeries().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/model-series/details/{key}/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing ModelSeries
     */
    async updateNameOfModelSeries(requestParameters: UpdateNameOfModelSeriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNameOfModelSeriesRaw(requestParameters, initOverrides);
    }

}

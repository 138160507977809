import { PPopover, PText } from "@porsche-design-system/components-react"
import { FC, ReactNode } from "react"
import styled from "styled-components"

export interface LabeledContentWrapperProps {
    label?: string
    labelColor: "neutral-contrast-high" | "neutral-contrast-medium" | "neutral-contrast-low"
    optional?: boolean
    hint?: string
    children: ReactNode
}

export const LabeledContentWrapper: FC<LabeledContentWrapperProps> = (props) => (
    <Column>
        {props.label && (
            <div style={{ display: "flex", gap: "0.25em", userSelect: "none" }}>
                <PText size={"small"} color={props.labelColor}>
                    {props.label}
                </PText>
                {props.optional && <PText color={props.labelColor}>(optional)</PText>}
                {props.hint && (
                    <PPopover direction={"top"} style={{ userSelect: "text" }}>
                        <PText color={props.labelColor}>{props.hint}</PText>
                    </PPopover>
                )}
            </div>
        )}
        {props.children}
    </Column>
)

const Column = styled("div")`
    width: 100%;
    display: flex;
    flex-direction: column;
`

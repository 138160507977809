/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ColorCreateRequestDTO,
  ColorKeyDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    ColorCreateRequestDTOFromJSON,
    ColorCreateRequestDTOToJSON,
    ColorKeyDTOFromJSON,
    ColorKeyDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface CreateColorRequest {
    colorCreateRequestDTO: ColorCreateRequestDTO;
}

/**
 * 
 */
export class ColorCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new Color
     */
    async createColorRaw(requestParameters: CreateColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ColorKeyDTO>> {
        if (requestParameters['colorCreateRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'colorCreateRequestDTO',
                'Required parameter "colorCreateRequestDTO" was null or undefined when calling createColor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/colors/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ColorCreateRequestDTOToJSON(requestParameters['colorCreateRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ColorKeyDTOFromJSON(jsonValue));
    }

    /**
     * Create a new Color
     */
    async createColor(requestParameters: CreateColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ColorKeyDTO> {
        const response = await this.createColorRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

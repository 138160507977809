/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionalSelectDTOExteriorColorType } from './OptionalSelectDTOExteriorColorType';
import {
    OptionalSelectDTOExteriorColorTypeFromJSON,
    OptionalSelectDTOExteriorColorTypeFromJSONTyped,
    OptionalSelectDTOExteriorColorTypeToJSON,
    OptionalSelectDTOExteriorColorTypeToJSONTyped,
} from './OptionalSelectDTOExteriorColorType';
import type { OptionalSelectDTOString } from './OptionalSelectDTOString';
import {
    OptionalSelectDTOStringFromJSON,
    OptionalSelectDTOStringFromJSONTyped,
    OptionalSelectDTOStringToJSON,
    OptionalSelectDTOStringToJSONTyped,
} from './OptionalSelectDTOString';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesDTOExteriorColorValue
 */
export interface OptionCustomizationAttributesDTOExteriorColorValue {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationAttributesDTOExteriorColorValue
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationAttributesDTOExteriorColorValue
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOExteriorColorValue
     */
    hexCode: string;
    /**
     * 
     * @type {OptionalSelectDTOString}
     * @memberof OptionCustomizationAttributesDTOExteriorColorValue
     */
    tag: OptionalSelectDTOString;
    /**
     * 
     * @type {OptionalSelectDTOExteriorColorType}
     * @memberof OptionCustomizationAttributesDTOExteriorColorValue
     */
    exteriorColorType: OptionalSelectDTOExteriorColorType;
}

/**
 * Check if a given object implements the OptionCustomizationAttributesDTOExteriorColorValue interface.
 */
export function instanceOfOptionCustomizationAttributesDTOExteriorColorValue(value: object): value is OptionCustomizationAttributesDTOExteriorColorValue {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('hexCode' in value) || value['hexCode'] === undefined) return false;
    if (!('tag' in value) || value['tag'] === undefined) return false;
    if (!('exteriorColorType' in value) || value['exteriorColorType'] === undefined) return false;
    return true;
}

export function OptionCustomizationAttributesDTOExteriorColorValueFromJSON(json: any): OptionCustomizationAttributesDTOExteriorColorValue {
    return OptionCustomizationAttributesDTOExteriorColorValueFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOExteriorColorValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOExteriorColorValue {
    if (json == null) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
        'hexCode': json['hexCode'],
        'tag': OptionalSelectDTOStringFromJSON(json['tag']),
        'exteriorColorType': OptionalSelectDTOExteriorColorTypeFromJSON(json['exteriorColorType']),
    };
}

export function OptionCustomizationAttributesDTOExteriorColorValueToJSON(json: any): OptionCustomizationAttributesDTOExteriorColorValue {
    return OptionCustomizationAttributesDTOExteriorColorValueToJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOExteriorColorValueToJSONTyped(value?: OptionCustomizationAttributesDTOExteriorColorValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': BaseNameDTOToJSON(value['name']),
        'description': BaseNameDTOToJSON(value['description']),
        'hexCode': value['hexCode'],
        'tag': OptionalSelectDTOStringToJSON(value['tag']),
        'exteriorColorType': OptionalSelectDTOExteriorColorTypeToJSON(value['exteriorColorType']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTONumberOfSeats } from './SelectOptionDTONumberOfSeats';
import {
    SelectOptionDTONumberOfSeatsFromJSON,
    SelectOptionDTONumberOfSeatsFromJSONTyped,
    SelectOptionDTONumberOfSeatsToJSON,
    SelectOptionDTONumberOfSeatsToJSONTyped,
} from './SelectOptionDTONumberOfSeats';

/**
 * 
 * @export
 * @interface OptionalSelectDTONumberOfSeats
 */
export interface OptionalSelectDTONumberOfSeats {
    /**
     * 
     * @type {SelectOptionDTONumberOfSeats}
     * @memberof OptionalSelectDTONumberOfSeats
     */
    value?: SelectOptionDTONumberOfSeats;
    /**
     * 
     * @type {Array<SelectOptionDTONumberOfSeats>}
     * @memberof OptionalSelectDTONumberOfSeats
     */
    options: Array<SelectOptionDTONumberOfSeats>;
}

/**
 * Check if a given object implements the OptionalSelectDTONumberOfSeats interface.
 */
export function instanceOfOptionalSelectDTONumberOfSeats(value: object): value is OptionalSelectDTONumberOfSeats {
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function OptionalSelectDTONumberOfSeatsFromJSON(json: any): OptionalSelectDTONumberOfSeats {
    return OptionalSelectDTONumberOfSeatsFromJSONTyped(json, false);
}

export function OptionalSelectDTONumberOfSeatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalSelectDTONumberOfSeats {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : SelectOptionDTONumberOfSeatsFromJSON(json['value']),
        'options': ((json['options'] as Array<any>).map(SelectOptionDTONumberOfSeatsFromJSON)),
    };
}

export function OptionalSelectDTONumberOfSeatsToJSON(json: any): OptionalSelectDTONumberOfSeats {
    return OptionalSelectDTONumberOfSeatsToJSONTyped(json, false);
}

export function OptionalSelectDTONumberOfSeatsToJSONTyped(value?: OptionalSelectDTONumberOfSeats | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': SelectOptionDTONumberOfSeatsToJSON(value['value']),
        'options': ((value['options'] as Array<any>).map(SelectOptionDTONumberOfSeatsToJSON)),
    };
}


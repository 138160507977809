/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomExteriorColorCreateOptionsDTO,
  CustomExteriorColorCreateRequestDTO,
  KeyDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    CustomExteriorColorCreateOptionsDTOFromJSON,
    CustomExteriorColorCreateOptionsDTOToJSON,
    CustomExteriorColorCreateRequestDTOFromJSON,
    CustomExteriorColorCreateRequestDTOToJSON,
    KeyDTOFromJSON,
    KeyDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface CreateCustomExteriorColorRequest {
    customExteriorColorCreateRequestDTO: CustomExteriorColorCreateRequestDTO;
}

export interface GetCustomExteriorColorCreateOptionsRequest {
    languageTag: string;
}

/**
 * 
 */
export class CustomExteriorColorCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new CustomExteriorColor
     */
    async createCustomExteriorColorRaw(requestParameters: CreateCustomExteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KeyDTO>> {
        if (requestParameters['customExteriorColorCreateRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'customExteriorColorCreateRequestDTO',
                'Required parameter "customExteriorColorCreateRequestDTO" was null or undefined when calling createCustomExteriorColor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/custom-exterior-colors/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomExteriorColorCreateRequestDTOToJSON(requestParameters['customExteriorColorCreateRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KeyDTOFromJSON(jsonValue));
    }

    /**
     * Create a new CustomExteriorColor
     */
    async createCustomExteriorColor(requestParameters: CreateCustomExteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KeyDTO> {
        const response = await this.createCustomExteriorColorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get select options to create CustomExteriorColors
     */
    async getCustomExteriorColorCreateOptionsRaw(requestParameters: GetCustomExteriorColorCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomExteriorColorCreateOptionsDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getCustomExteriorColorCreateOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-exterior-colors/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomExteriorColorCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create CustomExteriorColors
     */
    async getCustomExteriorColorCreateOptions(requestParameters: GetCustomExteriorColorCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomExteriorColorCreateOptionsDTO> {
        const response = await this.getCustomExteriorColorCreateOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

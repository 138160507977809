/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  KeyDTO,
  OrderTypeCreateOptionsDTO,
  OrderTypeCreateRequestDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    KeyDTOFromJSON,
    KeyDTOToJSON,
    OrderTypeCreateOptionsDTOFromJSON,
    OrderTypeCreateOptionsDTOToJSON,
    OrderTypeCreateRequestDTOFromJSON,
    OrderTypeCreateRequestDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface CreateOrderTypeRequest {
    orderTypeCreateRequestDTO: OrderTypeCreateRequestDTO;
}

export interface GetOrderTypeCreateOptionsRequest {
    languageTag: string;
}

/**
 * 
 */
export class OrderTypeCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new OrderType
     */
    async createOrderTypeRaw(requestParameters: CreateOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KeyDTO>> {
        if (requestParameters['orderTypeCreateRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'orderTypeCreateRequestDTO',
                'Required parameter "orderTypeCreateRequestDTO" was null or undefined when calling createOrderType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderTypeCreateRequestDTOToJSON(requestParameters['orderTypeCreateRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KeyDTOFromJSON(jsonValue));
    }

    /**
     * Create a new OrderType
     */
    async createOrderType(requestParameters: CreateOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KeyDTO> {
        const response = await this.createOrderTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get select options to create OrderTypes
     */
    async getOrderTypeCreateOptionsRaw(requestParameters: GetOrderTypeCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderTypeCreateOptionsDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getOrderTypeCreateOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create OrderTypes
     */
    async getOrderTypeCreateOptions(requestParameters: GetOrderTypeCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderTypeCreateOptionsDTO> {
        const response = await this.getOrderTypeCreateOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InteriorColorTileWithLabelDTO
 */
export interface InteriorColorTileWithLabelDTO {
    /**
     * 
     * @type {string}
     * @memberof InteriorColorTileWithLabelDTO
     */
    seatColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorColorTileWithLabelDTO
     */
    seatStitchingColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorColorTileWithLabelDTO
     */
    cockpitColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorColorTileWithLabelDTO
     */
    cockpitStitchingColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof InteriorColorTileWithLabelDTO
     */
    label: string;
}

/**
 * Check if a given object implements the InteriorColorTileWithLabelDTO interface.
 */
export function instanceOfInteriorColorTileWithLabelDTO(value: object): value is InteriorColorTileWithLabelDTO {
    if (!('seatColorHexCode' in value) || value['seatColorHexCode'] === undefined) return false;
    if (!('seatStitchingColorHexCode' in value) || value['seatStitchingColorHexCode'] === undefined) return false;
    if (!('cockpitColorHexCode' in value) || value['cockpitColorHexCode'] === undefined) return false;
    if (!('cockpitStitchingColorHexCode' in value) || value['cockpitStitchingColorHexCode'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function InteriorColorTileWithLabelDTOFromJSON(json: any): InteriorColorTileWithLabelDTO {
    return InteriorColorTileWithLabelDTOFromJSONTyped(json, false);
}

export function InteriorColorTileWithLabelDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InteriorColorTileWithLabelDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'seatColorHexCode': json['seatColorHexCode'],
        'seatStitchingColorHexCode': json['seatStitchingColorHexCode'],
        'cockpitColorHexCode': json['cockpitColorHexCode'],
        'cockpitStitchingColorHexCode': json['cockpitStitchingColorHexCode'],
        'label': json['label'],
    };
}

export function InteriorColorTileWithLabelDTOToJSON(json: any): InteriorColorTileWithLabelDTO {
    return InteriorColorTileWithLabelDTOToJSONTyped(json, false);
}

export function InteriorColorTileWithLabelDTOToJSONTyped(value?: InteriorColorTileWithLabelDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'seatColorHexCode': value['seatColorHexCode'],
        'seatStitchingColorHexCode': value['seatStitchingColorHexCode'],
        'cockpitColorHexCode': value['cockpitColorHexCode'],
        'cockpitStitchingColorHexCode': value['cockpitStitchingColorHexCode'],
        'label': value['label'],
    };
}


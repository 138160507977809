/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { OptionTagAttributesUpdateDTOEquipment } from './OptionTagAttributesUpdateDTOEquipment';
import {
    instanceOfOptionTagAttributesUpdateDTOEquipment,
    OptionTagAttributesUpdateDTOEquipmentFromJSON,
    OptionTagAttributesUpdateDTOEquipmentFromJSONTyped,
    OptionTagAttributesUpdateDTOEquipmentToJSON,
} from './OptionTagAttributesUpdateDTOEquipment';
import type { OptionTagAttributesUpdateDTOExteriorColor } from './OptionTagAttributesUpdateDTOExteriorColor';
import {
    instanceOfOptionTagAttributesUpdateDTOExteriorColor,
    OptionTagAttributesUpdateDTOExteriorColorFromJSON,
    OptionTagAttributesUpdateDTOExteriorColorFromJSONTyped,
    OptionTagAttributesUpdateDTOExteriorColorToJSON,
} from './OptionTagAttributesUpdateDTOExteriorColor';
import type { OptionTagAttributesUpdateDTOInteriorColor } from './OptionTagAttributesUpdateDTOInteriorColor';
import {
    instanceOfOptionTagAttributesUpdateDTOInteriorColor,
    OptionTagAttributesUpdateDTOInteriorColorFromJSON,
    OptionTagAttributesUpdateDTOInteriorColorFromJSONTyped,
    OptionTagAttributesUpdateDTOInteriorColorToJSON,
} from './OptionTagAttributesUpdateDTOInteriorColor';
import type { OptionTagAttributesUpdateDTORoofColor } from './OptionTagAttributesUpdateDTORoofColor';
import {
    instanceOfOptionTagAttributesUpdateDTORoofColor,
    OptionTagAttributesUpdateDTORoofColorFromJSON,
    OptionTagAttributesUpdateDTORoofColorFromJSONTyped,
    OptionTagAttributesUpdateDTORoofColorToJSON,
} from './OptionTagAttributesUpdateDTORoofColor';

/**
 * @type OptionTagAttributesUpdateDTOOptionTag
 * 
 * @export
 */
export type OptionTagAttributesUpdateDTOOptionTag = { type: 'Equipment' } & OptionTagAttributesUpdateDTOEquipment | { type: 'ExteriorColor' } & OptionTagAttributesUpdateDTOExteriorColor | { type: 'InteriorColor' } & OptionTagAttributesUpdateDTOInteriorColor | { type: 'RoofColor' } & OptionTagAttributesUpdateDTORoofColor;

export function OptionTagAttributesUpdateDTOOptionTagFromJSON(json: any): OptionTagAttributesUpdateDTOOptionTag {
    return OptionTagAttributesUpdateDTOOptionTagFromJSONTyped(json, false);
}

export function OptionTagAttributesUpdateDTOOptionTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagAttributesUpdateDTOOptionTag {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return Object.assign({}, OptionTagAttributesUpdateDTOEquipmentFromJSONTyped(json, true), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionTagAttributesUpdateDTOExteriorColorFromJSONTyped(json, true), { type: 'ExteriorColor' } as const);
        case 'InteriorColor':
            return Object.assign({}, OptionTagAttributesUpdateDTOInteriorColorFromJSONTyped(json, true), { type: 'InteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionTagAttributesUpdateDTORoofColorFromJSONTyped(json, true), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionTagAttributesUpdateDTOOptionTag exists with 'type=${json['type']}'`);
    }
}

export function OptionTagAttributesUpdateDTOOptionTagToJSON(json: any): any {
    return OptionTagAttributesUpdateDTOOptionTagToJSONTyped(json, false);
}

export function OptionTagAttributesUpdateDTOOptionTagToJSONTyped(value?: OptionTagAttributesUpdateDTOOptionTag | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'Equipment':
            return Object.assign({}, OptionTagAttributesUpdateDTOEquipmentToJSON(value), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionTagAttributesUpdateDTOExteriorColorToJSON(value), { type: 'ExteriorColor' } as const);
        case 'InteriorColor':
            return Object.assign({}, OptionTagAttributesUpdateDTOInteriorColorToJSON(value), { type: 'InteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionTagAttributesUpdateDTORoofColorToJSON(value), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionTagAttributesUpdateDTOOptionTag exists with 'type=${value['type']}'`);
    }

}


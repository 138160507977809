/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO } from './OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO';
import {
    OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTOFromJSON,
    OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTOFromJSONTyped,
    OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTOToJSON,
    OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTOToJSONTyped,
} from './OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO';
import type { LocalizationsItemDTO } from './LocalizationsItemDTO';
import {
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOFromJSONTyped,
    LocalizationsItemDTOToJSON,
    LocalizationsItemDTOToJSONTyped,
} from './LocalizationsItemDTO';

/**
 * 
 * @export
 * @interface OptionCustomizationValuesDTOMapStringLocalizationsItemDTO
 */
export interface OptionCustomizationValuesDTOMapStringLocalizationsItemDTO {
    /**
     * 
     * @type {{ [key: string]: LocalizationsItemDTO; }}
     * @memberof OptionCustomizationValuesDTOMapStringLocalizationsItemDTO
     */
    defaultValue: { [key: string]: LocalizationsItemDTO; };
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO>}
     * @memberof OptionCustomizationValuesDTOMapStringLocalizationsItemDTO
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTO>;
}

/**
 * Check if a given object implements the OptionCustomizationValuesDTOMapStringLocalizationsItemDTO interface.
 */
export function instanceOfOptionCustomizationValuesDTOMapStringLocalizationsItemDTO(value: object): value is OptionCustomizationValuesDTOMapStringLocalizationsItemDTO {
    if (!('defaultValue' in value) || value['defaultValue'] === undefined) return false;
    if (!('conditionalValues' in value) || value['conditionalValues'] === undefined) return false;
    return true;
}

export function OptionCustomizationValuesDTOMapStringLocalizationsItemDTOFromJSON(json: any): OptionCustomizationValuesDTOMapStringLocalizationsItemDTO {
    return OptionCustomizationValuesDTOMapStringLocalizationsItemDTOFromJSONTyped(json, false);
}

export function OptionCustomizationValuesDTOMapStringLocalizationsItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationValuesDTOMapStringLocalizationsItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultValue': (mapValues(json['defaultValue'], LocalizationsItemDTOFromJSON)),
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTOFromJSON)),
    };
}

export function OptionCustomizationValuesDTOMapStringLocalizationsItemDTOToJSON(json: any): OptionCustomizationValuesDTOMapStringLocalizationsItemDTO {
    return OptionCustomizationValuesDTOMapStringLocalizationsItemDTOToJSONTyped(json, false);
}

export function OptionCustomizationValuesDTOMapStringLocalizationsItemDTOToJSONTyped(value?: OptionCustomizationValuesDTOMapStringLocalizationsItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultValue': (mapValues(value['defaultValue'], LocalizationsItemDTOToJSON)),
        'conditionalValues': ((value['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOMapStringLocalizationsItemDTOToJSON)),
    };
}


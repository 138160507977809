/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ModelSeriesSortField,
  PageDTOModelSeriesItemDTO,
  SortDirection,
} from '../models/index';
import {
    ModelSeriesSortFieldFromJSON,
    ModelSeriesSortFieldToJSON,
    PageDTOModelSeriesItemDTOFromJSON,
    PageDTOModelSeriesItemDTOToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models/index';

export interface GetModelSeriesPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: ModelSeriesSortField;
    sortDirection: SortDirection;
    search?: string;
}

/**
 * 
 */
export class ModelSeriesListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get page of ModelSeries
     */
    async getModelSeriesPageRaw(requestParameters: GetModelSeriesPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOModelSeriesItemDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getModelSeriesPage().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getModelSeriesPage().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getModelSeriesPage().'
            );
        }

        if (requestParameters['sortField'] == null) {
            throw new runtime.RequiredError(
                'sortField',
                'Required parameter "sortField" was null or undefined when calling getModelSeriesPage().'
            );
        }

        if (requestParameters['sortDirection'] == null) {
            throw new runtime.RequiredError(
                'sortDirection',
                'Required parameter "sortDirection" was null or undefined when calling getModelSeriesPage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sortField'] != null) {
            queryParameters['sortField'] = requestParameters['sortField'];
        }

        if (requestParameters['sortDirection'] != null) {
            queryParameters['sortDirection'] = requestParameters['sortDirection'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-series/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOModelSeriesItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of ModelSeries
     */
    async getModelSeriesPage(requestParameters: GetModelSeriesPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOModelSeriesItemDTO> {
        const response = await this.getModelSeriesPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

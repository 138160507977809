/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TechnicalDataAttributeDataValueObject } from './TechnicalDataAttributeDataValueObject';
import {
    TechnicalDataAttributeDataValueObjectFromJSON,
    TechnicalDataAttributeDataValueObjectFromJSONTyped,
    TechnicalDataAttributeDataValueObjectToJSON,
    TechnicalDataAttributeDataValueObjectToJSONTyped,
} from './TechnicalDataAttributeDataValueObject';

/**
 * 
 * @export
 * @interface MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue
 */
export interface MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue {
    /**
     * 
     * @type {TechnicalDataAttributeDataValueObject}
     * @memberof MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue
     */
    globalValue?: TechnicalDataAttributeDataValueObject;
    /**
     * 
     * @type {TechnicalDataAttributeDataValueObject}
     * @memberof MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue
     */
    specificValue: TechnicalDataAttributeDataValueObject;
}

/**
 * Check if a given object implements the MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue interface.
 */
export function instanceOfMarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue(value: object): value is MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue {
    if (!('specificValue' in value) || value['specificValue'] === undefined) return false;
    return true;
}

export function MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValueFromJSON(json: any): MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue {
    return MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValueFromJSONTyped(json, false);
}

export function MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue {
    if (json == null) {
        return json;
    }
    return {
        
        'globalValue': json['globalValue'] == null ? undefined : TechnicalDataAttributeDataValueObjectFromJSON(json['globalValue']),
        'specificValue': TechnicalDataAttributeDataValueObjectFromJSON(json['specificValue']),
    };
}

export function MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValueToJSON(json: any): MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue {
    return MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValueToJSONTyped(json, false);
}

export function MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValueToJSONTyped(value?: MarketplaceSpecificOrderTypeTechnicalDataAttributeDTOMarketplaceSpecificValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'globalValue': TechnicalDataAttributeDataValueObjectToJSON(value['globalValue']),
        'specificValue': TechnicalDataAttributeDataValueObjectToJSON(value['specificValue']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptionCustomizationItemDTOEquipment
 */
export interface OptionCustomizationItemDTOEquipment {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTOEquipment
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTOEquipment
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationItemDTOEquipment
     */
    modelGenerationNames: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationItemDTOEquipment
     */
    equipmentTagNames: Array<string>;
}

/**
 * Check if a given object implements the OptionCustomizationItemDTOEquipment interface.
 */
export function instanceOfOptionCustomizationItemDTOEquipment(value: object): value is OptionCustomizationItemDTOEquipment {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelGenerationNames' in value) || value['modelGenerationNames'] === undefined) return false;
    if (!('equipmentTagNames' in value) || value['equipmentTagNames'] === undefined) return false;
    return true;
}

export function OptionCustomizationItemDTOEquipmentFromJSON(json: any): OptionCustomizationItemDTOEquipment {
    return OptionCustomizationItemDTOEquipmentFromJSONTyped(json, false);
}

export function OptionCustomizationItemDTOEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationItemDTOEquipment {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'optionCode': json['optionCode'],
        'modelGenerationNames': json['modelGenerationNames'],
        'equipmentTagNames': json['equipmentTagNames'],
    };
}

export function OptionCustomizationItemDTOEquipmentToJSON(json: any): OptionCustomizationItemDTOEquipment {
    return OptionCustomizationItemDTOEquipmentToJSONTyped(json, false);
}

export function OptionCustomizationItemDTOEquipmentToJSONTyped(value?: OptionCustomizationItemDTOEquipment | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'optionCode': value['optionCode'],
        'modelGenerationNames': value['modelGenerationNames'],
        'equipmentTagNames': value['equipmentTagNames'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PageDTOIncompleteCustomizationDTO,
  PageDTOIncompleteOrderTypeDTO,
  PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization,
  PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization,
  PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization,
  PageDTOUnmaintainedCustomColorDTO,
} from '../models/index';
import {
    PageDTOIncompleteCustomizationDTOFromJSON,
    PageDTOIncompleteCustomizationDTOToJSON,
    PageDTOIncompleteOrderTypeDTOFromJSON,
    PageDTOIncompleteOrderTypeDTOToJSON,
    PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomizationFromJSON,
    PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomizationToJSON,
    PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSON,
    PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationToJSON,
    PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSON,
    PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationToJSON,
    PageDTOUnmaintainedCustomColorDTOFromJSON,
    PageDTOUnmaintainedCustomColorDTOToJSON,
} from '../models/index';

export interface GetIncompleteExteriorColorCustomizationsRequest {
    languageTag: string;
    offset: number;
    size: number;
}

export interface GetIncompleteInteriorCustomizationsRequest {
    languageTag: string;
    offset: number;
    size: number;
}

export interface GetIncompleteOrderTypesRequest {
    offset: number;
    size: number;
}

export interface GetMissingExteriorColorCustomizationsRequest {
    languageTag: string;
    offset: number;
    size: number;
}

export interface GetMissingInteriorCustomizationsRequest {
    languageTag: string;
    offset: number;
    size: number;
}

export interface GetMissingRoofColorCustomizationsRequest {
    languageTag: string;
    offset: number;
    size: number;
}

export interface GetUnmaintainedCustomExteriorColorsRequest {
    offset: number;
    size: number;
}

export interface GetUnmaintainedCustomInteriorColorsRequest {
    offset: number;
    size: number;
}

/**
 * 
 */
export class DashboardWebControllerApi extends runtime.BaseAPI {

    /**
     * Lists all incompleteExteriorColorCustomizations
     */
    async getIncompleteExteriorColorCustomizationsRaw(requestParameters: GetIncompleteExteriorColorCustomizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOIncompleteCustomizationDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getIncompleteExteriorColorCustomizations().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getIncompleteExteriorColorCustomizations().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getIncompleteExteriorColorCustomizations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/incomplete-exterior-color-customizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOIncompleteCustomizationDTOFromJSON(jsonValue));
    }

    /**
     * Lists all incompleteExteriorColorCustomizations
     */
    async getIncompleteExteriorColorCustomizations(requestParameters: GetIncompleteExteriorColorCustomizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOIncompleteCustomizationDTO> {
        const response = await this.getIncompleteExteriorColorCustomizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists all incompleteInteriorCustomizations
     */
    async getIncompleteInteriorCustomizationsRaw(requestParameters: GetIncompleteInteriorCustomizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOIncompleteCustomizationDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getIncompleteInteriorCustomizations().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getIncompleteInteriorCustomizations().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getIncompleteInteriorCustomizations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/incomplete-interior-customizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOIncompleteCustomizationDTOFromJSON(jsonValue));
    }

    /**
     * Lists all incompleteInteriorCustomizations
     */
    async getIncompleteInteriorCustomizations(requestParameters: GetIncompleteInteriorCustomizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOIncompleteCustomizationDTO> {
        const response = await this.getIncompleteInteriorCustomizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists all incompleteOrderTypes
     */
    async getIncompleteOrderTypesRaw(requestParameters: GetIncompleteOrderTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOIncompleteOrderTypeDTO>> {
        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getIncompleteOrderTypes().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getIncompleteOrderTypes().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/incomplete-order-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOIncompleteOrderTypeDTOFromJSON(jsonValue));
    }

    /**
     * Lists all incompleteOrderTypes
     */
    async getIncompleteOrderTypes(requestParameters: GetIncompleteOrderTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOIncompleteOrderTypeDTO> {
        const response = await this.getIncompleteOrderTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists all missingExteriorColorCustomizations
     */
    async getMissingExteriorColorCustomizationsRaw(requestParameters: GetMissingExteriorColorCustomizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getMissingExteriorColorCustomizations().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getMissingExteriorColorCustomizations().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getMissingExteriorColorCustomizations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/missing-exterior-color-customizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomizationFromJSON(jsonValue));
    }

    /**
     * Lists all missingExteriorColorCustomizations
     */
    async getMissingExteriorColorCustomizations(requestParameters: GetMissingExteriorColorCustomizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization> {
        const response = await this.getMissingExteriorColorCustomizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists all missingInteriorCustomizations
     */
    async getMissingInteriorCustomizationsRaw(requestParameters: GetMissingInteriorCustomizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getMissingInteriorCustomizations().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getMissingInteriorCustomizations().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getMissingInteriorCustomizations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/missing-interior-customizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSON(jsonValue));
    }

    /**
     * Lists all missingInteriorCustomizations
     */
    async getMissingInteriorCustomizations(requestParameters: GetMissingInteriorCustomizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization> {
        const response = await this.getMissingInteriorCustomizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists all missingRoofColorCustomizations
     */
    async getMissingRoofColorCustomizationsRaw(requestParameters: GetMissingRoofColorCustomizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getMissingRoofColorCustomizations().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getMissingRoofColorCustomizations().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getMissingRoofColorCustomizations().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/missing-roof-color-customizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSON(jsonValue));
    }

    /**
     * Lists all missingRoofColorCustomizations
     */
    async getMissingRoofColorCustomizations(requestParameters: GetMissingRoofColorCustomizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization> {
        const response = await this.getMissingRoofColorCustomizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists all unmaintainedCustomExteriorColors
     */
    async getUnmaintainedCustomExteriorColorsRaw(requestParameters: GetUnmaintainedCustomExteriorColorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOUnmaintainedCustomColorDTO>> {
        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getUnmaintainedCustomExteriorColors().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getUnmaintainedCustomExteriorColors().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/unmaintained-custom-exterior-colors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOUnmaintainedCustomColorDTOFromJSON(jsonValue));
    }

    /**
     * Lists all unmaintainedCustomExteriorColors
     */
    async getUnmaintainedCustomExteriorColors(requestParameters: GetUnmaintainedCustomExteriorColorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOUnmaintainedCustomColorDTO> {
        const response = await this.getUnmaintainedCustomExteriorColorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists all unmaintainedCustomInteriorColors
     */
    async getUnmaintainedCustomInteriorColorsRaw(requestParameters: GetUnmaintainedCustomInteriorColorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOUnmaintainedCustomColorDTO>> {
        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getUnmaintainedCustomInteriorColors().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getUnmaintainedCustomInteriorColors().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/dashboard/unmaintained-custom-interior-colors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOUnmaintainedCustomColorDTOFromJSON(jsonValue));
    }

    /**
     * Lists all unmaintainedCustomInteriorColors
     */
    async getUnmaintainedCustomInteriorColors(requestParameters: GetUnmaintainedCustomInteriorColorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOUnmaintainedCustomColorDTO> {
        const response = await this.getUnmaintainedCustomInteriorColorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

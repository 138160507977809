/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface DerivativeLinksDTO
 */
export interface DerivativeLinksDTO {
    /**
     * 
     * @type {SelectOptionDTOString}
     * @memberof DerivativeLinksDTO
     */
    modelSeries: SelectOptionDTOString;
}

/**
 * Check if a given object implements the DerivativeLinksDTO interface.
 */
export function instanceOfDerivativeLinksDTO(value: object): value is DerivativeLinksDTO {
    if (!('modelSeries' in value) || value['modelSeries'] === undefined) return false;
    return true;
}

export function DerivativeLinksDTOFromJSON(json: any): DerivativeLinksDTO {
    return DerivativeLinksDTOFromJSONTyped(json, false);
}

export function DerivativeLinksDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DerivativeLinksDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'modelSeries': SelectOptionDTOStringFromJSON(json['modelSeries']),
    };
}

export function DerivativeLinksDTOToJSON(json: any): DerivativeLinksDTO {
    return DerivativeLinksDTOToJSONTyped(json, false);
}

export function DerivativeLinksDTOToJSONTyped(value?: DerivativeLinksDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'modelSeries': SelectOptionDTOStringToJSON(value['modelSeries']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';

/**
 * 
 * @export
 * @interface OptionTagAttributesUpdateDTOInteriorColor
 */
export interface OptionTagAttributesUpdateDTOInteriorColor {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionTagAttributesUpdateDTOInteriorColor
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {boolean}
     * @memberof OptionTagAttributesUpdateDTOInteriorColor
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof OptionTagAttributesUpdateDTOInteriorColor
     */
    hexCode: string;
}

/**
 * Check if a given object implements the OptionTagAttributesUpdateDTOInteriorColor interface.
 */
export function instanceOfOptionTagAttributesUpdateDTOInteriorColor(value: object): value is OptionTagAttributesUpdateDTOInteriorColor {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('hexCode' in value) || value['hexCode'] === undefined) return false;
    return true;
}

export function OptionTagAttributesUpdateDTOInteriorColorFromJSON(json: any): OptionTagAttributesUpdateDTOInteriorColor {
    return OptionTagAttributesUpdateDTOInteriorColorFromJSONTyped(json, false);
}

export function OptionTagAttributesUpdateDTOInteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagAttributesUpdateDTOInteriorColor {
    if (json == null) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'active': json['active'],
        'hexCode': json['hexCode'],
    };
}

export function OptionTagAttributesUpdateDTOInteriorColorToJSON(json: any): OptionTagAttributesUpdateDTOInteriorColor {
    return OptionTagAttributesUpdateDTOInteriorColorToJSONTyped(json, false);
}

export function OptionTagAttributesUpdateDTOInteriorColorToJSONTyped(value?: OptionTagAttributesUpdateDTOInteriorColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': BaseNameDTOToJSON(value['name']),
        'active': value['active'],
        'hexCode': value['hexCode'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OptionLocalizationItemDTO,
  OptionTypeDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    OptionLocalizationItemDTOFromJSON,
    OptionLocalizationItemDTOToJSON,
    OptionTypeDTOFromJSON,
    OptionTypeDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface GetLocalizationsOfNameOfOptionRequest {
    orderTypeKey: string;
    optionCode: string;
    type: OptionTypeDTO;
}

export interface UpdateNameOfOptionRequest {
    orderTypeKey: string;
    optionCode: string;
    type: OptionTypeDTO;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class OrderTypeOptionNameDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get localizationsOfName of Option with given key
     */
    async getLocalizationsOfNameOfOptionRaw(requestParameters: GetLocalizationsOfNameOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: OptionLocalizationItemDTO; }>> {
        if (requestParameters['orderTypeKey'] == null) {
            throw new runtime.RequiredError(
                'orderTypeKey',
                'Required parameter "orderTypeKey" was null or undefined when calling getLocalizationsOfNameOfOption().'
            );
        }

        if (requestParameters['optionCode'] == null) {
            throw new runtime.RequiredError(
                'optionCode',
                'Required parameter "optionCode" was null or undefined when calling getLocalizationsOfNameOfOption().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getLocalizationsOfNameOfOption().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['optionCode'] != null) {
            queryParameters['optionCode'] = requestParameters['optionCode'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/option/name/localizations`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters['orderTypeKey']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, OptionLocalizationItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of Option with given key
     */
    async getLocalizationsOfNameOfOption(requestParameters: GetLocalizationsOfNameOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: OptionLocalizationItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfOptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates name of an existing Option
     */
    async updateNameOfOptionRaw(requestParameters: UpdateNameOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['orderTypeKey'] == null) {
            throw new runtime.RequiredError(
                'orderTypeKey',
                'Required parameter "orderTypeKey" was null or undefined when calling updateNameOfOption().'
            );
        }

        if (requestParameters['optionCode'] == null) {
            throw new runtime.RequiredError(
                'optionCode',
                'Required parameter "optionCode" was null or undefined when calling updateNameOfOption().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling updateNameOfOption().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateNameOfOption().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['optionCode'] != null) {
            queryParameters['optionCode'] = requestParameters['optionCode'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/option/name`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters['orderTypeKey']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing Option
     */
    async updateNameOfOption(requestParameters: UpdateNameOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNameOfOptionRaw(requestParameters, initOverrides);
    }

}

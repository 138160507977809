/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionClassDTO } from './OptionClassDTO';
import {
    OptionClassDTOFromJSON,
    OptionClassDTOFromJSONTyped,
    OptionClassDTOToJSON,
    OptionClassDTOToJSONTyped,
} from './OptionClassDTO';

/**
 * 
 * @export
 * @interface SelectOptionDTOOptionClassDTO
 */
export interface SelectOptionDTOOptionClassDTO {
    /**
     * 
     * @type {OptionClassDTO}
     * @memberof SelectOptionDTOOptionClassDTO
     */
    key: OptionClassDTO;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDTOOptionClassDTO
     */
    label: string;
}



/**
 * Check if a given object implements the SelectOptionDTOOptionClassDTO interface.
 */
export function instanceOfSelectOptionDTOOptionClassDTO(value: object): value is SelectOptionDTOOptionClassDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function SelectOptionDTOOptionClassDTOFromJSON(json: any): SelectOptionDTOOptionClassDTO {
    return SelectOptionDTOOptionClassDTOFromJSONTyped(json, false);
}

export function SelectOptionDTOOptionClassDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectOptionDTOOptionClassDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': OptionClassDTOFromJSON(json['key']),
        'label': json['label'],
    };
}

export function SelectOptionDTOOptionClassDTOToJSON(json: any): SelectOptionDTOOptionClassDTO {
    return SelectOptionDTOOptionClassDTOToJSONTyped(json, false);
}

export function SelectOptionDTOOptionClassDTOToJSONTyped(value?: SelectOptionDTOOptionClassDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': OptionClassDTOToJSON(value['key']),
        'label': value['label'],
    };
}


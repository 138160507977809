import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { DerivativeCategoryFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { DerivativeCategoryFilter } from "../../../../../pdsapi"
import { FunctionComponent } from "react"
import { MultiSelectFilter } from "../../../../../components/filter/MultiSelectFilter"

export const DerivativeCategoryListFilter: FunctionComponent<TableFilterProps<DerivativeCategoryFilter, DerivativeCategoryFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const modelSeriesFilter = (
        <MultiSelectFilter
            label={"Model Series"}
            options={filterOptions?.modelSeries}
            value={filter.modelSeriesKeys}
            onChange={(modelSeriesKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelSeriesKeys,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Model"}>{modelSeriesFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOExteriorColorType } from './SelectOptionDTOExteriorColorType';
import {
    SelectOptionDTOExteriorColorTypeFromJSON,
    SelectOptionDTOExteriorColorTypeFromJSONTyped,
    SelectOptionDTOExteriorColorTypeToJSON,
    SelectOptionDTOExteriorColorTypeToJSONTyped,
} from './SelectOptionDTOExteriorColorType';
import type { SelectOptionDTOInteriorMaterial } from './SelectOptionDTOInteriorMaterial';
import {
    SelectOptionDTOInteriorMaterialFromJSON,
    SelectOptionDTOInteriorMaterialFromJSONTyped,
    SelectOptionDTOInteriorMaterialToJSON,
    SelectOptionDTOInteriorMaterialToJSONTyped,
} from './SelectOptionDTOInteriorMaterial';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';
import type { OptionGroupDTOString } from './OptionGroupDTOString';
import {
    OptionGroupDTOStringFromJSON,
    OptionGroupDTOStringFromJSONTyped,
    OptionGroupDTOStringToJSON,
    OptionGroupDTOStringToJSONTyped,
} from './OptionGroupDTOString';

/**
 * 
 * @export
 * @interface OptionCustomizationCreateOptionsDTO
 */
export interface OptionCustomizationCreateOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationCreateOptionsDTO
     */
    modelGenerations: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<OptionGroupDTOString>}
     * @memberof OptionCustomizationCreateOptionsDTO
     */
    categorizedEquipmentTags: Array<OptionGroupDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationCreateOptionsDTO
     */
    interiorColorTags: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOInteriorMaterial>}
     * @memberof OptionCustomizationCreateOptionsDTO
     */
    interiorMaterials: Array<SelectOptionDTOInteriorMaterial>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationCreateOptionsDTO
     */
    exteriorColorTags: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOExteriorColorType>}
     * @memberof OptionCustomizationCreateOptionsDTO
     */
    exteriorColorTypes: Array<SelectOptionDTOExteriorColorType>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationCreateOptionsDTO
     */
    roofColorTags: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the OptionCustomizationCreateOptionsDTO interface.
 */
export function instanceOfOptionCustomizationCreateOptionsDTO(value: object): value is OptionCustomizationCreateOptionsDTO {
    if (!('modelGenerations' in value) || value['modelGenerations'] === undefined) return false;
    if (!('categorizedEquipmentTags' in value) || value['categorizedEquipmentTags'] === undefined) return false;
    if (!('interiorColorTags' in value) || value['interiorColorTags'] === undefined) return false;
    if (!('interiorMaterials' in value) || value['interiorMaterials'] === undefined) return false;
    if (!('exteriorColorTags' in value) || value['exteriorColorTags'] === undefined) return false;
    if (!('exteriorColorTypes' in value) || value['exteriorColorTypes'] === undefined) return false;
    if (!('roofColorTags' in value) || value['roofColorTags'] === undefined) return false;
    return true;
}

export function OptionCustomizationCreateOptionsDTOFromJSON(json: any): OptionCustomizationCreateOptionsDTO {
    return OptionCustomizationCreateOptionsDTOFromJSONTyped(json, false);
}

export function OptionCustomizationCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationCreateOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'modelGenerations': ((json['modelGenerations'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'categorizedEquipmentTags': ((json['categorizedEquipmentTags'] as Array<any>).map(OptionGroupDTOStringFromJSON)),
        'interiorColorTags': ((json['interiorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'interiorMaterials': ((json['interiorMaterials'] as Array<any>).map(SelectOptionDTOInteriorMaterialFromJSON)),
        'exteriorColorTags': ((json['exteriorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'exteriorColorTypes': ((json['exteriorColorTypes'] as Array<any>).map(SelectOptionDTOExteriorColorTypeFromJSON)),
        'roofColorTags': ((json['roofColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function OptionCustomizationCreateOptionsDTOToJSON(json: any): OptionCustomizationCreateOptionsDTO {
    return OptionCustomizationCreateOptionsDTOToJSONTyped(json, false);
}

export function OptionCustomizationCreateOptionsDTOToJSONTyped(value?: OptionCustomizationCreateOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'modelGenerations': ((value['modelGenerations'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'categorizedEquipmentTags': ((value['categorizedEquipmentTags'] as Array<any>).map(OptionGroupDTOStringToJSON)),
        'interiorColorTags': ((value['interiorColorTags'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'interiorMaterials': ((value['interiorMaterials'] as Array<any>).map(SelectOptionDTOInteriorMaterialToJSON)),
        'exteriorColorTags': ((value['exteriorColorTags'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'exteriorColorTypes': ((value['exteriorColorTypes'] as Array<any>).map(SelectOptionDTOExteriorColorTypeToJSON)),
        'roofColorTags': ((value['roofColorTags'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { OptionItemDTOCustomTailoring } from './OptionItemDTOCustomTailoring';
import {
    instanceOfOptionItemDTOCustomTailoring,
    OptionItemDTOCustomTailoringFromJSON,
    OptionItemDTOCustomTailoringFromJSONTyped,
    OptionItemDTOCustomTailoringToJSON,
} from './OptionItemDTOCustomTailoring';
import type { OptionItemDTOExteriorColor } from './OptionItemDTOExteriorColor';
import {
    instanceOfOptionItemDTOExteriorColor,
    OptionItemDTOExteriorColorFromJSON,
    OptionItemDTOExteriorColorFromJSONTyped,
    OptionItemDTOExteriorColorToJSON,
} from './OptionItemDTOExteriorColor';
import type { OptionItemDTOIndividualEquipment } from './OptionItemDTOIndividualEquipment';
import {
    instanceOfOptionItemDTOIndividualEquipment,
    OptionItemDTOIndividualEquipmentFromJSON,
    OptionItemDTOIndividualEquipmentFromJSONTyped,
    OptionItemDTOIndividualEquipmentToJSON,
} from './OptionItemDTOIndividualEquipment';
import type { OptionItemDTOInterior } from './OptionItemDTOInterior';
import {
    instanceOfOptionItemDTOInterior,
    OptionItemDTOInteriorFromJSON,
    OptionItemDTOInteriorFromJSONTyped,
    OptionItemDTOInteriorToJSON,
} from './OptionItemDTOInterior';
import type { OptionItemDTOPaintToSampleExteriorColor } from './OptionItemDTOPaintToSampleExteriorColor';
import {
    instanceOfOptionItemDTOPaintToSampleExteriorColor,
    OptionItemDTOPaintToSampleExteriorColorFromJSON,
    OptionItemDTOPaintToSampleExteriorColorFromJSONTyped,
    OptionItemDTOPaintToSampleExteriorColorToJSON,
} from './OptionItemDTOPaintToSampleExteriorColor';
import type { OptionItemDTORoofColor } from './OptionItemDTORoofColor';
import {
    instanceOfOptionItemDTORoofColor,
    OptionItemDTORoofColorFromJSON,
    OptionItemDTORoofColorFromJSONTyped,
    OptionItemDTORoofColorToJSON,
} from './OptionItemDTORoofColor';
import type { OptionItemDTOSalesPackage } from './OptionItemDTOSalesPackage';
import {
    instanceOfOptionItemDTOSalesPackage,
    OptionItemDTOSalesPackageFromJSON,
    OptionItemDTOSalesPackageFromJSONTyped,
    OptionItemDTOSalesPackageToJSON,
} from './OptionItemDTOSalesPackage';
import type { OptionItemDTOTechnicalPackage } from './OptionItemDTOTechnicalPackage';
import {
    instanceOfOptionItemDTOTechnicalPackage,
    OptionItemDTOTechnicalPackageFromJSON,
    OptionItemDTOTechnicalPackageFromJSONTyped,
    OptionItemDTOTechnicalPackageToJSON,
} from './OptionItemDTOTechnicalPackage';
import type { OptionItemDTOUnclassifiedEquipment } from './OptionItemDTOUnclassifiedEquipment';
import {
    instanceOfOptionItemDTOUnclassifiedEquipment,
    OptionItemDTOUnclassifiedEquipmentFromJSON,
    OptionItemDTOUnclassifiedEquipmentFromJSONTyped,
    OptionItemDTOUnclassifiedEquipmentToJSON,
} from './OptionItemDTOUnclassifiedEquipment';
import type { OptionItemDTOZOrder } from './OptionItemDTOZOrder';
import {
    instanceOfOptionItemDTOZOrder,
    OptionItemDTOZOrderFromJSON,
    OptionItemDTOZOrderFromJSONTyped,
    OptionItemDTOZOrderToJSON,
} from './OptionItemDTOZOrder';

/**
 * @type OptionItemDTO
 * 
 * @export
 */
export type OptionItemDTO = { type: 'CustomTailoring' } & OptionItemDTOCustomTailoring | { type: 'ExteriorColor' } & OptionItemDTOExteriorColor | { type: 'IndividualEquipment' } & OptionItemDTOIndividualEquipment | { type: 'Interior' } & OptionItemDTOInterior | { type: 'PaintToSampleExteriorColor' } & OptionItemDTOPaintToSampleExteriorColor | { type: 'RoofColor' } & OptionItemDTORoofColor | { type: 'SalesPackage' } & OptionItemDTOSalesPackage | { type: 'TechnicalPackage' } & OptionItemDTOTechnicalPackage | { type: 'UnclassifiedEquipment' } & OptionItemDTOUnclassifiedEquipment | { type: 'ZOrder' } & OptionItemDTOZOrder;

export function OptionItemDTOFromJSON(json: any): OptionItemDTO {
    return OptionItemDTOFromJSONTyped(json, false);
}

export function OptionItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionItemDTO {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'CustomTailoring':
            return Object.assign({}, OptionItemDTOCustomTailoringFromJSONTyped(json, true), { type: 'CustomTailoring' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionItemDTOExteriorColorFromJSONTyped(json, true), { type: 'ExteriorColor' } as const);
        case 'IndividualEquipment':
            return Object.assign({}, OptionItemDTOIndividualEquipmentFromJSONTyped(json, true), { type: 'IndividualEquipment' } as const);
        case 'Interior':
            return Object.assign({}, OptionItemDTOInteriorFromJSONTyped(json, true), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionItemDTOPaintToSampleExteriorColorFromJSONTyped(json, true), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionItemDTORoofColorFromJSONTyped(json, true), { type: 'RoofColor' } as const);
        case 'SalesPackage':
            return Object.assign({}, OptionItemDTOSalesPackageFromJSONTyped(json, true), { type: 'SalesPackage' } as const);
        case 'TechnicalPackage':
            return Object.assign({}, OptionItemDTOTechnicalPackageFromJSONTyped(json, true), { type: 'TechnicalPackage' } as const);
        case 'UnclassifiedEquipment':
            return Object.assign({}, OptionItemDTOUnclassifiedEquipmentFromJSONTyped(json, true), { type: 'UnclassifiedEquipment' } as const);
        case 'ZOrder':
            return Object.assign({}, OptionItemDTOZOrderFromJSONTyped(json, true), { type: 'ZOrder' } as const);
        default:
            throw new Error(`No variant of OptionItemDTO exists with 'type=${json['type']}'`);
    }
}

export function OptionItemDTOToJSON(json: any): any {
    return OptionItemDTOToJSONTyped(json, false);
}

export function OptionItemDTOToJSONTyped(value?: OptionItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'CustomTailoring':
            return Object.assign({}, OptionItemDTOCustomTailoringToJSON(value), { type: 'CustomTailoring' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionItemDTOExteriorColorToJSON(value), { type: 'ExteriorColor' } as const);
        case 'IndividualEquipment':
            return Object.assign({}, OptionItemDTOIndividualEquipmentToJSON(value), { type: 'IndividualEquipment' } as const);
        case 'Interior':
            return Object.assign({}, OptionItemDTOInteriorToJSON(value), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionItemDTOPaintToSampleExteriorColorToJSON(value), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionItemDTORoofColorToJSON(value), { type: 'RoofColor' } as const);
        case 'SalesPackage':
            return Object.assign({}, OptionItemDTOSalesPackageToJSON(value), { type: 'SalesPackage' } as const);
        case 'TechnicalPackage':
            return Object.assign({}, OptionItemDTOTechnicalPackageToJSON(value), { type: 'TechnicalPackage' } as const);
        case 'UnclassifiedEquipment':
            return Object.assign({}, OptionItemDTOUnclassifiedEquipmentToJSON(value), { type: 'UnclassifiedEquipment' } as const);
        case 'ZOrder':
            return Object.assign({}, OptionItemDTOZOrderToJSON(value), { type: 'ZOrder' } as const);
        default:
            throw new Error(`No variant of OptionItemDTO exists with 'type=${value['type']}'`);
    }

}


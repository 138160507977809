/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionTypeDTO } from './OptionTypeDTO';
import {
    OptionTypeDTOFromJSON,
    OptionTypeDTOFromJSONTyped,
    OptionTypeDTOToJSON,
    OptionTypeDTOToJSONTyped,
} from './OptionTypeDTO';

/**
 * 
 * @export
 * @interface OptionCustomizationLinksDTOOption
 */
export interface OptionCustomizationLinksDTOOption {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationLinksDTOOption
     */
    orderTypeKey: string;
    /**
     * 
     * @type {number}
     * @memberof OptionCustomizationLinksDTOOption
     */
    modelYear: number;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationLinksDTOOption
     */
    modelGenerationKey: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationLinksDTOOption
     */
    optionCode: string;
    /**
     * 
     * @type {OptionTypeDTO}
     * @memberof OptionCustomizationLinksDTOOption
     */
    type: OptionTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationLinksDTOOption
     */
    label: string;
}



/**
 * Check if a given object implements the OptionCustomizationLinksDTOOption interface.
 */
export function instanceOfOptionCustomizationLinksDTOOption(value: object): value is OptionCustomizationLinksDTOOption {
    if (!('orderTypeKey' in value) || value['orderTypeKey'] === undefined) return false;
    if (!('modelYear' in value) || value['modelYear'] === undefined) return false;
    if (!('modelGenerationKey' in value) || value['modelGenerationKey'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function OptionCustomizationLinksDTOOptionFromJSON(json: any): OptionCustomizationLinksDTOOption {
    return OptionCustomizationLinksDTOOptionFromJSONTyped(json, false);
}

export function OptionCustomizationLinksDTOOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationLinksDTOOption {
    if (json == null) {
        return json;
    }
    return {
        
        'orderTypeKey': json['orderTypeKey'],
        'modelYear': json['modelYear'],
        'modelGenerationKey': json['modelGenerationKey'],
        'optionCode': json['optionCode'],
        'type': OptionTypeDTOFromJSON(json['type']),
        'label': json['label'],
    };
}

export function OptionCustomizationLinksDTOOptionToJSON(json: any): OptionCustomizationLinksDTOOption {
    return OptionCustomizationLinksDTOOptionToJSONTyped(json, false);
}

export function OptionCustomizationLinksDTOOptionToJSONTyped(value?: OptionCustomizationLinksDTOOption | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'orderTypeKey': value['orderTypeKey'],
        'modelYear': value['modelYear'],
        'modelGenerationKey': value['modelGenerationKey'],
        'optionCode': value['optionCode'],
        'type': OptionTypeDTOToJSON(value['type']),
        'label': value['label'],
    };
}


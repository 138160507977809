/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationConditionalValueUpdateDTOModelYearRange } from './OptionCustomizationConditionalValueUpdateDTOModelYearRange';
import {
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSON,
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSONTyped,
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSON,
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSONTyped,
} from './OptionCustomizationConditionalValueUpdateDTOModelYearRange';
import type { OptionCustomizationAttributesUpdateDTOInteriorValue } from './OptionCustomizationAttributesUpdateDTOInteriorValue';
import {
    OptionCustomizationAttributesUpdateDTOInteriorValueFromJSON,
    OptionCustomizationAttributesUpdateDTOInteriorValueFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOInteriorValueToJSON,
    OptionCustomizationAttributesUpdateDTOInteriorValueToJSONTyped,
} from './OptionCustomizationAttributesUpdateDTOInteriorValue';

/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue
 */
export interface OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue
     */
    key: string;
    /**
     * 
     * @type {OptionCustomizationConditionalValueUpdateDTOModelYearRange}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue
     */
    modelYearRange: OptionCustomizationConditionalValueUpdateDTOModelYearRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue
     */
    derivativeKeys?: Array<string>;
    /**
     * 
     * @type {OptionCustomizationAttributesUpdateDTOInteriorValue}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue
     */
    value: OptionCustomizationAttributesUpdateDTOInteriorValue;
}

/**
 * Check if a given object implements the OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue interface.
 */
export function instanceOfOptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue(value: object): value is OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('modelYearRange' in value) || value['modelYearRange'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValueFromJSON(json: any): OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue {
    return OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValueFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelYearRange': OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSON(json['modelYearRange']),
        'derivativeKeys': json['derivativeKeys'] == null ? undefined : json['derivativeKeys'],
        'value': OptionCustomizationAttributesUpdateDTOInteriorValueFromJSON(json['value']),
    };
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValueToJSON(json: any): OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue {
    return OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValueToJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValueToJSONTyped(value?: OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOInteriorValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'modelYearRange': OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSON(value['modelYearRange']),
        'derivativeKeys': value['derivativeKeys'],
        'value': OptionCustomizationAttributesUpdateDTOInteriorValueToJSON(value['value']),
    };
}


import { FC, useState } from "react"
import { OptionCustomizationLinksDTO } from "../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { ReadonlyFormElementWrapper } from "../../../../../../components/formelements/ReadonlyFormElementWrapper"
import { getTagsFormElement } from "../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { routes } from "../../../../../../common/routes"
import { MultiSelectFilter, NumberMultiSelectFilter } from "../../../../../../components/filter/MultiSelectFilter"

export const LinkedOptionsCardContent: FC<ProductDetailsCardContentProps<OptionCustomizationLinksDTO, object>> = ({ formDisplayData }) => {
    const [selectedModelGenerations, setSelectedModelGenerations] = useState<Set<string> | undefined>(undefined)
    const [selectedModelYears, setSelectedModelYears] = useState<Set<number> | undefined>(undefined)

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Options"}>
                <MultiSelectFilter
                    label={"Model Generations"}
                    options={formDisplayData.data?.availableModelGenerations}
                    value={selectedModelGenerations}
                    onChange={setSelectedModelGenerations}
                />
                <NumberMultiSelectFilter
                    label={"Model Years"}
                    options={formDisplayData.data?.availableModelYears}
                    value={selectedModelYears}
                    onChange={setSelectedModelYears}
                />
            </RowGroupWrapper>
            <RowGroupWrapper childrenSize={12}>
                <ReadonlyFormElementWrapper
                    label={undefined}
                    formDisplayData={formDisplayData}
                    FormElement={getTagsFormElement()}
                    getViewProps={(data) => ({
                        emptyLabel: "-",
                        openInNewTab: true,
                        tags: data.options
                            .filter(
                                (relatedOption) =>
                                    (!selectedModelGenerations || selectedModelGenerations.has(relatedOption.modelGenerationKey)) &&
                                    (!selectedModelYears || selectedModelYears.has(relatedOption.modelYear))
                            )
                            .map((relatedOption) => ({
                                label: relatedOption.label,
                                route: routes.models.orderTypes
                                    .subpages(relatedOption.orderTypeKey)
                                    .options.details(relatedOption.optionCode, relatedOption.type),
                            })),
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}

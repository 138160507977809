/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OptionTypeDTO = {
    EQUIPMENT: 'EQUIPMENT',
    INTERIOR: 'INTERIOR',
    EXTERIOR_COLOR: 'EXTERIOR_COLOR',
    PAINT_TO_SAMPLE_EXTERIOR_COLOR: 'PAINT_TO_SAMPLE_EXTERIOR_COLOR',
    ROOF_COLOR: 'ROOF_COLOR'
} as const;
export type OptionTypeDTO = typeof OptionTypeDTO[keyof typeof OptionTypeDTO];


export function instanceOfOptionTypeDTO(value: any): boolean {
    for (const key in OptionTypeDTO) {
        if (Object.prototype.hasOwnProperty.call(OptionTypeDTO, key)) {
            if (OptionTypeDTO[key as keyof typeof OptionTypeDTO] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OptionTypeDTOFromJSON(json: any): OptionTypeDTO {
    return OptionTypeDTOFromJSONTyped(json, false);
}

export function OptionTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTypeDTO {
    return json as OptionTypeDTO;
}

export function OptionTypeDTOToJSON(value?: OptionTypeDTO | null): any {
    return value as any;
}

export function OptionTypeDTOToJSONTyped(value: any, ignoreDiscriminator: boolean): OptionTypeDTO {
    return value as OptionTypeDTO;
}


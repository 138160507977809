/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FuelType } from './FuelType';
import {
    FuelTypeFromJSON,
    FuelTypeFromJSONTyped,
    FuelTypeToJSON,
    FuelTypeToJSONTyped,
} from './FuelType';

/**
 * 
 * @export
 * @interface SelectOptionDTOFuelType
 */
export interface SelectOptionDTOFuelType {
    /**
     * 
     * @type {FuelType}
     * @memberof SelectOptionDTOFuelType
     */
    key: FuelType;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDTOFuelType
     */
    label: string;
}



/**
 * Check if a given object implements the SelectOptionDTOFuelType interface.
 */
export function instanceOfSelectOptionDTOFuelType(value: object): value is SelectOptionDTOFuelType {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function SelectOptionDTOFuelTypeFromJSON(json: any): SelectOptionDTOFuelType {
    return SelectOptionDTOFuelTypeFromJSONTyped(json, false);
}

export function SelectOptionDTOFuelTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectOptionDTOFuelType {
    if (json == null) {
        return json;
    }
    return {
        
        'key': FuelTypeFromJSON(json['key']),
        'label': json['label'],
    };
}

export function SelectOptionDTOFuelTypeToJSON(json: any): SelectOptionDTOFuelType {
    return SelectOptionDTOFuelTypeToJSONTyped(json, false);
}

export function SelectOptionDTOFuelTypeToJSONTyped(value?: SelectOptionDTOFuelType | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': FuelTypeToJSON(value['key']),
        'label': value['label'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OptionCustomizationSortField = {
    OPTION_CODE: 'OPTION_CODE'
} as const;
export type OptionCustomizationSortField = typeof OptionCustomizationSortField[keyof typeof OptionCustomizationSortField];


export function instanceOfOptionCustomizationSortField(value: any): boolean {
    for (const key in OptionCustomizationSortField) {
        if (Object.prototype.hasOwnProperty.call(OptionCustomizationSortField, key)) {
            if (OptionCustomizationSortField[key as keyof typeof OptionCustomizationSortField] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OptionCustomizationSortFieldFromJSON(json: any): OptionCustomizationSortField {
    return OptionCustomizationSortFieldFromJSONTyped(json, false);
}

export function OptionCustomizationSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationSortField {
    return json as OptionCustomizationSortField;
}

export function OptionCustomizationSortFieldToJSON(value?: OptionCustomizationSortField | null): any {
    return value as any;
}

export function OptionCustomizationSortFieldToJSONTyped(value: any, ignoreDiscriminator: boolean): OptionCustomizationSortField {
    return value as OptionCustomizationSortField;
}


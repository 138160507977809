/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOExteriorColorGroup } from './SelectOptionDTOExteriorColorGroup';
import {
    SelectOptionDTOExteriorColorGroupFromJSON,
    SelectOptionDTOExteriorColorGroupFromJSONTyped,
    SelectOptionDTOExteriorColorGroupToJSON,
    SelectOptionDTOExteriorColorGroupToJSONTyped,
} from './SelectOptionDTOExteriorColorGroup';
import type { SelectOptionDTOExteriorColorType } from './SelectOptionDTOExteriorColorType';
import {
    SelectOptionDTOExteriorColorTypeFromJSON,
    SelectOptionDTOExteriorColorTypeFromJSONTyped,
    SelectOptionDTOExteriorColorTypeToJSON,
    SelectOptionDTOExteriorColorTypeToJSONTyped,
} from './SelectOptionDTOExteriorColorType';
import type { SelectOptionDTOVehicleEquipmentCategory } from './SelectOptionDTOVehicleEquipmentCategory';
import {
    SelectOptionDTOVehicleEquipmentCategoryFromJSON,
    SelectOptionDTOVehicleEquipmentCategoryFromJSONTyped,
    SelectOptionDTOVehicleEquipmentCategoryToJSON,
    SelectOptionDTOVehicleEquipmentCategoryToJSONTyped,
} from './SelectOptionDTOVehicleEquipmentCategory';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface OptionCreateRequestOptionsDTO
 */
export interface OptionCreateRequestOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOExteriorColorType>}
     * @memberof OptionCreateRequestOptionsDTO
     */
    exteriorColorTypes: Array<SelectOptionDTOExteriorColorType>;
    /**
     * 
     * @type {Array<SelectOptionDTOExteriorColorGroup>}
     * @memberof OptionCreateRequestOptionsDTO
     */
    exteriorColorGroups: Array<SelectOptionDTOExteriorColorGroup>;
    /**
     * 
     * @type {Array<SelectOptionDTOVehicleEquipmentCategory>}
     * @memberof OptionCreateRequestOptionsDTO
     */
    equipmentCategories: Array<SelectOptionDTOVehicleEquipmentCategory>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCreateRequestOptionsDTO
     */
    availableColors: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the OptionCreateRequestOptionsDTO interface.
 */
export function instanceOfOptionCreateRequestOptionsDTO(value: object): value is OptionCreateRequestOptionsDTO {
    if (!('exteriorColorTypes' in value) || value['exteriorColorTypes'] === undefined) return false;
    if (!('exteriorColorGroups' in value) || value['exteriorColorGroups'] === undefined) return false;
    if (!('equipmentCategories' in value) || value['equipmentCategories'] === undefined) return false;
    if (!('availableColors' in value) || value['availableColors'] === undefined) return false;
    return true;
}

export function OptionCreateRequestOptionsDTOFromJSON(json: any): OptionCreateRequestOptionsDTO {
    return OptionCreateRequestOptionsDTOFromJSONTyped(json, false);
}

export function OptionCreateRequestOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCreateRequestOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'exteriorColorTypes': ((json['exteriorColorTypes'] as Array<any>).map(SelectOptionDTOExteriorColorTypeFromJSON)),
        'exteriorColorGroups': ((json['exteriorColorGroups'] as Array<any>).map(SelectOptionDTOExteriorColorGroupFromJSON)),
        'equipmentCategories': ((json['equipmentCategories'] as Array<any>).map(SelectOptionDTOVehicleEquipmentCategoryFromJSON)),
        'availableColors': ((json['availableColors'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function OptionCreateRequestOptionsDTOToJSON(json: any): OptionCreateRequestOptionsDTO {
    return OptionCreateRequestOptionsDTOToJSONTyped(json, false);
}

export function OptionCreateRequestOptionsDTOToJSONTyped(value?: OptionCreateRequestOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'exteriorColorTypes': ((value['exteriorColorTypes'] as Array<any>).map(SelectOptionDTOExteriorColorTypeToJSON)),
        'exteriorColorGroups': ((value['exteriorColorGroups'] as Array<any>).map(SelectOptionDTOExteriorColorGroupToJSON)),
        'equipmentCategories': ((value['equipmentCategories'] as Array<any>).map(SelectOptionDTOVehicleEquipmentCategoryToJSON)),
        'availableColors': ((value['availableColors'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


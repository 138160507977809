/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomInteriorColorFilterOptionsDTO,
  PageDTOCustomInteriorColorItemDTO,
} from '../models/index';
import {
    CustomInteriorColorFilterOptionsDTOFromJSON,
    CustomInteriorColorFilterOptionsDTOToJSON,
    PageDTOCustomInteriorColorItemDTOFromJSON,
    PageDTOCustomInteriorColorItemDTOToJSON,
} from '../models/index';

export interface GetCustomInteriorColorFilterOptionsRequest {
    languageTag: string;
}

export interface GetCustomInteriorColorsPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    search?: string;
    interiorColorTagKeys?: Set<string>;
}

/**
 * 
 */
export class CustomInteriorColorListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter CustomInteriorColors
     */
    async getCustomInteriorColorFilterOptionsRaw(requestParameters: GetCustomInteriorColorFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomInteriorColorFilterOptionsDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getCustomInteriorColorFilterOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-interior-colors/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomInteriorColorFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter CustomInteriorColors
     */
    async getCustomInteriorColorFilterOptions(requestParameters: GetCustomInteriorColorFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomInteriorColorFilterOptionsDTO> {
        const response = await this.getCustomInteriorColorFilterOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get page of CustomInteriorColors
     */
    async getCustomInteriorColorsPageRaw(requestParameters: GetCustomInteriorColorsPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOCustomInteriorColorItemDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getCustomInteriorColorsPage().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getCustomInteriorColorsPage().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getCustomInteriorColorsPage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['interiorColorTagKeys'] != null) {
            queryParameters['interiorColorTagKeys'] = requestParameters['interiorColorTagKeys'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-interior-colors/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOCustomInteriorColorItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of CustomInteriorColors
     */
    async getCustomInteriorColorsPage(requestParameters: GetCustomInteriorColorsPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOCustomInteriorColorItemDTO> {
        const response = await this.getCustomInteriorColorsPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

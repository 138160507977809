/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { OptionCustomizationItemDTOEquipment } from './OptionCustomizationItemDTOEquipment';
import {
    instanceOfOptionCustomizationItemDTOEquipment,
    OptionCustomizationItemDTOEquipmentFromJSON,
    OptionCustomizationItemDTOEquipmentFromJSONTyped,
    OptionCustomizationItemDTOEquipmentToJSON,
} from './OptionCustomizationItemDTOEquipment';
import type { OptionCustomizationItemDTOExteriorColor } from './OptionCustomizationItemDTOExteriorColor';
import {
    instanceOfOptionCustomizationItemDTOExteriorColor,
    OptionCustomizationItemDTOExteriorColorFromJSON,
    OptionCustomizationItemDTOExteriorColorFromJSONTyped,
    OptionCustomizationItemDTOExteriorColorToJSON,
} from './OptionCustomizationItemDTOExteriorColor';
import type { OptionCustomizationItemDTOInterior } from './OptionCustomizationItemDTOInterior';
import {
    instanceOfOptionCustomizationItemDTOInterior,
    OptionCustomizationItemDTOInteriorFromJSON,
    OptionCustomizationItemDTOInteriorFromJSONTyped,
    OptionCustomizationItemDTOInteriorToJSON,
} from './OptionCustomizationItemDTOInterior';
import type { OptionCustomizationItemDTOPaintToSampleExteriorColor } from './OptionCustomizationItemDTOPaintToSampleExteriorColor';
import {
    instanceOfOptionCustomizationItemDTOPaintToSampleExteriorColor,
    OptionCustomizationItemDTOPaintToSampleExteriorColorFromJSON,
    OptionCustomizationItemDTOPaintToSampleExteriorColorFromJSONTyped,
    OptionCustomizationItemDTOPaintToSampleExteriorColorToJSON,
} from './OptionCustomizationItemDTOPaintToSampleExteriorColor';
import type { OptionCustomizationItemDTORoofColor } from './OptionCustomizationItemDTORoofColor';
import {
    instanceOfOptionCustomizationItemDTORoofColor,
    OptionCustomizationItemDTORoofColorFromJSON,
    OptionCustomizationItemDTORoofColorFromJSONTyped,
    OptionCustomizationItemDTORoofColorToJSON,
} from './OptionCustomizationItemDTORoofColor';

/**
 * @type OptionCustomizationItemDTO
 * 
 * @export
 */
export type OptionCustomizationItemDTO = { type: 'Equipment' } & OptionCustomizationItemDTOEquipment | { type: 'ExteriorColor' } & OptionCustomizationItemDTOExteriorColor | { type: 'Interior' } & OptionCustomizationItemDTOInterior | { type: 'PaintToSampleExteriorColor' } & OptionCustomizationItemDTOPaintToSampleExteriorColor | { type: 'RoofColor' } & OptionCustomizationItemDTORoofColor;

export function OptionCustomizationItemDTOFromJSON(json: any): OptionCustomizationItemDTO {
    return OptionCustomizationItemDTOFromJSONTyped(json, false);
}

export function OptionCustomizationItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationItemDTO {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return Object.assign({}, OptionCustomizationItemDTOEquipmentFromJSONTyped(json, true), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionCustomizationItemDTOExteriorColorFromJSONTyped(json, true), { type: 'ExteriorColor' } as const);
        case 'Interior':
            return Object.assign({}, OptionCustomizationItemDTOInteriorFromJSONTyped(json, true), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionCustomizationItemDTOPaintToSampleExteriorColorFromJSONTyped(json, true), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionCustomizationItemDTORoofColorFromJSONTyped(json, true), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionCustomizationItemDTO exists with 'type=${json['type']}'`);
    }
}

export function OptionCustomizationItemDTOToJSON(json: any): any {
    return OptionCustomizationItemDTOToJSONTyped(json, false);
}

export function OptionCustomizationItemDTOToJSONTyped(value?: OptionCustomizationItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'Equipment':
            return Object.assign({}, OptionCustomizationItemDTOEquipmentToJSON(value), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionCustomizationItemDTOExteriorColorToJSON(value), { type: 'ExteriorColor' } as const);
        case 'Interior':
            return Object.assign({}, OptionCustomizationItemDTOInteriorToJSON(value), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionCustomizationItemDTOPaintToSampleExteriorColorToJSON(value), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionCustomizationItemDTORoofColorToJSON(value), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionCustomizationItemDTO exists with 'type=${value['type']}'`);
    }

}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DriveTrain = {
    ALL_WHEEL_DRIVE: 'ALL_WHEEL_DRIVE',
    REAR_WHEEL_DRIVE: 'REAR_WHEEL_DRIVE'
} as const;
export type DriveTrain = typeof DriveTrain[keyof typeof DriveTrain];


export function instanceOfDriveTrain(value: any): boolean {
    for (const key in DriveTrain) {
        if (Object.prototype.hasOwnProperty.call(DriveTrain, key)) {
            if (DriveTrain[key as keyof typeof DriveTrain] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DriveTrainFromJSON(json: any): DriveTrain {
    return DriveTrainFromJSONTyped(json, false);
}

export function DriveTrainFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriveTrain {
    return json as DriveTrain;
}

export function DriveTrainToJSON(value?: DriveTrain | null): any {
    return value as any;
}

export function DriveTrainToJSONTyped(value: any, ignoreDiscriminator: boolean): DriveTrain {
    return value as DriveTrain;
}


import { Property } from "../../common/property"
import { LabeledContentWrapper } from "../layouts/labeledcontentwrapper/LabeledContentWrapper"
import { SkeletonText } from "../skeleton/SkeletonText"
import { EditFormDisplayData, FormDisplayData } from "./FormDisplayData"
import { FC, JSX, ReactNode } from "react"
import { FieldValues } from "react-hook-form"

type Label = { label: string | undefined }
type Hint = { hint?: string }
export type LoadingProps = Label
export type ViewProps<PROPS> = PROPS & Label & Hint
export type EditProps<DATA, UPDATE extends FieldValues, T, PROPS> = PROPS & EditFormDisplayData<DATA, UPDATE> & Label & Hint & { field: Property<UPDATE, T> }

export interface FormElement<DATA, UPDATE extends FieldValues, T, VIEW_PROPS, EDIT_PROPS> {
    Loading: (props: LoadingProps) => JSX.Element | null
    View: (props: ViewProps<VIEW_PROPS>) => JSX.Element | null
    Edit: (props: EditProps<DATA, UPDATE, T, EDIT_PROPS>) => JSX.Element | null
}

export const FormElementWrapper = <DATA, UPDATE extends FieldValues, T, VIEW_PROPS, EDIT_PROPS>({
    label,
    hint,
    formDisplayData,
    field,
    FormElement,
    getViewProps,
    getEditProps,
}: {
    label: string | undefined
    hint?: string
    formDisplayData: FormDisplayData<DATA, UPDATE>
    field: Property<UPDATE, T>
    FormElement: FormElement<DATA, UPDATE, T, VIEW_PROPS, EDIT_PROPS>
    getViewProps: (data: DATA) => VIEW_PROPS
    getEditProps: (data: DATA) => EDIT_PROPS
}) => {
    switch (formDisplayData.kind) {
        case "LOADING":
            return <FormElement.Loading label={label} />
        case "VIEW":
            return <FormElement.View label={label} hint={hint} {...getViewProps(formDisplayData.data)} />
        case "EDIT":
            return <FormElement.Edit label={label} hint={hint} field={field} {...formDisplayData} {...getEditProps(formDisplayData.data)} />
    }
}

export const DefaultFormElementLoading = ({ label }: LoadingProps) => (
    <LabeledContentWrapper label={label} labelColor={"neutral-contrast-medium"}>
        <SkeletonText />
    </LabeledContentWrapper>
)

interface DefaultViewLabeledContentWrapperProps {
    label?: string
    hint?: string
    children: ReactNode
}

export const ViewLabeledContentWrapper: FC<DefaultViewLabeledContentWrapperProps> = ({ children, label, hint }) => (
    <LabeledContentWrapper label={label} hint={hint} labelColor={"neutral-contrast-medium"}>
        {children}
    </LabeledContentWrapper>
)

interface DefaultEditLabeledContentWrapperProps {
    label?: string
    hint?: string
    optional?: boolean
    children: ReactNode
}

export const EditLabeledContentWrapper: FC<DefaultEditLabeledContentWrapperProps> = ({ children, label, hint, optional }) => (
    <LabeledContentWrapper label={label} hint={hint} labelColor={"neutral-contrast-high"} optional={optional}>
        {children}
    </LabeledContentWrapper>
)

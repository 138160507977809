import { SelectOption } from "@finder/ui-kit/build/types/components/forms/multiSelect/MultiSelect"
import { OptionClassDTO, OptionCustomizationTypeDTO, OptionTypeDTO, OrderTypeOptionItemDTO } from "../../../../../generated/pdsapi"

type OptionClass = OrderTypeOptionItemDTO["type"]

export const equipmentClasses = {
    IndividualEquipment: "Individual Equipment",
    CustomTailoring: "Custom Tailoring",
    ZOrder: "Z Order",
    SalesPackage: "Sales Package",
    TechnicalPackage: "Technical Package",
    UnclassifiedEquipment: "Unclassified Equipment",
}

export const nonEquipmentClasses = {
    ExteriorColor: "Exterior Color",
    PaintToSampleExteriorColor: "Paint To Sample Exterior Color",
    Interior: "Interior",
    RoofColor: "Roof Color",
}

export const optionClasses: Record<OptionClass, string> = {
    ...nonEquipmentClasses,
    ...equipmentClasses,
}

export const equipmentClassOptions = Object.entries(equipmentClasses).map(
    ([value, label]) =>
        ({
            value: value as OptionClass,
            label,
        }) satisfies SelectOption
)

export const nonEquipmentClassOptions = Object.entries(nonEquipmentClasses).map(
    ([value, label]) =>
        ({
            value: value as OptionClass,
            label,
        }) satisfies SelectOption
)

export const optionClassOptions = Object.entries(optionClasses).map(
    ([value, label]) =>
        ({
            value: value as OptionClass,
            label,
        }) satisfies SelectOption
)

export type CreatableOptionClass = Exclude<OptionClass, "UnclassifiedEquipment">

export const creatableOptionClassOptions: SelectOption[] = optionClassOptions.filter((option) => option.value !== "UnclassifiedEquipment")

export const optionClassToOptionTypeEnum = (type: OptionClass): OptionTypeDTO => {
    switch (type) {
        case "IndividualEquipment":
        case "CustomTailoring":
        case "ZOrder":
        case "SalesPackage":
        case "TechnicalPackage":
        case "UnclassifiedEquipment":
            return OptionTypeDTO.EQUIPMENT
        case "ExteriorColor":
            return OptionTypeDTO.EXTERIOR_COLOR
        case "PaintToSampleExteriorColor":
            return OptionTypeDTO.PAINT_TO_SAMPLE_EXTERIOR_COLOR
        case "Interior":
            return OptionTypeDTO.INTERIOR
        case "RoofColor":
            return OptionTypeDTO.ROOF_COLOR
    }
}

export const optionClassToEnum = (type: OptionClass): OptionClassDTO => {
    switch (type) {
        case "IndividualEquipment":
            return OptionClassDTO.INDIVIDUAL_EQUIPMENT
        case "CustomTailoring":
            return OptionClassDTO.CUSTOM_TAILORING
        case "ZOrder":
            return OptionClassDTO.Z_ORDER
        case "SalesPackage":
            return OptionClassDTO.SALES_PACKAGE
        case "TechnicalPackage":
            return OptionClassDTO.TECHNICAL_PACKAGE
        case "UnclassifiedEquipment":
            return OptionClassDTO.UNCLASSIFIED_EQUIPMENT
        case "ExteriorColor":
            return OptionClassDTO.EXTERIOR_COLOR
        case "PaintToSampleExteriorColor":
            return OptionClassDTO.PAINT_TO_SAMPLE_EXTERIOR_COLOR
        case "Interior":
            return OptionClassDTO.INTERIOR
        case "RoofColor":
            return OptionClassDTO.ROOF_COLOR
    }
}

export const optionTypeToCustomizationType: Record<OptionTypeDTO, OptionCustomizationTypeDTO> = {
    EQUIPMENT: OptionCustomizationTypeDTO.EQUIPMENT,
    PAINT_TO_SAMPLE_EXTERIOR_COLOR: OptionCustomizationTypeDTO.PAINT_TO_SAMPLE_EXTERIOR_COLOR,
    EXTERIOR_COLOR: OptionCustomizationTypeDTO.EXTERIOR_COLOR,
    INTERIOR: OptionCustomizationTypeDTO.INTERIOR,
    ROOF_COLOR: OptionCustomizationTypeDTO.ROOF_COLOR,
}

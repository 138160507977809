/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OptionTagTypeDTO = {
    EQUIPMENT: 'EQUIPMENT',
    INTERIOR_COLOR: 'INTERIOR_COLOR',
    EXTERIOR_COLOR: 'EXTERIOR_COLOR',
    ROOF_COLOR: 'ROOF_COLOR'
} as const;
export type OptionTagTypeDTO = typeof OptionTagTypeDTO[keyof typeof OptionTagTypeDTO];


export function instanceOfOptionTagTypeDTO(value: any): boolean {
    for (const key in OptionTagTypeDTO) {
        if (Object.prototype.hasOwnProperty.call(OptionTagTypeDTO, key)) {
            if (OptionTagTypeDTO[key as keyof typeof OptionTagTypeDTO] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OptionTagTypeDTOFromJSON(json: any): OptionTagTypeDTO {
    return OptionTagTypeDTOFromJSONTyped(json, false);
}

export function OptionTagTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagTypeDTO {
    return json as OptionTagTypeDTO;
}

export function OptionTagTypeDTOToJSON(value?: OptionTagTypeDTO | null): any {
    return value as any;
}

export function OptionTagTypeDTOToJSONTyped(value: any, ignoreDiscriminator: boolean): OptionTagTypeDTO {
    return value as OptionTagTypeDTO;
}


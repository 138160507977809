/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ModelGenerationSortField = {
    SORT_INDEX: 'SORT_INDEX'
} as const;
export type ModelGenerationSortField = typeof ModelGenerationSortField[keyof typeof ModelGenerationSortField];


export function instanceOfModelGenerationSortField(value: any): boolean {
    for (const key in ModelGenerationSortField) {
        if (Object.prototype.hasOwnProperty.call(ModelGenerationSortField, key)) {
            if (ModelGenerationSortField[key as keyof typeof ModelGenerationSortField] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ModelGenerationSortFieldFromJSON(json: any): ModelGenerationSortField {
    return ModelGenerationSortFieldFromJSONTyped(json, false);
}

export function ModelGenerationSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelGenerationSortField {
    return json as ModelGenerationSortField;
}

export function ModelGenerationSortFieldToJSON(value?: ModelGenerationSortField | null): any {
    return value as any;
}

export function ModelGenerationSortFieldToJSONTyped(value: any, ignoreDiscriminator: boolean): ModelGenerationSortField {
    return value as ModelGenerationSortField;
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DerivativeItemDTO } from './DerivativeItemDTO';
import {
    DerivativeItemDTOFromJSON,
    DerivativeItemDTOFromJSONTyped,
    DerivativeItemDTOToJSON,
    DerivativeItemDTOToJSONTyped,
} from './DerivativeItemDTO';

/**
 * 
 * @export
 * @interface PageDTODerivativeItemDTO
 */
export interface PageDTODerivativeItemDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTODerivativeItemDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<DerivativeItemDTO>}
     * @memberof PageDTODerivativeItemDTO
     */
    content: Array<DerivativeItemDTO>;
}

/**
 * Check if a given object implements the PageDTODerivativeItemDTO interface.
 */
export function instanceOfPageDTODerivativeItemDTO(value: object): value is PageDTODerivativeItemDTO {
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function PageDTODerivativeItemDTOFromJSON(json: any): PageDTODerivativeItemDTO {
    return PageDTODerivativeItemDTOFromJSONTyped(json, false);
}

export function PageDTODerivativeItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTODerivativeItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(DerivativeItemDTOFromJSON)),
    };
}

export function PageDTODerivativeItemDTOToJSON(json: any): PageDTODerivativeItemDTO {
    return PageDTODerivativeItemDTOToJSONTyped(json, false);
}

export function PageDTODerivativeItemDTOToJSONTyped(value?: PageDTODerivativeItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'totalItemCount': value['totalItemCount'],
        'content': ((value['content'] as Array<any>).map(DerivativeItemDTOToJSON)),
    };
}


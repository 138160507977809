/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LinkedCustomizationDTOCustomizationValue } from './LinkedCustomizationDTOCustomizationValue';
import {
    LinkedCustomizationDTOCustomizationValueFromJSON,
    LinkedCustomizationDTOCustomizationValueFromJSONTyped,
    LinkedCustomizationDTOCustomizationValueToJSON,
    LinkedCustomizationDTOCustomizationValueToJSONTyped,
} from './LinkedCustomizationDTOCustomizationValue';

/**
 * 
 * @export
 * @interface LinkedCustomizationDTOCustomization
 */
export interface LinkedCustomizationDTOCustomization {
    /**
     * 
     * @type {string}
     * @memberof LinkedCustomizationDTOCustomization
     */
    key: string;
    /**
     * 
     * @type {LinkedCustomizationDTOCustomizationValue}
     * @memberof LinkedCustomizationDTOCustomization
     */
    value?: LinkedCustomizationDTOCustomizationValue;
}

/**
 * Check if a given object implements the LinkedCustomizationDTOCustomization interface.
 */
export function instanceOfLinkedCustomizationDTOCustomization(value: object): value is LinkedCustomizationDTOCustomization {
    if (!('key' in value) || value['key'] === undefined) return false;
    return true;
}

export function LinkedCustomizationDTOCustomizationFromJSON(json: any): LinkedCustomizationDTOCustomization {
    return LinkedCustomizationDTOCustomizationFromJSONTyped(json, false);
}

export function LinkedCustomizationDTOCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedCustomizationDTOCustomization {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'value': json['value'] == null ? undefined : LinkedCustomizationDTOCustomizationValueFromJSON(json['value']),
    };
}

export function LinkedCustomizationDTOCustomizationToJSON(json: any): LinkedCustomizationDTOCustomization {
    return LinkedCustomizationDTOCustomizationToJSONTyped(json, false);
}

export function LinkedCustomizationDTOCustomizationToJSONTyped(value?: LinkedCustomizationDTOCustomization | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'value': LinkedCustomizationDTOCustomizationValueToJSON(value['value']),
    };
}


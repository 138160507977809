/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionalSelectDTOEngineType } from './OptionalSelectDTOEngineType';
import {
    OptionalSelectDTOEngineTypeFromJSON,
    OptionalSelectDTOEngineTypeFromJSONTyped,
    OptionalSelectDTOEngineTypeToJSON,
    OptionalSelectDTOEngineTypeToJSONTyped,
} from './OptionalSelectDTOEngineType';
import type { RelatedGroupedOptionalSelectDTOStringString } from './RelatedGroupedOptionalSelectDTOStringString';
import {
    RelatedGroupedOptionalSelectDTOStringStringFromJSON,
    RelatedGroupedOptionalSelectDTOStringStringFromJSONTyped,
    RelatedGroupedOptionalSelectDTOStringStringToJSON,
    RelatedGroupedOptionalSelectDTOStringStringToJSONTyped,
} from './RelatedGroupedOptionalSelectDTOStringString';
import type { RelatedOptionalSelectDTOStringString } from './RelatedOptionalSelectDTOStringString';
import {
    RelatedOptionalSelectDTOStringStringFromJSON,
    RelatedOptionalSelectDTOStringStringFromJSONTyped,
    RelatedOptionalSelectDTOStringStringToJSON,
    RelatedOptionalSelectDTOStringStringToJSONTyped,
} from './RelatedOptionalSelectDTOStringString';
import type { MasterDataSource } from './MasterDataSource';
import {
    MasterDataSourceFromJSON,
    MasterDataSourceFromJSONTyped,
    MasterDataSourceToJSON,
    MasterDataSourceToJSONTyped,
} from './MasterDataSource';
import type { OptionalSelectDTODriveTrain } from './OptionalSelectDTODriveTrain';
import {
    OptionalSelectDTODriveTrainFromJSON,
    OptionalSelectDTODriveTrainFromJSONTyped,
    OptionalSelectDTODriveTrainToJSON,
    OptionalSelectDTODriveTrainToJSONTyped,
} from './OptionalSelectDTODriveTrain';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';
import type { OptionalSelectDTONumberOfSeats } from './OptionalSelectDTONumberOfSeats';
import {
    OptionalSelectDTONumberOfSeatsFromJSON,
    OptionalSelectDTONumberOfSeatsFromJSONTyped,
    OptionalSelectDTONumberOfSeatsToJSON,
    OptionalSelectDTONumberOfSeatsToJSONTyped,
} from './OptionalSelectDTONumberOfSeats';
import type { OrderTypeAttributesDTOBaseName } from './OrderTypeAttributesDTOBaseName';
import {
    OrderTypeAttributesDTOBaseNameFromJSON,
    OrderTypeAttributesDTOBaseNameFromJSONTyped,
    OrderTypeAttributesDTOBaseNameToJSON,
    OrderTypeAttributesDTOBaseNameToJSONTyped,
} from './OrderTypeAttributesDTOBaseName';

/**
 * 
 * @export
 * @interface OrderTypeAttributesDTO
 */
export interface OrderTypeAttributesDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeAttributesDTO
     */
    codes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrderTypeAttributesDTO
     */
    modelYear: number;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OrderTypeAttributesDTO
     */
    furtherModelYears: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {RelatedGroupedOptionalSelectDTOStringString}
     * @memberof OrderTypeAttributesDTO
     */
    modelSeriesRelatedModelGeneration: RelatedGroupedOptionalSelectDTOStringString;
    /**
     * 
     * @type {RelatedOptionalSelectDTOStringString}
     * @memberof OrderTypeAttributesDTO
     */
    modelSeriesRelatedDerivative: RelatedOptionalSelectDTOStringString;
    /**
     * 
     * @type {MasterDataSource}
     * @memberof OrderTypeAttributesDTO
     */
    dataSource: MasterDataSource;
    /**
     * 
     * @type {OptionalSelectDTODriveTrain}
     * @memberof OrderTypeAttributesDTO
     */
    driveTrain: OptionalSelectDTODriveTrain;
    /**
     * 
     * @type {OptionalSelectDTOEngineType}
     * @memberof OrderTypeAttributesDTO
     */
    engineType: OptionalSelectDTOEngineType;
    /**
     * 
     * @type {OptionalSelectDTONumberOfSeats}
     * @memberof OrderTypeAttributesDTO
     */
    numberOfSeats: OptionalSelectDTONumberOfSeats;
    /**
     * 
     * @type {OrderTypeAttributesDTOBaseName}
     * @memberof OrderTypeAttributesDTO
     */
    name: OrderTypeAttributesDTOBaseName;
    /**
     * 
     * @type {boolean}
     * @memberof OrderTypeAttributesDTO
     */
    special: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderTypeAttributesDTO
     */
    limited: boolean;
}



/**
 * Check if a given object implements the OrderTypeAttributesDTO interface.
 */
export function instanceOfOrderTypeAttributesDTO(value: object): value is OrderTypeAttributesDTO {
    if (!('codes' in value) || value['codes'] === undefined) return false;
    if (!('modelYear' in value) || value['modelYear'] === undefined) return false;
    if (!('furtherModelYears' in value) || value['furtherModelYears'] === undefined) return false;
    if (!('modelSeriesRelatedModelGeneration' in value) || value['modelSeriesRelatedModelGeneration'] === undefined) return false;
    if (!('modelSeriesRelatedDerivative' in value) || value['modelSeriesRelatedDerivative'] === undefined) return false;
    if (!('dataSource' in value) || value['dataSource'] === undefined) return false;
    if (!('driveTrain' in value) || value['driveTrain'] === undefined) return false;
    if (!('engineType' in value) || value['engineType'] === undefined) return false;
    if (!('numberOfSeats' in value) || value['numberOfSeats'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('special' in value) || value['special'] === undefined) return false;
    if (!('limited' in value) || value['limited'] === undefined) return false;
    return true;
}

export function OrderTypeAttributesDTOFromJSON(json: any): OrderTypeAttributesDTO {
    return OrderTypeAttributesDTOFromJSONTyped(json, false);
}

export function OrderTypeAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeAttributesDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'codes': json['codes'],
        'modelYear': json['modelYear'],
        'furtherModelYears': ((json['furtherModelYears'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'modelSeriesRelatedModelGeneration': RelatedGroupedOptionalSelectDTOStringStringFromJSON(json['modelSeriesRelatedModelGeneration']),
        'modelSeriesRelatedDerivative': RelatedOptionalSelectDTOStringStringFromJSON(json['modelSeriesRelatedDerivative']),
        'dataSource': MasterDataSourceFromJSON(json['dataSource']),
        'driveTrain': OptionalSelectDTODriveTrainFromJSON(json['driveTrain']),
        'engineType': OptionalSelectDTOEngineTypeFromJSON(json['engineType']),
        'numberOfSeats': OptionalSelectDTONumberOfSeatsFromJSON(json['numberOfSeats']),
        'name': OrderTypeAttributesDTOBaseNameFromJSON(json['name']),
        'special': json['special'],
        'limited': json['limited'],
    };
}

export function OrderTypeAttributesDTOToJSON(json: any): OrderTypeAttributesDTO {
    return OrderTypeAttributesDTOToJSONTyped(json, false);
}

export function OrderTypeAttributesDTOToJSONTyped(value?: OrderTypeAttributesDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'codes': value['codes'],
        'modelYear': value['modelYear'],
        'furtherModelYears': ((value['furtherModelYears'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'modelSeriesRelatedModelGeneration': RelatedGroupedOptionalSelectDTOStringStringToJSON(value['modelSeriesRelatedModelGeneration']),
        'modelSeriesRelatedDerivative': RelatedOptionalSelectDTOStringStringToJSON(value['modelSeriesRelatedDerivative']),
        'dataSource': MasterDataSourceToJSON(value['dataSource']),
        'driveTrain': OptionalSelectDTODriveTrainToJSON(value['driveTrain']),
        'engineType': OptionalSelectDTOEngineTypeToJSON(value['engineType']),
        'numberOfSeats': OptionalSelectDTONumberOfSeatsToJSON(value['numberOfSeats']),
        'name': OrderTypeAttributesDTOBaseNameToJSON(value['name']),
        'special': value['special'],
        'limited': value['limited'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionMarketplaceSpecificsIndexDTOValues } from './OptionMarketplaceSpecificsIndexDTOValues';
import {
    OptionMarketplaceSpecificsIndexDTOValuesFromJSON,
    OptionMarketplaceSpecificsIndexDTOValuesFromJSONTyped,
    OptionMarketplaceSpecificsIndexDTOValuesToJSON,
    OptionMarketplaceSpecificsIndexDTOValuesToJSONTyped,
} from './OptionMarketplaceSpecificsIndexDTOValues';

/**
 * 
 * @export
 * @interface OptionMarketplaceSpecificsIndexDTO
 */
export interface OptionMarketplaceSpecificsIndexDTO {
    /**
     * 
     * @type {OptionMarketplaceSpecificsIndexDTOValues}
     * @memberof OptionMarketplaceSpecificsIndexDTO
     */
    globalValues?: OptionMarketplaceSpecificsIndexDTOValues;
    /**
     * 
     * @type {{ [key: string]: OptionMarketplaceSpecificsIndexDTOValues; }}
     * @memberof OptionMarketplaceSpecificsIndexDTO
     */
    marketplaceValues: { [key: string]: OptionMarketplaceSpecificsIndexDTOValues; };
}

/**
 * Check if a given object implements the OptionMarketplaceSpecificsIndexDTO interface.
 */
export function instanceOfOptionMarketplaceSpecificsIndexDTO(value: object): value is OptionMarketplaceSpecificsIndexDTO {
    if (!('marketplaceValues' in value) || value['marketplaceValues'] === undefined) return false;
    return true;
}

export function OptionMarketplaceSpecificsIndexDTOFromJSON(json: any): OptionMarketplaceSpecificsIndexDTO {
    return OptionMarketplaceSpecificsIndexDTOFromJSONTyped(json, false);
}

export function OptionMarketplaceSpecificsIndexDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionMarketplaceSpecificsIndexDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'globalValues': json['globalValues'] == null ? undefined : OptionMarketplaceSpecificsIndexDTOValuesFromJSON(json['globalValues']),
        'marketplaceValues': (mapValues(json['marketplaceValues'], OptionMarketplaceSpecificsIndexDTOValuesFromJSON)),
    };
}

export function OptionMarketplaceSpecificsIndexDTOToJSON(json: any): OptionMarketplaceSpecificsIndexDTO {
    return OptionMarketplaceSpecificsIndexDTOToJSONTyped(json, false);
}

export function OptionMarketplaceSpecificsIndexDTOToJSONTyped(value?: OptionMarketplaceSpecificsIndexDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'globalValues': OptionMarketplaceSpecificsIndexDTOValuesToJSON(value['globalValues']),
        'marketplaceValues': (mapValues(value['marketplaceValues'], OptionMarketplaceSpecificsIndexDTOValuesToJSON)),
    };
}


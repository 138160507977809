/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { OrderTypeOptionItemDTOCustomTailoring } from './OrderTypeOptionItemDTOCustomTailoring';
import {
    instanceOfOrderTypeOptionItemDTOCustomTailoring,
    OrderTypeOptionItemDTOCustomTailoringFromJSON,
    OrderTypeOptionItemDTOCustomTailoringFromJSONTyped,
    OrderTypeOptionItemDTOCustomTailoringToJSON,
} from './OrderTypeOptionItemDTOCustomTailoring';
import type { OrderTypeOptionItemDTOExteriorColor } from './OrderTypeOptionItemDTOExteriorColor';
import {
    instanceOfOrderTypeOptionItemDTOExteriorColor,
    OrderTypeOptionItemDTOExteriorColorFromJSON,
    OrderTypeOptionItemDTOExteriorColorFromJSONTyped,
    OrderTypeOptionItemDTOExteriorColorToJSON,
} from './OrderTypeOptionItemDTOExteriorColor';
import type { OrderTypeOptionItemDTOIndividualEquipment } from './OrderTypeOptionItemDTOIndividualEquipment';
import {
    instanceOfOrderTypeOptionItemDTOIndividualEquipment,
    OrderTypeOptionItemDTOIndividualEquipmentFromJSON,
    OrderTypeOptionItemDTOIndividualEquipmentFromJSONTyped,
    OrderTypeOptionItemDTOIndividualEquipmentToJSON,
} from './OrderTypeOptionItemDTOIndividualEquipment';
import type { OrderTypeOptionItemDTOInterior } from './OrderTypeOptionItemDTOInterior';
import {
    instanceOfOrderTypeOptionItemDTOInterior,
    OrderTypeOptionItemDTOInteriorFromJSON,
    OrderTypeOptionItemDTOInteriorFromJSONTyped,
    OrderTypeOptionItemDTOInteriorToJSON,
} from './OrderTypeOptionItemDTOInterior';
import type { OrderTypeOptionItemDTOPaintToSampleExteriorColor } from './OrderTypeOptionItemDTOPaintToSampleExteriorColor';
import {
    instanceOfOrderTypeOptionItemDTOPaintToSampleExteriorColor,
    OrderTypeOptionItemDTOPaintToSampleExteriorColorFromJSON,
    OrderTypeOptionItemDTOPaintToSampleExteriorColorFromJSONTyped,
    OrderTypeOptionItemDTOPaintToSampleExteriorColorToJSON,
} from './OrderTypeOptionItemDTOPaintToSampleExteriorColor';
import type { OrderTypeOptionItemDTORoofColor } from './OrderTypeOptionItemDTORoofColor';
import {
    instanceOfOrderTypeOptionItemDTORoofColor,
    OrderTypeOptionItemDTORoofColorFromJSON,
    OrderTypeOptionItemDTORoofColorFromJSONTyped,
    OrderTypeOptionItemDTORoofColorToJSON,
} from './OrderTypeOptionItemDTORoofColor';
import type { OrderTypeOptionItemDTOSalesPackage } from './OrderTypeOptionItemDTOSalesPackage';
import {
    instanceOfOrderTypeOptionItemDTOSalesPackage,
    OrderTypeOptionItemDTOSalesPackageFromJSON,
    OrderTypeOptionItemDTOSalesPackageFromJSONTyped,
    OrderTypeOptionItemDTOSalesPackageToJSON,
} from './OrderTypeOptionItemDTOSalesPackage';
import type { OrderTypeOptionItemDTOTechnicalPackage } from './OrderTypeOptionItemDTOTechnicalPackage';
import {
    instanceOfOrderTypeOptionItemDTOTechnicalPackage,
    OrderTypeOptionItemDTOTechnicalPackageFromJSON,
    OrderTypeOptionItemDTOTechnicalPackageFromJSONTyped,
    OrderTypeOptionItemDTOTechnicalPackageToJSON,
} from './OrderTypeOptionItemDTOTechnicalPackage';
import type { OrderTypeOptionItemDTOUnclassifiedEquipment } from './OrderTypeOptionItemDTOUnclassifiedEquipment';
import {
    instanceOfOrderTypeOptionItemDTOUnclassifiedEquipment,
    OrderTypeOptionItemDTOUnclassifiedEquipmentFromJSON,
    OrderTypeOptionItemDTOUnclassifiedEquipmentFromJSONTyped,
    OrderTypeOptionItemDTOUnclassifiedEquipmentToJSON,
} from './OrderTypeOptionItemDTOUnclassifiedEquipment';
import type { OrderTypeOptionItemDTOZOrder } from './OrderTypeOptionItemDTOZOrder';
import {
    instanceOfOrderTypeOptionItemDTOZOrder,
    OrderTypeOptionItemDTOZOrderFromJSON,
    OrderTypeOptionItemDTOZOrderFromJSONTyped,
    OrderTypeOptionItemDTOZOrderToJSON,
} from './OrderTypeOptionItemDTOZOrder';

/**
 * @type OrderTypeOptionItemDTO
 * 
 * @export
 */
export type OrderTypeOptionItemDTO = { type: 'CustomTailoring' } & OrderTypeOptionItemDTOCustomTailoring | { type: 'ExteriorColor' } & OrderTypeOptionItemDTOExteriorColor | { type: 'IndividualEquipment' } & OrderTypeOptionItemDTOIndividualEquipment | { type: 'Interior' } & OrderTypeOptionItemDTOInterior | { type: 'PaintToSampleExteriorColor' } & OrderTypeOptionItemDTOPaintToSampleExteriorColor | { type: 'RoofColor' } & OrderTypeOptionItemDTORoofColor | { type: 'SalesPackage' } & OrderTypeOptionItemDTOSalesPackage | { type: 'TechnicalPackage' } & OrderTypeOptionItemDTOTechnicalPackage | { type: 'UnclassifiedEquipment' } & OrderTypeOptionItemDTOUnclassifiedEquipment | { type: 'ZOrder' } & OrderTypeOptionItemDTOZOrder;

export function OrderTypeOptionItemDTOFromJSON(json: any): OrderTypeOptionItemDTO {
    return OrderTypeOptionItemDTOFromJSONTyped(json, false);
}

export function OrderTypeOptionItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeOptionItemDTO {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'CustomTailoring':
            return Object.assign({}, OrderTypeOptionItemDTOCustomTailoringFromJSONTyped(json, true), { type: 'CustomTailoring' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OrderTypeOptionItemDTOExteriorColorFromJSONTyped(json, true), { type: 'ExteriorColor' } as const);
        case 'IndividualEquipment':
            return Object.assign({}, OrderTypeOptionItemDTOIndividualEquipmentFromJSONTyped(json, true), { type: 'IndividualEquipment' } as const);
        case 'Interior':
            return Object.assign({}, OrderTypeOptionItemDTOInteriorFromJSONTyped(json, true), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OrderTypeOptionItemDTOPaintToSampleExteriorColorFromJSONTyped(json, true), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OrderTypeOptionItemDTORoofColorFromJSONTyped(json, true), { type: 'RoofColor' } as const);
        case 'SalesPackage':
            return Object.assign({}, OrderTypeOptionItemDTOSalesPackageFromJSONTyped(json, true), { type: 'SalesPackage' } as const);
        case 'TechnicalPackage':
            return Object.assign({}, OrderTypeOptionItemDTOTechnicalPackageFromJSONTyped(json, true), { type: 'TechnicalPackage' } as const);
        case 'UnclassifiedEquipment':
            return Object.assign({}, OrderTypeOptionItemDTOUnclassifiedEquipmentFromJSONTyped(json, true), { type: 'UnclassifiedEquipment' } as const);
        case 'ZOrder':
            return Object.assign({}, OrderTypeOptionItemDTOZOrderFromJSONTyped(json, true), { type: 'ZOrder' } as const);
        default:
            throw new Error(`No variant of OrderTypeOptionItemDTO exists with 'type=${json['type']}'`);
    }
}

export function OrderTypeOptionItemDTOToJSON(json: any): any {
    return OrderTypeOptionItemDTOToJSONTyped(json, false);
}

export function OrderTypeOptionItemDTOToJSONTyped(value?: OrderTypeOptionItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'CustomTailoring':
            return Object.assign({}, OrderTypeOptionItemDTOCustomTailoringToJSON(value), { type: 'CustomTailoring' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OrderTypeOptionItemDTOExteriorColorToJSON(value), { type: 'ExteriorColor' } as const);
        case 'IndividualEquipment':
            return Object.assign({}, OrderTypeOptionItemDTOIndividualEquipmentToJSON(value), { type: 'IndividualEquipment' } as const);
        case 'Interior':
            return Object.assign({}, OrderTypeOptionItemDTOInteriorToJSON(value), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OrderTypeOptionItemDTOPaintToSampleExteriorColorToJSON(value), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OrderTypeOptionItemDTORoofColorToJSON(value), { type: 'RoofColor' } as const);
        case 'SalesPackage':
            return Object.assign({}, OrderTypeOptionItemDTOSalesPackageToJSON(value), { type: 'SalesPackage' } as const);
        case 'TechnicalPackage':
            return Object.assign({}, OrderTypeOptionItemDTOTechnicalPackageToJSON(value), { type: 'TechnicalPackage' } as const);
        case 'UnclassifiedEquipment':
            return Object.assign({}, OrderTypeOptionItemDTOUnclassifiedEquipmentToJSON(value), { type: 'UnclassifiedEquipment' } as const);
        case 'ZOrder':
            return Object.assign({}, OrderTypeOptionItemDTOZOrderToJSON(value), { type: 'ZOrder' } as const);
        default:
            throw new Error(`No variant of OrderTypeOptionItemDTO exists with 'type=${value['type']}'`);
    }

}


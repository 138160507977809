import { routes } from "../../../common/routes"
import { IncompleteCustomizationDTO, OptionCustomizationTypeDTO } from "../../../generated/pdsapi"
import { dashboardApi } from "../../../pdsapi"
import { DashboardPagedTableProps } from "../dashboardpagedtable/DashboardPagedTable"
import { incompleteCustomizationTableColumns } from "./parts/IncompleteCustomizationTableColumns"

export const IncompleteInteriorCustomizationsTableConfig: DashboardPagedTableProps<IncompleteCustomizationDTO> = {
    titlePrefix: "Incomplete",
    itemsName: "Interior Customizations",
    getRoute: (incompleteCustomization) =>
        routes.options.customizations.details({
            type: OptionCustomizationTypeDTO.INTERIOR,
            key: incompleteCustomization.key,
        }),
    urlParamsPrefix: "interior-customizations.incomplete",
    columns: incompleteCustomizationTableColumns,
    fetchPage: dashboardApi.getIncompleteInteriorCustomizations,
}

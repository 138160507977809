/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OptionClassDTO = {
    EXTERIOR_COLOR: 'EXTERIOR_COLOR',
    PAINT_TO_SAMPLE_EXTERIOR_COLOR: 'PAINT_TO_SAMPLE_EXTERIOR_COLOR',
    INTERIOR: 'INTERIOR',
    ROOF_COLOR: 'ROOF_COLOR',
    INDIVIDUAL_EQUIPMENT: 'INDIVIDUAL_EQUIPMENT',
    CUSTOM_TAILORING: 'CUSTOM_TAILORING',
    Z_ORDER: 'Z_ORDER',
    SALES_PACKAGE: 'SALES_PACKAGE',
    TECHNICAL_PACKAGE: 'TECHNICAL_PACKAGE',
    UNCLASSIFIED_EQUIPMENT: 'UNCLASSIFIED_EQUIPMENT'
} as const;
export type OptionClassDTO = typeof OptionClassDTO[keyof typeof OptionClassDTO];


export function instanceOfOptionClassDTO(value: any): boolean {
    for (const key in OptionClassDTO) {
        if (Object.prototype.hasOwnProperty.call(OptionClassDTO, key)) {
            if (OptionClassDTO[key as keyof typeof OptionClassDTO] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OptionClassDTOFromJSON(json: any): OptionClassDTO {
    return OptionClassDTOFromJSONTyped(json, false);
}

export function OptionClassDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionClassDTO {
    return json as OptionClassDTO;
}

export function OptionClassDTOToJSON(value?: OptionClassDTO | null): any {
    return value as any;
}

export function OptionClassDTOToJSONTyped(value: any, ignoreDiscriminator: boolean): OptionClassDTO {
    return value as OptionClassDTO;
}


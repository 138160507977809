/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BaseNameDTO
 */
export interface BaseNameDTO {
    /**
     * 
     * @type {string}
     * @memberof BaseNameDTO
     */
    en?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseNameDTO
     */
    de?: string;
}

/**
 * Check if a given object implements the BaseNameDTO interface.
 */
export function instanceOfBaseNameDTO(value: object): value is BaseNameDTO {
    return true;
}

export function BaseNameDTOFromJSON(json: any): BaseNameDTO {
    return BaseNameDTOFromJSONTyped(json, false);
}

export function BaseNameDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseNameDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'en': json['en'] == null ? undefined : json['en'],
        'de': json['de'] == null ? undefined : json['de'],
    };
}

export function BaseNameDTOToJSON(json: any): BaseNameDTO {
    return BaseNameDTOToJSONTyped(json, false);
}

export function BaseNameDTOToJSONTyped(value?: BaseNameDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'en': value['en'],
        'de': value['de'],
    };
}


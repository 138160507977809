/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EquipmentTagCategory = {
    STEERING: 'STEERING',
    PERFORMANCE: 'PERFORMANCE',
    E_PERFORMANCE: 'E_PERFORMANCE',
    TRANSMISSION: 'TRANSMISSION',
    WHEEL_SIZE: 'WHEEL_SIZE',
    EXTERIOR_EQUIPMENT: 'EXTERIOR_EQUIPMENT',
    INTERIOR_EQUIPMENT: 'INTERIOR_EQUIPMENT',
    INTERIOR_STITCHING: 'INTERIOR_STITCHING',
    AUDIO_COMM: 'AUDIO_COMM',
    COMFORT_ASSISTANCE: 'COMFORT_ASSISTANCE',
    OPTION_PACKAGE: 'OPTION_PACKAGE'
} as const;
export type EquipmentTagCategory = typeof EquipmentTagCategory[keyof typeof EquipmentTagCategory];


export function instanceOfEquipmentTagCategory(value: any): boolean {
    for (const key in EquipmentTagCategory) {
        if (Object.prototype.hasOwnProperty.call(EquipmentTagCategory, key)) {
            if (EquipmentTagCategory[key as keyof typeof EquipmentTagCategory] === value) {
                return true;
            }
        }
    }
    return false;
}

export function EquipmentTagCategoryFromJSON(json: any): EquipmentTagCategory {
    return EquipmentTagCategoryFromJSONTyped(json, false);
}

export function EquipmentTagCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentTagCategory {
    return json as EquipmentTagCategory;
}

export function EquipmentTagCategoryToJSON(value?: EquipmentTagCategory | null): any {
    return value as any;
}

export function EquipmentTagCategoryToJSONTyped(value: any, ignoreDiscriminator: boolean): EquipmentTagCategory {
    return value as EquipmentTagCategory;
}


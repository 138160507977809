/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DerivativeSortField = {
    SORT_INDEX: 'SORT_INDEX'
} as const;
export type DerivativeSortField = typeof DerivativeSortField[keyof typeof DerivativeSortField];


export function instanceOfDerivativeSortField(value: any): boolean {
    for (const key in DerivativeSortField) {
        if (Object.prototype.hasOwnProperty.call(DerivativeSortField, key)) {
            if (DerivativeSortField[key as keyof typeof DerivativeSortField] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DerivativeSortFieldFromJSON(json: any): DerivativeSortField {
    return DerivativeSortFieldFromJSONTyped(json, false);
}

export function DerivativeSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): DerivativeSortField {
    return json as DerivativeSortField;
}

export function DerivativeSortFieldToJSON(value?: DerivativeSortField | null): any {
    return value as any;
}

export function DerivativeSortFieldToJSONTyped(value: any, ignoreDiscriminator: boolean): DerivativeSortField {
    return value as DerivativeSortField;
}


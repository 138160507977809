/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LinkedCustomizationDTOCustomizationValueInteriorColorTag
 */
export interface LinkedCustomizationDTOCustomizationValueInteriorColorTag {
    /**
     * 
     * @type {string}
     * @memberof LinkedCustomizationDTOCustomizationValueInteriorColorTag
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof LinkedCustomizationDTOCustomizationValueInteriorColorTag
     */
    hexCode: string;
    /**
     * 
     * @type {string}
     * @memberof LinkedCustomizationDTOCustomizationValueInteriorColorTag
     */
    name?: string;
}

/**
 * Check if a given object implements the LinkedCustomizationDTOCustomizationValueInteriorColorTag interface.
 */
export function instanceOfLinkedCustomizationDTOCustomizationValueInteriorColorTag(value: object): value is LinkedCustomizationDTOCustomizationValueInteriorColorTag {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('hexCode' in value) || value['hexCode'] === undefined) return false;
    return true;
}

export function LinkedCustomizationDTOCustomizationValueInteriorColorTagFromJSON(json: any): LinkedCustomizationDTOCustomizationValueInteriorColorTag {
    return LinkedCustomizationDTOCustomizationValueInteriorColorTagFromJSONTyped(json, false);
}

export function LinkedCustomizationDTOCustomizationValueInteriorColorTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedCustomizationDTOCustomizationValueInteriorColorTag {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'hexCode': json['hexCode'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function LinkedCustomizationDTOCustomizationValueInteriorColorTagToJSON(json: any): LinkedCustomizationDTOCustomizationValueInteriorColorTag {
    return LinkedCustomizationDTOCustomizationValueInteriorColorTagToJSONTyped(json, false);
}

export function LinkedCustomizationDTOCustomizationValueInteriorColorTagToJSONTyped(value?: LinkedCustomizationDTOCustomizationValueInteriorColorTag | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'hexCode': value['hexCode'],
        'name': value['name'],
    };
}


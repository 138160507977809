/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OptionTagCreateOptionsDTO,
  OptionTagCreateRequestDTO,
  SimpleOrValidationApiError,
  TypedKeyDTOOptionTagTypeDTO,
} from '../models/index';
import {
    OptionTagCreateOptionsDTOFromJSON,
    OptionTagCreateOptionsDTOToJSON,
    OptionTagCreateRequestDTOFromJSON,
    OptionTagCreateRequestDTOToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    TypedKeyDTOOptionTagTypeDTOFromJSON,
    TypedKeyDTOOptionTagTypeDTOToJSON,
} from '../models/index';

export interface CreateOptionTagRequest {
    optionTagCreateRequestDTO: OptionTagCreateRequestDTO;
}

export interface GetOptionTagCreateOptionsRequest {
    languageTag: string;
}

/**
 * 
 */
export class OptionTagCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new OptionTag
     */
    async createOptionTagRaw(requestParameters: CreateOptionTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TypedKeyDTOOptionTagTypeDTO>> {
        if (requestParameters['optionTagCreateRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'optionTagCreateRequestDTO',
                'Required parameter "optionTagCreateRequestDTO" was null or undefined when calling createOptionTag().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-tags/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OptionTagCreateRequestDTOToJSON(requestParameters['optionTagCreateRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypedKeyDTOOptionTagTypeDTOFromJSON(jsonValue));
    }

    /**
     * Create a new OptionTag
     */
    async createOptionTag(requestParameters: CreateOptionTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TypedKeyDTOOptionTagTypeDTO> {
        const response = await this.createOptionTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get select options to create OptionTags
     */
    async getOptionTagCreateOptionsRaw(requestParameters: GetOptionTagCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionTagCreateOptionsDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getOptionTagCreateOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-tags/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionTagCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create OptionTags
     */
    async getOptionTagCreateOptions(requestParameters: GetOptionTagCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionTagCreateOptionsDTO> {
        const response = await this.getOptionTagCreateOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

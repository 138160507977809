import { routes } from "../../../../common/routes"
import { getEnumConverterGroup, getObjectURLParamConverter } from "../../../../common/useURLSearchParamState"
import { ColorItemDTO, ColorSortField, SortDirection } from "../../../../generated/pdsapi"
import { colorApi, ColorFilter } from "../../../../pdsapi"
import { getProductListContainer, ProductListContainerConfig } from "../../../../viewtemplates/list/ProductListContainer"
import { ColorListColumnsConfig } from "./columns/ColorListColumns"
import { Route } from "react-router-dom"

export const ColorListContainerConfig: ProductListContainerConfig<ColorItemDTO, ColorFilter, unknown, ColorSortField> = {
    listName: "Option Colors",
    createItemLabel: "Add new Option Color",

    buildDetailsPath: (color) => routes.options.colors.details(color.colorCode),
    buildCreatePath: routes.options.colors.create,
    buildSortPath: undefined,

    defaultFilter: {},
    filterUrlParamsConverter: getObjectURLParamConverter({}),
    defaultSorting: {
        sortField: ColorSortField.COLOR_CODE,
        sortDirection: SortDirection.ASC,
    },
    sortingUrlParamsConverter: getObjectURLParamConverter({
        sortField: getEnumConverterGroup(ColorSortField).required,
        sortDirection: getEnumConverterGroup(SortDirection).required,
    }),
    fetchPage: colorApi.list.getPage,
    fetchFilterOptions: async () => ({}),

    defaultShowsFilter: false,
    Filter: () => null,
    filterTagsConfig: () => [],

    columnsConfig: ColorListColumnsConfig,
}

const ColorListContainer = getProductListContainer(ColorListContainerConfig)

export const ColorListRoute = <Route path={routes.options.colors.list()} Component={ColorListContainer} />

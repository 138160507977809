import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { OptionTagFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { OptionTagFilter } from "../../../../../pdsapi"
import { findOption } from "../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import { ActiveTag } from "../../../../../components/filteredpagedtable/filtertags/activetag/ActiveTag"
import { filterTagsFor } from "../../../../../components/filteredpagedtable/filtertags/filterTagsFor"

export const OptionTagListFilterTagsConfig: TableFilterTagsConfig<OptionTagFilter, OptionTagFilterOptionsDTO> = ({ filter, setFilter, filterOptions }) => [
    ...filterTagsFor(filter, setFilter, "types", (type) => findOption(filterOptions?.types, type)?.label ?? type),
    ...(filter.active !== undefined
        ? [
              <ActiveTag
                  key={"active_tag"}
                  active={filter.active}
                  removeActiveFlag={() =>
                      setFilter((findParams) => ({
                          ...findParams,
                          active: undefined,
                      }))
                  }
              />,
          ]
        : []),
    ...filterTagsFor(filter, setFilter, "equipmentTagCategories", (category) => findOption(filterOptions?.equipmentTagCategories, category)?.label ?? category),
]

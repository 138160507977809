import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { OptionTagFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { OptionTagFilter } from "../../../../../pdsapi"
import { FunctionComponent } from "react"
import { ActiveFilter } from "../../../../../components/filteredpagedtable/filter/active/ActiveFilter"
import { MultiSelectFilter } from "../../../../../components/filter/MultiSelectFilter"

export const OptionTagListFilter: FunctionComponent<TableFilterProps<OptionTagFilter, OptionTagFilterOptionsDTO>> = ({ filter, setFilter, filterOptions }) => {
    const typeFilter = (
        <MultiSelectFilter
            label={"Types"}
            options={filterOptions?.types}
            value={filter.types}
            onChange={(types) =>
                setFilter((filter) => ({
                    ...filter,
                    types,
                }))
            }
        />
    )
    const activeFilter = (
        <ActiveFilter
            active={filter.active}
            onChange={(active) =>
                setFilter((filter) => ({
                    ...filter,
                    active,
                }))
            }
        />
    )
    const equipmentTagCategoryFilter = (
        <MultiSelectFilter
            label={"Equipment Tag Categories"}
            options={filterOptions?.equipmentTagCategories}
            value={filter.equipmentTagCategories}
            onChange={(equipmentTagCategories) =>
                setFilter((filter) => ({
                    ...filter,
                    equipmentTagCategories,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Type"}>{typeFilter}</ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Status"}>{activeFilter}</ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Category"}>{equipmentTagCategoryFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}

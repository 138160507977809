import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { CustomInteriorColorFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { CustomInteriorColorFilter } from "../../../../../pdsapi"
import { FunctionComponent } from "react"
import { MultiSelectFilter } from "../../../../../components/filter/MultiSelectFilter"

export const CustomInteriorColorListFilter: FunctionComponent<TableFilterProps<CustomInteriorColorFilter, CustomInteriorColorFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const interiorColorTagKeysFilter = (
        <MultiSelectFilter
            label={"Interior color tag"}
            options={filterOptions?.interiorColorTags}
            value={filter.interiorColorTagKeys}
            onChange={(interiorColorTagKeys) =>
                setFilter({
                    ...filter,
                    interiorColorTagKeys,
                })
            }
        />
    )
    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Interior color tag"}>{interiorColorTagKeysFilter}</ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseInformationDTO,
  LocalizationsItemDTO,
  OptionTagAttributesDTO,
  OptionTagAttributesUpdateDTOOptionTag,
  OptionTagTypeDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOToJSON,
    OptionTagAttributesDTOFromJSON,
    OptionTagAttributesDTOToJSON,
    OptionTagAttributesUpdateDTOOptionTagFromJSON,
    OptionTagAttributesUpdateDTOOptionTagToJSON,
    OptionTagTypeDTOFromJSON,
    OptionTagTypeDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface DeleteOptionTagRequest {
    type: OptionTagTypeDTO;
    key: string;
}

export interface GetAttributesOfOptionTagRequest {
    type: OptionTagTypeDTO;
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfOptionTagRequest {
    type: OptionTagTypeDTO;
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfNameOfOptionTagRequest {
    type: OptionTagTypeDTO;
    key: string;
}

export interface UpdateAttributesOfOptionTagRequest {
    type: OptionTagTypeDTO;
    key: string;
    optionTagAttributesUpdateDTOOptionTag: OptionTagAttributesUpdateDTOOptionTag;
}

export interface UpdateNameOfOptionTagRequest {
    type: OptionTagTypeDTO;
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class OptionTagDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a OptionTag by a given key
     */
    async deleteOptionTagRaw(requestParameters: DeleteOptionTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling deleteOptionTag().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling deleteOptionTag().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-tags/details/{type}/{key}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a OptionTag by a given key
     */
    async deleteOptionTag(requestParameters: DeleteOptionTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOptionTagRaw(requestParameters, initOverrides);
    }

    /**
     * Get attributes of OptionTag with given key
     */
    async getAttributesOfOptionTagRaw(requestParameters: GetAttributesOfOptionTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionTagAttributesDTO>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getAttributesOfOptionTag().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getAttributesOfOptionTag().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getAttributesOfOptionTag().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-tags/details/{type}/{key}/attributes`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionTagAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of OptionTag with given key
     */
    async getAttributesOfOptionTag(requestParameters: GetAttributesOfOptionTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionTagAttributesDTO> {
        const response = await this.getAttributesOfOptionTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get baseInformation of OptionTag with given key
     */
    async getBaseInformationOfOptionTagRaw(requestParameters: GetBaseInformationOfOptionTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getBaseInformationOfOptionTag().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getBaseInformationOfOptionTag().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getBaseInformationOfOptionTag().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-tags/details/{type}/{key}/base-information`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of OptionTag with given key
     */
    async getBaseInformationOfOptionTag(requestParameters: GetBaseInformationOfOptionTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfOptionTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get localizationsOfName of OptionTag with given key
     */
    async getLocalizationsOfNameOfOptionTagRaw(requestParameters: GetLocalizationsOfNameOfOptionTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: LocalizationsItemDTO; }>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getLocalizationsOfNameOfOptionTag().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getLocalizationsOfNameOfOptionTag().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-tags/details/{type}/{key}/name/localizations`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, LocalizationsItemDTOFromJSON));
    }

    /**
     * Get localizationsOfName of OptionTag with given key
     */
    async getLocalizationsOfNameOfOptionTag(requestParameters: GetLocalizationsOfNameOfOptionTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: LocalizationsItemDTO; }> {
        const response = await this.getLocalizationsOfNameOfOptionTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates attributes of an existing OptionTag
     */
    async updateAttributesOfOptionTagRaw(requestParameters: UpdateAttributesOfOptionTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling updateAttributesOfOptionTag().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateAttributesOfOptionTag().'
            );
        }

        if (requestParameters['optionTagAttributesUpdateDTOOptionTag'] == null) {
            throw new runtime.RequiredError(
                'optionTagAttributesUpdateDTOOptionTag',
                'Required parameter "optionTagAttributesUpdateDTOOptionTag" was null or undefined when calling updateAttributesOfOptionTag().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-tags/details/{type}/{key}/attributes`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OptionTagAttributesUpdateDTOOptionTagToJSON(requestParameters['optionTagAttributesUpdateDTOOptionTag']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing OptionTag
     */
    async updateAttributesOfOptionTag(requestParameters: UpdateAttributesOfOptionTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAttributesOfOptionTagRaw(requestParameters, initOverrides);
    }

    /**
     * Updates name of an existing OptionTag
     */
    async updateNameOfOptionTagRaw(requestParameters: UpdateNameOfOptionTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling updateNameOfOptionTag().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateNameOfOptionTag().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateNameOfOptionTag().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-tags/details/{type}/{key}/name`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates name of an existing OptionTag
     */
    async updateNameOfOptionTag(requestParameters: UpdateNameOfOptionTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNameOfOptionTagRaw(requestParameters, initOverrides);
    }

}

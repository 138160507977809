/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOEquipmentTagCategory } from './SelectOptionDTOEquipmentTagCategory';
import {
    SelectOptionDTOEquipmentTagCategoryFromJSON,
    SelectOptionDTOEquipmentTagCategoryFromJSONTyped,
    SelectOptionDTOEquipmentTagCategoryToJSON,
    SelectOptionDTOEquipmentTagCategoryToJSONTyped,
} from './SelectOptionDTOEquipmentTagCategory';

/**
 * 
 * @export
 * @interface OptionTagCreateOptionsDTO
 */
export interface OptionTagCreateOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOEquipmentTagCategory>}
     * @memberof OptionTagCreateOptionsDTO
     */
    equipmentTagCategories: Array<SelectOptionDTOEquipmentTagCategory>;
}

/**
 * Check if a given object implements the OptionTagCreateOptionsDTO interface.
 */
export function instanceOfOptionTagCreateOptionsDTO(value: object): value is OptionTagCreateOptionsDTO {
    if (!('equipmentTagCategories' in value) || value['equipmentTagCategories'] === undefined) return false;
    return true;
}

export function OptionTagCreateOptionsDTOFromJSON(json: any): OptionTagCreateOptionsDTO {
    return OptionTagCreateOptionsDTOFromJSONTyped(json, false);
}

export function OptionTagCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagCreateOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'equipmentTagCategories': ((json['equipmentTagCategories'] as Array<any>).map(SelectOptionDTOEquipmentTagCategoryFromJSON)),
    };
}

export function OptionTagCreateOptionsDTOToJSON(json: any): OptionTagCreateOptionsDTO {
    return OptionTagCreateOptionsDTOToJSONTyped(json, false);
}

export function OptionTagCreateOptionsDTOToJSONTyped(value?: OptionTagCreateOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'equipmentTagCategories': ((value['equipmentTagCategories'] as Array<any>).map(SelectOptionDTOEquipmentTagCategoryToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOInteriorMaterial } from './SelectOptionDTOInteriorMaterial';
import {
    SelectOptionDTOInteriorMaterialFromJSON,
    SelectOptionDTOInteriorMaterialFromJSONTyped,
    SelectOptionDTOInteriorMaterialToJSON,
    SelectOptionDTOInteriorMaterialToJSONTyped,
} from './SelectOptionDTOInteriorMaterial';
import type { SelectOptionDTOOptionCustomizationTypeDTO } from './SelectOptionDTOOptionCustomizationTypeDTO';
import {
    SelectOptionDTOOptionCustomizationTypeDTOFromJSON,
    SelectOptionDTOOptionCustomizationTypeDTOFromJSONTyped,
    SelectOptionDTOOptionCustomizationTypeDTOToJSON,
    SelectOptionDTOOptionCustomizationTypeDTOToJSONTyped,
} from './SelectOptionDTOOptionCustomizationTypeDTO';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';
import type { OptionGroupDTOString } from './OptionGroupDTOString';
import {
    OptionGroupDTOStringFromJSON,
    OptionGroupDTOStringFromJSONTyped,
    OptionGroupDTOStringToJSON,
    OptionGroupDTOStringToJSONTyped,
} from './OptionGroupDTOString';

/**
 * 
 * @export
 * @interface OptionCustomizationFilterOptionsDTO
 */
export interface OptionCustomizationFilterOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOOptionCustomizationTypeDTO>}
     * @memberof OptionCustomizationFilterOptionsDTO
     */
    types: Array<SelectOptionDTOOptionCustomizationTypeDTO>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationFilterOptionsDTO
     */
    modelGenerations: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<OptionGroupDTOString>}
     * @memberof OptionCustomizationFilterOptionsDTO
     */
    categorizedEquipmentTags: Array<OptionGroupDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationFilterOptionsDTO
     */
    interiorColorTags: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationFilterOptionsDTO
     */
    exteriorColorTags: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationFilterOptionsDTO
     */
    roofColorTags: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOInteriorMaterial>}
     * @memberof OptionCustomizationFilterOptionsDTO
     */
    interiorMaterials: Array<SelectOptionDTOInteriorMaterial>;
}

/**
 * Check if a given object implements the OptionCustomizationFilterOptionsDTO interface.
 */
export function instanceOfOptionCustomizationFilterOptionsDTO(value: object): value is OptionCustomizationFilterOptionsDTO {
    if (!('types' in value) || value['types'] === undefined) return false;
    if (!('modelGenerations' in value) || value['modelGenerations'] === undefined) return false;
    if (!('categorizedEquipmentTags' in value) || value['categorizedEquipmentTags'] === undefined) return false;
    if (!('interiorColorTags' in value) || value['interiorColorTags'] === undefined) return false;
    if (!('exteriorColorTags' in value) || value['exteriorColorTags'] === undefined) return false;
    if (!('roofColorTags' in value) || value['roofColorTags'] === undefined) return false;
    if (!('interiorMaterials' in value) || value['interiorMaterials'] === undefined) return false;
    return true;
}

export function OptionCustomizationFilterOptionsDTOFromJSON(json: any): OptionCustomizationFilterOptionsDTO {
    return OptionCustomizationFilterOptionsDTOFromJSONTyped(json, false);
}

export function OptionCustomizationFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationFilterOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'types': ((json['types'] as Array<any>).map(SelectOptionDTOOptionCustomizationTypeDTOFromJSON)),
        'modelGenerations': ((json['modelGenerations'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'categorizedEquipmentTags': ((json['categorizedEquipmentTags'] as Array<any>).map(OptionGroupDTOStringFromJSON)),
        'interiorColorTags': ((json['interiorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'exteriorColorTags': ((json['exteriorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'roofColorTags': ((json['roofColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'interiorMaterials': ((json['interiorMaterials'] as Array<any>).map(SelectOptionDTOInteriorMaterialFromJSON)),
    };
}

export function OptionCustomizationFilterOptionsDTOToJSON(json: any): OptionCustomizationFilterOptionsDTO {
    return OptionCustomizationFilterOptionsDTOToJSONTyped(json, false);
}

export function OptionCustomizationFilterOptionsDTOToJSONTyped(value?: OptionCustomizationFilterOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'types': ((value['types'] as Array<any>).map(SelectOptionDTOOptionCustomizationTypeDTOToJSON)),
        'modelGenerations': ((value['modelGenerations'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'categorizedEquipmentTags': ((value['categorizedEquipmentTags'] as Array<any>).map(OptionGroupDTOStringToJSON)),
        'interiorColorTags': ((value['interiorColorTags'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'exteriorColorTags': ((value['exteriorColorTags'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'roofColorTags': ((value['roofColorTags'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'interiorMaterials': ((value['interiorMaterials'] as Array<any>).map(SelectOptionDTOInteriorMaterialToJSON)),
    };
}


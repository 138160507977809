/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationTypeDTO } from './OptionCustomizationTypeDTO';
import {
    OptionCustomizationTypeDTOFromJSON,
    OptionCustomizationTypeDTOFromJSONTyped,
    OptionCustomizationTypeDTOToJSON,
    OptionCustomizationTypeDTOToJSONTyped,
} from './OptionCustomizationTypeDTO';

/**
 * 
 * @export
 * @interface SelectOptionDTOOptionCustomizationTypeDTO
 */
export interface SelectOptionDTOOptionCustomizationTypeDTO {
    /**
     * 
     * @type {OptionCustomizationTypeDTO}
     * @memberof SelectOptionDTOOptionCustomizationTypeDTO
     */
    key: OptionCustomizationTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDTOOptionCustomizationTypeDTO
     */
    label: string;
}



/**
 * Check if a given object implements the SelectOptionDTOOptionCustomizationTypeDTO interface.
 */
export function instanceOfSelectOptionDTOOptionCustomizationTypeDTO(value: object): value is SelectOptionDTOOptionCustomizationTypeDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function SelectOptionDTOOptionCustomizationTypeDTOFromJSON(json: any): SelectOptionDTOOptionCustomizationTypeDTO {
    return SelectOptionDTOOptionCustomizationTypeDTOFromJSONTyped(json, false);
}

export function SelectOptionDTOOptionCustomizationTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectOptionDTOOptionCustomizationTypeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': OptionCustomizationTypeDTOFromJSON(json['key']),
        'label': json['label'],
    };
}

export function SelectOptionDTOOptionCustomizationTypeDTOToJSON(json: any): SelectOptionDTOOptionCustomizationTypeDTO {
    return SelectOptionDTOOptionCustomizationTypeDTOToJSONTyped(json, false);
}

export function SelectOptionDTOOptionCustomizationTypeDTOToJSONTyped(value?: SelectOptionDTOOptionCustomizationTypeDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': OptionCustomizationTypeDTOToJSON(value['key']),
        'label': value['label'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SimpleOrValidationApiError,
  SortCategoryDTO,
  SortIndexUpdateRequestDTO,
} from '../models/index';
import {
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    SortCategoryDTOFromJSON,
    SortCategoryDTOToJSON,
    SortIndexUpdateRequestDTOFromJSON,
    SortIndexUpdateRequestDTOToJSON,
} from '../models/index';

export interface GetDerivativeSortableItemsRequest {
    languageTag: string;
}

export interface UpdateDerivativeSortIndexRequest {
    sortIndexUpdateRequestDTO: SortIndexUpdateRequestDTO;
}

/**
 * 
 */
export class DerivativeSortWebControllerApi extends runtime.BaseAPI {

    /**
     * Get sortable items of Derivatives
     */
    async getDerivativeSortableItemsRaw(requestParameters: GetDerivativeSortableItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SortCategoryDTO>>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getDerivativeSortableItems().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/sort/items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SortCategoryDTOFromJSON));
    }

    /**
     * Get sortable items of Derivatives
     */
    async getDerivativeSortableItems(requestParameters: GetDerivativeSortableItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SortCategoryDTO>> {
        const response = await this.getDerivativeSortableItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the sortIndex of multiple Derivatives
     */
    async updateDerivativeSortIndexRaw(requestParameters: UpdateDerivativeSortIndexRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['sortIndexUpdateRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'sortIndexUpdateRequestDTO',
                'Required parameter "sortIndexUpdateRequestDTO" was null or undefined when calling updateDerivativeSortIndex().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/derivatives/sort/_update-sortindices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SortIndexUpdateRequestDTOToJSON(requestParameters['sortIndexUpdateRequestDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the sortIndex of multiple Derivatives
     */
    async updateDerivativeSortIndex(requestParameters: UpdateDerivativeSortIndexRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDerivativeSortIndexRaw(requestParameters, initOverrides);
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EngineType = {
    PETROL: 'PETROL',
    DIESEL: 'DIESEL',
    PLUG_IN_HYBRID: 'PLUG_IN_HYBRID',
    MILD_HYBRID: 'MILD_HYBRID',
    ELECTRIC: 'ELECTRIC'
} as const;
export type EngineType = typeof EngineType[keyof typeof EngineType];


export function instanceOfEngineType(value: any): boolean {
    for (const key in EngineType) {
        if (Object.prototype.hasOwnProperty.call(EngineType, key)) {
            if (EngineType[key as keyof typeof EngineType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function EngineTypeFromJSON(json: any): EngineType {
    return EngineTypeFromJSONTyped(json, false);
}

export function EngineTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EngineType {
    return json as EngineType;
}

export function EngineTypeToJSON(value?: EngineType | null): any {
    return value as any;
}

export function EngineTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): EngineType {
    return value as EngineType;
}


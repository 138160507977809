/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';

/**
 * 
 * @export
 * @interface ModelSeriesAttributesDTO
 */
export interface ModelSeriesAttributesDTO {
    /**
     * 
     * @type {string}
     * @memberof ModelSeriesAttributesDTO
     */
    key: string;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof ModelSeriesAttributesDTO
     */
    name: BaseNameDTO;
}

/**
 * Check if a given object implements the ModelSeriesAttributesDTO interface.
 */
export function instanceOfModelSeriesAttributesDTO(value: object): value is ModelSeriesAttributesDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function ModelSeriesAttributesDTOFromJSON(json: any): ModelSeriesAttributesDTO {
    return ModelSeriesAttributesDTOFromJSONTyped(json, false);
}

export function ModelSeriesAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelSeriesAttributesDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': BaseNameDTOFromJSON(json['name']),
    };
}

export function ModelSeriesAttributesDTOToJSON(json: any): ModelSeriesAttributesDTO {
    return ModelSeriesAttributesDTOToJSONTyped(json, false);
}

export function ModelSeriesAttributesDTOToJSONTyped(value?: ModelSeriesAttributesDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'name': BaseNameDTOToJSON(value['name']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnmaintainedCustomColorDTO
 */
export interface UnmaintainedCustomColorDTO {
    /**
     * 
     * @type {string}
     * @memberof UnmaintainedCustomColorDTO
     */
    key: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnmaintainedCustomColorDTO
     */
    knownNames: Array<string>;
}

/**
 * Check if a given object implements the UnmaintainedCustomColorDTO interface.
 */
export function instanceOfUnmaintainedCustomColorDTO(value: object): value is UnmaintainedCustomColorDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('knownNames' in value) || value['knownNames'] === undefined) return false;
    return true;
}

export function UnmaintainedCustomColorDTOFromJSON(json: any): UnmaintainedCustomColorDTO {
    return UnmaintainedCustomColorDTOFromJSONTyped(json, false);
}

export function UnmaintainedCustomColorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnmaintainedCustomColorDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'knownNames': json['knownNames'],
    };
}

export function UnmaintainedCustomColorDTOToJSON(json: any): UnmaintainedCustomColorDTO {
    return UnmaintainedCustomColorDTOToJSONTyped(json, false);
}

export function UnmaintainedCustomColorDTOToJSONTyped(value?: UnmaintainedCustomColorDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'knownNames': value['knownNames'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOInteriorMaterial } from './SelectOptionDTOInteriorMaterial';
import {
    SelectOptionDTOInteriorMaterialFromJSON,
    SelectOptionDTOInteriorMaterialFromJSONTyped,
    SelectOptionDTOInteriorMaterialToJSON,
    SelectOptionDTOInteriorMaterialToJSONTyped,
} from './SelectOptionDTOInteriorMaterial';
import type { SelectOptionDTOOptionClassDTO } from './SelectOptionDTOOptionClassDTO';
import {
    SelectOptionDTOOptionClassDTOFromJSON,
    SelectOptionDTOOptionClassDTOFromJSONTyped,
    SelectOptionDTOOptionClassDTOToJSON,
    SelectOptionDTOOptionClassDTOToJSONTyped,
} from './SelectOptionDTOOptionClassDTO';
import type { SelectOptionDTOVehicleEquipmentCategory } from './SelectOptionDTOVehicleEquipmentCategory';
import {
    SelectOptionDTOVehicleEquipmentCategoryFromJSON,
    SelectOptionDTOVehicleEquipmentCategoryFromJSONTyped,
    SelectOptionDTOVehicleEquipmentCategoryToJSON,
    SelectOptionDTOVehicleEquipmentCategoryToJSONTyped,
} from './SelectOptionDTOVehicleEquipmentCategory';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';
import type { OptionGroupDTOString } from './OptionGroupDTOString';
import {
    OptionGroupDTOStringFromJSON,
    OptionGroupDTOStringFromJSONTyped,
    OptionGroupDTOStringToJSON,
    OptionGroupDTOStringToJSONTyped,
} from './OptionGroupDTOString';

/**
 * 
 * @export
 * @interface OrderTypeOptionFilterOptionsDTO
 */
export interface OrderTypeOptionFilterOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOOptionClassDTO>}
     * @memberof OrderTypeOptionFilterOptionsDTO
     */
    classes: Array<SelectOptionDTOOptionClassDTO>;
    /**
     * 
     * @type {Array<OptionGroupDTOString>}
     * @memberof OrderTypeOptionFilterOptionsDTO
     */
    categorizedEquipmentTags: Array<OptionGroupDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OrderTypeOptionFilterOptionsDTO
     */
    interiorColorTags: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OrderTypeOptionFilterOptionsDTO
     */
    exteriorColorTags: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OrderTypeOptionFilterOptionsDTO
     */
    roofColorTags: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOVehicleEquipmentCategory>}
     * @memberof OrderTypeOptionFilterOptionsDTO
     */
    vehicleEquipmentCategories: Array<SelectOptionDTOVehicleEquipmentCategory>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeOptionFilterOptionsDTO
     */
    equipmentFamilies: Array<string>;
    /**
     * 
     * @type {Array<SelectOptionDTOInteriorMaterial>}
     * @memberof OrderTypeOptionFilterOptionsDTO
     */
    interiorMaterials: Array<SelectOptionDTOInteriorMaterial>;
}

/**
 * Check if a given object implements the OrderTypeOptionFilterOptionsDTO interface.
 */
export function instanceOfOrderTypeOptionFilterOptionsDTO(value: object): value is OrderTypeOptionFilterOptionsDTO {
    if (!('classes' in value) || value['classes'] === undefined) return false;
    if (!('categorizedEquipmentTags' in value) || value['categorizedEquipmentTags'] === undefined) return false;
    if (!('interiorColorTags' in value) || value['interiorColorTags'] === undefined) return false;
    if (!('exteriorColorTags' in value) || value['exteriorColorTags'] === undefined) return false;
    if (!('roofColorTags' in value) || value['roofColorTags'] === undefined) return false;
    if (!('vehicleEquipmentCategories' in value) || value['vehicleEquipmentCategories'] === undefined) return false;
    if (!('equipmentFamilies' in value) || value['equipmentFamilies'] === undefined) return false;
    if (!('interiorMaterials' in value) || value['interiorMaterials'] === undefined) return false;
    return true;
}

export function OrderTypeOptionFilterOptionsDTOFromJSON(json: any): OrderTypeOptionFilterOptionsDTO {
    return OrderTypeOptionFilterOptionsDTOFromJSONTyped(json, false);
}

export function OrderTypeOptionFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeOptionFilterOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'classes': ((json['classes'] as Array<any>).map(SelectOptionDTOOptionClassDTOFromJSON)),
        'categorizedEquipmentTags': ((json['categorizedEquipmentTags'] as Array<any>).map(OptionGroupDTOStringFromJSON)),
        'interiorColorTags': ((json['interiorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'exteriorColorTags': ((json['exteriorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'roofColorTags': ((json['roofColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'vehicleEquipmentCategories': ((json['vehicleEquipmentCategories'] as Array<any>).map(SelectOptionDTOVehicleEquipmentCategoryFromJSON)),
        'equipmentFamilies': json['equipmentFamilies'],
        'interiorMaterials': ((json['interiorMaterials'] as Array<any>).map(SelectOptionDTOInteriorMaterialFromJSON)),
    };
}

export function OrderTypeOptionFilterOptionsDTOToJSON(json: any): OrderTypeOptionFilterOptionsDTO {
    return OrderTypeOptionFilterOptionsDTOToJSONTyped(json, false);
}

export function OrderTypeOptionFilterOptionsDTOToJSONTyped(value?: OrderTypeOptionFilterOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'classes': ((value['classes'] as Array<any>).map(SelectOptionDTOOptionClassDTOToJSON)),
        'categorizedEquipmentTags': ((value['categorizedEquipmentTags'] as Array<any>).map(OptionGroupDTOStringToJSON)),
        'interiorColorTags': ((value['interiorColorTags'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'exteriorColorTags': ((value['exteriorColorTags'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'roofColorTags': ((value['roofColorTags'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'vehicleEquipmentCategories': ((value['vehicleEquipmentCategories'] as Array<any>).map(SelectOptionDTOVehicleEquipmentCategoryToJSON)),
        'equipmentFamilies': value['equipmentFamilies'],
        'interiorMaterials': ((value['interiorMaterials'] as Array<any>).map(SelectOptionDTOInteriorMaterialToJSON)),
    };
}


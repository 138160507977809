/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RelatedOptionDTOStringString } from './RelatedOptionDTOStringString';
import {
    RelatedOptionDTOStringStringFromJSON,
    RelatedOptionDTOStringStringFromJSONTyped,
    RelatedOptionDTOStringStringToJSON,
    RelatedOptionDTOStringStringToJSONTyped,
} from './RelatedOptionDTOStringString';

/**
 * 
 * @export
 * @interface RelatedOptionalSelectDTOStringString
 */
export interface RelatedOptionalSelectDTOStringString {
    /**
     * 
     * @type {RelatedOptionDTOStringString}
     * @memberof RelatedOptionalSelectDTOStringString
     */
    value?: RelatedOptionDTOStringString;
    /**
     * 
     * @type {Array<RelatedOptionDTOStringString>}
     * @memberof RelatedOptionalSelectDTOStringString
     */
    relatedOptions: Array<RelatedOptionDTOStringString>;
}

/**
 * Check if a given object implements the RelatedOptionalSelectDTOStringString interface.
 */
export function instanceOfRelatedOptionalSelectDTOStringString(value: object): value is RelatedOptionalSelectDTOStringString {
    if (!('relatedOptions' in value) || value['relatedOptions'] === undefined) return false;
    return true;
}

export function RelatedOptionalSelectDTOStringStringFromJSON(json: any): RelatedOptionalSelectDTOStringString {
    return RelatedOptionalSelectDTOStringStringFromJSONTyped(json, false);
}

export function RelatedOptionalSelectDTOStringStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedOptionalSelectDTOStringString {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : RelatedOptionDTOStringStringFromJSON(json['value']),
        'relatedOptions': ((json['relatedOptions'] as Array<any>).map(RelatedOptionDTOStringStringFromJSON)),
    };
}

export function RelatedOptionalSelectDTOStringStringToJSON(json: any): RelatedOptionalSelectDTOStringString {
    return RelatedOptionalSelectDTOStringStringToJSONTyped(json, false);
}

export function RelatedOptionalSelectDTOStringStringToJSONTyped(value?: RelatedOptionalSelectDTOStringString | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': RelatedOptionDTOStringStringToJSON(value['value']),
        'relatedOptions': ((value['relatedOptions'] as Array<any>).map(RelatedOptionDTOStringStringToJSON)),
    };
}


import { propertiesOf } from "../../../../../../../common/property"
import { EditFormElementWrapper } from "../../../../../../../components/formelements/EditFormElementWrapper"
import { getNumberFormElement } from "../../../../../../../components/formelements/numberformelement/NumberFormElement"
import { getSelectTextFormElement } from "../../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTextFormElement } from "../../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../components/layouts/spaceditems/SpacedItems"
import {
    OptionCreateRequestDTO,
    OptionCreateRequestDTOCustomTailoring,
    OptionCreateRequestDTOExteriorColor,
    OptionCreateRequestDTOIndividualEquipment,
    OptionCreateRequestDTOPaintToSampleExteriorColor,
    OptionCreateRequestDTOSalesPackage,
    OptionCreateRequestDTOTechnicalPackage,
    OptionCreateRequestDTOZOrder,
    OptionCreateRequestOptionsDTO,
} from "../../../../../../../generated/pdsapi"
import { ProductCreateContentProps } from "../../../../../../../viewtemplates/create/ProductCreateContainer"
import { CreateDefaultLocalizationsRow } from "../../../../../../../viewtemplates/create/components/createdefaultlocalizationsrow/CreateDefaultLocalizationsRow"
import React, { FC, JSX } from "react"
import { toOption } from "../../../../../../../components/formelements/selecttextformelement/Option"
import { EditFormDisplayData, getEditData, LoadingFormDisplayData } from "../../../../../../../components/formelements/FormDisplayData"
import { creatableOptionClassOptions } from "../../optionTypeMappings"
import { PIcon, PText } from "@porsche-design-system/components-react"
import { getMultiTextFormElement } from "../../../../../../../components/formelements/multitextformelement/MultiTextFormElement"
import { getTagsFormElement } from "../../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { getBoolFormElement } from "../../../../../../../components/formelements/boolformelement/BoolFormElement"

export const OrderTypeOptionCreateOptionCard: FC<
    ProductCreateContentProps<OptionCreateRequestDTO, OptionCreateRequestOptionsDTO> & { orderTypeKey: string }
> = ({ formDisplayData, orderTypeKey }) => {
    const properties = propertiesOf<OptionCreateRequestDTO>()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <EditFormElementWrapper
                    label={"Order Type"}
                    formDisplayData={formDisplayData}
                    field={properties.orderTypeKey}
                    FormElement={getSelectTextFormElement<unknown, OptionCreateRequestDTO>()}
                    getEditProps={() => ({
                        options: [{ value: orderTypeKey, label: orderTypeKey }],
                        value: orderTypeKey,
                        readOnly: true,
                    })}
                />
                <EditFormElementWrapper
                    label={"Class"}
                    formDisplayData={formDisplayData}
                    field={properties.type}
                    FormElement={getSelectTextFormElement<OptionCreateRequestOptionsDTO, OptionCreateRequestDTO>()}
                    getEditProps={() => ({
                        options: creatableOptionClassOptions,
                        value: creatableOptionClassOptions[0].value,
                    })}
                />
                <EditFormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    field={properties.optionCode}
                    FormElement={getTextFormElement<unknown, OptionCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: "",
                    })}
                />
            </RowGroupWrapper>
            <RowGroupWrapper>
                <EditFormElementWrapper
                    label={"Sort Index"}
                    formDisplayData={formDisplayData}
                    field={properties.sortIndex}
                    FormElement={getNumberFormElement<unknown, OptionCreateRequestDTO>()}
                    getEditProps={() => ({
                        step: 1,
                        min: 0,
                        value: undefined,
                        optional: true,
                    })}
                />
            </RowGroupWrapper>

            <CreateDefaultLocalizationsRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getEditProps={() => ({
                    optional: true,
                    value: { de: "", en: "" },
                })}
            />

            <CreateDefaultLocalizationsRow
                label={"Description"}
                formDisplayData={formDisplayData}
                field={properties.description}
                getEditProps={() => ({
                    optional: true,
                    value: { de: "", en: "" },
                })}
            />

            <TypeSpecificFormFields formDisplayData={formDisplayData}></TypeSpecificFormFields>
        </SpacedItems>
    )
}

interface TypeSpecificFormFieldsProperties<
    CREATE_OPTIONS extends OptionCreateRequestOptionsDTO = OptionCreateRequestOptionsDTO,
    DATA extends OptionCreateRequestDTO = OptionCreateRequestDTO,
> {
    formDisplayData: LoadingFormDisplayData | EditFormDisplayData<CREATE_OPTIONS, DATA>
}

const TypeSpecificFormFields = (props: TypeSpecificFormFieldsProperties): JSX.Element | null => {
    const { formDisplayData } = props
    switch (getEditData(formDisplayData)?.watch("type")) {
        case "IndividualEquipment": {
            return (
                <RowGroupWrapper label={"Equipment Information"}>
                    <CommonEquipmentFields {...props}></CommonEquipmentFields>
                    <TwikitFlagField {...props}></TwikitFlagField>
                </RowGroupWrapper>
            )
        }
        case "CustomTailoring": {
            return (
                <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
                    <RowGroupWrapper label={"Equipment Information"}>
                        <CommonEquipmentFields {...props}></CommonEquipmentFields>
                        <TwikitFlagField {...props}></TwikitFlagField>
                        <LenaFlagField {...props}></LenaFlagField>
                    </RowGroupWrapper>
                    <RowGroupWrapper childrenSize={12}>
                        <AvailableColorsField {...props}></AvailableColorsField>
                    </RowGroupWrapper>
                </SpacedItems>
            )
        }
        case "ZOrder": {
            return (
                <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
                    <RowGroupWrapper label={"Equipment Information"}>
                        <CommonEquipmentFields {...props}></CommonEquipmentFields>
                        <LenaFlagField {...props}></LenaFlagField>
                    </RowGroupWrapper>
                    <RowGroupWrapper childrenSize={12}>
                        <AvailableColorsField {...props}></AvailableColorsField>
                    </RowGroupWrapper>
                </SpacedItems>
            )
        }
        case "SalesPackage": {
            return (
                <RowGroupWrapper label={"Equipment Information"}>
                    <CommonEquipmentFields {...props}></CommonEquipmentFields>
                </RowGroupWrapper>
            )
        }
        case "TechnicalPackage": {
            return (
                <RowGroupWrapper label={"Equipment Information"}>
                    <CommonEquipmentFields {...props}></CommonEquipmentFields>
                </RowGroupWrapper>
            )
        }
        case "Interior": {
            return null
        }
        case "RoofColor": {
            return null
        }
        case "ExteriorColor": {
            const properties = propertiesOf<OptionCreateRequestDTOExteriorColor>()

            return (
                <RowGroupWrapper>
                    <EditFormElementWrapper
                        label={"Exterior Color Type"}
                        formDisplayData={formDisplayData}
                        field={properties.exteriorColorType}
                        FormElement={getSelectTextFormElement<unknown, OptionCreateRequestDTO>()}
                        getEditProps={(options) => ({
                            options: [{ value: "", label: "-" }, ...options.exteriorColorTypes.map(toOption)],
                            value: "",
                            optional: true,
                        })}
                    />
                    <EditFormElementWrapper
                        label={"Exterior Color Group"}
                        formDisplayData={formDisplayData}
                        field={properties.exteriorColorGroup}
                        FormElement={getSelectTextFormElement<unknown, OptionCreateRequestDTO>()}
                        getEditProps={(options) => ({
                            options: [{ value: "", label: "-" }, ...options.exteriorColorGroups.map(toOption)],
                            value: "",
                            optional: true,
                        })}
                    />
                </RowGroupWrapper>
            )
        }

        case "PaintToSampleExteriorColor": {
            const properties = propertiesOf<OptionCreateRequestDTOPaintToSampleExteriorColor>()

            return (
                <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
                    <RowGroupWrapper label={"References"}>
                        <EditFormElementWrapper
                            label={"Paint to Sample Equipment Option Code"}
                            formDisplayData={formDisplayData}
                            field={properties.paintToSampleEquipmentOptionCode}
                            FormElement={getTextFormElement<unknown, OptionCreateRequestDTO>()}
                            getEditProps={() => ({
                                value: "",
                                readOnly: false,
                                optional: true,
                            })}
                        />
                        <EditFormElementWrapper
                            label={"Paint to Sample Plus Equipment Option Code"}
                            formDisplayData={formDisplayData}
                            field={properties.paintToSamplePlusEquipmentOptionCode}
                            FormElement={getTextFormElement<unknown, OptionCreateRequestDTO>()}
                            getEditProps={() => ({
                                value: "",
                                readOnly: false,
                                optional: true,
                            })}
                        />
                    </RowGroupWrapper>
                    <RowGroupWrapper childrenSize={8}>
                        <EditFormElementWrapper
                            label={"Z-Order Option Code(s)"}
                            formDisplayData={formDisplayData}
                            field={properties.zOrderOptionCodes}
                            FormElement={getMultiTextFormElement<unknown, OptionCreateRequestDTO>()}
                            getEditProps={() => ({
                                value: [],
                                readOnly: false,
                                optional: true,
                            })}
                        />
                        <div style={{ display: "flex", gap: "4px" }}>
                            <PIcon name={"information"} color={"contrast-medium"}></PIcon>
                            <PText color={"neutral-contrast-medium"} size={"x-small"}>
                                Comma-separated list of Z-Order Options from which to take the colors for this Paint to Sample Option (e.g. "24931, 25401").
                                Ideally this field contains only one option code, but on some older order types we have found more than one.
                            </PText>
                        </div>
                    </RowGroupWrapper>
                </SpacedItems>
            )
        }
        case undefined:
            return null
    }
}

const CommonEquipmentFields = (props: TypeSpecificFormFieldsProperties) => {
    type EquipmentCreateRequestDTO =
        | (OptionCreateRequestDTOIndividualEquipment & { type: "IndividualEquipment" })
        | (OptionCreateRequestDTOCustomTailoring & { type: "CustomTailoring" })
        | (OptionCreateRequestDTOZOrder & { type: "ZOrder" })
        | (OptionCreateRequestDTOSalesPackage & { type: "SalesPackage" })
        | (OptionCreateRequestDTOTechnicalPackage & { type: "TechnicalPackage" })
    const { formDisplayData } = props as TypeSpecificFormFieldsProperties<OptionCreateRequestOptionsDTO, EquipmentCreateRequestDTO>
    const properties = propertiesOf<EquipmentCreateRequestDTO>()
    return (
        <EditFormElementWrapper
            label={"Category"}
            formDisplayData={formDisplayData}
            field={properties.category}
            FormElement={getSelectTextFormElement<unknown, EquipmentCreateRequestDTO>()}
            getEditProps={(createOptions) => ({
                options: [
                    {
                        value: "",
                        label: "-",
                    },
                    ...createOptions.equipmentCategories.map(toOption),
                ],
                value: "",
                optional: true,
            })}
        />
    )
}

const TwikitFlagField = (props: TypeSpecificFormFieldsProperties) => {
    type EquipmentWithTwikitFlag =
        | (OptionCreateRequestDTOIndividualEquipment & { type: "IndividualEquipment" })
        | (OptionCreateRequestDTOCustomTailoring & { type: "CustomTailoring" })
    const { formDisplayData } = props as TypeSpecificFormFieldsProperties<OptionCreateRequestOptionsDTO, EquipmentWithTwikitFlag>
    const properties = propertiesOf<EquipmentWithTwikitFlag>()

    return (
        <EditFormElementWrapper
            label={"Is Twikit Option?"}
            hint={`Twikit marks an option that supports special configuration via the third-party Twikit configurator. 
            These options will be displayed differently in the car configurator.`}
            formDisplayData={formDisplayData}
            field={properties.twikit}
            FormElement={getBoolFormElement<OptionCreateRequestOptionsDTO, EquipmentWithTwikitFlag>()}
            getEditProps={() => ({
                value: false,
                trueMessage: "Yes",
                falseMessage: "No",
            })}
        />
    )
}

const LenaFlagField = (props: TypeSpecificFormFieldsProperties) => {
    type EquipmentWithLenaFlag = (OptionCreateRequestDTOZOrder & { type: "ZOrder" }) | (OptionCreateRequestDTOCustomTailoring & { type: "CustomTailoring" })
    const { formDisplayData } = props as TypeSpecificFormFieldsProperties<OptionCreateRequestOptionsDTO, EquipmentWithLenaFlag>
    const properties = propertiesOf<EquipmentWithLenaFlag>()

    return (
        <EditFormElementWrapper
            label={"Is LENA Option?"}
            hint={`LENA (Limited Edition Number Assignment) marks an option that is added to a limited edition Porsche 
            so it can be recognized as such. This option will receive a specific number value when added to a vehicle.`}
            formDisplayData={formDisplayData}
            field={properties.lena}
            FormElement={getBoolFormElement<OptionCreateRequestOptionsDTO, EquipmentWithLenaFlag>()}
            getEditProps={() => ({
                value: false,
                trueMessage: "Yes",
                falseMessage: "No",
            })}
        />
    )
}

const AvailableColorsField = (props: TypeSpecificFormFieldsProperties) => {
    type EquipmentWithColorsCreateRequestDTO =
        | (OptionCreateRequestDTOCustomTailoring & { type: "CustomTailoring" })
        | (OptionCreateRequestDTOZOrder & { type: "ZOrder" })
    const { formDisplayData } = props as TypeSpecificFormFieldsProperties<OptionCreateRequestOptionsDTO, EquipmentWithColorsCreateRequestDTO>
    const properties = propertiesOf<EquipmentWithColorsCreateRequestDTO>()
    return (
        <EditFormElementWrapper
            label={"Available Colors"}
            formDisplayData={formDisplayData}
            field={properties.availableColorCodes}
            FormElement={getTagsFormElement<OptionCreateRequestOptionsDTO, EquipmentWithColorsCreateRequestDTO>()}
            getEditProps={(data) => ({
                emptyLabel: "-",
                value: [],
                options: data.availableColors.map(toOption),
                clearable: true,
                optional: true,
            })}
        />
    )
}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment } from './OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment';
import {
    OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentFromJSON,
    OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentFromJSONTyped,
    OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentToJSON,
    OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentToJSONTyped,
} from './OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment';

/**
 * 
 * @export
 * @interface OptionMarketplaceSpecificsIndexDTOValues
 */
export interface OptionMarketplaceSpecificsIndexDTOValues {
    /**
     * 
     * @type {Date}
     * @memberof OptionMarketplaceSpecificsIndexDTOValues
     */
    validFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OptionMarketplaceSpecificsIndexDTOValues
     */
    validTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof OptionMarketplaceSpecificsIndexDTOValues
     */
    visibleForSale: boolean;
    /**
     * 
     * @type {OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment}
     * @memberof OptionMarketplaceSpecificsIndexDTOValues
     */
    isStandardEquipment?: OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipment;
}

/**
 * Check if a given object implements the OptionMarketplaceSpecificsIndexDTOValues interface.
 */
export function instanceOfOptionMarketplaceSpecificsIndexDTOValues(value: object): value is OptionMarketplaceSpecificsIndexDTOValues {
    if (!('visibleForSale' in value) || value['visibleForSale'] === undefined) return false;
    return true;
}

export function OptionMarketplaceSpecificsIndexDTOValuesFromJSON(json: any): OptionMarketplaceSpecificsIndexDTOValues {
    return OptionMarketplaceSpecificsIndexDTOValuesFromJSONTyped(json, false);
}

export function OptionMarketplaceSpecificsIndexDTOValuesFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionMarketplaceSpecificsIndexDTOValues {
    if (json == null) {
        return json;
    }
    return {
        
        'validFrom': json['validFrom'] == null ? undefined : (new Date(json['validFrom'])),
        'validTo': json['validTo'] == null ? undefined : (new Date(json['validTo'])),
        'visibleForSale': json['visibleForSale'],
        'isStandardEquipment': json['isStandardEquipment'] == null ? undefined : OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentFromJSON(json['isStandardEquipment']),
    };
}

export function OptionMarketplaceSpecificsIndexDTOValuesToJSON(json: any): OptionMarketplaceSpecificsIndexDTOValues {
    return OptionMarketplaceSpecificsIndexDTOValuesToJSONTyped(json, false);
}

export function OptionMarketplaceSpecificsIndexDTOValuesToJSONTyped(value?: OptionMarketplaceSpecificsIndexDTOValues | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'validFrom': value['validFrom'] == null ? undefined : ((value['validFrom']).toISOString().substring(0,10)),
        'validTo': value['validTo'] == null ? undefined : ((value['validTo']).toISOString().substring(0,10)),
        'visibleForSale': value['visibleForSale'],
        'isStandardEquipment': OptionMarketplaceSpecificsIndexDTOValuesIsStandardEquipmentToJSON(value['isStandardEquipment']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderTypePressEmbargoesDTOPressEmbargoes
 */
export interface OrderTypePressEmbargoesDTOPressEmbargoes {
    /**
     * 
     * @type {Date}
     * @memberof OrderTypePressEmbargoesDTOPressEmbargoes
     */
    synced?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderTypePressEmbargoesDTOPressEmbargoes
     */
    manual?: Date;
}

/**
 * Check if a given object implements the OrderTypePressEmbargoesDTOPressEmbargoes interface.
 */
export function instanceOfOrderTypePressEmbargoesDTOPressEmbargoes(value: object): value is OrderTypePressEmbargoesDTOPressEmbargoes {
    return true;
}

export function OrderTypePressEmbargoesDTOPressEmbargoesFromJSON(json: any): OrderTypePressEmbargoesDTOPressEmbargoes {
    return OrderTypePressEmbargoesDTOPressEmbargoesFromJSONTyped(json, false);
}

export function OrderTypePressEmbargoesDTOPressEmbargoesFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypePressEmbargoesDTOPressEmbargoes {
    if (json == null) {
        return json;
    }
    return {
        
        'synced': json['synced'] == null ? undefined : (new Date(json['synced'])),
        'manual': json['manual'] == null ? undefined : (new Date(json['manual'])),
    };
}

export function OrderTypePressEmbargoesDTOPressEmbargoesToJSON(json: any): OrderTypePressEmbargoesDTOPressEmbargoes {
    return OrderTypePressEmbargoesDTOPressEmbargoesToJSONTyped(json, false);
}

export function OrderTypePressEmbargoesDTOPressEmbargoesToJSONTyped(value?: OrderTypePressEmbargoesDTOPressEmbargoes | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'synced': value['synced'] == null ? undefined : ((value['synced']).toISOString()),
        'manual': value['manual'] == null ? undefined : ((value['manual']).toISOString()),
    };
}


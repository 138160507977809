/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue } from './OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue';
import {
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSON,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSON,
    OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSONTyped,
} from './OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue';
import type { OptionCustomizationConditionalValueUpdateDTOModelYearRange } from './OptionCustomizationConditionalValueUpdateDTOModelYearRange';
import {
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSON,
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSONTyped,
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSON,
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSONTyped,
} from './OptionCustomizationConditionalValueUpdateDTOModelYearRange';

/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue
 */
export interface OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue
     */
    key: string;
    /**
     * 
     * @type {OptionCustomizationConditionalValueUpdateDTOModelYearRange}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue
     */
    modelYearRange: OptionCustomizationConditionalValueUpdateDTOModelYearRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue
     */
    derivativeKeys?: Array<string>;
    /**
     * 
     * @type {OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue
     */
    value: OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue;
}

/**
 * Check if a given object implements the OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue interface.
 */
export function instanceOfOptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue(value: object): value is OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('modelYearRange' in value) || value['modelYearRange'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSON(json: any): OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue {
    return OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelYearRange': OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSON(json['modelYearRange']),
        'derivativeKeys': json['derivativeKeys'] == null ? undefined : json['derivativeKeys'],
        'value': OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueFromJSON(json['value']),
    };
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSON(json: any): OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue {
    return OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSONTyped(value?: OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'modelYearRange': OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSON(value['modelYearRange']),
        'derivativeKeys': value['derivativeKeys'],
        'value': OptionCustomizationAttributesUpdateDTOPaintToSampleExteriorColorValueToJSON(value['value']),
    };
}


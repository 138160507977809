import { JSX } from "react"
import { Tag } from "../../tag/Tag"

type KeyValue<KEY extends string | number | symbol, VALUE> = { [K in KEY]?: VALUE }
type SetGeneric<T extends Set<any> | undefined> = T extends Set<infer U> ? U : never

export const filterTagsFor = <PROPERTY extends keyof FILTER, FILTER extends KeyValue<PROPERTY, Set<unknown>>, VALUE extends SetGeneric<FILTER[PROPERTY]>>(
    filter: FILTER,
    updateFilter: (update: (filter: FILTER) => FILTER) => void,
    filterProperty: PROPERTY,
    label: (value: VALUE) => JSX.Element | string
): JSX.Element[] =>
    Array.from(filter[filterProperty] ?? [])?.map((value) => (
        <Tag
            key={`filterTag_${String(filterProperty)}_${value}`}
            onRemove={() =>
                updateFilter((filter) => {
                    const newValues = filter[filterProperty]?.difference(new Set([value]))
                    return {
                        ...filter,
                        [filterProperty]: newValues?.size !== 0 ? newValues : undefined,
                    }
                })
            }
        >
            {label(value as VALUE)}
        </Tag>
    )) ?? []

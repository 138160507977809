/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderTypePressEmbargoesDTOPressEmbargoes } from './OrderTypePressEmbargoesDTOPressEmbargoes';
import {
    OrderTypePressEmbargoesDTOPressEmbargoesFromJSON,
    OrderTypePressEmbargoesDTOPressEmbargoesFromJSONTyped,
    OrderTypePressEmbargoesDTOPressEmbargoesToJSON,
    OrderTypePressEmbargoesDTOPressEmbargoesToJSONTyped,
} from './OrderTypePressEmbargoesDTOPressEmbargoes';

/**
 * 
 * @export
 * @interface OrderTypePressEmbargoesDTO
 */
export interface OrderTypePressEmbargoesDTO {
    /**
     * 
     * @type {OrderTypePressEmbargoesDTOPressEmbargoes}
     * @memberof OrderTypePressEmbargoesDTO
     */
    globalPressEmbargo: OrderTypePressEmbargoesDTOPressEmbargoes;
    /**
     * 
     * @type {{ [key: string]: OrderTypePressEmbargoesDTOPressEmbargoes; }}
     * @memberof OrderTypePressEmbargoesDTO
     */
    marketplaceSpecificPressEmbargoes: { [key: string]: OrderTypePressEmbargoesDTOPressEmbargoes; };
}

/**
 * Check if a given object implements the OrderTypePressEmbargoesDTO interface.
 */
export function instanceOfOrderTypePressEmbargoesDTO(value: object): value is OrderTypePressEmbargoesDTO {
    if (!('globalPressEmbargo' in value) || value['globalPressEmbargo'] === undefined) return false;
    if (!('marketplaceSpecificPressEmbargoes' in value) || value['marketplaceSpecificPressEmbargoes'] === undefined) return false;
    return true;
}

export function OrderTypePressEmbargoesDTOFromJSON(json: any): OrderTypePressEmbargoesDTO {
    return OrderTypePressEmbargoesDTOFromJSONTyped(json, false);
}

export function OrderTypePressEmbargoesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypePressEmbargoesDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'globalPressEmbargo': OrderTypePressEmbargoesDTOPressEmbargoesFromJSON(json['globalPressEmbargo']),
        'marketplaceSpecificPressEmbargoes': (mapValues(json['marketplaceSpecificPressEmbargoes'], OrderTypePressEmbargoesDTOPressEmbargoesFromJSON)),
    };
}

export function OrderTypePressEmbargoesDTOToJSON(json: any): OrderTypePressEmbargoesDTO {
    return OrderTypePressEmbargoesDTOToJSONTyped(json, false);
}

export function OrderTypePressEmbargoesDTOToJSONTyped(value?: OrderTypePressEmbargoesDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'globalPressEmbargo': OrderTypePressEmbargoesDTOPressEmbargoesToJSON(value['globalPressEmbargo']),
        'marketplaceSpecificPressEmbargoes': (mapValues(value['marketplaceSpecificPressEmbargoes'], OrderTypePressEmbargoesDTOPressEmbargoesToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaseInformationDTO,
  CustomizationVideoUpdateDTO,
  OptionCustomizationAttributesDTOObject,
  OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject,
  OptionCustomizationLinksDTO,
  OptionCustomizationTypeDTO,
  OptionCustomizationValuesDTOMapStringLocalizationsItemDTO,
  OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
  VehicleStateSearchResult,
} from '../models/index';
import {
    BaseInformationDTOFromJSON,
    BaseInformationDTOToJSON,
    CustomizationVideoUpdateDTOFromJSON,
    CustomizationVideoUpdateDTOToJSON,
    OptionCustomizationAttributesDTOObjectFromJSON,
    OptionCustomizationAttributesDTOObjectToJSON,
    OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObjectFromJSON,
    OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObjectToJSON,
    OptionCustomizationLinksDTOFromJSON,
    OptionCustomizationLinksDTOToJSON,
    OptionCustomizationTypeDTOFromJSON,
    OptionCustomizationTypeDTOToJSON,
    OptionCustomizationValuesDTOMapStringLocalizationsItemDTOFromJSON,
    OptionCustomizationValuesDTOMapStringLocalizationsItemDTOToJSON,
    OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTOFromJSON,
    OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
    VehicleStateSearchResultFromJSON,
    VehicleStateSearchResultToJSON,
} from '../models/index';

export interface DeleteOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
}

export interface GetAttributesOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    languageTag: string;
}

export interface GetBaseInformationOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    languageTag: string;
}

export interface GetLinksOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    languageTag: string;
}

export interface GetLocalizationsOfDescriptionOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
}

export interface GetLocalizationsOfNameOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
}

export interface GetLocalizationsOfVideoOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
}

export interface GetVehicleImagesOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    limit?: number;
}

export interface UpdateAttributesOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    optionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject: OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject;
}

export interface UpdateDescriptionOfDefaultValueOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    conditionalValueKey: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfDefaultValueOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    conditionalValueKey: string;
    requestBody: { [key: string]: string; };
}

export interface UpdateVideoOfDefaultValueOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    customizationVideoUpdateDTO: CustomizationVideoUpdateDTO;
}

export interface UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest {
    type: OptionCustomizationTypeDTO;
    key: string;
    conditionalValueKey: string;
    customizationVideoUpdateDTO: CustomizationVideoUpdateDTO;
}

/**
 * 
 */
export class OptionCustomizationDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Delete a OptionCustomization by a given key
     */
    async deleteOptionCustomizationRaw(requestParameters: DeleteOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling deleteOptionCustomization().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling deleteOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a OptionCustomization by a given key
     */
    async deleteOptionCustomization(requestParameters: DeleteOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOptionCustomizationRaw(requestParameters, initOverrides);
    }

    /**
     * Get attributes of OptionCustomization with given key
     */
    async getAttributesOfOptionCustomizationRaw(requestParameters: GetAttributesOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionCustomizationAttributesDTOObject>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getAttributesOfOptionCustomization().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getAttributesOfOptionCustomization().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getAttributesOfOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/attributes`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationAttributesDTOObjectFromJSON(jsonValue));
    }

    /**
     * Get attributes of OptionCustomization with given key
     */
    async getAttributesOfOptionCustomization(requestParameters: GetAttributesOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionCustomizationAttributesDTOObject> {
        const response = await this.getAttributesOfOptionCustomizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get baseInformation of OptionCustomization with given key
     */
    async getBaseInformationOfOptionCustomizationRaw(requestParameters: GetBaseInformationOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BaseInformationDTO>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getBaseInformationOfOptionCustomization().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getBaseInformationOfOptionCustomization().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getBaseInformationOfOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/base-information`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseInformationDTOFromJSON(jsonValue));
    }

    /**
     * Get baseInformation of OptionCustomization with given key
     */
    async getBaseInformationOfOptionCustomization(requestParameters: GetBaseInformationOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BaseInformationDTO> {
        const response = await this.getBaseInformationOfOptionCustomizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get links of OptionCustomization with given key
     */
    async getLinksOfOptionCustomizationRaw(requestParameters: GetLinksOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionCustomizationLinksDTO>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getLinksOfOptionCustomization().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getLinksOfOptionCustomization().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getLinksOfOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/links`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationLinksDTOFromJSON(jsonValue));
    }

    /**
     * Get links of OptionCustomization with given key
     */
    async getLinksOfOptionCustomization(requestParameters: GetLinksOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionCustomizationLinksDTO> {
        const response = await this.getLinksOfOptionCustomizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get localizationsOfDescription of OptionCustomization with given key
     */
    async getLocalizationsOfDescriptionOfOptionCustomizationRaw(requestParameters: GetLocalizationsOfDescriptionOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionCustomizationValuesDTOMapStringLocalizationsItemDTO>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getLocalizationsOfDescriptionOfOptionCustomization().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getLocalizationsOfDescriptionOfOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/description/localizations`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationValuesDTOMapStringLocalizationsItemDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfDescription of OptionCustomization with given key
     */
    async getLocalizationsOfDescriptionOfOptionCustomization(requestParameters: GetLocalizationsOfDescriptionOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionCustomizationValuesDTOMapStringLocalizationsItemDTO> {
        const response = await this.getLocalizationsOfDescriptionOfOptionCustomizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get localizationsOfName of OptionCustomization with given key
     */
    async getLocalizationsOfNameOfOptionCustomizationRaw(requestParameters: GetLocalizationsOfNameOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionCustomizationValuesDTOMapStringLocalizationsItemDTO>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getLocalizationsOfNameOfOptionCustomization().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getLocalizationsOfNameOfOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/name/localizations`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationValuesDTOMapStringLocalizationsItemDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfName of OptionCustomization with given key
     */
    async getLocalizationsOfNameOfOptionCustomization(requestParameters: GetLocalizationsOfNameOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionCustomizationValuesDTOMapStringLocalizationsItemDTO> {
        const response = await this.getLocalizationsOfNameOfOptionCustomizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get localizationsOfVideo of OptionCustomization with given key
     */
    async getLocalizationsOfVideoOfOptionCustomizationRaw(requestParameters: GetLocalizationsOfVideoOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getLocalizationsOfVideoOfOptionCustomization().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getLocalizationsOfVideoOfOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/video/localizations`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTOFromJSON(jsonValue));
    }

    /**
     * Get localizationsOfVideo of OptionCustomization with given key
     */
    async getLocalizationsOfVideoOfOptionCustomization(requestParameters: GetLocalizationsOfVideoOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionCustomizationValuesDTOMapStringVideoLocalizationsItemDTO> {
        const response = await this.getLocalizationsOfVideoOfOptionCustomizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get vehicleImages of OptionCustomization with given key
     */
    async getVehicleImagesOfOptionCustomizationRaw(requestParameters: GetVehicleImagesOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleStateSearchResult>>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getVehicleImagesOfOptionCustomization().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getVehicleImagesOfOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/vehicle-images`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleStateSearchResultFromJSON));
    }

    /**
     * Get vehicleImages of OptionCustomization with given key
     */
    async getVehicleImagesOfOptionCustomization(requestParameters: GetVehicleImagesOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleStateSearchResult>> {
        const response = await this.getVehicleImagesOfOptionCustomizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates attributes of an existing OptionCustomization
     */
    async updateAttributesOfOptionCustomizationRaw(requestParameters: UpdateAttributesOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling updateAttributesOfOptionCustomization().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateAttributesOfOptionCustomization().'
            );
        }

        if (requestParameters['optionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject'] == null) {
            throw new runtime.RequiredError(
                'optionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject',
                'Required parameter "optionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject" was null or undefined when calling updateAttributesOfOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/attributes`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObjectToJSON(requestParameters['optionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing OptionCustomization
     */
    async updateAttributesOfOptionCustomization(requestParameters: UpdateAttributesOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAttributesOfOptionCustomizationRaw(requestParameters, initOverrides);
    }

    /**
     * Updates descriptionOfDefaultValue of an existing OptionCustomization
     */
    async updateDescriptionOfDefaultValueOfOptionCustomizationRaw(requestParameters: UpdateDescriptionOfDefaultValueOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling updateDescriptionOfDefaultValueOfOptionCustomization().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateDescriptionOfDefaultValueOfOptionCustomization().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateDescriptionOfDefaultValueOfOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/default-value/description`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates descriptionOfDefaultValue of an existing OptionCustomization
     */
    async updateDescriptionOfDefaultValueOfOptionCustomization(requestParameters: UpdateDescriptionOfDefaultValueOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDescriptionOfDefaultValueOfOptionCustomizationRaw(requestParameters, initOverrides);
    }

    /**
     * Updates descriptionOfValueOfConditionalValuesByConditionalValueKey of an existing OptionCustomization
     */
    async updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRaw(requestParameters: UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization().'
            );
        }

        if (requestParameters['conditionalValueKey'] == null) {
            throw new runtime.RequiredError(
                'conditionalValueKey',
                'Required parameter "conditionalValueKey" was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/conditional-values/{conditionalValueKey}/value/description`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters['conditionalValueKey']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates descriptionOfValueOfConditionalValuesByConditionalValueKey of an existing OptionCustomization
     */
    async updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization(requestParameters: UpdateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDescriptionOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRaw(requestParameters, initOverrides);
    }

    /**
     * Updates nameOfDefaultValue of an existing OptionCustomization
     */
    async updateNameOfDefaultValueOfOptionCustomizationRaw(requestParameters: UpdateNameOfDefaultValueOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling updateNameOfDefaultValueOfOptionCustomization().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateNameOfDefaultValueOfOptionCustomization().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateNameOfDefaultValueOfOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/default-value/name`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates nameOfDefaultValue of an existing OptionCustomization
     */
    async updateNameOfDefaultValueOfOptionCustomization(requestParameters: UpdateNameOfDefaultValueOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNameOfDefaultValueOfOptionCustomizationRaw(requestParameters, initOverrides);
    }

    /**
     * Updates nameOfValueOfConditionalValuesByConditionalValueKey of an existing OptionCustomization
     */
    async updateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRaw(requestParameters: UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization().'
            );
        }

        if (requestParameters['conditionalValueKey'] == null) {
            throw new runtime.RequiredError(
                'conditionalValueKey',
                'Required parameter "conditionalValueKey" was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/conditional-values/{conditionalValueKey}/value/name`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters['conditionalValueKey']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates nameOfValueOfConditionalValuesByConditionalValueKey of an existing OptionCustomization
     */
    async updateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization(requestParameters: UpdateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNameOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRaw(requestParameters, initOverrides);
    }

    /**
     * Updates videoOfDefaultValue of an existing OptionCustomization
     */
    async updateVideoOfDefaultValueOfOptionCustomizationRaw(requestParameters: UpdateVideoOfDefaultValueOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling updateVideoOfDefaultValueOfOptionCustomization().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateVideoOfDefaultValueOfOptionCustomization().'
            );
        }

        if (requestParameters['customizationVideoUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'customizationVideoUpdateDTO',
                'Required parameter "customizationVideoUpdateDTO" was null or undefined when calling updateVideoOfDefaultValueOfOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/default-value/video`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomizationVideoUpdateDTOToJSON(requestParameters['customizationVideoUpdateDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates videoOfDefaultValue of an existing OptionCustomization
     */
    async updateVideoOfDefaultValueOfOptionCustomization(requestParameters: UpdateVideoOfDefaultValueOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateVideoOfDefaultValueOfOptionCustomizationRaw(requestParameters, initOverrides);
    }

    /**
     * Updates videoOfValueOfConditionalValuesByConditionalValueKey of an existing OptionCustomization
     */
    async updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRaw(requestParameters: UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization().'
            );
        }

        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization().'
            );
        }

        if (requestParameters['conditionalValueKey'] == null) {
            throw new runtime.RequiredError(
                'conditionalValueKey',
                'Required parameter "conditionalValueKey" was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization().'
            );
        }

        if (requestParameters['customizationVideoUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'customizationVideoUpdateDTO',
                'Required parameter "customizationVideoUpdateDTO" was null or undefined when calling updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/option-customizations/details/{type}/{key}/conditional-values/{conditionalValueKey}/value/video`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))).replace(`{${"conditionalValueKey"}}`, encodeURIComponent(String(requestParameters['conditionalValueKey']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomizationVideoUpdateDTOToJSON(requestParameters['customizationVideoUpdateDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates videoOfValueOfConditionalValuesByConditionalValueKey of an existing OptionCustomization
     */
    async updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomization(requestParameters: UpdateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateVideoOfValueOfConditionalValuesByConditionalValueKeyOfOptionCustomizationRaw(requestParameters, initOverrides);
    }

}

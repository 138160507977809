/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ColorTileDTO } from './ColorTileDTO';
import {
    ColorTileDTOFromJSON,
    ColorTileDTOFromJSONTyped,
    ColorTileDTOToJSON,
    ColorTileDTOToJSONTyped,
} from './ColorTileDTO';

/**
 * 
 * @export
 * @interface CustomInteriorColorItemDTO
 */
export interface CustomInteriorColorItemDTO {
    /**
     * 
     * @type {string}
     * @memberof CustomInteriorColorItemDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof CustomInteriorColorItemDTO
     */
    hexCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomInteriorColorItemDTO
     */
    name: string;
    /**
     * 
     * @type {ColorTileDTO}
     * @memberof CustomInteriorColorItemDTO
     */
    interiorColorTag?: ColorTileDTO;
}

/**
 * Check if a given object implements the CustomInteriorColorItemDTO interface.
 */
export function instanceOfCustomInteriorColorItemDTO(value: object): value is CustomInteriorColorItemDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function CustomInteriorColorItemDTOFromJSON(json: any): CustomInteriorColorItemDTO {
    return CustomInteriorColorItemDTOFromJSONTyped(json, false);
}

export function CustomInteriorColorItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomInteriorColorItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'hexCode': json['hexCode'] == null ? undefined : json['hexCode'],
        'name': json['name'],
        'interiorColorTag': json['interiorColorTag'] == null ? undefined : ColorTileDTOFromJSON(json['interiorColorTag']),
    };
}

export function CustomInteriorColorItemDTOToJSON(json: any): CustomInteriorColorItemDTO {
    return CustomInteriorColorItemDTOToJSONTyped(json, false);
}

export function CustomInteriorColorItemDTOToJSONTyped(value?: CustomInteriorColorItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'hexCode': value['hexCode'],
        'name': value['name'],
        'interiorColorTag': ColorTileDTOToJSON(value['interiorColorTag']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OptionMarketplaceSpecificsIndexDTO,
  OptionTypeDTO,
  SimpleApiError,
} from '../models/index';
import {
    OptionMarketplaceSpecificsIndexDTOFromJSON,
    OptionMarketplaceSpecificsIndexDTOToJSON,
    OptionTypeDTOFromJSON,
    OptionTypeDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
} from '../models/index';

export interface GetMarketplaceSpecificsOfOptionRequest {
    orderTypeKey: string;
    optionCode: string;
    type: OptionTypeDTO;
}

/**
 * 
 */
export class OrderTypeOptionMarketplaceSpecificsDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get marketplaceSpecifics of Option with given key
     */
    async getMarketplaceSpecificsOfOptionRaw(requestParameters: GetMarketplaceSpecificsOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionMarketplaceSpecificsIndexDTO>> {
        if (requestParameters['orderTypeKey'] == null) {
            throw new runtime.RequiredError(
                'orderTypeKey',
                'Required parameter "orderTypeKey" was null or undefined when calling getMarketplaceSpecificsOfOption().'
            );
        }

        if (requestParameters['optionCode'] == null) {
            throw new runtime.RequiredError(
                'optionCode',
                'Required parameter "optionCode" was null or undefined when calling getMarketplaceSpecificsOfOption().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getMarketplaceSpecificsOfOption().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['optionCode'] != null) {
            queryParameters['optionCode'] = requestParameters['optionCode'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/{orderTypeKey}/option/marketplace-specifics`.replace(`{${"orderTypeKey"}}`, encodeURIComponent(String(requestParameters['orderTypeKey']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionMarketplaceSpecificsIndexDTOFromJSON(jsonValue));
    }

    /**
     * Get marketplaceSpecifics of Option with given key
     */
    async getMarketplaceSpecificsOfOption(requestParameters: GetMarketplaceSpecificsOfOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionMarketplaceSpecificsIndexDTO> {
        const response = await this.getMarketplaceSpecificsOfOptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionTagTypeDTO } from './OptionTagTypeDTO';
import {
    OptionTagTypeDTOFromJSON,
    OptionTagTypeDTOFromJSONTyped,
    OptionTagTypeDTOToJSON,
    OptionTagTypeDTOToJSONTyped,
} from './OptionTagTypeDTO';

/**
 * 
 * @export
 * @interface TypedKeyDTOOptionTagTypeDTO
 */
export interface TypedKeyDTOOptionTagTypeDTO {
    /**
     * 
     * @type {string}
     * @memberof TypedKeyDTOOptionTagTypeDTO
     */
    key: string;
    /**
     * 
     * @type {OptionTagTypeDTO}
     * @memberof TypedKeyDTOOptionTagTypeDTO
     */
    type: OptionTagTypeDTO;
}



/**
 * Check if a given object implements the TypedKeyDTOOptionTagTypeDTO interface.
 */
export function instanceOfTypedKeyDTOOptionTagTypeDTO(value: object): value is TypedKeyDTOOptionTagTypeDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function TypedKeyDTOOptionTagTypeDTOFromJSON(json: any): TypedKeyDTOOptionTagTypeDTO {
    return TypedKeyDTOOptionTagTypeDTOFromJSONTyped(json, false);
}

export function TypedKeyDTOOptionTagTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypedKeyDTOOptionTagTypeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'type': OptionTagTypeDTOFromJSON(json['type']),
    };
}

export function TypedKeyDTOOptionTagTypeDTOToJSON(json: any): TypedKeyDTOOptionTagTypeDTO {
    return TypedKeyDTOOptionTagTypeDTOToJSONTyped(json, false);
}

export function TypedKeyDTOOptionTagTypeDTOToJSONTyped(value?: TypedKeyDTOOptionTagTypeDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'type': OptionTagTypeDTOToJSON(value['type']),
    };
}


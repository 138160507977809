import { routes } from "../../../../../../../../common/routes"
import { TagFormElementView } from "../../../../../../../../components/formelements/tagformelement/TagFormElement"
import { TextFormElementView } from "../../../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../../../components/layouts/spaceditems/SpacedItems"
import { LinkedCustomizationDTOCustomization, LinkedCustomizationDTOCustomizationValue } from "../../../../../../../../generated/pdsapi"
import { optionCustomizationTypeToEnum } from "../../../../../../../options/customizations/optionCustomizationTypes"
import { ViewLabeledContentWrapper } from "../../../../../../../../components/formelements/FormElementWrapper"
import { ExteriorColorTile, InteriorColorTile } from "@finder/ui-kit"
import { NamedColorTile } from "../../../../../../../../components/tiles/namedcolortile/NamedColorTile"

export const LinkedOptionCustomizationCardContent = ({ customization }: { customization: LinkedCustomizationDTOCustomization }) =>
    (customization.value && (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <TagFormElementView
                    label={undefined}
                    tag={{
                        label: "Jump to customization",
                        route: routes.options.customizations.details({
                            type: optionCustomizationTypeToEnum(customization.value.type),
                            key: customization.key,
                        }),
                    }}
                />
            </RowGroupWrapper>
            <RowGroupWrapper label={"Name"}>
                {(["de", "en"] as ("en" | "de")[]).map((languageTag) => (
                    <TextFormElementView key={languageTag} label={`Name - ${languageTag}`} value={customization.value?.name[languageTag]} />
                ))}
            </RowGroupWrapper>
            <SpecificCustomizationValueContent value={customization.value} />
        </SpacedItems>
    )) || <></>

const SpecificCustomizationValueContent = ({ value }: { value: LinkedCustomizationDTOCustomizationValue }) => {
    switch (value.type) {
        case "ExteriorColor": {
            return (
                <RowGroupWrapper label={"Attributes"} childrenSize={12}>
                    <TextFormElementView label={"Exterior Color Type"} value={value?.colorTypeName} />
                    <ViewLabeledContentWrapper label={"Color"}>{value ? <ExteriorColorTile color={value.hexCode} /> : "-"}</ViewLabeledContentWrapper>
                    <ViewLabeledContentWrapper label={"Exterior Color Tag"}>
                        {value?.colorTag ? <NamedColorTile label={value?.colorTag.name ?? "-"} hexCode={value?.colorTag.hexCode} /> : "-"}
                    </ViewLabeledContentWrapper>
                </RowGroupWrapper>
            )
        }
        case "PaintToSampleExteriorColor": {
            return <></>
        }
        case "Interior": {
            return (
                <RowGroupWrapper label={"Attributes"} childrenSize={12}>
                    <TextFormElementView label={"Interior Material"} value={value?.materialName ?? "-"} />
                    <ViewLabeledContentWrapper label={"Color"}>
                        {value ? (
                            <InteriorColorTile
                                seatColor={value.color.seatStitchingColorHexCode}
                                seatStitchingColor={value.color.seatStitchingColorHexCode}
                                cockpitColor={value.color.cockpitColorHexCode}
                                cockpitStitchingColor={value.color.cockpitStitchingColorHexCode}
                            />
                        ) : (
                            "-"
                        )}
                    </ViewLabeledContentWrapper>
                    <ViewLabeledContentWrapper label={"Interior Color Tag"}>
                        {value?.colorTag ? <NamedColorTile label={value.colorTag.name ?? "-"} hexCode={value.colorTag.hexCode} /> : "-"}
                    </ViewLabeledContentWrapper>
                </RowGroupWrapper>
            )
        }
        case "RoofColor": {
            return (
                <RowGroupWrapper label={"Attributes"} childrenSize={12}>
                    <ViewLabeledContentWrapper label={"Color"}>{value ? <ExteriorColorTile color={value.hexCode} /> : "-"}</ViewLabeledContentWrapper>
                    <ViewLabeledContentWrapper label={"Roof Color Tag"}>
                        {value?.colorTag ? <NamedColorTile label={value?.colorTag.name ?? "-"} hexCode={value?.colorTag.hexCode} /> : "-"}
                    </ViewLabeledContentWrapper>
                </RowGroupWrapper>
            )
        }
    }
}

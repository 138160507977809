/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Transmission } from './Transmission';
import {
    TransmissionFromJSON,
    TransmissionFromJSONTyped,
    TransmissionToJSON,
    TransmissionToJSONTyped,
} from './Transmission';

/**
 * 
 * @export
 * @interface SelectOptionDTOTransmission
 */
export interface SelectOptionDTOTransmission {
    /**
     * 
     * @type {Transmission}
     * @memberof SelectOptionDTOTransmission
     */
    key: Transmission;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDTOTransmission
     */
    label: string;
}



/**
 * Check if a given object implements the SelectOptionDTOTransmission interface.
 */
export function instanceOfSelectOptionDTOTransmission(value: object): value is SelectOptionDTOTransmission {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function SelectOptionDTOTransmissionFromJSON(json: any): SelectOptionDTOTransmission {
    return SelectOptionDTOTransmissionFromJSONTyped(json, false);
}

export function SelectOptionDTOTransmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectOptionDTOTransmission {
    if (json == null) {
        return json;
    }
    return {
        
        'key': TransmissionFromJSON(json['key']),
        'label': json['label'],
    };
}

export function SelectOptionDTOTransmissionToJSON(json: any): SelectOptionDTOTransmission {
    return SelectOptionDTOTransmissionToJSONTyped(json, false);
}

export function SelectOptionDTOTransmissionToJSONTyped(value?: SelectOptionDTOTransmission | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': TransmissionToJSON(value['key']),
        'label': value['label'],
    };
}


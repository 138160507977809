/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MissingOptionCustomizationDTORoofColorCustomization } from './MissingOptionCustomizationDTORoofColorCustomization';
import {
    MissingOptionCustomizationDTORoofColorCustomizationFromJSON,
    MissingOptionCustomizationDTORoofColorCustomizationFromJSONTyped,
    MissingOptionCustomizationDTORoofColorCustomizationToJSON,
    MissingOptionCustomizationDTORoofColorCustomizationToJSONTyped,
} from './MissingOptionCustomizationDTORoofColorCustomization';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
 */
export interface MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    /**
     * 
     * @type {string}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
     */
    optionCode: string;
    /**
     * 
     * @type {SelectOptionDTOString}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
     */
    modelGeneration: SelectOptionDTOString;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
     */
    orderTypes: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<MissingOptionCustomizationDTORoofColorCustomization>}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization
     */
    similarCustomizations: Array<MissingOptionCustomizationDTORoofColorCustomization>;
}

/**
 * Check if a given object implements the MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization interface.
 */
export function instanceOfMissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization(value: object): value is MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelGeneration' in value) || value['modelGeneration'] === undefined) return false;
    if (!('orderTypes' in value) || value['orderTypes'] === undefined) return false;
    if (!('similarCustomizations' in value) || value['similarCustomizations'] === undefined) return false;
    return true;
}

export function MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSON(json: any): MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    return MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSONTyped(json, false);
}

export function MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    if (json == null) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGeneration': SelectOptionDTOStringFromJSON(json['modelGeneration']),
        'orderTypes': ((json['orderTypes'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'similarCustomizations': ((json['similarCustomizations'] as Array<any>).map(MissingOptionCustomizationDTORoofColorCustomizationFromJSON)),
    };
}

export function MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationToJSON(json: any): MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization {
    return MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationToJSONTyped(json, false);
}

export function MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomizationToJSONTyped(value?: MissingOptionCustomizationDTOMissingOptionCustomizationDTORoofColorCustomization | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'optionCode': value['optionCode'],
        'modelGeneration': SelectOptionDTOStringToJSON(value['modelGeneration']),
        'orderTypes': ((value['orderTypes'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'similarCustomizations': ((value['similarCustomizations'] as Array<any>).map(MissingOptionCustomizationDTORoofColorCustomizationToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LinkedCustomizationDTOCustomizationValueExteriorColorColorTag } from './LinkedCustomizationDTOCustomizationValueExteriorColorColorTag';
import {
    LinkedCustomizationDTOCustomizationValueExteriorColorColorTagFromJSON,
    LinkedCustomizationDTOCustomizationValueExteriorColorColorTagFromJSONTyped,
    LinkedCustomizationDTOCustomizationValueExteriorColorColorTagToJSON,
    LinkedCustomizationDTOCustomizationValueExteriorColorColorTagToJSONTyped,
} from './LinkedCustomizationDTOCustomizationValueExteriorColorColorTag';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';

/**
 * 
 * @export
 * @interface LinkedCustomizationDTOCustomizationValueExteriorColor
 */
export interface LinkedCustomizationDTOCustomizationValueExteriorColor {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof LinkedCustomizationDTOCustomizationValueExteriorColor
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof LinkedCustomizationDTOCustomizationValueExteriorColor
     */
    colorTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkedCustomizationDTOCustomizationValueExteriorColor
     */
    hexCode: string;
    /**
     * 
     * @type {LinkedCustomizationDTOCustomizationValueExteriorColorColorTag}
     * @memberof LinkedCustomizationDTOCustomizationValueExteriorColor
     */
    colorTag?: LinkedCustomizationDTOCustomizationValueExteriorColorColorTag;
}

/**
 * Check if a given object implements the LinkedCustomizationDTOCustomizationValueExteriorColor interface.
 */
export function instanceOfLinkedCustomizationDTOCustomizationValueExteriorColor(value: object): value is LinkedCustomizationDTOCustomizationValueExteriorColor {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('hexCode' in value) || value['hexCode'] === undefined) return false;
    return true;
}

export function LinkedCustomizationDTOCustomizationValueExteriorColorFromJSON(json: any): LinkedCustomizationDTOCustomizationValueExteriorColor {
    return LinkedCustomizationDTOCustomizationValueExteriorColorFromJSONTyped(json, false);
}

export function LinkedCustomizationDTOCustomizationValueExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedCustomizationDTOCustomizationValueExteriorColor {
    if (json == null) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'colorTypeName': json['colorTypeName'] == null ? undefined : json['colorTypeName'],
        'hexCode': json['hexCode'],
        'colorTag': json['colorTag'] == null ? undefined : LinkedCustomizationDTOCustomizationValueExteriorColorColorTagFromJSON(json['colorTag']),
    };
}

export function LinkedCustomizationDTOCustomizationValueExteriorColorToJSON(json: any): LinkedCustomizationDTOCustomizationValueExteriorColor {
    return LinkedCustomizationDTOCustomizationValueExteriorColorToJSONTyped(json, false);
}

export function LinkedCustomizationDTOCustomizationValueExteriorColorToJSONTyped(value?: LinkedCustomizationDTOCustomizationValueExteriorColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': BaseNameDTOToJSON(value['name']),
        'colorTypeName': value['colorTypeName'],
        'hexCode': value['hexCode'],
        'colorTag': LinkedCustomizationDTOCustomizationValueExteriorColorColorTagToJSON(value['colorTag']),
    };
}


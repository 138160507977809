import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { CustomInteriorColorFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { CustomInteriorColorFilter } from "../../../../../pdsapi"
import { filterTagsFor } from "../../../../../components/filteredpagedtable/filtertags/filterTagsFor"

export const CustomInteriorColorListFilterTagsConfig: TableFilterTagsConfig<CustomInteriorColorFilter, CustomInteriorColorFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => [
    ...filterTagsFor(
        filter,
        setFilter,
        "interiorColorTagKeys",
        (interiorColorTagKey) => filterOptions?.interiorColorTags?.find((it) => it.key === interiorColorTagKey)?.label ?? interiorColorTagKey
    ),
]

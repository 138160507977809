/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationLinksDTOOption } from './OptionCustomizationLinksDTOOption';
import {
    OptionCustomizationLinksDTOOptionFromJSON,
    OptionCustomizationLinksDTOOptionFromJSONTyped,
    OptionCustomizationLinksDTOOptionToJSON,
    OptionCustomizationLinksDTOOptionToJSONTyped,
} from './OptionCustomizationLinksDTOOption';
import type { SelectOptionDTOInteger } from './SelectOptionDTOInteger';
import {
    SelectOptionDTOIntegerFromJSON,
    SelectOptionDTOIntegerFromJSONTyped,
    SelectOptionDTOIntegerToJSON,
    SelectOptionDTOIntegerToJSONTyped,
} from './SelectOptionDTOInteger';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface OptionCustomizationLinksDTO
 */
export interface OptionCustomizationLinksDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationLinksDTO
     */
    availableModelGenerations: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOInteger>}
     * @memberof OptionCustomizationLinksDTO
     */
    availableModelYears: Array<SelectOptionDTOInteger>;
    /**
     * 
     * @type {Array<OptionCustomizationLinksDTOOption>}
     * @memberof OptionCustomizationLinksDTO
     */
    options: Array<OptionCustomizationLinksDTOOption>;
}

/**
 * Check if a given object implements the OptionCustomizationLinksDTO interface.
 */
export function instanceOfOptionCustomizationLinksDTO(value: object): value is OptionCustomizationLinksDTO {
    if (!('availableModelGenerations' in value) || value['availableModelGenerations'] === undefined) return false;
    if (!('availableModelYears' in value) || value['availableModelYears'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function OptionCustomizationLinksDTOFromJSON(json: any): OptionCustomizationLinksDTO {
    return OptionCustomizationLinksDTOFromJSONTyped(json, false);
}

export function OptionCustomizationLinksDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationLinksDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'availableModelGenerations': ((json['availableModelGenerations'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'availableModelYears': ((json['availableModelYears'] as Array<any>).map(SelectOptionDTOIntegerFromJSON)),
        'options': ((json['options'] as Array<any>).map(OptionCustomizationLinksDTOOptionFromJSON)),
    };
}

export function OptionCustomizationLinksDTOToJSON(json: any): OptionCustomizationLinksDTO {
    return OptionCustomizationLinksDTOToJSONTyped(json, false);
}

export function OptionCustomizationLinksDTOToJSONTyped(value?: OptionCustomizationLinksDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'availableModelGenerations': ((value['availableModelGenerations'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'availableModelYears': ((value['availableModelYears'] as Array<any>).map(SelectOptionDTOIntegerToJSON)),
        'options': ((value['options'] as Array<any>).map(OptionCustomizationLinksDTOOptionToJSON)),
    };
}


import { TableFilterTagsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { CustomExteriorColorFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { CustomExteriorColorFilter } from "../../../../../pdsapi"
import { filterTagsFor } from "../../../../../components/filteredpagedtable/filtertags/filterTagsFor"

export const CustomExteriorColorListFilterTagsConfig: TableFilterTagsConfig<CustomExteriorColorFilter, CustomExteriorColorFilterOptionsDTO> = ({
    filter,
    setFilter,
    filterOptions,
}) => [
    ...filterTagsFor(
        filter,
        setFilter,
        "exteriorColorTagKeys",
        (exteriorColorTagKey) => filterOptions?.exteriorColorTags?.find((it) => it.key === exteriorColorTagKey)?.label ?? exteriorColorTagKey
    ),
    ...filterTagsFor(
        filter,
        setFilter,
        "exteriorColorTypes",
        (exteriorColorType) => filterOptions?.exteriorColorTypes?.find((it) => it.key === exteriorColorType)?.label ?? exteriorColorType
    ),
]

import { usePdsBootContext } from "../../common/PdsBootContext"
import { UserAuthority } from "../../generated/pdsapi"
import { SelectableButton } from "../buttons/selectablebutton/SelectableButton"
import { Box } from "../layouts/box/Box"
import { pdsHeaderConfig } from "./PdsHeaderConfig"
import { PdsHeaderFlyoutView } from "./PdsHeaderFlyoutView"
import { PContentWrapper, PFlex, PMarque } from "@porsche-design-system/components-react"
import { color } from "@porsche-design-system/utilities"
import { FC, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

export interface PdsHeaderNavigationItem {
    label: string
    to: string
    items?: FlyoutItem[]
    requiredUserAuthority: UserAuthority
}

export interface FlyoutItem {
    label: string
    deprecated?: boolean
    to?: string
    customRef?: string
}

export const PdsHeader: FC = () => {
    const navigate = useNavigate()

    const [selectedTab, setSelectedTab] = useState<number | undefined>(undefined)
    const selectedTabRef = useRef(selectedTab)
    selectedTabRef.current = selectedTab

    const [flyoutHoveredIndex, setFlyoutHoveredIndex] = useState<number | undefined>(undefined)
    const [focusedIndex, setFocusedIndex] = useState<number | undefined>(undefined)

    useEffect(() => {
        setFocusedIndex(selectedTab ?? flyoutHoveredIndex)
    }, [selectedTab, flyoutHoveredIndex])

    const { hasAuthority } = usePdsBootContext()

    const handleOnNavbarEnter = (index: number) => () => {
        setSelectedTab(index)
    }

    const handleOnNavbarLeave = (index: number) => () => {
        setTimeout(() => {
            if (selectedTabRef.current === index) {
                setSelectedTab(undefined)
            }
        }, 200)
    }

    const handleOnFlyoutEnter = (index: number) => () => {
        setFlyoutHoveredIndex(index)
    }

    const handleOnFlyoutLeave = () => {
        setTimeout(() => {
            setFlyoutHoveredIndex(undefined)
        }, 200)
    }

    useEffect(() => {
        const getMainComponent = () => {
            return document.body.querySelector("main")?.classList
        }

        if (focusedIndex !== undefined) {
            getMainComponent()?.add("backdrop")
        } else {
            getMainComponent()?.remove("backdrop")
        }
    }, [focusedIndex])

    return (
        <PdsHeaderContainer>
            <PContentWrapper>
                <PdsHeaderContent>
                    <PFlex justifyContent="center">
                        <CrestButton onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                            <PMarque />
                        </CrestButton>
                        <NavigationBar>
                            {pdsHeaderConfig
                                .filter((config) => hasAuthority(config.requiredUserAuthority))
                                .map((config, index) => (
                                    <StyledHeaderItem key={index}>
                                        <SelectableButton
                                            label={config.label}
                                            height={"calc(7rem - 3px)"}
                                            sidePadding={"1rem"}
                                            selectionLineHeight={"3px"}
                                            isSelected={focusedIndex === index}
                                            onClick={() => config.items || navigate(config.to)}
                                            onMouseEnter={handleOnNavbarEnter(index)}
                                            onMouseLeave={handleOnNavbarLeave(index)}
                                        />
                                        {config.items && focusedIndex === index && (
                                            <PdsHeaderFlyoutView
                                                items={config.items}
                                                basePath={config.to}
                                                onMouseEnter={handleOnFlyoutEnter(focusedIndex)}
                                                onMouseLeave={handleOnFlyoutLeave}
                                            />
                                        )}
                                    </StyledHeaderItem>
                                ))}
                        </NavigationBar>

                        <Box width={"400px"} />
                    </PFlex>
                </PdsHeaderContent>
            </PContentWrapper>

            <PFlex>
                <HeaderBottomDivider />
            </PFlex>
        </PdsHeaderContainer>
    )
}

const PdsHeaderContainer = styled.div`
    height: 8rem;
    background-color: ${color.background.default};
`
const PdsHeaderContent = styled.div`
    height: 7rem;
`

const CrestButton = styled.a`
    margin-right: 4vw;
    height: 8rem;
    width: 8rem;
    background-color: ${color.background.default};
    border: 1px solid ${color.neutralContrast.low};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
`

const NavigationBar = styled.div`
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

const HeaderBottomDivider = styled.div`
    height: 1px;
    background-color: ${color.neutralContrast.low};
    width: 100%;
`

const StyledHeaderItem = styled.div`
    position: relative;
`

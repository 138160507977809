/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';

/**
 * 
 * @export
 * @interface DerivativeCategoryAttributesUpdateDTO
 */
export interface DerivativeCategoryAttributesUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof DerivativeCategoryAttributesUpdateDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof DerivativeCategoryAttributesUpdateDTO
     */
    modelSeriesKey: string;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof DerivativeCategoryAttributesUpdateDTO
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof DerivativeCategoryAttributesUpdateDTO
     */
    description: BaseNameDTO;
}

/**
 * Check if a given object implements the DerivativeCategoryAttributesUpdateDTO interface.
 */
export function instanceOfDerivativeCategoryAttributesUpdateDTO(value: object): value is DerivativeCategoryAttributesUpdateDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('modelSeriesKey' in value) || value['modelSeriesKey'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    return true;
}

export function DerivativeCategoryAttributesUpdateDTOFromJSON(json: any): DerivativeCategoryAttributesUpdateDTO {
    return DerivativeCategoryAttributesUpdateDTOFromJSONTyped(json, false);
}

export function DerivativeCategoryAttributesUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DerivativeCategoryAttributesUpdateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelSeriesKey': json['modelSeriesKey'],
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
    };
}

export function DerivativeCategoryAttributesUpdateDTOToJSON(json: any): DerivativeCategoryAttributesUpdateDTO {
    return DerivativeCategoryAttributesUpdateDTOToJSONTyped(json, false);
}

export function DerivativeCategoryAttributesUpdateDTOToJSONTyped(value?: DerivativeCategoryAttributesUpdateDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'modelSeriesKey': value['modelSeriesKey'],
        'name': BaseNameDTOToJSON(value['name']),
        'description': BaseNameDTOToJSON(value['description']),
    };
}


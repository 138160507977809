/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OrderTypeAttributesDTO,
  OrderTypeAttributesUpdateDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    OrderTypeAttributesDTOFromJSON,
    OrderTypeAttributesDTOToJSON,
    OrderTypeAttributesUpdateDTOFromJSON,
    OrderTypeAttributesUpdateDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface GetAttributesOfOrderTypeRequest {
    key: string;
    languageTag: string;
}

export interface UpdateAttributesOfOrderTypeRequest {
    key: string;
    orderTypeAttributesUpdateDTO: OrderTypeAttributesUpdateDTO;
}

/**
 * 
 */
export class OrderTypeDataDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get attributes of OrderType with given key
     */
    async getAttributesOfOrderTypeRaw(requestParameters: GetAttributesOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderTypeAttributesDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getAttributesOfOrderType().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getAttributesOfOrderType().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/data/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes of OrderType with given key
     */
    async getAttributesOfOrderType(requestParameters: GetAttributesOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderTypeAttributesDTO> {
        const response = await this.getAttributesOfOrderTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates attributes of an existing OrderType
     */
    async updateAttributesOfOrderTypeRaw(requestParameters: UpdateAttributesOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateAttributesOfOrderType().'
            );
        }

        if (requestParameters['orderTypeAttributesUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'orderTypeAttributesUpdateDTO',
                'Required parameter "orderTypeAttributesUpdateDTO" was null or undefined when calling updateAttributesOfOrderType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/details/{key}/data/attributes`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderTypeAttributesUpdateDTOToJSON(requestParameters['orderTypeAttributesUpdateDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates attributes of an existing OrderType
     */
    async updateAttributesOfOrderType(requestParameters: UpdateAttributesOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAttributesOfOrderTypeRaw(requestParameters, initOverrides);
    }

}

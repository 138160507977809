/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LocalizationsItemDTO } from './LocalizationsItemDTO';
import {
    LocalizationsItemDTOFromJSON,
    LocalizationsItemDTOFromJSONTyped,
    LocalizationsItemDTOToJSON,
    LocalizationsItemDTOToJSONTyped,
} from './LocalizationsItemDTO';

/**
 * 
 * @export
 * @interface OptionLocalizationItemDTO
 */
export interface OptionLocalizationItemDTO {
    /**
     * 
     * @type {LocalizationsItemDTO}
     * @memberof OptionLocalizationItemDTO
     */
    merged: LocalizationsItemDTO;
    /**
     * 
     * @type {LocalizationsItemDTO}
     * @memberof OptionLocalizationItemDTO
     */
    option: LocalizationsItemDTO;
    /**
     * 
     * @type {LocalizationsItemDTO}
     * @memberof OptionLocalizationItemDTO
     */
    customization?: LocalizationsItemDTO;
}

/**
 * Check if a given object implements the OptionLocalizationItemDTO interface.
 */
export function instanceOfOptionLocalizationItemDTO(value: object): value is OptionLocalizationItemDTO {
    if (!('merged' in value) || value['merged'] === undefined) return false;
    if (!('option' in value) || value['option'] === undefined) return false;
    return true;
}

export function OptionLocalizationItemDTOFromJSON(json: any): OptionLocalizationItemDTO {
    return OptionLocalizationItemDTOFromJSONTyped(json, false);
}

export function OptionLocalizationItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionLocalizationItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'merged': LocalizationsItemDTOFromJSON(json['merged']),
        'option': LocalizationsItemDTOFromJSON(json['option']),
        'customization': json['customization'] == null ? undefined : LocalizationsItemDTOFromJSON(json['customization']),
    };
}

export function OptionLocalizationItemDTOToJSON(json: any): OptionLocalizationItemDTO {
    return OptionLocalizationItemDTOToJSONTyped(json, false);
}

export function OptionLocalizationItemDTOToJSONTyped(value?: OptionLocalizationItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'merged': LocalizationsItemDTOToJSON(value['merged']),
        'option': LocalizationsItemDTOToJSON(value['option']),
        'customization': LocalizationsItemDTOToJSON(value['customization']),
    };
}


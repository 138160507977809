/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';
import type { GroupedMultiSelectDTOString } from './GroupedMultiSelectDTOString';
import {
    GroupedMultiSelectDTOStringFromJSON,
    GroupedMultiSelectDTOStringFromJSONTyped,
    GroupedMultiSelectDTOStringToJSON,
    GroupedMultiSelectDTOStringToJSONTyped,
} from './GroupedMultiSelectDTOString';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesDTOEquipmentValue
 */
export interface OptionCustomizationAttributesDTOEquipmentValue {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationAttributesDTOEquipmentValue
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationAttributesDTOEquipmentValue
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {GroupedMultiSelectDTOString}
     * @memberof OptionCustomizationAttributesDTOEquipmentValue
     */
    equipmentTags: GroupedMultiSelectDTOString;
}

/**
 * Check if a given object implements the OptionCustomizationAttributesDTOEquipmentValue interface.
 */
export function instanceOfOptionCustomizationAttributesDTOEquipmentValue(value: object): value is OptionCustomizationAttributesDTOEquipmentValue {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('equipmentTags' in value) || value['equipmentTags'] === undefined) return false;
    return true;
}

export function OptionCustomizationAttributesDTOEquipmentValueFromJSON(json: any): OptionCustomizationAttributesDTOEquipmentValue {
    return OptionCustomizationAttributesDTOEquipmentValueFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOEquipmentValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOEquipmentValue {
    if (json == null) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
        'equipmentTags': GroupedMultiSelectDTOStringFromJSON(json['equipmentTags']),
    };
}

export function OptionCustomizationAttributesDTOEquipmentValueToJSON(json: any): OptionCustomizationAttributesDTOEquipmentValue {
    return OptionCustomizationAttributesDTOEquipmentValueToJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOEquipmentValueToJSONTyped(value?: OptionCustomizationAttributesDTOEquipmentValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': BaseNameDTOToJSON(value['name']),
        'description': BaseNameDTOToJSON(value['description']),
        'equipmentTags': GroupedMultiSelectDTOStringToJSON(value['equipmentTags']),
    };
}


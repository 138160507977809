/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RelatedOptionGroupDTOStringString } from './RelatedOptionGroupDTOStringString';
import {
    RelatedOptionGroupDTOStringStringFromJSON,
    RelatedOptionGroupDTOStringStringFromJSONTyped,
    RelatedOptionGroupDTOStringStringToJSON,
    RelatedOptionGroupDTOStringStringToJSONTyped,
} from './RelatedOptionGroupDTOStringString';
import type { SelectOptionDTOInteger } from './SelectOptionDTOInteger';
import {
    SelectOptionDTOIntegerFromJSON,
    SelectOptionDTOIntegerFromJSONTyped,
    SelectOptionDTOIntegerToJSON,
    SelectOptionDTOIntegerToJSONTyped,
} from './SelectOptionDTOInteger';
import type { RelatedGroupedRelatedOptionDTOIntegerStringString } from './RelatedGroupedRelatedOptionDTOIntegerStringString';
import {
    RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSON,
    RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSONTyped,
    RelatedGroupedRelatedOptionDTOIntegerStringStringToJSON,
    RelatedGroupedRelatedOptionDTOIntegerStringStringToJSONTyped,
} from './RelatedGroupedRelatedOptionDTOIntegerStringString';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface TechnicalDataFilterOptionsDTO
 */
export interface TechnicalDataFilterOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof TechnicalDataFilterOptionsDTO
     */
    modelSeries: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<RelatedOptionGroupDTOStringString>}
     * @memberof TechnicalDataFilterOptionsDTO
     */
    modelSeriesRelatedModelGenerations: Array<RelatedOptionGroupDTOStringString>;
    /**
     * 
     * @type {Array<SelectOptionDTOInteger>}
     * @memberof TechnicalDataFilterOptionsDTO
     */
    modelYears: Array<SelectOptionDTOInteger>;
    /**
     * 
     * @type {Array<RelatedGroupedRelatedOptionDTOIntegerStringString>}
     * @memberof TechnicalDataFilterOptionsDTO
     */
    modelYearAndGenerationRelatedOrderTypes: Array<RelatedGroupedRelatedOptionDTOIntegerStringString>;
}

/**
 * Check if a given object implements the TechnicalDataFilterOptionsDTO interface.
 */
export function instanceOfTechnicalDataFilterOptionsDTO(value: object): value is TechnicalDataFilterOptionsDTO {
    if (!('modelSeries' in value) || value['modelSeries'] === undefined) return false;
    if (!('modelSeriesRelatedModelGenerations' in value) || value['modelSeriesRelatedModelGenerations'] === undefined) return false;
    if (!('modelYears' in value) || value['modelYears'] === undefined) return false;
    if (!('modelYearAndGenerationRelatedOrderTypes' in value) || value['modelYearAndGenerationRelatedOrderTypes'] === undefined) return false;
    return true;
}

export function TechnicalDataFilterOptionsDTOFromJSON(json: any): TechnicalDataFilterOptionsDTO {
    return TechnicalDataFilterOptionsDTOFromJSONTyped(json, false);
}

export function TechnicalDataFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalDataFilterOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'modelSeries': ((json['modelSeries'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'modelSeriesRelatedModelGenerations': ((json['modelSeriesRelatedModelGenerations'] as Array<any>).map(RelatedOptionGroupDTOStringStringFromJSON)),
        'modelYears': ((json['modelYears'] as Array<any>).map(SelectOptionDTOIntegerFromJSON)),
        'modelYearAndGenerationRelatedOrderTypes': ((json['modelYearAndGenerationRelatedOrderTypes'] as Array<any>).map(RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSON)),
    };
}

export function TechnicalDataFilterOptionsDTOToJSON(json: any): TechnicalDataFilterOptionsDTO {
    return TechnicalDataFilterOptionsDTOToJSONTyped(json, false);
}

export function TechnicalDataFilterOptionsDTOToJSONTyped(value?: TechnicalDataFilterOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'modelSeries': ((value['modelSeries'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'modelSeriesRelatedModelGenerations': ((value['modelSeriesRelatedModelGenerations'] as Array<any>).map(RelatedOptionGroupDTOStringStringToJSON)),
        'modelYears': ((value['modelYears'] as Array<any>).map(SelectOptionDTOIntegerToJSON)),
        'modelYearAndGenerationRelatedOrderTypes': ((value['modelYearAndGenerationRelatedOrderTypes'] as Array<any>).map(RelatedGroupedRelatedOptionDTOIntegerStringStringToJSON)),
    };
}


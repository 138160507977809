/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationAttributesDTOInteriorValue } from './OptionCustomizationAttributesDTOInteriorValue';
import {
    OptionCustomizationAttributesDTOInteriorValueFromJSON,
    OptionCustomizationAttributesDTOInteriorValueFromJSONTyped,
    OptionCustomizationAttributesDTOInteriorValueToJSON,
    OptionCustomizationAttributesDTOInteriorValueToJSONTyped,
} from './OptionCustomizationAttributesDTOInteriorValue';
import type { OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue } from './OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue';
import {
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueFromJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueFromJSONTyped,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueToJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueToJSONTyped,
} from './OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';
import type { MultiSelectDTOString } from './MultiSelectDTOString';
import {
    MultiSelectDTOStringFromJSON,
    MultiSelectDTOStringFromJSONTyped,
    MultiSelectDTOStringToJSON,
    MultiSelectDTOStringToJSONTyped,
} from './MultiSelectDTOString';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesDTOInterior
 */
export interface OptionCustomizationAttributesDTOInterior {
    /**
     * 
     * @type {OptionCustomizationAttributesDTOInteriorValue}
     * @memberof OptionCustomizationAttributesDTOInterior
     */
    defaultValue: OptionCustomizationAttributesDTOInteriorValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOInterior
     */
    optionCode: string;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue>}
     * @memberof OptionCustomizationAttributesDTOInterior
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesDTOInterior
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {MultiSelectDTOString}
     * @memberof OptionCustomizationAttributesDTOInterior
     */
    modelGenerations: MultiSelectDTOString;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationAttributesDTOInterior
     */
    derivatives: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the OptionCustomizationAttributesDTOInterior interface.
 */
export function instanceOfOptionCustomizationAttributesDTOInterior(value: object): value is OptionCustomizationAttributesDTOInterior {
    if (!('defaultValue' in value) || value['defaultValue'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('conditionalValues' in value) || value['conditionalValues'] === undefined) return false;
    if (!('isDefaultValueActive' in value) || value['isDefaultValueActive'] === undefined) return false;
    if (!('modelGenerations' in value) || value['modelGenerations'] === undefined) return false;
    if (!('derivatives' in value) || value['derivatives'] === undefined) return false;
    return true;
}

export function OptionCustomizationAttributesDTOInteriorFromJSON(json: any): OptionCustomizationAttributesDTOInterior {
    return OptionCustomizationAttributesDTOInteriorFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOInteriorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOInterior {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesDTOInteriorValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'modelGenerations': MultiSelectDTOStringFromJSON(json['modelGenerations']),
        'derivatives': ((json['derivatives'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function OptionCustomizationAttributesDTOInteriorToJSON(json: any): OptionCustomizationAttributesDTOInterior {
    return OptionCustomizationAttributesDTOInteriorToJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOInteriorToJSONTyped(value?: OptionCustomizationAttributesDTOInterior | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultValue': OptionCustomizationAttributesDTOInteriorValueToJSON(value['defaultValue']),
        'optionCode': value['optionCode'],
        'conditionalValues': ((value['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueToJSON)),
        'isDefaultValueActive': value['isDefaultValueActive'],
        'modelGenerations': MultiSelectDTOStringToJSON(value['modelGenerations']),
        'derivatives': ((value['derivatives'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


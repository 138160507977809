/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MissingOptionCustomizationDTOInteriorCustomization } from './MissingOptionCustomizationDTOInteriorCustomization';
import {
    MissingOptionCustomizationDTOInteriorCustomizationFromJSON,
    MissingOptionCustomizationDTOInteriorCustomizationFromJSONTyped,
    MissingOptionCustomizationDTOInteriorCustomizationToJSON,
    MissingOptionCustomizationDTOInteriorCustomizationToJSONTyped,
} from './MissingOptionCustomizationDTOInteriorCustomization';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization
 */
export interface MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization {
    /**
     * 
     * @type {string}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization
     */
    optionCode: string;
    /**
     * 
     * @type {SelectOptionDTOString}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization
     */
    modelGeneration: SelectOptionDTOString;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization
     */
    orderTypes: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<MissingOptionCustomizationDTOInteriorCustomization>}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization
     */
    similarCustomizations: Array<MissingOptionCustomizationDTOInteriorCustomization>;
}

/**
 * Check if a given object implements the MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization interface.
 */
export function instanceOfMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization(value: object): value is MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization {
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelGeneration' in value) || value['modelGeneration'] === undefined) return false;
    if (!('orderTypes' in value) || value['orderTypes'] === undefined) return false;
    if (!('similarCustomizations' in value) || value['similarCustomizations'] === undefined) return false;
    return true;
}

export function MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSON(json: any): MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization {
    return MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSONTyped(json, false);
}

export function MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization {
    if (json == null) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGeneration': SelectOptionDTOStringFromJSON(json['modelGeneration']),
        'orderTypes': ((json['orderTypes'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'similarCustomizations': ((json['similarCustomizations'] as Array<any>).map(MissingOptionCustomizationDTOInteriorCustomizationFromJSON)),
    };
}

export function MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationToJSON(json: any): MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization {
    return MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationToJSONTyped(json, false);
}

export function MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationToJSONTyped(value?: MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'optionCode': value['optionCode'],
        'modelGeneration': SelectOptionDTOStringToJSON(value['modelGeneration']),
        'orderTypes': ((value['orderTypes'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'similarCustomizations': ((value['similarCustomizations'] as Array<any>).map(MissingOptionCustomizationDTOInteriorCustomizationToJSON)),
    };
}


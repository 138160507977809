import { getCurrentEnvironment } from "../../common/environment"
import { UserAuthority } from "../../generated/pdsapi"
import { PdsHeaderNavigationItem } from "./PdsHeader"

export const pdsHeaderConfig: PdsHeaderNavigationItem[] = [
    {
        label: "Models",
        to: "/models",
        items: [
            {
                label: "Model Series",
                to: "/model-series",
            },
            {
                label: "Model Generations",
                to: "/model-generations",
            },
            {
                label: "Derivative Categories",
                to: "/derivative-categories",
            },
            {
                label: "Derivatives",
                to: "/derivatives",
            },
            {
                label: "Order Types",
                to: "/order-types",
            },
        ],
        requiredUserAuthority: UserAuthority.VIEW_DATA,
    },
    {
        label: "Options",
        to: "/options",
        items: [
            {
                label: "Option Catalogue",
                to: "/catalogue",
            },
            {
                label: "Option Customizations",
                to: "/customizations",
            },
            {
                label: "Option Tags",
                to: "/tags",
            },
            {
                label: "Option Colors",
                to: "/colors",
            },
        ],
        requiredUserAuthority: UserAuthority.VIEW_DATA,
    },
    {
        label: "Custom Colors",
        to: "/custom-colors",
        items: [
            {
                label: "Exterior",
                to: "/exterior",
            },
            {
                label: "Interior",
                to: "/interior",
            },
        ],
        requiredUserAuthority: UserAuthority.VIEW_DATA,
    },
    {
        label: "Technical Data",
        to: "/technical-data",
        requiredUserAuthority: UserAuthority.VIEW_DATA,
    },
    {
        label: "Administration",
        to: "/admin",
        items: [
            {
                label: "Swagger-UI",
                customRef: `${getCurrentEnvironment().backendUrl}/swagger-ui.html`,
            },
            {
                label: "GraphQL (Altair)",
                customRef: `${getCurrentEnvironment().backendUrl}/altair`,
            },
        ],
        requiredUserAuthority: UserAuthority.ADMINISTRATE_SERVICE,
    },
]

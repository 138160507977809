/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomInteriorColorCreateOptionsDTO,
  CustomInteriorColorCreateRequestDTO,
  KeyDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    CustomInteriorColorCreateOptionsDTOFromJSON,
    CustomInteriorColorCreateOptionsDTOToJSON,
    CustomInteriorColorCreateRequestDTOFromJSON,
    CustomInteriorColorCreateRequestDTOToJSON,
    KeyDTOFromJSON,
    KeyDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface CreateCustomInteriorColorRequest {
    customInteriorColorCreateRequestDTO: CustomInteriorColorCreateRequestDTO;
}

export interface GetCustomInteriorColorCreateOptionsRequest {
    languageTag: string;
}

/**
 * 
 */
export class CustomInteriorColorCreateWebControllerApi extends runtime.BaseAPI {

    /**
     * Create a new CustomInteriorColor
     */
    async createCustomInteriorColorRaw(requestParameters: CreateCustomInteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KeyDTO>> {
        if (requestParameters['customInteriorColorCreateRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'customInteriorColorCreateRequestDTO',
                'Required parameter "customInteriorColorCreateRequestDTO" was null or undefined when calling createCustomInteriorColor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/custom-interior-colors/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomInteriorColorCreateRequestDTOToJSON(requestParameters['customInteriorColorCreateRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KeyDTOFromJSON(jsonValue));
    }

    /**
     * Create a new CustomInteriorColor
     */
    async createCustomInteriorColor(requestParameters: CreateCustomInteriorColorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KeyDTO> {
        const response = await this.createCustomInteriorColorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get select options to create CustomInteriorColors
     */
    async getCustomInteriorColorCreateOptionsRaw(requestParameters: GetCustomInteriorColorCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomInteriorColorCreateOptionsDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getCustomInteriorColorCreateOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-interior-colors/create/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomInteriorColorCreateOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get select options to create CustomInteriorColors
     */
    async getCustomInteriorColorCreateOptions(requestParameters: GetCustomInteriorColorCreateOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomInteriorColorCreateOptionsDTO> {
        const response = await this.getCustomInteriorColorCreateOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

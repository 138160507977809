/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { OptionDTOCustomTailoring } from './OptionDTOCustomTailoring';
import {
    instanceOfOptionDTOCustomTailoring,
    OptionDTOCustomTailoringFromJSON,
    OptionDTOCustomTailoringFromJSONTyped,
    OptionDTOCustomTailoringToJSON,
} from './OptionDTOCustomTailoring';
import type { OptionDTOExteriorColor } from './OptionDTOExteriorColor';
import {
    instanceOfOptionDTOExteriorColor,
    OptionDTOExteriorColorFromJSON,
    OptionDTOExteriorColorFromJSONTyped,
    OptionDTOExteriorColorToJSON,
} from './OptionDTOExteriorColor';
import type { OptionDTOIndividualEquipment } from './OptionDTOIndividualEquipment';
import {
    instanceOfOptionDTOIndividualEquipment,
    OptionDTOIndividualEquipmentFromJSON,
    OptionDTOIndividualEquipmentFromJSONTyped,
    OptionDTOIndividualEquipmentToJSON,
} from './OptionDTOIndividualEquipment';
import type { OptionDTOInterior } from './OptionDTOInterior';
import {
    instanceOfOptionDTOInterior,
    OptionDTOInteriorFromJSON,
    OptionDTOInteriorFromJSONTyped,
    OptionDTOInteriorToJSON,
} from './OptionDTOInterior';
import type { OptionDTOPaintToSampleExteriorColor } from './OptionDTOPaintToSampleExteriorColor';
import {
    instanceOfOptionDTOPaintToSampleExteriorColor,
    OptionDTOPaintToSampleExteriorColorFromJSON,
    OptionDTOPaintToSampleExteriorColorFromJSONTyped,
    OptionDTOPaintToSampleExteriorColorToJSON,
} from './OptionDTOPaintToSampleExteriorColor';
import type { OptionDTORoofColor } from './OptionDTORoofColor';
import {
    instanceOfOptionDTORoofColor,
    OptionDTORoofColorFromJSON,
    OptionDTORoofColorFromJSONTyped,
    OptionDTORoofColorToJSON,
} from './OptionDTORoofColor';
import type { OptionDTOSalesPackage } from './OptionDTOSalesPackage';
import {
    instanceOfOptionDTOSalesPackage,
    OptionDTOSalesPackageFromJSON,
    OptionDTOSalesPackageFromJSONTyped,
    OptionDTOSalesPackageToJSON,
} from './OptionDTOSalesPackage';
import type { OptionDTOTechnicalPackage } from './OptionDTOTechnicalPackage';
import {
    instanceOfOptionDTOTechnicalPackage,
    OptionDTOTechnicalPackageFromJSON,
    OptionDTOTechnicalPackageFromJSONTyped,
    OptionDTOTechnicalPackageToJSON,
} from './OptionDTOTechnicalPackage';
import type { OptionDTOUnclassifiedEquipment } from './OptionDTOUnclassifiedEquipment';
import {
    instanceOfOptionDTOUnclassifiedEquipment,
    OptionDTOUnclassifiedEquipmentFromJSON,
    OptionDTOUnclassifiedEquipmentFromJSONTyped,
    OptionDTOUnclassifiedEquipmentToJSON,
} from './OptionDTOUnclassifiedEquipment';
import type { OptionDTOZOrder } from './OptionDTOZOrder';
import {
    instanceOfOptionDTOZOrder,
    OptionDTOZOrderFromJSON,
    OptionDTOZOrderFromJSONTyped,
    OptionDTOZOrderToJSON,
} from './OptionDTOZOrder';

/**
 * @type OptionDTO
 * 
 * @export
 */
export type OptionDTO = { type: 'CustomTailoring' } & OptionDTOCustomTailoring | { type: 'ExteriorColor' } & OptionDTOExteriorColor | { type: 'IndividualEquipment' } & OptionDTOIndividualEquipment | { type: 'Interior' } & OptionDTOInterior | { type: 'PaintToSampleExteriorColor' } & OptionDTOPaintToSampleExteriorColor | { type: 'RoofColor' } & OptionDTORoofColor | { type: 'SalesPackage' } & OptionDTOSalesPackage | { type: 'TechnicalPackage' } & OptionDTOTechnicalPackage | { type: 'UnclassifiedEquipment' } & OptionDTOUnclassifiedEquipment | { type: 'ZOrder' } & OptionDTOZOrder;

export function OptionDTOFromJSON(json: any): OptionDTO {
    return OptionDTOFromJSONTyped(json, false);
}

export function OptionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionDTO {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'CustomTailoring':
            return Object.assign({}, OptionDTOCustomTailoringFromJSONTyped(json, true), { type: 'CustomTailoring' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionDTOExteriorColorFromJSONTyped(json, true), { type: 'ExteriorColor' } as const);
        case 'IndividualEquipment':
            return Object.assign({}, OptionDTOIndividualEquipmentFromJSONTyped(json, true), { type: 'IndividualEquipment' } as const);
        case 'Interior':
            return Object.assign({}, OptionDTOInteriorFromJSONTyped(json, true), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionDTOPaintToSampleExteriorColorFromJSONTyped(json, true), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionDTORoofColorFromJSONTyped(json, true), { type: 'RoofColor' } as const);
        case 'SalesPackage':
            return Object.assign({}, OptionDTOSalesPackageFromJSONTyped(json, true), { type: 'SalesPackage' } as const);
        case 'TechnicalPackage':
            return Object.assign({}, OptionDTOTechnicalPackageFromJSONTyped(json, true), { type: 'TechnicalPackage' } as const);
        case 'UnclassifiedEquipment':
            return Object.assign({}, OptionDTOUnclassifiedEquipmentFromJSONTyped(json, true), { type: 'UnclassifiedEquipment' } as const);
        case 'ZOrder':
            return Object.assign({}, OptionDTOZOrderFromJSONTyped(json, true), { type: 'ZOrder' } as const);
        default:
            throw new Error(`No variant of OptionDTO exists with 'type=${json['type']}'`);
    }
}

export function OptionDTOToJSON(json: any): any {
    return OptionDTOToJSONTyped(json, false);
}

export function OptionDTOToJSONTyped(value?: OptionDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'CustomTailoring':
            return Object.assign({}, OptionDTOCustomTailoringToJSON(value), { type: 'CustomTailoring' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionDTOExteriorColorToJSON(value), { type: 'ExteriorColor' } as const);
        case 'IndividualEquipment':
            return Object.assign({}, OptionDTOIndividualEquipmentToJSON(value), { type: 'IndividualEquipment' } as const);
        case 'Interior':
            return Object.assign({}, OptionDTOInteriorToJSON(value), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, OptionDTOPaintToSampleExteriorColorToJSON(value), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionDTORoofColorToJSON(value), { type: 'RoofColor' } as const);
        case 'SalesPackage':
            return Object.assign({}, OptionDTOSalesPackageToJSON(value), { type: 'SalesPackage' } as const);
        case 'TechnicalPackage':
            return Object.assign({}, OptionDTOTechnicalPackageToJSON(value), { type: 'TechnicalPackage' } as const);
        case 'UnclassifiedEquipment':
            return Object.assign({}, OptionDTOUnclassifiedEquipmentToJSON(value), { type: 'UnclassifiedEquipment' } as const);
        case 'ZOrder':
            return Object.assign({}, OptionDTOZOrderToJSON(value), { type: 'ZOrder' } as const);
        default:
            throw new Error(`No variant of OptionDTO exists with 'type=${value['type']}'`);
    }

}


import { TableFilterProps } from "../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnGroupsWrapper } from "../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { OptionFilterOptionsDTO } from "../../../../generated/pdsapi"
import { OptionFilter } from "../../../../pdsapi"
import { FunctionComponent } from "react"
import { MultiSelectFilter, NumberMultiSelectFilter } from "../../../../components/filter/MultiSelectFilter"
import { GroupedMultiSelectFilter } from "../../../../components/filter/GroupedMultiSelectFilter"

export const OptionCatalogueFilter: FunctionComponent<TableFilterProps<OptionFilter, OptionFilterOptionsDTO>> = ({ filterOptions, filter, setFilter }) => {
    const modelGenerationFilter = (
        <MultiSelectFilter
            label={"Model Generations"}
            options={filterOptions?.modelGenerations}
            value={filter.modelGenerationKeys}
            onChange={(modelGenerationKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelGenerationKeys,
                    orderTypeKeys: undefined,
                }))
            }
        />
    )

    const modelYearFilter = (
        <NumberMultiSelectFilter
            label={"Model Years"}
            options={filterOptions?.modelYears}
            value={filter.modelYears}
            onChange={(modelYears) =>
                setFilter((filter) => ({
                    ...filter,
                    modelYears,
                    orderTypeKeys: undefined,
                }))
            }
        />
    )

    const relevantOrderTypes =
        filter.modelGenerationKeys || filter.modelYears
            ? filterOptions?.modelYearAndGenerationRelatedOrderTypes
                  .map((modelYearGroup) => ({
                      ...modelYearGroup,
                      options: modelYearGroup.options.filter((relatedOption) => filter.modelGenerationKeys?.has(relatedOption.relatedKey) ?? true),
                  }))
                  .filter((modelYearGroup) => modelYearGroup.options.length && (filter.modelYears?.has(modelYearGroup.relatedKey) ?? true))
            : filterOptions?.modelYearAndGenerationRelatedOrderTypes
    const orderTypeFilter = (
        <GroupedMultiSelectFilter
            label={"Order Types"}
            options={relevantOrderTypes}
            value={filter.orderTypeKeys}
            onChange={(orderTypeKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    orderTypeKeys,
                }))
            }
        />
    )

    const classFilter = (
        <MultiSelectFilter
            label={"Classes"}
            options={filterOptions?.classes}
            value={filter.classes}
            onChange={(classes) =>
                setFilter((filter) => ({
                    ...filter,
                    classes,
                }))
            }
        />
    )
    const equipmentTagFilter = (
        <GroupedMultiSelectFilter
            label={"Equipment Tags"}
            options={filterOptions?.categorizedEquipmentTags}
            value={filter.equipmentTagKeys}
            onChange={(equipmentTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    equipmentTagKeys,
                }))
            }
        />
    )
    const interiorColorTagFilter = (
        <MultiSelectFilter
            label={"Interior Color Tags"}
            options={filterOptions?.interiorColorTags}
            value={filter.interiorColorTagKeys}
            onChange={(interiorColorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    interiorColorTagKeys,
                }))
            }
        />
    )
    const exteriorColorTagFilter = (
        <MultiSelectFilter
            label={"Exterior Color Tags"}
            options={filterOptions?.exteriorColorTags}
            value={filter.exteriorColorTagKeys}
            onChange={(exteriorColorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    exteriorColorTagKeys,
                }))
            }
        />
    )
    const roofColorTagFilter = (
        <MultiSelectFilter
            label={"Roof Color Tags"}
            options={filterOptions?.roofColorTags}
            value={filter.roofColorTagKeys}
            onChange={(roofColorTagKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    roofColorTagKeys,
                }))
            }
        />
    )
    const vehicleEquipmentCategoryFilter = (
        <MultiSelectFilter
            label={"Vehicle Equipment Category"}
            options={filterOptions?.vehicleEquipmentCategories}
            value={filter.vehicleEquipmentCategories}
            onChange={(vehicleEquipmentCategories) =>
                setFilter((filter) => ({
                    ...filter,
                    vehicleEquipmentCategories,
                }))
            }
        />
    )
    const equipmentFamilyFilter = (
        <MultiSelectFilter
            label={"Equipment Families"}
            options={filterOptions?.equipmentFamilies?.map((family) => ({ key: family, label: family }))}
            value={filter.equipmentFamilies}
            onChange={(equipmentFamilies) =>
                setFilter((filter) => ({
                    ...filter,
                    equipmentFamilies,
                }))
            }
        />
    )
    const interiorMaterialFilter = (
        <MultiSelectFilter
            label={"Interior Materials"}
            options={filterOptions?.interiorMaterials}
            value={filter.interiorMaterials}
            onChange={(interiorMaterials) =>
                setFilter((filter) => ({
                    ...filter,
                    interiorMaterials,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Model"}>
                {modelGenerationFilter}
                {modelYearFilter}
                {orderTypeFilter}
            </ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Type"}>{classFilter}</ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Tag"}>
                {equipmentTagFilter}
                {interiorColorTagFilter}
                {exteriorColorTagFilter}
                {roofColorTagFilter}
            </ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Other"}>
                {vehicleEquipmentCategoryFilter}
                {equipmentFamilyFilter}
                {interiorMaterialFilter}
            </ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}

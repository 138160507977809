/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface RelatedOptionGroupDTOStringString
 */
export interface RelatedOptionGroupDTOStringString {
    /**
     * 
     * @type {string}
     * @memberof RelatedOptionGroupDTOStringString
     */
    relatedKey: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedOptionGroupDTOStringString
     */
    label: string;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof RelatedOptionGroupDTOStringString
     */
    options: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the RelatedOptionGroupDTOStringString interface.
 */
export function instanceOfRelatedOptionGroupDTOStringString(value: object): value is RelatedOptionGroupDTOStringString {
    if (!('relatedKey' in value) || value['relatedKey'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    return true;
}

export function RelatedOptionGroupDTOStringStringFromJSON(json: any): RelatedOptionGroupDTOStringString {
    return RelatedOptionGroupDTOStringStringFromJSONTyped(json, false);
}

export function RelatedOptionGroupDTOStringStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedOptionGroupDTOStringString {
    if (json == null) {
        return json;
    }
    return {
        
        'relatedKey': json['relatedKey'],
        'label': json['label'],
        'options': ((json['options'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function RelatedOptionGroupDTOStringStringToJSON(json: any): RelatedOptionGroupDTOStringString {
    return RelatedOptionGroupDTOStringStringToJSONTyped(json, false);
}

export function RelatedOptionGroupDTOStringStringToJSONTyped(value?: RelatedOptionGroupDTOStringString | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'relatedKey': value['relatedKey'],
        'label': value['label'],
        'options': ((value['options'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BodyType = {
    SUV: 'SUV',
    COUPE: 'COUPE',
    CONVERTIBLE: 'CONVERTIBLE',
    TARGA: 'TARGA',
    SPEEDSTER: 'SPEEDSTER',
    LIMOUSINE: 'LIMOUSINE',
    EXECUTIVE: 'EXECUTIVE',
    SPORTTURISMO: 'SPORTTURISMO',
    CROSSTURISMO: 'CROSSTURISMO'
} as const;
export type BodyType = typeof BodyType[keyof typeof BodyType];


export function instanceOfBodyType(value: any): boolean {
    for (const key in BodyType) {
        if (Object.prototype.hasOwnProperty.call(BodyType, key)) {
            if (BodyType[key as keyof typeof BodyType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function BodyTypeFromJSON(json: any): BodyType {
    return BodyTypeFromJSONTyped(json, false);
}

export function BodyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyType {
    return json as BodyType;
}

export function BodyTypeToJSON(value?: BodyType | null): any {
    return value as any;
}

export function BodyTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): BodyType {
    return value as BodyType;
}


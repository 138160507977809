/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TechnicalDataAttributeValueType } from './TechnicalDataAttributeValueType';
import {
    TechnicalDataAttributeValueTypeFromJSON,
    TechnicalDataAttributeValueTypeFromJSONTyped,
    TechnicalDataAttributeValueTypeToJSON,
    TechnicalDataAttributeValueTypeToJSONTyped,
} from './TechnicalDataAttributeValueType';

/**
 * 
 * @export
 * @interface TechnicalDataAttributeDataColumnDefinition
 */
export interface TechnicalDataAttributeDataColumnDefinition {
    /**
     * 
     * @type {string}
     * @memberof TechnicalDataAttributeDataColumnDefinition
     */
    field: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicalDataAttributeDataColumnDefinition
     */
    label: string;
    /**
     * 
     * @type {TechnicalDataAttributeValueType}
     * @memberof TechnicalDataAttributeDataColumnDefinition
     */
    type: TechnicalDataAttributeValueType;
}



/**
 * Check if a given object implements the TechnicalDataAttributeDataColumnDefinition interface.
 */
export function instanceOfTechnicalDataAttributeDataColumnDefinition(value: object): value is TechnicalDataAttributeDataColumnDefinition {
    if (!('field' in value) || value['field'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function TechnicalDataAttributeDataColumnDefinitionFromJSON(json: any): TechnicalDataAttributeDataColumnDefinition {
    return TechnicalDataAttributeDataColumnDefinitionFromJSONTyped(json, false);
}

export function TechnicalDataAttributeDataColumnDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalDataAttributeDataColumnDefinition {
    if (json == null) {
        return json;
    }
    return {
        
        'field': json['field'],
        'label': json['label'],
        'type': TechnicalDataAttributeValueTypeFromJSON(json['type']),
    };
}

export function TechnicalDataAttributeDataColumnDefinitionToJSON(json: any): TechnicalDataAttributeDataColumnDefinition {
    return TechnicalDataAttributeDataColumnDefinitionToJSONTyped(json, false);
}

export function TechnicalDataAttributeDataColumnDefinitionToJSONTyped(value?: TechnicalDataAttributeDataColumnDefinition | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'field': value['field'],
        'label': value['label'],
        'type': TechnicalDataAttributeValueTypeToJSON(value['type']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InteriorColorTileDTO } from './InteriorColorTileDTO';
import {
    InteriorColorTileDTOFromJSON,
    InteriorColorTileDTOFromJSONTyped,
    InteriorColorTileDTOToJSON,
    InteriorColorTileDTOToJSONTyped,
} from './InteriorColorTileDTO';

/**
 * 
 * @export
 * @interface OptionCustomizationItemDTOInterior
 */
export interface OptionCustomizationItemDTOInterior {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTOInterior
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTOInterior
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationItemDTOInterior
     */
    modelGenerationNames: Array<string>;
    /**
     * 
     * @type {InteriorColorTileDTO}
     * @memberof OptionCustomizationItemDTOInterior
     */
    color: InteriorColorTileDTO;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTOInterior
     */
    interiorMaterialName: string;
}

/**
 * Check if a given object implements the OptionCustomizationItemDTOInterior interface.
 */
export function instanceOfOptionCustomizationItemDTOInterior(value: object): value is OptionCustomizationItemDTOInterior {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelGenerationNames' in value) || value['modelGenerationNames'] === undefined) return false;
    if (!('color' in value) || value['color'] === undefined) return false;
    if (!('interiorMaterialName' in value) || value['interiorMaterialName'] === undefined) return false;
    return true;
}

export function OptionCustomizationItemDTOInteriorFromJSON(json: any): OptionCustomizationItemDTOInterior {
    return OptionCustomizationItemDTOInteriorFromJSONTyped(json, false);
}

export function OptionCustomizationItemDTOInteriorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationItemDTOInterior {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'optionCode': json['optionCode'],
        'modelGenerationNames': json['modelGenerationNames'],
        'color': InteriorColorTileDTOFromJSON(json['color']),
        'interiorMaterialName': json['interiorMaterialName'],
    };
}

export function OptionCustomizationItemDTOInteriorToJSON(json: any): OptionCustomizationItemDTOInterior {
    return OptionCustomizationItemDTOInteriorToJSONTyped(json, false);
}

export function OptionCustomizationItemDTOInteriorToJSONTyped(value?: OptionCustomizationItemDTOInterior | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'optionCode': value['optionCode'],
        'modelGenerationNames': value['modelGenerationNames'],
        'color': InteriorColorTileDTOToJSON(value['color']),
        'interiorMaterialName': value['interiorMaterialName'],
    };
}


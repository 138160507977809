import { ReadonlyFormElementWrapper } from "../../../../../../components/formelements/ReadonlyFormElementWrapper"
import { getTagsFormElement } from "../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import { ModelSeriesLinksDTO } from "../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { FC } from "react"
import { routes } from "../../../../../../common/routes"

export const ModelSeriesLinksCardContent: FC<ProductDetailsCardContentProps<ModelSeriesLinksDTO, Record<string, never>>> = ({ formDisplayData }) => (
    <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
        <RowGroupWrapper label={"Model Generations"} childrenSize={12}>
            <ReadonlyFormElementWrapper
                label={undefined}
                formDisplayData={formDisplayData}
                FormElement={getTagsFormElement()}
                getViewProps={(data) => ({
                    emptyLabel: "-",
                    tags: data.modelGenerations.map((modelGeneration) => ({
                        label: modelGeneration.label,
                        route: routes.models.modelGenerations.details(modelGeneration.key),
                    })),
                })}
            />
        </RowGroupWrapper>
        <RowGroupWrapper label={"Derivative categories"} childrenSize={12}>
            <ReadonlyFormElementWrapper
                label={undefined}
                formDisplayData={formDisplayData}
                FormElement={getTagsFormElement()}
                getViewProps={(data) => ({
                    emptyLabel: "-",
                    tags: data.derivativeCategories.map((derivativeCategory) => ({
                        label: derivativeCategory.label,
                        route: routes.models.derivatives.details(derivativeCategory.key),
                    })),
                })}
            />
        </RowGroupWrapper>
    </SpacedItems>
)

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IntRange } from './IntRange';
import {
    IntRangeFromJSON,
    IntRangeFromJSONTyped,
    IntRangeToJSON,
    IntRangeToJSONTyped,
} from './IntRange';
import type { OptionCustomizationAttributesDTOInteriorValue } from './OptionCustomizationAttributesDTOInteriorValue';
import {
    OptionCustomizationAttributesDTOInteriorValueFromJSON,
    OptionCustomizationAttributesDTOInteriorValueFromJSONTyped,
    OptionCustomizationAttributesDTOInteriorValueToJSON,
    OptionCustomizationAttributesDTOInteriorValueToJSONTyped,
} from './OptionCustomizationAttributesDTOInteriorValue';

/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue
 */
export interface OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue
     */
    key: string;
    /**
     * 
     * @type {IntRange}
     * @memberof OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue
     */
    modelYearRange?: IntRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue
     */
    derivativeKeys?: Array<string>;
    /**
     * 
     * @type {OptionCustomizationAttributesDTOInteriorValue}
     * @memberof OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue
     */
    value: OptionCustomizationAttributesDTOInteriorValue;
}

/**
 * Check if a given object implements the OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue interface.
 */
export function instanceOfOptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue(value: object): value is OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueFromJSON(json: any): OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue {
    return OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelYearRange': json['modelYearRange'] == null ? undefined : IntRangeFromJSON(json['modelYearRange']),
        'derivativeKeys': json['derivativeKeys'] == null ? undefined : json['derivativeKeys'],
        'value': OptionCustomizationAttributesDTOInteriorValueFromJSON(json['value']),
    };
}

export function OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueToJSON(json: any): OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue {
    return OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueToJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValueToJSONTyped(value?: OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOInteriorValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'modelYearRange': IntRangeToJSON(value['modelYearRange']),
        'derivativeKeys': value['derivativeKeys'],
        'value': OptionCustomizationAttributesDTOInteriorValueToJSON(value['value']),
    };
}


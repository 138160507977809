/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderTypeSortField = {
    SORT_INDEX: 'SORT_INDEX',
    MODEL_YEAR: 'MODEL_YEAR'
} as const;
export type OrderTypeSortField = typeof OrderTypeSortField[keyof typeof OrderTypeSortField];


export function instanceOfOrderTypeSortField(value: any): boolean {
    for (const key in OrderTypeSortField) {
        if (Object.prototype.hasOwnProperty.call(OrderTypeSortField, key)) {
            if (OrderTypeSortField[key as keyof typeof OrderTypeSortField] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OrderTypeSortFieldFromJSON(json: any): OrderTypeSortField {
    return OrderTypeSortFieldFromJSONTyped(json, false);
}

export function OrderTypeSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeSortField {
    return json as OrderTypeSortField;
}

export function OrderTypeSortFieldToJSON(value?: OrderTypeSortField | null): any {
    return value as any;
}

export function OrderTypeSortFieldToJSONTyped(value: any, ignoreDiscriminator: boolean): OrderTypeSortField {
    return value as OrderTypeSortField;
}


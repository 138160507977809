/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TechnicalDataAttributeEquipmentRelationObject } from './TechnicalDataAttributeEquipmentRelationObject';
import {
    TechnicalDataAttributeEquipmentRelationObjectFromJSON,
    TechnicalDataAttributeEquipmentRelationObjectFromJSONTyped,
    TechnicalDataAttributeEquipmentRelationObjectToJSON,
    TechnicalDataAttributeEquipmentRelationObjectToJSONTyped,
} from './TechnicalDataAttributeEquipmentRelationObject';

/**
 * 
 * @export
 * @interface TechnicalDataItemDTO
 */
export interface TechnicalDataItemDTO {
    /**
     * 
     * @type {string}
     * @memberof TechnicalDataItemDTO
     */
    orderTypeKey: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof TechnicalDataItemDTO
     */
    orderTypeCodes: Set<string>;
    /**
     * 
     * @type {number}
     * @memberof TechnicalDataItemDTO
     */
    modelYear: number;
    /**
     * 
     * @type {string}
     * @memberof TechnicalDataItemDTO
     */
    orderTypeName: string;
    /**
     * 
     * @type {Array<TechnicalDataAttributeEquipmentRelationObject>}
     * @memberof TechnicalDataItemDTO
     */
    equipmentRelations: Array<TechnicalDataAttributeEquipmentRelationObject>;
}

/**
 * Check if a given object implements the TechnicalDataItemDTO interface.
 */
export function instanceOfTechnicalDataItemDTO(value: object): value is TechnicalDataItemDTO {
    if (!('orderTypeKey' in value) || value['orderTypeKey'] === undefined) return false;
    if (!('orderTypeCodes' in value) || value['orderTypeCodes'] === undefined) return false;
    if (!('modelYear' in value) || value['modelYear'] === undefined) return false;
    if (!('orderTypeName' in value) || value['orderTypeName'] === undefined) return false;
    if (!('equipmentRelations' in value) || value['equipmentRelations'] === undefined) return false;
    return true;
}

export function TechnicalDataItemDTOFromJSON(json: any): TechnicalDataItemDTO {
    return TechnicalDataItemDTOFromJSONTyped(json, false);
}

export function TechnicalDataItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalDataItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'orderTypeKey': json['orderTypeKey'],
        'orderTypeCodes': new Set(json['orderTypeCodes']),
        'modelYear': json['modelYear'],
        'orderTypeName': json['orderTypeName'],
        'equipmentRelations': ((json['equipmentRelations'] as Array<any>).map(TechnicalDataAttributeEquipmentRelationObjectFromJSON)),
    };
}

export function TechnicalDataItemDTOToJSON(json: any): TechnicalDataItemDTO {
    return TechnicalDataItemDTOToJSONTyped(json, false);
}

export function TechnicalDataItemDTOToJSONTyped(value?: TechnicalDataItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'orderTypeKey': value['orderTypeKey'],
        'orderTypeCodes': Array.from(value['orderTypeCodes'] as Set<any>),
        'modelYear': value['modelYear'],
        'orderTypeName': value['orderTypeName'],
        'equipmentRelations': ((value['equipmentRelations'] as Array<any>).map(TechnicalDataAttributeEquipmentRelationObjectToJSON)),
    };
}


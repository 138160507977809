/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RelatedOptionGroupDTOStringString } from './RelatedOptionGroupDTOStringString';
import {
    RelatedOptionGroupDTOStringStringFromJSON,
    RelatedOptionGroupDTOStringStringFromJSONTyped,
    RelatedOptionGroupDTOStringStringToJSON,
    RelatedOptionGroupDTOStringStringToJSONTyped,
} from './RelatedOptionGroupDTOStringString';
import type { SelectOptionDTOBodyType } from './SelectOptionDTOBodyType';
import {
    SelectOptionDTOBodyTypeFromJSON,
    SelectOptionDTOBodyTypeFromJSONTyped,
    SelectOptionDTOBodyTypeToJSON,
    SelectOptionDTOBodyTypeToJSONTyped,
} from './SelectOptionDTOBodyType';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface DerivativeFilterOptionsDTO
 */
export interface DerivativeFilterOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof DerivativeFilterOptionsDTO
     */
    modelSeries: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<RelatedOptionGroupDTOStringString>}
     * @memberof DerivativeFilterOptionsDTO
     */
    modelSeriesRelatedDerivativeCategories: Array<RelatedOptionGroupDTOStringString>;
    /**
     * 
     * @type {Array<SelectOptionDTOBodyType>}
     * @memberof DerivativeFilterOptionsDTO
     */
    bodyTypes: Array<SelectOptionDTOBodyType>;
}

/**
 * Check if a given object implements the DerivativeFilterOptionsDTO interface.
 */
export function instanceOfDerivativeFilterOptionsDTO(value: object): value is DerivativeFilterOptionsDTO {
    if (!('modelSeries' in value) || value['modelSeries'] === undefined) return false;
    if (!('modelSeriesRelatedDerivativeCategories' in value) || value['modelSeriesRelatedDerivativeCategories'] === undefined) return false;
    if (!('bodyTypes' in value) || value['bodyTypes'] === undefined) return false;
    return true;
}

export function DerivativeFilterOptionsDTOFromJSON(json: any): DerivativeFilterOptionsDTO {
    return DerivativeFilterOptionsDTOFromJSONTyped(json, false);
}

export function DerivativeFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DerivativeFilterOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'modelSeries': ((json['modelSeries'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'modelSeriesRelatedDerivativeCategories': ((json['modelSeriesRelatedDerivativeCategories'] as Array<any>).map(RelatedOptionGroupDTOStringStringFromJSON)),
        'bodyTypes': ((json['bodyTypes'] as Array<any>).map(SelectOptionDTOBodyTypeFromJSON)),
    };
}

export function DerivativeFilterOptionsDTOToJSON(json: any): DerivativeFilterOptionsDTO {
    return DerivativeFilterOptionsDTOToJSONTyped(json, false);
}

export function DerivativeFilterOptionsDTOToJSONTyped(value?: DerivativeFilterOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'modelSeries': ((value['modelSeries'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'modelSeriesRelatedDerivativeCategories': ((value['modelSeriesRelatedDerivativeCategories'] as Array<any>).map(RelatedOptionGroupDTOStringStringToJSON)),
        'bodyTypes': ((value['bodyTypes'] as Array<any>).map(SelectOptionDTOBodyTypeToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PdsMarketplace = {
    AM: 'AM',
    AO: 'AO',
    AR: 'AR',
    AT: 'AT',
    AU: 'AU',
    AZ: 'AZ',
    BA: 'BA',
    BE: 'BE',
    BG: 'BG',
    BH: 'BH',
    BN: 'BN',
    BO: 'BO',
    BR: 'BR',
    BY: 'BY',
    CA: 'CA',
    CH: 'CH',
    CL: 'CL',
    CN: 'CN',
    CO: 'CO',
    CR: 'CR',
    CW: 'CW',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DK: 'DK',
    DO: 'DO',
    DZ: 'DZ',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    ES: 'ES',
    FI: 'FI',
    FR: 'FR',
    GB: 'GB',
    GE: 'GE',
    GH: 'GH',
    GP: 'GP',
    GR: 'GR',
    GT: 'GT',
    HK: 'HK',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IN: 'IN',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KH: 'KH',
    KR: 'KR',
    KW: 'KW',
    KZ: 'KZ',
    LB: 'LB',
    LK: 'LK',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    MA: 'MA',
    MD: 'MD',
    MK: 'MK',
    MN: 'MN',
    MT: 'MT',
    MU: 'MU',
    MX: 'MX',
    MY: 'MY',
    NC: 'NC',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PR: 'PR',
    PT: 'PT',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    SA: 'SA',
    SE: 'SE',
    SG: 'SG',
    SI: 'SI',
    SK: 'SK',
    SV: 'SV',
    SY: 'SY',
    TH: 'TH',
    TN: 'TN',
    TR: 'TR',
    TT: 'TT',
    TW: 'TW',
    UA: 'UA',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VE: 'VE',
    VN: 'VN',
    XA: 'XA',
    XD: 'XD',
    XW: 'XW',
    YE: 'YE',
    ZA: 'ZA'
} as const;
export type PdsMarketplace = typeof PdsMarketplace[keyof typeof PdsMarketplace];


export function instanceOfPdsMarketplace(value: any): boolean {
    for (const key in PdsMarketplace) {
        if (Object.prototype.hasOwnProperty.call(PdsMarketplace, key)) {
            if (PdsMarketplace[key as keyof typeof PdsMarketplace] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PdsMarketplaceFromJSON(json: any): PdsMarketplace {
    return PdsMarketplaceFromJSONTyped(json, false);
}

export function PdsMarketplaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): PdsMarketplace {
    return json as PdsMarketplace;
}

export function PdsMarketplaceToJSON(value?: PdsMarketplace | null): any {
    return value as any;
}

export function PdsMarketplaceToJSONTyped(value: any, ignoreDiscriminator: boolean): PdsMarketplace {
    return value as PdsMarketplace;
}


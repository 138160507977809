/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationAttributesUpdateDTOEquipmentValue } from './OptionCustomizationAttributesUpdateDTOEquipmentValue';
import {
    OptionCustomizationAttributesUpdateDTOEquipmentValueFromJSON,
    OptionCustomizationAttributesUpdateDTOEquipmentValueFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOEquipmentValueToJSON,
    OptionCustomizationAttributesUpdateDTOEquipmentValueToJSONTyped,
} from './OptionCustomizationAttributesUpdateDTOEquipmentValue';
import type { OptionCustomizationConditionalValueUpdateDTOModelYearRange } from './OptionCustomizationConditionalValueUpdateDTOModelYearRange';
import {
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSON,
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSONTyped,
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSON,
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSONTyped,
} from './OptionCustomizationConditionalValueUpdateDTOModelYearRange';

/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue
 */
export interface OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue
     */
    key: string;
    /**
     * 
     * @type {OptionCustomizationConditionalValueUpdateDTOModelYearRange}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue
     */
    modelYearRange: OptionCustomizationConditionalValueUpdateDTOModelYearRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue
     */
    derivativeKeys?: Array<string>;
    /**
     * 
     * @type {OptionCustomizationAttributesUpdateDTOEquipmentValue}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue
     */
    value: OptionCustomizationAttributesUpdateDTOEquipmentValue;
}

/**
 * Check if a given object implements the OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue interface.
 */
export function instanceOfOptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue(value: object): value is OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('modelYearRange' in value) || value['modelYearRange'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueFromJSON(json: any): OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue {
    return OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelYearRange': OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSON(json['modelYearRange']),
        'derivativeKeys': json['derivativeKeys'] == null ? undefined : json['derivativeKeys'],
        'value': OptionCustomizationAttributesUpdateDTOEquipmentValueFromJSON(json['value']),
    };
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueToJSON(json: any): OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue {
    return OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueToJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValueToJSONTyped(value?: OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTOEquipmentValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'modelYearRange': OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSON(value['modelYearRange']),
        'derivativeKeys': value['derivativeKeys'],
        'value': OptionCustomizationAttributesUpdateDTOEquipmentValueToJSON(value['value']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BodyType,
  DerivativeFilterOptionsDTO,
  DerivativeSortField,
  PageDTODerivativeItemDTO,
  SortDirection,
} from '../models/index';
import {
    BodyTypeFromJSON,
    BodyTypeToJSON,
    DerivativeFilterOptionsDTOFromJSON,
    DerivativeFilterOptionsDTOToJSON,
    DerivativeSortFieldFromJSON,
    DerivativeSortFieldToJSON,
    PageDTODerivativeItemDTOFromJSON,
    PageDTODerivativeItemDTOToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models/index';

export interface GetDerivativeFilterOptionsRequest {
    languageTag: string;
}

export interface GetDerivativesPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: DerivativeSortField;
    sortDirection: SortDirection;
    search?: string;
    modelSeriesKeys?: Set<string>;
    derivativeCategoryKeys?: Set<string>;
    bodyTypes?: Set<BodyType>;
}

/**
 * 
 */
export class DerivativeListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter Derivatives
     */
    async getDerivativeFilterOptionsRaw(requestParameters: GetDerivativeFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DerivativeFilterOptionsDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getDerivativeFilterOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DerivativeFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter Derivatives
     */
    async getDerivativeFilterOptions(requestParameters: GetDerivativeFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DerivativeFilterOptionsDTO> {
        const response = await this.getDerivativeFilterOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get page of Derivatives
     */
    async getDerivativesPageRaw(requestParameters: GetDerivativesPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTODerivativeItemDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getDerivativesPage().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getDerivativesPage().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getDerivativesPage().'
            );
        }

        if (requestParameters['sortField'] == null) {
            throw new runtime.RequiredError(
                'sortField',
                'Required parameter "sortField" was null or undefined when calling getDerivativesPage().'
            );
        }

        if (requestParameters['sortDirection'] == null) {
            throw new runtime.RequiredError(
                'sortDirection',
                'Required parameter "sortDirection" was null or undefined when calling getDerivativesPage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sortField'] != null) {
            queryParameters['sortField'] = requestParameters['sortField'];
        }

        if (requestParameters['sortDirection'] != null) {
            queryParameters['sortDirection'] = requestParameters['sortDirection'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['modelSeriesKeys'] != null) {
            queryParameters['modelSeriesKeys'] = requestParameters['modelSeriesKeys'];
        }

        if (requestParameters['derivativeCategoryKeys'] != null) {
            queryParameters['derivativeCategoryKeys'] = requestParameters['derivativeCategoryKeys'];
        }

        if (requestParameters['bodyTypes'] != null) {
            queryParameters['bodyTypes'] = requestParameters['bodyTypes'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/derivatives/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTODerivativeItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of Derivatives
     */
    async getDerivativesPage(requestParameters: GetDerivativesPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTODerivativeItemDTO> {
        const response = await this.getDerivativesPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptionCustomizationItemDTORoofColor
 */
export interface OptionCustomizationItemDTORoofColor {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTORoofColor
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTORoofColor
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationItemDTORoofColor
     */
    modelGenerationNames: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationItemDTORoofColor
     */
    hexCode: string;
}

/**
 * Check if a given object implements the OptionCustomizationItemDTORoofColor interface.
 */
export function instanceOfOptionCustomizationItemDTORoofColor(value: object): value is OptionCustomizationItemDTORoofColor {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelGenerationNames' in value) || value['modelGenerationNames'] === undefined) return false;
    if (!('hexCode' in value) || value['hexCode'] === undefined) return false;
    return true;
}

export function OptionCustomizationItemDTORoofColorFromJSON(json: any): OptionCustomizationItemDTORoofColor {
    return OptionCustomizationItemDTORoofColorFromJSONTyped(json, false);
}

export function OptionCustomizationItemDTORoofColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationItemDTORoofColor {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'optionCode': json['optionCode'],
        'modelGenerationNames': json['modelGenerationNames'],
        'hexCode': json['hexCode'],
    };
}

export function OptionCustomizationItemDTORoofColorToJSON(json: any): OptionCustomizationItemDTORoofColor {
    return OptionCustomizationItemDTORoofColorToJSONTyped(json, false);
}

export function OptionCustomizationItemDTORoofColorToJSONTyped(value?: OptionCustomizationItemDTORoofColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'optionCode': value['optionCode'],
        'modelGenerationNames': value['modelGenerationNames'],
        'hexCode': value['hexCode'],
    };
}


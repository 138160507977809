/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ModelGenerationFilterOptionsDTO,
  ModelGenerationSortField,
  PageDTOModelGenerationItemDTO,
  SortDirection,
} from '../models/index';
import {
    ModelGenerationFilterOptionsDTOFromJSON,
    ModelGenerationFilterOptionsDTOToJSON,
    ModelGenerationSortFieldFromJSON,
    ModelGenerationSortFieldToJSON,
    PageDTOModelGenerationItemDTOFromJSON,
    PageDTOModelGenerationItemDTOToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models/index';

export interface GetModelGenerationFilterOptionsRequest {
    languageTag: string;
}

export interface GetModelGenerationsPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    sortField: ModelGenerationSortField;
    sortDirection: SortDirection;
    search?: string;
    modelSeriesKeys?: Set<string>;
}

/**
 * 
 */
export class ModelGenerationListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter ModelGenerations
     */
    async getModelGenerationFilterOptionsRaw(requestParameters: GetModelGenerationFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModelGenerationFilterOptionsDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getModelGenerationFilterOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-generations/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModelGenerationFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter ModelGenerations
     */
    async getModelGenerationFilterOptions(requestParameters: GetModelGenerationFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModelGenerationFilterOptionsDTO> {
        const response = await this.getModelGenerationFilterOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get page of ModelGenerations
     */
    async getModelGenerationsPageRaw(requestParameters: GetModelGenerationsPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOModelGenerationItemDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getModelGenerationsPage().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getModelGenerationsPage().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getModelGenerationsPage().'
            );
        }

        if (requestParameters['sortField'] == null) {
            throw new runtime.RequiredError(
                'sortField',
                'Required parameter "sortField" was null or undefined when calling getModelGenerationsPage().'
            );
        }

        if (requestParameters['sortDirection'] == null) {
            throw new runtime.RequiredError(
                'sortDirection',
                'Required parameter "sortDirection" was null or undefined when calling getModelGenerationsPage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sortField'] != null) {
            queryParameters['sortField'] = requestParameters['sortField'];
        }

        if (requestParameters['sortDirection'] != null) {
            queryParameters['sortDirection'] = requestParameters['sortDirection'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['modelSeriesKeys'] != null) {
            queryParameters['modelSeriesKeys'] = requestParameters['modelSeriesKeys'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/model-generations/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOModelGenerationItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of ModelGenerations
     */
    async getModelGenerationsPage(requestParameters: GetModelGenerationsPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOModelGenerationItemDTO> {
        const response = await this.getModelGenerationsPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

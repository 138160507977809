/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';
import type { InteriorMaterial } from './InteriorMaterial';
import {
    InteriorMaterialFromJSON,
    InteriorMaterialFromJSONTyped,
    InteriorMaterialToJSON,
    InteriorMaterialToJSONTyped,
} from './InteriorMaterial';

/**
 * 
 * @export
 * @interface OptionCustomizationCreateRequestDTOInterior
 */
export interface OptionCustomizationCreateRequestDTOInterior {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationCreateRequestDTOInterior
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationCreateRequestDTOInterior
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationCreateRequestDTOInterior
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationCreateRequestDTOInterior
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationCreateRequestDTOInterior
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationCreateRequestDTOInterior
     */
    seatColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationCreateRequestDTOInterior
     */
    seatStitchingColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationCreateRequestDTOInterior
     */
    cockpitColorHexCode: string;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationCreateRequestDTOInterior
     */
    cockpitStitchingColorHexCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationCreateRequestDTOInterior
     */
    isCustomColor: boolean;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationCreateRequestDTOInterior
     */
    interiorColorTagKey: string;
    /**
     * 
     * @type {InteriorMaterial}
     * @memberof OptionCustomizationCreateRequestDTOInterior
     */
    material: InteriorMaterial;
}



/**
 * Check if a given object implements the OptionCustomizationCreateRequestDTOInterior interface.
 */
export function instanceOfOptionCustomizationCreateRequestDTOInterior(value: object): value is OptionCustomizationCreateRequestDTOInterior {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelGenerationKeys' in value) || value['modelGenerationKeys'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('isDefaultValueActive' in value) || value['isDefaultValueActive'] === undefined) return false;
    if (!('seatColorHexCode' in value) || value['seatColorHexCode'] === undefined) return false;
    if (!('seatStitchingColorHexCode' in value) || value['seatStitchingColorHexCode'] === undefined) return false;
    if (!('cockpitColorHexCode' in value) || value['cockpitColorHexCode'] === undefined) return false;
    if (!('cockpitStitchingColorHexCode' in value) || value['cockpitStitchingColorHexCode'] === undefined) return false;
    if (!('isCustomColor' in value) || value['isCustomColor'] === undefined) return false;
    if (!('interiorColorTagKey' in value) || value['interiorColorTagKey'] === undefined) return false;
    if (!('material' in value) || value['material'] === undefined) return false;
    return true;
}

export function OptionCustomizationCreateRequestDTOInteriorFromJSON(json: any): OptionCustomizationCreateRequestDTOInterior {
    return OptionCustomizationCreateRequestDTOInteriorFromJSONTyped(json, false);
}

export function OptionCustomizationCreateRequestDTOInteriorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationCreateRequestDTOInterior {
    if (json == null) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'description': BaseNameDTOFromJSON(json['description']),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'seatColorHexCode': json['seatColorHexCode'],
        'seatStitchingColorHexCode': json['seatStitchingColorHexCode'],
        'cockpitColorHexCode': json['cockpitColorHexCode'],
        'cockpitStitchingColorHexCode': json['cockpitStitchingColorHexCode'],
        'isCustomColor': json['isCustomColor'],
        'interiorColorTagKey': json['interiorColorTagKey'],
        'material': InteriorMaterialFromJSON(json['material']),
    };
}

export function OptionCustomizationCreateRequestDTOInteriorToJSON(json: any): OptionCustomizationCreateRequestDTOInterior {
    return OptionCustomizationCreateRequestDTOInteriorToJSONTyped(json, false);
}

export function OptionCustomizationCreateRequestDTOInteriorToJSONTyped(value?: OptionCustomizationCreateRequestDTOInterior | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': BaseNameDTOToJSON(value['name']),
        'optionCode': value['optionCode'],
        'modelGenerationKeys': value['modelGenerationKeys'],
        'description': BaseNameDTOToJSON(value['description']),
        'isDefaultValueActive': value['isDefaultValueActive'],
        'seatColorHexCode': value['seatColorHexCode'],
        'seatStitchingColorHexCode': value['seatStitchingColorHexCode'],
        'cockpitColorHexCode': value['cockpitColorHexCode'],
        'cockpitStitchingColorHexCode': value['cockpitStitchingColorHexCode'],
        'isCustomColor': value['isCustomColor'],
        'interiorColorTagKey': value['interiorColorTagKey'],
        'material': InteriorMaterialToJSON(value['material']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NumberOfSeats } from './NumberOfSeats';
import {
    NumberOfSeatsFromJSON,
    NumberOfSeatsFromJSONTyped,
    NumberOfSeatsToJSON,
    NumberOfSeatsToJSONTyped,
} from './NumberOfSeats';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';
import type { DriveTrain } from './DriveTrain';
import {
    DriveTrainFromJSON,
    DriveTrainFromJSONTyped,
    DriveTrainToJSON,
    DriveTrainToJSONTyped,
} from './DriveTrain';
import type { EngineType } from './EngineType';
import {
    EngineTypeFromJSON,
    EngineTypeFromJSONTyped,
    EngineTypeToJSON,
    EngineTypeToJSONTyped,
} from './EngineType';

/**
 * 
 * @export
 * @interface OrderTypeAttributesUpdateDTO
 */
export interface OrderTypeAttributesUpdateDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeAttributesUpdateDTO
     */
    codes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrderTypeAttributesUpdateDTO
     */
    modelYear: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeAttributesUpdateDTO
     */
    modelGenerationKey: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeAttributesUpdateDTO
     */
    derivativeKey: string;
    /**
     * 
     * @type {DriveTrain}
     * @memberof OrderTypeAttributesUpdateDTO
     */
    driveTrain: DriveTrain;
    /**
     * 
     * @type {EngineType}
     * @memberof OrderTypeAttributesUpdateDTO
     */
    engineType: EngineType;
    /**
     * 
     * @type {NumberOfSeats}
     * @memberof OrderTypeAttributesUpdateDTO
     */
    numberOfSeats: NumberOfSeats;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OrderTypeAttributesUpdateDTO
     */
    manualName: BaseNameDTO;
    /**
     * 
     * @type {boolean}
     * @memberof OrderTypeAttributesUpdateDTO
     */
    special: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderTypeAttributesUpdateDTO
     */
    limited: boolean;
}



/**
 * Check if a given object implements the OrderTypeAttributesUpdateDTO interface.
 */
export function instanceOfOrderTypeAttributesUpdateDTO(value: object): value is OrderTypeAttributesUpdateDTO {
    if (!('codes' in value) || value['codes'] === undefined) return false;
    if (!('modelYear' in value) || value['modelYear'] === undefined) return false;
    if (!('modelGenerationKey' in value) || value['modelGenerationKey'] === undefined) return false;
    if (!('derivativeKey' in value) || value['derivativeKey'] === undefined) return false;
    if (!('driveTrain' in value) || value['driveTrain'] === undefined) return false;
    if (!('engineType' in value) || value['engineType'] === undefined) return false;
    if (!('numberOfSeats' in value) || value['numberOfSeats'] === undefined) return false;
    if (!('manualName' in value) || value['manualName'] === undefined) return false;
    if (!('special' in value) || value['special'] === undefined) return false;
    if (!('limited' in value) || value['limited'] === undefined) return false;
    return true;
}

export function OrderTypeAttributesUpdateDTOFromJSON(json: any): OrderTypeAttributesUpdateDTO {
    return OrderTypeAttributesUpdateDTOFromJSONTyped(json, false);
}

export function OrderTypeAttributesUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeAttributesUpdateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'codes': json['codes'],
        'modelYear': json['modelYear'],
        'modelGenerationKey': json['modelGenerationKey'],
        'derivativeKey': json['derivativeKey'],
        'driveTrain': DriveTrainFromJSON(json['driveTrain']),
        'engineType': EngineTypeFromJSON(json['engineType']),
        'numberOfSeats': NumberOfSeatsFromJSON(json['numberOfSeats']),
        'manualName': BaseNameDTOFromJSON(json['manualName']),
        'special': json['special'],
        'limited': json['limited'],
    };
}

export function OrderTypeAttributesUpdateDTOToJSON(json: any): OrderTypeAttributesUpdateDTO {
    return OrderTypeAttributesUpdateDTOToJSONTyped(json, false);
}

export function OrderTypeAttributesUpdateDTOToJSONTyped(value?: OrderTypeAttributesUpdateDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'codes': value['codes'],
        'modelYear': value['modelYear'],
        'modelGenerationKey': value['modelGenerationKey'],
        'derivativeKey': value['derivativeKey'],
        'driveTrain': DriveTrainToJSON(value['driveTrain']),
        'engineType': EngineTypeToJSON(value['engineType']),
        'numberOfSeats': NumberOfSeatsToJSON(value['numberOfSeats']),
        'manualName': BaseNameDTOToJSON(value['manualName']),
        'special': value['special'],
        'limited': value['limited'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface CustomInteriorColorCreateOptionsDTO
 */
export interface CustomInteriorColorCreateOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof CustomInteriorColorCreateOptionsDTO
     */
    interiorColorTags: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the CustomInteriorColorCreateOptionsDTO interface.
 */
export function instanceOfCustomInteriorColorCreateOptionsDTO(value: object): value is CustomInteriorColorCreateOptionsDTO {
    if (!('interiorColorTags' in value) || value['interiorColorTags'] === undefined) return false;
    return true;
}

export function CustomInteriorColorCreateOptionsDTOFromJSON(json: any): CustomInteriorColorCreateOptionsDTO {
    return CustomInteriorColorCreateOptionsDTOFromJSONTyped(json, false);
}

export function CustomInteriorColorCreateOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomInteriorColorCreateOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'interiorColorTags': ((json['interiorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function CustomInteriorColorCreateOptionsDTOToJSON(json: any): CustomInteriorColorCreateOptionsDTO {
    return CustomInteriorColorCreateOptionsDTOToJSONTyped(json, false);
}

export function CustomInteriorColorCreateOptionsDTOToJSONTyped(value?: CustomInteriorColorCreateOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'interiorColorTags': ((value['interiorColorTags'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}


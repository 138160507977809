/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { SimilarCustomizationsDTORoofColor } from './SimilarCustomizationsDTORoofColor';
import {
    instanceOfSimilarCustomizationsDTORoofColor,
    SimilarCustomizationsDTORoofColorFromJSON,
    SimilarCustomizationsDTORoofColorFromJSONTyped,
    SimilarCustomizationsDTORoofColorToJSON,
} from './SimilarCustomizationsDTORoofColor';
import type { SimilarCustomizationsDTOSimilarCustomizationEquipment } from './SimilarCustomizationsDTOSimilarCustomizationEquipment';
import {
    instanceOfSimilarCustomizationsDTOSimilarCustomizationEquipment,
    SimilarCustomizationsDTOSimilarCustomizationEquipmentFromJSON,
    SimilarCustomizationsDTOSimilarCustomizationEquipmentFromJSONTyped,
    SimilarCustomizationsDTOSimilarCustomizationEquipmentToJSON,
} from './SimilarCustomizationsDTOSimilarCustomizationEquipment';
import type { SimilarCustomizationsDTOSimilarCustomizationExteriorColor } from './SimilarCustomizationsDTOSimilarCustomizationExteriorColor';
import {
    instanceOfSimilarCustomizationsDTOSimilarCustomizationExteriorColor,
    SimilarCustomizationsDTOSimilarCustomizationExteriorColorFromJSON,
    SimilarCustomizationsDTOSimilarCustomizationExteriorColorFromJSONTyped,
    SimilarCustomizationsDTOSimilarCustomizationExteriorColorToJSON,
} from './SimilarCustomizationsDTOSimilarCustomizationExteriorColor';
import type { SimilarCustomizationsDTOSimilarCustomizationInterior } from './SimilarCustomizationsDTOSimilarCustomizationInterior';
import {
    instanceOfSimilarCustomizationsDTOSimilarCustomizationInterior,
    SimilarCustomizationsDTOSimilarCustomizationInteriorFromJSON,
    SimilarCustomizationsDTOSimilarCustomizationInteriorFromJSONTyped,
    SimilarCustomizationsDTOSimilarCustomizationInteriorToJSON,
} from './SimilarCustomizationsDTOSimilarCustomizationInterior';
import type { SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColor } from './SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColor';
import {
    instanceOfSimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColor,
    SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorFromJSON,
    SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorFromJSONTyped,
    SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorToJSON,
} from './SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColor';

/**
 * @type SimilarCustomizationsDTOSimilarCustomization
 * 
 * @export
 */
export type SimilarCustomizationsDTOSimilarCustomization = { type: 'Equipment' } & SimilarCustomizationsDTOSimilarCustomizationEquipment | { type: 'ExteriorColor' } & SimilarCustomizationsDTOSimilarCustomizationExteriorColor | { type: 'Interior' } & SimilarCustomizationsDTOSimilarCustomizationInterior | { type: 'PaintToSampleExteriorColor' } & SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColor | { type: 'RoofColor' } & SimilarCustomizationsDTORoofColor;

export function SimilarCustomizationsDTOSimilarCustomizationFromJSON(json: any): SimilarCustomizationsDTOSimilarCustomization {
    return SimilarCustomizationsDTOSimilarCustomizationFromJSONTyped(json, false);
}

export function SimilarCustomizationsDTOSimilarCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilarCustomizationsDTOSimilarCustomization {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return Object.assign({}, SimilarCustomizationsDTOSimilarCustomizationEquipmentFromJSONTyped(json, true), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, SimilarCustomizationsDTOSimilarCustomizationExteriorColorFromJSONTyped(json, true), { type: 'ExteriorColor' } as const);
        case 'Interior':
            return Object.assign({}, SimilarCustomizationsDTOSimilarCustomizationInteriorFromJSONTyped(json, true), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorFromJSONTyped(json, true), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, SimilarCustomizationsDTORoofColorFromJSONTyped(json, true), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of SimilarCustomizationsDTOSimilarCustomization exists with 'type=${json['type']}'`);
    }
}

export function SimilarCustomizationsDTOSimilarCustomizationToJSON(json: any): any {
    return SimilarCustomizationsDTOSimilarCustomizationToJSONTyped(json, false);
}

export function SimilarCustomizationsDTOSimilarCustomizationToJSONTyped(value?: SimilarCustomizationsDTOSimilarCustomization | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'Equipment':
            return Object.assign({}, SimilarCustomizationsDTOSimilarCustomizationEquipmentToJSON(value), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, SimilarCustomizationsDTOSimilarCustomizationExteriorColorToJSON(value), { type: 'ExteriorColor' } as const);
        case 'Interior':
            return Object.assign({}, SimilarCustomizationsDTOSimilarCustomizationInteriorToJSON(value), { type: 'Interior' } as const);
        case 'PaintToSampleExteriorColor':
            return Object.assign({}, SimilarCustomizationsDTOSimilarCustomizationPaintToSampleExteriorColorToJSON(value), { type: 'PaintToSampleExteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, SimilarCustomizationsDTORoofColorToJSON(value), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of SimilarCustomizationsDTOSimilarCustomization exists with 'type=${value['type']}'`);
    }

}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';
import type { OptionGroupDTOString } from './OptionGroupDTOString';
import {
    OptionGroupDTOStringFromJSON,
    OptionGroupDTOStringFromJSONTyped,
    OptionGroupDTOStringToJSON,
    OptionGroupDTOStringToJSONTyped,
} from './OptionGroupDTOString';

/**
 * 
 * @export
 * @interface GroupedMultiSelectDTOString
 */
export interface GroupedMultiSelectDTOString {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof GroupedMultiSelectDTOString
     */
    value: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<OptionGroupDTOString>}
     * @memberof GroupedMultiSelectDTOString
     */
    categorizedOptions: Array<OptionGroupDTOString>;
}

/**
 * Check if a given object implements the GroupedMultiSelectDTOString interface.
 */
export function instanceOfGroupedMultiSelectDTOString(value: object): value is GroupedMultiSelectDTOString {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('categorizedOptions' in value) || value['categorizedOptions'] === undefined) return false;
    return true;
}

export function GroupedMultiSelectDTOStringFromJSON(json: any): GroupedMultiSelectDTOString {
    return GroupedMultiSelectDTOStringFromJSONTyped(json, false);
}

export function GroupedMultiSelectDTOStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupedMultiSelectDTOString {
    if (json == null) {
        return json;
    }
    return {
        
        'value': ((json['value'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'categorizedOptions': ((json['categorizedOptions'] as Array<any>).map(OptionGroupDTOStringFromJSON)),
    };
}

export function GroupedMultiSelectDTOStringToJSON(json: any): GroupedMultiSelectDTOString {
    return GroupedMultiSelectDTOStringToJSONTyped(json, false);
}

export function GroupedMultiSelectDTOStringToJSONTyped(value?: GroupedMultiSelectDTOString | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'value': ((value['value'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'categorizedOptions': ((value['categorizedOptions'] as Array<any>).map(OptionGroupDTOStringToJSON)),
    };
}


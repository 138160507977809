/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';

/**
 * 
 * @export
 * @interface ModelGenerationCreateRequestDTO
 */
export interface ModelGenerationCreateRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof ModelGenerationCreateRequestDTO
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGenerationCreateRequestDTO
     */
    modelSeriesKey: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGenerationCreateRequestDTO
     */
    faceliftOf?: string;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof ModelGenerationCreateRequestDTO
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {boolean}
     * @memberof ModelGenerationCreateRequestDTO
     */
    classic: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ModelGenerationCreateRequestDTO
     */
    pointOfSalesDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ModelGenerationCreateRequestDTO
     */
    endOfProductionDate?: Date;
}

/**
 * Check if a given object implements the ModelGenerationCreateRequestDTO interface.
 */
export function instanceOfModelGenerationCreateRequestDTO(value: object): value is ModelGenerationCreateRequestDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('modelSeriesKey' in value) || value['modelSeriesKey'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('classic' in value) || value['classic'] === undefined) return false;
    return true;
}

export function ModelGenerationCreateRequestDTOFromJSON(json: any): ModelGenerationCreateRequestDTO {
    return ModelGenerationCreateRequestDTOFromJSONTyped(json, false);
}

export function ModelGenerationCreateRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelGenerationCreateRequestDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelSeriesKey': json['modelSeriesKey'],
        'faceliftOf': json['faceliftOf'] == null ? undefined : json['faceliftOf'],
        'name': BaseNameDTOFromJSON(json['name']),
        'classic': json['classic'],
        'pointOfSalesDate': json['pointOfSalesDate'] == null ? undefined : (new Date(json['pointOfSalesDate'])),
        'endOfProductionDate': json['endOfProductionDate'] == null ? undefined : (new Date(json['endOfProductionDate'])),
    };
}

export function ModelGenerationCreateRequestDTOToJSON(json: any): ModelGenerationCreateRequestDTO {
    return ModelGenerationCreateRequestDTOToJSONTyped(json, false);
}

export function ModelGenerationCreateRequestDTOToJSONTyped(value?: ModelGenerationCreateRequestDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'modelSeriesKey': value['modelSeriesKey'],
        'faceliftOf': value['faceliftOf'],
        'name': BaseNameDTOToJSON(value['name']),
        'classic': value['classic'],
        'pointOfSalesDate': value['pointOfSalesDate'] == null ? undefined : ((value['pointOfSalesDate']).toISOString().substring(0,10)),
        'endOfProductionDate': value['endOfProductionDate'] == null ? undefined : ((value['endOfProductionDate']).toISOString().substring(0,10)),
    };
}


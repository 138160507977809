/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SortDirection = {
    ASC: 'ASC',
    DESC: 'DESC'
} as const;
export type SortDirection = typeof SortDirection[keyof typeof SortDirection];


export function instanceOfSortDirection(value: any): boolean {
    for (const key in SortDirection) {
        if (Object.prototype.hasOwnProperty.call(SortDirection, key)) {
            if (SortDirection[key as keyof typeof SortDirection] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SortDirectionFromJSON(json: any): SortDirection {
    return SortDirectionFromJSONTyped(json, false);
}

export function SortDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortDirection {
    return json as SortDirection;
}

export function SortDirectionToJSON(value?: SortDirection | null): any {
    return value as any;
}

export function SortDirectionToJSONTyped(value: any, ignoreDiscriminator: boolean): SortDirection {
    return value as SortDirection;
}


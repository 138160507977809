import { routes } from "../../../../../../common/routes"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { getBoolFormElement } from "../../../../../../components/formelements/boolformelement/BoolFormElement"
import { getColorPickerFormElement } from "../../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTagFormElement } from "../../../../../../components/formelements/tagformelement/TagFormElement"
import { toTagOption } from "../../../../../../components/formelements/tagformelement/TagOption"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import {
    ExteriorColorType,
    OptionCustomizationAttributesDTOEquipment,
    OptionCustomizationAttributesDTOExteriorColor,
    OptionCustomizationAttributesDTOInterior,
    OptionCustomizationAttributesDTOObject,
    OptionCustomizationAttributesDTOPaintToSampleExteriorColor,
    OptionCustomizationAttributesDTORoofColor,
    OptionCustomizationAttributesUpdateDTOEquipment,
    OptionCustomizationAttributesUpdateDTOExteriorColor,
    OptionCustomizationAttributesUpdateDTOInterior,
    OptionCustomizationAttributesUpdateDTOInteriorValue,
    OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject,
    OptionCustomizationAttributesUpdateDTORoofColor,
    OptionTagTypeDTO,
} from "../../../../../../generated/pdsapi"
import { useSyncState } from "../../../../../../hooks/useSyncState"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import React, { FC, JSX } from "react"
import { propertiesOf, Property } from "../../../../../../common/property"
import { FormDisplayData, getEditData } from "../../../../../../components/formelements/FormDisplayData"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { getTagsFormElement } from "../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { PButtonPure, PDivider, PHeadline, PText } from "@porsche-design-system/components-react"
import { conditionalValueLabelOf } from "../../../../../../common/conditionalValueLabel"
import { getNumberFormElement } from "../../../../../../components/formelements/numberformelement/NumberFormElement"
import { findOption } from "../../../../../../viewtemplates/list/filter/ListFilterTagsConfig"
import ObjectID from "bson-objectid"
import { OptionCustomizationType, optionCustomizationTypeOptions } from "../../../optionCustomizationTypes"
import { getGroupedTagsFormElement } from "../../../../../../components/formelements/groupedtagsformelement/GroupedTagsFormElement"
import { InteriorColorTile } from "@finder/ui-kit"
import { toGroupedOption } from "../../../../../../components/formelements/groupedselecttextformelement/GroupedSelectOption"
import { OptionCustomizationKey } from "../../OptionCustomizationKey"

export const OptionCustomizationDetailsAttributesCardContent: FC<
    ProductDetailsCardContentProps<
        OptionCustomizationAttributesDTOObject,
        OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject,
        OptionCustomizationKey
    >
> = ({ formDisplayData }) => {
    const optionCustomizationProperties = propertiesOf<OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject>()
    const [conditionalValues, setConditionalValues] = useSyncState(formDisplayData.data?.conditionalValues ?? [], {
        onChange: (conditionalValues) =>
            getEditData(formDisplayData)?.setValue(
                "conditionalValues",
                conditionalValues.map((conditionalValue) => ({
                    key: conditionalValue.key,
                    modelYearRange: {
                        start: conditionalValue.modelYearRange?.start,
                        end: conditionalValue.modelYearRange?.end,
                    },
                    derivativeKeys: conditionalValue.derivativeKeys,
                    value: convertValueToValueUpdate(formDisplayData.data!.type, conditionalValue.value),
                }))
            ),
    })

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <FormElementWrapper
                    label={"Type"}
                    formDisplayData={formDisplayData}
                    field={optionCustomizationProperties.type}
                    FormElement={getSelectTextFormElement<
                        OptionCustomizationAttributesDTOObject,
                        OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject
                    >()}
                    getViewProps={(data) => ({
                        text: optionCustomizationTypeOptions.find((option) => option.value === data.type)?.label ?? data.type,
                    })}
                    getEditProps={(data) => ({
                        value: data.type,
                        options: optionCustomizationTypeOptions,
                        readOnly: true,
                    })}
                />
                <FormElementWrapper
                    label={"Option Code"}
                    formDisplayData={formDisplayData}
                    field={optionCustomizationProperties.optionCode}
                    FormElement={getTextFormElement<
                        OptionCustomizationAttributesDTOObject,
                        OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject
                    >()}
                    getViewProps={(data) => ({
                        value: data.optionCode,
                        showAsHint: false,
                    })}
                    getEditProps={(data) => ({
                        value: data.optionCode,
                    })}
                />
                <FormElementWrapper
                    label={"Model Generations"}
                    formDisplayData={formDisplayData}
                    field={optionCustomizationProperties.modelGenerationKeys}
                    FormElement={getTagsFormElement<
                        OptionCustomizationAttributesDTOObject,
                        OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject
                    >()}
                    getViewProps={(data) => ({
                        emptyLabel: "-",
                        tags: data.modelGenerations.value.map((modelGeneration) => toTagOption(modelGeneration, routes.models.modelGenerations.details)),
                    })}
                    getEditProps={(data) => ({
                        emptyLabel: "-",
                        value: data.modelGenerations.value.map((modelGeneration) => modelGeneration.key),
                        options: data.modelGenerations.options.map(toOption),
                        clearable: true,
                    })}
                />
                <FormElementWrapper
                    label={"Default Value Status"}
                    formDisplayData={formDisplayData}
                    field={optionCustomizationProperties.isDefaultValueActive}
                    FormElement={getBoolFormElement<
                        OptionCustomizationAttributesDTOObject,
                        OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject
                    >()}
                    getViewProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.isDefaultValueActive,
                    })}
                    getEditProps={(data) => ({
                        falseMessage: "Inactive",
                        trueMessage: "Active",
                        value: data.isDefaultValueActive,
                    })}
                />
            </RowGroupWrapper>

            <PDivider />
            <>
                <PHeadline variant={"headline-4"}>Default Value</PHeadline>
                {conditionalValues.length ? (
                    <PText color={"neutral-contrast-medium"}>Not applied in: {conditionalValues.map(conditionalValueLabelOf).join(", ")}</PText>
                ) : null}
            </>

            <OptionCustomizationValueContent
                key={"defaultValue"}
                formDisplayData={formDisplayData}
                getValue={(attributes) => attributes.defaultValue}
                property={optionCustomizationProperties.defaultValue}
            />

            {conditionalValues.length || formDisplayData.kind === "EDIT" ? <PDivider /> : null}
            {conditionalValues.length || formDisplayData.kind === "EDIT" ? <PHeadline variant={"headline-4"}>Conditional Values</PHeadline> : null}

            {conditionalValues.flatMap((conditionalValue, index) => {
                const property = optionCustomizationProperties.conditionalValues._index(index)

                return [
                    <RowGroupWrapper key={`configuration_${index}`} label={"Configuration"}>
                        <FormElementWrapper
                            label={"Key"}
                            formDisplayData={formDisplayData}
                            field={property.key}
                            FormElement={getTextFormElement<
                                OptionCustomizationAttributesDTOObject,
                                OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject
                            >()}
                            getViewProps={() => ({
                                value: conditionalValue.key,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.key,
                                readOnly: true,
                            })}
                        />
                    </RowGroupWrapper>,
                    <RowGroupWrapper key={`model_year_condition_${index}`} label={"Model Year Condition"}>
                        <FormElementWrapper
                            label={"From"}
                            formDisplayData={formDisplayData}
                            field={property.modelYearRange.start}
                            FormElement={getNumberFormElement<
                                OptionCustomizationAttributesDTOObject,
                                OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject
                            >()}
                            getViewProps={() => ({
                                value: conditionalValue.modelYearRange?.start,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.modelYearRange?.start,
                                optional: true,
                            })}
                        />
                        <FormElementWrapper
                            label={"To"}
                            formDisplayData={formDisplayData}
                            field={property.modelYearRange.end}
                            FormElement={getNumberFormElement<
                                OptionCustomizationAttributesDTOObject,
                                OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject
                            >()}
                            getViewProps={() => ({
                                value: conditionalValue.modelYearRange?.end,
                            })}
                            getEditProps={() => ({
                                value: conditionalValue.modelYearRange?.end,
                                optional: true,
                            })}
                        />
                    </RowGroupWrapper>,
                    <RowGroupWrapper key={`derivative_keys_${index}`} label={"Derivative Condition"}>
                        <FormElementWrapper
                            label={"Derivatives"}
                            formDisplayData={formDisplayData}
                            field={property.derivativeKeys}
                            FormElement={getTagsFormElement<
                                OptionCustomizationAttributesDTOObject,
                                OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject
                            >()}
                            getViewProps={(attributes) => ({
                                emptyLabel: "-",
                                tags:
                                    conditionalValue.derivativeKeys?.map((derivativeKey) => {
                                        const option = findOption(attributes.derivatives, derivativeKey) ?? {
                                            key: derivativeKey,
                                            label: derivativeKey,
                                        }
                                        return toTagOption(option, () => routes.models.derivatives.details(derivativeKey))
                                    }) ?? [],
                            })}
                            getEditProps={(data) => ({
                                emptyLabel: "-",
                                value: conditionalValue.derivativeKeys ?? [],
                                options: data.derivatives.map(toOption),
                                clearable: true,
                                optional: true,
                            })}
                        />
                    </RowGroupWrapper>,
                    <OptionCustomizationValueContent
                        key={`customization_value_content_${index}`}
                        formDisplayData={formDisplayData}
                        getValue={() => conditionalValue.value}
                        property={property.value}
                    />,
                    formDisplayData.kind === "EDIT" ? (
                        <PButtonPure
                            key={`delete_conditional_value_${index}`}
                            type={"button"}
                            icon={"delete"}
                            onClick={() => {
                                setConditionalValues(conditionalValues.filter((_, i) => i !== index))
                            }}
                        >
                            Delete Conditional Value
                        </PButtonPure>
                    ) : null,
                    formDisplayData.kind === "EDIT" || index !== conditionalValues.length - 1 ? <PDivider key={`divider_${index}`} /> : null,
                ]
            })}
            {formDisplayData.kind === "EDIT" ? (
                <PButtonPure
                    type={"button"}
                    icon={"add"}
                    onClick={() =>
                        setConditionalValues((conditionalValues) => [
                            ...conditionalValues,
                            {
                                key: ObjectID().toHexString(),
                                modelYearRange: { start: 2020, end: 2020 },
                                value: formDisplayData.data.defaultValue,
                            },
                        ])
                    }
                >
                    Add Conditional Value
                </PButtonPure>
            ) : null}
        </SpacedItems>
    )
}

interface OptionCustomizationValueContentProps<
    ATTRIBUTES extends OptionCustomizationAttributesDTOObject = OptionCustomizationAttributesDTOObject,
    UPDATE extends
        OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject = OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject,
> {
    getValue: (attributes: ATTRIBUTES) => ATTRIBUTES["defaultValue"]
    property: Property<UPDATE, UPDATE["defaultValue"]>
    formDisplayData: FormDisplayData<ATTRIBUTES, UPDATE>
}

const OptionCustomizationValueContent: FC<OptionCustomizationValueContentProps> = (props) => (
    <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
        <DetailsDefaultLocalizationsFormRow
            label={"Name"}
            formDisplayData={props.formDisplayData}
            field={props.property.name}
            getViewProps={(data) => ({
                value: props.getValue(data).name,
            })}
            getEditProps={(data) => ({
                value: props.getValue(data).name,
                optional: true,
            })}
        />
        <DetailsDefaultLocalizationsFormRow
            label={"Description"}
            formDisplayData={props.formDisplayData}
            field={props.property.description}
            getViewProps={(data) => ({
                value: props.getValue(data).description,
            })}
            getEditProps={(data) => ({
                value: props.getValue(data).description,
                optional: true,
            })}
        />
        <SpecificOptionCustomizationValueContent {...props} />
    </SpacedItems>
)

type EquipmentCustomizationAttributesDTO = { type: "Equipment" } & OptionCustomizationAttributesDTOEquipment
type EquipmentCustomizationAttributesUpdateDTO = { type: "Equipment" } & OptionCustomizationAttributesUpdateDTOEquipment
type InteriorCustomizationAttributesDTO = { type: "Interior" } & OptionCustomizationAttributesDTOInterior
type InteriorCustomizationAttributesUpdateDTO = { type: "Interior" } & OptionCustomizationAttributesUpdateDTOInterior
type ExteriorColorCustomizationAttributesDTO = { type: "ExteriorColor" } & OptionCustomizationAttributesDTOExteriorColor
type ExteriorColorCustomizationAttributesUpdateDTO = { type: "ExteriorColor" } & OptionCustomizationAttributesUpdateDTOExteriorColor
type RoofColorCustomizationAttributesDTO = { type: "RoofColor" } & OptionCustomizationAttributesDTORoofColor
type RoofColorCustomizationAttributesUpdateDTO = { type: "RoofColor" } & OptionCustomizationAttributesUpdateDTORoofColor

const SpecificOptionCustomizationValueContent: FC<OptionCustomizationValueContentProps> = (props): JSX.Element | null => {
    switch (props.formDisplayData.data?.type) {
        case "Equipment": {
            const { getValue, property, formDisplayData } = props as OptionCustomizationValueContentProps<
                EquipmentCustomizationAttributesDTO,
                EquipmentCustomizationAttributesUpdateDTO
            >
            return (
                <RowGroupWrapper label={"Tags"}>
                    <FormElementWrapper
                        label={"Equipment Tags"}
                        formDisplayData={formDisplayData}
                        field={property.equipmentTagKeys}
                        FormElement={getGroupedTagsFormElement<EquipmentCustomizationAttributesDTO, EquipmentCustomizationAttributesUpdateDTO>()}
                        getViewProps={(data) => ({
                            tags: getValue(data).equipmentTags.value.map((tag) =>
                                toTagOption(
                                    {
                                        key: { key: tag.key, type: OptionTagTypeDTO.EQUIPMENT },
                                        label: tag.label,
                                    },
                                    routes.options.tags.details
                                )
                            ),
                            emptyLabel: "-",
                        })}
                        getEditProps={(data) => ({
                            value: getValue(data).equipmentTags.value.map((tag) => tag.key),
                            options: getValue(data).equipmentTags.categorizedOptions.map(toGroupedOption),
                            emptyLabel: "-",
                            clearable: true,
                        })}
                    />
                </RowGroupWrapper>
            )
        }

        case "Interior": {
            const { getValue, property, formDisplayData } = props as OptionCustomizationValueContentProps<
                InteriorCustomizationAttributesDTO,
                InteriorCustomizationAttributesUpdateDTO
            >

            const getColor = () => {
                switch (formDisplayData.kind) {
                    case "LOADING":
                        return undefined
                    case "VIEW":
                        return getValue(formDisplayData.data).color
                    case "EDIT":
                        return formDisplayData.watch(property.color._path) as OptionCustomizationAttributesUpdateDTOInteriorValue["color"]
                }
            }
            const color = getColor()
            const colorLabel = (
                <>
                    Color
                    <InteriorColorTile
                        seatColor={color?.seatColorHexCode ?? "#000000"}
                        seatStitchingColor={color?.seatStitchingColorHexCode}
                        cockpitColor={color?.cockpitColorHexCode}
                        cockpitStitchingColor={color?.cockpitStitchingColorHexCode}
                    />
                </>
            )
            return (
                <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
                    <RowGroupWrapper label={colorLabel}>
                        <FormElementWrapper
                            label={"Seat Color Hex Code"}
                            formDisplayData={formDisplayData}
                            field={property.color.seatColorHexCode}
                            FormElement={getColorPickerFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                            getViewProps={(data) => ({
                                hexValue: getValue(data).color.seatColorHexCode,
                                disableGlossy: true,
                            })}
                            getEditProps={(data) => ({
                                hexValue: getValue(data).color.seatColorHexCode,
                                disableGlossy: true,
                            })}
                        />
                        <FormElementWrapper
                            label={"Seat Stitching Color Hex Code"}
                            formDisplayData={formDisplayData}
                            field={property.color.seatStitchingColorHexCode}
                            FormElement={getColorPickerFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                            getViewProps={(data) => ({
                                hexValue: getValue(data).color.seatStitchingColorHexCode,
                                disableGlossy: true,
                            })}
                            getEditProps={(data) => ({
                                hexValue: getValue(data).color.seatStitchingColorHexCode,
                                disableGlossy: true,
                            })}
                        />
                    </RowGroupWrapper>
                    <RowGroupWrapper>
                        <FormElementWrapper
                            label={"Cockpit Color Hex Code"}
                            formDisplayData={formDisplayData}
                            field={property.color.cockpitColorHexCode}
                            FormElement={getColorPickerFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                            getViewProps={(data) => ({
                                hexValue: getValue(data).color.cockpitColorHexCode,
                                disableGlossy: true,
                            })}
                            getEditProps={(data) => ({
                                hexValue: getValue(data).color.cockpitColorHexCode,
                                disableGlossy: true,
                            })}
                        />
                        <FormElementWrapper
                            label={"Cockpit Stitching Color Hex Code"}
                            formDisplayData={formDisplayData}
                            field={property.color.cockpitStitchingColorHexCode}
                            FormElement={getColorPickerFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                            getViewProps={(data) => ({
                                hexValue: getValue(data).color.cockpitStitchingColorHexCode,
                                disableGlossy: true,
                            })}
                            getEditProps={(data) => ({
                                hexValue: getValue(data).color.cockpitStitchingColorHexCode,
                                disableGlossy: true,
                            })}
                        />
                    </RowGroupWrapper>
                    <RowGroupWrapper>
                        <FormElementWrapper
                            label={"Interior Color Tag"}
                            formDisplayData={formDisplayData}
                            field={property.color.tagKey}
                            FormElement={getTagFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                            getViewProps={(data) => {
                                const tag = getValue(data).color.tag.value
                                return {
                                    tag:
                                        tag &&
                                        toTagOption(
                                            {
                                                key: { key: tag.key, type: OptionTagTypeDTO.INTERIOR_COLOR },
                                                label: tag.label,
                                            },
                                            routes.options.tags.details
                                        ),
                                }
                            }}
                            getEditProps={(data) => ({
                                value: getValue(data).color.tag.value?.key ?? "",
                                options: [
                                    {
                                        value: "",
                                        label: "-",
                                    },
                                    ...getValue(data).color.tag.options.map((tag) => toOption(tag)),
                                ],
                                optional: true,
                            })}
                        />
                        <FormElementWrapper
                            label={"Custom Color"}
                            formDisplayData={formDisplayData}
                            field={property.color.isCustomColor}
                            FormElement={getBoolFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                            getViewProps={(data) => ({
                                trueMessage: "Yes",
                                falseMessage: "No",
                                value: getValue(data).color.isCustomColor,
                            })}
                            getEditProps={(data) => ({
                                trueMessage: "Yes",
                                falseMessage: "No",
                                value: getValue(data).color.isCustomColor,
                            })}
                        />
                    </RowGroupWrapper>
                    <RowGroupWrapper label={"Material"}>
                        <FormElementWrapper
                            label={"Interior Material"}
                            formDisplayData={formDisplayData}
                            field={property.material}
                            FormElement={getSelectTextFormElement<InteriorCustomizationAttributesDTO, InteriorCustomizationAttributesUpdateDTO>()}
                            getViewProps={(data) => ({
                                text: getValue(data).material.value.label,
                            })}
                            getEditProps={(data) => ({
                                value: getValue(data).material.value.key,
                                options: getValue(data).material.options.map(toOption),
                            })}
                        />
                    </RowGroupWrapper>
                </SpacedItems>
            )
        }

        case "ExteriorColor": {
            const { getValue, property, formDisplayData } = props as OptionCustomizationValueContentProps<
                ExteriorColorCustomizationAttributesDTO,
                ExteriorColorCustomizationAttributesUpdateDTO
            >
            return (
                <RowGroupWrapper label={"Color"}>
                    <FormElementWrapper
                        label={"Hex Code"}
                        formDisplayData={formDisplayData}
                        field={property.hexCode}
                        FormElement={getColorPickerFormElement<ExteriorColorCustomizationAttributesDTO, ExteriorColorCustomizationAttributesUpdateDTO>()}
                        getViewProps={(data) => ({
                            hexValue: getValue(data).hexCode,
                            disableGlossy: true,
                        })}
                        getEditProps={(data) => ({
                            hexValue: getValue(data).hexCode,
                            disableGlossy: true,
                        })}
                    />
                    <FormElementWrapper
                        label={"Exterior Color Tag"}
                        formDisplayData={formDisplayData}
                        field={property.exteriorColorTagKey}
                        FormElement={getTagFormElement<ExteriorColorCustomizationAttributesDTO, ExteriorColorCustomizationAttributesUpdateDTO>()}
                        getViewProps={(data) => {
                            const tag = getValue(data).tag.value
                            return {
                                tag:
                                    tag &&
                                    toTagOption(
                                        {
                                            key: { key: tag.key, type: OptionTagTypeDTO.EXTERIOR_COLOR },
                                            label: tag.label,
                                        },
                                        routes.options.tags.details
                                    ),
                            }
                        }}
                        getEditProps={(data) => ({
                            value: getValue(data).tag.value?.key ?? "",
                            options: [
                                {
                                    value: "",
                                    label: "-",
                                },
                                ...getValue(data).tag.options.map((tag) => toOption(tag)),
                            ],
                            optional: true,
                        })}
                    />
                    <FormElementWrapper
                        label={"Exterior Color Type"}
                        formDisplayData={formDisplayData}
                        field={property.exteriorColorType}
                        FormElement={getSelectTextFormElement<ExteriorColorCustomizationAttributesDTO, ExteriorColorCustomizationAttributesUpdateDTO>()}
                        getViewProps={(data) => ({
                            text: getValue(data).exteriorColorType.value?.label ?? "-",
                        })}
                        getEditProps={(data) => ({
                            value: getValue(data).exteriorColorType.value?.key ?? "",
                            options: [
                                {
                                    value: "",
                                    label: "-",
                                },
                                ...getValue(data).exteriorColorType.options.map(toOption),
                            ],
                        })}
                    />
                </RowGroupWrapper>
            )
        }

        case "PaintToSampleExteriorColor":
            return null

        case "RoofColor": {
            const { getValue, property, formDisplayData } = props as OptionCustomizationValueContentProps<
                RoofColorCustomizationAttributesDTO,
                RoofColorCustomizationAttributesUpdateDTO
            >
            return (
                <RowGroupWrapper label={"Color"}>
                    <FormElementWrapper
                        label={"Hex Code"}
                        formDisplayData={formDisplayData}
                        field={property.hexCode}
                        FormElement={getColorPickerFormElement<RoofColorCustomizationAttributesDTO, RoofColorCustomizationAttributesUpdateDTO>()}
                        getViewProps={(data) => ({
                            hexValue: getValue(data).hexCode,
                            disableGlossy: true,
                        })}
                        getEditProps={(data) => ({
                            hexValue: getValue(data).hexCode,
                            disableGlossy: true,
                        })}
                    />
                    <FormElementWrapper
                        label={"Roof Color Tag"}
                        formDisplayData={formDisplayData}
                        field={property.roofColorTagKey}
                        FormElement={getTagFormElement<RoofColorCustomizationAttributesDTO, RoofColorCustomizationAttributesUpdateDTO>()}
                        getViewProps={(data) => {
                            const tag = getValue(data).tag.value
                            return {
                                tag:
                                    tag &&
                                    toTagOption(
                                        {
                                            key: { key: tag.key, type: OptionTagTypeDTO.ROOF_COLOR },
                                            label: tag.label,
                                        },
                                        routes.options.tags.details
                                    ),
                            }
                        }}
                        getEditProps={(data) => ({
                            value: getValue(data).tag.value?.key ?? "",
                            options: [
                                {
                                    value: "",
                                    label: "-",
                                },
                                ...getValue(data).tag.options.map((tag) => toOption(tag)),
                            ],
                            optional: true,
                        })}
                    />
                </RowGroupWrapper>
            )
        }

        case undefined:
            return null
    }
}

const convertValueToValueUpdate = (
    type: OptionCustomizationType,
    value: OptionCustomizationAttributesDTOObject["defaultValue"]
): OptionCustomizationAttributesUpdateDTOObjectOptionCustomizationObject["defaultValue"] => {
    switch (type) {
        case "Equipment": {
            const equipmentValue = value as EquipmentCustomizationAttributesDTO["defaultValue"]
            return {
                name: equipmentValue.name,
                description: equipmentValue.description,
                equipmentTagKeys: equipmentValue.equipmentTags.value.map((option) => option.key),
            } satisfies OptionCustomizationAttributesUpdateDTOEquipment["defaultValue"]
        }
        case "Interior": {
            const interiorValue = value as InteriorCustomizationAttributesDTO["defaultValue"]
            return {
                name: interiorValue.name,
                description: interiorValue.description,
                color: {
                    seatColorHexCode: interiorValue.color.seatColorHexCode,
                    seatStitchingColorHexCode: interiorValue.color.seatStitchingColorHexCode,
                    cockpitColorHexCode: interiorValue.color.cockpitColorHexCode,
                    cockpitStitchingColorHexCode: interiorValue.color.cockpitStitchingColorHexCode,
                    tagKey: interiorValue.color.tag.value?.key,
                    isCustomColor: interiorValue.color.isCustomColor,
                },
                material: interiorValue.material.value?.key,
            } satisfies InteriorCustomizationAttributesUpdateDTO["defaultValue"]
        }
        case "ExteriorColor": {
            const exteriorColorValue = value as ExteriorColorCustomizationAttributesDTO["defaultValue"]
            return {
                name: exteriorColorValue.name,
                description: exteriorColorValue.description,
                hexCode: exteriorColorValue.hexCode,
                exteriorColorTagKey: exteriorColorValue.tag.value?.key,
                exteriorColorType: exteriorColorValue.exteriorColorType.value?.key ?? ("" as ExteriorColorType),
            } satisfies ExteriorColorCustomizationAttributesUpdateDTO["defaultValue"]
        }
        case "PaintToSampleExteriorColor": {
            return {
                name: value.name,
                description: value.description,
            } satisfies OptionCustomizationAttributesDTOPaintToSampleExteriorColor["defaultValue"]
        }
        case "RoofColor": {
            const roofColorValue = value as RoofColorCustomizationAttributesDTO["defaultValue"]
            return {
                name: roofColorValue.name,
                description: roofColorValue.description,
                hexCode: roofColorValue.hexCode,
                roofColorTagKey: roofColorValue.tag.value?.key,
            } satisfies RoofColorCustomizationAttributesUpdateDTO["defaultValue"]
        }
    }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VideoUpdateDTO
 */
export interface VideoUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof VideoUpdateDTO
     */
    vimeoId?: string;
    /**
     * 
     * @type {string}
     * @memberof VideoUpdateDTO
     */
    hash?: string;
}

/**
 * Check if a given object implements the VideoUpdateDTO interface.
 */
export function instanceOfVideoUpdateDTO(value: object): value is VideoUpdateDTO {
    return true;
}

export function VideoUpdateDTOFromJSON(json: any): VideoUpdateDTO {
    return VideoUpdateDTOFromJSONTyped(json, false);
}

export function VideoUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VideoUpdateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'vimeoId': json['vimeoId'] == null ? undefined : json['vimeoId'],
        'hash': json['hash'] == null ? undefined : json['hash'],
    };
}

export function VideoUpdateDTOToJSON(json: any): VideoUpdateDTO {
    return VideoUpdateDTOToJSONTyped(json, false);
}

export function VideoUpdateDTOToJSONTyped(value?: VideoUpdateDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'vimeoId': value['vimeoId'],
        'hash': value['hash'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationAttributesUpdateDTORoofColorValue } from './OptionCustomizationAttributesUpdateDTORoofColorValue';
import {
    OptionCustomizationAttributesUpdateDTORoofColorValueFromJSON,
    OptionCustomizationAttributesUpdateDTORoofColorValueFromJSONTyped,
    OptionCustomizationAttributesUpdateDTORoofColorValueToJSON,
    OptionCustomizationAttributesUpdateDTORoofColorValueToJSONTyped,
} from './OptionCustomizationAttributesUpdateDTORoofColorValue';
import type { OptionCustomizationConditionalValueUpdateDTOModelYearRange } from './OptionCustomizationConditionalValueUpdateDTOModelYearRange';
import {
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSON,
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSONTyped,
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSON,
    OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSONTyped,
} from './OptionCustomizationConditionalValueUpdateDTOModelYearRange';

/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue
 */
export interface OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue
     */
    key: string;
    /**
     * 
     * @type {OptionCustomizationConditionalValueUpdateDTOModelYearRange}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue
     */
    modelYearRange: OptionCustomizationConditionalValueUpdateDTOModelYearRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue
     */
    derivativeKeys?: Array<string>;
    /**
     * 
     * @type {OptionCustomizationAttributesUpdateDTORoofColorValue}
     * @memberof OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue
     */
    value: OptionCustomizationAttributesUpdateDTORoofColorValue;
}

/**
 * Check if a given object implements the OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue interface.
 */
export function instanceOfOptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue(value: object): value is OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('modelYearRange' in value) || value['modelYearRange'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueFromJSON(json: any): OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue {
    return OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelYearRange': OptionCustomizationConditionalValueUpdateDTOModelYearRangeFromJSON(json['modelYearRange']),
        'derivativeKeys': json['derivativeKeys'] == null ? undefined : json['derivativeKeys'],
        'value': OptionCustomizationAttributesUpdateDTORoofColorValueFromJSON(json['value']),
    };
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueToJSON(json: any): OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue {
    return OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueToJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueToJSONTyped(value?: OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'modelYearRange': OptionCustomizationConditionalValueUpdateDTOModelYearRangeToJSON(value['modelYearRange']),
        'derivativeKeys': value['derivativeKeys'],
        'value': OptionCustomizationAttributesUpdateDTORoofColorValueToJSON(value['value']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { OptionTagAttributesDTOEquipment } from './OptionTagAttributesDTOEquipment';
import {
    instanceOfOptionTagAttributesDTOEquipment,
    OptionTagAttributesDTOEquipmentFromJSON,
    OptionTagAttributesDTOEquipmentFromJSONTyped,
    OptionTagAttributesDTOEquipmentToJSON,
} from './OptionTagAttributesDTOEquipment';
import type { OptionTagAttributesDTOExteriorColor } from './OptionTagAttributesDTOExteriorColor';
import {
    instanceOfOptionTagAttributesDTOExteriorColor,
    OptionTagAttributesDTOExteriorColorFromJSON,
    OptionTagAttributesDTOExteriorColorFromJSONTyped,
    OptionTagAttributesDTOExteriorColorToJSON,
} from './OptionTagAttributesDTOExteriorColor';
import type { OptionTagAttributesDTOInteriorColor } from './OptionTagAttributesDTOInteriorColor';
import {
    instanceOfOptionTagAttributesDTOInteriorColor,
    OptionTagAttributesDTOInteriorColorFromJSON,
    OptionTagAttributesDTOInteriorColorFromJSONTyped,
    OptionTagAttributesDTOInteriorColorToJSON,
} from './OptionTagAttributesDTOInteriorColor';
import type { OptionTagAttributesDTORoofColor } from './OptionTagAttributesDTORoofColor';
import {
    instanceOfOptionTagAttributesDTORoofColor,
    OptionTagAttributesDTORoofColorFromJSON,
    OptionTagAttributesDTORoofColorFromJSONTyped,
    OptionTagAttributesDTORoofColorToJSON,
} from './OptionTagAttributesDTORoofColor';

/**
 * @type OptionTagAttributesDTO
 * 
 * @export
 */
export type OptionTagAttributesDTO = { type: 'Equipment' } & OptionTagAttributesDTOEquipment | { type: 'ExteriorColor' } & OptionTagAttributesDTOExteriorColor | { type: 'InteriorColor' } & OptionTagAttributesDTOInteriorColor | { type: 'RoofColor' } & OptionTagAttributesDTORoofColor;

export function OptionTagAttributesDTOFromJSON(json: any): OptionTagAttributesDTO {
    return OptionTagAttributesDTOFromJSONTyped(json, false);
}

export function OptionTagAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagAttributesDTO {
    if (json == null) {
        return json;
    }
    switch (json['type']) {
        case 'Equipment':
            return Object.assign({}, OptionTagAttributesDTOEquipmentFromJSONTyped(json, true), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionTagAttributesDTOExteriorColorFromJSONTyped(json, true), { type: 'ExteriorColor' } as const);
        case 'InteriorColor':
            return Object.assign({}, OptionTagAttributesDTOInteriorColorFromJSONTyped(json, true), { type: 'InteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionTagAttributesDTORoofColorFromJSONTyped(json, true), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionTagAttributesDTO exists with 'type=${json['type']}'`);
    }
}

export function OptionTagAttributesDTOToJSON(json: any): any {
    return OptionTagAttributesDTOToJSONTyped(json, false);
}

export function OptionTagAttributesDTOToJSONTyped(value?: OptionTagAttributesDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }
    switch (value['type']) {
        case 'Equipment':
            return Object.assign({}, OptionTagAttributesDTOEquipmentToJSON(value), { type: 'Equipment' } as const);
        case 'ExteriorColor':
            return Object.assign({}, OptionTagAttributesDTOExteriorColorToJSON(value), { type: 'ExteriorColor' } as const);
        case 'InteriorColor':
            return Object.assign({}, OptionTagAttributesDTOInteriorColorToJSON(value), { type: 'InteriorColor' } as const);
        case 'RoofColor':
            return Object.assign({}, OptionTagAttributesDTORoofColorToJSON(value), { type: 'RoofColor' } as const);
        default:
            throw new Error(`No variant of OptionTagAttributesDTO exists with 'type=${value['type']}'`);
    }

}


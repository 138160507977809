/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncompleteOrderTypeDTO
 */
export interface IncompleteOrderTypeDTO {
    /**
     * 
     * @type {string}
     * @memberof IncompleteOrderTypeDTO
     */
    key: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IncompleteOrderTypeDTO
     */
    codes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof IncompleteOrderTypeDTO
     */
    modelYear: number;
}

/**
 * Check if a given object implements the IncompleteOrderTypeDTO interface.
 */
export function instanceOfIncompleteOrderTypeDTO(value: object): value is IncompleteOrderTypeDTO {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('codes' in value) || value['codes'] === undefined) return false;
    if (!('modelYear' in value) || value['modelYear'] === undefined) return false;
    return true;
}

export function IncompleteOrderTypeDTOFromJSON(json: any): IncompleteOrderTypeDTO {
    return IncompleteOrderTypeDTOFromJSONTyped(json, false);
}

export function IncompleteOrderTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncompleteOrderTypeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'codes': json['codes'],
        'modelYear': json['modelYear'],
    };
}

export function IncompleteOrderTypeDTOToJSON(json: any): IncompleteOrderTypeDTO {
    return IncompleteOrderTypeDTOToJSONTyped(json, false);
}

export function IncompleteOrderTypeDTOToJSONTyped(value?: IncompleteOrderTypeDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'codes': value['codes'],
        'modelYear': value['modelYear'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ColorItemDTO } from './ColorItemDTO';
import {
    ColorItemDTOFromJSON,
    ColorItemDTOFromJSONTyped,
    ColorItemDTOToJSON,
    ColorItemDTOToJSONTyped,
} from './ColorItemDTO';

/**
 * 
 * @export
 * @interface PageDTOColorItemDTO
 */
export interface PageDTOColorItemDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTOColorItemDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<ColorItemDTO>}
     * @memberof PageDTOColorItemDTO
     */
    content: Array<ColorItemDTO>;
}

/**
 * Check if a given object implements the PageDTOColorItemDTO interface.
 */
export function instanceOfPageDTOColorItemDTO(value: object): value is PageDTOColorItemDTO {
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function PageDTOColorItemDTOFromJSON(json: any): PageDTOColorItemDTO {
    return PageDTOColorItemDTOFromJSONTyped(json, false);
}

export function PageDTOColorItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOColorItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(ColorItemDTOFromJSON)),
    };
}

export function PageDTOColorItemDTOToJSON(json: any): PageDTOColorItemDTO {
    return PageDTOColorItemDTOToJSONTyped(json, false);
}

export function PageDTOColorItemDTOToJSONTyped(value?: PageDTOColorItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'totalItemCount': value['totalItemCount'],
        'content': ((value['content'] as Array<any>).map(ColorItemDTOToJSON)),
    };
}


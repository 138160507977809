/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationAttributesUpdateDTORoofColorValue } from './OptionCustomizationAttributesUpdateDTORoofColorValue';
import {
    OptionCustomizationAttributesUpdateDTORoofColorValueFromJSON,
    OptionCustomizationAttributesUpdateDTORoofColorValueFromJSONTyped,
    OptionCustomizationAttributesUpdateDTORoofColorValueToJSON,
    OptionCustomizationAttributesUpdateDTORoofColorValueToJSONTyped,
} from './OptionCustomizationAttributesUpdateDTORoofColorValue';
import type { OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue } from './OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue';
import {
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueFromJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueFromJSONTyped,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueToJSON,
    OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueToJSONTyped,
} from './OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesUpdateDTORoofColor
 */
export interface OptionCustomizationAttributesUpdateDTORoofColor {
    /**
     * 
     * @type {OptionCustomizationAttributesUpdateDTORoofColorValue}
     * @memberof OptionCustomizationAttributesUpdateDTORoofColor
     */
    defaultValue: OptionCustomizationAttributesUpdateDTORoofColorValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesUpdateDTORoofColor
     */
    optionCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationAttributesUpdateDTORoofColor
     */
    modelGenerationKeys: Array<string>;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue>}
     * @memberof OptionCustomizationAttributesUpdateDTORoofColor
     */
    conditionalValues: Array<OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValue>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesUpdateDTORoofColor
     */
    isDefaultValueActive: boolean;
}

/**
 * Check if a given object implements the OptionCustomizationAttributesUpdateDTORoofColor interface.
 */
export function instanceOfOptionCustomizationAttributesUpdateDTORoofColor(value: object): value is OptionCustomizationAttributesUpdateDTORoofColor {
    if (!('defaultValue' in value) || value['defaultValue'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelGenerationKeys' in value) || value['modelGenerationKeys'] === undefined) return false;
    if (!('conditionalValues' in value) || value['conditionalValues'] === undefined) return false;
    if (!('isDefaultValueActive' in value) || value['isDefaultValueActive'] === undefined) return false;
    return true;
}

export function OptionCustomizationAttributesUpdateDTORoofColorFromJSON(json: any): OptionCustomizationAttributesUpdateDTORoofColor {
    return OptionCustomizationAttributesUpdateDTORoofColorFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTORoofColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTORoofColor {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTORoofColorValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'modelGenerationKeys': json['modelGenerationKeys'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
    };
}

export function OptionCustomizationAttributesUpdateDTORoofColorToJSON(json: any): OptionCustomizationAttributesUpdateDTORoofColor {
    return OptionCustomizationAttributesUpdateDTORoofColorToJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTORoofColorToJSONTyped(value?: OptionCustomizationAttributesUpdateDTORoofColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultValue': OptionCustomizationAttributesUpdateDTORoofColorValueToJSON(value['defaultValue']),
        'optionCode': value['optionCode'],
        'modelGenerationKeys': value['modelGenerationKeys'],
        'conditionalValues': ((value['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueUpdateDTOOptionCustomizationAttributesUpdateDTORoofColorValueToJSON)),
        'isDefaultValueActive': value['isDefaultValueActive'],
    };
}


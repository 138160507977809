/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IncompleteOrderTypeDTO } from './IncompleteOrderTypeDTO';
import {
    IncompleteOrderTypeDTOFromJSON,
    IncompleteOrderTypeDTOFromJSONTyped,
    IncompleteOrderTypeDTOToJSON,
    IncompleteOrderTypeDTOToJSONTyped,
} from './IncompleteOrderTypeDTO';

/**
 * 
 * @export
 * @interface PageDTOIncompleteOrderTypeDTO
 */
export interface PageDTOIncompleteOrderTypeDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTOIncompleteOrderTypeDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<IncompleteOrderTypeDTO>}
     * @memberof PageDTOIncompleteOrderTypeDTO
     */
    content: Array<IncompleteOrderTypeDTO>;
}

/**
 * Check if a given object implements the PageDTOIncompleteOrderTypeDTO interface.
 */
export function instanceOfPageDTOIncompleteOrderTypeDTO(value: object): value is PageDTOIncompleteOrderTypeDTO {
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function PageDTOIncompleteOrderTypeDTOFromJSON(json: any): PageDTOIncompleteOrderTypeDTO {
    return PageDTOIncompleteOrderTypeDTOFromJSONTyped(json, false);
}

export function PageDTOIncompleteOrderTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOIncompleteOrderTypeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(IncompleteOrderTypeDTOFromJSON)),
    };
}

export function PageDTOIncompleteOrderTypeDTOToJSON(json: any): PageDTOIncompleteOrderTypeDTO {
    return PageDTOIncompleteOrderTypeDTOToJSONTyped(json, false);
}

export function PageDTOIncompleteOrderTypeDTOToJSONTyped(value?: PageDTOIncompleteOrderTypeDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'totalItemCount': value['totalItemCount'],
        'content': ((value['content'] as Array<any>).map(IncompleteOrderTypeDTOToJSON)),
    };
}


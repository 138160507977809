import { TableFilterProps } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnGroupsWrapper } from "../../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { OrderTypeFilterOptionsDTO } from "../../../../../generated/pdsapi"
import { OrderTypeFilter } from "../../../../../pdsapi"
import { FunctionComponent } from "react"
import { MultiSelectFilter } from "../../../../../components/filter/MultiSelectFilter"
import { GroupedMultiSelectFilter } from "../../../../../components/filter/GroupedMultiSelectFilter"

export const OrderTypeListFilter: FunctionComponent<TableFilterProps<OrderTypeFilter, OrderTypeFilterOptionsDTO>> = ({ filter, setFilter, filterOptions }) => {
    const modelSeriesKeysFilter = (
        <MultiSelectFilter
            label={"Model Series"}
            options={filterOptions?.modelSeries}
            value={filter.modelSeriesKeys}
            onChange={(modelSeriesKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelSeriesKeys,
                    modelGenerationKeys: undefined,
                    derivativeKeys: undefined,
                }))
            }
        />
    )

    const relevantModelGenerations = filter.modelSeriesKeys
        ? filterOptions?.modelSeriesRelatedModelGenerations.filter((modelSeriesGroup) => filter.modelSeriesKeys!.has(modelSeriesGroup.relatedKey))
        : filterOptions?.modelSeriesRelatedModelGenerations
    const modelGenerationKeysFilter = (
        <GroupedMultiSelectFilter
            label={"Model Generations"}
            options={relevantModelGenerations}
            value={filter.modelGenerationKeys}
            onChange={(modelGenerationKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelGenerationKeys,
                }))
            }
        />
    )

    const relevantDerivatives = filter.modelSeriesKeys
        ? filterOptions?.modelSeriesRelatedDerivatives.filter((modelSeriesGroup) => filter.modelSeriesKeys!.has(modelSeriesGroup.relatedKey))
        : filterOptions?.modelSeriesRelatedDerivatives
    const derivativeKeysFilter = (
        <GroupedMultiSelectFilter
            label={"Derivatives"}
            options={relevantDerivatives}
            value={filter.derivativeKeys}
            onChange={(derivativeKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    derivativeKeys,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper>
            <ColumnGroupWrapper label={"Model"}>
                {modelSeriesKeysFilter}
                {modelGenerationKeysFilter}
                {derivativeKeysFilter}
            </ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}

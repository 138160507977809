/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderTypeOptionItemDTOIndividualEquipment
 */
export interface OrderTypeOptionItemDTOIndividualEquipment {
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTOIndividualEquipment
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTOIndividualEquipment
     */
    optionCode: string;
    /**
     * 
     * @type {number}
     * @memberof OrderTypeOptionItemDTOIndividualEquipment
     */
    sortIndex: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTOIndividualEquipment
     */
    imageKey?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTOIndividualEquipment
     */
    categoryName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeOptionItemDTOIndividualEquipment
     */
    tagNames: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeOptionItemDTOIndividualEquipment
     */
    family?: string;
}

/**
 * Check if a given object implements the OrderTypeOptionItemDTOIndividualEquipment interface.
 */
export function instanceOfOrderTypeOptionItemDTOIndividualEquipment(value: object): value is OrderTypeOptionItemDTOIndividualEquipment {
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('sortIndex' in value) || value['sortIndex'] === undefined) return false;
    if (!('tagNames' in value) || value['tagNames'] === undefined) return false;
    return true;
}

export function OrderTypeOptionItemDTOIndividualEquipmentFromJSON(json: any): OrderTypeOptionItemDTOIndividualEquipment {
    return OrderTypeOptionItemDTOIndividualEquipmentFromJSONTyped(json, false);
}

export function OrderTypeOptionItemDTOIndividualEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeOptionItemDTOIndividualEquipment {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'optionCode': json['optionCode'],
        'sortIndex': json['sortIndex'],
        'imageKey': json['imageKey'] == null ? undefined : json['imageKey'],
        'categoryName': json['categoryName'] == null ? undefined : json['categoryName'],
        'tagNames': json['tagNames'],
        'family': json['family'] == null ? undefined : json['family'],
    };
}

export function OrderTypeOptionItemDTOIndividualEquipmentToJSON(json: any): OrderTypeOptionItemDTOIndividualEquipment {
    return OrderTypeOptionItemDTOIndividualEquipmentToJSONTyped(json, false);
}

export function OrderTypeOptionItemDTOIndividualEquipmentToJSONTyped(value?: OrderTypeOptionItemDTOIndividualEquipment | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'optionCode': value['optionCode'],
        'sortIndex': value['sortIndex'],
        'imageKey': value['imageKey'],
        'categoryName': value['categoryName'],
        'tagNames': value['tagNames'],
        'family': value['family'],
    };
}


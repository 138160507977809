/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MissingOptionCustomizationDTOExteriorColorCustomization } from './MissingOptionCustomizationDTOExteriorColorCustomization';
import {
    MissingOptionCustomizationDTOExteriorColorCustomizationFromJSON,
    MissingOptionCustomizationDTOExteriorColorCustomizationFromJSONTyped,
    MissingOptionCustomizationDTOExteriorColorCustomizationToJSON,
    MissingOptionCustomizationDTOExteriorColorCustomizationToJSONTyped,
} from './MissingOptionCustomizationDTOExteriorColorCustomization';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';

/**
 * 
 * @export
 * @interface MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization
 */
export interface MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization {
    /**
     * 
     * @type {string}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization
     */
    optionCode: string;
    /**
     * 
     * @type {SelectOptionDTOString}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization
     */
    modelGeneration: SelectOptionDTOString;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization
     */
    orderTypes: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<MissingOptionCustomizationDTOExteriorColorCustomization>}
     * @memberof MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization
     */
    similarCustomizations: Array<MissingOptionCustomizationDTOExteriorColorCustomization>;
}

/**
 * Check if a given object implements the MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization interface.
 */
export function instanceOfMissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization(value: object): value is MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization {
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelGeneration' in value) || value['modelGeneration'] === undefined) return false;
    if (!('orderTypes' in value) || value['orderTypes'] === undefined) return false;
    if (!('similarCustomizations' in value) || value['similarCustomizations'] === undefined) return false;
    return true;
}

export function MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomizationFromJSON(json: any): MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization {
    return MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomizationFromJSONTyped(json, false);
}

export function MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization {
    if (json == null) {
        return json;
    }
    return {
        
        'optionCode': json['optionCode'],
        'modelGeneration': SelectOptionDTOStringFromJSON(json['modelGeneration']),
        'orderTypes': ((json['orderTypes'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'similarCustomizations': ((json['similarCustomizations'] as Array<any>).map(MissingOptionCustomizationDTOExteriorColorCustomizationFromJSON)),
    };
}

export function MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomizationToJSON(json: any): MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization {
    return MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomizationToJSONTyped(json, false);
}

export function MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomizationToJSONTyped(value?: MissingOptionCustomizationDTOMissingOptionCustomizationDTOExteriorColorCustomization | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'optionCode': value['optionCode'],
        'modelGeneration': SelectOptionDTOStringToJSON(value['modelGeneration']),
        'orderTypes': ((value['orderTypes'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'similarCustomizations': ((value['similarCustomizations'] as Array<any>).map(MissingOptionCustomizationDTOExteriorColorCustomizationToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOInteger } from './SelectOptionDTOInteger';
import {
    SelectOptionDTOIntegerFromJSON,
    SelectOptionDTOIntegerFromJSONTyped,
    SelectOptionDTOIntegerToJSON,
    SelectOptionDTOIntegerToJSONTyped,
} from './SelectOptionDTOInteger';
import type { SelectOptionDTOInteriorMaterial } from './SelectOptionDTOInteriorMaterial';
import {
    SelectOptionDTOInteriorMaterialFromJSON,
    SelectOptionDTOInteriorMaterialFromJSONTyped,
    SelectOptionDTOInteriorMaterialToJSON,
    SelectOptionDTOInteriorMaterialToJSONTyped,
} from './SelectOptionDTOInteriorMaterial';
import type { SelectOptionDTOOptionClassDTO } from './SelectOptionDTOOptionClassDTO';
import {
    SelectOptionDTOOptionClassDTOFromJSON,
    SelectOptionDTOOptionClassDTOFromJSONTyped,
    SelectOptionDTOOptionClassDTOToJSON,
    SelectOptionDTOOptionClassDTOToJSONTyped,
} from './SelectOptionDTOOptionClassDTO';
import type { RelatedGroupedRelatedOptionDTOIntegerStringString } from './RelatedGroupedRelatedOptionDTOIntegerStringString';
import {
    RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSON,
    RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSONTyped,
    RelatedGroupedRelatedOptionDTOIntegerStringStringToJSON,
    RelatedGroupedRelatedOptionDTOIntegerStringStringToJSONTyped,
} from './RelatedGroupedRelatedOptionDTOIntegerStringString';
import type { SelectOptionDTOVehicleEquipmentCategory } from './SelectOptionDTOVehicleEquipmentCategory';
import {
    SelectOptionDTOVehicleEquipmentCategoryFromJSON,
    SelectOptionDTOVehicleEquipmentCategoryFromJSONTyped,
    SelectOptionDTOVehicleEquipmentCategoryToJSON,
    SelectOptionDTOVehicleEquipmentCategoryToJSONTyped,
} from './SelectOptionDTOVehicleEquipmentCategory';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';
import type { OptionGroupDTOString } from './OptionGroupDTOString';
import {
    OptionGroupDTOStringFromJSON,
    OptionGroupDTOStringFromJSONTyped,
    OptionGroupDTOStringToJSON,
    OptionGroupDTOStringToJSONTyped,
} from './OptionGroupDTOString';

/**
 * 
 * @export
 * @interface OptionFilterOptionsDTO
 */
export interface OptionFilterOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionFilterOptionsDTO
     */
    modelGenerations: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOInteger>}
     * @memberof OptionFilterOptionsDTO
     */
    modelYears: Array<SelectOptionDTOInteger>;
    /**
     * 
     * @type {Array<RelatedGroupedRelatedOptionDTOIntegerStringString>}
     * @memberof OptionFilterOptionsDTO
     */
    modelYearAndGenerationRelatedOrderTypes: Array<RelatedGroupedRelatedOptionDTOIntegerStringString>;
    /**
     * 
     * @type {Array<SelectOptionDTOOptionClassDTO>}
     * @memberof OptionFilterOptionsDTO
     */
    classes: Array<SelectOptionDTOOptionClassDTO>;
    /**
     * 
     * @type {Array<OptionGroupDTOString>}
     * @memberof OptionFilterOptionsDTO
     */
    categorizedEquipmentTags: Array<OptionGroupDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionFilterOptionsDTO
     */
    interiorColorTags: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionFilterOptionsDTO
     */
    exteriorColorTags: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionFilterOptionsDTO
     */
    roofColorTags: Array<SelectOptionDTOString>;
    /**
     * 
     * @type {Array<SelectOptionDTOVehicleEquipmentCategory>}
     * @memberof OptionFilterOptionsDTO
     */
    vehicleEquipmentCategories: Array<SelectOptionDTOVehicleEquipmentCategory>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionFilterOptionsDTO
     */
    equipmentFamilies: Array<string>;
    /**
     * 
     * @type {Array<SelectOptionDTOInteriorMaterial>}
     * @memberof OptionFilterOptionsDTO
     */
    interiorMaterials: Array<SelectOptionDTOInteriorMaterial>;
}

/**
 * Check if a given object implements the OptionFilterOptionsDTO interface.
 */
export function instanceOfOptionFilterOptionsDTO(value: object): value is OptionFilterOptionsDTO {
    if (!('modelGenerations' in value) || value['modelGenerations'] === undefined) return false;
    if (!('modelYears' in value) || value['modelYears'] === undefined) return false;
    if (!('modelYearAndGenerationRelatedOrderTypes' in value) || value['modelYearAndGenerationRelatedOrderTypes'] === undefined) return false;
    if (!('classes' in value) || value['classes'] === undefined) return false;
    if (!('categorizedEquipmentTags' in value) || value['categorizedEquipmentTags'] === undefined) return false;
    if (!('interiorColorTags' in value) || value['interiorColorTags'] === undefined) return false;
    if (!('exteriorColorTags' in value) || value['exteriorColorTags'] === undefined) return false;
    if (!('roofColorTags' in value) || value['roofColorTags'] === undefined) return false;
    if (!('vehicleEquipmentCategories' in value) || value['vehicleEquipmentCategories'] === undefined) return false;
    if (!('equipmentFamilies' in value) || value['equipmentFamilies'] === undefined) return false;
    if (!('interiorMaterials' in value) || value['interiorMaterials'] === undefined) return false;
    return true;
}

export function OptionFilterOptionsDTOFromJSON(json: any): OptionFilterOptionsDTO {
    return OptionFilterOptionsDTOFromJSONTyped(json, false);
}

export function OptionFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionFilterOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'modelGenerations': ((json['modelGenerations'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'modelYears': ((json['modelYears'] as Array<any>).map(SelectOptionDTOIntegerFromJSON)),
        'modelYearAndGenerationRelatedOrderTypes': ((json['modelYearAndGenerationRelatedOrderTypes'] as Array<any>).map(RelatedGroupedRelatedOptionDTOIntegerStringStringFromJSON)),
        'classes': ((json['classes'] as Array<any>).map(SelectOptionDTOOptionClassDTOFromJSON)),
        'categorizedEquipmentTags': ((json['categorizedEquipmentTags'] as Array<any>).map(OptionGroupDTOStringFromJSON)),
        'interiorColorTags': ((json['interiorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'exteriorColorTags': ((json['exteriorColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'roofColorTags': ((json['roofColorTags'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
        'vehicleEquipmentCategories': ((json['vehicleEquipmentCategories'] as Array<any>).map(SelectOptionDTOVehicleEquipmentCategoryFromJSON)),
        'equipmentFamilies': json['equipmentFamilies'],
        'interiorMaterials': ((json['interiorMaterials'] as Array<any>).map(SelectOptionDTOInteriorMaterialFromJSON)),
    };
}

export function OptionFilterOptionsDTOToJSON(json: any): OptionFilterOptionsDTO {
    return OptionFilterOptionsDTOToJSONTyped(json, false);
}

export function OptionFilterOptionsDTOToJSONTyped(value?: OptionFilterOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'modelGenerations': ((value['modelGenerations'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'modelYears': ((value['modelYears'] as Array<any>).map(SelectOptionDTOIntegerToJSON)),
        'modelYearAndGenerationRelatedOrderTypes': ((value['modelYearAndGenerationRelatedOrderTypes'] as Array<any>).map(RelatedGroupedRelatedOptionDTOIntegerStringStringToJSON)),
        'classes': ((value['classes'] as Array<any>).map(SelectOptionDTOOptionClassDTOToJSON)),
        'categorizedEquipmentTags': ((value['categorizedEquipmentTags'] as Array<any>).map(OptionGroupDTOStringToJSON)),
        'interiorColorTags': ((value['interiorColorTags'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'exteriorColorTags': ((value['exteriorColorTags'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'roofColorTags': ((value['roofColorTags'] as Array<any>).map(SelectOptionDTOStringToJSON)),
        'vehicleEquipmentCategories': ((value['vehicleEquipmentCategories'] as Array<any>).map(SelectOptionDTOVehicleEquipmentCategoryToJSON)),
        'equipmentFamilies': value['equipmentFamilies'],
        'interiorMaterials': ((value['interiorMaterials'] as Array<any>).map(SelectOptionDTOInteriorMaterialToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TechnicalDataSortField = {
    MODEL_YEAR: 'MODEL_YEAR'
} as const;
export type TechnicalDataSortField = typeof TechnicalDataSortField[keyof typeof TechnicalDataSortField];


export function instanceOfTechnicalDataSortField(value: any): boolean {
    for (const key in TechnicalDataSortField) {
        if (Object.prototype.hasOwnProperty.call(TechnicalDataSortField, key)) {
            if (TechnicalDataSortField[key as keyof typeof TechnicalDataSortField] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TechnicalDataSortFieldFromJSON(json: any): TechnicalDataSortField {
    return TechnicalDataSortFieldFromJSONTyped(json, false);
}

export function TechnicalDataSortFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalDataSortField {
    return json as TechnicalDataSortField;
}

export function TechnicalDataSortFieldToJSON(value?: TechnicalDataSortField | null): any {
    return value as any;
}

export function TechnicalDataSortFieldToJSONTyped(value: any, ignoreDiscriminator: boolean): TechnicalDataSortField {
    return value as TechnicalDataSortField;
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionLinkDTO } from './OptionLinkDTO';
import {
    OptionLinkDTOFromJSON,
    OptionLinkDTOFromJSONTyped,
    OptionLinkDTOToJSON,
    OptionLinkDTOToJSONTyped,
} from './OptionLinkDTO';

/**
 * 
 * @export
 * @interface OptionsInFamilyDTO
 */
export interface OptionsInFamilyDTO {
    /**
     * 
     * @type {Array<OptionLinkDTO>}
     * @memberof OptionsInFamilyDTO
     */
    optionsInFamily: Array<OptionLinkDTO>;
}

/**
 * Check if a given object implements the OptionsInFamilyDTO interface.
 */
export function instanceOfOptionsInFamilyDTO(value: object): value is OptionsInFamilyDTO {
    if (!('optionsInFamily' in value) || value['optionsInFamily'] === undefined) return false;
    return true;
}

export function OptionsInFamilyDTOFromJSON(json: any): OptionsInFamilyDTO {
    return OptionsInFamilyDTOFromJSONTyped(json, false);
}

export function OptionsInFamilyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionsInFamilyDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'optionsInFamily': ((json['optionsInFamily'] as Array<any>).map(OptionLinkDTOFromJSON)),
    };
}

export function OptionsInFamilyDTOToJSON(json: any): OptionsInFamilyDTO {
    return OptionsInFamilyDTOToJSONTyped(json, false);
}

export function OptionsInFamilyDTOToJSONTyped(value?: OptionsInFamilyDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'optionsInFamily': ((value['optionsInFamily'] as Array<any>).map(OptionLinkDTOToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptionTagItemDTOExteriorColor
 */
export interface OptionTagItemDTOExteriorColor {
    /**
     * 
     * @type {string}
     * @memberof OptionTagItemDTOExteriorColor
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OptionTagItemDTOExteriorColor
     */
    key: string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionTagItemDTOExteriorColor
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof OptionTagItemDTOExteriorColor
     */
    sortIndex: number;
    /**
     * 
     * @type {string}
     * @memberof OptionTagItemDTOExteriorColor
     */
    hexCode: string;
}

/**
 * Check if a given object implements the OptionTagItemDTOExteriorColor interface.
 */
export function instanceOfOptionTagItemDTOExteriorColor(value: object): value is OptionTagItemDTOExteriorColor {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('sortIndex' in value) || value['sortIndex'] === undefined) return false;
    if (!('hexCode' in value) || value['hexCode'] === undefined) return false;
    return true;
}

export function OptionTagItemDTOExteriorColorFromJSON(json: any): OptionTagItemDTOExteriorColor {
    return OptionTagItemDTOExteriorColorFromJSONTyped(json, false);
}

export function OptionTagItemDTOExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagItemDTOExteriorColor {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'key': json['key'],
        'active': json['active'],
        'sortIndex': json['sortIndex'],
        'hexCode': json['hexCode'],
    };
}

export function OptionTagItemDTOExteriorColorToJSON(json: any): OptionTagItemDTOExteriorColor {
    return OptionTagItemDTOExteriorColorToJSONTyped(json, false);
}

export function OptionTagItemDTOExteriorColorToJSONTyped(value?: OptionTagItemDTOExteriorColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'key': value['key'],
        'active': value['active'],
        'sortIndex': value['sortIndex'],
        'hexCode': value['hexCode'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SortableListItemDTO } from './SortableListItemDTO';
import {
    SortableListItemDTOFromJSON,
    SortableListItemDTOFromJSONTyped,
    SortableListItemDTOToJSON,
    SortableListItemDTOToJSONTyped,
} from './SortableListItemDTO';

/**
 * 
 * @export
 * @interface SortCategoryDTO
 */
export interface SortCategoryDTO {
    /**
     * 
     * @type {string}
     * @memberof SortCategoryDTO
     */
    label?: string;
    /**
     * 
     * @type {Array<SortableListItemDTO>}
     * @memberof SortCategoryDTO
     */
    items: Array<SortableListItemDTO>;
}

/**
 * Check if a given object implements the SortCategoryDTO interface.
 */
export function instanceOfSortCategoryDTO(value: object): value is SortCategoryDTO {
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function SortCategoryDTOFromJSON(json: any): SortCategoryDTO {
    return SortCategoryDTOFromJSONTyped(json, false);
}

export function SortCategoryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortCategoryDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'] == null ? undefined : json['label'],
        'items': ((json['items'] as Array<any>).map(SortableListItemDTOFromJSON)),
    };
}

export function SortCategoryDTOToJSON(json: any): SortCategoryDTO {
    return SortCategoryDTOToJSONTyped(json, false);
}

export function SortCategoryDTOToJSONTyped(value?: SortCategoryDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'label': value['label'],
        'items': ((value['items'] as Array<any>).map(SortableListItemDTOToJSON)),
    };
}


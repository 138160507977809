import { optionTagApi } from "../../../../pdsapi"
import { getProductDetailsContainer, ProductDetailsContainerConfig } from "../../../../viewtemplates/details/ProductDetailsContainer"
import { ProductDetailsCard } from "../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsLocalizationCard } from "../../../../viewtemplates/details/cards/localizationcard/DetailsLocalizationCard"
import { ProductDetailsTab } from "../../../../viewtemplates/details/tabs/ProductDetailsTab"
import { OptionTagDetailsAttributesCardContent } from "./data/attributes/OptionTagDetailsAttributesCardContent"
import { OptionTagKey } from "../OptionTagKey"
import { Route, useParams } from "react-router-dom"
import { routes } from "../../../../common/routes"
import { OptionTagTypeDTO } from "../../../../generated/pdsapi"
import { OptionTagProductListContainerConfig } from "../list/OptionTagListRoute"

export const OptionTagProductDetailsContainerConfig: ProductDetailsContainerConfig<OptionTagKey> = {
    getBaseInformation: optionTagApi.details.getBaseInformation,
    deleteByKey: optionTagApi.details.deleteByKey,
    tabsConfig: [
        {
            label: "Data",
            Content: (props) => (
                <ProductDetailsTab>
                    <ProductDetailsCard
                        {...props}
                        label={"Attributes"}
                        getData={optionTagApi.details.getAttributes}
                        updateData={({ key, type, update }) =>
                            optionTagApi.details.updateAttributes({
                                key,
                                type,
                                optionTagAttributesUpdateDTOOptionTag: update,
                            })
                        }
                        Content={OptionTagDetailsAttributesCardContent}
                    />
                </ProductDetailsTab>
            ),
        },
        {
            label: "Name",
            Content: (props) => (
                <ProductDetailsTab>
                    <DetailsLocalizationCard
                        {...props}
                        label={"Localizations"}
                        getData={optionTagApi.details.getNameLocalizations}
                        updateData={(update) =>
                            optionTagApi.details.updateName({
                                key: update.key,
                                type: update.type,
                                requestBody: update.update,
                            })
                        }
                    />
                </ProductDetailsTab>
            ),
        },
    ],
}

const OptionTagDetailsContainer = getProductDetailsContainer(OptionTagProductDetailsContainerConfig)

export const OptionTagDetailsRoute = (
    <Route
        path={routes.options.tags.details({ type: ":type" as OptionTagTypeDTO, key: ":key" })}
        Component={() => {
            const params = useParams<OptionTagKey>()

            return (
                <OptionTagDetailsContainer
                    itemKey={params}
                    parentCrumbs={[
                        {
                            name: OptionTagProductListContainerConfig.listName,
                            path: routes.options.tags.list(),
                        },
                    ]}
                />
            )
        }}
    />
)

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionLocalizationItemDTO } from './OptionLocalizationItemDTO';
import {
    OptionLocalizationItemDTOFromJSON,
    OptionLocalizationItemDTOFromJSONTyped,
    OptionLocalizationItemDTOToJSON,
    OptionLocalizationItemDTOToJSONTyped,
} from './OptionLocalizationItemDTO';

/**
 * 
 * @export
 * @interface OptionDTOBaseLocalization
 */
export interface OptionDTOBaseLocalization {
    /**
     * 
     * @type {OptionLocalizationItemDTO}
     * @memberof OptionDTOBaseLocalization
     */
    en: OptionLocalizationItemDTO;
    /**
     * 
     * @type {OptionLocalizationItemDTO}
     * @memberof OptionDTOBaseLocalization
     */
    de: OptionLocalizationItemDTO;
}

/**
 * Check if a given object implements the OptionDTOBaseLocalization interface.
 */
export function instanceOfOptionDTOBaseLocalization(value: object): value is OptionDTOBaseLocalization {
    if (!('en' in value) || value['en'] === undefined) return false;
    if (!('de' in value) || value['de'] === undefined) return false;
    return true;
}

export function OptionDTOBaseLocalizationFromJSON(json: any): OptionDTOBaseLocalization {
    return OptionDTOBaseLocalizationFromJSONTyped(json, false);
}

export function OptionDTOBaseLocalizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionDTOBaseLocalization {
    if (json == null) {
        return json;
    }
    return {
        
        'en': OptionLocalizationItemDTOFromJSON(json['en']),
        'de': OptionLocalizationItemDTOFromJSON(json['de']),
    };
}

export function OptionDTOBaseLocalizationToJSON(json: any): OptionDTOBaseLocalization {
    return OptionDTOBaseLocalizationToJSONTyped(json, false);
}

export function OptionDTOBaseLocalizationToJSONTyped(value?: OptionDTOBaseLocalization | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'en': OptionLocalizationItemDTOToJSON(value['en']),
        'de': OptionLocalizationItemDTOToJSON(value['de']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization } from './MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization';
import {
    MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSON,
    MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSONTyped,
    MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationToJSON,
    MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationToJSONTyped,
} from './MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization';

/**
 * 
 * @export
 * @interface PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization
 */
export interface PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization {
    /**
     * 
     * @type {number}
     * @memberof PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization>}
     * @memberof PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization
     */
    content: Array<MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization>;
}

/**
 * Check if a given object implements the PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization interface.
 */
export function instanceOfPageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization(value: object): value is PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization {
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSON(json: any): PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization {
    return PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSONTyped(json, false);
}

export function PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization {
    if (json == null) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationFromJSON)),
    };
}

export function PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationToJSON(json: any): PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization {
    return PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationToJSONTyped(json, false);
}

export function PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationToJSONTyped(value?: PageDTOMissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomization | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'totalItemCount': value['totalItemCount'],
        'content': ((value['content'] as Array<any>).map(MissingOptionCustomizationDTOMissingOptionCustomizationDTOInteriorCustomizationToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EngineType } from './EngineType';
import {
    EngineTypeFromJSON,
    EngineTypeFromJSONTyped,
    EngineTypeToJSON,
    EngineTypeToJSONTyped,
} from './EngineType';

/**
 * 
 * @export
 * @interface SelectOptionDTOEngineType
 */
export interface SelectOptionDTOEngineType {
    /**
     * 
     * @type {EngineType}
     * @memberof SelectOptionDTOEngineType
     */
    key: EngineType;
    /**
     * 
     * @type {string}
     * @memberof SelectOptionDTOEngineType
     */
    label: string;
}



/**
 * Check if a given object implements the SelectOptionDTOEngineType interface.
 */
export function instanceOfSelectOptionDTOEngineType(value: object): value is SelectOptionDTOEngineType {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function SelectOptionDTOEngineTypeFromJSON(json: any): SelectOptionDTOEngineType {
    return SelectOptionDTOEngineTypeFromJSONTyped(json, false);
}

export function SelectOptionDTOEngineTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectOptionDTOEngineType {
    if (json == null) {
        return json;
    }
    return {
        
        'key': EngineTypeFromJSON(json['key']),
        'label': json['label'],
    };
}

export function SelectOptionDTOEngineTypeToJSON(json: any): SelectOptionDTOEngineType {
    return SelectOptionDTOEngineTypeToJSONTyped(json, false);
}

export function SelectOptionDTOEngineTypeToJSONTyped(value?: SelectOptionDTOEngineType | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': EngineTypeToJSON(value['key']),
        'label': value['label'],
    };
}


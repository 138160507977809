/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptionTagItemDTOInteriorColor
 */
export interface OptionTagItemDTOInteriorColor {
    /**
     * 
     * @type {string}
     * @memberof OptionTagItemDTOInteriorColor
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OptionTagItemDTOInteriorColor
     */
    key: string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionTagItemDTOInteriorColor
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof OptionTagItemDTOInteriorColor
     */
    sortIndex: number;
    /**
     * 
     * @type {string}
     * @memberof OptionTagItemDTOInteriorColor
     */
    hexCode: string;
}

/**
 * Check if a given object implements the OptionTagItemDTOInteriorColor interface.
 */
export function instanceOfOptionTagItemDTOInteriorColor(value: object): value is OptionTagItemDTOInteriorColor {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('sortIndex' in value) || value['sortIndex'] === undefined) return false;
    if (!('hexCode' in value) || value['hexCode'] === undefined) return false;
    return true;
}

export function OptionTagItemDTOInteriorColorFromJSON(json: any): OptionTagItemDTOInteriorColor {
    return OptionTagItemDTOInteriorColorFromJSONTyped(json, false);
}

export function OptionTagItemDTOInteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagItemDTOInteriorColor {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'key': json['key'],
        'active': json['active'],
        'sortIndex': json['sortIndex'],
        'hexCode': json['hexCode'],
    };
}

export function OptionTagItemDTOInteriorColorToJSON(json: any): OptionTagItemDTOInteriorColor {
    return OptionTagItemDTOInteriorColorToJSONTyped(json, false);
}

export function OptionTagItemDTOInteriorColorToJSONTyped(value?: OptionTagItemDTOInteriorColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'key': value['key'],
        'active': value['active'],
        'sortIndex': value['sortIndex'],
        'hexCode': value['hexCode'],
    };
}


import { TableFilterProps } from "../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnGroupsWrapper } from "../../../../components/layouts/columngroupswrapper/ColumnGroupsWrapper"
import { ColumnGroupWrapper } from "../../../../components/layouts/columngroupwrapper/ColumnGroupWrapper"
import { TechnicalDataFilterOptionsDTO } from "../../../../generated/pdsapi"
import { TechnicalDataFilter } from "../../../../pdsapi"
import { FunctionComponent } from "react"
import { MultiSelectFilter, NumberMultiSelectFilter } from "../../../../components/filter/MultiSelectFilter"
import { GroupedMultiSelectFilter } from "../../../../components/filter/GroupedMultiSelectFilter"

export const TechnicalDataListFilter: FunctionComponent<TableFilterProps<TechnicalDataFilter, TechnicalDataFilterOptionsDTO>> = ({
    filter,
    setFilter,
    filterOptions,
}) => {
    const modelSeriesKeysFilter = (
        <MultiSelectFilter
            label={"Model Series"}
            options={filterOptions?.modelSeries}
            value={filter.modelSeriesKeys}
            onChange={(modelSeriesKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelSeriesKeys,
                    modelGenerationKeys: undefined,
                    orderTypeCodes: undefined,
                }))
            }
        />
    )

    const relevantModelGenerations = filter.modelSeriesKeys
        ? filterOptions?.modelSeriesRelatedModelGenerations.filter((modelSeriesGroup) => filter.modelSeriesKeys!.has(modelSeriesGroup.relatedKey))
        : filterOptions?.modelSeriesRelatedModelGenerations
    const modelGenerationKeysFilter = (
        <GroupedMultiSelectFilter
            label={"Model Generations"}
            options={relevantModelGenerations}
            value={filter.modelGenerationKeys}
            onChange={(modelGenerationKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    modelGenerationKeys,
                }))
            }
        />
    )

    const modelYearsFilter = (
        <NumberMultiSelectFilter
            label={"Model Years"}
            options={filterOptions?.modelYears}
            value={filter.modelYears}
            onChange={(modelYears) =>
                setFilter((filter) => ({
                    ...filter,
                    modelYears,
                    orderTypeCodes: undefined,
                }))
            }
        />
    )

    const relevantOrderTypes =
        filter.modelGenerationKeys || filter.modelYears
            ? filterOptions?.modelYearAndGenerationRelatedOrderTypes
                  .map((modelYearGroup) => ({
                      ...modelYearGroup,
                      options: modelYearGroup.options.filter((relatedOption) => filter.modelGenerationKeys?.has(relatedOption.relatedKey) ?? true),
                  }))
                  .filter((modelYearGroup) => modelYearGroup.options.length && (filter.modelYears?.has(modelYearGroup.relatedKey) ?? true))
            : filterOptions?.modelYearAndGenerationRelatedOrderTypes
    const orderTypesFilter = (
        <GroupedMultiSelectFilter
            label={"Order Types"}
            options={relevantOrderTypes}
            value={filter.orderTypeKeys}
            onChange={(orderTypeKeys) =>
                setFilter((filter) => ({
                    ...filter,
                    orderTypeKeys,
                }))
            }
        />
    )

    return (
        <ColumnGroupsWrapper flexWidth={"one-third"}>
            <ColumnGroupWrapper label={"Model"}>
                {modelSeriesKeysFilter}
                {modelGenerationKeysFilter}
            </ColumnGroupWrapper>
            <ColumnGroupWrapper label={"Order Type"}>
                {modelYearsFilter}
                {orderTypesFilter}
            </ColumnGroupWrapper>
        </ColumnGroupsWrapper>
    )
}

import { TableColumnsConfig } from "../../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../../components/layouts/table/Table"
import { ColorItemDTO, ColorSortField } from "../../../../../generated/pdsapi"
import { NamedColorTile } from "../../../../../components/tiles/namedcolortile/NamedColorTile"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { spacing } from "@porsche-design-system/utilities"

type ColorColumnBuilder = ColumnBuilder<ColorItemDTO, ColorSortField>

export const ColorListColumnsConfig: TableColumnsConfig<ColorItemDTO, ColorSortField> = ({ sorting, setSorting }) => {
    const colorCodeColumn: ColorColumnBuilder = {
        width: 60,
        headerCellProps: {
            content: "Code",
            sorting: {
                field: ColorSortField.COLOR_CODE,
                value: sorting,
                setValue: setSorting,
            },
        },
        buildCellContent: (color) => color.colorCode,
    }
    const nameColumn: ColorColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (color) => color.name,
    }
    const hexCodesColumn: ColorColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Hex Codes",
        },
        buildCellContent: (color) => (
            <SpacedItems direction={"row"} itemSpacing={spacing["16"]}>
                {color.hexCodes.map((hexCode) => (
                    <NamedColorTile hexCode={hexCode} label={hexCode} />
                ))}
            </SpacedItems>
        ),
    }

    return [colorCodeColumn, nameColumn, hexCodesColumn]
}

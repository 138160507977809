/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OrderTypeNameItemDTO,
  SimpleApiError,
  SimpleOrValidationApiError,
} from '../models/index';
import {
    OrderTypeNameItemDTOFromJSON,
    OrderTypeNameItemDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SimpleOrValidationApiErrorFromJSON,
    SimpleOrValidationApiErrorToJSON,
} from '../models/index';

export interface GetNameOfOrderTypeRequest {
    key: string;
}

export interface UpdateManualOfNameOfOrderTypeRequest {
    key: string;
    requestBody: { [key: string]: string; };
}

/**
 * 
 */
export class OrderTypeLocalizationDetailsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get name of OrderType with given key
     */
    async getNameOfOrderTypeRaw(requestParameters: GetNameOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: OrderTypeNameItemDTO; }>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getNameOfOrderType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/localization/name`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, OrderTypeNameItemDTOFromJSON));
    }

    /**
     * Get name of OrderType with given key
     */
    async getNameOfOrderType(requestParameters: GetNameOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: OrderTypeNameItemDTO; }> {
        const response = await this.getNameOfOrderTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates manualOfName of an existing OrderType
     */
    async updateManualOfNameOfOrderTypeRaw(requestParameters: UpdateManualOfNameOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateManualOfNameOfOrderType().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling updateManualOfNameOfOrderType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/web/order-types/details/{key}/localization/name/manual`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates manualOfName of an existing OrderType
     */
    async updateManualOfNameOfOrderType(requestParameters: UpdateManualOfNameOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateManualOfNameOfOrderTypeRaw(requestParameters, initOverrides);
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderTypeOptionItemDTO } from './OrderTypeOptionItemDTO';
import {
    OrderTypeOptionItemDTOFromJSON,
    OrderTypeOptionItemDTOFromJSONTyped,
    OrderTypeOptionItemDTOToJSON,
    OrderTypeOptionItemDTOToJSONTyped,
} from './OrderTypeOptionItemDTO';

/**
 * 
 * @export
 * @interface PageDTOOrderTypeOptionItemDTO
 */
export interface PageDTOOrderTypeOptionItemDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTOOrderTypeOptionItemDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<OrderTypeOptionItemDTO>}
     * @memberof PageDTOOrderTypeOptionItemDTO
     */
    content: Array<OrderTypeOptionItemDTO>;
}

/**
 * Check if a given object implements the PageDTOOrderTypeOptionItemDTO interface.
 */
export function instanceOfPageDTOOrderTypeOptionItemDTO(value: object): value is PageDTOOrderTypeOptionItemDTO {
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function PageDTOOrderTypeOptionItemDTOFromJSON(json: any): PageDTOOrderTypeOptionItemDTO {
    return PageDTOOrderTypeOptionItemDTOFromJSONTyped(json, false);
}

export function PageDTOOrderTypeOptionItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOOrderTypeOptionItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(OrderTypeOptionItemDTOFromJSON)),
    };
}

export function PageDTOOrderTypeOptionItemDTOToJSON(json: any): PageDTOOrderTypeOptionItemDTO {
    return PageDTOOrderTypeOptionItemDTOToJSONTyped(json, false);
}

export function PageDTOOrderTypeOptionItemDTOToJSONTyped(value?: PageDTOOrderTypeOptionItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'totalItemCount': value['totalItemCount'],
        'content': ((value['content'] as Array<any>).map(OrderTypeOptionItemDTOToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ColorTileDTO } from './ColorTileDTO';
import {
    ColorTileDTOFromJSON,
    ColorTileDTOFromJSONTyped,
    ColorTileDTOToJSON,
    ColorTileDTOToJSONTyped,
} from './ColorTileDTO';
import type { InteriorColorTileWithLabelDTO } from './InteriorColorTileWithLabelDTO';
import {
    InteriorColorTileWithLabelDTOFromJSON,
    InteriorColorTileWithLabelDTOFromJSONTyped,
    InteriorColorTileWithLabelDTOToJSON,
    InteriorColorTileWithLabelDTOToJSONTyped,
} from './InteriorColorTileWithLabelDTO';

/**
 * 
 * @export
 * @interface OptionItemDTOInterior
 */
export interface OptionItemDTOInterior {
    /**
     * 
     * @type {string}
     * @memberof OptionItemDTOInterior
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionItemDTOInterior
     */
    orderTypeKey: string;
    /**
     * 
     * @type {string}
     * @memberof OptionItemDTOInterior
     */
    optionCode: string;
    /**
     * 
     * @type {number}
     * @memberof OptionItemDTOInterior
     */
    modelYear: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionItemDTOInterior
     */
    orderTypeCodes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OptionItemDTOInterior
     */
    imageKey?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionItemDTOInterior
     */
    modelGenerationName?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionItemDTOInterior
     */
    materialName?: string;
    /**
     * 
     * @type {InteriorColorTileWithLabelDTO}
     * @memberof OptionItemDTOInterior
     */
    color?: InteriorColorTileWithLabelDTO;
    /**
     * 
     * @type {ColorTileDTO}
     * @memberof OptionItemDTOInterior
     */
    colorTag?: ColorTileDTO;
}

/**
 * Check if a given object implements the OptionItemDTOInterior interface.
 */
export function instanceOfOptionItemDTOInterior(value: object): value is OptionItemDTOInterior {
    if (!('orderTypeKey' in value) || value['orderTypeKey'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('modelYear' in value) || value['modelYear'] === undefined) return false;
    if (!('orderTypeCodes' in value) || value['orderTypeCodes'] === undefined) return false;
    return true;
}

export function OptionItemDTOInteriorFromJSON(json: any): OptionItemDTOInterior {
    return OptionItemDTOInteriorFromJSONTyped(json, false);
}

export function OptionItemDTOInteriorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionItemDTOInterior {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'orderTypeKey': json['orderTypeKey'],
        'optionCode': json['optionCode'],
        'modelYear': json['modelYear'],
        'orderTypeCodes': json['orderTypeCodes'],
        'imageKey': json['imageKey'] == null ? undefined : json['imageKey'],
        'modelGenerationName': json['modelGenerationName'] == null ? undefined : json['modelGenerationName'],
        'materialName': json['materialName'] == null ? undefined : json['materialName'],
        'color': json['color'] == null ? undefined : InteriorColorTileWithLabelDTOFromJSON(json['color']),
        'colorTag': json['colorTag'] == null ? undefined : ColorTileDTOFromJSON(json['colorTag']),
    };
}

export function OptionItemDTOInteriorToJSON(json: any): OptionItemDTOInterior {
    return OptionItemDTOInteriorToJSONTyped(json, false);
}

export function OptionItemDTOInteriorToJSONTyped(value?: OptionItemDTOInterior | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'orderTypeKey': value['orderTypeKey'],
        'optionCode': value['optionCode'],
        'modelYear': value['modelYear'],
        'orderTypeCodes': value['orderTypeCodes'],
        'imageKey': value['imageKey'],
        'modelGenerationName': value['modelGenerationName'],
        'materialName': value['materialName'],
        'color': InteriorColorTileWithLabelDTOToJSON(value['color']),
        'colorTag': ColorTileDTOToJSON(value['colorTag']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LinkedCustomizationDTOCustomizationValueInteriorColorTag } from './LinkedCustomizationDTOCustomizationValueInteriorColorTag';
import {
    LinkedCustomizationDTOCustomizationValueInteriorColorTagFromJSON,
    LinkedCustomizationDTOCustomizationValueInteriorColorTagFromJSONTyped,
    LinkedCustomizationDTOCustomizationValueInteriorColorTagToJSON,
    LinkedCustomizationDTOCustomizationValueInteriorColorTagToJSONTyped,
} from './LinkedCustomizationDTOCustomizationValueInteriorColorTag';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';
import type { InteriorColorTileDTO } from './InteriorColorTileDTO';
import {
    InteriorColorTileDTOFromJSON,
    InteriorColorTileDTOFromJSONTyped,
    InteriorColorTileDTOToJSON,
    InteriorColorTileDTOToJSONTyped,
} from './InteriorColorTileDTO';

/**
 * 
 * @export
 * @interface LinkedCustomizationDTOCustomizationValueInterior
 */
export interface LinkedCustomizationDTOCustomizationValueInterior {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof LinkedCustomizationDTOCustomizationValueInterior
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof LinkedCustomizationDTOCustomizationValueInterior
     */
    materialName?: string;
    /**
     * 
     * @type {InteriorColorTileDTO}
     * @memberof LinkedCustomizationDTOCustomizationValueInterior
     */
    color: InteriorColorTileDTO;
    /**
     * 
     * @type {LinkedCustomizationDTOCustomizationValueInteriorColorTag}
     * @memberof LinkedCustomizationDTOCustomizationValueInterior
     */
    colorTag?: LinkedCustomizationDTOCustomizationValueInteriorColorTag;
}

/**
 * Check if a given object implements the LinkedCustomizationDTOCustomizationValueInterior interface.
 */
export function instanceOfLinkedCustomizationDTOCustomizationValueInterior(value: object): value is LinkedCustomizationDTOCustomizationValueInterior {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('color' in value) || value['color'] === undefined) return false;
    return true;
}

export function LinkedCustomizationDTOCustomizationValueInteriorFromJSON(json: any): LinkedCustomizationDTOCustomizationValueInterior {
    return LinkedCustomizationDTOCustomizationValueInteriorFromJSONTyped(json, false);
}

export function LinkedCustomizationDTOCustomizationValueInteriorFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkedCustomizationDTOCustomizationValueInterior {
    if (json == null) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'materialName': json['materialName'] == null ? undefined : json['materialName'],
        'color': InteriorColorTileDTOFromJSON(json['color']),
        'colorTag': json['colorTag'] == null ? undefined : LinkedCustomizationDTOCustomizationValueInteriorColorTagFromJSON(json['colorTag']),
    };
}

export function LinkedCustomizationDTOCustomizationValueInteriorToJSON(json: any): LinkedCustomizationDTOCustomizationValueInterior {
    return LinkedCustomizationDTOCustomizationValueInteriorToJSONTyped(json, false);
}

export function LinkedCustomizationDTOCustomizationValueInteriorToJSONTyped(value?: LinkedCustomizationDTOCustomizationValueInterior | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': BaseNameDTOToJSON(value['name']),
        'materialName': value['materialName'],
        'color': InteriorColorTileDTOToJSON(value['color']),
        'colorTag': LinkedCustomizationDTOCustomizationValueInteriorColorTagToJSON(value['colorTag']),
    };
}


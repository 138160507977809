/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionalSelectDTOVehicleEquipmentCategory } from './OptionalSelectDTOVehicleEquipmentCategory';
import {
    OptionalSelectDTOVehicleEquipmentCategoryFromJSON,
    OptionalSelectDTOVehicleEquipmentCategoryFromJSONTyped,
    OptionalSelectDTOVehicleEquipmentCategoryToJSON,
    OptionalSelectDTOVehicleEquipmentCategoryToJSONTyped,
} from './OptionalSelectDTOVehicleEquipmentCategory';
import type { OptionDTOBaseLocalization } from './OptionDTOBaseLocalization';
import {
    OptionDTOBaseLocalizationFromJSON,
    OptionDTOBaseLocalizationFromJSONTyped,
    OptionDTOBaseLocalizationToJSON,
    OptionDTOBaseLocalizationToJSONTyped,
} from './OptionDTOBaseLocalization';

/**
 * 
 * @export
 * @interface OptionDTOTechnicalPackage
 */
export interface OptionDTOTechnicalPackage {
    /**
     * 
     * @type {OptionDTOBaseLocalization}
     * @memberof OptionDTOTechnicalPackage
     */
    name: OptionDTOBaseLocalization;
    /**
     * 
     * @type {string}
     * @memberof OptionDTOTechnicalPackage
     */
    optionCode: string;
    /**
     * 
     * @type {OptionDTOBaseLocalization}
     * @memberof OptionDTOTechnicalPackage
     */
    description: OptionDTOBaseLocalization;
    /**
     * 
     * @type {number}
     * @memberof OptionDTOTechnicalPackage
     */
    sortIndex: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionDTOTechnicalPackage
     */
    imageKeys: Array<string>;
    /**
     * 
     * @type {OptionalSelectDTOVehicleEquipmentCategory}
     * @memberof OptionDTOTechnicalPackage
     */
    category: OptionalSelectDTOVehicleEquipmentCategory;
    /**
     * 
     * @type {string}
     * @memberof OptionDTOTechnicalPackage
     */
    family?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionDTOTechnicalPackage
     */
    serialOptionType?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof OptionDTOTechnicalPackage
     */
    groupIdList: Array<number>;
}

/**
 * Check if a given object implements the OptionDTOTechnicalPackage interface.
 */
export function instanceOfOptionDTOTechnicalPackage(value: object): value is OptionDTOTechnicalPackage {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('sortIndex' in value) || value['sortIndex'] === undefined) return false;
    if (!('imageKeys' in value) || value['imageKeys'] === undefined) return false;
    if (!('category' in value) || value['category'] === undefined) return false;
    if (!('groupIdList' in value) || value['groupIdList'] === undefined) return false;
    return true;
}

export function OptionDTOTechnicalPackageFromJSON(json: any): OptionDTOTechnicalPackage {
    return OptionDTOTechnicalPackageFromJSONTyped(json, false);
}

export function OptionDTOTechnicalPackageFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionDTOTechnicalPackage {
    if (json == null) {
        return json;
    }
    return {
        
        'name': OptionDTOBaseLocalizationFromJSON(json['name']),
        'optionCode': json['optionCode'],
        'description': OptionDTOBaseLocalizationFromJSON(json['description']),
        'sortIndex': json['sortIndex'],
        'imageKeys': json['imageKeys'],
        'category': OptionalSelectDTOVehicleEquipmentCategoryFromJSON(json['category']),
        'family': json['family'] == null ? undefined : json['family'],
        'serialOptionType': json['serialOptionType'] == null ? undefined : json['serialOptionType'],
        'groupIdList': json['groupIdList'],
    };
}

export function OptionDTOTechnicalPackageToJSON(json: any): OptionDTOTechnicalPackage {
    return OptionDTOTechnicalPackageToJSONTyped(json, false);
}

export function OptionDTOTechnicalPackageToJSONTyped(value?: OptionDTOTechnicalPackage | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': OptionDTOBaseLocalizationToJSON(value['name']),
        'optionCode': value['optionCode'],
        'description': OptionDTOBaseLocalizationToJSON(value['description']),
        'sortIndex': value['sortIndex'],
        'imageKeys': value['imageKeys'],
        'category': OptionalSelectDTOVehicleEquipmentCategoryToJSON(value['category']),
        'family': value['family'],
        'serialOptionType': value['serialOptionType'],
        'groupIdList': value['groupIdList'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOEquipmentTagCategory } from './SelectOptionDTOEquipmentTagCategory';
import {
    SelectOptionDTOEquipmentTagCategoryFromJSON,
    SelectOptionDTOEquipmentTagCategoryFromJSONTyped,
    SelectOptionDTOEquipmentTagCategoryToJSON,
    SelectOptionDTOEquipmentTagCategoryToJSONTyped,
} from './SelectOptionDTOEquipmentTagCategory';
import type { SelectOptionDTOOptionTagTypeDTO } from './SelectOptionDTOOptionTagTypeDTO';
import {
    SelectOptionDTOOptionTagTypeDTOFromJSON,
    SelectOptionDTOOptionTagTypeDTOFromJSONTyped,
    SelectOptionDTOOptionTagTypeDTOToJSON,
    SelectOptionDTOOptionTagTypeDTOToJSONTyped,
} from './SelectOptionDTOOptionTagTypeDTO';

/**
 * 
 * @export
 * @interface OptionTagFilterOptionsDTO
 */
export interface OptionTagFilterOptionsDTO {
    /**
     * 
     * @type {Array<SelectOptionDTOOptionTagTypeDTO>}
     * @memberof OptionTagFilterOptionsDTO
     */
    types: Array<SelectOptionDTOOptionTagTypeDTO>;
    /**
     * 
     * @type {Array<SelectOptionDTOEquipmentTagCategory>}
     * @memberof OptionTagFilterOptionsDTO
     */
    equipmentTagCategories: Array<SelectOptionDTOEquipmentTagCategory>;
}

/**
 * Check if a given object implements the OptionTagFilterOptionsDTO interface.
 */
export function instanceOfOptionTagFilterOptionsDTO(value: object): value is OptionTagFilterOptionsDTO {
    if (!('types' in value) || value['types'] === undefined) return false;
    if (!('equipmentTagCategories' in value) || value['equipmentTagCategories'] === undefined) return false;
    return true;
}

export function OptionTagFilterOptionsDTOFromJSON(json: any): OptionTagFilterOptionsDTO {
    return OptionTagFilterOptionsDTOFromJSONTyped(json, false);
}

export function OptionTagFilterOptionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagFilterOptionsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'types': ((json['types'] as Array<any>).map(SelectOptionDTOOptionTagTypeDTOFromJSON)),
        'equipmentTagCategories': ((json['equipmentTagCategories'] as Array<any>).map(SelectOptionDTOEquipmentTagCategoryFromJSON)),
    };
}

export function OptionTagFilterOptionsDTOToJSON(json: any): OptionTagFilterOptionsDTO {
    return OptionTagFilterOptionsDTOToJSONTyped(json, false);
}

export function OptionTagFilterOptionsDTOToJSONTyped(value?: OptionTagFilterOptionsDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'types': ((value['types'] as Array<any>).map(SelectOptionDTOOptionTagTypeDTOToJSON)),
        'equipmentTagCategories': ((value['equipmentTagCategories'] as Array<any>).map(SelectOptionDTOEquipmentTagCategoryToJSON)),
    };
}


import { PIcon, PText } from "@porsche-design-system/components-react"
import { useMemo } from "react"
import { indexBy } from "lodash/fp"
import {
    MarketplaceDTO,
    OptionMarketplaceSpecificsIndexDTO,
    OptionMarketplaceSpecificsIndexDTOValues,
    OptionTypeDTO,
    PdsMarketplace,
} from "../../../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { usePdsBootContext } from "../../../../../../../../common/PdsBootContext"
import { ViewState } from "../../../../../../../../common/ViewState"
import { ColumnBuilder, Table } from "../../../../../../../../components/layouts/table/Table"
import { OrderTypeOptionKey } from "../../../../../OrderTypeOptionKey"

export type OptionMarketplaceSpecificsCardContentProps = ProductDetailsCardContentProps<
    OptionMarketplaceSpecificsIndexDTO,
    Record<string, never>,
    OrderTypeOptionKey
>

export const MarketplaceSpecificsCardContent = ({ formDisplayData, itemKey }: OptionMarketplaceSpecificsCardContentProps) => {
    const { supportedMarketplaces } = usePdsBootContext()
    const { data, kind } = formDisplayData

    const viewState = kind === "LOADING" ? ViewState.LOADING : ViewState.CONTENT
    const columns = useMemo(() => buildColumns(supportedMarketplaces, itemKey.type), [supportedMarketplaces])
    const rows = useMemo(() => (data ? buildRows(supportedMarketplaces, data) : []), [data, supportedMarketplaces])

    return <Table items={rows} viewState={viewState} columns={columns}></Table>
}

type Row = OptionMarketplaceSpecificsIndexDTOValues & { marketplace: PdsMarketplace | undefined }

const buildRows = (supportedMarketplaces: MarketplaceDTO[], { globalValues, marketplaceValues }: OptionMarketplaceSpecificsIndexDTO): Row[] => {
    const rows: Row[] = supportedMarketplaces
        .filter((marketplaceDTO) => marketplaceDTO.marketplace in marketplaceValues)
        .map((marketplaceDTO) => ({
            ...marketplaceValues[marketplaceDTO.marketplace],
            marketplace: marketplaceDTO.marketplace,
        }))
    if (globalValues) {
        rows.unshift({ ...globalValues, marketplace: undefined })
    }
    return rows
}

const buildColumns = (supportedMarketplaces: MarketplaceDTO[], optionType?: OptionTypeDTO): ColumnBuilder<Row, unknown>[] => {
    const marketplace = indexBy((marketplaceDTO) => marketplaceDTO.marketplace, supportedMarketplaces)

    const columns: ColumnBuilder<Row, unknown>[] = [
        {
            headerCellProps: { content: "Market" },
            grow: 5,
            buildCellContent: (row) => (row.marketplace ? (marketplace[row.marketplace]?.label ?? "") : "Global"),
        },
        {
            headerCellProps: { content: "Valid From" },
            grow: 2,
            buildCellContent: (row) => row.validFrom?.toLocaleDateString() ?? <PText color="contrast-medium">-</PText>,
        },
        {
            headerCellProps: { content: "Valid To" },
            grow: 2,
            buildCellContent: (row) => row.validTo?.toLocaleDateString() ?? <PText color="contrast-medium">-</PText>,
        },
        {
            headerCellProps: { content: "For Sale" },
            grow: 2,
            buildCellContent: (row) => (row.visibleForSale ? <PIcon name="check" /> : <PText color="contrast-medium">-</PText>),
        },
    ]

    switch (optionType) {
        case OptionTypeDTO.EQUIPMENT:
            columns.push({
                headerCellProps: { content: "Standard Equipment" },
                grow: 2,
                buildCellContent: (row) =>
                    !row.isStandardEquipment ? (
                        <PText color="contrast-medium">-</PText>
                    ) : row.isStandardEquipment.from && row.isStandardEquipment.to ? (
                        `${row.isStandardEquipment.from.toLocaleDateString()} - ${row.isStandardEquipment.to.toLocaleDateString()}`
                    ) : row.isStandardEquipment.from ? (
                        `since ${row.isStandardEquipment.from.toLocaleDateString()}`
                    ) : row.isStandardEquipment.to ? (
                        `until ${row.isStandardEquipment.to.toLocaleDateString()}`
                    ) : (
                        <PIcon name="check" />
                    ),
            })
            break
        case OptionTypeDTO.INTERIOR:
        case OptionTypeDTO.EXTERIOR_COLOR:
        case OptionTypeDTO.PAINT_TO_SAMPLE_EXTERIOR_COLOR:
        case OptionTypeDTO.ROOF_COLOR:
    }

    return columns
}

import { propertiesOf } from "../../../../../../common/property"
import { FormElementWrapper } from "../../../../../../components/formelements/FormElementWrapper"
import { getColorPickerFormElement } from "../../../../../../components/formelements/colorpickerformelement/ColorPickerFormElement"
import { toOption } from "../../../../../../components/formelements/selecttextformelement/Option"
import { getSelectTextFormElement } from "../../../../../../components/formelements/selecttextformelement/SelectTextFormElement"
import { getTextFormElement } from "../../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../../components/layouts/spaceditems/SpacedItems"
import {
    CustomExteriorColorAttributesDTO,
    CustomExteriorColorAttributesUpdateDTO,
    CustomExteriorColorCreateRequestDTO,
} from "../../../../../../generated/pdsapi"
import { ProductDetailsCardContentProps } from "../../../../../../viewtemplates/details/cards/ProductDetailsCard"
import { DetailsDefaultLocalizationsFormRow } from "../../../../../../viewtemplates/details/cards/components/defaultlocalizationsformrow/DetailsDefaultLocalizationsFormRow"
import { FC } from "react"
import { getTagsFormElement } from "../../../../../../components/formelements/tagsformelement/TagsFormElement"
import { ReadonlyFormElementWrapper } from "../../../../../../components/formelements/ReadonlyFormElementWrapper"
import { toTagOption } from "../../../../../../components/formelements/tagformelement/TagOption"
import { routes } from "../../../../../../common/routes"

export const CustomExteriorColorDetailsAttributesCardContent: FC<
    ProductDetailsCardContentProps<CustomExteriorColorAttributesDTO, CustomExteriorColorAttributesUpdateDTO>
> = ({ formDisplayData }) => {
    const properties = propertiesOf<CustomExteriorColorAttributesUpdateDTO>()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <FormElementWrapper
                    label={"Code"}
                    formDisplayData={formDisplayData}
                    field={properties.key}
                    FormElement={getTextFormElement<CustomExteriorColorAttributesDTO, CustomExteriorColorAttributesUpdateDTO>()}
                    getViewProps={(data) => ({
                        value: data.key,
                    })}
                    getEditProps={(data) => ({
                        value: data.key,
                        readOnly: true,
                    })}
                />
                <ReadonlyFormElementWrapper
                    label={"Linked Option Colors"}
                    formDisplayData={formDisplayData}
                    FormElement={getTagsFormElement<unknown, CustomExteriorColorCreateRequestDTO>()}
                    getViewProps={(data) => ({
                        emptyLabel: "-",
                        tags: data.linkedColors.map((linkedColor) => toTagOption(linkedColor, routes.options.colors.details)),
                    })}
                />
            </RowGroupWrapper>

            <DetailsDefaultLocalizationsFormRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getViewProps={(data) => ({
                    value: data.name,
                })}
                getEditProps={(data) => ({
                    value: data.name,
                })}
            />

            <RowGroupWrapper label={"Color"}>
                <FormElementWrapper
                    label={"Hex Code"}
                    formDisplayData={formDisplayData}
                    field={properties.hexCode}
                    FormElement={getColorPickerFormElement<CustomExteriorColorAttributesDTO, CustomExteriorColorCreateRequestDTO>()}
                    getViewProps={(data) => ({
                        hexValue: data.hexCode,
                    })}
                    getEditProps={(data) => ({
                        hexValue: data.hexCode,
                    })}
                />
                <FormElementWrapper
                    label={"Exterior color tag"}
                    formDisplayData={formDisplayData}
                    field={properties.exteriorColorTagKey}
                    FormElement={getSelectTextFormElement<unknown, CustomExteriorColorCreateRequestDTO>()}
                    getViewProps={(data) => ({
                        text: data.exteriorColorTag.value?.label ?? "-",
                    })}
                    getEditProps={(data) => ({
                        options: [{ value: "", label: "-" }, ...data.exteriorColorTag.options.map(toOption)],
                        value: data.exteriorColorTag.value?.key ?? "",
                        optional: true,
                    })}
                />
                <FormElementWrapper
                    label={"Exterior color type"}
                    formDisplayData={formDisplayData}
                    field={properties.exteriorColorType}
                    FormElement={getSelectTextFormElement<unknown, CustomExteriorColorCreateRequestDTO>()}
                    getViewProps={(data) => ({
                        text: data.exteriorColorType.value?.label ?? "-",
                    })}
                    getEditProps={(data) => ({
                        options: [{ value: "", label: "-" }, ...data.exteriorColorType.options.map(toOption)],
                        value: data.exteriorColorType.value?.key ?? "",
                        optional: true,
                    })}
                />
            </RowGroupWrapper>
        </SpacedItems>
    )
}

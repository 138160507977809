/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DerivativeCategoryItemDTO } from './DerivativeCategoryItemDTO';
import {
    DerivativeCategoryItemDTOFromJSON,
    DerivativeCategoryItemDTOFromJSONTyped,
    DerivativeCategoryItemDTOToJSON,
    DerivativeCategoryItemDTOToJSONTyped,
} from './DerivativeCategoryItemDTO';

/**
 * 
 * @export
 * @interface PageDTODerivativeCategoryItemDTO
 */
export interface PageDTODerivativeCategoryItemDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTODerivativeCategoryItemDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<DerivativeCategoryItemDTO>}
     * @memberof PageDTODerivativeCategoryItemDTO
     */
    content: Array<DerivativeCategoryItemDTO>;
}

/**
 * Check if a given object implements the PageDTODerivativeCategoryItemDTO interface.
 */
export function instanceOfPageDTODerivativeCategoryItemDTO(value: object): value is PageDTODerivativeCategoryItemDTO {
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function PageDTODerivativeCategoryItemDTOFromJSON(json: any): PageDTODerivativeCategoryItemDTO {
    return PageDTODerivativeCategoryItemDTOFromJSONTyped(json, false);
}

export function PageDTODerivativeCategoryItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTODerivativeCategoryItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(DerivativeCategoryItemDTOFromJSON)),
    };
}

export function PageDTODerivativeCategoryItemDTOToJSON(json: any): PageDTODerivativeCategoryItemDTO {
    return PageDTODerivativeCategoryItemDTOToJSONTyped(json, false);
}

export function PageDTODerivativeCategoryItemDTOToJSONTyped(value?: PageDTODerivativeCategoryItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'totalItemCount': value['totalItemCount'],
        'content': ((value['content'] as Array<any>).map(DerivativeCategoryItemDTOToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ExteriorColorType = {
    UNI: 'UNI',
    METALLIC: 'METALLIC',
    SPECIAL: 'SPECIAL'
} as const;
export type ExteriorColorType = typeof ExteriorColorType[keyof typeof ExteriorColorType];


export function instanceOfExteriorColorType(value: any): boolean {
    for (const key in ExteriorColorType) {
        if (Object.prototype.hasOwnProperty.call(ExteriorColorType, key)) {
            if (ExteriorColorType[key as keyof typeof ExteriorColorType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ExteriorColorTypeFromJSON(json: any): ExteriorColorType {
    return ExteriorColorTypeFromJSONTyped(json, false);
}

export function ExteriorColorTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExteriorColorType {
    return json as ExteriorColorType;
}

export function ExteriorColorTypeToJSON(value?: ExteriorColorType | null): any {
    return value as any;
}

export function ExteriorColorTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): ExteriorColorType {
    return value as ExteriorColorType;
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionTypeDTO } from './OptionTypeDTO';
import {
    OptionTypeDTOFromJSON,
    OptionTypeDTOFromJSONTyped,
    OptionTypeDTOToJSON,
    OptionTypeDTOToJSONTyped,
} from './OptionTypeDTO';

/**
 * 
 * @export
 * @interface OptionKeyDTOOptionTypeDTO
 */
export interface OptionKeyDTOOptionTypeDTO {
    /**
     * 
     * @type {string}
     * @memberof OptionKeyDTOOptionTypeDTO
     */
    orderTypeKey: string;
    /**
     * 
     * @type {string}
     * @memberof OptionKeyDTOOptionTypeDTO
     */
    optionCode: string;
    /**
     * 
     * @type {OptionTypeDTO}
     * @memberof OptionKeyDTOOptionTypeDTO
     */
    type: OptionTypeDTO;
}



/**
 * Check if a given object implements the OptionKeyDTOOptionTypeDTO interface.
 */
export function instanceOfOptionKeyDTOOptionTypeDTO(value: object): value is OptionKeyDTOOptionTypeDTO {
    if (!('orderTypeKey' in value) || value['orderTypeKey'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function OptionKeyDTOOptionTypeDTOFromJSON(json: any): OptionKeyDTOOptionTypeDTO {
    return OptionKeyDTOOptionTypeDTOFromJSONTyped(json, false);
}

export function OptionKeyDTOOptionTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionKeyDTOOptionTypeDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'orderTypeKey': json['orderTypeKey'],
        'optionCode': json['optionCode'],
        'type': OptionTypeDTOFromJSON(json['type']),
    };
}

export function OptionKeyDTOOptionTypeDTOToJSON(json: any): OptionKeyDTOOptionTypeDTO {
    return OptionKeyDTOOptionTypeDTOToJSONTyped(json, false);
}

export function OptionKeyDTOOptionTypeDTOToJSONTyped(value?: OptionKeyDTOOptionTypeDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'orderTypeKey': value['orderTypeKey'],
        'optionCode': value['optionCode'],
        'type': OptionTypeDTOToJSON(value['type']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderTypeMediaRelatedDataDTO
 */
export interface OrderTypeMediaRelatedDataDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeMediaRelatedDataDTO
     */
    possibleOptionCodes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderTypeMediaRelatedDataDTO
     */
    mediaBasePath: string;
}

/**
 * Check if a given object implements the OrderTypeMediaRelatedDataDTO interface.
 */
export function instanceOfOrderTypeMediaRelatedDataDTO(value: object): value is OrderTypeMediaRelatedDataDTO {
    if (!('possibleOptionCodes' in value) || value['possibleOptionCodes'] === undefined) return false;
    if (!('mediaBasePath' in value) || value['mediaBasePath'] === undefined) return false;
    return true;
}

export function OrderTypeMediaRelatedDataDTOFromJSON(json: any): OrderTypeMediaRelatedDataDTO {
    return OrderTypeMediaRelatedDataDTOFromJSONTyped(json, false);
}

export function OrderTypeMediaRelatedDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeMediaRelatedDataDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'possibleOptionCodes': json['possibleOptionCodes'],
        'mediaBasePath': json['mediaBasePath'],
    };
}

export function OrderTypeMediaRelatedDataDTOToJSON(json: any): OrderTypeMediaRelatedDataDTO {
    return OrderTypeMediaRelatedDataDTOToJSONTyped(json, false);
}

export function OrderTypeMediaRelatedDataDTOToJSONTyped(value?: OrderTypeMediaRelatedDataDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'possibleOptionCodes': value['possibleOptionCodes'],
        'mediaBasePath': value['mediaBasePath'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderTypeMediaDTOEquipmentRelationDTO } from './OrderTypeMediaDTOEquipmentRelationDTO';
import {
    OrderTypeMediaDTOEquipmentRelationDTOFromJSON,
    OrderTypeMediaDTOEquipmentRelationDTOFromJSONTyped,
    OrderTypeMediaDTOEquipmentRelationDTOToJSON,
    OrderTypeMediaDTOEquipmentRelationDTOToJSONTyped,
} from './OrderTypeMediaDTOEquipmentRelationDTO';

/**
 * 
 * @export
 * @interface OrderTypeMediaDTO
 */
export interface OrderTypeMediaDTO {
    /**
     * 
     * @type {Array<OrderTypeMediaDTOEquipmentRelationDTO>}
     * @memberof OrderTypeMediaDTO
     */
    equipmentRelations: Array<OrderTypeMediaDTOEquipmentRelationDTO>;
}

/**
 * Check if a given object implements the OrderTypeMediaDTO interface.
 */
export function instanceOfOrderTypeMediaDTO(value: object): value is OrderTypeMediaDTO {
    if (!('equipmentRelations' in value) || value['equipmentRelations'] === undefined) return false;
    return true;
}

export function OrderTypeMediaDTOFromJSON(json: any): OrderTypeMediaDTO {
    return OrderTypeMediaDTOFromJSONTyped(json, false);
}

export function OrderTypeMediaDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeMediaDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'equipmentRelations': ((json['equipmentRelations'] as Array<any>).map(OrderTypeMediaDTOEquipmentRelationDTOFromJSON)),
    };
}

export function OrderTypeMediaDTOToJSON(json: any): OrderTypeMediaDTO {
    return OrderTypeMediaDTOToJSONTyped(json, false);
}

export function OrderTypeMediaDTOToJSONTyped(value?: OrderTypeMediaDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'equipmentRelations': ((value['equipmentRelations'] as Array<any>).map(OrderTypeMediaDTOEquipmentRelationDTOToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ExteriorColorGroup = {
    CONTRASTS: 'CONTRASTS',
    SHADES: 'SHADES',
    DREAMS: 'DREAMS',
    LEGENDS: 'LEGENDS'
} as const;
export type ExteriorColorGroup = typeof ExteriorColorGroup[keyof typeof ExteriorColorGroup];


export function instanceOfExteriorColorGroup(value: any): boolean {
    for (const key in ExteriorColorGroup) {
        if (Object.prototype.hasOwnProperty.call(ExteriorColorGroup, key)) {
            if (ExteriorColorGroup[key as keyof typeof ExteriorColorGroup] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ExteriorColorGroupFromJSON(json: any): ExteriorColorGroup {
    return ExteriorColorGroupFromJSONTyped(json, false);
}

export function ExteriorColorGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExteriorColorGroup {
    return json as ExteriorColorGroup;
}

export function ExteriorColorGroupToJSON(value?: ExteriorColorGroup | null): any {
    return value as any;
}

export function ExteriorColorGroupToJSONTyped(value: any, ignoreDiscriminator: boolean): ExteriorColorGroup {
    return value as ExteriorColorGroup;
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectDTOEquipmentTagCategory } from './SelectDTOEquipmentTagCategory';
import {
    SelectDTOEquipmentTagCategoryFromJSON,
    SelectDTOEquipmentTagCategoryFromJSONTyped,
    SelectDTOEquipmentTagCategoryToJSON,
    SelectDTOEquipmentTagCategoryToJSONTyped,
} from './SelectDTOEquipmentTagCategory';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';

/**
 * 
 * @export
 * @interface OptionTagAttributesDTOEquipment
 */
export interface OptionTagAttributesDTOEquipment {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionTagAttributesDTOEquipment
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {string}
     * @memberof OptionTagAttributesDTOEquipment
     */
    key: string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionTagAttributesDTOEquipment
     */
    active: boolean;
    /**
     * 
     * @type {SelectDTOEquipmentTagCategory}
     * @memberof OptionTagAttributesDTOEquipment
     */
    category: SelectDTOEquipmentTagCategory;
}

/**
 * Check if a given object implements the OptionTagAttributesDTOEquipment interface.
 */
export function instanceOfOptionTagAttributesDTOEquipment(value: object): value is OptionTagAttributesDTOEquipment {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('category' in value) || value['category'] === undefined) return false;
    return true;
}

export function OptionTagAttributesDTOEquipmentFromJSON(json: any): OptionTagAttributesDTOEquipment {
    return OptionTagAttributesDTOEquipmentFromJSONTyped(json, false);
}

export function OptionTagAttributesDTOEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagAttributesDTOEquipment {
    if (json == null) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'key': json['key'],
        'active': json['active'],
        'category': SelectDTOEquipmentTagCategoryFromJSON(json['category']),
    };
}

export function OptionTagAttributesDTOEquipmentToJSON(json: any): OptionTagAttributesDTOEquipment {
    return OptionTagAttributesDTOEquipmentToJSONTyped(json, false);
}

export function OptionTagAttributesDTOEquipmentToJSONTyped(value?: OptionTagAttributesDTOEquipment | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': BaseNameDTOToJSON(value['name']),
        'key': value['key'],
        'active': value['active'],
        'category': SelectDTOEquipmentTagCategoryToJSON(value['category']),
    };
}


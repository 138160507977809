/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BootInfoDTO,
} from '../models/index';
import {
    BootInfoDTOFromJSON,
    BootInfoDTOToJSON,
} from '../models/index';

/**
 * 
 */
export class BootControllerApi extends runtime.BaseAPI {

    /**
     * initiates start up phase
     */
    async bootRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BootInfoDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/boot`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BootInfoDTOFromJSON(jsonValue));
    }

    /**
     * initiates start up phase
     */
    async boot(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BootInfoDTO> {
        const response = await this.bootRaw(initOverrides);
        return await response.value();
    }

}

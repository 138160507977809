/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptionTagItemDTORoofColor
 */
export interface OptionTagItemDTORoofColor {
    /**
     * 
     * @type {string}
     * @memberof OptionTagItemDTORoofColor
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OptionTagItemDTORoofColor
     */
    key: string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionTagItemDTORoofColor
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof OptionTagItemDTORoofColor
     */
    sortIndex: number;
    /**
     * 
     * @type {string}
     * @memberof OptionTagItemDTORoofColor
     */
    hexCode: string;
}

/**
 * Check if a given object implements the OptionTagItemDTORoofColor interface.
 */
export function instanceOfOptionTagItemDTORoofColor(value: object): value is OptionTagItemDTORoofColor {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('sortIndex' in value) || value['sortIndex'] === undefined) return false;
    if (!('hexCode' in value) || value['hexCode'] === undefined) return false;
    return true;
}

export function OptionTagItemDTORoofColorFromJSON(json: any): OptionTagItemDTORoofColor {
    return OptionTagItemDTORoofColorFromJSONTyped(json, false);
}

export function OptionTagItemDTORoofColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionTagItemDTORoofColor {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'key': json['key'],
        'active': json['active'],
        'sortIndex': json['sortIndex'],
        'hexCode': json['hexCode'],
    };
}

export function OptionTagItemDTORoofColorToJSON(json: any): OptionTagItemDTORoofColor {
    return OptionTagItemDTORoofColorToJSONTyped(json, false);
}

export function OptionTagItemDTORoofColorToJSONTyped(value?: OptionTagItemDTORoofColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'key': value['key'],
        'active': value['active'],
        'sortIndex': value['sortIndex'],
        'hexCode': value['hexCode'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomExteriorColorFilterOptionsDTO,
  ExteriorColorType,
  PageDTOCustomExteriorColorItemDTO,
} from '../models/index';
import {
    CustomExteriorColorFilterOptionsDTOFromJSON,
    CustomExteriorColorFilterOptionsDTOToJSON,
    ExteriorColorTypeFromJSON,
    ExteriorColorTypeToJSON,
    PageDTOCustomExteriorColorItemDTOFromJSON,
    PageDTOCustomExteriorColorItemDTOToJSON,
} from '../models/index';

export interface GetCustomExteriorColorFilterOptionsRequest {
    languageTag: string;
}

export interface GetCustomExteriorColorsPageRequest {
    languageTag: string;
    offset: number;
    size: number;
    search?: string;
    exteriorColorTypes?: Set<ExteriorColorType>;
    exteriorColorTagKeys?: Set<string>;
}

/**
 * 
 */
export class CustomExteriorColorListWebControllerApi extends runtime.BaseAPI {

    /**
     * Get options to filter CustomExteriorColors
     */
    async getCustomExteriorColorFilterOptionsRaw(requestParameters: GetCustomExteriorColorFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomExteriorColorFilterOptionsDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getCustomExteriorColorFilterOptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-exterior-colors/list/filter-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomExteriorColorFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get options to filter CustomExteriorColors
     */
    async getCustomExteriorColorFilterOptions(requestParameters: GetCustomExteriorColorFilterOptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomExteriorColorFilterOptionsDTO> {
        const response = await this.getCustomExteriorColorFilterOptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get page of CustomExteriorColors
     */
    async getCustomExteriorColorsPageRaw(requestParameters: GetCustomExteriorColorsPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOCustomExteriorColorItemDTO>> {
        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getCustomExteriorColorsPage().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getCustomExteriorColorsPage().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getCustomExteriorColorsPage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['exteriorColorTypes'] != null) {
            queryParameters['exteriorColorTypes'] = requestParameters['exteriorColorTypes'];
        }

        if (requestParameters['exteriorColorTagKeys'] != null) {
            queryParameters['exteriorColorTagKeys'] = requestParameters['exteriorColorTagKeys'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/custom-exterior-colors/list/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOCustomExteriorColorItemDTOFromJSON(jsonValue));
    }

    /**
     * Get page of CustomExteriorColors
     */
    async getCustomExteriorColorsPage(requestParameters: GetCustomExteriorColorsPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOCustomExteriorColorItemDTO> {
        const response = await this.getCustomExteriorColorsPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

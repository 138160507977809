/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationAttributesDTORoofColorValue } from './OptionCustomizationAttributesDTORoofColorValue';
import {
    OptionCustomizationAttributesDTORoofColorValueFromJSON,
    OptionCustomizationAttributesDTORoofColorValueFromJSONTyped,
    OptionCustomizationAttributesDTORoofColorValueToJSON,
    OptionCustomizationAttributesDTORoofColorValueToJSONTyped,
} from './OptionCustomizationAttributesDTORoofColorValue';
import type { IntRange } from './IntRange';
import {
    IntRangeFromJSON,
    IntRangeFromJSONTyped,
    IntRangeToJSON,
    IntRangeToJSONTyped,
} from './IntRange';

/**
 * 
 * @export
 * @interface OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue
 */
export interface OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue {
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue
     */
    key: string;
    /**
     * 
     * @type {IntRange}
     * @memberof OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue
     */
    modelYearRange?: IntRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue
     */
    derivativeKeys?: Array<string>;
    /**
     * 
     * @type {OptionCustomizationAttributesDTORoofColorValue}
     * @memberof OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue
     */
    value: OptionCustomizationAttributesDTORoofColorValue;
}

/**
 * Check if a given object implements the OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue interface.
 */
export function instanceOfOptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue(value: object): value is OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue {
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValueFromJSON(json: any): OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue {
    return OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValueFromJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
        'modelYearRange': json['modelYearRange'] == null ? undefined : IntRangeFromJSON(json['modelYearRange']),
        'derivativeKeys': json['derivativeKeys'] == null ? undefined : json['derivativeKeys'],
        'value': OptionCustomizationAttributesDTORoofColorValueFromJSON(json['value']),
    };
}

export function OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValueToJSON(json: any): OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue {
    return OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValueToJSONTyped(json, false);
}

export function OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValueToJSONTyped(value?: OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTORoofColorValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'key': value['key'],
        'modelYearRange': IntRangeToJSON(value['modelYearRange']),
        'derivativeKeys': value['derivativeKeys'],
        'value': OptionCustomizationAttributesDTORoofColorValueToJSON(value['value']),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InteriorMaterial,
  OptionClassDTO,
  OptionSortField,
  OrderTypeOptionFilterOptionsDTO,
  PageDTOOrderTypeOptionItemDTO,
  SimpleApiError,
  SortDirection,
  VehicleEquipmentCategory,
} from '../models/index';
import {
    InteriorMaterialFromJSON,
    InteriorMaterialToJSON,
    OptionClassDTOFromJSON,
    OptionClassDTOToJSON,
    OptionSortFieldFromJSON,
    OptionSortFieldToJSON,
    OrderTypeOptionFilterOptionsDTOFromJSON,
    OrderTypeOptionFilterOptionsDTOToJSON,
    PageDTOOrderTypeOptionItemDTOFromJSON,
    PageDTOOrderTypeOptionItemDTOToJSON,
    SimpleApiErrorFromJSON,
    SimpleApiErrorToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
    VehicleEquipmentCategoryFromJSON,
    VehicleEquipmentCategoryToJSON,
} from '../models/index';

export interface GetAllOfOrderTypeRequest {
    key: string;
    languageTag: string;
    offset: number;
    size: number;
    sortField: OptionSortField;
    sortDirection: SortDirection;
    search?: string;
    classes?: Set<OptionClassDTO>;
    equipmentTagKeys?: Set<string>;
    interiorColorTagKeys?: Set<string>;
    exteriorColorTagKeys?: Set<string>;
    roofColorTagKeys?: Set<string>;
    vehicleEquipmentCategories?: Set<VehicleEquipmentCategory>;
    equipmentFamilies?: Set<string>;
    interiorMaterials?: Set<InteriorMaterial>;
}

export interface GetFilterOptionsOfAllOfOrderTypeRequest {
    key: string;
    languageTag: string;
}

/**
 * 
 */
export class OrderTypeOptionsWebControllerApi extends runtime.BaseAPI {

    /**
     * Get all of OrderType with given key
     */
    async getAllOfOrderTypeRaw(requestParameters: GetAllOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDTOOrderTypeOptionItemDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getAllOfOrderType().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getAllOfOrderType().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getAllOfOrderType().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getAllOfOrderType().'
            );
        }

        if (requestParameters['sortField'] == null) {
            throw new runtime.RequiredError(
                'sortField',
                'Required parameter "sortField" was null or undefined when calling getAllOfOrderType().'
            );
        }

        if (requestParameters['sortDirection'] == null) {
            throw new runtime.RequiredError(
                'sortDirection',
                'Required parameter "sortDirection" was null or undefined when calling getAllOfOrderType().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        if (requestParameters['sortField'] != null) {
            queryParameters['sortField'] = requestParameters['sortField'];
        }

        if (requestParameters['sortDirection'] != null) {
            queryParameters['sortDirection'] = requestParameters['sortDirection'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['classes'] != null) {
            queryParameters['classes'] = requestParameters['classes'];
        }

        if (requestParameters['equipmentTagKeys'] != null) {
            queryParameters['equipmentTagKeys'] = requestParameters['equipmentTagKeys'];
        }

        if (requestParameters['interiorColorTagKeys'] != null) {
            queryParameters['interiorColorTagKeys'] = requestParameters['interiorColorTagKeys'];
        }

        if (requestParameters['exteriorColorTagKeys'] != null) {
            queryParameters['exteriorColorTagKeys'] = requestParameters['exteriorColorTagKeys'];
        }

        if (requestParameters['roofColorTagKeys'] != null) {
            queryParameters['roofColorTagKeys'] = requestParameters['roofColorTagKeys'];
        }

        if (requestParameters['vehicleEquipmentCategories'] != null) {
            queryParameters['vehicleEquipmentCategories'] = requestParameters['vehicleEquipmentCategories'];
        }

        if (requestParameters['equipmentFamilies'] != null) {
            queryParameters['equipmentFamilies'] = requestParameters['equipmentFamilies'];
        }

        if (requestParameters['interiorMaterials'] != null) {
            queryParameters['interiorMaterials'] = requestParameters['interiorMaterials'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/options/all`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTOOrderTypeOptionItemDTOFromJSON(jsonValue));
    }

    /**
     * Get all of OrderType with given key
     */
    async getAllOfOrderType(requestParameters: GetAllOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDTOOrderTypeOptionItemDTO> {
        const response = await this.getAllOfOrderTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get filterOptionsOfAll of OrderType with given key
     */
    async getFilterOptionsOfAllOfOrderTypeRaw(requestParameters: GetFilterOptionsOfAllOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderTypeOptionFilterOptionsDTO>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getFilterOptionsOfAllOfOrderType().'
            );
        }

        if (requestParameters['languageTag'] == null) {
            throw new runtime.RequiredError(
                'languageTag',
                'Required parameter "languageTag" was null or undefined when calling getFilterOptionsOfAllOfOrderType().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['languageTag'] != null) {
            queryParameters['languageTag'] = requestParameters['languageTag'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/web/order-types/details/{key}/options/all/filter-options`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderTypeOptionFilterOptionsDTOFromJSON(jsonValue));
    }

    /**
     * Get filterOptionsOfAll of OrderType with given key
     */
    async getFilterOptionsOfAllOfOrderType(requestParameters: GetFilterOptionsOfAllOfOrderTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderTypeOptionFilterOptionsDTO> {
        const response = await this.getFilterOptionsOfAllOfOrderTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

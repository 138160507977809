import { propertiesOf } from "../../../../../common/property"
import { EditFormElementWrapper } from "../../../../../components/formelements/EditFormElementWrapper"
import { getTextFormElement } from "../../../../../components/formelements/textformelement/TextFormElement"
import { rowGroupsSpacing, RowGroupWrapper } from "../../../../../components/layouts/rowgroupwrapper/RowGroupWrapper"
import { SpacedItems } from "../../../../../components/layouts/spaceditems/SpacedItems"
import { ColorCreateRequestDTO } from "../../../../../generated/pdsapi"
import { ProductCreateContentProps } from "../../../../../viewtemplates/create/ProductCreateContainer"
import { CreateDefaultLocalizationsRow } from "../../../../../viewtemplates/create/components/createdefaultlocalizationsrow/CreateDefaultLocalizationsRow"
import { FC } from "react"
import { getMultiTextFormElement } from "../../../../../components/formelements/multitextformelement/MultiTextFormElement"
import { getTagFormTextElement } from "../../../../../components/formelements/tagformtextelement/TagFormTextElement"

export const ColorCreateAttributesCard: FC<ProductCreateContentProps<ColorCreateRequestDTO, unknown>> = ({ formDisplayData }) => {
    const properties = propertiesOf<ColorCreateRequestDTO>()

    return (
        <SpacedItems direction={"column"} lineSpacing={rowGroupsSpacing}>
            <RowGroupWrapper label={"Configuration"}>
                <EditFormElementWrapper
                    label={"Color Code"}
                    formDisplayData={formDisplayData}
                    field={properties.colorCode}
                    FormElement={getTextFormElement<unknown, ColorCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: "",
                    })}
                />
                <EditFormElementWrapper
                    label={"Brush Code"}
                    formDisplayData={formDisplayData}
                    field={properties.brushCode}
                    FormElement={getTagFormTextElement<unknown, ColorCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: "",
                        optional: true,
                    })}
                />
                <EditFormElementWrapper
                    label={"Order Text"}
                    formDisplayData={formDisplayData}
                    field={properties.orderText}
                    FormElement={getTextFormElement<unknown, ColorCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: "",
                        optional: true,
                    })}
                />
                <EditFormElementWrapper
                    label={"Hex Codes"}
                    formDisplayData={formDisplayData}
                    field={properties.hexCodes}
                    FormElement={getMultiTextFormElement<unknown, ColorCreateRequestDTO>()}
                    getEditProps={() => ({
                        value: [],
                    })}
                />
            </RowGroupWrapper>
            <CreateDefaultLocalizationsRow
                label={"Name"}
                formDisplayData={formDisplayData}
                field={properties.name}
                getEditProps={() => ({
                    value: { de: "", en: "" },
                })}
            />
            <CreateDefaultLocalizationsRow
                label={"Description"}
                formDisplayData={formDisplayData}
                field={properties.description}
                getEditProps={() => ({
                    value: { de: "", en: "" },
                    optional: true,
                })}
            />
        </SpacedItems>
    )
}

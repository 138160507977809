/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationAttributesUpdateDTOInteriorValueColor } from './OptionCustomizationAttributesUpdateDTOInteriorValueColor';
import {
    OptionCustomizationAttributesUpdateDTOInteriorValueColorFromJSON,
    OptionCustomizationAttributesUpdateDTOInteriorValueColorFromJSONTyped,
    OptionCustomizationAttributesUpdateDTOInteriorValueColorToJSON,
    OptionCustomizationAttributesUpdateDTOInteriorValueColorToJSONTyped,
} from './OptionCustomizationAttributesUpdateDTOInteriorValueColor';
import type { BaseNameDTO } from './BaseNameDTO';
import {
    BaseNameDTOFromJSON,
    BaseNameDTOFromJSONTyped,
    BaseNameDTOToJSON,
    BaseNameDTOToJSONTyped,
} from './BaseNameDTO';
import type { InteriorMaterial } from './InteriorMaterial';
import {
    InteriorMaterialFromJSON,
    InteriorMaterialFromJSONTyped,
    InteriorMaterialToJSON,
    InteriorMaterialToJSONTyped,
} from './InteriorMaterial';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesUpdateDTOInteriorValue
 */
export interface OptionCustomizationAttributesUpdateDTOInteriorValue {
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationAttributesUpdateDTOInteriorValue
     */
    name: BaseNameDTO;
    /**
     * 
     * @type {BaseNameDTO}
     * @memberof OptionCustomizationAttributesUpdateDTOInteriorValue
     */
    description: BaseNameDTO;
    /**
     * 
     * @type {OptionCustomizationAttributesUpdateDTOInteriorValueColor}
     * @memberof OptionCustomizationAttributesUpdateDTOInteriorValue
     */
    color: OptionCustomizationAttributesUpdateDTOInteriorValueColor;
    /**
     * 
     * @type {InteriorMaterial}
     * @memberof OptionCustomizationAttributesUpdateDTOInteriorValue
     */
    material: InteriorMaterial;
}



/**
 * Check if a given object implements the OptionCustomizationAttributesUpdateDTOInteriorValue interface.
 */
export function instanceOfOptionCustomizationAttributesUpdateDTOInteriorValue(value: object): value is OptionCustomizationAttributesUpdateDTOInteriorValue {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('color' in value) || value['color'] === undefined) return false;
    if (!('material' in value) || value['material'] === undefined) return false;
    return true;
}

export function OptionCustomizationAttributesUpdateDTOInteriorValueFromJSON(json: any): OptionCustomizationAttributesUpdateDTOInteriorValue {
    return OptionCustomizationAttributesUpdateDTOInteriorValueFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOInteriorValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesUpdateDTOInteriorValue {
    if (json == null) {
        return json;
    }
    return {
        
        'name': BaseNameDTOFromJSON(json['name']),
        'description': BaseNameDTOFromJSON(json['description']),
        'color': OptionCustomizationAttributesUpdateDTOInteriorValueColorFromJSON(json['color']),
        'material': InteriorMaterialFromJSON(json['material']),
    };
}

export function OptionCustomizationAttributesUpdateDTOInteriorValueToJSON(json: any): OptionCustomizationAttributesUpdateDTOInteriorValue {
    return OptionCustomizationAttributesUpdateDTOInteriorValueToJSONTyped(json, false);
}

export function OptionCustomizationAttributesUpdateDTOInteriorValueToJSONTyped(value?: OptionCustomizationAttributesUpdateDTOInteriorValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': BaseNameDTOToJSON(value['name']),
        'description': BaseNameDTOToJSON(value['description']),
        'color': OptionCustomizationAttributesUpdateDTOInteriorValueColorToJSON(value['color']),
        'material': InteriorMaterialToJSON(value['material']),
    };
}


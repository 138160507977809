/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VideoUpdateDTO } from './VideoUpdateDTO';
import {
    VideoUpdateDTOFromJSON,
    VideoUpdateDTOFromJSONTyped,
    VideoUpdateDTOToJSON,
    VideoUpdateDTOToJSONTyped,
} from './VideoUpdateDTO';

/**
 * 
 * @export
 * @interface CustomizationVideoUpdateDTO
 */
export interface CustomizationVideoUpdateDTO {
    /**
     * 
     * @type {{ [key: string]: VideoUpdateDTO; }}
     * @memberof CustomizationVideoUpdateDTO
     */
    video: { [key: string]: VideoUpdateDTO; };
}

/**
 * Check if a given object implements the CustomizationVideoUpdateDTO interface.
 */
export function instanceOfCustomizationVideoUpdateDTO(value: object): value is CustomizationVideoUpdateDTO {
    if (!('video' in value) || value['video'] === undefined) return false;
    return true;
}

export function CustomizationVideoUpdateDTOFromJSON(json: any): CustomizationVideoUpdateDTO {
    return CustomizationVideoUpdateDTOFromJSONTyped(json, false);
}

export function CustomizationVideoUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomizationVideoUpdateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'video': (mapValues(json['video'], VideoUpdateDTOFromJSON)),
    };
}

export function CustomizationVideoUpdateDTOToJSON(json: any): CustomizationVideoUpdateDTO {
    return CustomizationVideoUpdateDTOToJSONTyped(json, false);
}

export function CustomizationVideoUpdateDTOToJSONTyped(value?: CustomizationVideoUpdateDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'video': (mapValues(value['video'], VideoUpdateDTOToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SelectOptionDTOEngineLayout } from './SelectOptionDTOEngineLayout';
import {
    SelectOptionDTOEngineLayoutFromJSON,
    SelectOptionDTOEngineLayoutFromJSONTyped,
    SelectOptionDTOEngineLayoutToJSON,
    SelectOptionDTOEngineLayoutToJSONTyped,
} from './SelectOptionDTOEngineLayout';
import type { SelectOptionDTOTransmission } from './SelectOptionDTOTransmission';
import {
    SelectOptionDTOTransmissionFromJSON,
    SelectOptionDTOTransmissionFromJSONTyped,
    SelectOptionDTOTransmissionToJSON,
    SelectOptionDTOTransmissionToJSONTyped,
} from './SelectOptionDTOTransmission';
import type { SelectOptionDTOEngineDesign } from './SelectOptionDTOEngineDesign';
import {
    SelectOptionDTOEngineDesignFromJSON,
    SelectOptionDTOEngineDesignFromJSONTyped,
    SelectOptionDTOEngineDesignToJSON,
    SelectOptionDTOEngineDesignToJSONTyped,
} from './SelectOptionDTOEngineDesign';
import type { SelectOptionDTOFuelType } from './SelectOptionDTOFuelType';
import {
    SelectOptionDTOFuelTypeFromJSON,
    SelectOptionDTOFuelTypeFromJSONTyped,
    SelectOptionDTOFuelTypeToJSON,
    SelectOptionDTOFuelTypeToJSONTyped,
} from './SelectOptionDTOFuelType';

/**
 * 
 * @export
 * @interface OrderTypeTechnicalDataRelatedDataDTO
 */
export interface OrderTypeTechnicalDataRelatedDataDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderTypeTechnicalDataRelatedDataDTO
     */
    possibleOptionCodes: Array<string>;
    /**
     * 
     * @type {Array<SelectOptionDTOEngineDesign>}
     * @memberof OrderTypeTechnicalDataRelatedDataDTO
     */
    engineDesigns: Array<SelectOptionDTOEngineDesign>;
    /**
     * 
     * @type {Array<SelectOptionDTOEngineLayout>}
     * @memberof OrderTypeTechnicalDataRelatedDataDTO
     */
    engineLayouts: Array<SelectOptionDTOEngineLayout>;
    /**
     * 
     * @type {Array<SelectOptionDTOTransmission>}
     * @memberof OrderTypeTechnicalDataRelatedDataDTO
     */
    transmissions: Array<SelectOptionDTOTransmission>;
    /**
     * 
     * @type {Array<SelectOptionDTOFuelType>}
     * @memberof OrderTypeTechnicalDataRelatedDataDTO
     */
    fuelTypes: Array<SelectOptionDTOFuelType>;
}

/**
 * Check if a given object implements the OrderTypeTechnicalDataRelatedDataDTO interface.
 */
export function instanceOfOrderTypeTechnicalDataRelatedDataDTO(value: object): value is OrderTypeTechnicalDataRelatedDataDTO {
    if (!('possibleOptionCodes' in value) || value['possibleOptionCodes'] === undefined) return false;
    if (!('engineDesigns' in value) || value['engineDesigns'] === undefined) return false;
    if (!('engineLayouts' in value) || value['engineLayouts'] === undefined) return false;
    if (!('transmissions' in value) || value['transmissions'] === undefined) return false;
    if (!('fuelTypes' in value) || value['fuelTypes'] === undefined) return false;
    return true;
}

export function OrderTypeTechnicalDataRelatedDataDTOFromJSON(json: any): OrderTypeTechnicalDataRelatedDataDTO {
    return OrderTypeTechnicalDataRelatedDataDTOFromJSONTyped(json, false);
}

export function OrderTypeTechnicalDataRelatedDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTypeTechnicalDataRelatedDataDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'possibleOptionCodes': json['possibleOptionCodes'],
        'engineDesigns': ((json['engineDesigns'] as Array<any>).map(SelectOptionDTOEngineDesignFromJSON)),
        'engineLayouts': ((json['engineLayouts'] as Array<any>).map(SelectOptionDTOEngineLayoutFromJSON)),
        'transmissions': ((json['transmissions'] as Array<any>).map(SelectOptionDTOTransmissionFromJSON)),
        'fuelTypes': ((json['fuelTypes'] as Array<any>).map(SelectOptionDTOFuelTypeFromJSON)),
    };
}

export function OrderTypeTechnicalDataRelatedDataDTOToJSON(json: any): OrderTypeTechnicalDataRelatedDataDTO {
    return OrderTypeTechnicalDataRelatedDataDTOToJSONTyped(json, false);
}

export function OrderTypeTechnicalDataRelatedDataDTOToJSONTyped(value?: OrderTypeTechnicalDataRelatedDataDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'possibleOptionCodes': value['possibleOptionCodes'],
        'engineDesigns': ((value['engineDesigns'] as Array<any>).map(SelectOptionDTOEngineDesignToJSON)),
        'engineLayouts': ((value['engineLayouts'] as Array<any>).map(SelectOptionDTOEngineLayoutToJSON)),
        'transmissions': ((value['transmissions'] as Array<any>).map(SelectOptionDTOTransmissionToJSON)),
        'fuelTypes': ((value['fuelTypes'] as Array<any>).map(SelectOptionDTOFuelTypeToJSON)),
    };
}


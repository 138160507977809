import { getCurrentEnvironment } from "../common/environment"
import { couldBeOfType } from "../common/global"
import { Configuration, HttpStatus, SimpleApiError, SimpleOrValidationApiError } from "../generated/pdsapi"

export const apiConfiguration = new Configuration({
    basePath: getCurrentEnvironment().backendUrl,
    credentials: "include",
    middleware: [],
    headers: {
        Accept: "application/json",
    },
})

export const boundApi = <API extends Record<string | symbol, any>>(apiConstructor: new (configuration?: Configuration) => API): API =>
    new Proxy(new apiConstructor(apiConfiguration), {
        get: (api, childProp) => {
            const fn: () => Promise<any> | ((args: any) => Promise<any>) = api[childProp].bind(api)

            switch (fn.length) {
                case 0:
                    return unwrapError(fn as () => Promise<any>)
                case 1:
                    return unwrapError1(fn as (args: any) => Promise<any>)
                case 2:
                    return unwrapError2(fn as (args1: any, args2: any) => Promise<any>)
                default:
                    throw Error(`not supported function call ${childProp.toString()} (${fn.length})`)
            }
        },
        set: () => {
            throw Error("set not supported")
        },
    }) as API

export type ApiRequestFn<PARAMS, OUTPUT> = (params: PARAMS) => Promise<OUTPUT>

type ApiRequestError = SimpleApiError & { statusCode: number }

const unwrapError = <OUTPUT>(request: () => Promise<OUTPUT>) => {
    return () => request().catch(unwrapErrorResponse)
}

const unwrapError1 = <PARAMS, OUTPUT>(request: ApiRequestFn<PARAMS, OUTPUT>) => {
    return (params: PARAMS) => request(params).catch(unwrapErrorResponse)
}

const unwrapError2 = <PARAMS1, PARAMS2, OUTPUT>(request: (params1: PARAMS1, params2: PARAMS2) => Promise<OUTPUT>) => {
    return (params1: PARAMS1, params2: PARAMS2) => request(params1, params2).catch(unwrapErrorResponse)
}

const unwrapErrorResponse = async (error: Response) => {
    let apiError: SimpleOrValidationApiError

    try {
        const parsedApiError = await error.json()
        parsedApiError.timeStamp = new Date(parsedApiError.timeStamp)
        apiError = parsedApiError
    } catch (error) {
        apiError = {
            status: HttpStatus.INTERNAL_SERVER_ERROR,
            message: "Ooops, something went wrong.",
            debugMessage: couldBeOfType<object>(error) ? error.toString() : "Something went wrong",
            timeStamp: new Date(),
        }
    }

    // noinspection UnnecessaryLocalVariableJS
    const apiRequestError: ApiRequestError = { ...apiError, statusCode: error.status ?? 500 }
    throw apiRequestError
}

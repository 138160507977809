import { TableColumnsConfig } from "../../../../components/filteredpagedtable/FilteredPagedTable"
import { ColumnBuilder } from "../../../../components/layouts/table/Table"
import { OptionImage } from "../../../../components/optionimage/OptionImage"
import { OptionItemDTO, OptionSortField } from "../../../../generated/pdsapi"
import { Text, Tooltip } from "@finder/ui-kit"
import React from "react"
import { NamedInteriorColorTile } from "../../../../components/tiles/namedinteriorcolortile/NamedInteriorColorTile"
import { NamedColorTile } from "../../../../components/tiles/namedcolortile/NamedColorTile"
import { spacing } from "@porsche-design-system/utilities"

type OptionCatalogueColumnBuilder = ColumnBuilder<OptionItemDTO, OptionSortField>

export const OptionCatalogueColumnsConfig: TableColumnsConfig<OptionItemDTO, OptionSortField> = () => {
    const previewColumn: OptionCatalogueColumnBuilder = {
        width: 80,
        headerCellProps: {
            content: "Preview",
        },
        buildCellContent: (option) => <OptionImage imageKey={option.imageKey} sizes={"100px"} />,
    }

    const orderTypeCodesColumn: OptionCatalogueColumnBuilder = {
        width: 80,
        headerCellProps: {
            content: "Order Types",
        },
        buildCellContent: (option) => option.orderTypeCodes.join(", "),
    }

    const modelYearColumn: OptionCatalogueColumnBuilder = {
        width: 80,
        headerCellProps: {
            content: "Model Year",
        },
        buildCellContent: (option) => option.modelYear.toString(),
    }

    const modelGenerationColumn: OptionCatalogueColumnBuilder = {
        width: 80,
        headerCellProps: {
            content: "Model Generation",
        },
        buildCellContent: (equipmentDetails) => equipmentDetails.modelGenerationName ?? "-",
    }

    const typeColumn: OptionCatalogueColumnBuilder = {
        width: 30,
        headerCellProps: {
            content: "Type",
        },
        buildCellContent: (option) => {
            switch (option.type) {
                case "IndividualEquipment":
                    return (
                        <Tooltip content={"Individual Equipment"}>
                            <Text>IEQ</Text>
                        </Tooltip>
                    )
                case "CustomTailoring":
                    return (
                        <Tooltip content={"Custom Tailoring"}>
                            <Text>CT</Text>
                        </Tooltip>
                    )
                case "ZOrder":
                    return (
                        <Tooltip content={"Z Order"}>
                            <Text>Z</Text>
                        </Tooltip>
                    )
                case "SalesPackage":
                    return (
                        <Tooltip content={"Sales Package"}>
                            <Text>SP</Text>
                        </Tooltip>
                    )
                case "TechnicalPackage":
                    return (
                        <Tooltip content={"Technical Package"}>
                            <Text>TP</Text>
                        </Tooltip>
                    )
                case "UnclassifiedEquipment":
                    return (
                        <Tooltip content={"Unclassified Equipment"}>
                            <Text>?</Text>
                        </Tooltip>
                    )
                case "ExteriorColor":
                    return (
                        <Tooltip content={"Exterior Color"}>
                            <Text>EC</Text>
                        </Tooltip>
                    )
                case "PaintToSampleExteriorColor":
                    return (
                        <Tooltip content={"Paint To Sample Exterior Color"}>
                            <Text>PTS</Text>
                        </Tooltip>
                    )
                case "Interior":
                    return (
                        <Tooltip content={"Interior"}>
                            <Text>I</Text>
                        </Tooltip>
                    )
                case "RoofColor":
                    return (
                        <Tooltip content={"Roof Color"}>
                            <Text>RC</Text>
                        </Tooltip>
                    )
            }
        },
    }

    const optionCodeColumn: OptionCatalogueColumnBuilder = {
        width: 50,
        headerCellProps: {
            content: "Option Code",
        },
        buildCellContent: (option) => option.optionCode,
    }

    const nameColumn: OptionCatalogueColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Name",
        },
        buildCellContent: (option) => {
            switch (option.type) {
                case "Interior": {
                    return option.color ? (
                        <NamedInteriorColorTile
                            label={option.name ?? "-"}
                            seatColor={option.color.seatColorHexCode}
                            seatStitchingColor={option.color.seatStitchingColorHexCode}
                            cockpitColor={option.color.cockpitColorHexCode}
                            cockpitStitchingColor={option.color.cockpitStitchingColorHexCode}
                        />
                    ) : (
                        (option.name ?? "-")
                    )
                }
                case "RoofColor":
                case "ExteriorColor":
                    return (option.color && option.name && <NamedColorTile label={option.name} hexCode={option.color.hexCode} />) ?? option.name ?? "-"
                default:
                    return option.name ?? "-"
            }
        },
    }

    const additionalFieldsColumn: OptionCatalogueColumnBuilder = {
        grow: 1,
        headerCellProps: {
            content: "Additional Properties",
        },
        buildCellContent: (option) => {
            switch (option.type) {
                case "IndividualEquipment":
                case "CustomTailoring":
                case "ZOrder":
                case "SalesPackage":
                case "TechnicalPackage":
                case "UnclassifiedEquipment":
                    return (
                        <SeparatedValues>
                            {option.family && <Text>{option.family}</Text>}
                            {option.categoryName && <Text>{option.categoryName}</Text>}
                        </SeparatedValues>
                    )
                case "ExteriorColor":
                    return (
                        <SeparatedValues>
                            {option.tag && <NamedColorTile label={option.tag.label} hexCode={option.tag.hexCode} />}
                            {option.colorTypeName && <Text>{option.colorTypeName}</Text>}
                        </SeparatedValues>
                    )
                case "PaintToSampleExteriorColor":
                    return <></>
                case "Interior":
                    return (
                        <SeparatedValues>
                            {option.colorTag && <NamedColorTile label={option.colorTag.label} hexCode={option.colorTag.hexCode} />}
                            {option.materialName && <Text>{option.materialName}</Text>}
                        </SeparatedValues>
                    )
                case "RoofColor":
                    return <SeparatedValues>{option.tag && <NamedColorTile label={option.tag.label} hexCode={option.tag.hexCode} />}</SeparatedValues>
            }
        },
    }

    return [previewColumn, orderTypeCodesColumn, modelYearColumn, modelGenerationColumn, typeColumn, optionCodeColumn, nameColumn, additionalFieldsColumn]
}

const SeparatedValues = ({ children }: { children: React.ReactNode }) => {
    return (
        <div style={{ display: "flex", gap: spacing[8] }}>
            {React.Children.toArray(children)
                .filter((child) => child != null)
                .map((child, index) => (
                    <>
                        {index > 0 && <Text color={"neutral-contrast-low"}>|</Text>}
                        {child}
                    </>
                ))}
        </div>
    )
}

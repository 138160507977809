/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderTypeItemDTO } from './OrderTypeItemDTO';
import {
    OrderTypeItemDTOFromJSON,
    OrderTypeItemDTOFromJSONTyped,
    OrderTypeItemDTOToJSON,
    OrderTypeItemDTOToJSONTyped,
} from './OrderTypeItemDTO';

/**
 * 
 * @export
 * @interface PageDTOOrderTypeItemDTO
 */
export interface PageDTOOrderTypeItemDTO {
    /**
     * 
     * @type {number}
     * @memberof PageDTOOrderTypeItemDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<OrderTypeItemDTO>}
     * @memberof PageDTOOrderTypeItemDTO
     */
    content: Array<OrderTypeItemDTO>;
}

/**
 * Check if a given object implements the PageDTOOrderTypeItemDTO interface.
 */
export function instanceOfPageDTOOrderTypeItemDTO(value: object): value is PageDTOOrderTypeItemDTO {
    if (!('totalItemCount' in value) || value['totalItemCount'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function PageDTOOrderTypeItemDTOFromJSON(json: any): PageDTOOrderTypeItemDTO {
    return PageDTOOrderTypeItemDTOFromJSONTyped(json, false);
}

export function PageDTOOrderTypeItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTOOrderTypeItemDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'content': ((json['content'] as Array<any>).map(OrderTypeItemDTOFromJSON)),
    };
}

export function PageDTOOrderTypeItemDTOToJSON(json: any): PageDTOOrderTypeItemDTO {
    return PageDTOOrderTypeItemDTOToJSONTyped(json, false);
}

export function PageDTOOrderTypeItemDTOToJSONTyped(value?: PageDTOOrderTypeItemDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'totalItemCount': value['totalItemCount'],
        'content': ((value['content'] as Array<any>).map(OrderTypeItemDTOToJSON)),
    };
}


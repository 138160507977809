/* tslint:disable */
/* eslint-disable */
/**
 * Finder Product Data Service
 * The Finder Product Data Service provides information about vehicle models.
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OptionCustomizationAttributesDTOPaintToSampleExteriorColorValue } from './OptionCustomizationAttributesDTOPaintToSampleExteriorColorValue';
import {
    OptionCustomizationAttributesDTOPaintToSampleExteriorColorValueFromJSON,
    OptionCustomizationAttributesDTOPaintToSampleExteriorColorValueFromJSONTyped,
    OptionCustomizationAttributesDTOPaintToSampleExteriorColorValueToJSON,
    OptionCustomizationAttributesDTOPaintToSampleExteriorColorValueToJSONTyped,
} from './OptionCustomizationAttributesDTOPaintToSampleExteriorColorValue';
import type { OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOPaintToSampleExteriorColorValue } from './OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOPaintToSampleExteriorColorValue';
import {
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOPaintToSampleExteriorColorValueFromJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOPaintToSampleExteriorColorValueFromJSONTyped,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOPaintToSampleExteriorColorValueToJSON,
    OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOPaintToSampleExteriorColorValueToJSONTyped,
} from './OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOPaintToSampleExteriorColorValue';
import type { SelectOptionDTOString } from './SelectOptionDTOString';
import {
    SelectOptionDTOStringFromJSON,
    SelectOptionDTOStringFromJSONTyped,
    SelectOptionDTOStringToJSON,
    SelectOptionDTOStringToJSONTyped,
} from './SelectOptionDTOString';
import type { MultiSelectDTOString } from './MultiSelectDTOString';
import {
    MultiSelectDTOStringFromJSON,
    MultiSelectDTOStringFromJSONTyped,
    MultiSelectDTOStringToJSON,
    MultiSelectDTOStringToJSONTyped,
} from './MultiSelectDTOString';

/**
 * 
 * @export
 * @interface OptionCustomizationAttributesDTOPaintToSampleExteriorColor
 */
export interface OptionCustomizationAttributesDTOPaintToSampleExteriorColor {
    /**
     * 
     * @type {OptionCustomizationAttributesDTOPaintToSampleExteriorColorValue}
     * @memberof OptionCustomizationAttributesDTOPaintToSampleExteriorColor
     */
    defaultValue: OptionCustomizationAttributesDTOPaintToSampleExteriorColorValue;
    /**
     * 
     * @type {string}
     * @memberof OptionCustomizationAttributesDTOPaintToSampleExteriorColor
     */
    optionCode: string;
    /**
     * 
     * @type {Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOPaintToSampleExteriorColorValue>}
     * @memberof OptionCustomizationAttributesDTOPaintToSampleExteriorColor
     */
    conditionalValues: Array<OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOPaintToSampleExteriorColorValue>;
    /**
     * 
     * @type {boolean}
     * @memberof OptionCustomizationAttributesDTOPaintToSampleExteriorColor
     */
    isDefaultValueActive: boolean;
    /**
     * 
     * @type {MultiSelectDTOString}
     * @memberof OptionCustomizationAttributesDTOPaintToSampleExteriorColor
     */
    modelGenerations: MultiSelectDTOString;
    /**
     * 
     * @type {Array<SelectOptionDTOString>}
     * @memberof OptionCustomizationAttributesDTOPaintToSampleExteriorColor
     */
    derivatives: Array<SelectOptionDTOString>;
}

/**
 * Check if a given object implements the OptionCustomizationAttributesDTOPaintToSampleExteriorColor interface.
 */
export function instanceOfOptionCustomizationAttributesDTOPaintToSampleExteriorColor(value: object): value is OptionCustomizationAttributesDTOPaintToSampleExteriorColor {
    if (!('defaultValue' in value) || value['defaultValue'] === undefined) return false;
    if (!('optionCode' in value) || value['optionCode'] === undefined) return false;
    if (!('conditionalValues' in value) || value['conditionalValues'] === undefined) return false;
    if (!('isDefaultValueActive' in value) || value['isDefaultValueActive'] === undefined) return false;
    if (!('modelGenerations' in value) || value['modelGenerations'] === undefined) return false;
    if (!('derivatives' in value) || value['derivatives'] === undefined) return false;
    return true;
}

export function OptionCustomizationAttributesDTOPaintToSampleExteriorColorFromJSON(json: any): OptionCustomizationAttributesDTOPaintToSampleExteriorColor {
    return OptionCustomizationAttributesDTOPaintToSampleExteriorColorFromJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOPaintToSampleExteriorColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCustomizationAttributesDTOPaintToSampleExteriorColor {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultValue': OptionCustomizationAttributesDTOPaintToSampleExteriorColorValueFromJSON(json['defaultValue']),
        'optionCode': json['optionCode'],
        'conditionalValues': ((json['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOPaintToSampleExteriorColorValueFromJSON)),
        'isDefaultValueActive': json['isDefaultValueActive'],
        'modelGenerations': MultiSelectDTOStringFromJSON(json['modelGenerations']),
        'derivatives': ((json['derivatives'] as Array<any>).map(SelectOptionDTOStringFromJSON)),
    };
}

export function OptionCustomizationAttributesDTOPaintToSampleExteriorColorToJSON(json: any): OptionCustomizationAttributesDTOPaintToSampleExteriorColor {
    return OptionCustomizationAttributesDTOPaintToSampleExteriorColorToJSONTyped(json, false);
}

export function OptionCustomizationAttributesDTOPaintToSampleExteriorColorToJSONTyped(value?: OptionCustomizationAttributesDTOPaintToSampleExteriorColor | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultValue': OptionCustomizationAttributesDTOPaintToSampleExteriorColorValueToJSON(value['defaultValue']),
        'optionCode': value['optionCode'],
        'conditionalValues': ((value['conditionalValues'] as Array<any>).map(OptionCustomizationConditionalValueDTOOptionCustomizationAttributesDTOPaintToSampleExteriorColorValueToJSON)),
        'isDefaultValueActive': value['isDefaultValueActive'],
        'modelGenerations': MultiSelectDTOStringToJSON(value['modelGenerations']),
        'derivatives': ((value['derivatives'] as Array<any>).map(SelectOptionDTOStringToJSON)),
    };
}

